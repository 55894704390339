// src/App.js
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Header from "./component/Navbar/Header";
import Hero from "./component/Home/Hero";
import MainNav from "./component/Navbar/MainNav";
import Footer from "./component/Footer/Footer";
import About from "./component/Home/About/About";
import TermCondition from "./component/Home/TermCondition/TermCondition";
import PrivacyPolicy from "./component/Home/PrivacyPolicy/PrivacyPolicy";
import Contact from "./component/Home/ContactUs/Contact";
import TourBanner from "./component/TourPackages/TourBanner/TourBanner";
import HotelSearch from "./component/Hotel/HotelSearch/HotelSearch";
import BookingConfirm from "./component/Hotel/HotelBookingConfirm/BookingConfirm";
import HotelBooking from "./component/Hotel/HotelBooking/HotelBooking";
import HotelDetail from "./component/Hotel/HotelDetail/HotelDetail";
import Hotels from "./component/Hotel/Hotels/Hotels";
import { useEffect, useState } from "react";
import FlightInternational from "./component/Flight/FlightInternational/FlightInternational";
import RoundTrips from "./component/roundtrip/RoundTrips";
import FlightDetail from "./component/Flight/FlightDetail/FlightDetail";
import FlightBookingTicket from "./component/Flight/TicketBooking/BookingTicket";
import { FlightList } from "./component/Flight/FlightList/FlightList";
import DetailPage from "./component/TourPackages/DetailsPages/DetailPage";
import DetailCard from "./component/TourPackages/DetailCard/DeatailCard";
import CommingSoon from "./CommingSoon";
import FlightSearchMobile from "./component/Flight/FlightSearchMobile/FlightSearchMobile";
import HotelSearchMobile from "./component/Hotel/HotelSearch/HotelSearchMobile/HotelSearchMobile";
import Offers from "./component/Offers/Offers";
import CategoryOffers from "./component/Offers/CategoryOffers";
import FlightBookingPage from "./component/booking/flightbooking/FlightBookingPage";
import FlightBookingDetails from "./component/booking/flightbooking/FlightBookingDetails";
import HotelBookingPage from "./component/booking/hotelbooking/HotelBookingPage";
import PackageBookingPage from "./component/booking/packagebooking/PackageBookingPage";
import ProtectedRoute from "./component/ProtectedRoute";
import { AuthProvider, useAuth } from "./context/AuthContext";
import LoginFirst from "./component/Navbar/LoginFirst";
import PhonePePayment from "./Dummy";

function App() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 576);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 576);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <AuthProvider>
      <div>
        <MainNav handleShow={handleShow} />
        <Routes>
          <Route
            path="/"
            element={isMobileView ? <FlightSearchMobile /> : <Hero />}
          />
          {/* <Route path="/" element={<TourBanner />} /> */}
          <Route path="/about-us" element={<About />} />
          <Route path="/terms-conditions" element={<TermCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/tour" element={<TourBanner />} />
          <Route path="/tour/:pack" element={<DetailPage />} />
          <Route path="/tour/:pack/:fullDetails" element={<DetailCard />} />
          <Route
            path="/hotel"
            element={isMobileView ? <HotelSearchMobile /> : <HotelSearch />}
          />
          <Route
            path="/hotel-booking-confirm/:bookingId"
            element={<BookingConfirm />}
          />
          <Route
            path="/hotel/booking/:id/:optionId"
            element={<HotelBooking />}
          />
          <Route path="/hotel-detail/:id" element={<HotelDetail />} />
          <Route path="/hotellist" element={<Hotels />} />
          <Route path="/flightList" element={<FlightList />} />
          <Route path="/flightList/:data" element={<FlightList />} />
          <Route
            path="/international-round/:data"
            element={<FlightInternational />}
          />
          <Route path="/round/:data" element={<RoundTrips />} />
          <Route
            path="/flight-detail/:index/:index2?/:srdvIdx?"
            element={<FlightDetail />}
          />
          <Route
            path="/flight-ticket/:srdvIdx?"
            element={<FlightBookingTicket />}
          />
          <Route
            path="/buses"
            element={
              <CommingSoon
                image="https://img.freepik.com/premium-vector/illustration-bus_951778-9627.jpg"
                heading="Bus"
              />
            }
          />
          <Route
            path="/cabs"
            element={
              <CommingSoon
                image="https://i.etsystatic.com/16454555/r/il/834cc4/2413299973/il_570xN.2413299973_r1ss.jpg"
                heading="Cab"
              />
            }
          />
          <Route
            path="/my-booking"
            element={
              <CommingSoon
                image="https://www.anu-sport.com.au/UITemplates/DefaultPublic/images/placeholder.jpg?MaxH=300"
                heading=""
              />
            }
          />
          <Route path="/offers" element={<Offers />} />
          <Route path="/offers/:category" element={<CategoryOffers />} />
          <Route
            path="/bookings/flight"
            element={
              <ProtectedRoute>
                <FlightBookingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bookings/hotel"
            element={
              <ProtectedRoute>
                <HotelBookingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bookings/tour"
            element={
              <ProtectedRoute>
                <PackageBookingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bookings/flight/details/:id"
            element={
              <ProtectedRoute>
                <FlightBookingDetails />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<LoginFirst />} />
          <Route path='/check' element={<PhonePePayment/>}/>
        </Routes>
        <Footer />
      </div>
    </AuthProvider>
  );
}

export default App;
