import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./TopCity.css";
import Left from "./wing.png";
import Right from "./wing2.png";
import { Link } from "react-router-dom";

const getTomorrowDate = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow.toISOString().split('T')[0];
};

const tomorrowDate = getTomorrowDate();


const data = [
  {
    img: "https://www.easemytrip.com/images/desk-img/mumbai-img.png",
    desti: "Delhi",
    ori: "Mumbai",
    overall: "DEL-BOM",
    url: `/flightList/dest_BOM*org_DEL*dep_${tomorrowDate}*arr_${tomorrowDate}*px_1-0-0*jt_1*cbn_2`
  },
  {
    img: "https://www.easemytrip.com/images/desk-img/bangalore-img.png",
    desti: "Delhi",
    ori: "Banglore",
    overall: "DEL-BLR",
    url: `/flightList/dest_BLR*org_DEL*dep_${tomorrowDate}*arr_${tomorrowDate}*px_1-0-0*jt_1*cbn_2`
  },
  {
    img: "https://www.easemytrip.com/images/desk-img/dubai-img.png",
    desti: "Delhi",
    ori: "Dubai",
    overall: "DEL-DXB",
    url: `/flightList/dest_DXB*org_DEL*dep_${tomorrowDate}*arr_${tomorrowDate}*px_1-0-0*jt_1*cbn_2`
  },
  {
    img: "https://www.easemytrip.com/images/desk-img/ahmedabad-img.png",
    desti: "Delhi",
    ori: "Ahmedabad",
    overall: "DEL-AMD",
    url: `/flightList/dest_AMD*org_DEL*dep_${tomorrowDate}*arr_${tomorrowDate}*px_1-0-0*jt_1*cbn_2`
  },
  {
    img: "https://www.easemytrip.com/images/desk-img/jaipur-img.png",
    desti: "Mumbai",
    ori: "Jaipur",
    overall: "BOM-JAI",
    url: `/flightList/dest_JAI*org_BOM*dep_${tomorrowDate}*arr_${tomorrowDate}*px_1-0-0*jt_1*cbn_2`
  },
  {
    img: "https://www.easemytrip.com/images/desk-img/lucknow-img.png",
    desti: "Delhi",
    ori: "Lucknow",
    overall: "DEL-LKO",
    url: `/flightList/dest_LKO*org_DEL*dep_${tomorrowDate}*arr_${tomorrowDate}*px_1-0-0*jt_1*cbn_2`
  },
  {
    img: "https://www.easemytrip.com/images/desk-img/chennai-img.png",
    desti: "Mumbai",
    ori: "Chennai",
    overall: "BOM-MAA",
    url: `/flightList/dest_MAA*org_BOM*dep_${tomorrowDate}*arr_${tomorrowDate}*px_1-0-0*jt_1*cbn_2`
  },
  {
    img:"https://www.easemytrip.com/images/desk-img/dubai-img.png",
    desti: "Mumbai",
    ori: "Dubai",
    overall: "BOM-DXB",
    url: `/flightList/dest_DXB*org_BOM*dep_${tomorrowDate}*arr_${tomorrowDate}*px_1-0-0*jt_1*cbn_2`
  },
  {
    img: "https://www.easemytrip.com/images/desk-img/kolkata-img.png",
    desti: "Delhi",
    ori: "Kolkata",
    overall: "DEL-CCU",
    url: `/flightList/dest_CCU*org_DEL*dep_${tomorrowDate}*arr_${tomorrowDate}*px_1-0-0*jt_1*cbn_2`
  },
];
const TopCities = () => {
  return (
    <div className="top-cities-container">
      <Container>
        <div className="_toprflcont">
          <div className="_hdrtxt">
            <img src={Right} alt="" style={{ width: "42px", height: "42px" }} />
            <div>Top Flight Routes</div>
            <img src={Left} alt="" style={{ width: "42px", height: "42px" }} />
          </div>
        </div>
        <Row style={{ padding: "10px" }}>
          {data.map((item, index) => (
            <Col md={4}>
              <Link className="_city_bx" to={item.url}>
                <div className="_img">
                  <img src={item.img} alt="Top Route" />
                </div>
                <div className="_igtxt">
                  <div className="_c_nm">
                    <span className="top-cities-countryyy">{item.desti}</span>
                    <span>
                      <img
                        src="/Images/direct-flight.png"
                        alt="Flight"
                        style={{ width: "32px", height: "32px" }}
                      />
                    </span>
                    <span className="top-cities-countryy">{item.ori}</span>
                  </div>
                  <div className="_count">{item.overall}</div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default TopCities;
