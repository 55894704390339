export const cities12=[
    {
        "CITYNAME": "Anaa",
        "CITYCODE": "AAA",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "AAA",
        "AIRPORTNAME": "Anaa",
    },
    {
        "CITYNAME": "Annaba",
        "CITYCODE": "AAE",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "AAE",
        "AIRPORTNAME": "Les Salines",
    },
    {
        "CITYNAME": "Aalborg",
        "CITYCODE": "AAL",
        "COUNTRYCODE": "DK",
        "COUNTRYNAME": "Denmark",
        "AIRPORTCODE": "AAL",
        "AIRPORTNAME": "Aalborg",
    },
    {
        "CITYNAME": "Al Ain",
        "CITYCODE": "AAN",
        "COUNTRYCODE": "AE",
        "COUNTRYNAME": "United Arab Emirates",
        "AIRPORTCODE": "AAN",
        "AIRPORTNAME": "Al Ain",
    },
    {
        "CITYNAME": "Anaco",
        "CITYCODE": "AAO",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "AAO",
        "AIRPORTNAME": "Anaco",
    },
    {
        "CITYNAME": "Houston",
        "CITYCODE": "AAP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AAP",
        "AIRPORTNAME": "ANDRAU AIRPARK",
    },
    {
        "CITYNAME": "Anapa",
        "CITYCODE": "AAQ",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "AAQ",
        "AIRPORTNAME": "Anapa",
    },
    {
        "CITYNAME": "Aarhus",
        "CITYCODE": "AAR",
        "COUNTRYCODE": "DK",
        "COUNTRYNAME": "Denmark",
        "AIRPORTCODE": "AAR",
        "AIRPORTNAME": "Tirstrup",
    },
    {
        "CITYNAME": "Araxa",
        "CITYCODE": "AAX",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "AAX",
        "AIRPORTNAME": "Araxa Airport",
    },
    {
        "CITYNAME": "Al Ghaydah",
        "CITYCODE": "AAY",
        "COUNTRYCODE": "YE",
        "COUNTRYNAME": "Yemen",
        "AIRPORTCODE": "AAY",
        "AIRPORTNAME": "Al Ghaydah",
    },
    {
        "CITYNAME": "Abakan",
        "CITYCODE": "ABA",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "ABA",
        "AIRPORTNAME": "Abakan",
    },
    {
        "CITYNAME": "Abadan",
        "CITYCODE": "ABD",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "ABD",
        "AIRPORTNAME": "Abadan Airport",
    },
    {
        "CITYNAME": "Allentown",
        "CITYCODE": "ABE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ABE",
        "AIRPORTNAME": "Allentown",
    },
    {
        "CITYNAME": "Abaiang",
        "CITYCODE": "ABF",
        "COUNTRYCODE": "KI",
        "COUNTRYNAME": "Kiribati",
        "AIRPORTCODE": "ABF",
        "AIRPORTNAME": "Abaiang",
    },
    {
        "CITYNAME": "Alpha",
        "CITYCODE": "ABH",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "ABH",
        "AIRPORTNAME": "Alpha Airport",
    },
    {
        "CITYNAME": "Abilene",
        "CITYCODE": "ABI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ABI",
        "AIRPORTNAME": "Abilene Municipal",
    },
    {
        "CITYNAME": "Abidjan",
        "CITYCODE": "ABJ",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "ABJ",
        "AIRPORTNAME": "F Houphouet Boig",
    },
    {
        "CITYNAME": "Kabri Dar",
        "CITYCODE": "ABK",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "ABK",
        "AIRPORTNAME": "Kabri Dar",
    },
    {
        "CITYNAME": "Ambler",
        "CITYCODE": "ABL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ABL",
        "AIRPORTNAME": "Ambler",
    },
    {
        "CITYNAME": "Bamaga",
        "CITYCODE": "ABM",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "ABM",
        "AIRPORTNAME": "Bamaga",
    },
    {
        "CITYNAME": "Albuquerque",
        "CITYCODE": "ABQ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ABQ",
        "AIRPORTNAME": "Albuquerque Int'l",
    },
    {
        "CITYNAME": "Aberdeen",
        "CITYCODE": "ABR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ABR",
        "AIRPORTNAME": "Aberdeen Municipal",
    },
    {
        "CITYNAME": "Abu Simbel",
        "CITYCODE": "ABS",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "ABS",
        "AIRPORTNAME": "Abu Simbel",
    },
    {
        "CITYNAME": "Al Baha",
        "CITYCODE": "ABT",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "ABT",
        "AIRPORTNAME": "Al Baha",
    },
    {
        "CITYNAME": "Atambua",
        "CITYCODE": "ABU",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "ABU",
        "AIRPORTNAME": "Atambua",
    },
    {
        "CITYNAME": "Abuja",
        "CITYCODE": "ABV",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "ABV",
        "AIRPORTNAME": "Abuja Airport",
    },
    {
        "CITYNAME": "Albury",
        "CITYCODE": "ABX",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "ABX",
        "AIRPORTNAME": "Albury",
    },
    {
        "CITYNAME": "Albany",
        "CITYCODE": "ABY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ABY",
        "AIRPORTNAME": "Dougherty Coun",
    },
    {
        "CITYNAME": "Aberdeen",
        "CITYCODE": "ABZ",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "ABZ",
        "AIRPORTNAME": "Dyce",
    },
    {
        "CITYNAME": "Acapulco",
        "CITYCODE": "ACA",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "ACA",
        "AIRPORTNAME": "Alvarez Int'l",
    },
    {
        "CITYNAME": "Bellaire",
        "CITYCODE": "ACB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ACB",
        "AIRPORTNAME": "Bellaire - Antrim County",
    },
    {
        "CITYNAME": "Accra",
        "CITYCODE": "ACC",
        "COUNTRYCODE": "GH",
        "COUNTRYNAME": "Ghana",
        "AIRPORTCODE": "ACC",
        "AIRPORTNAME": "Kotoka",
    },
    {
        "CITYNAME": "Acandi",
        "CITYCODE": "ACD",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "ACD",
        "AIRPORTNAME": "Acandi",
    },
    {
        "CITYNAME": "Lanzarote",
        "CITYCODE": "ACE",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "ACE",
        "AIRPORTNAME": "Lanzarote",
    },
    {
        "CITYNAME": "Altenrhein",
        "CITYCODE": "ACH",
        "COUNTRYCODE": "CH",
        "COUNTRYNAME": "Switzerland",
        "AIRPORTCODE": "ACH",
        "AIRPORTNAME": "Altenrhein",
    },
    {
        "CITYNAME": "Alderney",
        "CITYCODE": "ACI",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "ACI",
        "AIRPORTNAME": "The Blaye",
    },
    {
        "CITYNAME": "Nantucket",
        "CITYCODE": "ACK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ACK",
        "AIRPORTNAME": "Memorial",
    },
    {
        "CITYNAME": "Araracuara",
        "CITYCODE": "ACR",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "ACR",
        "AIRPORTNAME": "Araracuara",
    },
    {
        "CITYNAME": "Waco",
        "CITYCODE": "ACT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ACT",
        "AIRPORTNAME": "Waco Municipal",
    },
    {
        "CITYNAME": "Waco",
        "CITYCODE": "ACT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CNW",
        "AIRPORTNAME": "James Connall",
    },
    {
        "CITYNAME": "Arcata",
        "CITYCODE": "ACV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ACV",
        "AIRPORTNAME": "Arcata Eureka",
    },
    {
        "CITYNAME": "Adana",
        "CITYCODE": "ADA",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "ADA",
        "AIRPORTNAME": "Adana",
    },
    {
        "CITYNAME": "Addis Ababa",
        "CITYCODE": "ADD",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "ADD",
        "AIRPORTNAME": "Bole",
    },
    {
        "CITYNAME": "Aden",
        "CITYCODE": "ADE",
        "COUNTRYCODE": "YE",
        "COUNTRYNAME": "Yemen",
        "AIRPORTCODE": "ADE",
        "AIRPORTNAME": "Aden Int'l",
    },
    {
        "CITYNAME": "Adiyaman",
        "CITYCODE": "ADF",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "ADF",
        "AIRPORTNAME": "Adiyaman Airport",
    },
    {
        "CITYNAME": "Arandis",
        "CITYCODE": "ADI",
        "COUNTRYCODE": "NA",
        "COUNTRYNAME": "Namibia",
        "AIRPORTCODE": "ADI",
        "AIRPORTNAME": "Arandis",
    },
    {
        "CITYNAME": "Adak Island",
        "CITYCODE": "ADK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ADK",
        "AIRPORTNAME": "Adak Island",
    },
    {
        "CITYNAME": "Adelaide",
        "CITYCODE": "ADL",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "ADL",
        "AIRPORTNAME": "Adelaide",
    },
    {
        "CITYNAME": "Kodiak",
        "CITYCODE": "ADQ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ADQ",
        "AIRPORTNAME": "Kodiak",
    },
    {
        "CITYNAME": "San Andres Island",
        "CITYCODE": "ADZ",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "ADZ",
        "AIRPORTNAME": "San Andres Island",
    },
    {
        "CITYNAME": "Abemama Atoll",
        "CITYCODE": "AEA",
        "COUNTRYCODE": "KI",
        "COUNTRYNAME": "Kiribati",
        "AIRPORTCODE": "AEA",
        "AIRPORTNAME": "Abemama Atoll",
    },
    {
        "CITYNAME": "Aek Godang",
        "CITYCODE": "AEG",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "AEG",
        "AIRPORTNAME": "Aek Godang",
    },
    {
        "CITYNAME": "Abecher",
        "CITYCODE": "AEH",
        "COUNTRYCODE": "TD",
        "COUNTRYNAME": "Chad",
        "AIRPORTCODE": "AEH",
        "AIRPORTNAME": "Abecher",
    },
    {
        "CITYNAME": "Aioun El Atrouss",
        "CITYCODE": "AEO",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "AEO",
        "AIRPORTNAME": "Aioun El Atrouss",
    },
    {
        "CITYNAME": "Adler Sochi",
        "CITYCODE": "AER",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "AER",
        "AIRPORTNAME": "Adler Sochi",
    },
    {
        "CITYNAME": "Aalesund",
        "CITYCODE": "AES",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "AES",
        "AIRPORTNAME": "Vigra",
    },
    {
        "CITYNAME": "Alexandria",
        "CITYCODE": "AEX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AEX",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Alexandria",
        "CITYCODE": "AEX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ESF",
        "AIRPORTNAME": "Esler Field",
    },
    {
        "CITYNAME": "Akureyri",
        "CITYCODE": "AEY",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "AEY",
        "AIRPORTNAME": "Akureyri",
    },
    {
        "CITYNAME": "San Rafael",
        "CITYCODE": "AFA",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "AFA",
        "AIRPORTNAME": "San Rafael",
    },
    {
        "CITYNAME": "Alta Floresta",
        "CITYCODE": "AFL",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "AFL",
        "AIRPORTNAME": "Alta Floresta Mato Grosso",
    },
    {
        "CITYNAME": "Agadir",
        "CITYCODE": "AGA",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "AGA",
        "AIRPORTNAME": "Agadir Almassira",
    },
    {
        "CITYNAME": "Wangerooge",
        "CITYCODE": "AGE",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "AGE",
        "AIRPORTNAME": "Wangerooge Flugplatz",
    },
    {
        "CITYNAME": "Agen",
        "CITYCODE": "AGF",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "AGF",
        "AIRPORTNAME": "La Garenne",
    },
    {
        "CITYNAME": "Helsingborg",
        "CITYCODE": "AGH",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "AGH",
        "AIRPORTNAME": "Angelholm",
    },
    {
        "CITYNAME": "Aguni",
        "CITYCODE": "AGJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "AGJ",
        "AIRPORTNAME": "Aguni",
    },
    {
        "CITYNAME": "Malaga",
        "CITYCODE": "AGP",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "AGP",
        "AIRPORTNAME": "Malaga",
    },
    {
        "CITYNAME": "Malaga",
        "CITYCODE": "AGP",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "YJM",
        "AIRPORTNAME": "Malaga Airport ",
        "": " Spain"
    },
    {
        "CITYNAME": "Agra",
        "CITYCODE": "AGR",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "AGR",
        "AIRPORTNAME": "Kheria",
    },
    {
        "CITYNAME": "Augusta",
        "CITYCODE": "AGS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AGS",
        "AIRPORTNAME": "Augusta Bush Field",
    },
    {
        "CITYNAME": "Augusta",
        "CITYCODE": "AGS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DNL",
        "AIRPORTNAME": "Daniel Field",
    },
    {
        "CITYNAME": "Ciudad Del Este",
        "CITYCODE": "AGT",
        "COUNTRYCODE": "PY",
        "COUNTRYNAME": "Paraguay",
        "AIRPORTCODE": "AGT",
        "AIRPORTNAME": "Guarani Intl Airport",
    },
    {
        "CITYNAME": "Aguascalientes",
        "CITYCODE": "AGU",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "AGU",
        "AIRPORTNAME": "Aguascalientes",
    },
    {
        "CITYNAME": "Acarigua",
        "CITYCODE": "AGV",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "AGV",
        "AIRPORTNAME": "Acarigua",
    },
    {
        "CITYNAME": "Agatti Island",
        "CITYCODE": "AGX",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "AGX",
        "AIRPORTNAME": "Agatti Island",
    },
    {
        "CITYNAME": "Argyle Downs",
        "CITYCODE": "AGY",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "AGY",
        "AIRPORTNAME": "Argyle Downs Airport",
    },
    {
        "CITYNAME": "Aggeneys",
        "CITYCODE": "AGZ",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "AGZ",
        "AIRPORTNAME": "Aggeneys",
    },
    {
        "CITYNAME": "Abha",
        "CITYCODE": "AHB",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "AHB",
        "AIRPORTNAME": "Abha",
    },
    {
        "CITYNAME": "Alghero",
        "CITYCODE": "AHO",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "AHO",
        "AIRPORTNAME": "Fertilia",
    },
    {
        "CITYNAME": "Al Hoceima",
        "CITYCODE": "AHU",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "AHU",
        "AIRPORTNAME": "Charif Al Idrissi",
    },
    {
        "CITYNAME": "Alliance",
        "CITYCODE": "AIA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AIA",
        "AIRPORTNAME": "Alliance",
    },
    {
        "CITYNAME": "Airok",
        "CITYCODE": "AIC",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "AIC",
        "AIRPORTNAME": "Airok",
    },
    {
        "CITYNAME": "Yalinga",
        "CITYCODE": "AIG",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "AIG",
        "AIRPORTNAME": "Yalinga",
    },
    {
        "CITYNAME": "Ailuk Island",
        "CITYCODE": "AIM",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "AIM",
        "AIRPORTNAME": "Ailuk Island",
    },
    {
        "CITYNAME": "Wainwright",
        "CITYCODE": "AIN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AIN",
        "AIRPORTNAME": "Wainwright",
    },
    {
        "CITYNAME": "Adampur",
        "CITYCODE": "AIP",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "AIP",
        "AIRPORTNAME": "Adampur Airport",
    },
    {
        "CITYNAME": "Arorae Island",
        "CITYCODE": "AIS",
        "COUNTRYCODE": "KI",
        "COUNTRYNAME": "Kiribati",
        "AIRPORTCODE": "AIS",
        "AIRPORTNAME": "Arorae Island",
    },
    {
        "CITYNAME": "Aitutaki",
        "CITYCODE": "AIT",
        "COUNTRYCODE": "CK",
        "COUNTRYNAME": "Cook Islands",
        "AIRPORTCODE": "AIT",
        "AIRPORTNAME": "Aitutaki",
    },
    {
        "CITYNAME": "Atiu Island",
        "CITYCODE": "AIU",
        "COUNTRYCODE": "CK",
        "COUNTRYNAME": "Cook Islands",
        "AIRPORTCODE": "AIU",
        "AIRPORTNAME": "Atiu Island",
    },
    {
        "CITYNAME": "Atlantic Cty",
        "CITYCODE": "AIY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ACY",
        "AIRPORTNAME": "Atlantic city International ",
    },
    {
        "CITYNAME": "Atlantic Cty",
        "CITYCODE": "AIY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AIY",
        "AIRPORTNAME": "Bader Field",
    },
    {
        "CITYNAME": "Ajaccio",
        "CITYCODE": "AJA",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "AJA",
        "AIRPORTNAME": "Campo Dell Oro",
    },
    {
        "CITYNAME": "Jouf",
        "CITYCODE": "AJF",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "AJF",
        "AIRPORTNAME": " JOUF",
    },
    {
        "CITYNAME": "Agri",
        "CITYCODE": "AJI",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "AJI",
        "AIRPORTNAME": "Agri Airport",
    },
    {
        "CITYNAME": "Akjoujt",
        "CITYCODE": "AJJ",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "AJJ",
        "AIRPORTNAME": "Akjoujt",
    },
    {
        "CITYNAME": "Aizawl",
        "CITYCODE": "AJL",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "AJL",
        "AIRPORTNAME": "Lengpui Airport",
    },
    {
        "CITYNAME": "Anjouan",
        "CITYCODE": "AJN",
        "COUNTRYCODE": "KM",
        "COUNTRYNAME": "Comoros",
        "AIRPORTCODE": "AJN",
        "AIRPORTNAME": "Ouani",
    },
    {
        "CITYNAME": "Arvidsjaur",
        "CITYCODE": "AJR",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "AJR",
        "AIRPORTNAME": "Arvidsjaur",
    },
    {
        "CITYNAME": "Aracaju",
        "CITYCODE": "AJU",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "AJU",
        "AIRPORTNAME": "Aracaju",
    },
    {
        "CITYNAME": "Agades",
        "CITYCODE": "AJY",
        "COUNTRYCODE": "NE",
        "COUNTRYNAME": "Niger",
        "AIRPORTCODE": "AJY",
        "AIRPORTNAME": "Agades",
    },
    {
        "CITYNAME": "Akola",
        "CITYCODE": "AKD",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "AKD",
        "AIRPORTNAME": "Akola",
    },
    {
        "CITYNAME": "Kufrah",
        "CITYCODE": "AKF",
        "COUNTRYCODE": "LY",
        "COUNTRYNAME": "Libyan Arab Jamahiriya",
        "AIRPORTCODE": "AKF",
        "AIRPORTNAME": "Kufrah",
    },
    {
        "CITYNAME": "Asahikawa",
        "CITYCODE": "AKJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "AKJ",
        "AIRPORTNAME": "Asahikawa",
    },
    {
        "CITYNAME": "Auckland",
        "CITYCODE": "AKL",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "AKL",
        "AIRPORTNAME": "Auckland Int'l",
    },
    {
        "CITYNAME": "Zakouma",
        "CITYCODE": "AKM",
        "COUNTRYCODE": "TD",
        "COUNTRYNAME": "Chad",
        "AIRPORTCODE": "AKM",
        "AIRPORTNAME": "Zakouma",
    },
    {
        "CITYNAME": "King Salmon",
        "CITYCODE": "AKN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AKN",
        "AIRPORTNAME": "King Salmon",
    },
    {
        "CITYNAME": "Akure",
        "CITYCODE": "AKR",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "AKR",
        "AIRPORTNAME": "Akure",
    },
    {
        "CITYNAME": "Aksu",
        "CITYCODE": "AKU",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "AKU",
        "AIRPORTNAME": "Aksu Airport",
    },
    {
        "CITYNAME": "Aktyubinsk",
        "CITYCODE": "AKX",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "AKX",
        "AIRPORTNAME": "Aktyubinsk",
    },
    {
        "CITYNAME": "Sittwe",
        "CITYCODE": "AKY",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "AKY",
        "AIRPORTNAME": "Sittwe Civil",
    },
    {
        "CITYNAME": "Almaty",
        "CITYCODE": "ALA",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "ALA",
        "AIRPORTNAME": "Almaty",
    },
    {
        "CITYNAME": "Albany",
        "CITYCODE": "ALB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ALB",
        "AIRPORTNAME": "Albany",
    },
    {
        "CITYNAME": "Alicante",
        "CITYCODE": "ALC",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "ALC",
        "AIRPORTNAME": "Alicante",
    },
    {
        "CITYNAME": "Alta",
        "CITYCODE": "ALF",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "ALF",
        "AIRPORTNAME": "Alta Airport",
    },
    {
        "CITYNAME": "Algiers",
        "CITYCODE": "ALG",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "ALG",
        "AIRPORTNAME": "Houari Boumediene",
    },
    {
        "CITYNAME": "Albany",
        "CITYCODE": "ALH",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "ALH",
        "AIRPORTNAME": "Albany",
    },
    {
        "CITYNAME": "Albenga",
        "CITYCODE": "ALL",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "ALL",
        "AIRPORTNAME": "ALBENGA",
    },
    {
        "CITYNAME": "Alamogordo",
        "CITYCODE": "ALM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ALM",
        "AIRPORTNAME": "Alamogordo Municipal",
    },
    {
        "CITYNAME": "Waterloo",
        "CITYCODE": "ALO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ALO",
        "AIRPORTNAME": "Waterloo",
    },
    {
        "CITYNAME": "Aleppo",
        "CITYCODE": "ALP",
        "COUNTRYCODE": "SY",
        "COUNTRYNAME": "Syrian Arab Republic",
        "AIRPORTCODE": "ALP",
        "AIRPORTNAME": "Nejrab",
    },
    {
        "CITYNAME": "Alexandra",
        "CITYCODE": "ALR",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "ALR",
        "AIRPORTNAME": "Alexandra",
    },
    {
        "CITYNAME": "Alamosa",
        "CITYCODE": "ALS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ALS",
        "AIRPORTNAME": "Alamosa Municipal",
    },
    {
        "CITYNAME": "Alula",
        "CITYCODE": "ALU",
        "COUNTRYCODE": "SO",
        "COUNTRYNAME": "Somalia",
        "AIRPORTCODE": "ALU",
        "AIRPORTNAME": "Alula",
    },
    {
        "CITYNAME": "Walla Walla",
        "CITYCODE": "ALW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ALW",
        "AIRPORTNAME": "Walla Walla",
    },
    {
        "CITYNAME": "Alexandria",
        "CITYCODE": "ALY",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "ALY",
        "AIRPORTNAME": "Alexandria",
    },
    {
        "CITYNAME": "Alexandria",
        "CITYCODE": "ALY",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "HBE",
        "AIRPORTNAME": "Alexandria - Burj",
    },
    {
        "CITYNAME": "Amarillo",
        "CITYCODE": "AMA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AMA",
        "AIRPORTNAME": "Amarillo Internationa",
    },
    {
        "CITYNAME": "Ambilobe",
        "CITYCODE": "AMB",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "AMB",
        "AIRPORTNAME": "Ambilobe",
    },
    {
        "CITYNAME": "Am Timan",
        "CITYCODE": "AMC",
        "COUNTRYCODE": "TD",
        "COUNTRYNAME": "Chad",
        "AIRPORTCODE": "AMC",
        "AIRPORTNAME": "Am Timan",
    },
    {
        "CITYNAME": "Ahmedabad",
        "CITYCODE": "AMD",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "AMD",
        "AIRPORTNAME": "Sardar Vallabhbhai Patel International Airport",
    },
    {
        "CITYNAME": "Arba Mintch",
        "CITYCODE": "AMH",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "AMH",
        "AIRPORTNAME": "Arba Mintch",
    },
    {
        "CITYNAME": "Mataram",
        "CITYCODE": "AMI",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "AMI",
        "AIRPORTNAME": "Selaparang",
    },
    {
        "CITYNAME": "Amman",
        "CITYCODE": "AMM",
        "COUNTRYCODE": "JO",
        "COUNTRYNAME": "Jordan",
        "AIRPORTCODE": "ADJ",
        "AIRPORTNAME": "Marka",
    },
    {
        "CITYNAME": "Amman",
        "CITYCODE": "AMM",
        "COUNTRYCODE": "JO",
        "COUNTRYNAME": "Jordan",
        "AIRPORTCODE": "AMM",
        "AIRPORTNAME": "Queen Alia Int'l",
    },
    {
        "CITYNAME": "Mao",
        "CITYCODE": "AMO",
        "COUNTRYCODE": "TD",
        "COUNTRYNAME": "Chad",
        "AIRPORTCODE": "AMO",
        "AIRPORTNAME": "Mao",
    },
    {
        "CITYNAME": "Ampanihy",
        "CITYCODE": "AMP",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "AMP",
        "AIRPORTNAME": "Ampanihy",
    },
    {
        "CITYNAME": "Ambon",
        "CITYCODE": "AMQ",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "AMQ",
        "AIRPORTNAME": "Pattimura",
    },
    {
        "CITYNAME": "Amsterdam",
        "CITYCODE": "AMS",
        "COUNTRYCODE": "NL",
        "COUNTRYNAME": "Netherlands",
        "AIRPORTCODE": "AMS",
        "AIRPORTNAME": "Schiphol",
    },
    {
        "CITYNAME": "Amanab",
        "CITYCODE": "AMU",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "AMU",
        "AIRPORTNAME": "Amanab",
    },
    {
        "CITYNAME": "Ambatomainty",
        "CITYCODE": "AMY",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "AMY",
        "AIRPORTNAME": "Ambatomainty",
    },
    {
        "CITYNAME": "Anniston",
        "CITYCODE": "ANB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ANB",
        "AIRPORTNAME": "Anniston County",
    },
    {
        "CITYNAME": "Anchorage",
        "CITYCODE": "ANC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ANC",
        "AIRPORTNAME": "Anchorage Int'l",
    },
    {
        "CITYNAME": "Anchorage",
        "CITYCODE": "ANC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MRI",
        "AIRPORTNAME": "Merrill Field",
    },
    {
        "CITYNAME": "Angers",
        "CITYCODE": "ANE",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "ANE",
        "AIRPORTNAME": "Angers - Marce Airport",
    },
    {
        "CITYNAME": "Antofagasta",
        "CITYCODE": "ANF",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "ANF",
        "AIRPORTNAME": "Cerro Moreno",
    },
    {
        "CITYNAME": "Angouleme",
        "CITYCODE": "ANG",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "ANG",
        "AIRPORTNAME": "Brie-Champnier",
    },
    {
        "CITYNAME": "Aniak",
        "CITYCODE": "ANI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ANI",
        "AIRPORTNAME": "Aniak",
    },
    {
        "CITYNAME": "Ankara",
        "CITYCODE": "ANK",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "ANK",
        "AIRPORTNAME": "Ankara - Etimesgut",
    },
    {
        "CITYNAME": "Ankara",
        "CITYCODE": "ANK",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "ESB",
        "AIRPORTNAME": "Esenboga",
    },
    {
        "CITYNAME": "Antalaha",
        "CITYCODE": "ANM",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "ANM",
        "AIRPORTNAME": "Antsirabato",
    },
    {
        "CITYNAME": "Angoche",
        "CITYCODE": "ANO",
        "COUNTRYCODE": "MZ",
        "COUNTRYNAME": "Mozambique",
        "AIRPORTCODE": "ANO",
        "AIRPORTNAME": "Angoche",
    },
    {
        "CITYNAME": "Antwerp",
        "CITYCODE": "ANR",
        "COUNTRYCODE": "BE",
        "COUNTRYNAME": "Belgium",
        "AIRPORTCODE": "ANR",
        "AIRPORTNAME": "Deurne",
    },
    {
        "CITYNAME": "Andahuaylas",
        "CITYCODE": "ANS",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "ANS",
        "AIRPORTNAME": "Andahuaylas",
    },
    {
        "CITYNAME": "Antigua",
        "CITYCODE": "ANU",
        "COUNTRYCODE": "AG",
        "COUNTRYNAME": "Antigua & Barbuda",
        "AIRPORTCODE": "ANU",
        "AIRPORTNAME": "Vc Bird Int'l",
    },
    {
        "CITYNAME": "Anvik",
        "CITYCODE": "ANV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ANV",
        "AIRPORTNAME": "Anvik",
    },
    {
        "CITYNAME": "Andenes",
        "CITYCODE": "ANX",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "ANX",
        "AIRPORTNAME": "Andenes",
    },
    {
        "CITYNAME": "Altenburg",
        "CITYCODE": "AOC",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "AOC",
        "AIRPORTNAME": "Altenburg - Nobitz Airport",
    },
    {
        "CITYNAME": "Abou Deia",
        "CITYCODE": "AOD",
        "COUNTRYCODE": "TD",
        "COUNTRYNAME": "Chad",
        "AIRPORTCODE": "AOD",
        "AIRPORTNAME": "Abou Deia",
    },
    {
        "CITYNAME": "Eskisehir",
        "CITYCODE": "AOE",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "AOE",
        "AIRPORTNAME": "Eskisehir Anadolu",
    },
    {
        "CITYNAME": "Ancona",
        "CITYCODE": "AOI",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "AOI",
        "AIRPORTNAME": "Falconara",
    },
    {
        "CITYNAME": "Aomori",
        "CITYCODE": "AOJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "AOJ",
        "AIRPORTNAME": "Aomori",
    },
    {
        "CITYNAME": "Karpathos",
        "CITYCODE": "AOK",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "AOK",
        "AIRPORTNAME": "Karpathos",
    },
    {
        "CITYNAME": "Paso De Los Libres",
        "CITYCODE": "AOL",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "AOL",
        "AIRPORTNAME": "Paso De Los Libres",
    },
    {
        "CITYNAME": "Altoona",
        "CITYCODE": "AOO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AOO",
        "AIRPORTNAME": "Altoona",
    },
    {
        "CITYNAME": "Alor Setar",
        "CITYCODE": "AOR",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "AOR",
        "AIRPORTNAME": "Alor Setar",
    },
    {
        "CITYNAME": "Amook",
        "CITYCODE": "AOS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AOS",
        "AIRPORTNAME": "Amook",
    },
    {
        "CITYNAME": "Napa",
        "CITYCODE": "APC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "APC",
        "AIRPORTNAME": "Napa County",
    },
    {
        "CITYNAME": "Naples",
        "CITYCODE": "APF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "APF",
        "AIRPORTNAME": "Naples",
    },
    {
        "CITYNAME": "Apataki",
        "CITYCODE": "APK",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "APK",
        "AIRPORTNAME": "Apataki",
    },
    {
        "CITYNAME": "Nampula",
        "CITYCODE": "APL",
        "COUNTRYCODE": "MZ",
        "COUNTRYNAME": "Mozambique",
        "AIRPORTCODE": "APL",
        "AIRPORTNAME": "Nampula",
    },
    {
        "CITYNAME": "Alpena",
        "CITYCODE": "APN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "APN",
        "AIRPORTNAME": "Alpena County Regional",
    },
    {
        "CITYNAME": "Apartado",
        "CITYCODE": "APO",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "APO",
        "AIRPORTNAME": "Apartado",
    },
    {
        "CITYNAME": "Asapa",
        "CITYCODE": "APP",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "APP",
        "AIRPORTNAME": "Asapa",
    },
    {
        "CITYNAME": "Arapiraca",
        "CITYCODE": "APQ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "APQ",
        "AIRPORTNAME": "Arapiraca",
    },
    {
        "CITYNAME": "Apia",
        "CITYCODE": "APW",
        "COUNTRYCODE": "WS",
        "COUNTRYNAME": "Samoa",
        "AIRPORTCODE": "APW",
        "AIRPORTNAME": "Faleolo",
    },
    {
        "CITYNAME": "Zapala",
        "CITYCODE": "APZ",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "APZ",
        "AIRPORTNAME": "Zapala",
    },
    {
        "CITYNAME": "Araraquara",
        "CITYCODE": "AQA",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "AQA",
        "AIRPORTNAME": "Araraquara Airport",
    },
    {
        "CITYNAME": "Qaisumah",
        "CITYCODE": "AQI",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "AQI",
        "AIRPORTNAME": "HAFAR AL BATIN",
    },
    {
        "CITYNAME": "Aqaba",
        "CITYCODE": "AQJ",
        "COUNTRYCODE": "JO",
        "COUNTRYNAME": "Jordan",
        "AIRPORTCODE": "AQJ",
        "AIRPORTNAME": "King Hussein Intl Airport",
    },
    {
        "CITYNAME": "Arequipa",
        "CITYCODE": "AQP",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "AQP",
        "AIRPORTNAME": "Rodriguez Ballon",
    },
    {
        "CITYNAME": "New Iberia",
        "CITYCODE": "ARA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LFT",
        "AIRPORTNAME": "Lafayette Regional",
    },
    {
        "CITYNAME": "Arctic Village",
        "CITYCODE": "ARC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ARC",
        "AIRPORTNAME": "Arctic Village",
    },
    {
        "CITYNAME": "Alor Island",
        "CITYCODE": "ARD",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "ARD",
        "AIRPORTNAME": "Alor Island",
    },
    {
        "CITYNAME": "Arecibo",
        "CITYCODE": "ARE",
        "COUNTRYCODE": "PR",
        "COUNTRYNAME": "Puerto Rico",
        "AIRPORTCODE": "ARE",
        "AIRPORTNAME": "Antonio Juarbe Pol",
    },
    {
        "CITYNAME": "Arkhangelsk",
        "CITYCODE": "ARH",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "ARH",
        "AIRPORTNAME": "Arkhangelsk",
    },
    {
        "CITYNAME": "Arica",
        "CITYCODE": "ARI",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "ARI",
        "AIRPORTNAME": "Chacalluta",
    },
    {
        "CITYNAME": "Arusha",
        "CITYCODE": "ARK",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "ARK",
        "AIRPORTNAME": "Arusha Airport",
    },
    {
        "CITYNAME": "Armidale",
        "CITYCODE": "ARM",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "ARM",
        "AIRPORTNAME": "Armidale",
    },
    {
        "CITYNAME": "Aragip",
        "CITYCODE": "ARP",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "ARP",
        "AIRPORTNAME": "Aragip",
    },
    {
        "CITYNAME": "Alto Rio Senguerr",
        "CITYCODE": "ARR",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "ARR",
        "AIRPORTNAME": "Alto Rio Senguerr",
    },
    {
        "CITYNAME": "Watertown",
        "CITYCODE": "ART",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ART",
        "AIRPORTNAME": "Watertown",
    },
    {
        "CITYNAME": "Aractuba",
        "CITYCODE": "ARU",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "ARU",
        "AIRPORTNAME": "Aractuba",
    },
    {
        "CITYNAME": "Minocqua",
        "CITYCODE": "ARV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ARV",
        "AIRPORTNAME": "Noble F Lee",
    },
    {
        "CITYNAME": "Arad",
        "CITYCODE": "ARW",
        "COUNTRYCODE": "RO",
        "COUNTRYNAME": "Romania",
        "AIRPORTCODE": "ARW",
        "AIRPORTNAME": "Arad",
    },
    {
        "CITYNAME": "N'Zeto",
        "CITYCODE": "ARZ",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "ARZ",
        "AIRPORTNAME": "N'Zeto",
    },
    {
        "CITYNAME": "Assab",
        "CITYCODE": "ASA",
        "COUNTRYCODE": "ER",
        "COUNTRYNAME": "Eritrea",
        "AIRPORTCODE": "ASA",
        "AIRPORTNAME": "Assab",
    },
    {
        "CITYNAME": "Ashgabad",
        "CITYCODE": "ASB",
        "COUNTRYCODE": "TM",
        "COUNTRYNAME": "Turkmenistan",
        "AIRPORTCODE": "ASB",
        "AIRPORTNAME": "Ashgabad",
    },
    {
        "CITYNAME": "Andros Town",
        "CITYCODE": "ASD",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "ASD",
        "AIRPORTNAME": "Andros Town",
    },
    {
        "CITYNAME": "Aspen",
        "CITYCODE": "ASE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ASE",
        "AIRPORTNAME": "Aspen",
    },
    {
        "CITYNAME": "Astrakhan",
        "CITYCODE": "ASF",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "ASF",
        "AIRPORTNAME": "Astrakhan",
    },
    {
        "CITYNAME": "Amami O Shima",
        "CITYCODE": "ASJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "ASJ",
        "AIRPORTNAME": "Amami O Shima",
    },
    {
        "CITYNAME": "Yamoussoukro",
        "CITYCODE": "ASK",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "ASK",
        "AIRPORTNAME": "Yamoussoukro",
    },
    {
        "CITYNAME": "Asmara",
        "CITYCODE": "ASM",
        "COUNTRYCODE": "ER",
        "COUNTRYNAME": "Eritrea",
        "AIRPORTCODE": "ASM",
        "AIRPORTNAME": "Asmara Int'l",
    },
    {
        "CITYNAME": "Asosa",
        "CITYCODE": "ASO",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "ASO",
        "AIRPORTNAME": "Asosa",
    },
    {
        "CITYNAME": "Alice Springs",
        "CITYCODE": "ASP",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "ASP",
        "AIRPORTNAME": "Alice Springs",
    },
    {
        "CITYNAME": "Kayseri",
        "CITYCODE": "ASR",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "ASR",
        "AIRPORTNAME": "Kayseri",
    },
    {
        "CITYNAME": "Asuncion",
        "CITYCODE": "ASU",
        "COUNTRYCODE": "PY",
        "COUNTRYNAME": "Paraguay",
        "AIRPORTCODE": "ASU",
        "AIRPORTNAME": "Silvio Pettirossi",
    },
    {
        "CITYNAME": "Amboseli",
        "CITYCODE": "ASV",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "ASV",
        "AIRPORTNAME": "Amboseli",
    },
    {
        "CITYNAME": "Aswan",
        "CITYCODE": "ASW",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "ASW",
        "AIRPORTNAME": "Aswan",
    },
    {
        "CITYNAME": "Atbara",
        "CITYCODE": "ATB",
        "COUNTRYCODE": "SD",
        "COUNTRYNAME": "Sudan",
        "AIRPORTCODE": "ATB",
        "AIRPORTNAME": "Atbara",
    },
    {
        "CITYNAME": "Arthur's Town",
        "CITYCODE": "ATC",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "ATC",
        "AIRPORTNAME": "Arthur's Town",
    },
    {
        "CITYNAME": "Athens",
        "CITYCODE": "ATH",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "ATH",
        "AIRPORTNAME": "Athinai",
    },
    {
        "CITYNAME": "Artigas",
        "CITYCODE": "ATI",
        "COUNTRYCODE": "UY",
        "COUNTRYNAME": "Uruguay",
        "AIRPORTCODE": "ATI",
        "AIRPORTNAME": "Artigas",
    },
    {
        "CITYNAME": "Antsirabe",
        "CITYCODE": "ATJ",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "ATJ",
        "AIRPORTNAME": "Antsirabe",
    },
    {
        "CITYNAME": "Atqasuk",
        "CITYCODE": "ATK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ATK",
        "AIRPORTNAME": "Atqasuk",
    },
    {
        "CITYNAME": "Atlanta",
        "CITYCODE": "ATL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ATL",
        "AIRPORTNAME": "William B Hartsfield",
    },
    {
        "CITYNAME": "Altamira",
        "CITYCODE": "ATM",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "ATM",
        "AIRPORTNAME": "Altamira",
    },
    {
        "CITYNAME": "Aitape",
        "CITYCODE": "ATP",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "ATP",
        "AIRPORTNAME": "Aitape Airstrip",
    },
    {
        "CITYNAME": "Amritsar",
        "CITYCODE": "ATQ",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "ATQ",
        "AIRPORTNAME": "Sri Guru Ram Dass Jee International Airport",
    },
    {
        "CITYNAME": "Atar",
        "CITYCODE": "ATR",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "ATR",
        "AIRPORTNAME": "Mouakchott",
    },
    {
        "CITYNAME": "Atmautluak",
        "CITYCODE": "ATT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ATT",
        "AIRPORTNAME": "Atmautluak",
    },
    {
        "CITYNAME": "Ati",
        "CITYCODE": "ATV",
        "COUNTRYCODE": "TD",
        "COUNTRYNAME": "Chad",
        "AIRPORTCODE": "ATV",
        "AIRPORTNAME": "Ati",
    },
    {
        "CITYNAME": "Appleton",
        "CITYCODE": "ATW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ATW",
        "AIRPORTNAME": "Outagamie County",
    },
    {
        "CITYNAME": "Watertown",
        "CITYCODE": "ATY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ATY",
        "AIRPORTNAME": "Watertown",
    },
    {
        "CITYNAME": "Assiut",
        "CITYCODE": "ATZ",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "ATZ",
        "AIRPORTNAME": "Assiut",
    },
    {
        "CITYNAME": "Aruba",
        "CITYCODE": "AUA",
        "COUNTRYCODE": "AW",
        "COUNTRYNAME": "Aruba",
        "AIRPORTCODE": "AUA",
        "AIRPORTNAME": "Reina Beatrix",
    },
    {
        "CITYNAME": "Arauca",
        "CITYCODE": "AUC",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "AUC",
        "AIRPORTNAME": "Arauca",
    },
    {
        "CITYNAME": "Augusta",
        "CITYCODE": "AUG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AUG",
        "AIRPORTNAME": "Augusta",
    },
    {
        "CITYNAME": "Abu Dhabi",
        "CITYCODE": "AUH",
        "COUNTRYCODE": "AE",
        "COUNTRYNAME": "United Arab Emirates",
        "AIRPORTCODE": "AUH",
        "AIRPORTNAME": "Abu Dhabi Int'l",
    },
    {
        "CITYNAME": "Abu Dhabi",
        "CITYCODE": "AUH",
        "COUNTRYCODE": "AE",
        "COUNTRYNAME": "United Arab Emirates",
        "AIRPORTCODE": "ZVJ",
        "AIRPORTNAME": "Abu Dhabi Bus Station",
    },
    {
        "CITYNAME": "Ambunti",
        "CITYCODE": "AUJ",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "AUJ",
        "AIRPORTNAME": "Ambunti",
    },
    {
        "CITYNAME": "Alakanuk",
        "CITYCODE": "AUK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AUK",
        "AIRPORTNAME": "Alakanuk",
    },
    {
        "CITYNAME": "Agaun",
        "CITYCODE": "AUP",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "AUP",
        "AIRPORTNAME": "Agaun",
    },
    {
        "CITYNAME": "Atuona",
        "CITYCODE": "AUQ",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "AUQ",
        "AIRPORTNAME": "Atuona",
    },
    {
        "CITYNAME": "Aurillac",
        "CITYCODE": "AUR",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "AUR",
        "AIRPORTNAME": "Aurillac",
    },
    {
        "CITYNAME": "Austin",
        "CITYCODE": "AUS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AUS",
        "AIRPORTNAME": "Bergstorm International Airport",
    },
    {
        "CITYNAME": "Aurukun Mission",
        "CITYCODE": "AUU",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "AUU",
        "AIRPORTNAME": "Aurukun Mission",
    },
    {
        "CITYNAME": "Wausau",
        "CITYCODE": "AUW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CWA",
        "AIRPORTNAME": "Central Wisonsin",
    },
    {
        "CITYNAME": "Araguaina",
        "CITYCODE": "AUX",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "AUX",
        "AIRPORTNAME": "Araguaina",
    },
    {
        "CITYNAME": "Aneityum",
        "CITYCODE": "AUY",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "AUY",
        "AIRPORTNAME": "Aneityum",
    },
    {
        "CITYNAME": "Ciego De Avila",
        "CITYCODE": "AVI",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "AVI",
        "AIRPORTNAME": "Ciego De Avila",
    },
    {
        "CITYNAME": "Asheville",
        "CITYCODE": "AVL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AVL",
        "AIRPORTNAME": "Asheville Municipal",
    },
    {
        "CITYNAME": "Avignon",
        "CITYCODE": "AVN",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "AVN",
        "AIRPORTNAME": "Avignon",
    },
    {
        "CITYNAME": "Scranton",
        "CITYCODE": "AVP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AVP",
        "AIRPORTNAME": "Scranton Int'l",
    },
    {
        "CITYNAME": "Avu Avu",
        "CITYCODE": "AVU",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "AVU",
        "AIRPORTNAME": "Avu Avu",
    },
    {
        "CITYNAME": "Avalon",
        "CITYCODE": "AVV",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "AVV",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Catalina Island",
        "CITYCODE": "AVX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AVX",
        "AIRPORTNAME": "Avalon Bay",
    },
    {
        "CITYNAME": "Catalina Island",
        "CITYCODE": "AVX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CIB",
        "AIRPORTNAME": "Ap In The Sky",
    },
    {
        "CITYNAME": "Awassa",
        "CITYCODE": "AWA",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "AWA",
        "AIRPORTNAME": "Awassa",
    },
    {
        "CITYNAME": "Awaba",
        "CITYCODE": "AWB",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "AWB",
        "AIRPORTNAME": "AWABA",
    },
    {
        "CITYNAME": "Aniwa",
        "CITYCODE": "AWD",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "AWD",
        "AIRPORTNAME": "Aniwa",
    },
    {
        "CITYNAME": "Awareh",
        "CITYCODE": "AWH",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "AWH",
        "AIRPORTNAME": "Awareh",
    },
    {
        "CITYNAME": "Ahwaz",
        "CITYCODE": "AWZ",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "AWZ",
        "AIRPORTNAME": "Ahwaz",
    },
    {
        "CITYNAME": "Anguilla",
        "CITYCODE": "AXA",
        "COUNTRYCODE": "AI",
        "COUNTRYNAME": "Anguilla",
        "AIRPORTCODE": "AXA",
        "AIRPORTNAME": "Wallblake",
    },
    {
        "CITYNAME": "Alexandroupolis",
        "CITYCODE": "AXD",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "AXD",
        "AIRPORTNAME": "Demokritos",
    },
    {
        "CITYNAME": "Ataq",
        "CITYCODE": "AXK",
        "COUNTRYCODE": "YE",
        "COUNTRYNAME": "Yemen",
        "AIRPORTCODE": "AXK",
        "AIRPORTNAME": "Ataq",
    },
    {
        "CITYNAME": "Armenia",
        "CITYCODE": "AXM",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "AXM",
        "AIRPORTNAME": "El Eden",
    },
    {
        "CITYNAME": "Spring Point",
        "CITYCODE": "AXP",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "AXP",
        "AIRPORTNAME": "Spring Point",
    },
    {
        "CITYNAME": "Arutua",
        "CITYCODE": "AXR",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "AXR",
        "AIRPORTNAME": "Arutua",
    },
    {
        "CITYNAME": "Akita",
        "CITYCODE": "AXT",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "AXT",
        "AIRPORTNAME": "Akita",
    },
    {
        "CITYNAME": "Axum",
        "CITYCODE": "AXU",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "AXU",
        "AIRPORTNAME": "Axum",
    },
    {
        "CITYNAME": "Yaguara",
        "CITYCODE": "AYG",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "AYG",
        "AIRPORTNAME": "Yaguara",
    },
    {
        "CITYNAME": "Ayodhya",
        "CITYCODE": "AYJ",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "AYJ",
        "AIRPORTNAME": "Maharishi Valmiki International Airport Ayodhya Dham",
    },
    {
        "CITYNAME": "Ayacucho",
        "CITYCODE": "AYP",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "AYP",
        "AIRPORTNAME": "Yanamilla",
    },
    {
        "CITYNAME": "Ayers Rock",
        "CITYCODE": "AYQ",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "AYQ",
        "AIRPORTNAME": "Connellan",
    },
    {
        "CITYNAME": "Antalya",
        "CITYCODE": "AYT",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "AYT",
        "AIRPORTNAME": "Antalya",
    },
    {
        "CITYNAME": "Ayawasi",
        "CITYCODE": "AYW",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "AYW",
        "AIRPORTNAME": "Ayawasi",
    },
    {
        "CITYNAME": "Amazon Bay",
        "CITYCODE": "AZB",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "AZB",
        "AIRPORTNAME": "Amazon Bay",
    },
    {
        "CITYNAME": "Yazd",
        "CITYCODE": "AZD",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "AZD",
        "AIRPORTNAME": "Yazd",
    },
    {
        "CITYNAME": "Andizhan",
        "CITYCODE": "AZN",
        "COUNTRYCODE": "UZ",
        "COUNTRYNAME": "Uzbekistan",
        "AIRPORTCODE": "AZN",
        "AIRPORTNAME": "Andizhan",
    },
    {
        "CITYNAME": "Kalamazoo",
        "CITYCODE": "AZO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AZO",
        "AIRPORTNAME": "Battle Creek Int'l",
    },
    {
        "CITYNAME": "Adrar",
        "CITYCODE": "AZR",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "AZR",
        "AIRPORTNAME": "Adrar",
    },
    {
        "CITYNAME": "Samana",
        "CITYCODE": "AZS",
        "COUNTRYCODE": "DO",
        "COUNTRYNAME": "Dominican Republic",
        "AIRPORTCODE": "AZS",
        "AIRPORTNAME": "Samana El Catey Intl Airport",
    },
    {
        "CITYNAME": "Ambriz",
        "CITYCODE": "AZZ",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "AZZ",
        "AIRPORTNAME": "Ambriz",
    },
    {
        "CITYNAME": "Bialla",
        "CITYCODE": "BAA",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "BAA",
        "AIRPORTNAME": "Bialla",
    },
    {
        "CITYNAME": "Baguio",
        "CITYCODE": "BAG",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "BAG",
        "AIRPORTNAME": "Loakan",
    },
    {
        "CITYNAME": "Bahrain",
        "CITYCODE": "BAH",
        "COUNTRYCODE": "BH",
        "COUNTRYNAME": "Bahrain",
        "AIRPORTCODE": "BAH",
        "AIRPORTNAME": "Bahrain Int'l",
    },
    {
        "CITYNAME": "Buenos Aires",
        "CITYCODE": "BAI",
        "COUNTRYCODE": "CR",
        "COUNTRYNAME": "Costa Rica",
        "AIRPORTCODE": "BAI",
        "AIRPORTNAME": "BUENOS AIRES",
    },
    {
        "CITYNAME": "Batman",
        "CITYCODE": "BAL",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "BAL",
        "AIRPORTNAME": "Batman",
    },
    {
        "CITYNAME": "Ban Mak Khaen",
        "CITYCODE": "BAO",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "BAO",
        "AIRPORTNAME": "Ban Mak Khaen Airport",
    },
    {
        "CITYNAME": "Barranquilla",
        "CITYCODE": "BAQ",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "BAQ",
        "AIRPORTNAME": "E Cortissoz",
    },
    {
        "CITYNAME": "Balalae",
        "CITYCODE": "BAS",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "BAS",
        "AIRPORTNAME": "Balalae",
    },
    {
        "CITYNAME": "Bauru",
        "CITYCODE": "BAU",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "BAU",
        "AIRPORTNAME": "Bauru",
    },
    {
        "CITYNAME": "Baotou",
        "CITYCODE": "BAV",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "BAV",
        "AIRPORTNAME": "Baotou",
    },
    {
        "CITYNAME": "Barnaul",
        "CITYCODE": "BAX",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "BAX",
        "AIRPORTNAME": "Barnaul",
    },
    {
        "CITYNAME": "Baia Mare",
        "CITYCODE": "BAY",
        "COUNTRYCODE": "RO",
        "COUNTRYNAME": "Romania",
        "AIRPORTCODE": "BAY",
        "AIRPORTNAME": "Baia Mare",
    },
    {
        "CITYNAME": "Barbelos",
        "CITYCODE": "BAZ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "BAZ",
        "AIRPORTNAME": "Barcelos Airport",
    },
    {
        "CITYNAME": "Balmaceda",
        "CITYCODE": "BBA",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "BBA",
        "AIRPORTNAME": "Balmaceda Airport",
    },
    {
        "CITYNAME": "Burlington",
        "CITYCODE": "BBF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BBF",
        "AIRPORTNAME": "Burlington",
    },
    {
        "CITYNAME": "Butaritari",
        "CITYCODE": "BBG",
        "COUNTRYCODE": "KI",
        "COUNTRYNAME": "Kiribati",
        "AIRPORTCODE": "BBG",
        "AIRPORTNAME": "Butaritari",
    },
    {
        "CITYNAME": "Barth",
        "CITYCODE": "BBH",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "BBH",
        "AIRPORTNAME": "Stralsund Barth Airport",
    },
    {
        "CITYNAME": "Bhubaneshwar",
        "CITYCODE": "BBI",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "BBI",
        "AIRPORTNAME": "Biju Patnaik International Airport",
    },
    {
        "CITYNAME": "Kasane",
        "CITYCODE": "BBK",
        "COUNTRYCODE": "BW",
        "COUNTRYNAME": "Botswana",
        "AIRPORTCODE": "BBK",
        "AIRPORTNAME": "Kasane",
    },
    {
        "CITYNAME": "Bario",
        "CITYCODE": "BBN",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "BBN",
        "AIRPORTNAME": "Bario",
    },
    {
        "CITYNAME": "Berbera",
        "CITYCODE": "BBO",
        "COUNTRYCODE": "SO",
        "COUNTRYNAME": "Somalia",
        "AIRPORTCODE": "BBO",
        "AIRPORTNAME": "Berbera",
    },
    {
        "CITYNAME": "Barbuda",
        "CITYCODE": "BBQ",
        "COUNTRYCODE": "AG",
        "COUNTRYNAME": "Antigua & Barbuda",
        "AIRPORTCODE": "BBQ",
        "AIRPORTNAME": "Barbuda",
    },
    {
        "CITYNAME": "Blackbush",
        "CITYCODE": "BBS",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "BBS",
        "AIRPORTNAME": "Blackbush",
    },
    {
        "CITYNAME": "Berberati",
        "CITYCODE": "BBT",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "BBT",
        "AIRPORTNAME": "Berberati",
    },
    {
        "CITYNAME": "Bereby",
        "CITYCODE": "BBV",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "BBV",
        "AIRPORTNAME": "Bereby",
    },
    {
        "CITYNAME": "Bambari",
        "CITYCODE": "BBY",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "BBY",
        "AIRPORTNAME": "Bambari",
    },
    {
        "CITYNAME": "Baracoa",
        "CITYCODE": "BCA",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "BCA",
        "AIRPORTNAME": "Baracoa",
    },
    {
        "CITYNAME": "Bacolod",
        "CITYCODE": "BCD",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "BCD",
        "AIRPORTNAME": "Bacolod",
    },
    {
        "CITYNAME": "Bryce",
        "CITYCODE": "BCE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BCE",
        "AIRPORTNAME": "Bryce",
    },
    {
        "CITYNAME": "Bouca",
        "CITYCODE": "BCF",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "BCF",
        "AIRPORTNAME": "Bouca",
    },
    {
        "CITYNAME": "Bacau",
        "CITYCODE": "BCM",
        "COUNTRYCODE": "RO",
        "COUNTRYNAME": "Romania",
        "AIRPORTCODE": "BCM",
        "AIRPORTNAME": "Bacau",
    },
    {
        "CITYNAME": "Barcelona",
        "CITYCODE": "BCN",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "BCN",
        "AIRPORTNAME": "Barcelona",
    },
    {
        "CITYNAME": "Jinka",
        "CITYCODE": "BCO",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "BCO",
        "AIRPORTNAME": "Jinka",
    },
    {
        "CITYNAME": "Boca Raton",
        "CITYCODE": "BCT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BCT",
        "AIRPORTNAME": "Public Non Commercial",
    },
    {
        "CITYNAME": "Bauchi",
        "CITYCODE": "BCU",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "BCU",
        "AIRPORTNAME": "Bauchi",
    },
    {
        "CITYNAME": "Bulchi",
        "CITYCODE": "BCY",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "BCY",
        "AIRPORTNAME": "Bulchi",
    },
    {
        "CITYNAME": "Bickerton Island",
        "CITYCODE": "BCZ",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BCZ",
        "AIRPORTNAME": "Bickerton Island",
    },
    {
        "CITYNAME": "Bermuda",
        "CITYCODE": "BDA",
        "COUNTRYCODE": "BM",
        "COUNTRYNAME": "Bermuda",
        "AIRPORTCODE": "BDA",
        "AIRPORTNAME": "Bermuda Int'l",
    },
    {
        "CITYNAME": "Bundaberg",
        "CITYCODE": "BDB",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BDB",
        "AIRPORTNAME": "Bundaberg",
    },
    {
        "CITYNAME": "Badu Island",
        "CITYCODE": "BDD",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BDD",
        "AIRPORTNAME": "Badu Island",
    },
    {
        "CITYNAME": "Bandar Lengeh",
        "CITYCODE": "BDH",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "BDH",
        "AIRPORTNAME": "Bandar Lengeh",
    },
    {
        "CITYNAME": "Bird Island",
        "CITYCODE": "BDI",
        "COUNTRYCODE": "SC",
        "COUNTRYNAME": "Seychelles",
        "AIRPORTCODE": "BDI",
        "AIRPORTNAME": "Bird Island",
    },
    {
        "CITYNAME": "Banjarmasin",
        "CITYCODE": "BDJ",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "BDJ",
        "AIRPORTNAME": "Sjamsudin Noor",
    },
    {
        "CITYNAME": "Bondoukou",
        "CITYCODE": "BDK",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "BDK",
        "AIRPORTNAME": "Bondoukou",
    },
    {
        "CITYNAME": "Bandung",
        "CITYCODE": "BDO",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "BDO",
        "AIRPORTNAME": "Husein Sastranegara",
    },
    {
        "CITYNAME": "Vadodara",
        "CITYCODE": "BDQ",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "BDQ",
        "AIRPORTNAME": "Vadodara",
    },
    {
        "CITYNAME": "Bridgeport",
        "CITYCODE": "BDR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BDR",
        "AIRPORTNAME": "Igor I. Sikorsky Memorial",
    },
    {
        "CITYNAME": "Brindisi",
        "CITYCODE": "BDS",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "BDS",
        "AIRPORTNAME": "Papola Casale",
    },
    {
        "CITYNAME": "Gbadolite",
        "CITYCODE": "BDT",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "BDT",
        "AIRPORTNAME": "Gbadolite",
    },
    {
        "CITYNAME": "Bardufoss",
        "CITYCODE": "BDU",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "BDU",
        "AIRPORTNAME": "Bardufoss",
    },
    {
        "CITYNAME": "Benbecula",
        "CITYCODE": "BEB",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "BEB",
        "AIRPORTNAME": "Benbecula",
    },
    {
        "CITYNAME": "Bedford",
        "CITYCODE": "BED",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BED",
        "AIRPORTNAME": "Hanscom Field",
    },
    {
        "CITYNAME": "Bluefields",
        "CITYCODE": "BEF",
        "COUNTRYCODE": "NI",
        "COUNTRYNAME": "Nicaragua",
        "AIRPORTCODE": "BEF",
        "AIRPORTNAME": "Bluefields",
    },
    {
        "CITYNAME": "Belgrade",
        "CITYCODE": "BEG",
        "COUNTRYCODE": "RS",
        "COUNTRYNAME": "Serbia",
        "AIRPORTCODE": "BEG",
        "AIRPORTNAME": "Beograd",
    },
    {
        "CITYNAME": "Beica",
        "CITYCODE": "BEI",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "BEI",
        "AIRPORTNAME": "Beica",
    },
    {
        "CITYNAME": "Berau",
        "CITYCODE": "BEJ",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "BEJ",
        "AIRPORTNAME": "Berau",
    },
    {
        "CITYNAME": "Bareilly",
        "CITYCODE": "BEK",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "BEK",
        "AIRPORTNAME": "Bareilly Air Force Station",
    },
    {
        "CITYNAME": "Belem",
        "CITYCODE": "BEL",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "BEL",
        "AIRPORTNAME": "Val De Cans",
    },
    {
        "CITYNAME": "Bossembele",
        "CITYCODE": "BEM",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "BEM",
        "AIRPORTNAME": "Bossembele",
    },
    {
        "CITYNAME": "Benghazi",
        "CITYCODE": "BEN",
        "COUNTRYCODE": "LY",
        "COUNTRYNAME": "Libyan Arab Jamahiriya",
        "AIRPORTCODE": "BEN",
        "AIRPORTNAME": "Benina Int'l",
    },
    {
        "CITYNAME": "Bellary",
        "CITYCODE": "BEP",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "BEP",
        "AIRPORTNAME": "Bellary",
    },
    {
        "CITYNAME": "Berlin",
        "CITYCODE": "BER",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "BER",
        "AIRPORTNAME": "Berlin Metropolitan Area",
    },
    {
        "CITYNAME": "Berlin",
        "CITYCODE": "BER",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "QPP",
        "AIRPORTNAME": "Berlin Hauptbahnhof Airport",
    },
    {
        "CITYNAME": "Berlin",
        "CITYCODE": "BER",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "SXF",
        "AIRPORTNAME": "Schoenefeld",
    },
    {
        "CITYNAME": "Berlin",
        "CITYCODE": "BER",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "THF",
        "AIRPORTNAME": "Tempelhof",
    },
    {
        "CITYNAME": "Berlin",
        "CITYCODE": "BER",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "TXL",
        "AIRPORTNAME": "Tegel",
    },
    {
        "CITYNAME": "Brest",
        "CITYCODE": "BES",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "BES",
        "AIRPORTNAME": "Guipavas",
    },
    {
        "CITYNAME": "Bethel",
        "CITYCODE": "BET",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BET",
        "AIRPORTNAME": "Bethel",
    },
    {
        "CITYNAME": "Beer Sheba",
        "CITYCODE": "BEV",
        "COUNTRYCODE": "IL",
        "COUNTRYNAME": "Israel",
        "AIRPORTCODE": "ETM",
        "AIRPORTNAME": "Ramon International Airport",
    },
    {
        "CITYNAME": "Beira",
        "CITYCODE": "BEW",
        "COUNTRYCODE": "MZ",
        "COUNTRYNAME": "Mozambique",
        "AIRPORTCODE": "BEW",
        "AIRPORTNAME": "Beira",
    },
    {
        "CITYNAME": "Beirut",
        "CITYCODE": "BEY",
        "COUNTRYCODE": "LB",
        "COUNTRYNAME": "Lebanon",
        "AIRPORTCODE": "BEY",
        "AIRPORTNAME": "Beirut Int'l",
    },
    {
        "CITYNAME": "Beru",
        "CITYCODE": "BEZ",
        "COUNTRYCODE": "KI",
        "COUNTRYNAME": "Kiribati",
        "AIRPORTCODE": "BEZ",
        "AIRPORTNAME": "Beru",
    },
    {
        "CITYNAME": "Bloomfield",
        "CITYCODE": "BFC",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BFC",
        "AIRPORTNAME": "Bloomfield",
    },
    {
        "CITYNAME": "Bradford",
        "CITYCODE": "BFD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BFD",
        "AIRPORTNAME": "Bradford",
    },
    {
        "CITYNAME": "Scottsbluff",
        "CITYCODE": "BFF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BFF",
        "AIRPORTNAME": "Scotts Bluff County",
    },
    {
        "CITYNAME": "Georgetown",
        "CITYCODE": "BFI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BFI",
        "AIRPORTNAME": "King County Intl Airport",
    },
    {
        "CITYNAME": "Bakersfield",
        "CITYCODE": "BFL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BFL",
        "AIRPORTNAME": "Meadows Field",
    },
    {
        "CITYNAME": "Bloemfontein",
        "CITYCODE": "BFN",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "BFN",
        "AIRPORTNAME": " Bloemfontein",
    },
    {
        "CITYNAME": "Buffalo Range",
        "CITYCODE": "BFO",
        "COUNTRYCODE": "ZW",
        "COUNTRYNAME": "Zimbabwe",
        "AIRPORTCODE": "BFO",
        "AIRPORTNAME": "Buffalo Range",
    },
    {
        "CITYNAME": "Belfast",
        "CITYCODE": "BFS",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "BFS",
        "AIRPORTNAME": "Belfast Int'l",
    },
    {
        "CITYNAME": "Belfast",
        "CITYCODE": "BFS",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "BHD",
        "AIRPORTNAME": "Belfast City",
    },
    {
        "CITYNAME": "Buri Ram",
        "CITYCODE": "BFV",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "BFV",
        "AIRPORTNAME": "Buriram Airport",
    },
    {
        "CITYNAME": "Bafoussam",
        "CITYCODE": "BFX",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "BFX",
        "AIRPORTNAME": "Bafoussam",
    },
    {
        "CITYNAME": "Bucaramanga",
        "CITYCODE": "BGA",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "BGA",
        "AIRPORTNAME": "Bucaramanga",
    },
    {
        "CITYNAME": "Booue",
        "CITYCODE": "BGB",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "BGB",
        "AIRPORTNAME": "Booue",
    },
    {
        "CITYNAME": "Braganca",
        "CITYCODE": "BGC",
        "COUNTRYCODE": "PT",
        "COUNTRYNAME": "Portugal",
        "AIRPORTCODE": "BGC",
        "AIRPORTNAME": "Braganca",
    },
    {
        "CITYNAME": "Bangui",
        "CITYCODE": "BGF",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "BGF",
        "AIRPORTNAME": "Bangui",
    },
    {
        "CITYNAME": "Bongouanou",
        "CITYCODE": "BGG",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "BGG",
        "AIRPORTNAME": "Bingol Airport",
    },
    {
        "CITYNAME": "Boghe",
        "CITYCODE": "BGH",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "BGH",
        "AIRPORTNAME": "Abbaye",
    },
    {
        "CITYNAME": "Bridgetown",
        "CITYCODE": "BGI",
        "COUNTRYCODE": "BB",
        "COUNTRYNAME": "Barbados",
        "AIRPORTCODE": "BGI",
        "AIRPORTNAME": "Grantley Adams Int'l",
    },
    {
        "CITYNAME": "Borgarfjordur Eystri",
        "CITYCODE": "BGJ",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "BGJ",
        "AIRPORTNAME": "Borgarfjordur Eystri",
    },
    {
        "CITYNAME": "Big Creek",
        "CITYCODE": "BGK",
        "COUNTRYCODE": "BZ",
        "COUNTRYNAME": "Belize",
        "AIRPORTCODE": "BGK",
        "AIRPORTNAME": "Big Creek",
    },
    {
        "CITYNAME": "Binghamton",
        "CITYCODE": "BGM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BGM",
        "AIRPORTNAME": "Binghamton",
    },
    {
        "CITYNAME": "Bergen",
        "CITYCODE": "BGO",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "BGO",
        "AIRPORTNAME": "Flesland",
    },
    {
        "CITYNAME": "Bangor",
        "CITYCODE": "BGR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BGR",
        "AIRPORTNAME": "Bangor Internationa",
    },
    {
        "CITYNAME": "Bangassou",
        "CITYCODE": "BGU",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "BGU",
        "AIRPORTNAME": "Bangassou",
    },
    {
        "CITYNAME": "Baghdad",
        "CITYCODE": "BGW",
        "COUNTRYCODE": "IQ",
        "COUNTRYNAME": "Iraq",
        "AIRPORTCODE": "BGW",
        "AIRPORTNAME": "Baghdad International Airport",
    },
    {
        "CITYNAME": "Baghdad",
        "CITYCODE": "BGW",
        "COUNTRYCODE": "IQ",
        "COUNTRYNAME": "Iraq",
        "AIRPORTCODE": "SDA",
        "AIRPORTNAME": "Saddam Int'l",
    },
    {
        "CITYNAME": "Bahia De Caraquez",
        "CITYCODE": "BHA",
        "COUNTRYCODE": "EC",
        "COUNTRYNAME": "Ecuador",
        "AIRPORTCODE": "BHA",
        "AIRPORTNAME": "Bahia De Caraquez",
    },
    {
        "CITYNAME": "Bar Harbour",
        "CITYCODE": "BHB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BHB",
        "AIRPORTNAME": "Bar Harbour",
    },
    {
        "CITYNAME": "Bhurban",
        "CITYCODE": "BHC",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "BHC",
        "AIRPORTNAME": "Bhurban Heliport",
    },
    {
        "CITYNAME": "Blenheim",
        "CITYCODE": "BHE",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "BHE",
        "AIRPORTNAME": "Blenheim",
    },
    {
        "CITYNAME": "Brus Laguna",
        "CITYCODE": "BHG",
        "COUNTRYCODE": "HN",
        "COUNTRYNAME": "Honduras",
        "AIRPORTCODE": "BHG",
        "AIRPORTNAME": "Brus Laguna",
    },
    {
        "CITYNAME": "Bisha",
        "CITYCODE": "BHH",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "BHH",
        "AIRPORTNAME": "Bisha",
    },
    {
        "CITYNAME": "Bahia Blanca",
        "CITYCODE": "BHI",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "BHI",
        "AIRPORTNAME": "Comandante",
    },
    {
        "CITYNAME": "Bhuj",
        "CITYCODE": "BHJ",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "BHJ",
        "AIRPORTNAME": "Rudra Mata Airport",
    },
    {
        "CITYNAME": "Bukhara",
        "CITYCODE": "BHK",
        "COUNTRYCODE": "UZ",
        "COUNTRYNAME": "Uzbekistan",
        "AIRPORTCODE": "BHK",
        "AIRPORTNAME": "Bukhara",
    },
    {
        "CITYNAME": "Birmingham",
        "CITYCODE": "BHM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BHM",
        "AIRPORTNAME": "Birmingham",
    },
    {
        "CITYNAME": "Beihan",
        "CITYCODE": "BHN",
        "COUNTRYCODE": "YE",
        "COUNTRYNAME": "Yemen",
        "AIRPORTCODE": "BHN",
        "AIRPORTNAME": "Beihan",
    },
    {
        "CITYNAME": "Bhopal",
        "CITYCODE": "BHO",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "BHO",
        "AIRPORTNAME": "Raja Bhoj Airport",
    },
    {
        "CITYNAME": "Bhojpur",
        "CITYCODE": "BHP",
        "COUNTRYCODE": "NP",
        "COUNTRYNAME": "Nepal",
        "AIRPORTCODE": "BHP",
        "AIRPORTNAME": "BHOJPUR",
    },
    {
        "CITYNAME": "Broken Hill",
        "CITYCODE": "BHQ",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BHQ",
        "AIRPORTNAME": "Broken Hill",
    },
    {
        "CITYNAME": "Bharatpur",
        "CITYCODE": "BHR",
        "COUNTRYCODE": "NP",
        "COUNTRYNAME": "Nepal",
        "AIRPORTCODE": "BHR",
        "AIRPORTNAME": "Bharatpur Airport",
    },
    {
        "CITYNAME": "Bathurst",
        "CITYCODE": "BHS",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BHS",
        "AIRPORTNAME": "Raglan",
    },
    {
        "CITYNAME": "Bhavnagar",
        "CITYCODE": "BHU",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "BHU",
        "AIRPORTNAME": "Bhavnagar",
    },
    {
        "CITYNAME": "Birmingham",
        "CITYCODE": "BHX",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "BHX",
        "AIRPORTNAME": "Birmingham Int'l",
    },
    {
        "CITYNAME": "Beihai",
        "CITYCODE": "BHY",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "BHY",
        "AIRPORTNAME": "Beihai",
    },
    {
        "CITYNAME": "Belo Horizonte",
        "CITYCODE": "BHZ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CNF",
        "AIRPORTNAME": "Tancredo Neves Int'l",
    },
    {
        "CITYNAME": "Belo Horizonte",
        "CITYCODE": "BHZ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "PLU",
        "AIRPORTNAME": "Pampulha",
    },
    {
        "CITYNAME": "Bastia",
        "CITYCODE": "BIA",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "BIA",
        "AIRPORTNAME": "Poretta",
    },
    {
        "CITYNAME": "Block Island",
        "CITYCODE": "BID",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BID",
        "AIRPORTNAME": "Block Island",
    },
    {
        "CITYNAME": "Bishop",
        "CITYCODE": "BIH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BIH",
        "AIRPORTNAME": "Bishop",
    },
    {
        "CITYNAME": "Bikini Atoll",
        "CITYCODE": "BII",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "BII",
        "AIRPORTNAME": "Enyu Airfield",
    },
    {
        "CITYNAME": "Biak",
        "CITYCODE": "BIK",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "BIK",
        "AIRPORTNAME": "Mokmer",
    },
    {
        "CITYNAME": "Billings",
        "CITYCODE": "BIL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BIL",
        "AIRPORTNAME": "Billings",
    },
    {
        "CITYNAME": "Bimini",
        "CITYCODE": "BIM",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "BIM",
        "AIRPORTNAME": "Bimini Int'l",
    },
    {
        "CITYNAME": "Bimini",
        "CITYCODE": "BIM",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "NSB",
        "AIRPORTNAME": "North Sea Plan Base",
    },
    {
        "CITYNAME": "Bilbao",
        "CITYCODE": "BIO",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "BIO",
        "AIRPORTNAME": "Bilbao",
    },
    {
        "CITYNAME": "Biarritz",
        "CITYCODE": "BIQ",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "BIQ",
        "AIRPORTNAME": "Bayonne Anglet",
    },
    {
        "CITYNAME": "Biratnagar",
        "CITYCODE": "BIR",
        "COUNTRYCODE": "NP",
        "COUNTRYNAME": "Nepal",
        "AIRPORTCODE": "BIR",
        "AIRPORTNAME": "Biratnagar",
    },
    {
        "CITYNAME": "Bismarck",
        "CITYCODE": "BIS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BIS",
        "AIRPORTNAME": "Bismarck",
    },
    {
        "CITYNAME": "Bria",
        "CITYCODE": "BIV",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "BIV",
        "AIRPORTNAME": "Bria",
    },
    {
        "CITYNAME": "Bisho",
        "CITYCODE": "BIY",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "BIY",
        "AIRPORTNAME": "Bisho",
    },
    {
        "CITYNAME": "Bejaia",
        "CITYCODE": "BJA",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "BJA",
        "AIRPORTNAME": "Bejaia",
    },
    {
        "CITYNAME": "Bakkafjordur",
        "CITYCODE": "BJD",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "BJD",
        "AIRPORTNAME": "Bakkafjordur",
    },
    {
        "CITYNAME": "Batsfjord",
        "CITYCODE": "BJF",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "BJF",
        "AIRPORTNAME": "Batsfjord",
    },
    {
        "CITYNAME": "Bemidji",
        "CITYCODE": "BJI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BJI",
        "AIRPORTNAME": "Bemidji",
    },
    {
        "CITYNAME": "Banjul",
        "CITYCODE": "BJL",
        "COUNTRYCODE": "GM",
        "COUNTRYNAME": "Gambia",
        "AIRPORTCODE": "BJL",
        "AIRPORTNAME": "Yundum Int'l",
    },
    {
        "CITYNAME": "Bujumbura",
        "CITYCODE": "BJM",
        "COUNTRYCODE": "BI",
        "COUNTRYNAME": "Burundi",
        "AIRPORTCODE": "BJM",
        "AIRPORTNAME": "Bujumbura Int'l",
    },
    {
        "CITYNAME": "Bermejo",
        "CITYCODE": "BJO",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "BJO",
        "AIRPORTNAME": "Bermejo",
    },
    {
        "CITYNAME": "Bahar Dar",
        "CITYCODE": "BJR",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "BJR",
        "AIRPORTNAME": "Bahar Dar",
    },
    {
        "CITYNAME": "Beijing",
        "CITYCODE": "BJS",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "PEK",
        "AIRPORTNAME": "Beijing Capital",
    },
    {
        "CITYNAME": "Bajawa",
        "CITYCODE": "BJW",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "BJW",
        "AIRPORTNAME": "Bajawa",
    },
    {
        "CITYNAME": "Leon",
        "CITYCODE": "BJX",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "BJX",
        "AIRPORTNAME": "Guanajuato del Bajio",
    },
    {
        "CITYNAME": "Badajoz",
        "CITYCODE": "BJZ",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "BJZ",
        "AIRPORTNAME": "Badajoz Airport",
    },
    {
        "CITYNAME": "Bikaner",
        "CITYCODE": "BKB",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "BKB",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Buckland",
        "CITYCODE": "BKC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BKC",
        "AIRPORTNAME": "Buckland",
    },
    {
        "CITYNAME": "Branson",
        "CITYCODE": "BKG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BKG",
        "AIRPORTNAME": "Branson Airport",
    },
    {
        "CITYNAME": "Kota Kinabalu",
        "CITYCODE": "BKI",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "BKI",
        "AIRPORTNAME": "Kota Kinabalu",
    },
    {
        "CITYNAME": "Boke",
        "CITYCODE": "BKJ",
        "COUNTRYCODE": "GN",
        "COUNTRYNAME": "Guinea",
        "AIRPORTCODE": "BKJ",
        "AIRPORTNAME": "Boke",
    },
    {
        "CITYNAME": "Bangkok",
        "CITYCODE": "BKK",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "BKK",
        "AIRPORTNAME": "Bangkok Int'l",
    },
    {
        "CITYNAME": "Bakalalan",
        "CITYCODE": "BKM",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "BKM",
        "AIRPORTNAME": "Bakalalan",
    },
    {
        "CITYNAME": "Birni Nkoni",
        "CITYCODE": "BKN",
        "COUNTRYCODE": "NE",
        "COUNTRYNAME": "Niger",
        "AIRPORTCODE": "BKN",
        "AIRPORTNAME": "BKN",
    },
    {
        "CITYNAME": "Bamako",
        "CITYCODE": "BKO",
        "COUNTRYCODE": "ML",
        "COUNTRYNAME": "Mali",
        "AIRPORTCODE": "BKO",
        "AIRPORTNAME": "Bamako",
    },
    {
        "CITYNAME": "Blackall",
        "CITYCODE": "BKQ",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BKQ",
        "AIRPORTNAME": "Blackall",
    },
    {
        "CITYNAME": "Bokoro",
        "CITYCODE": "BKR",
        "COUNTRYCODE": "TD",
        "COUNTRYNAME": "Chad",
        "AIRPORTCODE": "BKR",
        "AIRPORTNAME": "Bokoro",
    },
    {
        "CITYNAME": "Bengkulu",
        "CITYCODE": "BKS",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "BKS",
        "AIRPORTNAME": "Padangkemiling",
    },
    {
        "CITYNAME": "Betioky",
        "CITYCODE": "BKU",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "BKU",
        "AIRPORTNAME": "Betioky",
    },
    {
        "CITYNAME": "Beckley",
        "CITYCODE": "BKW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BKW",
        "AIRPORTNAME": "Beckley",
    },
    {
        "CITYNAME": "Brookings",
        "CITYCODE": "BKX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BKX",
        "AIRPORTNAME": "Brookings",
    },
    {
        "CITYNAME": "Bukavu",
        "CITYCODE": "BKY",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "BKY",
        "AIRPORTNAME": "Kamenbe",
    },
    {
        "CITYNAME": "Bukoba",
        "CITYCODE": "BKZ",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "BKZ",
        "AIRPORTNAME": "Bukoba",
    },
    {
        "CITYNAME": "Barcelona",
        "CITYCODE": "BLA",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "BLA",
        "AIRPORTNAME": "Barcelonagen Ja A Anzoategui",
    },
    {
        "CITYNAME": "Balboa",
        "CITYCODE": "BLB",
        "COUNTRYCODE": "PA",
        "COUNTRYNAME": "Panama",
        "AIRPORTCODE": "BLB",
        "AIRPORTNAME": "Panama Pacifico",
    },
    {
        "CITYNAME": "Bali",
        "CITYCODE": "BLC",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "BLC",
        "AIRPORTNAME": "Bali",
    },
    {
        "CITYNAME": "Borlange",
        "CITYCODE": "BLE",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "BLE",
        "AIRPORTNAME": "Dala",
    },
    {
        "CITYNAME": "Bluefield",
        "CITYCODE": "BLF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BLF",
        "AIRPORTNAME": "Mercer County",
    },
    {
        "CITYNAME": "Belaga",
        "CITYCODE": "BLG",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "BLG",
        "AIRPORTNAME": "Belaga",
    },
    {
        "CITYNAME": "Bellingham",
        "CITYCODE": "BLI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BLI",
        "AIRPORTNAME": "Bellingham",
    },
    {
        "CITYNAME": "Batna",
        "CITYCODE": "BLJ",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "BLJ",
        "AIRPORTNAME": "Batna Airport",
    },
    {
        "CITYNAME": "Blackpool",
        "CITYCODE": "BLK",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "BLK",
        "AIRPORTNAME": "Blackpool",
    },
    {
        "CITYNAME": "Billund",
        "CITYCODE": "BLL",
        "COUNTRYCODE": "DK",
        "COUNTRYNAME": "Denmark",
        "AIRPORTCODE": "BLL",
        "AIRPORTNAME": "Billund",
    },
    {
        "CITYNAME": "Bologna",
        "CITYCODE": "BLQ",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "BLQ",
        "AIRPORTNAME": "Guglielmo Marconi",
    },
    {
        "CITYNAME": "Bangalore",
        "CITYCODE": "BLR",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "BLR",
        "AIRPORTNAME": "Kempegowda International Airport",
    },
    {
        "CITYNAME": "Blackwater",
        "CITYCODE": "BLT",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BLT",
        "AIRPORTNAME": "Blackwater",
    },
    {
        "CITYNAME": "Blantyre",
        "CITYCODE": "BLZ",
        "COUNTRYCODE": "MW",
        "COUNTRYNAME": "Malawi",
        "AIRPORTCODE": "BLZ",
        "AIRPORTNAME": "Chileka",
    },
    {
        "CITYNAME": "Bumba",
        "CITYCODE": "BMB",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "BMB",
        "AIRPORTNAME": "Bumba",
    },
    {
        "CITYNAME": "Belo",
        "CITYCODE": "BMD",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "BMD",
        "AIRPORTNAME": "Belo",
    },
    {
        "CITYNAME": "Broome",
        "CITYCODE": "BME",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BME",
        "AIRPORTNAME": "Broome",
    },
    {
        "CITYNAME": "Bakouma",
        "CITYCODE": "BMF",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "BMF",
        "AIRPORTNAME": "Bakouma",
    },
    {
        "CITYNAME": "Bloomington",
        "CITYCODE": "BMG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BMG",
        "AIRPORTNAME": "Bloomington",
    },
    {
        "CITYNAME": "Bloomington Normal",
        "CITYCODE": "BMI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BMI",
        "AIRPORTNAME": "Bloomington",
    },
    {
        "CITYNAME": "Borkum",
        "CITYCODE": "BMK",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "BMK",
        "AIRPORTNAME": "Borkum",
    },
    {
        "CITYNAME": "Bitam",
        "CITYCODE": "BMM",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "BMM",
        "AIRPORTNAME": "Bitam",
    },
    {
        "CITYNAME": "Bhamo",
        "CITYCODE": "BMO",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "BMO",
        "AIRPORTNAME": "Bhamo",
    },
    {
        "CITYNAME": "Brampton Island",
        "CITYCODE": "BMP",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BMP",
        "AIRPORTNAME": "Brampton Island",
    },
    {
        "CITYNAME": "Bamburi",
        "CITYCODE": "BMQ",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "BMQ",
        "AIRPORTNAME": "Bamburi",
    },
    {
        "CITYNAME": "Bima",
        "CITYCODE": "BMU",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "BMU",
        "AIRPORTNAME": "Bima",
    },
    {
        "CITYNAME": "Banmethuot",
        "CITYCODE": "BMV",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "BMV",
        "AIRPORTNAME": "Phung - Duc",
    },
    {
        "CITYNAME": "Bordj Badji Mokht",
        "CITYCODE": "BMW",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "BMW",
        "AIRPORTNAME": "Bordj Badji Mokht",
    },
    {
        "CITYNAME": "Belep Island",
        "CITYCODE": "BMY",
        "COUNTRYCODE": "NC",
        "COUNTRYNAME": "New Caledonia",
        "AIRPORTCODE": "BMY",
        "AIRPORTNAME": "Belep Island",
    },
    {
        "CITYNAME": "Nashville",
        "CITYCODE": "BNA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BNA",
        "AIRPORTNAME": "Nashville",
    },
    {
        "CITYNAME": "Boende",
        "CITYCODE": "BNB",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "BNB",
        "AIRPORTNAME": "Boende",
    },
    {
        "CITYNAME": "Beni",
        "CITYCODE": "BNC",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "BNC",
        "AIRPORTNAME": "Beni",
    },
    {
        "CITYNAME": "Bandar Abbas",
        "CITYCODE": "BND",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "BND",
        "AIRPORTNAME": "Bandar Abbas",
    },
    {
        "CITYNAME": "Brisbane",
        "CITYCODE": "BNE",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BNE",
        "AIRPORTNAME": "Brisbane Airport",
    },
    {
        "CITYNAME": "Benin City",
        "CITYCODE": "BNI",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "BNI",
        "AIRPORTNAME": "Benin City",
    },
    {
        "CITYNAME": "Ballina",
        "CITYCODE": "BNK",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BNK",
        "AIRPORTNAME": "Ballina",
    },
    {
        "CITYNAME": "Bronnoysund",
        "CITYCODE": "BNN",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "BNN",
        "AIRPORTNAME": "Bronnoy",
    },
    {
        "CITYNAME": "Banfora",
        "CITYCODE": "BNR",
        "COUNTRYCODE": "BF",
        "COUNTRYNAME": "Burkina Faso",
        "AIRPORTCODE": "BNR",
        "AIRPORTNAME": "Banfora",
    },
    {
        "CITYNAME": "Barinas",
        "CITYCODE": "BNS",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "BNS",
        "AIRPORTNAME": "Barinas",
    },
    {
        "CITYNAME": "Boone",
        "CITYCODE": "BNW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BNW",
        "AIRPORTNAME": "Boone Municipal Airport",
    },
    {
        "CITYNAME": "Banja Luka",
        "CITYCODE": "BNX",
        "COUNTRYCODE": "BA",
        "COUNTRYNAME": "Bosnia and Herzegowina",
        "AIRPORTCODE": "BNX",
        "AIRPORTNAME": "Banja Luka Intl Airport",
    },
    {
        "CITYNAME": "Bellona",
        "CITYCODE": "BNY",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "BNY",
        "AIRPORTNAME": "Bellona",
    },
    {
        "CITYNAME": "Boma",
        "CITYCODE": "BOA",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "BOA",
        "AIRPORTNAME": "BOMA",
    },
    {
        "CITYNAME": "Bora Bora",
        "CITYCODE": "BOB",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "BOB",
        "AIRPORTNAME": "Motu-Mute",
    },
    {
        "CITYNAME": "Bocas Del Toro",
        "CITYCODE": "BOC",
        "COUNTRYCODE": "PA",
        "COUNTRYNAME": "Panama",
        "AIRPORTCODE": "BOC",
        "AIRPORTNAME": "Bocas Del Toro",
    },
    {
        "CITYNAME": "Bordeaux",
        "CITYCODE": "BOD",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "BOD",
        "AIRPORTNAME": "Merignac",
    },
    {
        "CITYNAME": "Boundji",
        "CITYCODE": "BOE",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "BOE",
        "AIRPORTNAME": "Boundji",
    },
    {
        "CITYNAME": "Bogota",
        "CITYCODE": "BOG",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "BOG",
        "AIRPORTNAME": "Eldorado",
    },
    {
        "CITYNAME": "Bournemouth",
        "CITYCODE": "BOH",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "BOH",
        "AIRPORTNAME": "Bournemouth Int'l",
    },
    {
        "CITYNAME": "Boise",
        "CITYCODE": "BOI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BOI",
        "AIRPORTNAME": "Boise Air Term. (Gowen Field)",
    },
    {
        "CITYNAME": "Bourgas",
        "CITYCODE": "BOJ",
        "COUNTRYCODE": "BG",
        "COUNTRYNAME": "Bulgaria",
        "AIRPORTCODE": "BOJ",
        "AIRPORTNAME": "Bourgas",
    },
    {
        "CITYNAME": "Mumbai",
        "CITYCODE": "BOM",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "BOM",
        "AIRPORTNAME": "Chhatrapati Shivaji International Airport",
    },
    {
        "CITYNAME": "Bonaire",
        "CITYCODE": "BON",
        "COUNTRYCODE": "AN",
        "COUNTRYNAME": "Netherlands Antilles",
        "AIRPORTCODE": "BON",
        "AIRPORTNAME": "Flamingo Int'l",
    },
    {
        "CITYNAME": "Bodo",
        "CITYCODE": "BOO",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "BOO",
        "AIRPORTNAME": "Bodo",
    },
    {
        "CITYNAME": "Bouar",
        "CITYCODE": "BOP",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "BOP",
        "AIRPORTNAME": "Bouar",
    },
    {
        "CITYNAME": "Belfort",
        "CITYCODE": "BOR",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "BOR",
        "AIRPORTNAME": "Fontaine",
    },
    {
        "CITYNAME": "Boston",
        "CITYCODE": "BOS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BOS",
        "AIRPORTNAME": "Logan Int'l",
    },
    {
        "CITYNAME": "Borroloola",
        "CITYCODE": "BOX",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BOX",
        "AIRPORTNAME": "Borroloola",
    },
    {
        "CITYNAME": "Bobo Dioulasso",
        "CITYCODE": "BOY",
        "COUNTRYCODE": "BF",
        "COUNTRYNAME": "Burkina Faso",
        "AIRPORTCODE": "BOY",
        "AIRPORTNAME": "Borgo",
    },
    {
        "CITYNAME": "Bozoum",
        "CITYCODE": "BOZ",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "BOZ",
        "AIRPORTNAME": "Bozoum",
    },
    {
        "CITYNAME": "Bamenda",
        "CITYCODE": "BPC",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "BPC",
        "AIRPORTNAME": "Bamenda",
    },
    {
        "CITYNAME": "Barra Do Garcas",
        "CITYCODE": "BPG",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "BPG",
        "AIRPORTNAME": "Barra Do Garcas",
    },
    {
        "CITYNAME": "Hyderabad",
        "CITYCODE": "BPM",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "BPM",
        "AIRPORTNAME": "Begumpet Airport",
    },
    {
        "CITYNAME": "Balikpapan",
        "CITYCODE": "BPN",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "BPN",
        "AIRPORTNAME": "Sepingan",
    },
    {
        "CITYNAME": "Borongan",
        "CITYCODE": "BPR",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "BPR",
        "AIRPORTNAME": "Borongan Airport",
    },
    {
        "CITYNAME": "Porto Seguro",
        "CITYCODE": "BPS",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "BPS",
        "AIRPORTNAME": "Porto Seguro",
    },
    {
        "CITYNAME": "Beaumont",
        "CITYCODE": "BPT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BPT",
        "AIRPORTNAME": "Jefferson County",
    },
    {
        "CITYNAME": "Besalampy",
        "CITYCODE": "BPY",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "BPY",
        "AIRPORTNAME": "Besalampy",
    },
    {
        "CITYNAME": "Busselton",
        "CITYCODE": "BQB",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BQB",
        "AIRPORTNAME": "Busselton",
    },
    {
        "CITYNAME": "Quebec",
        "CITYCODE": "BQC",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "BQC",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Boulia",
        "CITYCODE": "BQL",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BQL",
        "AIRPORTNAME": "Boulia",
    },
    {
        "CITYNAME": "Aguadilla",
        "CITYCODE": "BQN",
        "COUNTRYCODE": "PR",
        "COUNTRYNAME": "Puerto Rico",
        "AIRPORTCODE": "BQN",
        "AIRPORTNAME": "Borinquen",
    },
    {
        "CITYNAME": "Bouna",
        "CITYCODE": "BQO",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "BQO",
        "AIRPORTNAME": "Bouna",
    },
    {
        "CITYNAME": "Brest",
        "CITYCODE": "BQT",
        "COUNTRYCODE": "BY",
        "COUNTRYNAME": "Belarus (Belorussia)",
        "AIRPORTCODE": "BQT",
        "AIRPORTNAME": "Brest",
    },
    {
        "CITYNAME": "Barreiras",
        "CITYCODE": "BRA",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "BRA",
        "AIRPORTNAME": "Barreiras",
    },
    {
        "CITYNAME": "Bariloche",
        "CITYCODE": "BRC",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "BRC",
        "AIRPORTNAME": "Bariloche Int'l",
    },
    {
        "CITYNAME": "Brainerd",
        "CITYCODE": "BRD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BRD",
        "AIRPORTNAME": "Crow Wing County",
    },
    {
        "CITYNAME": "Bremen",
        "CITYCODE": "BRE",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "BRE",
        "AIRPORTNAME": "Bremen",
    },
    {
        "CITYNAME": "Bremen",
        "CITYCODE": "BRE",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "DHC",
        "AIRPORTNAME": "Bremen Central railway station",
    },
    {
        "CITYNAME": "Whitesburg",
        "CITYCODE": "BRG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BRG",
        "AIRPORTNAME": "Whitesburg Municipal Airport",
    },
    {
        "CITYNAME": "Bari",
        "CITYCODE": "BRI",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "BRI",
        "AIRPORTNAME": "Palese",
    },
    {
        "CITYNAME": "Bourke",
        "CITYCODE": "BRK",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BRK",
        "AIRPORTNAME": "Bourke",
    },
    {
        "CITYNAME": "Burlington",
        "CITYCODE": "BRL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BRL",
        "AIRPORTNAME": "Burlington",
    },
    {
        "CITYNAME": "Barquisimeto",
        "CITYCODE": "BRM",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "BRM",
        "AIRPORTNAME": "Barquisimeto",
    },
    {
        "CITYNAME": "Berne",
        "CITYCODE": "BRN",
        "COUNTRYCODE": "CH",
        "COUNTRYNAME": "Switzerland",
        "AIRPORTCODE": "BRN",
        "AIRPORTNAME": "Belp",
    },
    {
        "CITYNAME": "Brownsville",
        "CITYCODE": "BRO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BRO",
        "AIRPORTNAME": "Bouth Padre Is. Int'l",
    },
    {
        "CITYNAME": "Brno",
        "CITYCODE": "BRQ",
        "COUNTRYCODE": "CZ",
        "COUNTRYNAME": "Czech Republic",
        "AIRPORTCODE": "BRQ",
        "AIRPORTNAME": "Turany",
    },
    {
        "CITYNAME": "Barra",
        "CITYCODE": "BRR",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "BRR",
        "AIRPORTNAME": "North Bay",
    },
    {
        "CITYNAME": "Bristol",
        "CITYCODE": "BRS",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "BRS",
        "AIRPORTNAME": "Bristol",
    },
    {
        "CITYNAME": "Bathurst Island",
        "CITYCODE": "BRT",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BRT",
        "AIRPORTNAME": "Bathurst Island",
    },
    {
        "CITYNAME": "Brussels",
        "CITYCODE": "BRU",
        "COUNTRYCODE": "BE",
        "COUNTRYNAME": "Belgium",
        "AIRPORTCODE": "BRU",
        "AIRPORTNAME": "Brussels National",
    },
    {
        "CITYNAME": "Brussels",
        "CITYCODE": "BRU",
        "COUNTRYCODE": "BE",
        "COUNTRYNAME": "Belgium",
        "AIRPORTCODE": "CRL",
        "AIRPORTNAME": "Brussels S. Charleroi",
    },
    {
        "CITYNAME": "Brussels",
        "CITYCODE": "BRU",
        "COUNTRYCODE": "BE",
        "COUNTRYNAME": "Belgium",
        "AIRPORTCODE": "ZYR",
        "AIRPORTNAME": "Brussels Midi Railway Station",
    },
    {
        "CITYNAME": "Bremerhaven",
        "CITYCODE": "BRV",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "BRV",
        "AIRPORTNAME": "Bremerhaven",
    },
    {
        "CITYNAME": "Barrow",
        "CITYCODE": "BRW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BRW",
        "AIRPORTNAME": "Will Rogers Memorial",
    },
    {
        "CITYNAME": "Bossaso",
        "CITYCODE": "BSA",
        "COUNTRYCODE": "SO",
        "COUNTRYNAME": "Somalia",
        "AIRPORTCODE": "BSA",
        "AIRPORTNAME": "Bossaso",
    },
    {
        "CITYNAME": "Brasilia",
        "CITYCODE": "BSB",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "BSB",
        "AIRPORTNAME": "Brasilia Int'l",
    },
    {
        "CITYNAME": "Bahia Solano",
        "CITYCODE": "BSC",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "BSC",
        "AIRPORTNAME": "Bahia Solano",
    },
    {
        "CITYNAME": "Bata",
        "CITYCODE": "BSG",
        "COUNTRYCODE": "GQ",
        "COUNTRYNAME": "Equatorial Guinea",
        "AIRPORTCODE": "BSG",
        "AIRPORTNAME": "Bata",
    },
    {
        "CITYNAME": "Brighton",
        "CITYCODE": "BSH",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "BSH",
        "AIRPORTNAME": "Brighton Airport",
    },
    {
        "CITYNAME": "Bairnsdale",
        "CITYCODE": "BSJ",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BSJ",
        "AIRPORTNAME": "Bairnsdale",
    },
    {
        "CITYNAME": "Biskra",
        "CITYCODE": "BSK",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "BSK",
        "AIRPORTNAME": "Biskra",
    },
    {
        "CITYNAME": "Bossangoa",
        "CITYCODE": "BSN",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "BSN",
        "AIRPORTNAME": "Bossangoa",
    },
    {
        "CITYNAME": "Basco",
        "CITYCODE": "BSO",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "BSO",
        "AIRPORTNAME": "Basco Airport",
    },
    {
        "CITYNAME": "Bensbach",
        "CITYCODE": "BSP",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "BSP",
        "AIRPORTNAME": "Bensbach",
    },
    {
        "CITYNAME": "Basra",
        "CITYCODE": "BSR",
        "COUNTRYCODE": "IQ",
        "COUNTRYNAME": "Iraq",
        "AIRPORTCODE": "BSR",
        "AIRPORTNAME": " BASRAH INTERNATIONAL",
    },
    {
        "CITYNAME": "Bost",
        "CITYCODE": "BST",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "BST",
        "AIRPORTNAME": "Bost",
    },
    {
        "CITYNAME": "Basankusu",
        "CITYCODE": "BSU",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "BSU",
        "AIRPORTNAME": "Basankusu",
    },
    {
        "CITYNAME": "Besakoa",
        "CITYCODE": "BSV",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "BSV",
        "AIRPORTNAME": "Besakoa",
    },
    {
        "CITYNAME": "Bertoua",
        "CITYCODE": "BTA",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "BTA",
        "AIRPORTNAME": "Bertoua",
    },
    {
        "CITYNAME": "Betou",
        "CITYCODE": "BTB",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "BTB",
        "AIRPORTNAME": "Betou",
    },
    {
        "CITYNAME": "Bonthe",
        "CITYCODE": "BTE",
        "COUNTRYCODE": "SL",
        "COUNTRYNAME": "Sierra Leone",
        "AIRPORTCODE": "BTE",
        "AIRPORTNAME": "Bonthe",
    },
    {
        "CITYNAME": "Batangafo",
        "CITYCODE": "BTG",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "BTG",
        "AIRPORTNAME": "Batangafo",
    },
    {
        "CITYNAME": "Batu Besar",
        "CITYCODE": "BTH",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "BTH",
        "AIRPORTNAME": "Hang Nadim",
    },
    {
        "CITYNAME": "Barter Island",
        "CITYCODE": "BTI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BTI",
        "AIRPORTNAME": "Barter Island",
    },
    {
        "CITYNAME": "Banda Aceh",
        "CITYCODE": "BTJ",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "BTJ",
        "AIRPORTNAME": "Blang Bintang",
    },
    {
        "CITYNAME": "Bratsk",
        "CITYCODE": "BTK",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "BTK",
        "AIRPORTNAME": "Bratsk",
    },
    {
        "CITYNAME": "Battle Creek",
        "CITYCODE": "BTL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BTL",
        "AIRPORTNAME": "Wk Kellogg Regional",
    },
    {
        "CITYNAME": "Butte",
        "CITYCODE": "BTM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BTM",
        "AIRPORTNAME": "Butte",
    },
    {
        "CITYNAME": "Baton Rouge",
        "CITYCODE": "BTR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BTR",
        "AIRPORTNAME": "Ryan",
    },
    {
        "CITYNAME": "Bratislava",
        "CITYCODE": "BTS",
        "COUNTRYCODE": "SK",
        "COUNTRYNAME": "Slovakia",
        "AIRPORTCODE": "BTS",
        "AIRPORTNAME": "Ivanka",
    },
    {
        "CITYNAME": "Bettles",
        "CITYCODE": "BTT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BTT",
        "AIRPORTNAME": "Bettles",
    },
    {
        "CITYNAME": "Bintulu",
        "CITYCODE": "BTU",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "BTU",
        "AIRPORTNAME": "Bintulu",
    },
    {
        "CITYNAME": "Burlington",
        "CITYCODE": "BTV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BTV",
        "AIRPORTNAME": "Burlington Int'l",
    },
    {
        "CITYNAME": "Bursa",
        "CITYCODE": "BTZ",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "BTZ",
        "AIRPORTNAME": " BURSA",
    },
    {
        "CITYNAME": "Buka",
        "CITYCODE": "BUA",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "BUA",
        "AIRPORTNAME": "Buka",
    },
    {
        "CITYNAME": "Burketown",
        "CITYCODE": "BUC",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BUC",
        "AIRPORTNAME": "Burketown",
    },
    {
        "CITYNAME": "Budapest",
        "CITYCODE": "BUD",
        "COUNTRYCODE": "HU",
        "COUNTRYNAME": "Hungary",
        "AIRPORTCODE": "BUD",
        "AIRPORTNAME": "Ferihegy",
    },
    {
        "CITYNAME": "Buenos Aires",
        "CITYCODE": "BUE",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "AEP",
        "AIRPORTNAME": "Jorge Newbery",
    },
    {
        "CITYNAME": "Buenos Aires",
        "CITYCODE": "BUE",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "EZE",
        "AIRPORTNAME": "Ministro Pistarini",
    },
    {
        "CITYNAME": "Buffalo",
        "CITYCODE": "BUF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BUF",
        "AIRPORTNAME": "Greater Buffalo Int'l",
    },
    {
        "CITYNAME": "Benguela",
        "CITYCODE": "BUG",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "BUG",
        "AIRPORTNAME": "Gen V Deslande",
    },
    {
        "CITYNAME": "Bucharest",
        "CITYCODE": "BUH",
        "COUNTRYCODE": "RO",
        "COUNTRYNAME": "Romania",
        "AIRPORTCODE": "BBU",
        "AIRPORTNAME": "Baneasa",
    },
    {
        "CITYNAME": "Bucharest",
        "CITYCODE": "BUH",
        "COUNTRYCODE": "RO",
        "COUNTRYNAME": "Romania",
        "AIRPORTCODE": "OTP",
        "AIRPORTNAME": "Otopeni Int'l",
    },
    {
        "CITYNAME": "Bokondini",
        "CITYCODE": "BUI",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "BUI",
        "AIRPORTNAME": "Bokondini",
    },
    {
        "CITYNAME": "Albuq",
        "CITYCODE": "BUK",
        "COUNTRYCODE": "YE",
        "COUNTRYNAME": "Yemen",
        "AIRPORTCODE": "BUK",
        "AIRPORTNAME": "Albuq",
    },
    {
        "CITYNAME": "Bulolo",
        "CITYCODE": "BUL",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "BUL",
        "AIRPORTNAME": "Bulolo",
    },
    {
        "CITYNAME": "Buenaventura",
        "CITYCODE": "BUN",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "BUN",
        "AIRPORTNAME": "Buenaventura",
    },
    {
        "CITYNAME": "Burao",
        "CITYCODE": "BUO",
        "COUNTRYCODE": "SO",
        "COUNTRYNAME": "Somalia",
        "AIRPORTCODE": "BUO",
        "AIRPORTNAME": "Burao",
    },
    {
        "CITYNAME": "Bhatinda",
        "CITYCODE": "BUP",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "BUP",
        "AIRPORTNAME": "Bhatinda",
    },
    {
        "CITYNAME": "Bulawayo",
        "CITYCODE": "BUQ",
        "COUNTRYCODE": "ZW",
        "COUNTRYNAME": "Zimbabwe",
        "AIRPORTCODE": "BUQ",
        "AIRPORTNAME": "Bulawayo",
    },
    {
        "CITYNAME": "Burbank",
        "CITYCODE": "BUR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BUR",
        "AIRPORTNAME": "Burbank",
    },
    {
        "CITYNAME": "Batumi",
        "CITYCODE": "BUS",
        "COUNTRYCODE": "GE",
        "COUNTRYNAME": "Georgia",
        "AIRPORTCODE": "BUS",
        "AIRPORTNAME": "Batumi",
    },
    {
        "CITYNAME": "Burtonwood",
        "CITYCODE": "BUT",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "BUT",
        "AIRPORTNAME": "Bathpalathang Airport",
    },
    {
        "CITYNAME": "Baubau",
        "CITYCODE": "BUW",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "BUW",
        "AIRPORTNAME": "Baubau",
    },
    {
        "CITYNAME": "Bunia",
        "CITYCODE": "BUX",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "BUX",
        "AIRPORTNAME": "Bunia",
    },
    {
        "CITYNAME": "Bushehr",
        "CITYCODE": "BUZ",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "BUZ",
        "AIRPORTNAME": "Bushehr",
    },
    {
        "CITYNAME": "Boa Vista",
        "CITYCODE": "BVB",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "BVB",
        "AIRPORTNAME": "Boa Vista",
    },
    {
        "CITYNAME": "Boa Vista",
        "CITYCODE": "BVC",
        "COUNTRYCODE": "CV",
        "COUNTRYNAME": "Cape Verde",
        "AIRPORTCODE": "BVC",
        "AIRPORTNAME": "Rabil",
    },
    {
        "CITYNAME": "Brive La Gaillard",
        "CITYCODE": "BVE",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "BVE",
        "AIRPORTNAME": "Laroche",
    },
    {
        "CITYNAME": "Berlevag",
        "CITYCODE": "BVG",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "BVG",
        "AIRPORTNAME": "Berlevag",
    },
    {
        "CITYNAME": "Vilhena",
        "CITYCODE": "BVH",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "BVH",
        "AIRPORTNAME": "Vilhena",
    },
    {
        "CITYNAME": "Birdsville",
        "CITYCODE": "BVI",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BVI",
        "AIRPORTNAME": "Birdsville",
    },
    {
        "CITYNAME": "Belmonte",
        "CITYCODE": "BVM",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "BVM",
        "AIRPORTNAME": "Belmonte",
    },
    {
        "CITYNAME": "Bhairawa",
        "CITYCODE": "BWA",
        "COUNTRYCODE": "NP",
        "COUNTRYNAME": "Nepal",
        "AIRPORTCODE": "BWA",
        "AIRPORTNAME": "BWA",
    },
    {
        "CITYNAME": "Brownwood",
        "CITYCODE": "BWD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BWD",
        "AIRPORTNAME": "Brownwood",
    },
    {
        "CITYNAME": "Braunschweig",
        "CITYCODE": "BWE",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "BWE",
        "AIRPORTNAME": "Braunschweig",
    },
    {
        "CITYNAME": "Bowling Green",
        "CITYCODE": "BWG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BWG",
        "AIRPORTNAME": "Bowling Green - Warren County Airport",
    },
    {
        "CITYNAME": "Bol",
        "CITYCODE": "BWK",
        "COUNTRYCODE": "HR",
        "COUNTRYNAME": "Croatia",
        "AIRPORTCODE": "BWK",
        "AIRPORTNAME": "Brac Airport",
    },
    {
        "CITYNAME": "Bandar Seri Begawan",
        "CITYCODE": "BWN",
        "COUNTRYCODE": "BN",
        "COUNTRYNAME": "Brunei Darussalam",
        "AIRPORTCODE": "BWN",
        "AIRPORTNAME": "Brunei Int'l",
    },
    {
        "CITYNAME": "Burnie",
        "CITYCODE": "BWT",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BWT",
        "AIRPORTNAME": "Burnie Wynyard",
    },
    {
        "CITYNAME": "Babo",
        "CITYCODE": "BXB",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "BXB",
        "AIRPORTNAME": "Babo",
    },
    {
        "CITYNAME": "Boxborough",
        "CITYCODE": "BXC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BXC",
        "AIRPORTNAME": "Boxborough",
    },
    {
        "CITYNAME": "Bade",
        "CITYCODE": "BXD",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "BXD",
        "AIRPORTNAME": "Bade",
    },
    {
        "CITYNAME": "Bakel",
        "CITYCODE": "BXE",
        "COUNTRYCODE": "SN",
        "COUNTRYNAME": "Senegal",
        "AIRPORTCODE": "BXE",
        "AIRPORTNAME": "Bakel",
    },
    {
        "CITYNAME": "Boundiali",
        "CITYCODE": "BXI",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "BXI",
        "AIRPORTNAME": "Boundiali",
    },
    {
        "CITYNAME": "Batom",
        "CITYCODE": "BXM",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "BXM",
        "AIRPORTNAME": "Batom",
    },
    {
        "CITYNAME": "Bodrum",
        "CITYCODE": "BXN",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "BJV",
        "AIRPORTNAME": "Milas",
    },
    {
        "CITYNAME": "Bodrum",
        "CITYCODE": "BXN",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "BXN",
        "AIRPORTNAME": "Bodrum Imsik Airport",
    },
    {
        "CITYNAME": "Butuan",
        "CITYCODE": "BXU",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "BXU",
        "AIRPORTNAME": "Butuan",
    },
    {
        "CITYNAME": "Breiddalsvik",
        "CITYCODE": "BXV",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "BXV",
        "AIRPORTNAME": "Breiddalsvik",
    },
    {
        "CITYNAME": "Dibaa",
        "CITYCODE": "BYB",
        "COUNTRYCODE": "OM",
        "COUNTRYNAME": "Oman",
        "AIRPORTCODE": "BYB",
        "AIRPORTNAME": "Dibaa",
    },
    {
        "CITYNAME": "Yacuiba",
        "CITYCODE": "BYC",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "BYC",
        "AIRPORTNAME": "Yacuiba",
    },
    {
        "CITYNAME": "Bouake",
        "CITYCODE": "BYK",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "BYK",
        "AIRPORTNAME": "Bouake",
    },
    {
        "CITYNAME": "Bayamo",
        "CITYCODE": "BYM",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "BYM",
        "AIRPORTNAME": "C.M. De Cespedes",
    },
    {
        "CITYNAME": "Bonito",
        "CITYCODE": "BYO",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "BYO",
        "AIRPORTNAME": "Bonito Airport",
    },
    {
        "CITYNAME": "Bayreuth",
        "CITYCODE": "BYU",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "BYU",
        "AIRPORTNAME": "Bindlacher-Berg",
    },
    {
        "CITYNAME": "Baniyala",
        "CITYCODE": "BYX",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BYX",
        "AIRPORTNAME": "Baniyala",
    },
    {
        "CITYNAME": "Belize City",
        "CITYCODE": "BZE",
        "COUNTRYCODE": "BZ",
        "COUNTRYNAME": "Belize",
        "AIRPORTCODE": "BZE",
        "AIRPORTNAME": "Philip S.W. Goldson Int'l",
    },
    {
        "CITYNAME": "Bydgoszcz",
        "CITYCODE": "BZG",
        "COUNTRYCODE": "PL",
        "COUNTRYNAME": "Poland",
        "AIRPORTCODE": "BZG",
        "AIRPORTNAME": "Bydgoszcz I. J. Paderewski Airport",
    },
    {
        "CITYNAME": "Barisal",
        "CITYCODE": "BZL",
        "COUNTRYCODE": "BD",
        "COUNTRYNAME": "Bangladesh",
        "AIRPORTCODE": "BZL",
        "AIRPORTNAME": "Barisal Airport",
    },
    {
        "CITYNAME": "Bergen Op Zoom",
        "CITYCODE": "BZM",
        "COUNTRYCODE": "NL",
        "COUNTRYNAME": "Netherlands",
        "AIRPORTCODE": "BZM",
        "AIRPORTNAME": "Woensdrecht",
    },
    {
        "CITYNAME": "Bozeman",
        "CITYCODE": "BZN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BZN",
        "AIRPORTNAME": "Galltin Field",
    },
    {
        "CITYNAME": "Bolzano",
        "CITYCODE": "BZO",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "BZO",
        "AIRPORTNAME": "Bolzano",
    },
    {
        "CITYNAME": "Beziers",
        "CITYCODE": "BZR",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "BZR",
        "AIRPORTNAME": "Beziers Vias",
    },
    {
        "CITYNAME": "Brazzaville",
        "CITYCODE": "BZV",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "BZV",
        "AIRPORTNAME": "Maya Maya",
    },
    {
        "CITYNAME": "Cabinda",
        "CITYCODE": "CAB",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "CAB",
        "AIRPORTNAME": "Cabinda",
    },
    {
        "CITYNAME": "Cascavel",
        "CITYCODE": "CAC",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CAC",
        "AIRPORTNAME": "Cascavel",
    },
    {
        "CITYNAME": "Columbia",
        "CITYCODE": "CAE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CAE",
        "AIRPORTNAME": "Columbia Metropolitan",
    },
    {
        "CITYNAME": "Cagliari",
        "CITYCODE": "CAG",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "CAG",
        "AIRPORTNAME": "Elmas",
    },
    {
        "CITYNAME": "Ca Mau",
        "CITYCODE": "CAH",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "CAH",
        "AIRPORTNAME": "CÃ  Mau Airport",
    },
    {
        "CITYNAME": "Cairo",
        "CITYCODE": "CAI",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "CAI",
        "AIRPORTNAME": "Cairo Int'l",
    },
    {
        "CITYNAME": "Cairo",
        "CITYCODE": "CAI",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "SPX",
        "AIRPORTNAME": "Sphinx Int'l",
    },
    {
        "CITYNAME": "Canaima",
        "CITYCODE": "CAJ",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "CAJ",
        "AIRPORTNAME": "Canaima",
    },
    {
        "CITYNAME": "Akron Canton",
        "CITYCODE": "CAK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CAK",
        "AIRPORTNAME": " Akron",
    },
    {
        "CITYNAME": "Campbeltown",
        "CITYCODE": "CAL",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "CAL",
        "AIRPORTNAME": "Machrihanish",
    },
    {
        "CITYNAME": "Camiri",
        "CITYCODE": "CAM",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "CAM",
        "AIRPORTNAME": "Camiri",
    },
    {
        "CITYNAME": "Guangzhou",
        "CITYCODE": "CAN",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "CAN",
        "AIRPORTNAME": "Baiyun",
    },
    {
        "CITYNAME": "Guangzhou",
        "CITYCODE": "CAN",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "PFT",
        "AIRPORTNAME": "Pazhou Ferry Terminal",
    },
    {
        "CITYNAME": "Cap Haitien",
        "CITYCODE": "CAP",
        "COUNTRYCODE": "HT",
        "COUNTRYNAME": "Haiti",
        "AIRPORTCODE": "CAP",
        "AIRPORTNAME": "Cap Haitien",
    },
    {
        "CITYNAME": "Caucasia",
        "CITYCODE": "CAQ",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "CAQ",
        "AIRPORTNAME": "Caucasia",
    },
    {
        "CITYNAME": "Casablanca",
        "CITYCODE": "CAS",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "CAS",
        "AIRPORTNAME": "Casablanca - Anfa",
    },
    {
        "CITYNAME": "Casablanca",
        "CITYCODE": "CAS",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "CMN",
        "AIRPORTNAME": "Mohamed V",
    },
    {
        "CITYNAME": "Cazombo",
        "CITYCODE": "CAV",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "CAV",
        "AIRPORTNAME": "Cazombo",
    },
    {
        "CITYNAME": "Campos",
        "CITYCODE": "CAW",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CAW",
        "AIRPORTNAME": "Bartolomeu Lisandro",
    },
    {
        "CITYNAME": "Carlisle",
        "CITYCODE": "CAX",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "CAX",
        "AIRPORTNAME": "Carlisle",
    },
    {
        "CITYNAME": "Cayenne",
        "CITYCODE": "CAY",
        "COUNTRYCODE": "GF",
        "COUNTRYNAME": "French Guiana",
        "AIRPORTCODE": "CAY",
        "AIRPORTNAME": "Rochambeau",
    },
    {
        "CITYNAME": "Cochabamba",
        "CITYCODE": "CBB",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "CBB",
        "AIRPORTNAME": "J Wilsterman",
    },
    {
        "CITYNAME": "Car Nicobar",
        "CITYCODE": "CBD",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "CBD",
        "AIRPORTNAME": "Car Nicobar",
    },
    {
        "CITYNAME": "Cumberland",
        "CITYCODE": "CBE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CBE",
        "AIRPORTNAME": " WILEY FORD",
    },
    {
        "CITYNAME": "Cambridge",
        "CITYCODE": "CBG",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "CBG",
        "AIRPORTNAME": "Cambridge",
    },
    {
        "CITYNAME": "Bechar",
        "CITYCODE": "CBH",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "CBH",
        "AIRPORTNAME": "Leger",
    },
    {
        "CITYNAME": "Ciudad Bolivar",
        "CITYCODE": "CBL",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "CBL",
        "AIRPORTNAME": "Ciudad Bolivar",
    },
    {
        "CITYNAME": "Cirebon",
        "CITYCODE": "CBN",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "CBN",
        "AIRPORTNAME": "Penggung",
    },
    {
        "CITYNAME": "Cotabato",
        "CITYCODE": "CBO",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "CBO",
        "AIRPORTNAME": "Awang",
    },
    {
        "CITYNAME": "Calabar",
        "CITYCODE": "CBQ",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "CBQ",
        "AIRPORTNAME": "Calabar",
    },
    {
        "CITYNAME": "Canberra",
        "CITYCODE": "CBR",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CBR",
        "AIRPORTNAME": "Canberra",
    },
    {
        "CITYNAME": "Catumbela",
        "CITYCODE": "CBT",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "CBT",
        "AIRPORTNAME": "Catumbela",
    },
    {
        "CITYNAME": "Condobolin",
        "CITYCODE": "CBX",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CBX",
        "AIRPORTNAME": "Condobolin",
    },
    {
        "CITYNAME": "Cayo Coco",
        "CITYCODE": "CCC",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "CCC",
        "AIRPORTNAME": "Cayo Coco Airport",
    },
    {
        "CITYNAME": "Carcassonne",
        "CITYCODE": "CCF",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "CCF",
        "AIRPORTNAME": "Salvaza",
    },
    {
        "CITYNAME": "Calicut",
        "CITYCODE": "CCJ",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "CCJ",
        "AIRPORTNAME": "Calicut",
    },
    {
        "CITYNAME": "Cocos Islands",
        "CITYCODE": "CCK",
        "COUNTRYCODE": "CC",
        "COUNTRYNAME": "Cocos (Keeling) Island",
        "AIRPORTCODE": "CCK",
        "AIRPORTNAME": "Cocos Islands",
    },
    {
        "CITYNAME": "Crisciuma",
        "CITYCODE": "CCM",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CCM",
        "AIRPORTNAME": "Crisciuma",
    },
    {
        "CITYNAME": "Chakcharan",
        "CITYCODE": "CCN",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "CCN",
        "AIRPORTNAME": "Chakcharan",
    },
    {
        "CITYNAME": "Carimagua",
        "CITYCODE": "CCO",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "CCO",
        "AIRPORTNAME": "Carimagua",
    },
    {
        "CITYNAME": "Concepcion",
        "CITYCODE": "CCP",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "CCP",
        "AIRPORTNAME": "Carriel Sur",
    },
    {
        "CITYNAME": "Concord",
        "CITYCODE": "CCR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CCR",
        "AIRPORTNAME": "Buchanan Field",
    },
    {
        "CITYNAME": "Caracas",
        "CITYCODE": "CCS",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "CCS",
        "AIRPORTNAME": "Simon Bolivar",
    },
    {
        "CITYNAME": "Colonia Catriel",
        "CITYCODE": "CCT",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "CCT",
        "AIRPORTNAME": "Colonia Catriel",
    },
    {
        "CITYNAME": "Kolkata",
        "CITYCODE": "CCU",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "CCU",
        "AIRPORTNAME": "Netaji Subhash Chandra Bose International Airport",
    },
    {
        "CITYNAME": "Craig Cove",
        "CITYCODE": "CCV",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "CCV",
        "AIRPORTNAME": "Craig Cove",
    },
    {
        "CITYNAME": "Chub Cay",
        "CITYCODE": "CCZ",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "CCZ",
        "AIRPORTNAME": "Chub Cay",
    },
    {
        "CITYNAME": "Cold Bay",
        "CITYCODE": "CDB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CDB",
        "AIRPORTNAME": "Cold Bay",
    },
    {
        "CITYNAME": "Cedar City",
        "CITYCODE": "CDC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CDC",
        "AIRPORTNAME": "Cedar City",
    },
    {
        "CITYNAME": "Conceicao Do Arag",
        "CITYCODE": "CDJ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CDJ",
        "AIRPORTNAME": "Conceicao Do Arag",
    },
    {
        "CITYNAME": "Cuddapah",
        "CITYCODE": "CDP",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "CDP",
        "AIRPORTNAME": "Cuddapah Airport",
    },
    {
        "CITYNAME": "Chadron",
        "CITYCODE": "CDR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CDR",
        "AIRPORTNAME": "Chadron",
    },
    {
        "CITYNAME": "Castellon de la Plana",
        "CITYCODE": "CDT",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "CDT",
        "AIRPORTNAME": "Castellon de la Plana",
    },
    {
        "CITYNAME": "Cordova",
        "CITYCODE": "CDV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CDV",
        "AIRPORTNAME": "Mudhole Smith",
    },
    {
        "CITYNAME": "Cebu",
        "CITYCODE": "CEB",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "CEB",
        "AIRPORTNAME": "Cebu",
    },
    {
        "CITYNAME": "Crescent City",
        "CITYCODE": "CEC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CEC",
        "AIRPORTNAME": "Mc Namara Field",
    },
    {
        "CITYNAME": "Ceduna",
        "CITYCODE": "CED",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CED",
        "AIRPORTNAME": "Ceduna",
    },
    {
        "CITYNAME": "Chiang Rai",
        "CITYCODE": "CEI",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "CEI",
        "AIRPORTNAME": "Chiang Rai",
    },
    {
        "CITYNAME": "Chelyabinsk",
        "CITYCODE": "CEK",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "CEK",
        "AIRPORTNAME": "Chelyabinsk",
    },
    {
        "CITYNAME": "Ciudad Obregon",
        "CITYCODE": "CEN",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "CEN",
        "AIRPORTNAME": "Ciudad Obregon",
    },
    {
        "CITYNAME": "Waco Kungo",
        "CITYCODE": "CEO",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "CEO",
        "AIRPORTNAME": "Waco Kungo",
    },
    {
        "CITYNAME": "Cannes",
        "CITYCODE": "CEQ",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "CEQ",
        "AIRPORTNAME": "Cannes - Mandelieu",
    },
    {
        "CITYNAME": "Cherbourg",
        "CITYCODE": "CER",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "CER",
        "AIRPORTNAME": "Maupertus",
    },
    {
        "CITYNAME": "Cessnock",
        "CITYCODE": "CES",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CES",
        "AIRPORTNAME": "Cessnock",
    },
    {
        "CITYNAME": "Cortez",
        "CITYCODE": "CEZ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CEZ",
        "AIRPORTNAME": "Montezuma County",
    },
    {
        "CITYNAME": "Cabo Frio",
        "CITYCODE": "CFB",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CFB",
        "AIRPORTNAME": "Cabo Frio",
    },
    {
        "CITYNAME": "Cacador",
        "CITYCODE": "CFC",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CFC",
        "AIRPORTNAME": "Cacador Airport",
    },
    {
        "CITYNAME": "Clermont Ferrand",
        "CITYCODE": "CFE",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "CFE",
        "AIRPORTNAME": "Aulnat",
    },
    {
        "CITYNAME": "Cienfuegos",
        "CITYCODE": "CFG",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "CFG",
        "AIRPORTNAME": "Jaime GonzÃÂ¡lez Airport",
    },
    {
        "CITYNAME": "chief",
        "CITYCODE": "CFK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CFK",
        "AIRPORTNAME": "Aboubakr Belkaid",
    },
    {
        "CITYNAME": "Donegal",
        "CITYCODE": "CFN",
        "COUNTRYCODE": "IE",
        "COUNTRYNAME": "Ireland",
        "AIRPORTCODE": "CFN",
        "AIRPORTNAME": "Donegal",
    },
    {
        "CITYNAME": "Confreza",
        "CITYCODE": "CFO",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CFO",
        "AIRPORTNAME": "Confreza",
    },
    {
        "CITYNAME": "Caen",
        "CITYCODE": "CFR",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "CFR",
        "AIRPORTNAME": "Carpiquet",
    },
    {
        "CITYNAME": "Coffs Harbour",
        "CITYCODE": "CFS",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CFS",
        "AIRPORTNAME": "Coffs Harbour",
    },
    {
        "CITYNAME": "Kerkyra",
        "CITYCODE": "CFU",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "CFU",
        "AIRPORTNAME": "Kerkyra",
    },
    {
        "CITYNAME": "Cuiaba",
        "CITYCODE": "CGB",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CGB",
        "AIRPORTNAME": "M. Rondon",
    },
    {
        "CITYNAME": "Changde",
        "CITYCODE": "CGD",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "CGD",
        "AIRPORTNAME": "Changde Taohuayuan Airport",
    },
    {
        "CITYNAME": "Cape Girardeau",
        "CITYCODE": "CGI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CGI",
        "AIRPORTNAME": "Cape Girardeau",
    },
    {
        "CITYNAME": "Camiguin",
        "CITYCODE": "CGM",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "CGM",
        "AIRPORTNAME": "Camiguin Airport",
    },
    {
        "CITYNAME": "Cologne",
        "CITYCODE": "CGN",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "CGN",
        "AIRPORTNAME": "Cologne",
    },
    {
        "CITYNAME": "Zhengzhou",
        "CITYCODE": "CGO",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "CGO",
        "AIRPORTNAME": "Zhengzhou",
    },
    {
        "CITYNAME": "Chittagong",
        "CITYCODE": "CGP",
        "COUNTRYCODE": "BD",
        "COUNTRYNAME": "Bangladesh",
        "AIRPORTCODE": "CGP",
        "AIRPORTNAME": "Patenga",
    },
    {
        "CITYNAME": "Changchun",
        "CITYCODE": "CGQ",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "CGQ",
        "AIRPORTNAME": "Changchun",
    },
    {
        "CITYNAME": "Campo Grande",
        "CITYCODE": "CGR",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CGR",
        "AIRPORTNAME": "Campo Grande Int'l",
    },
    {
        "CITYNAME": "College Park",
        "CITYCODE": "CGS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CGS",
        "AIRPORTNAME": "College Park",
    },
    {
        "CITYNAME": "Cagayan De Oro",
        "CITYCODE": "CGY",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "CGY",
        "AIRPORTNAME": "Lumbia",
    },
    {
        "CITYNAME": "Chattanooga",
        "CITYCODE": "CHA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CHA",
        "AIRPORTNAME": "Lovell Field",
    },
    {
        "CITYNAME": "Christchurch",
        "CITYCODE": "CHC",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "CHC",
        "AIRPORTNAME": "Christchurch",
    },
    {
        "CITYNAME": "Chandler",
        "CITYCODE": "CHD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SLJ",
        "AIRPORTNAME": "Stellar Air Park",
    },
    {
        "CITYNAME": "Chachapoyas",
        "CITYCODE": "CHH",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "CHH",
        "AIRPORTNAME": "Chachapoyas",
    },
    {
        "CITYNAME": "Chicago",
        "CITYCODE": "CHI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MDW",
        "AIRPORTNAME": "Midway",
    },
    {
        "CITYNAME": "Chicago",
        "CITYCODE": "CHI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ORD",
        "AIRPORTNAME": "OâHare Int'l",
    },
    {
        "CITYNAME": "Chimbote",
        "CITYCODE": "CHM",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "CHM",
        "AIRPORTNAME": "Chimbote",
    },
    {
        "CITYNAME": "Charlottesville",
        "CITYCODE": "CHO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CHO",
        "AIRPORTNAME": "Albemarle",
    },
    {
        "CITYNAME": "Chania",
        "CITYCODE": "CHQ",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "CHQ",
        "AIRPORTNAME": "Souda",
    },
    {
        "CITYNAME": "Chateauroux",
        "CITYCODE": "CHR",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "CHR",
        "AIRPORTNAME": "Chateauroux",
    },
    {
        "CITYNAME": "Charleston",
        "CITYCODE": "CHS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CHS",
        "AIRPORTNAME": "Charleston",
    },
    {
        "CITYNAME": "Chuathbaluk",
        "CITYCODE": "CHU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CHU",
        "AIRPORTNAME": "Chuathbaluk",
    },
    {
        "CITYNAME": "Changuinola",
        "CITYCODE": "CHX",
        "COUNTRYCODE": "PA",
        "COUNTRYNAME": "Panama",
        "AIRPORTCODE": "CHX",
        "AIRPORTNAME": "Changuinola",
    },
    {
        "CITYNAME": "Choiseul Bay",
        "CITYCODE": "CHY",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "CHY",
        "AIRPORTNAME": "Choiseul Bay",
    },
    {
        "CITYNAME": "Chico",
        "CITYCODE": "CIC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CIC",
        "AIRPORTNAME": "Chico",
    },
    {
        "CITYNAME": "Cedrrap Iowacty",
        "CITYCODE": "CID",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CID",
        "AIRPORTNAME": "Cedar Rapids",
    },
    {
        "CITYNAME": "Collie",
        "CITYCODE": "CIE",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CIE",
        "AIRPORTNAME": "Collie Airport",
    },
    {
        "CITYNAME": "Chifeng",
        "CITYCODE": "CIF",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "CIF",
        "AIRPORTNAME": "Chifeng",
    },
    {
        "CITYNAME": "Changzhi",
        "CITYCODE": "CIH",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "CIH",
        "AIRPORTNAME": "Changzhi",
    },
    {
        "CITYNAME": "Cobija",
        "CITYCODE": "CIJ",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "CIJ",
        "AIRPORTNAME": "E. Beltram",
    },
    {
        "CITYNAME": "Chalkyitsik",
        "CITYCODE": "CIK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CIK",
        "AIRPORTNAME": "Chalkyitsik",
    },
    {
        "CITYNAME": "Council",
        "CITYCODE": "CIL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CIL",
        "AIRPORTNAME": "Melsing Creek",
    },
    {
        "CITYNAME": "Cimitarra",
        "CITYCODE": "CIM",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "CIM",
        "AIRPORTNAME": "Cimitarra",
    },
    {
        "CITYNAME": "Chipata",
        "CITYCODE": "CIP",
        "COUNTRYCODE": "ZM",
        "COUNTRYNAME": "Zambia",
        "AIRPORTCODE": "CIP",
        "AIRPORTNAME": "Chipata",
    },
    {
        "CITYNAME": "Chimkent",
        "CITYCODE": "CIT",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "CIT",
        "AIRPORTNAME": "Chimkent",
    },
    {
        "CITYNAME": "Canouan Island",
        "CITYCODE": "CIW",
        "COUNTRYCODE": "VC",
        "COUNTRYNAME": "St. Vincent & the Grenadines",
        "AIRPORTCODE": "CIW",
        "AIRPORTNAME": "Canouan Island",
    },
    {
        "CITYNAME": "Chiclayo",
        "CITYCODE": "CIX",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "CIX",
        "AIRPORTNAME": "Cornel Ruiy",
    },
    {
        "CITYNAME": "Comiso",
        "CITYCODE": "CIY",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "CIY",
        "AIRPORTNAME": "Comiso Ragusa Airport",
    },
    {
        "CITYNAME": "Coari",
        "CITYCODE": "CIZ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CIZ",
        "AIRPORTNAME": "Coari Airport",
    },
    {
        "CITYNAME": "Cajamarca",
        "CITYCODE": "CJA",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "CJA",
        "AIRPORTNAME": "Cajamarca",
    },
    {
        "CITYNAME": "Coimbatore",
        "CITYCODE": "CJB",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "CJB",
        "AIRPORTNAME": "Coimbatore International Airport",
    },
    {
        "CITYNAME": "Calama",
        "CITYCODE": "CJC",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "CJC",
        "AIRPORTNAME": "Calama",
    },
    {
        "CITYNAME": "Candilejas",
        "CITYCODE": "CJD",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "CJD",
        "AIRPORTNAME": "Candilejas",
    },
    {
        "CITYNAME": "Cheong Ju",
        "CITYCODE": "CJJ",
        "COUNTRYCODE": "KR",
        "COUNTRYNAME": "South Korea",
        "AIRPORTCODE": "CJJ",
        "AIRPORTNAME": "Cheongju International Airport",
    },
    {
        "CITYNAME": "Chumphon",
        "CITYCODE": "CJM",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "CJM",
        "AIRPORTNAME": "Chumphon",
    },
    {
        "CITYNAME": "Ciudad Juarez",
        "CITYCODE": "CJS",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "CJS",
        "AIRPORTNAME": "Ciudad Juarez Intl",
    },
    {
        "CITYNAME": "Cheju",
        "CITYCODE": "CJU",
        "COUNTRYCODE": "KR",
        "COUNTRYNAME": "South Korea",
        "AIRPORTCODE": "CJU",
        "AIRPORTNAME": "Cheju",
    },
    {
        "CITYNAME": "Clarksburg",
        "CITYCODE": "CKB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CKB",
        "AIRPORTNAME": "Benedium",
    },
    {
        "CITYNAME": "Crooked Creek",
        "CITYCODE": "CKD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CKD",
        "AIRPORTNAME": "Crooked Creek",
    },
    {
        "CITYNAME": "Chongqing",
        "CITYCODE": "CKG",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "CKG",
        "AIRPORTNAME": "Chongqing",
    },
    {
        "CITYNAME": "Cherokee",
        "CITYCODE": "CKK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CKK",
        "AIRPORTNAME": "CHEROKEE",
    },
    {
        "CITYNAME": "Carajas",
        "CITYCODE": "CKS",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CKS",
        "AIRPORTNAME": "Carajas",
    },
    {
        "CITYNAME": "Conakry",
        "CITYCODE": "CKY",
        "COUNTRYCODE": "GN",
        "COUNTRYNAME": "Guinea",
        "AIRPORTCODE": "CKY",
        "AIRPORTNAME": "Conakry",
    },
    {
        "CITYNAME": "Canakkale",
        "CITYCODE": "CKZ",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "CKZ",
        "AIRPORTNAME": "Canakkale",
    },
    {
        "CITYNAME": "Carlsbad",
        "CITYCODE": "CLD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CLD",
        "AIRPORTNAME": "Carlsbad",
    },
    {
        "CITYNAME": "Cleveland",
        "CITYCODE": "CLE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BKL",
        "AIRPORTNAME": "Burke Lakefront",
    },
    {
        "CITYNAME": "Cleveland",
        "CITYCODE": "CLE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CGF",
        "AIRPORTNAME": "Cuyahoga County",
    },
    {
        "CITYNAME": "Cleveland",
        "CITYCODE": "CLE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CLE",
        "AIRPORTNAME": "Hopkins Int'l",
    },
    {
        "CITYNAME": "Cluj",
        "CITYCODE": "CLJ",
        "COUNTRYCODE": "RO",
        "COUNTRYNAME": "Romania",
        "AIRPORTCODE": "CLJ",
        "AIRPORTNAME": "Cluj",
    },
    {
        "CITYNAME": "College Station",
        "CITYCODE": "CLL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CLL",
        "AIRPORTNAME": "Easterwood Field",
    },
    {
        "CITYNAME": "Port Angeles",
        "CITYCODE": "CLM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CLM",
        "AIRPORTNAME": "Fairchild Int'l",
    },
    {
        "CITYNAME": "Cali",
        "CITYCODE": "CLO",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "CLO",
        "AIRPORTNAME": "Alfonso B. Aragon",
    },
    {
        "CITYNAME": "Clarks Point",
        "CITYCODE": "CLP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CLP",
        "AIRPORTNAME": "Clarks Point",
    },
    {
        "CITYNAME": "Colima",
        "CITYCODE": "CLQ",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "CLQ",
        "AIRPORTNAME": "Colima",
    },
    {
        "CITYNAME": "Charlotte",
        "CITYCODE": "CLT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CLT",
        "AIRPORTNAME": "Douglas",
    },
    {
        "CITYNAME": "Caldas Novas",
        "CITYCODE": "CLV",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CLV",
        "AIRPORTNAME": "Caldas Novas Airport",
    },
    {
        "CITYNAME": "Calvi",
        "CITYCODE": "CLY",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "CLY",
        "AIRPORTNAME": "Ste Catherine",
    },
    {
        "CITYNAME": "Cunnamulla",
        "CITYCODE": "CMA",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CMA",
        "AIRPORTNAME": "Camarillo Airport",
    },
    {
        "CITYNAME": "Colombo",
        "CITYCODE": "CMB",
        "COUNTRYCODE": "LK",
        "COUNTRYNAME": "Sri Lanka",
        "AIRPORTCODE": "CMB",
        "AIRPORTNAME": "Katunayake",
    },
    {
        "CITYNAME": "Cootamundra",
        "CITYCODE": "CMD",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CMD",
        "AIRPORTNAME": "Cootamundra Airport",
    },
    {
        "CITYNAME": "Ciudad Del Carmen",
        "CITYCODE": "CME",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "CME",
        "AIRPORTNAME": "Ciudad Del Carmen",
    },
    {
        "CITYNAME": "Chambery",
        "CITYCODE": "CMF",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "CMF",
        "AIRPORTNAME": "Chambery",
    },
    {
        "CITYNAME": "Corumba",
        "CITYCODE": "CMG",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CMG",
        "AIRPORTNAME": "Corumba Int'l",
    },
    {
        "CITYNAME": "Columbus",
        "CITYCODE": "CMH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CMH",
        "AIRPORTNAME": "Port Columbus Int'l",
    },
    {
        "CITYNAME": "Columbus",
        "CITYCODE": "CMH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LCK",
        "AIRPORTNAME": "Rickenbacker",
    },
    {
        "CITYNAME": "Columbus",
        "CITYCODE": "CMH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OSU",
        "AIRPORTNAME": "Ohio State University",
    },
    {
        "CITYNAME": "Champaign",
        "CITYCODE": "CMI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CMI",
        "AIRPORTNAME": "Willard University",
    },
    {
        "CITYNAME": "Chi Mei",
        "CITYCODE": "CMJ",
        "COUNTRYCODE": "TW",
        "COUNTRYNAME": "Taiwan",
        "AIRPORTCODE": "CMJ",
        "AIRPORTNAME": "Chi Mei",
    },
    {
        "CITYNAME": "Santana Do Aragua",
        "CITYCODE": "CMP",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CMP",
        "AIRPORTNAME": "Santana Do Aragua",
    },
    {
        "CITYNAME": "Clermont",
        "CITYCODE": "CMQ",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CMQ",
        "AIRPORTNAME": "Clermont",
    },
    {
        "CITYNAME": "Colmar",
        "CITYCODE": "CMR",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "CMR",
        "AIRPORTNAME": "Colmar-Houssen",
    },
    {
        "CITYNAME": "Kundiawa",
        "CITYCODE": "CMU",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "CMU",
        "AIRPORTNAME": "Chimbu",
    },
    {
        "CITYNAME": "Camaguey",
        "CITYCODE": "CMW",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "CMW",
        "AIRPORTNAME": "Ign Agramonte Int'l",
    },
    {
        "CITYNAME": "Hancock",
        "CITYCODE": "CMX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CMX",
        "AIRPORTNAME": "Houghton County",
    },
    {
        "CITYNAME": "Coconut Island",
        "CITYCODE": "CNC",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CNC",
        "AIRPORTNAME": "Coconut Island",
    },
    {
        "CITYNAME": "Constanta",
        "CITYCODE": "CND",
        "COUNTRYCODE": "RO",
        "COUNTRYNAME": "Romania",
        "AIRPORTCODE": "CND",
        "AIRPORTNAME": "Kogalniceanu",
    },
    {
        "CITYNAME": "Cloncurry",
        "CITYCODE": "CNJ",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CNJ",
        "AIRPORTNAME": "Cloncurry",
    },
    {
        "CITYNAME": "Carlsbad",
        "CITYCODE": "CNM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CNM",
        "AIRPORTNAME": "Carlsbad",
    },
    {
        "CITYNAME": "Kannur",
        "CITYCODE": "CNN",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "CNN",
        "AIRPORTNAME": "Kannur International Airport",
    },
    {
        "CITYNAME": "Chino",
        "CITYCODE": "CNO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CNO",
        "AIRPORTNAME": "Chino",
    },
    {
        "CITYNAME": "Corrientes",
        "CITYCODE": "CNQ",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "CNQ",
        "AIRPORTNAME": "Camba Punta",
    },
    {
        "CITYNAME": "Cairns",
        "CITYCODE": "CNS",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CNS",
        "AIRPORTNAME": "Cairns",
    },
    {
        "CITYNAME": "Chiang Mai",
        "CITYCODE": "CNX",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "CNX",
        "AIRPORTNAME": "Chiang Mai Int'l",
    },
    {
        "CITYNAME": "Moab",
        "CITYCODE": "CNY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CNY",
        "AIRPORTNAME": "Canyonlands Field",
    },
    {
        "CITYNAME": "Concordia",
        "CITYCODE": "COC",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "COC",
        "AIRPORTNAME": "Concordia",
    },
    {
        "CITYNAME": "Cody",
        "CITYCODE": "COD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "COD",
        "AIRPORTNAME": "Yellowstone Regional",
    },
    {
        "CITYNAME": "Coeur D'Alene",
        "CITYCODE": "COE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "COE",
        "AIRPORTNAME": "Coeur D'Alene",
    },
    {
        "CITYNAME": "Condoto",
        "CITYCODE": "COG",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "COG",
        "AIRPORTNAME": "Condoto",
    },
    {
        "CITYNAME": "Cooch Behar",
        "CITYCODE": "COH",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "COH",
        "AIRPORTNAME": "Cooch Behar",
    },
    {
        "CITYNAME": "Coonabarabran",
        "CITYCODE": "COJ",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "COJ",
        "AIRPORTNAME": "Coonabarabran",
    },
    {
        "CITYNAME": "Kochi",
        "CITYCODE": "COK",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "COK",
        "AIRPORTNAME": "Cochin International Airport",
    },
    {
        "CITYNAME": "Concord",
        "CITYCODE": "CON",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CON",
        "AIRPORTNAME": "Concord",
    },
    {
        "CITYNAME": "Cotonou",
        "CITYCODE": "COO",
        "COUNTRYCODE": "BJ",
        "COUNTRYNAME": "Benin",
        "AIRPORTCODE": "COO",
        "AIRPORTNAME": "Cotonou",
    },
    {
        "CITYNAME": "Cordoba",
        "CITYCODE": "COR",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "COR",
        "AIRPORTNAME": "Pajas Blancas",
    },
    {
        "CITYNAME": "Colorado Springs",
        "CITYCODE": "COS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "COS",
        "AIRPORTNAME": "Colorado Springs",
    },
    {
        "CITYNAME": "Columbia",
        "CITYCODE": "COU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "COU",
        "AIRPORTNAME": "Columbia Regional",
    },
    {
        "CITYNAME": "Cape Palmas",
        "CITYCODE": "CPA",
        "COUNTRYCODE": "LR",
        "COUNTRYNAME": "Liberia",
        "AIRPORTCODE": "CPA",
        "AIRPORTNAME": "A. Tubman",
    },
    {
        "CITYNAME": "Capurgana",
        "CITYCODE": "CPB",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "CPB",
        "AIRPORTNAME": "Capurgana",
    },
    {
        "CITYNAME": "San Martin de Los Andes",
        "CITYCODE": "CPC",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "CPC",
        "AIRPORTNAME": "San Martin de Los Andes",
    },
    {
        "CITYNAME": "Coober Pedy",
        "CITYCODE": "CPD",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CPD",
        "AIRPORTNAME": "Coober Pedy",
    },
    {
        "CITYNAME": "Campeche",
        "CITYCODE": "CPE",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "CPE",
        "AIRPORTNAME": "Campeche Int'l",
    },
    {
        "CITYNAME": "Copenhagen",
        "CITYCODE": "CPH",
        "COUNTRYCODE": "DK",
        "COUNTRYNAME": "Denmark",
        "AIRPORTCODE": "CPH",
        "AIRPORTNAME": "Copenhagen",
    },
    {
        "CITYNAME": "Copenhagen",
        "CITYCODE": "CPH",
        "COUNTRYCODE": "DK",
        "COUNTRYNAME": "Denmark",
        "AIRPORTCODE": "RKE",
        "AIRPORTNAME": "Roskilde",
    },
    {
        "CITYNAME": "Compton",
        "CITYCODE": "CPM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CPM",
        "AIRPORTNAME": "Compton",
    },
    {
        "CITYNAME": "Cape Rodney",
        "CITYCODE": "CPN",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "CPN",
        "AIRPORTNAME": "Cape Rodney",
    },
    {
        "CITYNAME": "Copiapo",
        "CITYCODE": "CPO",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "CPO",
        "AIRPORTNAME": "Chamonate",
    },
    {
        "CITYNAME": "Campinas",
        "CITYCODE": "CPQ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CPQ",
        "AIRPORTNAME": "Campinas Int'l",
    },
    {
        "CITYNAME": "Casper",
        "CITYCODE": "CPR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CPR",
        "AIRPORTNAME": "Casper",
    },
    {
        "CITYNAME": "Cape Town",
        "CITYCODE": "CPT",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "CPT",
        "AIRPORTNAME": "International",
    },
    {
        "CITYNAME": "Campina Grande",
        "CITYCODE": "CPV",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CPV",
        "AIRPORTNAME": "Joao Suassuna",
    },
    {
        "CITYNAME": "Culebra",
        "CITYCODE": "CPX",
        "COUNTRYCODE": "PR",
        "COUNTRYNAME": "Puerto Rico",
        "AIRPORTCODE": "CPX",
        "AIRPORTNAME": "Culebra",
    },
    {
        "CITYNAME": "Ciudad Real",
        "CITYCODE": "CQM",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "CQM",
        "AIRPORTNAME": "Ciudad Real",
    },
    {
        "CITYNAME": "Caquetania",
        "CITYCODE": "CQT",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "CQT",
        "AIRPORTNAME": "Caquetania",
    },
    {
        "CITYNAME": "Craiova",
        "CITYCODE": "CRA",
        "COUNTRYCODE": "RO",
        "COUNTRYNAME": "Romania",
        "AIRPORTCODE": "CRA",
        "AIRPORTNAME": "Craiova Airport",
    },
    {
        "CITYNAME": "Cdro  Rivadavia",
        "CITYCODE": "CRD",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "CRD",
        "AIRPORTNAME": "Cdro  Rivadavia",
    },
    {
        "CITYNAME": "Carnot",
        "CITYCODE": "CRF",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "CRF",
        "AIRPORTNAME": "Carnot",
    },
    {
        "CITYNAME": "Crooked Island",
        "CITYCODE": "CRI",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "CRI",
        "AIRPORTNAME": "Crooked Island",
    },
    {
        "CITYNAME": "Angeles City",
        "CITYCODE": "CRK",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "CRK",
        "AIRPORTNAME": "Clark International Airport",
    },
    {
        "CITYNAME": "Catarman",
        "CITYCODE": "CRM",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "CRM",
        "AIRPORTNAME": "Catarman National Airport",
    },
    {
        "CITYNAME": "Cromarty",
        "CITYCODE": "CRN",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "CRN",
        "AIRPORTNAME": "Cromarty Airport",
    },
    {
        "CITYNAME": "Corpus Christi",
        "CITYCODE": "CRP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CRP",
        "AIRPORTNAME": "Corpus Christi Int'l",
    },
    {
        "CITYNAME": "Caravelas",
        "CITYCODE": "CRQ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CRQ",
        "AIRPORTNAME": "Caravelas",
    },
    {
        "CITYNAME": "Carriacou Island",
        "CITYCODE": "CRU",
        "COUNTRYCODE": "GD",
        "COUNTRYNAME": "Grenada",
        "AIRPORTCODE": "CRU",
        "AIRPORTNAME": "Carriacou Is",
    },
    {
        "CITYNAME": "Crotone",
        "CITYCODE": "CRV",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "CRV",
        "AIRPORTNAME": "crotone airport",
    },
    {
        "CITYNAME": "Charleston",
        "CITYCODE": "CRW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CRW",
        "AIRPORTNAME": "Yeager",
    },
    {
        "CITYNAME": "Caransebes",
        "CITYCODE": "CSB",
        "COUNTRYCODE": "RO",
        "COUNTRYNAME": "Romania",
        "AIRPORTCODE": "CSB",
        "AIRPORTNAME": "Caransebes",
    },
    {
        "CITYNAME": "Columbus",
        "CITYCODE": "CSG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CSG",
        "AIRPORTNAME": "Columbus",
    },
    {
        "CITYNAME": "Cap Skirring",
        "CITYCODE": "CSK",
        "COUNTRYCODE": "SN",
        "COUNTRYNAME": "Senegal",
        "AIRPORTCODE": "CSK",
        "AIRPORTNAME": "Cap Skirring",
    },
    {
        "CITYNAME": "San Luis",
        "CITYCODE": "CSL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CSL",
        "AIRPORTNAME": "OâSullivan AAF",
    },
    {
        "CITYNAME": "San Luis",
        "CITYCODE": "CSL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SBP",
        "AIRPORTNAME": "San Luis County",
    },
    {
        "CITYNAME": "Carson City",
        "CITYCODE": "CSN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CSN",
        "AIRPORTNAME": "Carson City",
    },
    {
        "CITYNAME": "Cochstedt",
        "CITYCODE": "CSO",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "CSO",
        "AIRPORTNAME": "Cochstedt",
    },
    {
        "CITYNAME": "Castaway",
        "CITYCODE": "CST",
        "COUNTRYCODE": "FJ",
        "COUNTRYNAME": "Fiji Islands",
        "AIRPORTCODE": "CST",
        "AIRPORTNAME": "Castaway",
    },
    {
        "CITYNAME": "Changsha",
        "CITYCODE": "CSX",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "CSX",
        "AIRPORTNAME": "Changsha",
    },
    {
        "CITYNAME": "Cheboksary",
        "CITYCODE": "CSY",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "CSY",
        "AIRPORTNAME": "Cheboksary Airport",
    },
    {
        "CITYNAME": "Catania",
        "CITYCODE": "CTA",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "CTA",
        "AIRPORTNAME": "Fontanarossa",
    },
    {
        "CITYNAME": "Catamarca",
        "CITYCODE": "CTC",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "CTC",
        "AIRPORTNAME": "Catamarca",
    },
    {
        "CITYNAME": "Cartagena",
        "CITYCODE": "CTG",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "CTG",
        "AIRPORTNAME": "Rafael Nunez",
    },
    {
        "CITYNAME": "Charleville",
        "CITYCODE": "CTL",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CTL",
        "AIRPORTNAME": "Charleville",
    },
    {
        "CITYNAME": "Chetumal",
        "CITYCODE": "CTM",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "CTM",
        "AIRPORTNAME": "Chetumal",
    },
    {
        "CITYNAME": "Cooktown",
        "CITYCODE": "CTN",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CTN",
        "AIRPORTNAME": "Cooktown",
    },
    {
        "CITYNAME": "Chengdu",
        "CITYCODE": "CTU",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "CTU",
        "AIRPORTNAME": "Chengdu",
    },
    {
        "CITYNAME": "Chengdu",
        "CITYCODE": "CTU",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "TFU",
        "AIRPORTNAME": "Chengdu Tianfu",
    },
    {
        "CITYNAME": "Cucuta",
        "CITYCODE": "CUC",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "CUC",
        "AIRPORTNAME": "Cucuta",
    },
    {
        "CITYNAME": "Cuenca",
        "CITYCODE": "CUE",
        "COUNTRYCODE": "EC",
        "COUNTRYNAME": "Ecuador",
        "AIRPORTCODE": "CUE",
        "AIRPORTNAME": "Cuenca",
    },
    {
        "CITYNAME": "Cuneo",
        "CITYCODE": "CUF",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "CUF",
        "AIRPORTNAME": "Levaldige",
    },
    {
        "CITYNAME": "Culiacan",
        "CITYCODE": "CUL",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "CUL",
        "AIRPORTNAME": "Fedl De Bachigualato",
    },
    {
        "CITYNAME": "Cumana",
        "CITYCODE": "CUM",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "CUM",
        "AIRPORTNAME": "Cumana",
    },
    {
        "CITYNAME": "Cancun",
        "CITYCODE": "CUN",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "CUN",
        "AIRPORTNAME": "Cancun",
    },
    {
        "CITYNAME": "Carupano",
        "CITYCODE": "CUP",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "CUP",
        "AIRPORTNAME": "Carupano",
    },
    {
        "CITYNAME": "Coen",
        "CITYCODE": "CUQ",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CUQ",
        "AIRPORTNAME": "Coen",
    },
    {
        "CITYNAME": "Curacao",
        "CITYCODE": "CUR",
        "COUNTRYCODE": "AN",
        "COUNTRYNAME": "Netherlands Antilles",
        "AIRPORTCODE": "CUR",
        "AIRPORTNAME": "Aeropuerto Hato",
    },
    {
        "CITYNAME": "Cutral",
        "CITYCODE": "CUT",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "CUT",
        "AIRPORTNAME": "Cutral",
    },
    {
        "CITYNAME": "Chihuahua",
        "CITYCODE": "CUU",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "CUU",
        "AIRPORTNAME": "Gen Fierro Villalobos",
    },
    {
        "CITYNAME": "Cue",
        "CITYCODE": "CUY",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CUY",
        "AIRPORTNAME": "Cue",
    },
    {
        "CITYNAME": "Cuzco",
        "CITYCODE": "CUZ",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "CUZ",
        "AIRPORTNAME": "Velazco Astete",
    },
    {
        "CITYNAME": "Courchevel",
        "CITYCODE": "CVF",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "CVF",
        "AIRPORTNAME": "Courchevel",
    },
    {
        "CITYNAME": "Cincinnati",
        "CITYCODE": "CVG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CVG",
        "AIRPORTNAME": "Cincinnati/Northern Kentucky",
    },
    {
        "CITYNAME": "Cuernavaca",
        "CITYCODE": "CVJ",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "CVJ",
        "AIRPORTNAME": "General Mariano Matamoros Airport",
    },
    {
        "CITYNAME": "Cape Vogel",
        "CITYCODE": "CVL",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "CVL",
        "AIRPORTNAME": "Cape Vogel",
    },
    {
        "CITYNAME": "Ciudad Victoria",
        "CITYCODE": "CVM",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "CVM",
        "AIRPORTNAME": "Ciudad Victoria",
    },
    {
        "CITYNAME": "Clovis",
        "CITYCODE": "CVN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CVN",
        "AIRPORTNAME": "Clovis Municipal",
    },
    {
        "CITYNAME": "Carnarvon",
        "CITYCODE": "CVQ",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CVQ",
        "AIRPORTNAME": "Carnarvon",
    },
    {
        "CITYNAME": "Culver City",
        "CITYCODE": "CVR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CVR",
        "AIRPORTNAME": "Hughes",
    },
    {
        "CITYNAME": "Coventry",
        "CITYCODE": "CVT",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "CVT",
        "AIRPORTNAME": "Coventry West Midlands Intl Airport",
    },
    {
        "CITYNAME": "Curitiba",
        "CITYCODE": "CWB",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CWB",
        "AIRPORTNAME": "Afonso Pena",
    },
    {
        "CITYNAME": "Chernovtsy",
        "CITYCODE": "CWC",
        "COUNTRYCODE": "UA",
        "COUNTRYNAME": "Ukraine",
        "AIRPORTCODE": "CWC",
        "AIRPORTNAME": "Chernovtsy Airport",
    },
    {
        "CITYNAME": "Cardiff",
        "CITYCODE": "CWL",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "CFW",
        "AIRPORTNAME": "Cardiff Central Railway Station",
    },
    {
        "CITYNAME": "Cardiff",
        "CITYCODE": "CWL",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "CWL",
        "AIRPORTNAME": "Cardiff",
    },
    {
        "CITYNAME": "Cowra",
        "CITYCODE": "CWT",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CWT",
        "AIRPORTNAME": "Cowra",
    },
    {
        "CITYNAME": "Caicara De Oro",
        "CITYCODE": "CXA",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "CXA",
        "AIRPORTNAME": "Caicara De Oro",
    },
    {
        "CITYNAME": "Cox's Bazar",
        "CITYCODE": "CXB",
        "COUNTRYCODE": "BD",
        "COUNTRYNAME": "Bangladesh",
        "AIRPORTCODE": "CXB",
        "AIRPORTNAME": "Cox's Bazar",
    },
    {
        "CITYNAME": "Christmas Island",
        "CITYCODE": "CXI",
        "COUNTRYCODE": "KI",
        "COUNTRYNAME": "Kiribati",
        "AIRPORTCODE": "CXI",
        "AIRPORTNAME": "Christmas Island",
    },
    {
        "CITYNAME": "Caxias Do Sul",
        "CITYCODE": "CXJ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CXJ",
        "AIRPORTNAME": "Campo Dos Bugres",
    },
    {
        "CITYNAME": "Calexico",
        "CITYCODE": "CXL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CXL",
        "AIRPORTNAME": "Calexico Internatioanl",
    },
    {
        "CITYNAME": "Cilacap",
        "CITYCODE": "CXP",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "CXP",
        "AIRPORTNAME": "Tunggul Wulung",
    },
    {
        "CITYNAME": "Cam Ranh",
        "CITYCODE": "CXR",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "CXR",
        "AIRPORTNAME": "Cam Ranh International Airport",
    },
    {
        "CITYNAME": "Charters Towers",
        "CITYCODE": "CXT",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CXT",
        "AIRPORTNAME": "Charters Towers",
    },
    {
        "CITYNAME": "Cayman Brac Island",
        "CITYCODE": "CYB",
        "COUNTRYCODE": "KY",
        "COUNTRYNAME": "Cayman Islands",
        "AIRPORTCODE": "CYB",
        "AIRPORTNAME": "Gerrard-Smith",
    },
    {
        "CITYNAME": "Caye Chapel",
        "CITYCODE": "CYC",
        "COUNTRYCODE": "BZ",
        "COUNTRYNAME": "Belize",
        "AIRPORTCODE": "CYC",
        "AIRPORTNAME": "Caye Chapel",
    },
    {
        "CITYNAME": "Corryong",
        "CITYCODE": "CYG",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "CYG",
        "AIRPORTNAME": "Corryong",
    },
    {
        "CITYNAME": "Cayo Largo Del Sur",
        "CITYCODE": "CYO",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "CYO",
        "AIRPORTNAME": "Vilo Acuna Airport",
    },
    {
        "CITYNAME": "Calbayog",
        "CITYCODE": "CYP",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "CYP",
        "AIRPORTNAME": "Calbayog",
    },
    {
        "CITYNAME": "Colonia",
        "CITYCODE": "CYR",
        "COUNTRYCODE": "UY",
        "COUNTRYNAME": "Uruguay",
        "AIRPORTCODE": "CYR",
        "AIRPORTNAME": "Colonia",
    },
    {
        "CITYNAME": "Cheyenne",
        "CITYCODE": "CYS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CYS",
        "AIRPORTNAME": "Cheyenne",
    },
    {
        "CITYNAME": "Cauayan",
        "CITYCODE": "CYZ",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "CYZ",
        "AIRPORTNAME": "Cauayan Airport",
    },
    {
        "CITYNAME": "Coro",
        "CITYCODE": "CZE",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "CZE",
        "AIRPORTNAME": "Coro",
    },
    {
        "CITYNAME": "Corozal",
        "CITYCODE": "CZH",
        "COUNTRYCODE": "BZ",
        "COUNTRYNAME": "Belize",
        "AIRPORTCODE": "CZH",
        "AIRPORTNAME": "Corozal",
    },
    {
        "CITYNAME": "Constantine",
        "CITYCODE": "CZL",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "CZL",
        "AIRPORTNAME": "Ain El Bey",
    },
    {
        "CITYNAME": "Cozumel",
        "CITYCODE": "CZM",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "CZM",
        "AIRPORTNAME": "Cozumel",
    },
    {
        "CITYNAME": "Cruzeiro Do Sul",
        "CITYCODE": "CZS",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CZS",
        "AIRPORTNAME": "Campo Int'l",
    },
    {
        "CITYNAME": "Corozal",
        "CITYCODE": "CZU",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "CZU",
        "AIRPORTNAME": "Corozal",
    },
    {
        "CITYNAME": "Changzhou",
        "CITYCODE": "CZX",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "CZX",
        "AIRPORTNAME": "Changzhou",
    },
    {
        "CITYNAME": "Daytona Beach",
        "CITYCODE": "DAB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DAB",
        "AIRPORTNAME": "Daytona Beach Regional",
    },
    {
        "CITYNAME": "Dhaka",
        "CITYCODE": "DAC",
        "COUNTRYCODE": "BD",
        "COUNTRYNAME": "Bangladesh",
        "AIRPORTCODE": "DAC",
        "AIRPORTNAME": "Zia Int'l",
    },
    {
        "CITYNAME": "Da Nang",
        "CITYCODE": "DAD",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "DAD",
        "AIRPORTNAME": "Da Nang",
    },
    {
        "CITYNAME": "Damascus",
        "CITYCODE": "DAM",
        "COUNTRYCODE": "SY",
        "COUNTRYNAME": "Syrian Arab Republic",
        "AIRPORTCODE": "DAM",
        "AIRPORTNAME": "Damascus Int'l",
    },
    {
        "CITYNAME": "Danville",
        "CITYCODE": "DAN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DAN",
        "AIRPORTNAME": "Danville Municipal",
    },
    {
        "CITYNAME": "Dar Es Salaam",
        "CITYCODE": "DAR",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "DAR",
        "AIRPORTNAME": "Dar Es Salaam Int'l",
    },
    {
        "CITYNAME": "Daru",
        "CITYCODE": "DAU",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "DAU",
        "AIRPORTNAME": "Daru",
    },
    {
        "CITYNAME": "David",
        "CITYCODE": "DAV",
        "COUNTRYCODE": "PA",
        "COUNTRYNAME": "Panama",
        "AIRPORTCODE": "DAV",
        "AIRPORTNAME": "Enrique Malek",
    },
    {
        "CITYNAME": "Dayton",
        "CITYCODE": "DAY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DAY",
        "AIRPORTNAME": "James Cox Dayton Int'l",
    },
    {
        "CITYNAME": "Dayton",
        "CITYCODE": "DAY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MGY",
        "AIRPORTNAME": "Montgomery Co",
    },
    {
        "CITYNAME": "Dabaa",
        "CITYCODE": "DBB",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "DBB",
        "AIRPORTNAME": "Alalamain - Dabaa Intl Airport",
    },
    {
        "CITYNAME": "Dhanbad",
        "CITYCODE": "DBD",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "DBD",
        "AIRPORTNAME": "DHANBAD",
    },
    {
        "CITYNAME": "Debra Marcos",
        "CITYCODE": "DBM",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "DBM",
        "AIRPORTNAME": "Debra Marcos",
    },
    {
        "CITYNAME": "Dubbo",
        "CITYCODE": "DBO",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "DBO",
        "AIRPORTNAME": "Dubbo",
    },
    {
        "CITYNAME": "Debepare",
        "CITYCODE": "DBP",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "DBP",
        "AIRPORTNAME": "Debepare",
    },
    {
        "CITYNAME": "Dubuque",
        "CITYCODE": "DBQ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DBQ",
        "AIRPORTNAME": "Dubuque Municipal",
    },
    {
        "CITYNAME": "Darbhanga",
        "CITYCODE": "DBR",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "DBR",
        "AIRPORTNAME": "Darbhanga Airport",
    },
    {
        "CITYNAME": "Debra Tabor",
        "CITYCODE": "DBT",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "DBT",
        "AIRPORTNAME": "Debra Tabor",
    },
    {
        "CITYNAME": "Dubrovnik",
        "CITYCODE": "DBV",
        "COUNTRYCODE": "HR",
        "COUNTRYNAME": "Croatia",
        "AIRPORTCODE": "DBV",
        "AIRPORTNAME": "Dubrovnik",
    },
    {
        "CITYNAME": "Castres",
        "CITYCODE": "DCM",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "DCM",
        "AIRPORTNAME": "Castres - Mazamet",
    },
    {
        "CITYNAME": "Derby",
        "CITYCODE": "DCN",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "DCN",
        "AIRPORTNAME": "Derby Airport",
    },
    {
        "CITYNAME": "Dodge City",
        "CITYCODE": "DDC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DDC",
        "AIRPORTNAME": "Dodge City Municipal",
    },
    {
        "CITYNAME": "Daydream Is",
        "CITYCODE": "DDI",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "DDI",
        "AIRPORTNAME": "Daydream Is",
    },
    {
        "CITYNAME": "Dodoima",
        "CITYCODE": "DDM",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "DDM",
        "AIRPORTNAME": "Dodoima",
    },
    {
        "CITYNAME": "Delta Downs",
        "CITYCODE": "DDN",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "DDN",
        "AIRPORTNAME": "DELTA DOWNS",
    },
    {
        "CITYNAME": "Debrecen",
        "CITYCODE": "DEB",
        "COUNTRYCODE": "HU",
        "COUNTRYNAME": "Hungary",
        "AIRPORTCODE": "DEB",
        "AIRPORTNAME": "Debrecen",
    },
    {
        "CITYNAME": "Decatur",
        "CITYCODE": "DEC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DEC",
        "AIRPORTNAME": "Decatur",
    },
    {
        "CITYNAME": "Dehra Dun",
        "CITYCODE": "DED",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "DED",
        "AIRPORTNAME": "Jolly Grant Airport",
    },
    {
        "CITYNAME": "Decorah",
        "CITYCODE": "DEH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DEH",
        "AIRPORTNAME": "DECORAH MNPL",
    },
    {
        "CITYNAME": "Denis Island",
        "CITYCODE": "DEI",
        "COUNTRYCODE": "SC",
        "COUNTRYNAME": "Seychelles",
        "AIRPORTCODE": "DEI",
        "AIRPORTNAME": "Denis Island",
    },
    {
        "CITYNAME": "Delhi",
        "CITYCODE": "DEL",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "DEL",
        "AIRPORTNAME": "Indira Gandhi Airport",
    },
    {
        "CITYNAME": "Dembidollo",
        "CITYCODE": "DEM",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "DEM",
        "AIRPORTNAME": "Dembidollo",
    },
    {
        "CITYNAME": "Denver",
        "CITYCODE": "DEN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DEN",
        "AIRPORTNAME": "Denver Internationa",
    },
    {
        "CITYNAME": "Derim",
        "CITYCODE": "DER",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "DER",
        "AIRPORTNAME": "Derim",
    },
    {
        "CITYNAME": "Desroches",
        "CITYCODE": "DES",
        "COUNTRYCODE": "SC",
        "COUNTRYNAME": "Seychelles",
        "AIRPORTCODE": "DES",
        "AIRPORTNAME": "Desroches",
    },
    {
        "CITYNAME": "Deirezzor",
        "CITYCODE": "DEZ",
        "COUNTRYCODE": "SY",
        "COUNTRYNAME": "Syrian Arab Republic",
        "AIRPORTCODE": "DEZ",
        "AIRPORTNAME": "Deirezzor",
    },
    {
        "CITYNAME": "Dallas",
        "CITYCODE": "DFW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DAL",
        "AIRPORTNAME": "Love Field",
    },
    {
        "CITYNAME": "Dallas",
        "CITYCODE": "DFW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DFW",
        "AIRPORTNAME": "Ft Worth Int'l",
    },
    {
        "CITYNAME": "Dangriga",
        "CITYCODE": "DGA",
        "COUNTRYCODE": "BZ",
        "COUNTRYNAME": "Belize",
        "AIRPORTCODE": "DGA",
        "AIRPORTNAME": "Dangriga",
    },
    {
        "CITYNAME": "Mudgee",
        "CITYCODE": "DGE",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "DGE",
        "AIRPORTNAME": "Mudgee",
    },
    {
        "CITYNAME": "Deoghar",
        "CITYCODE": "DGH",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "DGH",
        "AIRPORTNAME": "Deoghar Airport",
    },
    {
        "CITYNAME": "Durango",
        "CITYCODE": "DGO",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "DGO",
        "AIRPORTNAME": "Guadalupe Victoria",
    },
    {
        "CITYNAME": "Dumaguete",
        "CITYCODE": "DGT",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "DGT",
        "AIRPORTNAME": "Dumaguete",
    },
    {
        "CITYNAME": "Dedougou",
        "CITYCODE": "DGU",
        "COUNTRYCODE": "BF",
        "COUNTRYNAME": "Burkina Faso",
        "AIRPORTCODE": "DGU",
        "AIRPORTNAME": "Dedougou",
    },
    {
        "CITYNAME": "Dhahran",
        "CITYCODE": "DHA",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "DHA",
        "AIRPORTNAME": "Dhahran",
    },
    {
        "CITYNAME": "Dhangarhi",
        "CITYCODE": "DHI",
        "COUNTRYCODE": "NP",
        "COUNTRYNAME": "Nepal",
        "AIRPORTCODE": "DHI",
        "AIRPORTNAME": "Dhangarhi Airport",
    },
    {
        "CITYNAME": "Dharamsala",
        "CITYCODE": "DHM",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "DHM",
        "AIRPORTNAME": "Gaggal",
    },
    {
        "CITYNAME": "Dothan",
        "CITYCODE": "DHN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DHN",
        "AIRPORTNAME": "Dothan",
    },
    {
        "CITYNAME": "Den Helder",
        "CITYCODE": "DHR",
        "COUNTRYCODE": "NL",
        "COUNTRYNAME": "Netherlands",
        "AIRPORTCODE": "DHR",
        "AIRPORTNAME": "Den Helder - De Kooy Airfield",
    },
    {
        "CITYNAME": "Dalhart",
        "CITYCODE": "DHT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DHT",
        "AIRPORTNAME": "Dalhart",
    },
    {
        "CITYNAME": "Dibrugarh",
        "CITYCODE": "DIB",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "DIB",
        "AIRPORTNAME": "Mohanbari",
    },
    {
        "CITYNAME": "Antsiranana",
        "CITYCODE": "DIE",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "DIE",
        "AIRPORTNAME": "Antsiranana/Arrachart",
    },
    {
        "CITYNAME": "Dijon",
        "CITYCODE": "DIJ",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "DIJ",
        "AIRPORTNAME": "Dijon",
    },
    {
        "CITYNAME": "Dickinson",
        "CITYCODE": "DIK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DIK",
        "AIRPORTNAME": "Dickinson Theodore Roosevelt Reg Airport",
    },
    {
        "CITYNAME": "Dili",
        "CITYCODE": "DIL",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "DIL",
        "AIRPORTNAME": "Comoro",
    },
    {
        "CITYNAME": "Dimbokro",
        "CITYCODE": "DIM",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "DIM",
        "AIRPORTNAME": "Dimbokro",
    },
    {
        "CITYNAME": "Diomede Island",
        "CITYCODE": "DIO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DIO",
        "AIRPORTNAME": "Diomede Island",
    },
    {
        "CITYNAME": "Divinopolis",
        "CITYCODE": "DIQ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "DIQ",
        "AIRPORTNAME": "Divinopolis Airport",
    },
    {
        "CITYNAME": "Dire Dawa",
        "CITYCODE": "DIR",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "DIR",
        "AIRPORTNAME": "Aba Tenna D Yilma",
    },
    {
        "CITYNAME": "Loubomo",
        "CITYCODE": "DIS",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "DIS",
        "AIRPORTNAME": "Loubomo",
    },
    {
        "CITYNAME": "Diu",
        "CITYCODE": "DIU",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "DIU",
        "AIRPORTNAME": "DIU",
    },
    {
        "CITYNAME": "Diyarbakir",
        "CITYCODE": "DIY",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "DIY",
        "AIRPORTNAME": "Diyarbakir",
    },
    {
        "CITYNAME": "Jambi",
        "CITYCODE": "DJB",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "DJB",
        "AIRPORTNAME": "Sultan Taha Syarifudn",
    },
    {
        "CITYNAME": "Djerba",
        "CITYCODE": "DJE",
        "COUNTRYCODE": "TN",
        "COUNTRYNAME": "Tunisia",
        "AIRPORTCODE": "DJE",
        "AIRPORTNAME": "Melita",
    },
    {
        "CITYNAME": "Djanet",
        "CITYCODE": "DJG",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "DJG",
        "AIRPORTNAME": "Inedbirenne",
    },
    {
        "CITYNAME": "Jayapura",
        "CITYCODE": "DJJ",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "DJJ",
        "AIRPORTNAME": "Sentani",
    },
    {
        "CITYNAME": "Djambala",
        "CITYCODE": "DJM",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "DJM",
        "AIRPORTNAME": "Djambala",
    },
    {
        "CITYNAME": "Delta Junction",
        "CITYCODE": "DJN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DJN",
        "AIRPORTNAME": "Delta Junction",
    },
    {
        "CITYNAME": "Daloa",
        "CITYCODE": "DJO",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "DJO",
        "AIRPORTNAME": "Daloa",
    },
    {
        "CITYNAME": "Dunk Island",
        "CITYCODE": "DKI",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "DKI",
        "AIRPORTNAME": "Dunk Island",
    },
    {
        "CITYNAME": "Dakar",
        "CITYCODE": "DKR",
        "COUNTRYCODE": "SN",
        "COUNTRYNAME": "Senegal",
        "AIRPORTCODE": "DKR",
        "AIRPORTNAME": "Yoff",
    },
    {
        "CITYNAME": "Dakar",
        "CITYCODE": "DKR",
        "COUNTRYCODE": "SN",
        "COUNTRYNAME": "Senegal",
        "AIRPORTCODE": "DSS",
        "AIRPORTNAME": "BLAISE DIAGNE INTL",
    },
    {
        "CITYNAME": "Douala",
        "CITYCODE": "DLA",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "DLA",
        "AIRPORTNAME": "Douala",
    },
    {
        "CITYNAME": "Dalian",
        "CITYCODE": "DLC",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "DLC",
        "AIRPORTNAME": "Dalian",
    },
    {
        "CITYNAME": "Dole",
        "CITYCODE": "DLE",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "DLE",
        "AIRPORTNAME": "Tavaux",
    },
    {
        "CITYNAME": "Dillingham",
        "CITYCODE": "DLG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DLG",
        "AIRPORTNAME": "Dillingham Municipal",
    },
    {
        "CITYNAME": "Duluth",
        "CITYCODE": "DLH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DLH",
        "AIRPORTNAME": "Duluth Int'l",
    },
    {
        "CITYNAME": "Dalat",
        "CITYCODE": "DLI",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "DLI",
        "AIRPORTNAME": "LIENKHANG",
    },
    {
        "CITYNAME": "Dalaman",
        "CITYCODE": "DLM",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "DLM",
        "AIRPORTNAME": "Dalaman",
    },
    {
        "CITYNAME": "Dolomi",
        "CITYCODE": "DLO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DLO",
        "AIRPORTNAME": "Dolomi",
    },
    {
        "CITYNAME": "Dillons Bay",
        "CITYCODE": "DLY",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "DLY",
        "AIRPORTNAME": "Dillons Bay",
    },
    {
        "CITYNAME": "Zhambyl",
        "CITYCODE": "DMB",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "DMB",
        "AIRPORTNAME": "Zhambyl",
    },
    {
        "CITYNAME": "Doomadgee",
        "CITYCODE": "DMD",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "DMD",
        "AIRPORTNAME": "Doomadgee",
    },
    {
        "CITYNAME": "Don Muang Airport",
        "CITYCODE": "DMK",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "DMK",
        "AIRPORTNAME": "Don Muang",
    },
    {
        "CITYNAME": "Dammam",
        "CITYCODE": "DMM",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "DMM",
        "AIRPORTNAME": "King Fahad Intl",
    },
    {
        "CITYNAME": "Dammam",
        "CITYCODE": "DMM",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "DMS",
        "AIRPORTNAME": "DAMMAM",
    },
    {
        "CITYNAME": "Dimapur",
        "CITYCODE": "DMU",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "DMU",
        "AIRPORTNAME": "Dimapur",
    },
    {
        "CITYNAME": "Dundee",
        "CITYCODE": "DND",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "DND",
        "AIRPORTNAME": "Dundee",
    },
    {
        "CITYNAME": "Dunhuang",
        "CITYCODE": "DNH",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "DNH",
        "AIRPORTNAME": "Dunhuang",
    },
    {
        "CITYNAME": "Dnepropetrovsk",
        "CITYCODE": "DNK",
        "COUNTRYCODE": "UA",
        "COUNTRYNAME": "Ukraine",
        "AIRPORTCODE": "DNK",
        "AIRPORTNAME": "Dnepropetrovsk",
    },
    {
        "CITYNAME": "Deniliquin",
        "CITYCODE": "DNQ",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "DNQ",
        "AIRPORTNAME": "Deniliquin",
    },
    {
        "CITYNAME": "Dinard",
        "CITYCODE": "DNR",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "DNR",
        "AIRPORTNAME": "Pleurtuit",
    },
    {
        "CITYNAME": "Denizli",
        "CITYCODE": "DNZ",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "DNZ",
        "AIRPORTNAME": "Cardak",
    },
    {
        "CITYNAME": "Doany",
        "CITYCODE": "DOA",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "DOA",
        "AIRPORTNAME": "Doany",
    },
    {
        "CITYNAME": "Dodoma",
        "CITYCODE": "DOD",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "DOD",
        "AIRPORTNAME": "Dodoma",
    },
    {
        "CITYNAME": "Dora Bay",
        "CITYCODE": "DOF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DOF",
        "AIRPORTNAME": "Dora Bay",
    },
    {
        "CITYNAME": "Dongola",
        "CITYCODE": "DOG",
        "COUNTRYCODE": "SD",
        "COUNTRYNAME": "Sudan",
        "AIRPORTCODE": "DOG",
        "AIRPORTNAME": "Dongola",
    },
    {
        "CITYNAME": "Doha",
        "CITYCODE": "DOH",
        "COUNTRYCODE": "QA",
        "COUNTRYNAME": "Qatar",
        "AIRPORTCODE": "DIA",
        "AIRPORTNAME": "Doha International Airport",
    },
    {
        "CITYNAME": "Doha",
        "CITYCODE": "DOH",
        "COUNTRYCODE": "QA",
        "COUNTRYNAME": "Qatar",
        "AIRPORTCODE": "DOH",
        "AIRPORTNAME": "Doha",
    },
    {
        "CITYNAME": "Donetsk",
        "CITYCODE": "DOK",
        "COUNTRYCODE": "UA",
        "COUNTRYNAME": "Ukraine",
        "AIRPORTCODE": "DOK",
        "AIRPORTNAME": "Donetsk",
    },
    {
        "CITYNAME": "Deauville",
        "CITYCODE": "DOL",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "DOL",
        "AIRPORTNAME": "St Gatien",
    },
    {
        "CITYNAME": "Dominica",
        "CITYCODE": "DOM",
        "COUNTRYCODE": "DM",
        "COUNTRYNAME": "Dominicana",
        "AIRPORTCODE": "DCF",
        "AIRPORTNAME": "Cane Field",
    },
    {
        "CITYNAME": "Dominica",
        "CITYCODE": "DOM",
        "COUNTRYCODE": "DM",
        "COUNTRYNAME": "Dominicana",
        "AIRPORTCODE": "DOM",
        "AIRPORTNAME": "Melville Hall",
    },
    {
        "CITYNAME": "Dori",
        "CITYCODE": "DOR",
        "COUNTRYCODE": "BF",
        "COUNTRYNAME": "Burkina Faso",
        "AIRPORTCODE": "DOR",
        "AIRPORTNAME": "Dori",
    },
    {
        "CITYNAME": "Dourados",
        "CITYCODE": "DOU",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "DOU",
        "AIRPORTNAME": "Dourados",
    },
    {
        "CITYNAME": "Dipolog",
        "CITYCODE": "DPL",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "DPL",
        "AIRPORTNAME": "Dipolog",
    },
    {
        "CITYNAME": "Devonport",
        "CITYCODE": "DPO",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "DPO",
        "AIRPORTNAME": "Devonport",
    },
    {
        "CITYNAME": "Denpasar Bali",
        "CITYCODE": "DPS",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "DPS",
        "AIRPORTNAME": "Ngurah Rai",
    },
    {
        "CITYNAME": "Duqm",
        "CITYCODE": "DQM",
        "COUNTRYCODE": "OM",
        "COUNTRYNAME": "Oman",
        "AIRPORTCODE": "DQM",
        "AIRPORTNAME": "Duqm International Airport",
    },
    {
        "CITYNAME": "Deering",
        "CITYCODE": "DRG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DRG",
        "AIRPORTNAME": "Deering",
    },
    {
        "CITYNAME": "Durango",
        "CITYCODE": "DRO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DRO",
        "AIRPORTNAME": "La Plata",
    },
    {
        "CITYNAME": "Dresden",
        "CITYCODE": "DRS",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "DRS",
        "AIRPORTNAME": "Dresden",
    },
    {
        "CITYNAME": "Darwin",
        "CITYCODE": "DRW",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "DRW",
        "AIRPORTNAME": "Darwin",
    },
    {
        "CITYNAME": "Doncaster/Shaffield",
        "CITYCODE": "DSA",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "DSA",
        "AIRPORTNAME": "Robin hood",
    },
    {
        "CITYNAME": "Dschang",
        "CITYCODE": "DSC",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "DSC",
        "AIRPORTNAME": "Dschang",
    },
    {
        "CITYNAME": "La Desirade",
        "CITYCODE": "DSD",
        "COUNTRYCODE": "GP",
        "COUNTRYNAME": "Guadeloupe",
        "AIRPORTCODE": "DSD",
        "AIRPORTNAME": "La Desirade",
    },
    {
        "CITYNAME": "Dessie",
        "CITYCODE": "DSE",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "DSE",
        "AIRPORTNAME": "Comobolcha",
    },
    {
        "CITYNAME": "Des Moines",
        "CITYCODE": "DSM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DSM",
        "AIRPORTNAME": "Des Moines",
    },
    {
        "CITYNAME": "Dongsheng",
        "CITYCODE": "DSN",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "DSN",
        "AIRPORTNAME": "Ordos Ejin Horo Airport",
    },
    {
        "CITYNAME": "Siborong-Borong",
        "CITYCODE": "DTB",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "DTB",
        "AIRPORTNAME": "Silangit Airport",
    },
    {
        "CITYNAME": "Datadawai",
        "CITYCODE": "DTD",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "DTD",
        "AIRPORTNAME": "Datadawai",
    },
    {
        "CITYNAME": "Diamantina",
        "CITYCODE": "DTI",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "DTI",
        "AIRPORTNAME": "Diamantina Airport",
    },
    {
        "CITYNAME": "Dortmund",
        "CITYCODE": "DTM",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "DTM",
        "AIRPORTNAME": "Dortmund",
    },
    {
        "CITYNAME": "Detroit",
        "CITYCODE": "DTT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DET",
        "AIRPORTNAME": "Detroit City",
    },
    {
        "CITYNAME": "Detroit",
        "CITYCODE": "DTT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DTW",
        "AIRPORTNAME": "Wayne County",
    },
    {
        "CITYNAME": "Detroit",
        "CITYCODE": "DTT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "YIP",
        "AIRPORTNAME": "Willow Run",
    },
    {
        "CITYNAME": "Dortmund",
        "CITYCODE": "DTZ",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "DTZ",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Dublin",
        "CITYCODE": "DUB",
        "COUNTRYCODE": "IE",
        "COUNTRYNAME": "Ireland",
        "AIRPORTCODE": "DUB",
        "AIRPORTNAME": "Dublin",
    },
    {
        "CITYNAME": "Dunedin",
        "CITYCODE": "DUD",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "DUD",
        "AIRPORTNAME": "Dunedin",
    },
    {
        "CITYNAME": "Dundo",
        "CITYCODE": "DUE",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "DUE",
        "AIRPORTNAME": "Dundo",
    },
    {
        "CITYNAME": "Dubois",
        "CITYCODE": "DUJ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DUJ",
        "AIRPORTNAME": "Jefferson County",
    },
    {
        "CITYNAME": "Dumai",
        "CITYCODE": "DUM",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "DUM",
        "AIRPORTNAME": "Pinang Kampai",
    },
    {
        "CITYNAME": "Durban",
        "CITYCODE": "DUR",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "DUR",
        "AIRPORTNAME": "International",
    },
    {
        "CITYNAME": "Durban",
        "CITYCODE": "DUR",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "VIR",
        "AIRPORTNAME": "Virginia",
    },
    {
        "CITYNAME": "Dusseldorf",
        "CITYCODE": "DUS",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "DUS",
        "AIRPORTNAME": "Dusseldorf",
    },
    {
        "CITYNAME": "Dusseldorf",
        "CITYCODE": "DUS",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "NRN",
        "AIRPORTNAME": "Weeze",
    },
    {
        "CITYNAME": "Dutch Harbor",
        "CITYCODE": "DUT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DUT",
        "AIRPORTNAME": "Unalaska Airport",
    },
    {
        "CITYNAME": "Davao",
        "CITYCODE": "DVO",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "DVO",
        "AIRPORTNAME": "Mati",
    },
    {
        "CITYNAME": "Dwangwa",
        "CITYCODE": "DWA",
        "COUNTRYCODE": "MW",
        "COUNTRYNAME": "Malawi",
        "AIRPORTCODE": "DWA",
        "AIRPORTNAME": "Dwangwa",
    },
    {
        "CITYNAME": "Soalala",
        "CITYCODE": "DWB",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "DWB",
        "AIRPORTNAME": "Soalala",
    },
    {
        "CITYNAME": "Dubai",
        "CITYCODE": "DXB",
        "COUNTRYCODE": "AE",
        "COUNTRYNAME": "United Arab Emirates",
        "AIRPORTCODE": "DWC",
        "AIRPORTNAME": "Al Maktoum",
    },
    {
        "CITYNAME": "Dubai",
        "CITYCODE": "DXB",
        "COUNTRYCODE": "AE",
        "COUNTRYNAME": "United Arab Emirates",
        "AIRPORTCODE": "DXB",
        "AIRPORTNAME": "Dubai",
    },
    {
        "CITYNAME": "Dubai",
        "CITYCODE": "DXB",
        "COUNTRYCODE": "AE",
        "COUNTRYNAME": "United Arab Emirates",
        "AIRPORTCODE": "XNB",
        "AIRPORTNAME": "Dubai Bus Station",
    },
    {
        "CITYNAME": "Dubai",
        "CITYCODE": "DXB",
        "COUNTRYCODE": "AE",
        "COUNTRYNAME": "United Arab Emirates",
        "AIRPORTCODE": "ZXZ",
        "AIRPORTNAME": "Jumeirah Beach",
    },
    {
        "CITYNAME": "Dysart",
        "CITYCODE": "DYA",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "DYA",
        "AIRPORTNAME": "Dysart",
    },
    {
        "CITYNAME": "Dayong",
        "CITYCODE": "DYG",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "DYG",
        "AIRPORTNAME": "Zhangjiajie Hehua Airport",
    },
    {
        "CITYNAME": "Dushanbe",
        "CITYCODE": "DYU",
        "COUNTRYCODE": "TJ",
        "COUNTRYNAME": "Tajikistan",
        "AIRPORTCODE": "DYU",
        "AIRPORTNAME": "Dushanbe",
    },
    {
        "CITYNAME": "Dzaoudzi",
        "CITYCODE": "DZA",
        "COUNTRYCODE": "YT",
        "COUNTRYNAME": "Mayotte",
        "AIRPORTCODE": "DZA",
        "AIRPORTNAME": "Dzaoudzi",
    },
    {
        "CITYNAME": "Zhezkazgan",
        "CITYCODE": "DZN",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "DZN",
        "AIRPORTNAME": "Zhezkazgan Airport",
    },
    {
        "CITYNAME": "Eagle",
        "CITYCODE": "EAA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EAA",
        "AIRPORTNAME": "Eagle",
    },
    {
        "CITYNAME": "Emae",
        "CITYCODE": "EAE",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "EAE",
        "AIRPORTNAME": "Emae",
    },
    {
        "CITYNAME": "Nejran",
        "CITYCODE": "EAM",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "EAM",
        "AIRPORTNAME": "Nejran Airport",
    },
    {
        "CITYNAME": "Basel Euroairport",
        "CITYCODE": "EAP",
        "COUNTRYCODE": "CH",
        "COUNTRYNAME": "Switzerland",
        "AIRPORTCODE": "BSL",
        "AIRPORTNAME": "Basel Euroairport",
    },
    {
        "CITYNAME": "Basel Euroairport",
        "CITYCODE": "EAP",
        "COUNTRYCODE": "CH",
        "COUNTRYNAME": "Switzerland",
        "AIRPORTCODE": "MLH",
        "AIRPORTNAME": "Mulhouse Euroairport",
    },
    {
        "CITYNAME": "Kearney",
        "CITYCODE": "EAR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EAR",
        "AIRPORTNAME": "Kearney",
    },
    {
        "CITYNAME": "San Sebastian",
        "CITYCODE": "EAS",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "EAS",
        "AIRPORTNAME": "San Sebastian",
    },
    {
        "CITYNAME": "Wenatchee",
        "CITYCODE": "EAT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EAT",
        "AIRPORTNAME": "Pangborn Field",
    },
    {
        "CITYNAME": "Eau Claire",
        "CITYCODE": "EAU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EAU",
        "AIRPORTNAME": "Eau Claire",
    },
    {
        "CITYNAME": "Elba Island",
        "CITYCODE": "EBA",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "EBA",
        "AIRPORTNAME": "Elba - Marina Di Campo Airport",
    },
    {
        "CITYNAME": "Entebbe",
        "CITYCODE": "EBB",
        "COUNTRYCODE": "UG",
        "COUNTRYNAME": "Uganda",
        "AIRPORTCODE": "EBB",
        "AIRPORTNAME": "Entebbe",
    },
    {
        "CITYNAME": "El Obeid",
        "CITYCODE": "EBD",
        "COUNTRYCODE": "SD",
        "COUNTRYNAME": "Sudan",
        "AIRPORTCODE": "EBD",
        "AIRPORTNAME": "El Obeid",
    },
    {
        "CITYNAME": "El Bagre",
        "CITYCODE": "EBG",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "EBG",
        "AIRPORTNAME": "El Bagre",
    },
    {
        "CITYNAME": "Esbjerg",
        "CITYCODE": "EBJ",
        "COUNTRYCODE": "DK",
        "COUNTRYNAME": "Denmark",
        "AIRPORTCODE": "EBJ",
        "AIRPORTNAME": "Esbjerg",
    },
    {
        "CITYNAME": "Erbil",
        "CITYCODE": "EBL",
        "COUNTRYCODE": "IQ",
        "COUNTRYNAME": "Iraq",
        "AIRPORTCODE": "EBL",
        "AIRPORTNAME": "Erbil International",
    },
    {
        "CITYNAME": "St Etienne",
        "CITYCODE": "EBU",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "EBU",
        "AIRPORTNAME": "Boutheon",
    },
    {
        "CITYNAME": "Ebolowa",
        "CITYCODE": "EBW",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "EBW",
        "AIRPORTNAME": "Ebolowa",
    },
    {
        "CITYNAME": "Elizabeth City",
        "CITYCODE": "ECG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ECG",
        "AIRPORTNAME": "Elizabeth City",
    },
    {
        "CITYNAME": "Ercan",
        "CITYCODE": "ECN",
        "COUNTRYCODE": "CY",
        "COUNTRYNAME": "Cyprus",
        "AIRPORTCODE": "ECN",
        "AIRPORTNAME": "Ercan",
    },
    {
        "CITYNAME": "Panama City Beach",
        "CITYCODE": "ECP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ECP",
        "AIRPORTNAME": "Northwest Florida Beaches Intl Airport",
    },
    {
        "CITYNAME": "Edna Bay",
        "CITYCODE": "EDA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EDA",
        "AIRPORTNAME": "Edna Bay",
    },
    {
        "CITYNAME": "Eldebba",
        "CITYCODE": "EDB",
        "COUNTRYCODE": "SD",
        "COUNTRYNAME": "Sudan",
        "AIRPORTCODE": "EDB",
        "AIRPORTNAME": "Eldebba",
    },
    {
        "CITYNAME": "Edinburgh",
        "CITYCODE": "EDI",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "EDI",
        "AIRPORTNAME": "Edinburgh",
    },
    {
        "CITYNAME": "El Dorado",
        "CITYCODE": "EDK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ELD",
        "AIRPORTNAME": "South Arkansas Regional Airport",
    },
    {
        "CITYNAME": "Eldoret",
        "CITYCODE": "EDL",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "EDL",
        "AIRPORTNAME": "Eldoret",
    },
    {
        "CITYNAME": "Edremit",
        "CITYCODE": "EDO",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "EDO",
        "AIRPORTNAME": "Edremit - Korfez",
    },
    {
        "CITYNAME": "Edward River",
        "CITYCODE": "EDR",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "EDR",
        "AIRPORTNAME": "Edward River",
    },
    {
        "CITYNAME": "Eek",
        "CITYCODE": "EEK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EEK",
        "AIRPORTNAME": "Eek",
    },
    {
        "CITYNAME": "Kefallinia",
        "CITYCODE": "EFL",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "EFL",
        "AIRPORTNAME": "Argostolion",
    },
    {
        "CITYNAME": "Bergerac",
        "CITYCODE": "EGC",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "EGC",
        "AIRPORTNAME": "Roumanieres",
    },
    {
        "CITYNAME": "Vail Eagle",
        "CITYCODE": "EGE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EGE",
        "AIRPORTNAME": "Eagle County",
    },
    {
        "CITYNAME": "Sege",
        "CITYCODE": "EGM",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "EGM",
        "AIRPORTNAME": "Sege",
    },
    {
        "CITYNAME": "Geneina",
        "CITYCODE": "EGN",
        "COUNTRYCODE": "SD",
        "COUNTRYNAME": "Sudan",
        "AIRPORTCODE": "EGN",
        "AIRPORTNAME": "Geneina",
    },
    {
        "CITYNAME": "Belgorod",
        "CITYCODE": "EGO",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "EGO",
        "AIRPORTNAME": "Belgorod",
    },
    {
        "CITYNAME": "Egilsstadir",
        "CITYCODE": "EGS",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "EGS",
        "AIRPORTNAME": "Egilsstadir",
    },
    {
        "CITYNAME": "Eagle River",
        "CITYCODE": "EGV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EGV",
        "AIRPORTNAME": "Eagle River",
    },
    {
        "CITYNAME": "Egegik",
        "CITYCODE": "EGX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EGX",
        "AIRPORTNAME": "Egegik",
    },
    {
        "CITYNAME": "El Bolson",
        "CITYCODE": "EHL",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "EHL",
        "AIRPORTNAME": "El Bolson",
    },
    {
        "CITYNAME": "Eia",
        "CITYCODE": "EIA",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "EIA",
        "AIRPORTNAME": "Popondetta",
    },
    {
        "CITYNAME": "Eindhoven",
        "CITYCODE": "EIN",
        "COUNTRYCODE": "NL",
        "COUNTRYNAME": "Netherlands",
        "AIRPORTCODE": "EIN",
        "AIRPORTNAME": "Eindhoven",
    },
    {
        "CITYNAME": "Beef Island",
        "CITYCODE": "EIS",
        "COUNTRYCODE": "VG",
        "COUNTRYNAME": "British Virgin Islands",
        "AIRPORTCODE": "EIS",
        "AIRPORTNAME": "Beef Island",
    },
    {
        "CITYNAME": "Barrancabermeja",
        "CITYCODE": "EJA",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "EJA",
        "AIRPORTNAME": "Variguies",
    },
    {
        "CITYNAME": "Wedjh",
        "CITYCODE": "EJH",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "EJH",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Elkins",
        "CITYCODE": "EKN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EKN",
        "AIRPORTNAME": "Elkins",
    },
    {
        "CITYNAME": "Elko",
        "CITYCODE": "EKO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EKO",
        "AIRPORTNAME": "Elko",
    },
    {
        "CITYNAME": "El Banco",
        "CITYCODE": "ELB",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "ELB",
        "AIRPORTNAME": "San Bernardo",
    },
    {
        "CITYNAME": "Elcho Island",
        "CITYCODE": "ELC",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "ELC",
        "AIRPORTNAME": "Elcho Island",
    },
    {
        "CITYNAME": "El Fasher",
        "CITYCODE": "ELF",
        "COUNTRYCODE": "SD",
        "COUNTRYNAME": "Sudan",
        "AIRPORTCODE": "ELF",
        "AIRPORTNAME": "El Fasher",
    },
    {
        "CITYNAME": "El Golea",
        "CITYCODE": "ELG",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "ELG",
        "AIRPORTNAME": "El Golea",
    },
    {
        "CITYNAME": "North Eleuthera",
        "CITYCODE": "ELH",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "ELH",
        "AIRPORTNAME": "North Eleuthera Int'l",
    },
    {
        "CITYNAME": "Elim",
        "CITYCODE": "ELI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ELI",
        "AIRPORTNAME": "Elim",
    },
    {
        "CITYNAME": "El Recreo",
        "CITYCODE": "ELJ",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "ELJ",
        "AIRPORTNAME": "El Recreo",
    },
    {
        "CITYNAME": "Ellisras",
        "CITYCODE": "ELL",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "ELL",
        "AIRPORTNAME": "Ellisras",
    },
    {
        "CITYNAME": "Elmira",
        "CITYCODE": "ELM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ELM",
        "AIRPORTNAME": "Elmira",
    },
    {
        "CITYNAME": "El Paso",
        "CITYCODE": "ELP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ELP",
        "AIRPORTNAME": "El Paso Int'l",
    },
    {
        "CITYNAME": "Gassim",
        "CITYCODE": "ELQ",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "ELQ",
        "AIRPORTNAME": "GASSIM",
    },
    {
        "CITYNAME": "East London",
        "CITYCODE": "ELS",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "ELS",
        "AIRPORTNAME": " East London",
    },
    {
        "CITYNAME": "Tour Sinai City",
        "CITYCODE": "ELT",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "ELT",
        "AIRPORTNAME": "Tour Sinai City",
    },
    {
        "CITYNAME": "El Oued",
        "CITYCODE": "ELU",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "ELU",
        "AIRPORTNAME": "Guermar",
    },
    {
        "CITYNAME": "Ely",
        "CITYCODE": "ELY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ELY",
        "AIRPORTNAME": "Yelland",
    },
    {
        "CITYNAME": "Emerald",
        "CITYCODE": "EMD",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "EMD",
        "AIRPORTNAME": "Emerald",
    },
    {
        "CITYNAME": "Emden",
        "CITYCODE": "EME",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "EME",
        "AIRPORTNAME": "Emden",
    },
    {
        "CITYNAME": "Emmonak",
        "CITYCODE": "EMK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EMK",
        "AIRPORTNAME": "Emmonak",
    },
    {
        "CITYNAME": "Nema",
        "CITYCODE": "EMN",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "EMN",
        "AIRPORTNAME": "Nema",
    },
    {
        "CITYNAME": "Emo",
        "CITYCODE": "EMO",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "EMO",
        "AIRPORTNAME": "Emo",
    },
    {
        "CITYNAME": "Embessa",
        "CITYCODE": "EMS",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "EMS",
        "AIRPORTNAME": "Embessa",
    },
    {
        "CITYNAME": "El Maiten",
        "CITYCODE": "EMX",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "EMX",
        "AIRPORTNAME": "El Maiten",
    },
    {
        "CITYNAME": "Kenai",
        "CITYCODE": "ENA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ENA",
        "AIRPORTNAME": "Kenai",
    },
    {
        "CITYNAME": "Ende",
        "CITYCODE": "ENE",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "ENE",
        "AIRPORTNAME": "Ende",
    },
    {
        "CITYNAME": "Enontekio",
        "CITYCODE": "ENF",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "ENF",
        "AIRPORTNAME": "Enontekio",
    },
    {
        "CITYNAME": "Enschede",
        "CITYCODE": "ENS",
        "COUNTRYCODE": "NL",
        "COUNTRYNAME": "Netherlands",
        "AIRPORTCODE": "ENS",
        "AIRPORTNAME": "Twente",
    },
    {
        "CITYNAME": "Enewetak Island",
        "CITYCODE": "ENT",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "ENT",
        "AIRPORTNAME": "Enewetak Island",
    },
    {
        "CITYNAME": "Enugu",
        "CITYCODE": "ENU",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "ENU",
        "AIRPORTNAME": "Enugu",
    },
    {
        "CITYNAME": "Eday",
        "CITYCODE": "EOI",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "EOI",
        "AIRPORTNAME": "Eday",
    },
    {
        "CITYNAME": "Epinal",
        "CITYCODE": "EPL",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "EPL",
        "AIRPORTNAME": "Mirecourt",
    },
    {
        "CITYNAME": "Esperance",
        "CITYCODE": "EPR",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "EPR",
        "AIRPORTNAME": "Esperance",
    },
    {
        "CITYNAME": "El Portillo",
        "CITYCODE": "EPS",
        "COUNTRYCODE": "DO",
        "COUNTRYNAME": "Dominican Republic",
        "AIRPORTCODE": "EPS",
        "AIRPORTNAME": "El Portillo Airport",
    },
    {
        "CITYNAME": "Parnu",
        "CITYCODE": "EPU",
        "COUNTRYCODE": "EE",
        "COUNTRYNAME": "Estonia",
        "AIRPORTCODE": "EPU",
        "AIRPORTNAME": "Parnu",
    },
    {
        "CITYNAME": "Esquel",
        "CITYCODE": "EQS",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "EQS",
        "AIRPORTNAME": "Esquel",
    },
    {
        "CITYNAME": "Erzincan",
        "CITYCODE": "ERC",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "ERC",
        "AIRPORTNAME": "Erzincan",
    },
    {
        "CITYNAME": "Erfurt",
        "CITYCODE": "ERF",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "ERF",
        "AIRPORTNAME": "Erfurt",
    },
    {
        "CITYNAME": "Errachidia",
        "CITYCODE": "ERH",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "ERH",
        "AIRPORTNAME": "Errachidia",
    },
    {
        "CITYNAME": "Erie",
        "CITYCODE": "ERI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ERI",
        "AIRPORTNAME": "Erie Int'l",
    },
    {
        "CITYNAME": "Erechim",
        "CITYCODE": "ERM",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "ERM",
        "AIRPORTNAME": "Erechim Airport",
    },
    {
        "CITYNAME": "Eirunepe",
        "CITYCODE": "ERN",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "ERN",
        "AIRPORTNAME": "EIRUNEPE",
    },
    {
        "CITYNAME": "Erzurum",
        "CITYCODE": "ERZ",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "ERZ",
        "AIRPORTNAME": "Erzurum",
    },
    {
        "CITYNAME": "Esa'ala",
        "CITYCODE": "ESA",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "ESA",
        "AIRPORTNAME": "Esa'ala",
    },
    {
        "CITYNAME": "Escanaba",
        "CITYCODE": "ESC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ESC",
        "AIRPORTNAME": "Delta County",
    },
    {
        "CITYNAME": "Eastsound",
        "CITYCODE": "ESD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ESD",
        "AIRPORTNAME": "Orcas Island",
    },
    {
        "CITYNAME": "Shoreham By Sea",
        "CITYCODE": "ESH",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "ESH",
        "AIRPORTNAME": "Shoreham",
    },
    {
        "CITYNAME": "Eskisehir",
        "CITYCODE": "ESK",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "ESK",
        "AIRPORTNAME": "Eskisehir Airport",
    },
    {
        "CITYNAME": "Esmeraldas",
        "CITYCODE": "ESM",
        "COUNTRYCODE": "EC",
        "COUNTRYNAME": "Ecuador",
        "AIRPORTCODE": "ESM",
        "AIRPORTNAME": "Esmeraldas",
    },
    {
        "CITYNAME": "East Stroudsburg",
        "CITYCODE": "ESP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ESP",
        "AIRPORTNAME": "East Stroudsburg",
    },
    {
        "CITYNAME": "El Salvador",
        "CITYCODE": "ESR",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "ESR",
        "AIRPORTNAME": "El Salvador",
    },
    {
        "CITYNAME": "Essen",
        "CITYCODE": "ESS",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "ESS",
        "AIRPORTNAME": "Essen",
    },
    {
        "CITYNAME": "Essaouira",
        "CITYCODE": "ESU",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "ESU",
        "AIRPORTNAME": "Essaouira",
    },
    {
        "CITYNAME": "Genda Wuha",
        "CITYCODE": "ETE",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "ETE",
        "AIRPORTNAME": "Genda Wuha",
    },
    {
        "CITYNAME": "Elat",
        "CITYCODE": "ETH",
        "COUNTRYCODE": "IL",
        "COUNTRYNAME": "Israel",
        "AIRPORTCODE": "ETH",
        "AIRPORTNAME": "Elat",
    },
    {
        "CITYNAME": "Metz Nancy",
        "CITYCODE": "ETZ",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "ETZ",
        "AIRPORTNAME": "Nancy Metz",
    },
    {
        "CITYNAME": "Eua",
        "CITYCODE": "EUA",
        "COUNTRYCODE": "TO",
        "COUNTRYNAME": "Tonga",
        "AIRPORTCODE": "EUA",
        "AIRPORTNAME": "Kaufana",
    },
    {
        "CITYNAME": "Eugene",
        "CITYCODE": "EUG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EUG",
        "AIRPORTNAME": "Eugene",
    },
    {
        "CITYNAME": "Laayoune",
        "CITYCODE": "EUN",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "EUN",
        "AIRPORTNAME": "Hassan I",
    },
    {
        "CITYNAME": "St Eustatius",
        "CITYCODE": "EUX",
        "COUNTRYCODE": "AN",
        "COUNTRYNAME": "Netherlands Antilles",
        "AIRPORTCODE": "EUX",
        "AIRPORTNAME": "F D Roosevelt",
    },
    {
        "CITYNAME": "Harstad-Narvik",
        "CITYCODE": "EVE",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "EVE",
        "AIRPORTNAME": "Evenes",
    },
    {
        "CITYNAME": "Sveg",
        "CITYCODE": "EVG",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "EVG",
        "AIRPORTNAME": "Sveg",
    },
    {
        "CITYNAME": "Yerevan",
        "CITYCODE": "EVN",
        "COUNTRYCODE": "AM",
        "COUNTRYNAME": "Armenia",
        "AIRPORTCODE": "EVN",
        "AIRPORTNAME": "Yerevan",
    },
    {
        "CITYNAME": "Evansville",
        "CITYCODE": "EVV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EVV",
        "AIRPORTNAME": "Dress Regional",
    },
    {
        "CITYNAME": "New Bedford",
        "CITYCODE": "EWB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EWB",
        "AIRPORTNAME": "New Bedford",
    },
    {
        "CITYNAME": "Enarotali",
        "CITYCODE": "EWI",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "EWI",
        "AIRPORTNAME": "Enarotali",
    },
    {
        "CITYNAME": "New Bern",
        "CITYCODE": "EWN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EWN",
        "AIRPORTNAME": "Simmons Nott",
    },
    {
        "CITYNAME": "Ewo",
        "CITYCODE": "EWO",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "EWO",
        "AIRPORTNAME": "Ewo",
    },
    {
        "CITYNAME": "Exeter",
        "CITYCODE": "EXS",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "EXS",
        "AIRPORTNAME": "St. Thomas Railway",
    },
    {
        "CITYNAME": "Exeter",
        "CITYCODE": "EXT",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "EXT",
        "AIRPORTNAME": "Exeter",
    },
    {
        "CITYNAME": "Yelimane",
        "CITYCODE": "EYL",
        "COUNTRYCODE": "ML",
        "COUNTRYNAME": "Mali",
        "AIRPORTCODE": "EYL",
        "AIRPORTNAME": "Yelimane",
    },
    {
        "CITYNAME": "El Yopal",
        "CITYCODE": "EYP",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "EYP",
        "AIRPORTNAME": "El Yopal",
    },
    {
        "CITYNAME": "Key West",
        "CITYCODE": "EYW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EYW",
        "AIRPORTNAME": "Key West Int'l",
    },
    {
        "CITYNAME": "Elazig",
        "CITYCODE": "EZS",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "EZS",
        "AIRPORTNAME": "Elazig",
    },
    {
        "CITYNAME": "Faranah",
        "CITYCODE": "FAA",
        "COUNTRYCODE": "GN",
        "COUNTRYNAME": "Guinea",
        "AIRPORTCODE": "FAA",
        "AIRPORTNAME": "Faranah",
    },
    {
        "CITYNAME": "Faroe Islands",
        "CITYCODE": "FAE",
        "COUNTRYCODE": "FO",
        "COUNTRYNAME": "Faroe Islands",
        "AIRPORTCODE": "FAE",
        "AIRPORTNAME": "Vagar",
    },
    {
        "CITYNAME": "Farah",
        "CITYCODE": "FAH",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "FAH",
        "AIRPORTNAME": "Farah",
    },
    {
        "CITYNAME": "Fairbanks",
        "CITYCODE": "FAI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FAI",
        "AIRPORTNAME": "Fairbanks Int'l",
    },
    {
        "CITYNAME": "Fajardo",
        "CITYCODE": "FAJ",
        "COUNTRYCODE": "PR",
        "COUNTRYNAME": "Puerto Rico",
        "AIRPORTCODE": "FAJ",
        "AIRPORTNAME": "Fajardo",
    },
    {
        "CITYNAME": "Faro",
        "CITYCODE": "FAO",
        "COUNTRYCODE": "PT",
        "COUNTRYNAME": "Portugal",
        "AIRPORTCODE": "FAO",
        "AIRPORTNAME": "Faro",
    },
    {
        "CITYNAME": "Fargo",
        "CITYCODE": "FAR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FAR",
        "AIRPORTNAME": "Hector Field",
    },
    {
        "CITYNAME": "Fresno",
        "CITYCODE": "FAT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FAT",
        "AIRPORTNAME": "Fresno Airterminal",
    },
    {
        "CITYNAME": "Fakarava",
        "CITYCODE": "FAV",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "FAV",
        "AIRPORTNAME": "Fakarava",
    },
    {
        "CITYNAME": "Fayetteville",
        "CITYCODE": "FAY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FAY",
        "AIRPORTNAME": "Fayetteville Municipal",
    },
    {
        "CITYNAME": "Fonte Boa",
        "CITYCODE": "FBA",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "FBA",
        "AIRPORTNAME": "Fonte Boa Airport",
    },
    {
        "CITYNAME": "Faizabad",
        "CITYCODE": "FBD",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "FBD",
        "AIRPORTNAME": "Faizabad",
    },
    {
        "CITYNAME": "Francisco Beltrao",
        "CITYCODE": "FBE",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "FBE",
        "AIRPORTNAME": "Francisco Beltrao Airport",
    },
    {
        "CITYNAME": "Lubumbashi",
        "CITYCODE": "FBM",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "FBM",
        "AIRPORTNAME": "Luano",
    },
    {
        "CITYNAME": "Kalispell",
        "CITYCODE": "FCA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FCA",
        "AIRPORTNAME": "Glacier National Park",
    },
    {
        "CITYNAME": "Cuxhaven",
        "CITYCODE": "FCN",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "FCN",
        "AIRPORTNAME": "Cuxhaven Nordholz Airport",
    },
    {
        "CITYNAME": "Forde",
        "CITYCODE": "FDE",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "FDE",
        "AIRPORTNAME": "Bringeland",
    },
    {
        "CITYNAME": "Fort De France",
        "CITYCODE": "FDF",
        "COUNTRYCODE": "MQ",
        "COUNTRYNAME": "Martinique",
        "AIRPORTCODE": "FDF",
        "AIRPORTNAME": "Lamentin",
    },
    {
        "CITYNAME": "Friedrichshafen",
        "CITYCODE": "FDH",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "FDH",
        "AIRPORTNAME": "Friedrichshafen",
    },
    {
        "CITYNAME": "Bandundu",
        "CITYCODE": "FDU",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "FDU",
        "AIRPORTNAME": "Bandundu",
    },
    {
        "CITYNAME": "Feira De Santana",
        "CITYCODE": "FEC",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "FEC",
        "AIRPORTNAME": "Feira de Santana",
    },
    {
        "CITYNAME": "Fergana",
        "CITYCODE": "FEG",
        "COUNTRYCODE": "UZ",
        "COUNTRYNAME": "Uzbekistan",
        "AIRPORTCODE": "FEG",
        "AIRPORTNAME": "Fergana",
    },
    {
        "CITYNAME": "Feijo",
        "CITYCODE": "FEJ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "FEJ",
        "AIRPORTNAME": "FEIJO",
    },
    {
        "CITYNAME": "Fernando De Noron",
        "CITYCODE": "FEN",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "FEN",
        "AIRPORTNAME": "Fernando De Noron",
    },
    {
        "CITYNAME": "Fergusons Gulf",
        "CITYCODE": "FER",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "FER",
        "AIRPORTNAME": "Fergusons Gulf",
    },
    {
        "CITYNAME": "Fez",
        "CITYCODE": "FEZ",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "FEZ",
        "AIRPORTNAME": "Sais",
    },
    {
        "CITYNAME": "Fderik",
        "CITYCODE": "FGD",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "FGD",
        "AIRPORTNAME": "Fderik",
    },
    {
        "CITYNAME": "Fangatau",
        "CITYCODE": "FGU",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "FGU",
        "AIRPORTNAME": "Fangatau",
    },
    {
        "CITYNAME": "Fort Huachuca",
        "CITYCODE": "FHU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FHU",
        "AIRPORTNAME": "Libby Aaf",
    },
    {
        "CITYNAME": "Fakahina",
        "CITYCODE": "FHZ",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "FHZ",
        "AIRPORTNAME": "Fakahina",
    },
    {
        "CITYNAME": "Fire Cove",
        "CITYCODE": "FIC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FIC",
        "AIRPORTNAME": "Fire Cove",
    },
    {
        "CITYNAME": "Fishers Island",
        "CITYCODE": "FID",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FID",
        "AIRPORTNAME": "Elizabeth Field",
    },
    {
        "CITYNAME": "Fair Isle",
        "CITYCODE": "FIE",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "FIE",
        "AIRPORTNAME": "Fair Isle",
    },
    {
        "CITYNAME": "Fria",
        "CITYCODE": "FIG",
        "COUNTRYCODE": "GN",
        "COUNTRYNAME": "Guinea",
        "AIRPORTCODE": "FIG",
        "AIRPORTNAME": "Fria",
    },
    {
        "CITYNAME": "Kinshasa",
        "CITYCODE": "FIH",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "FIH",
        "AIRPORTNAME": "NâDjili",
    },
    {
        "CITYNAME": "Finschhafen",
        "CITYCODE": "FIN",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "FIN",
        "AIRPORTNAME": "Finschhafen",
    },
    {
        "CITYNAME": "Campo",
        "CITYCODE": "FIR",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "FIR",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Al Fujairah",
        "CITYCODE": "FJR",
        "COUNTRYCODE": "AE",
        "COUNTRYNAME": "United Arab Emirates",
        "AIRPORTCODE": "FJR",
        "AIRPORTNAME": "Fujairah Int'l",
    },
    {
        "CITYNAME": "Karlsruhe",
        "CITYCODE": "FKB",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "FKB",
        "AIRPORTNAME": "Baden airpark",
    },
    {
        "CITYNAME": "Kisangani",
        "CITYCODE": "FKI",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "FKI",
        "AIRPORTNAME": "Kisangani",
    },
    {
        "CITYNAME": "Franklin",
        "CITYCODE": "FKL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FKL",
        "AIRPORTNAME": "Chess Lamber",
    },
    {
        "CITYNAME": "Fak Fak",
        "CITYCODE": "FKQ",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "FKQ",
        "AIRPORTNAME": "Fak Fak",
    },
    {
        "CITYNAME": "Florencia",
        "CITYCODE": "FLA",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "FLA",
        "AIRPORTNAME": "Capitolio",
    },
    {
        "CITYNAME": "Flagstaff",
        "CITYCODE": "FLG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FLG",
        "AIRPORTNAME": "Pulliam Field",
    },
    {
        "CITYNAME": "Fort Lauderdale",
        "CITYCODE": "FLL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FLL",
        "AIRPORTNAME": "Fll Int'l",
    },
    {
        "CITYNAME": "Florianopolis",
        "CITYCODE": "FLN",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "FLN",
        "AIRPORTNAME": "Hercilio Luz",
    },
    {
        "CITYNAME": "Florence",
        "CITYCODE": "FLO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FLO",
        "AIRPORTNAME": "Florence",
    },
    {
        "CITYNAME": "Florence",
        "CITYCODE": "FLR",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "FLR",
        "AIRPORTNAME": "Peretola",
    },
    {
        "CITYNAME": "Flinders Island",
        "CITYCODE": "FLS",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "FLS",
        "AIRPORTNAME": "Flinders Island",
    },
    {
        "CITYNAME": "Flat",
        "CITYCODE": "FLT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FLT",
        "AIRPORTNAME": "Flat",
    },
    {
        "CITYNAME": "Flores Island",
        "CITYCODE": "FLW",
        "COUNTRYCODE": "PT",
        "COUNTRYNAME": "Portugal",
        "AIRPORTCODE": "FLW",
        "AIRPORTNAME": "Flores Island",
    },
    {
        "CITYNAME": "Formosa",
        "CITYCODE": "FMA",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "FMA",
        "AIRPORTNAME": "El Pucu",
    },
    {
        "CITYNAME": "Kalemie",
        "CITYCODE": "FMI",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "FMI",
        "AIRPORTNAME": "Kalemie",
    },
    {
        "CITYNAME": "Memmingen",
        "CITYCODE": "FMM",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "FMM",
        "AIRPORTNAME": "Allgaeu",
    },
    {
        "CITYNAME": "Farmington",
        "CITYCODE": "FMN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FMN",
        "AIRPORTNAME": "Farmington Municipal",
    },
    {
        "CITYNAME": "Muenster",
        "CITYCODE": "FMO",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "FMO",
        "AIRPORTNAME": "Muenster",
    },
    {
        "CITYNAME": "Fort Myers",
        "CITYCODE": "FMY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FMY",
        "AIRPORTNAME": "Fort Myers Page Field",
    },
    {
        "CITYNAME": "Fort Myers",
        "CITYCODE": "FMY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RSW",
        "AIRPORTNAME": "Southwest Florida Regional",
    },
    {
        "CITYNAME": "Freetown",
        "CITYCODE": "FNA",
        "COUNTRYCODE": "SL",
        "COUNTRYNAME": "Sierra Leone",
        "AIRPORTCODE": "FNA",
        "AIRPORTNAME": "Lungi Int'l",
    },
    {
        "CITYNAME": "Freetown",
        "CITYCODE": "FNA",
        "COUNTRYCODE": "SL",
        "COUNTRYNAME": "Sierra Leone",
        "AIRPORTCODE": "HGS",
        "AIRPORTNAME": "Hastings",
    },
    {
        "CITYNAME": "Funchal",
        "CITYCODE": "FNC",
        "COUNTRYCODE": "PT",
        "COUNTRYNAME": "Portugal",
        "AIRPORTCODE": "FNC",
        "AIRPORTNAME": "Funchal",
    },
    {
        "CITYNAME": "Fada Ngourma",
        "CITYCODE": "FNG",
        "COUNTRYCODE": "BF",
        "COUNTRYNAME": "Burkina Faso",
        "AIRPORTCODE": "FNG",
        "AIRPORTNAME": "Fada Ngourma",
    },
    {
        "CITYNAME": "Nimes",
        "CITYCODE": "FNI",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "FNI",
        "AIRPORTNAME": "Garons",
    },
    {
        "CITYNAME": "Pyongyang",
        "CITYCODE": "FNJ",
        "COUNTRYCODE": "KP",
        "COUNTRYNAME": "Korea (Democratic People's Republic Of)",
        "AIRPORTCODE": "FNJ",
        "AIRPORTNAME": "Sunan",
    },
    {
        "CITYNAME": "Fort Collins Love",
        "CITYCODE": "FNL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FNL",
        "AIRPORTNAME": "Fort Collins Love",
    },
    {
        "CITYNAME": "Flint",
        "CITYCODE": "FNT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FNT",
        "AIRPORTNAME": "Bishop",
    },
    {
        "CITYNAME": "Fuzhou",
        "CITYCODE": "FOC",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "FOC",
        "AIRPORTNAME": "Fuzhou",
    },
    {
        "CITYNAME": "Fort Dodge",
        "CITYCODE": "FOD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FOD",
        "AIRPORTNAME": "Fort Dodge",
    },
    {
        "CITYNAME": "Topeka",
        "CITYCODE": "FOE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FOE",
        "AIRPORTNAME": "Forbes Field",
    },
    {
        "CITYNAME": "Foggia",
        "CITYCODE": "FOG",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "FOG",
        "AIRPORTNAME": "Foggia - Gino Lisa",
    },
    {
        "CITYNAME": "Foumban",
        "CITYCODE": "FOM",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "FOM",
        "AIRPORTNAME": "Foumban",
    },
    {
        "CITYNAME": "Numfoor",
        "CITYCODE": "FOO",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "FOO",
        "AIRPORTNAME": "Numfoor",
    },
    {
        "CITYNAME": "Fortaleza",
        "CITYCODE": "FOR",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "FOR",
        "AIRPORTNAME": "Pinto Martins",
    },
    {
        "CITYNAME": "Fougamou",
        "CITYCODE": "FOU",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "FOU",
        "AIRPORTNAME": "Fougamou",
    },
    {
        "CITYNAME": "Freeport",
        "CITYCODE": "FPO",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "FPO",
        "AIRPORTNAME": "Freeport Int'l",
    },
    {
        "CITYNAME": "Fort Pierce",
        "CITYCODE": "FPR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FPR",
        "AIRPORTNAME": "St Lucie County",
    },
    {
        "CITYNAME": "Frankfurt",
        "CITYCODE": "FRA",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "FRA",
        "AIRPORTNAME": "Frankfurt Int'l",
    },
    {
        "CITYNAME": "Frankfurt",
        "CITYCODE": "FRA",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "HHN",
        "AIRPORTNAME": "Hahn",
    },
    {
        "CITYNAME": "Frankfurt",
        "CITYCODE": "FRA",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "ZRB",
        "AIRPORTNAME": "Frankfurt Main Station Airport",
    },
    {
        "CITYNAME": "Forbes",
        "CITYCODE": "FRB",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "FRB",
        "AIRPORTNAME": "Forbes",
    },
    {
        "CITYNAME": "Franca",
        "CITYCODE": "FRC",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "FRC",
        "AIRPORTNAME": "Franca",
    },
    {
        "CITYNAME": "Friday Harbor",
        "CITYCODE": "FRD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FRD",
        "AIRPORTNAME": "Friday Harbor",
    },
    {
        "CITYNAME": "Fera Island",
        "CITYCODE": "FRE",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "FRE",
        "AIRPORTNAME": "Fera Island",
    },
    {
        "CITYNAME": "Farmingdale",
        "CITYCODE": "FRG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FRG",
        "AIRPORTNAME": "Republic Field",
    },
    {
        "CITYNAME": "Forli",
        "CITYCODE": "FRL",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "FRL",
        "AIRPORTNAME": "Luigi Ridolfi",
    },
    {
        "CITYNAME": "Fairmont",
        "CITYCODE": "FRM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FRM",
        "AIRPORTNAME": "Fairmont",
    },
    {
        "CITYNAME": "Floro",
        "CITYCODE": "FRO",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "FRO",
        "AIRPORTNAME": "Flora",
    },
    {
        "CITYNAME": "Fresh Water Bay",
        "CITYCODE": "FRP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FRP",
        "AIRPORTNAME": "Fresh Water Bay",
    },
    {
        "CITYNAME": "Flores",
        "CITYCODE": "FRS",
        "COUNTRYCODE": "GT",
        "COUNTRYNAME": "Guatemala",
        "AIRPORTCODE": "FRS",
        "AIRPORTNAME": "Santa Elena",
    },
    {
        "CITYNAME": "Bishkek",
        "CITYCODE": "FRU",
        "COUNTRYCODE": "KG",
        "COUNTRYNAME": "Kyrgyzstan",
        "AIRPORTCODE": "FRU",
        "AIRPORTNAME": "Bishkek",
    },
    {
        "CITYNAME": "Francistown",
        "CITYCODE": "FRW",
        "COUNTRYCODE": "BW",
        "COUNTRYNAME": "Botswana",
        "AIRPORTCODE": "FRW",
        "AIRPORTNAME": "Francistown",
    },
    {
        "CITYNAME": "Figari",
        "CITYCODE": "FSC",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "FSC",
        "AIRPORTNAME": "Sud Corse",
    },
    {
        "CITYNAME": "Sioux Falls",
        "CITYCODE": "FSD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FSD",
        "AIRPORTNAME": "Sioux Falls Regional",
    },
    {
        "CITYNAME": "Fort Smith",
        "CITYCODE": "FSM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FSM",
        "AIRPORTNAME": "Fort Smith Municipal",
    },
    {
        "CITYNAME": "St Pierre",
        "CITYCODE": "FSP",
        "COUNTRYCODE": "PM",
        "COUNTRYNAME": "St. Pierre & Miquelon",
        "AIRPORTCODE": "FSP",
        "AIRPORTNAME": "St Pierre",
    },
    {
        "CITYNAME": "Futuna Island",
        "CITYCODE": "FTA",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "FTA",
        "AIRPORTNAME": "Futuna",
    },
    {
        "CITYNAME": "El Calafate",
        "CITYCODE": "FTE",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "FTE",
        "AIRPORTNAME": "Comandante Armando Tola Intl Airport",
    },
    {
        "CITYNAME": "Fort Dauphin",
        "CITYCODE": "FTU",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "FTU",
        "AIRPORTNAME": "Marillac",
    },
    {
        "CITYNAME": "Owando",
        "CITYCODE": "FTX",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "FTX",
        "AIRPORTNAME": "Owando",
    },
    {
        "CITYNAME": "Fuerteventura",
        "CITYCODE": "FUE",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "FUE",
        "AIRPORTNAME": "Fuerteventura",
    },
    {
        "CITYNAME": "Fuyang",
        "CITYCODE": "FUG",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "FUG",
        "AIRPORTNAME": "Fuyang Xiguan Airport",
    },
    {
        "CITYNAME": "Fukue",
        "CITYCODE": "FUJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "FUJ",
        "AIRPORTNAME": "Fukue",
    },
    {
        "CITYNAME": "Fukuoka",
        "CITYCODE": "FUK",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "FUK",
        "AIRPORTNAME": "Fukuoka",
    },
    {
        "CITYNAME": "Fullerton",
        "CITYCODE": "FUL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FUL",
        "AIRPORTNAME": "Fullerton Municipal",
    },
    {
        "CITYNAME": "Funafuti Atol",
        "CITYCODE": "FUN",
        "COUNTRYCODE": "TV",
        "COUNTRYNAME": "Tuvalu",
        "AIRPORTCODE": "FUN",
        "AIRPORTNAME": "Funafuti Atol Int'l",
    },
    {
        "CITYNAME": "Futuna Island",
        "CITYCODE": "FUT",
        "COUNTRYCODE": "WF",
        "COUNTRYNAME": "Wallis & Futuna Islands",
        "AIRPORTCODE": "FUT",
        "AIRPORTNAME": "Futuna Island",
    },
    {
        "CITYNAME": "Fort Wayne",
        "CITYCODE": "FWA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FWA",
        "AIRPORTNAME": "Fort Wayne Municipal",
    },
    {
        "CITYNAME": "Cuamba",
        "CITYCODE": "FXO",
        "COUNTRYCODE": "MZ",
        "COUNTRYNAME": "Mozambique",
        "AIRPORTCODE": "FXO",
        "AIRPORTNAME": "Cuamba",
    },
    {
        "CITYNAME": "Fayetteville",
        "CITYCODE": "FYV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "FYV",
        "AIRPORTNAME": "Drake Field",
    },
    {
        "CITYNAME": "Gadsden",
        "CITYCODE": "GAD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GAD",
        "AIRPORTNAME": "Gadsden Municipal",
    },
    {
        "CITYNAME": "Yamagata",
        "CITYCODE": "GAJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "GAJ",
        "AIRPORTNAME": "Junmachi",
    },
    {
        "CITYNAME": "Galena",
        "CITYCODE": "GAL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GAL",
        "AIRPORTNAME": "Galena",
    },
    {
        "CITYNAME": "Gambell",
        "CITYCODE": "GAM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GAM",
        "AIRPORTNAME": "Gambell",
    },
    {
        "CITYNAME": "Gan Island",
        "CITYCODE": "GAN",
        "COUNTRYCODE": "MV",
        "COUNTRYNAME": "Maldives",
        "AIRPORTCODE": "GAN",
        "AIRPORTNAME": "Gan Domestic Airport",
    },
    {
        "CITYNAME": "Guantanamo",
        "CITYCODE": "GAO",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "GAO",
        "AIRPORTNAME": "Los Canos",
    },
    {
        "CITYNAME": "Gao",
        "CITYCODE": "GAQ",
        "COUNTRYCODE": "ML",
        "COUNTRYNAME": "Mali",
        "AIRPORTCODE": "GAQ",
        "AIRPORTNAME": "Gao",
    },
    {
        "CITYNAME": "Garissa",
        "CITYCODE": "GAS",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "GAS",
        "AIRPORTNAME": "Garissa",
    },
    {
        "CITYNAME": "Guwahati",
        "CITYCODE": "GAU",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "GAU",
        "AIRPORTNAME": "Lokpriya Gopinath Bordoloi International",
    },
    {
        "CITYNAME": "Gamba",
        "CITYCODE": "GAX",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "GAX",
        "AIRPORTNAME": "Gamba",
    },
    {
        "CITYNAME": "Gaya",
        "CITYCODE": "GAY",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "GAY",
        "AIRPORTNAME": "Gaya International Airport",
    },
    {
        "CITYNAME": "Gara Djebilet",
        "CITYCODE": "GBB",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "GBB",
        "AIRPORTNAME": "Qabala International Airport",
    },
    {
        "CITYNAME": "Gasuke",
        "CITYCODE": "GBC",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "GBC",
        "AIRPORTNAME": "Gasuke",
    },
    {
        "CITYNAME": "Great Bend",
        "CITYCODE": "GBD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GBD",
        "AIRPORTNAME": "Great Bend",
    },
    {
        "CITYNAME": "Gaborone",
        "CITYCODE": "GBE",
        "COUNTRYCODE": "BW",
        "COUNTRYNAME": "Botswana",
        "AIRPORTCODE": "GBE",
        "AIRPORTNAME": "Sir Seretse Khama Int'l",
    },
    {
        "CITYNAME": "Gulbarga",
        "CITYCODE": "GBI",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "GBI",
        "AIRPORTNAME": "Kalaburagi Gulbarga",
    },
    {
        "CITYNAME": "Marie Galante",
        "CITYCODE": "GBJ",
        "COUNTRYCODE": "GP",
        "COUNTRYNAME": "Guadeloupe",
        "AIRPORTCODE": "GBJ",
        "AIRPORTNAME": "Les Bases",
    },
    {
        "CITYNAME": "Gbangbatok",
        "CITYCODE": "GBK",
        "COUNTRYCODE": "SL",
        "COUNTRYNAME": "Sierra Leone",
        "AIRPORTCODE": "GBK",
        "AIRPORTNAME": "Gbangbatok",
    },
    {
        "CITYNAME": "Granada",
        "CITYCODE": "GBX",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "GBX",
        "AIRPORTNAME": "Granada Bus Station",
    },
    {
        "CITYNAME": "Guacamaya",
        "CITYCODE": "GCA",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "GCA",
        "AIRPORTNAME": "Guacamaya",
    },
    {
        "CITYNAME": "Gillette",
        "CITYCODE": "GCC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GCC",
        "AIRPORTNAME": "Campbell County",
    },
    {
        "CITYNAME": "Guernsey",
        "CITYCODE": "GCI",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "GCI",
        "AIRPORTNAME": "Guernsey",
    },
    {
        "CITYNAME": "Garden City",
        "CITYCODE": "GCK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GCK",
        "AIRPORTNAME": "Garden City Municipal",
    },
    {
        "CITYNAME": "Grand Cayman Island",
        "CITYCODE": "GCM",
        "COUNTRYCODE": "KY",
        "COUNTRYNAME": "Cayman Islands",
        "AIRPORTCODE": "GCM",
        "AIRPORTNAME": "Owen Roberts Int'l",
    },
    {
        "CITYNAME": "Grand Canyon",
        "CITYCODE": "GCN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GCN",
        "AIRPORTNAME": "Grand Canyon National Park",
    },
    {
        "CITYNAME": "Gondia",
        "CITYCODE": "GDB",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "GDB",
        "AIRPORTNAME": "Gondia Airport",
    },
    {
        "CITYNAME": "Gordil",
        "CITYCODE": "GDI",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "GDI",
        "AIRPORTNAME": "Gordil",
    },
    {
        "CITYNAME": "Guadalajara",
        "CITYCODE": "GDL",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "GDL",
        "AIRPORTNAME": "Miguel Hidal",
    },
    {
        "CITYNAME": "Gdansk",
        "CITYCODE": "GDN",
        "COUNTRYCODE": "PL",
        "COUNTRYNAME": "Poland",
        "AIRPORTCODE": "GDN",
        "AIRPORTNAME": "Rebiechowo",
    },
    {
        "CITYNAME": "Gondar",
        "CITYCODE": "GDQ",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "GDQ",
        "AIRPORTNAME": "Gondar",
    },
    {
        "CITYNAME": "Grand Turk Island",
        "CITYCODE": "GDT",
        "COUNTRYCODE": "TC",
        "COUNTRYNAME": "Turcs & Caicos Islands",
        "AIRPORTCODE": "GDT",
        "AIRPORTNAME": "Grand Turk Is",
    },
    {
        "CITYNAME": "Glendive",
        "CITYCODE": "GDV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GDV",
        "AIRPORTNAME": "Dawson Community",
    },
    {
        "CITYNAME": "Magadan",
        "CITYCODE": "GDX",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "GDX",
        "AIRPORTNAME": "Magadan",
    },
    {
        "CITYNAME": "Gelendzik",
        "CITYCODE": "GDZ",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "GDZ",
        "AIRPORTNAME": "Gelendzhik Airport",
    },
    {
        "CITYNAME": "Gebe",
        "CITYCODE": "GEB",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "GEB",
        "AIRPORTNAME": "Gebe",
    },
    {
        "CITYNAME": "Gecitkale",
        "CITYCODE": "GEC",
        "COUNTRYCODE": "CY",
        "COUNTRYNAME": "Cyprus",
        "AIRPORTCODE": "GEC",
        "AIRPORTNAME": "Gecitkale",
    },
    {
        "CITYNAME": "Spokane",
        "CITYCODE": "GEG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GEG",
        "AIRPORTNAME": "Spokane Int'l",
    },
    {
        "CITYNAME": "Spokane",
        "CITYCODE": "GEG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SFF",
        "AIRPORTNAME": "Felts Field",
    },
    {
        "CITYNAME": "Santo Angelo",
        "CITYCODE": "GEL",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "GEL",
        "AIRPORTNAME": "Sepe Tiaraju",
    },
    {
        "CITYNAME": "Georgetown",
        "CITYCODE": "GEO",
        "COUNTRYCODE": "GY",
        "COUNTRYNAME": "Guyana",
        "AIRPORTCODE": "GEO",
        "AIRPORTNAME": "Cheddi Jagan Int",
    },
    {
        "CITYNAME": "Nueva Gerona",
        "CITYCODE": "GER",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "GER",
        "AIRPORTNAME": "Rafael Cabrera",
    },
    {
        "CITYNAME": "General Santos",
        "CITYCODE": "GES",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "GES",
        "AIRPORTNAME": "General Santos Intl Airport",
    },
    {
        "CITYNAME": "Geraldton",
        "CITYCODE": "GET",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "GET",
        "AIRPORTNAME": "Geraldton",
    },
    {
        "CITYNAME": "Gallivare",
        "CITYCODE": "GEV",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "GEV",
        "AIRPORTNAME": "Gallivare",
    },
    {
        "CITYNAME": "Gewoya",
        "CITYCODE": "GEW",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "GEW",
        "AIRPORTNAME": "Gewoya",
    },
    {
        "CITYNAME": "Greybull",
        "CITYCODE": "GEY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GEY",
        "AIRPORTNAME": "South Big Horn County",
    },
    {
        "CITYNAME": "Griffith",
        "CITYCODE": "GFF",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "GFF",
        "AIRPORTNAME": "Griffith",
    },
    {
        "CITYNAME": "Grand Forks",
        "CITYCODE": "GFK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GFK",
        "AIRPORTNAME": "Grand Forks",
    },
    {
        "CITYNAME": "Grafton",
        "CITYCODE": "GFN",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "GFN",
        "AIRPORTNAME": "Grafton",
    },
    {
        "CITYNAME": "Grootfontein",
        "CITYCODE": "GFY",
        "COUNTRYCODE": "NA",
        "COUNTRYNAME": "Namibia",
        "AIRPORTCODE": "GFY",
        "AIRPORTNAME": "Grootfontein",
    },
    {
        "CITYNAME": "Longview",
        "CITYCODE": "GGG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GGG",
        "AIRPORTNAME": "Gregg County",
    },
    {
        "CITYNAME": "Gagnoa",
        "CITYCODE": "GGN",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "GGN",
        "AIRPORTNAME": "Gagnoa",
    },
    {
        "CITYNAME": "Guiglo",
        "CITYCODE": "GGO",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "GGO",
        "AIRPORTNAME": "Guiglo",
    },
    {
        "CITYNAME": "Garoe",
        "CITYCODE": "GGR",
        "COUNTRYCODE": "SO",
        "COUNTRYNAME": "Somalia",
        "AIRPORTCODE": "GGR",
        "AIRPORTNAME": "Garoe",
    },
    {
        "CITYNAME": "Gdor  Gregores",
        "CITYCODE": "GGS",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "GGS",
        "AIRPORTNAME": "Gdor  Gregores",
    },
    {
        "CITYNAME": "George Town",
        "CITYCODE": "GGT",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "GGT",
        "AIRPORTNAME": "George Town",
    },
    {
        "CITYNAME": "Glasgow",
        "CITYCODE": "GGW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GGW",
        "AIRPORTNAME": "Glasgow",
    },
    {
        "CITYNAME": "Ghardaia",
        "CITYCODE": "GHA",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "GHA",
        "AIRPORTNAME": "Noumerate",
    },
    {
        "CITYNAME": "Governors Harbour",
        "CITYCODE": "GHB",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "GHB",
        "AIRPORTNAME": "Governors Harbour",
    },
    {
        "CITYNAME": "Great Harbour",
        "CITYCODE": "GHC",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "GHC",
        "AIRPORTNAME": "Great Harbour",
    },
    {
        "CITYNAME": "Ghat",
        "CITYCODE": "GHT",
        "COUNTRYCODE": "LY",
        "COUNTRYNAME": "Libyan Arab Jamahiriya",
        "AIRPORTCODE": "GHT",
        "AIRPORTNAME": "Ghat",
    },
    {
        "CITYNAME": "Gibraltar",
        "CITYCODE": "GIB",
        "COUNTRYCODE": "GI",
        "COUNTRYNAME": "Gibralter",
        "AIRPORTCODE": "GIB",
        "AIRPORTNAME": "North Front",
    },
    {
        "CITYNAME": "Boigu Island",
        "CITYCODE": "GIC",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "GIC",
        "AIRPORTNAME": "Boigu Island",
    },
    {
        "CITYNAME": "Gitega",
        "CITYCODE": "GID",
        "COUNTRYCODE": "BI",
        "COUNTRYNAME": "Burundi",
        "AIRPORTCODE": "GID",
        "AIRPORTNAME": "Gitega",
    },
    {
        "CITYNAME": "Siguiri",
        "CITYCODE": "GII",
        "COUNTRYCODE": "GN",
        "COUNTRYNAME": "Guinea",
        "AIRPORTCODE": "GII",
        "AIRPORTNAME": "Siguiri",
    },
    {
        "CITYNAME": "Gisborne",
        "CITYCODE": "GIS",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "GIS",
        "AIRPORTNAME": "Gisborne",
    },
    {
        "CITYNAME": "Gizan",
        "CITYCODE": "GIZ",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "GIZ",
        "AIRPORTNAME": "JAZAN",
    },
    {
        "CITYNAME": "Guanaja",
        "CITYCODE": "GJA",
        "COUNTRYCODE": "HN",
        "COUNTRYNAME": "Honduras",
        "AIRPORTCODE": "GJA",
        "AIRPORTNAME": "Guanaja",
    },
    {
        "CITYNAME": "Jijel",
        "CITYCODE": "GJL",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "GJL",
        "AIRPORTNAME": "Jijel",
    },
    {
        "CITYNAME": "Grand Junction",
        "CITYCODE": "GJT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GJT",
        "AIRPORTNAME": "Walker Field",
    },
    {
        "CITYNAME": "Goroka",
        "CITYCODE": "GKA",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "GKA",
        "AIRPORTNAME": "Goroka",
    },
    {
        "CITYNAME": "Great Keppel Island",
        "CITYCODE": "GKL",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "GKL",
        "AIRPORTNAME": "Great Keppel Island",
    },
    {
        "CITYNAME": "Glasgow",
        "CITYCODE": "GLA",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "GLA",
        "AIRPORTNAME": "Glasgow int'l",
    },
    {
        "CITYNAME": "Glasgow",
        "CITYCODE": "GLA",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "PIK",
        "AIRPORTNAME": "Prestwick",
    },
    {
        "CITYNAME": "Goodland",
        "CITYCODE": "GLD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GLD",
        "AIRPORTNAME": "Renner Field",
    },
    {
        "CITYNAME": "Golfito",
        "CITYCODE": "GLF",
        "COUNTRYCODE": "CR",
        "COUNTRYNAME": "Costa Rica",
        "AIRPORTCODE": "GLF",
        "AIRPORTNAME": "Golfito",
    },
    {
        "CITYNAME": "Greenville",
        "CITYCODE": "GLH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GLH",
        "AIRPORTNAME": "Greenville",
    },
    {
        "CITYNAME": "Glen Innes",
        "CITYCODE": "GLI",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "GLI",
        "AIRPORTNAME": "Glen Innes",
    },
    {
        "CITYNAME": "Galcaio",
        "CITYCODE": "GLK",
        "COUNTRYCODE": "SO",
        "COUNTRYNAME": "Somalia",
        "AIRPORTCODE": "GLK",
        "AIRPORTNAME": "Galcaio",
    },
    {
        "CITYNAME": "Goulimime",
        "CITYCODE": "GLN",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "GLN",
        "AIRPORTNAME": "Guelmim Airport",
    },
    {
        "CITYNAME": "Gloucester",
        "CITYCODE": "GLO",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "GLO",
        "AIRPORTNAME": "Gloucester",
    },
    {
        "CITYNAME": "Galveston",
        "CITYCODE": "GLS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GLS",
        "AIRPORTNAME": "Scholes Field",
    },
    {
        "CITYNAME": "Gladstone",
        "CITYCODE": "GLT",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "GLT",
        "AIRPORTNAME": "Gladstone",
    },
    {
        "CITYNAME": "Golovin",
        "CITYCODE": "GLV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GLV",
        "AIRPORTNAME": "Golovin",
    },
    {
        "CITYNAME": "Galela",
        "CITYCODE": "GLX",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "GLX",
        "AIRPORTNAME": "Galela",
    },
    {
        "CITYNAME": "Gemena",
        "CITYCODE": "GMA",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "GMA",
        "AIRPORTNAME": "Gemena",
    },
    {
        "CITYNAME": "Gambela",
        "CITYCODE": "GMB",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "GMB",
        "AIRPORTNAME": "Gambela",
    },
    {
        "CITYNAME": "Gomel",
        "CITYCODE": "GME",
        "COUNTRYCODE": "BY",
        "COUNTRYNAME": "Belarus (Belorussia)",
        "AIRPORTCODE": "GME",
        "AIRPORTNAME": "Gomel",
    },
    {
        "CITYNAME": "Gamboma",
        "CITYCODE": "GMM",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "GMM",
        "AIRPORTNAME": "Gamboma",
    },
    {
        "CITYNAME": "Gombe",
        "CITYCODE": "GMO",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "GMO",
        "AIRPORTNAME": "Gombe Lawanti Intl Airport",
    },
    {
        "CITYNAME": "Gambier Island",
        "CITYCODE": "GMR",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "GMR",
        "AIRPORTNAME": "Gambier Island",
    },
    {
        "CITYNAME": "San Sebastian (La Gomera)",
        "CITYCODE": "GMZ",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "GMZ",
        "AIRPORTNAME": "La Gomera",
    },
    {
        "CITYNAME": "Grenoble",
        "CITYCODE": "GNB",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "GNB",
        "AIRPORTNAME": "Saint Geoirs",
    },
    {
        "CITYNAME": "Grenada",
        "CITYCODE": "GND",
        "COUNTRYCODE": "GD",
        "COUNTRYNAME": "Grenada",
        "AIRPORTCODE": "GND",
        "AIRPORTNAME": "Point Saline Int'l",
    },
    {
        "CITYNAME": "Green Island",
        "CITYCODE": "GNI",
        "COUNTRYCODE": "TW",
        "COUNTRYNAME": "Taiwan",
        "AIRPORTCODE": "GNI",
        "AIRPORTNAME": "Green Island",
    },
    {
        "CITYNAME": "Guanambi",
        "CITYCODE": "GNM",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "GNM",
        "AIRPORTNAME": "Guanambi",
    },
    {
        "CITYNAME": "Ghinnir",
        "CITYCODE": "GNN",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "GNN",
        "AIRPORTNAME": "Ghinnir",
    },
    {
        "CITYNAME": "General Roca",
        "CITYCODE": "GNR",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "GNR",
        "AIRPORTNAME": "General Roca",
    },
    {
        "CITYNAME": "Gunungsitoli",
        "CITYCODE": "GNS",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "GNS",
        "AIRPORTNAME": "Gunungsitoli",
    },
    {
        "CITYNAME": "Goodnews Mumtrak",
        "CITYCODE": "GNU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GNU",
        "AIRPORTNAME": "Goodnews Mumtrak",
    },
    {
        "CITYNAME": "Gainesville",
        "CITYCODE": "GNV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GNV",
        "AIRPORTNAME": "J R Alison Municipal",
    },
    {
        "CITYNAME": "Ghanzi",
        "CITYCODE": "GNZ",
        "COUNTRYCODE": "BW",
        "COUNTRYNAME": "Botswana",
        "AIRPORTCODE": "GNZ",
        "AIRPORTNAME": "Ghanzi",
    },
    {
        "CITYNAME": "Genoa",
        "CITYCODE": "GOA",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "GEP",
        "AIRPORTNAME": "Genova Brignole railway station",
    },
    {
        "CITYNAME": "Genoa",
        "CITYCODE": "GOA",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "GOA",
        "AIRPORTNAME": "Cristoforo Colombo",
    },
    {
        "CITYNAME": "Genoa",
        "CITYCODE": "GOA",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "GPP",
        "AIRPORTNAME": "Genova Piazza Principe Railway Station Airport",
    },
    {
        "CITYNAME": "Goba",
        "CITYCODE": "GOB",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "GOB",
        "AIRPORTNAME": "Goba",
    },
    {
        "CITYNAME": "Gora",
        "CITYCODE": "GOC",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "GOC",
        "AIRPORTNAME": "Gora",
    },
    {
        "CITYNAME": "Nuuk",
        "CITYCODE": "GOH",
        "COUNTRYCODE": "GL",
        "COUNTRYNAME": "Greenland",
        "AIRPORTCODE": "GOH",
        "AIRPORTNAME": "Nuuk",
    },
    {
        "CITYNAME": "Goa",
        "CITYCODE": "GOI",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "GOI",
        "AIRPORTNAME": "Dabolim",
    },
    {
        "CITYNAME": "Nizhniy Novgorod",
        "CITYCODE": "GOJ",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "GOJ",
        "AIRPORTNAME": "Nizhniy Novgorod",
    },
    {
        "CITYNAME": "Goma",
        "CITYCODE": "GOM",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "GOM",
        "AIRPORTNAME": "Goma",
    },
    {
        "CITYNAME": "Groton",
        "CITYCODE": "GON",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GON",
        "AIRPORTNAME": "Groton-New London",
    },
    {
        "CITYNAME": "Gorakhpur",
        "CITYCODE": "GOP",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "GOP",
        "AIRPORTNAME": "Gorakhpur",
    },
    {
        "CITYNAME": "Gore",
        "CITYCODE": "GOR",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "GOR",
        "AIRPORTNAME": "Gore",
    },
    {
        "CITYNAME": "Gothenburg",
        "CITYCODE": "GOT",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "GOT",
        "AIRPORTNAME": "Landvetter",
    },
    {
        "CITYNAME": "Gothenburg",
        "CITYCODE": "GOT",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "GSE",
        "AIRPORTNAME": "Saeve",
    },
    {
        "CITYNAME": "Garoua",
        "CITYCODE": "GOU",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "GOU",
        "AIRPORTNAME": "Garoua",
    },
    {
        "CITYNAME": "Gove",
        "CITYCODE": "GOV",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "GOV",
        "AIRPORTNAME": "Nhulunbuy",
    },
    {
        "CITYNAME": "Goa",
        "CITYCODE": "GOX",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "GOX",
        "AIRPORTNAME": "Mopa Airport",
    },
    {
        "CITYNAME": "Gorna Orechovitsa",
        "CITYCODE": "GOZ",
        "COUNTRYCODE": "BG",
        "COUNTRYNAME": "Bulgaria",
        "AIRPORTCODE": "GOZ",
        "AIRPORTNAME": "Gorna Orechovitsa",
    },
    {
        "CITYNAME": "Patras",
        "CITYCODE": "GPA",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "GPA",
        "AIRPORTNAME": "Araxos",
    },
    {
        "CITYNAME": "Guarapuava",
        "CITYCODE": "GPB",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "GPB",
        "AIRPORTNAME": "Guarapuava Airport",
    },
    {
        "CITYNAME": "Guapi",
        "CITYCODE": "GPI",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "GPI",
        "AIRPORTNAME": "Guapi",
    },
    {
        "CITYNAME": "Garden Point",
        "CITYCODE": "GPN",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "GPN",
        "AIRPORTNAME": "Garden Point",
    },
    {
        "CITYNAME": "Galapagos Islands",
        "CITYCODE": "GPS",
        "COUNTRYCODE": "EC",
        "COUNTRYNAME": "Ecuador",
        "AIRPORTCODE": "GPS",
        "AIRPORTNAME": "Baltra",
    },
    {
        "CITYNAME": "Gulfport",
        "CITYCODE": "GPT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GPT",
        "AIRPORTNAME": "Biloxi Regional",
    },
    {
        "CITYNAME": "Grand Rapids",
        "CITYCODE": "GPZ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GPZ",
        "AIRPORTNAME": "Grand Rapids",
    },
    {
        "CITYNAME": "Green Bay",
        "CITYCODE": "GRB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GRB",
        "AIRPORTNAME": "Austin-Straubel Field",
    },
    {
        "CITYNAME": "Gardez",
        "CITYCODE": "GRG",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "GRG",
        "AIRPORTNAME": "Gardez",
    },
    {
        "CITYNAME": "Grand Island",
        "CITYCODE": "GRI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GRI",
        "AIRPORTNAME": "Grand Island",
    },
    {
        "CITYNAME": "George",
        "CITYCODE": "GRJ",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "GRJ",
        "AIRPORTNAME": "George",
    },
    {
        "CITYNAME": "Bell County",
        "CITYCODE": "GRK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GRK",
        "AIRPORTNAME": "Killeen-Fort Hood Regional Airport",
    },
    {
        "CITYNAME": "Gerona",
        "CITYCODE": "GRO",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "GRO",
        "AIRPORTNAME": "Costa Brava",
    },
    {
        "CITYNAME": "Gurupi",
        "CITYCODE": "GRP",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "GRP",
        "AIRPORTNAME": "Gurupi",
    },
    {
        "CITYNAME": "Groningen",
        "CITYCODE": "GRQ",
        "COUNTRYCODE": "NL",
        "COUNTRYNAME": "Netherlands",
        "AIRPORTCODE": "GRQ",
        "AIRPORTNAME": "Eelde",
    },
    {
        "CITYNAME": "Grand Rapids",
        "CITYCODE": "GRR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GRR",
        "AIRPORTNAME": "Kent County Int'l",
    },
    {
        "CITYNAME": "Grosseto",
        "CITYCODE": "GRS",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "GRS",
        "AIRPORTNAME": "Grosseto Airport",
    },
    {
        "CITYNAME": "Groznyj",
        "CITYCODE": "GRV",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "GRV",
        "AIRPORTNAME": "Groznyj",
    },
    {
        "CITYNAME": "Graciosa Island",
        "CITYCODE": "GRW",
        "COUNTRYCODE": "PT",
        "COUNTRYNAME": "Portugal",
        "AIRPORTCODE": "GRW",
        "AIRPORTNAME": "Graciosa Island",
    },
    {
        "CITYNAME": "Granada",
        "CITYCODE": "GRX",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "GRX",
        "AIRPORTNAME": "Granada",
    },
    {
        "CITYNAME": "Grimsey",
        "CITYCODE": "GRY",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "GRY",
        "AIRPORTNAME": "Grimsey",
    },
    {
        "CITYNAME": "Graz",
        "CITYCODE": "GRZ",
        "COUNTRYCODE": "AT",
        "COUNTRYNAME": "Austria",
        "AIRPORTCODE": "GGZ",
        "AIRPORTNAME": "Graz Hauptbahnhof Airport",
    },
    {
        "CITYNAME": "Graz",
        "CITYCODE": "GRZ",
        "COUNTRYCODE": "AT",
        "COUNTRYNAME": "Austria",
        "AIRPORTCODE": "GRZ",
        "AIRPORTNAME": "Thalerhof",
    },
    {
        "CITYNAME": "Long Pasia",
        "CITYCODE": "GSA",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "GSA",
        "AIRPORTNAME": "Long Pasia",
    },
    {
        "CITYNAME": "Greensboro",
        "CITYCODE": "GSO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GSO",
        "AIRPORTNAME": "Greensboro",
    },
    {
        "CITYNAME": "Greensboro",
        "CITYCODE": "GSO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "INT",
        "AIRPORTNAME": "Smith-Reynolds",
    },
    {
        "CITYNAME": "Greenville",
        "CITYCODE": "GSP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GSP",
        "AIRPORTNAME": "Greenville-Spartanbur",
    },
    {
        "CITYNAME": "Groote Eylandt",
        "CITYCODE": "GTE",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "GTE",
        "AIRPORTNAME": "Alyangula",
    },
    {
        "CITYNAME": "Great Falls",
        "CITYCODE": "GTF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GTF",
        "AIRPORTNAME": "Great Fall Int'ls",
    },
    {
        "CITYNAME": "Gorontalo",
        "CITYCODE": "GTO",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "GTO",
        "AIRPORTNAME": "Tolotio",
    },
    {
        "CITYNAME": "Guatemala City",
        "CITYCODE": "GUA",
        "COUNTRYCODE": "GT",
        "COUNTRYNAME": "Guatemala",
        "AIRPORTCODE": "GUA",
        "AIRPORTNAME": "La Aurora",
    },
    {
        "CITYNAME": "Gunnison",
        "CITYCODE": "GUC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GUC",
        "AIRPORTNAME": "Gunnison",
    },
    {
        "CITYNAME": "Goundam",
        "CITYCODE": "GUD",
        "COUNTRYCODE": "ML",
        "COUNTRYNAME": "Mali",
        "AIRPORTCODE": "GUD",
        "AIRPORTNAME": "Goundam",
    },
    {
        "CITYNAME": "Gunnedah",
        "CITYCODE": "GUH",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "GUH",
        "AIRPORTNAME": "Gunnedah",
    },
    {
        "CITYNAME": "Guiria",
        "CITYCODE": "GUI",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "GUI",
        "AIRPORTNAME": "Guiria",
    },
    {
        "CITYNAME": "Guam",
        "CITYCODE": "GUM",
        "COUNTRYCODE": "GU",
        "COUNTRYNAME": "Guam",
        "AIRPORTCODE": "GUM",
        "AIRPORTNAME": "A.B Won Pat Intl",
    },
    {
        "CITYNAME": "Gallup",
        "CITYCODE": "GUP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GUP",
        "AIRPORTNAME": "Senator Clark",
    },
    {
        "CITYNAME": "Guanare",
        "CITYCODE": "GUQ",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "GUQ",
        "AIRPORTNAME": "Guanare",
    },
    {
        "CITYNAME": "Alotau",
        "CITYCODE": "GUR",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "GUR",
        "AIRPORTNAME": "Gurney",
    },
    {
        "CITYNAME": "Mougulu",
        "CITYCODE": "GUV",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "GUV",
        "AIRPORTNAME": "Mougulu",
    },
    {
        "CITYNAME": "Atyrau",
        "CITYCODE": "GUW",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "GUW",
        "AIRPORTNAME": "Atyrau",
    },
    {
        "CITYNAME": "Geneva",
        "CITYCODE": "GVA",
        "COUNTRYCODE": "CH",
        "COUNTRYNAME": "Switzerland",
        "AIRPORTCODE": "GVA",
        "AIRPORTNAME": "Geneva",
    },
    {
        "CITYNAME": "Green River",
        "CITYCODE": "GVI",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "GVI",
        "AIRPORTNAME": "Green River",
    },
    {
        "CITYNAME": "Governador Valada",
        "CITYCODE": "GVR",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "GVR",
        "AIRPORTNAME": "Governador Valada",
    },
    {
        "CITYNAME": "Gavle",
        "CITYCODE": "GVX",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "GVX",
        "AIRPORTNAME": "Sandviien",
    },
    {
        "CITYNAME": "Gwadar",
        "CITYCODE": "GWD",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "GWD",
        "AIRPORTNAME": "Gwadar",
    },
    {
        "CITYNAME": "Gweru",
        "CITYCODE": "GWE",
        "COUNTRYCODE": "ZW",
        "COUNTRYNAME": "Zimbabwe",
        "AIRPORTCODE": "GWE",
        "AIRPORTNAME": "Gweru",
    },
    {
        "CITYNAME": "Gwalior",
        "CITYCODE": "GWL",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "GWL",
        "AIRPORTNAME": "Gwalior",
    },
    {
        "CITYNAME": "Westerland",
        "CITYCODE": "GWT",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "GWT",
        "AIRPORTNAME": "Westerland",
    },
    {
        "CITYNAME": "Galway",
        "CITYCODE": "GWY",
        "COUNTRYCODE": "IE",
        "COUNTRYNAME": "Ireland",
        "AIRPORTCODE": "GWY",
        "AIRPORTNAME": "Galway",
    },
    {
        "CITYNAME": "Seiyun",
        "CITYCODE": "GXF",
        "COUNTRYCODE": "YE",
        "COUNTRYNAME": "Yemen",
        "AIRPORTCODE": "GXF",
        "AIRPORTNAME": "Seiyun",
    },
    {
        "CITYNAME": "Negage",
        "CITYCODE": "GXG",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "GXG",
        "AIRPORTNAME": "Negage",
    },
    {
        "CITYNAME": "Coyhaique",
        "CITYCODE": "GXQ",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "GXQ",
        "AIRPORTNAME": "Coyhaique",
    },
    {
        "CITYNAME": "Yagoua",
        "CITYCODE": "GXX",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "GXX",
        "AIRPORTNAME": "Yagoua",
    },
    {
        "CITYNAME": "Guayaramerin",
        "CITYCODE": "GYA",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "GYA",
        "AIRPORTNAME": "Guayaramerin",
    },
    {
        "CITYNAME": "Baku",
        "CITYCODE": "GYD",
        "COUNTRYCODE": "AZ",
        "COUNTRYNAME": "Azerbaijan",
        "AIRPORTCODE": "GYD",
        "AIRPORTNAME": "HEYDAR ALIYEV INTL ARPT",
    },
    {
        "CITYNAME": "Guayaquil",
        "CITYCODE": "GYE",
        "COUNTRYCODE": "EC",
        "COUNTRYNAME": "Ecuador",
        "AIRPORTCODE": "GYE",
        "AIRPORTNAME": "Guayaquil",
    },
    {
        "CITYNAME": "Gisenyi",
        "CITYCODE": "GYI",
        "COUNTRYCODE": "RW",
        "COUNTRYNAME": "Ruanda",
        "AIRPORTCODE": "GYI",
        "AIRPORTNAME": "Gisenyi",
    },
    {
        "CITYNAME": "Guaymas",
        "CITYCODE": "GYM",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "GYM",
        "AIRPORTNAME": "Gen Jose M Yanez",
    },
    {
        "CITYNAME": "Goiania",
        "CITYCODE": "GYN",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "GYN",
        "AIRPORTNAME": "Santa Genoveva",
    },
    {
        "CITYNAME": "Goodyear",
        "CITYCODE": "GYR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GYR",
        "AIRPORTNAME": "Litchfield",
    },
    {
        "CITYNAME": "Ghaziabad",
        "CITYCODE": "GZB",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "VDX",
        "AIRPORTNAME": "Hindon",
    },
    {
        "CITYNAME": "Gizo",
        "CITYCODE": "GZO",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "GZO",
        "AIRPORTNAME": "Gizo",
    },
    {
        "CITYNAME": "Gazipasa",
        "CITYCODE": "GZP",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "GZP",
        "AIRPORTNAME": "Gazipasa",
    },
    {
        "CITYNAME": "Gaziantep",
        "CITYCODE": "GZT",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "GZT",
        "AIRPORTNAME": "Gaziantep",
    },
    {
        "CITYNAME": "Hasvik",
        "CITYCODE": "HAA",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "HAA",
        "AIRPORTNAME": "Hasvik",
    },
    {
        "CITYNAME": "Hachijo Jima",
        "CITYCODE": "HAC",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "HAC",
        "AIRPORTNAME": "Hachijo Jima",
    },
    {
        "CITYNAME": "Halmstad",
        "CITYCODE": "HAD",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "HAD",
        "AIRPORTNAME": "Halmstad",
    },
    {
        "CITYNAME": "Havasupai",
        "CITYCODE": "HAE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HAE",
        "AIRPORTNAME": "Havasupai",
    },
    {
        "CITYNAME": "Three Rivers",
        "CITYCODE": "HAI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HAI",
        "AIRPORTNAME": "DR HAINES",
    },
    {
        "CITYNAME": "Hanover",
        "CITYCODE": "HAJ",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "HAJ",
        "AIRPORTNAME": "Hanover",
    },
    {
        "CITYNAME": "Haikou",
        "CITYCODE": "HAK",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "HAK",
        "AIRPORTNAME": "Haikou",
    },
    {
        "CITYNAME": "Hamburg",
        "CITYCODE": "HAM",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "HAM",
        "AIRPORTNAME": "Fuhlsbuttel",
    },
    {
        "CITYNAME": "Hamburg",
        "CITYCODE": "HAM",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "LBC",
        "AIRPORTNAME": "Luebeck-Blankensee",
    },
    {
        "CITYNAME": "Hanoi",
        "CITYCODE": "HAN",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "HAN",
        "AIRPORTNAME": "HANOI",
    },
    {
        "CITYNAME": "Long Island",
        "CITYCODE": "HAP",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "HAP",
        "AIRPORTNAME": "Long Island",
    },
    {
        "CITYNAME": "Hanimaadhoo",
        "CITYCODE": "HAQ",
        "COUNTRYCODE": "MV",
        "COUNTRYNAME": "Maldives",
        "AIRPORTCODE": "HAQ",
        "AIRPORTNAME": "Hanimaadhoo Airport",
    },
    {
        "CITYNAME": "Harrisburg",
        "CITYCODE": "HAR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HAR",
        "AIRPORTNAME": "Harrisburg Skyport",
    },
    {
        "CITYNAME": "Harrisburg",
        "CITYCODE": "HAR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MDT",
        "AIRPORTNAME": "Harrisburg Int'l",
    },
    {
        "CITYNAME": "Hail",
        "CITYCODE": "HAS",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "HAS",
        "AIRPORTNAME": "HAIL",
    },
    {
        "CITYNAME": "Haugesund",
        "CITYCODE": "HAU",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "HAU",
        "AIRPORTNAME": "Haugesund",
    },
    {
        "CITYNAME": "Havana",
        "CITYCODE": "HAV",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "HAV",
        "AIRPORTNAME": "Jose Marti Int'l",
    },
    {
        "CITYNAME": "Hobart",
        "CITYCODE": "HBA",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "HBA",
        "AIRPORTNAME": "HBA",
    },
    {
        "CITYNAME": "Hubli",
        "CITYCODE": "HBX",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "HBX",
        "AIRPORTNAME": "Hubli",
    },
    {
        "CITYNAME": "Eil",
        "CITYCODE": "HCM",
        "COUNTRYCODE": "SO",
        "COUNTRYNAME": "Somalia",
        "AIRPORTCODE": "HCM",
        "AIRPORTNAME": "Eyl Airport",
    },
    {
        "CITYNAME": "Holy Cross",
        "CITYCODE": "HCR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HCR",
        "AIRPORTNAME": "Holy Cross",
    },
    {
        "CITYNAME": "Heringsdorf",
        "CITYCODE": "HDF",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "HDF",
        "AIRPORTNAME": "Heringsdorf - Garz",
    },
    {
        "CITYNAME": "Hamadan",
        "CITYCODE": "HDM",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "HDM",
        "AIRPORTNAME": "Hamadan",
    },
    {
        "CITYNAME": "Hoedspruit Transv",
        "CITYCODE": "HDS",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "HDS",
        "AIRPORTNAME": "Hoedspruit Airport",
    },
    {
        "CITYNAME": "Hat Yai",
        "CITYCODE": "HDY",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "HDY",
        "AIRPORTNAME": "Hat yai",
    },
    {
        "CITYNAME": "Herat",
        "CITYCODE": "HEA",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "HEA",
        "AIRPORTNAME": "Herat",
    },
    {
        "CITYNAME": "Heho",
        "CITYCODE": "HEH",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "HEH",
        "AIRPORTNAME": "Heho",
    },
    {
        "CITYNAME": "Heide Buesum",
        "CITYCODE": "HEI",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "HEI",
        "AIRPORTNAME": "Heide Buesum",
    },
    {
        "CITYNAME": "Helsinki",
        "CITYCODE": "HEL",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "HEL",
        "AIRPORTNAME": "Helsinki-Vantaa",
    },
    {
        "CITYNAME": "Heraklion",
        "CITYCODE": "HER",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "HER",
        "AIRPORTNAME": "N Kazantzakis",
    },
    {
        "CITYNAME": "Hohhot",
        "CITYCODE": "HET",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "HET",
        "AIRPORTNAME": "Hohhot",
    },
    {
        "CITYNAME": "Haifa",
        "CITYCODE": "HFA",
        "COUNTRYCODE": "IL",
        "COUNTRYNAME": "Israel",
        "AIRPORTCODE": "HFA",
        "AIRPORTNAME": "Haifa",
    },
    {
        "CITYNAME": "Hartford",
        "CITYCODE": "HFD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BDL",
        "AIRPORTNAME": "Bradley Int'l",
    },
    {
        "CITYNAME": "Hartford",
        "CITYCODE": "HFD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HFD",
        "AIRPORTNAME": "Brainard",
    },
    {
        "CITYNAME": "Hefei",
        "CITYCODE": "HFE",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "HFE",
        "AIRPORTNAME": "Hefei",
    },
    {
        "CITYNAME": "Hornafjordur",
        "CITYCODE": "HFN",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "HFN",
        "AIRPORTNAME": "Hornafjordur",
    },
    {
        "CITYNAME": "Hagfors",
        "CITYCODE": "HFS",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "HFS",
        "AIRPORTNAME": "Hagfors Airport",
    },
    {
        "CITYNAME": "Hammerfest",
        "CITYCODE": "HFT",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "HFT",
        "AIRPORTNAME": "Hammerfest",
    },
    {
        "CITYNAME": "Hargeisa",
        "CITYCODE": "HGA",
        "COUNTRYCODE": "SO",
        "COUNTRYNAME": "Somalia",
        "AIRPORTCODE": "HGA",
        "AIRPORTNAME": "Hargeisa",
    },
    {
        "CITYNAME": "Hughenden",
        "CITYCODE": "HGD",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "HGD",
        "AIRPORTNAME": "Hughenden",
    },
    {
        "CITYNAME": "Hangzhou",
        "CITYCODE": "HGH",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "HGH",
        "AIRPORTNAME": "Hangzhou",
    },
    {
        "CITYNAME": "Itanagar",
        "CITYCODE": "HGI",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "HGI",
        "AIRPORTNAME": "Itanagar Airport",
    },
    {
        "CITYNAME": "Helgoland",
        "CITYCODE": "HGL",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "HGL",
        "AIRPORTNAME": "Helgoland",
    },
    {
        "CITYNAME": "Mae Hong Son",
        "CITYCODE": "HGN",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "HGN",
        "AIRPORTNAME": "Mae Hong Son",
    },
    {
        "CITYNAME": "Korhogo",
        "CITYCODE": "HGO",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "HGO",
        "AIRPORTNAME": "Korhogo",
    },
    {
        "CITYNAME": "Hagerstown",
        "CITYCODE": "HGR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HGR",
        "AIRPORTNAME": "Wash. County Regional",
    },
    {
        "CITYNAME": "Mount Hagen",
        "CITYCODE": "HGU",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "HGU",
        "AIRPORTNAME": "Kagamuga",
    },
    {
        "CITYNAME": "Hilton Head",
        "CITYCODE": "HHH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HHH",
        "AIRPORTNAME": "Hilton Head",
    },
    {
        "CITYNAME": "Hua Hin",
        "CITYCODE": "HHQ",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "HHQ",
        "AIRPORTNAME": "Hua Hin",
    },
    {
        "CITYNAME": "Hawthorne",
        "CITYCODE": "HHR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HHR",
        "AIRPORTNAME": "Hawthorne",
    },
    {
        "CITYNAME": "Hibbing",
        "CITYCODE": "HIB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HIB",
        "AIRPORTNAME": "Chisholm",
    },
    {
        "CITYNAME": "Hiroshima",
        "CITYCODE": "HIJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "HIJ",
        "AIRPORTNAME": "Hiroshima Int'l",
    },
    {
        "CITYNAME": "Chinju",
        "CITYCODE": "HIN",
        "COUNTRYCODE": "KR",
        "COUNTRYNAME": "South Korea",
        "AIRPORTCODE": "HIN",
        "AIRPORTNAME": "Sacheon",
    },
    {
        "CITYNAME": "Honiara",
        "CITYCODE": "HIR",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "HIR",
        "AIRPORTNAME": "Henderson Int'l",
    },
    {
        "CITYNAME": "Hayman Island",
        "CITYCODE": "HIS",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "HIS",
        "AIRPORTNAME": "Hayman Island",
    },
    {
        "CITYNAME": "Khajuraho",
        "CITYCODE": "HJR",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "HJR",
        "AIRPORTNAME": "Khajuraho",
    },
    {
        "CITYNAME": "Hakodate",
        "CITYCODE": "HKD",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "HKD",
        "AIRPORTNAME": "Hakodate",
    },
    {
        "CITYNAME": "Hong Kong",
        "CITYCODE": "HKG",
        "COUNTRYCODE": "HK",
        "COUNTRYNAME": "Hong Kong",
        "AIRPORTCODE": "HKG",
        "AIRPORTNAME": "Hong Kong Int'l",
    },
    {
        "CITYNAME": "Hokitika",
        "CITYCODE": "HKK",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "HKK",
        "AIRPORTNAME": "Hokitika",
    },
    {
        "CITYNAME": "Hoskins",
        "CITYCODE": "HKN",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "HKN",
        "AIRPORTNAME": "Hoskins",
    },
    {
        "CITYNAME": "Phuket",
        "CITYCODE": "HKT",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "HKT",
        "AIRPORTNAME": "Phuket Int'l",
    },
    {
        "CITYNAME": "Hickory",
        "CITYCODE": "HKY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HKY",
        "AIRPORTNAME": "Hickory",
    },
    {
        "CITYNAME": "Lanseria",
        "CITYCODE": "HLA",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "HLA",
        "AIRPORTNAME": "Lanseria",
    },
    {
        "CITYNAME": "Hailar",
        "CITYCODE": "HLD",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "HLD",
        "AIRPORTNAME": "Hailar",
    },
    {
        "CITYNAME": "Hultsfred",
        "CITYCODE": "HLF",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "HLF",
        "AIRPORTNAME": "Hultsfred",
    },
    {
        "CITYNAME": "Ulanhot",
        "CITYCODE": "HLH",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "HLH",
        "AIRPORTNAME": "Ulanhot",
    },
    {
        "CITYNAME": "Helena",
        "CITYCODE": "HLN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HLN",
        "AIRPORTNAME": "Helena",
    },
    {
        "CITYNAME": "Holyhead",
        "CITYCODE": "HLY",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "HLY",
        "AIRPORTNAME": "Anglesey Airport",
    },
    {
        "CITYNAME": "Hamilton",
        "CITYCODE": "HLZ",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "HLZ",
        "AIRPORTNAME": "Hamilton",
    },
    {
        "CITYNAME": "Khanty-Mansiysk",
        "CITYCODE": "HMA",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "HMA",
        "AIRPORTNAME": "Khanty-Mansiysk Airport",
    },
    {
        "CITYNAME": "Sohag Governorate",
        "CITYCODE": "HMB",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "HMB",
        "AIRPORTNAME": "Sohag International Airport",
    },
    {
        "CITYNAME": "Hassi Messaoud",
        "CITYCODE": "HME",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "HME",
        "AIRPORTNAME": "Oued Irara",
    },
    {
        "CITYNAME": "Khmelnitskiy",
        "CITYCODE": "HMJ",
        "COUNTRYCODE": "UA",
        "COUNTRYNAME": "Ukraine",
        "AIRPORTCODE": "HMJ",
        "AIRPORTNAME": "Khmelnitskiy",
    },
    {
        "CITYNAME": "Hermosillo",
        "CITYCODE": "HMO",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "HMO",
        "AIRPORTNAME": "Gen Pesqueira Garcia",
    },
    {
        "CITYNAME": "Hemet",
        "CITYCODE": "HMT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HMT",
        "AIRPORTNAME": "Ryan Field",
    },
    {
        "CITYNAME": "Hemavan",
        "CITYCODE": "HMV",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "HMV",
        "AIRPORTNAME": "Hemavan - Tarneby",
    },
    {
        "CITYNAME": "Morioka",
        "CITYCODE": "HNA",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "HNA",
        "AIRPORTNAME": "Hanamaki",
    },
    {
        "CITYNAME": "Hoonah",
        "CITYCODE": "HNH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HNH",
        "AIRPORTNAME": "Hoonah",
    },
    {
        "CITYNAME": "Honolulu",
        "CITYCODE": "HNL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HNL",
        "AIRPORTNAME": "Honolulu Int'l",
    },
    {
        "CITYNAME": "Hana",
        "CITYCODE": "HNM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HNM",
        "AIRPORTNAME": "Hana",
    },
    {
        "CITYNAME": "Haines",
        "CITYCODE": "HNS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HNS",
        "AIRPORTNAME": "Haines Municipal",
    },
    {
        "CITYNAME": "Hengyang",
        "CITYCODE": "HNY",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "HNY",
        "AIRPORTNAME": "Hengyang",
    },
    {
        "CITYNAME": "Hobbs",
        "CITYCODE": "HOB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HOB",
        "AIRPORTNAME": "Lea County",
    },
    {
        "CITYNAME": "Hodeidah",
        "CITYCODE": "HOD",
        "COUNTRYCODE": "YE",
        "COUNTRYNAME": "Yemen",
        "AIRPORTCODE": "HOD",
        "AIRPORTNAME": "Hodeidah",
    },
    {
        "CITYNAME": "Houeisay",
        "CITYCODE": "HOE",
        "COUNTRYCODE": "LA",
        "COUNTRYNAME": "Lao People's Democratic Republic",
        "AIRPORTCODE": "HOE",
        "AIRPORTNAME": "Houeisay",
    },
    {
        "CITYNAME": "Hofuf",
        "CITYCODE": "HOF",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "HOF",
        "AIRPORTNAME": "HOF",
    },
    {
        "CITYNAME": "Holguin",
        "CITYCODE": "HOG",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "HOG",
        "AIRPORTNAME": "Frank Pais",
    },
    {
        "CITYNAME": "Hao Island",
        "CITYCODE": "HOI",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "HOI",
        "AIRPORTNAME": "Hao Island",
    },
    {
        "CITYNAME": "Hooker Creek",
        "CITYCODE": "HOK",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "HOK",
        "AIRPORTNAME": "Hooker Creek",
    },
    {
        "CITYNAME": "Homer",
        "CITYCODE": "HOM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HOM",
        "AIRPORTNAME": "Homer",
    },
    {
        "CITYNAME": "Huron",
        "CITYCODE": "HON",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HON",
        "AIRPORTNAME": "Howes",
    },
    {
        "CITYNAME": "Hof",
        "CITYCODE": "HOQ",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "HOQ",
        "AIRPORTNAME": "Hof",
    },
    {
        "CITYNAME": "Horta",
        "CITYCODE": "HOR",
        "COUNTRYCODE": "PT",
        "COUNTRYNAME": "Portugal",
        "AIRPORTCODE": "HOR",
        "AIRPORTNAME": "Horta",
    },
    {
        "CITYNAME": "Hot Springs",
        "CITYCODE": "HOT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HOT",
        "AIRPORTNAME": "Memorial Field",
    },
    {
        "CITYNAME": "Houston",
        "CITYCODE": "HOU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EFD",
        "AIRPORTNAME": "Ellington Field",
    },
    {
        "CITYNAME": "Houston",
        "CITYCODE": "HOU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HOU",
        "AIRPORTNAME": "Hobby",
    },
    {
        "CITYNAME": "Houston",
        "CITYCODE": "HOU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "IAH",
        "AIRPORTNAME": "Houston Intercontinental",
    },
    {
        "CITYNAME": "Orsta Volda",
        "CITYCODE": "HOV",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "HOV",
        "AIRPORTNAME": "Hovden",
    },
    {
        "CITYNAME": "Ha'apai",
        "CITYCODE": "HPA",
        "COUNTRYCODE": "TO",
        "COUNTRYNAME": "Tonga",
        "AIRPORTCODE": "HPA",
        "AIRPORTNAME": "Salote Pilolevu",
    },
    {
        "CITYNAME": "Hooper Bay",
        "CITYCODE": "HPB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HPB",
        "AIRPORTNAME": "Hooper Bay",
    },
    {
        "CITYNAME": "Haiphong",
        "CITYCODE": "HPH",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "HPH",
        "AIRPORTNAME": "Cat Bi",
    },
    {
        "CITYNAME": "Westchester County",
        "CITYCODE": "HPN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HPN",
        "AIRPORTNAME": "Westchester County",
    },
    {
        "CITYNAME": "Harbin",
        "CITYCODE": "HRB",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "HRB",
        "AIRPORTNAME": "Harbin",
    },
    {
        "CITYNAME": "Harare",
        "CITYCODE": "HRE",
        "COUNTRYCODE": "ZW",
        "COUNTRYNAME": "Zimbabwe",
        "AIRPORTCODE": "HRE",
        "AIRPORTNAME": "Harare",
    },
    {
        "CITYNAME": "Hurghada",
        "CITYCODE": "HRG",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "HRG",
        "AIRPORTNAME": "Hurghada",
    },
    {
        "CITYNAME": "Mattala",
        "CITYCODE": "HRI",
        "COUNTRYCODE": "LK",
        "COUNTRYNAME": "Sri Lanka",
        "AIRPORTCODE": "HRI",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Kharkov",
        "CITYCODE": "HRK",
        "COUNTRYCODE": "UA",
        "COUNTRYNAME": "Ukraine",
        "AIRPORTCODE": "HRK",
        "AIRPORTNAME": "Kharkov",
    },
    {
        "CITYNAME": "Harlingen",
        "CITYCODE": "HRL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HRL",
        "AIRPORTNAME": "Valley Int'l",
    },
    {
        "CITYNAME": "Harrison",
        "CITYCODE": "HRO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HRO",
        "AIRPORTNAME": "Boone County",
    },
    {
        "CITYNAME": "Saga",
        "CITYCODE": "HSG",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "HSG",
        "AIRPORTNAME": "Saga Airport",
    },
    {
        "CITYNAME": "Hastings",
        "CITYCODE": "HSI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HSI",
        "AIRPORTNAME": "Hastings",
    },
    {
        "CITYNAME": "Huesca",
        "CITYCODE": "HSK",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "HSK",
        "AIRPORTNAME": "Huesca - Pirineos",
    },
    {
        "CITYNAME": "Huslia",
        "CITYCODE": "HSL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HSL",
        "AIRPORTNAME": "Huslia",
    },
    {
        "CITYNAME": "Zhoushan",
        "CITYCODE": "HSN",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "HSN",
        "AIRPORTNAME": "Zhoushan Airport",
    },
    {
        "CITYNAME": "Hirasar Rajkot",
        "CITYCODE": "HSR",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "HSR",
        "AIRPORTNAME": "Rajkot Greenfield International Airport",
    },
    {
        "CITYNAME": "Homestead",
        "CITYCODE": "HST",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HST",
        "AIRPORTNAME": "Homestead",
    },
    {
        "CITYNAME": "Huntsville",
        "CITYCODE": "HSV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HSV",
        "AIRPORTNAME": "Madison County",
    },
    {
        "CITYNAME": "Chita",
        "CITYCODE": "HTA",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "HTA",
        "AIRPORTNAME": "Chita",
    },
    {
        "CITYNAME": "Hamilton Island",
        "CITYCODE": "HTI",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "HTI",
        "AIRPORTNAME": "Hamilton Island",
    },
    {
        "CITYNAME": "East Hampton",
        "CITYCODE": "HTO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HTO",
        "AIRPORTNAME": "East Hampton",
    },
    {
        "CITYNAME": "Hateruma",
        "CITYCODE": "HTR",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "HTR",
        "AIRPORTNAME": "Hateruma",
    },
    {
        "CITYNAME": "Ashland",
        "CITYCODE": "HTS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HTS",
        "AIRPORTNAME": "Huntington",
    },
    {
        "CITYNAME": "Hatay",
        "CITYCODE": "HTY",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "HTY",
        "AIRPORTNAME": "Hatay Airport",
    },
    {
        "CITYNAME": "Humbert River",
        "CITYCODE": "HUB",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "HUB",
        "AIRPORTNAME": "HUMBERT RIVER",
    },
    {
        "CITYNAME": "Humera",
        "CITYCODE": "HUE",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "HUE",
        "AIRPORTNAME": "Humera",
    },
    {
        "CITYNAME": "Terre Haute",
        "CITYCODE": "HUF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HUF",
        "AIRPORTNAME": "Hulman Field",
    },
    {
        "CITYNAME": "Huehuetenango",
        "CITYCODE": "HUG",
        "COUNTRYCODE": "GT",
        "COUNTRYNAME": "Guatemala",
        "AIRPORTCODE": "HUG",
        "AIRPORTNAME": "Huehuetenango Airport",
    },
    {
        "CITYNAME": "Huahine Island",
        "CITYCODE": "HUH",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "HUH",
        "AIRPORTNAME": "Flying Boat",
    },
    {
        "CITYNAME": "Hue",
        "CITYCODE": "HUI",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "HUI",
        "AIRPORTNAME": "Hue - Phu Bai",
    },
    {
        "CITYNAME": "Hukuntsi",
        "CITYCODE": "HUK",
        "COUNTRYCODE": "BW",
        "COUNTRYNAME": "Botswana",
        "AIRPORTCODE": "HUK",
        "AIRPORTNAME": "Hukuntsi",
    },
    {
        "CITYNAME": "Houma",
        "CITYCODE": "HUM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HUM",
        "AIRPORTNAME": "Houma - Terrebonne Airport",
    },
    {
        "CITYNAME": "Hualien",
        "CITYCODE": "HUN",
        "COUNTRYCODE": "TW",
        "COUNTRYNAME": "Taiwan",
        "AIRPORTCODE": "HUN",
        "AIRPORTNAME": "Hualien",
    },
    {
        "CITYNAME": "Houn",
        "CITYCODE": "HUQ",
        "COUNTRYCODE": "LY",
        "COUNTRYNAME": "Libyan Arab Jamahiriya",
        "AIRPORTCODE": "HUQ",
        "AIRPORTNAME": "Houn",
    },
    {
        "CITYNAME": "Hughes",
        "CITYCODE": "HUS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HUS",
        "AIRPORTNAME": "Hughes Municipal",
    },
    {
        "CITYNAME": "Hutchison",
        "CITYCODE": "HUT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HUT",
        "AIRPORTNAME": "Hutchison",
    },
    {
        "CITYNAME": "Hudiksvall",
        "CITYCODE": "HUV",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "HUV",
        "AIRPORTNAME": "Hudiksvall",
    },
    {
        "CITYNAME": "Humaita",
        "CITYCODE": "HUW",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "HUW",
        "AIRPORTNAME": "Humaita Airport",
    },
    {
        "CITYNAME": "Huatulco",
        "CITYCODE": "HUX",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "HUX",
        "AIRPORTNAME": "Huatulco",
    },
    {
        "CITYNAME": "Humberside",
        "CITYCODE": "HUY",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "HUY",
        "AIRPORTNAME": "Humberside",
    },
    {
        "CITYNAME": "Analalava",
        "CITYCODE": "HVA",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "HVA",
        "AIRPORTNAME": "Analalava",
    },
    {
        "CITYNAME": "Hervey Bay",
        "CITYCODE": "HVB",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "HVB",
        "AIRPORTNAME": "Hervey Bay",
    },
    {
        "CITYNAME": "Honningsvag",
        "CITYCODE": "HVG",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "HVG",
        "AIRPORTNAME": "Valan",
    },
    {
        "CITYNAME": "Hvammstangi",
        "CITYCODE": "HVM",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "BQD",
        "AIRPORTNAME": "Budardalur Airport",
    },
    {
        "CITYNAME": "New Haven",
        "CITYCODE": "HVN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HVN",
        "AIRPORTNAME": "New Haven",
    },
    {
        "CITYNAME": "Havre",
        "CITYCODE": "HVR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HVR",
        "AIRPORTNAME": "Havre City County",
    },
    {
        "CITYNAME": "Hwange National Park",
        "CITYCODE": "HWN",
        "COUNTRYCODE": "ZW",
        "COUNTRYNAME": "Zimbabwe",
        "AIRPORTCODE": "HWN",
        "AIRPORTNAME": "Hwange Nat Park",
    },
    {
        "CITYNAME": "Hollywood",
        "CITYCODE": "HWO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HWO",
        "AIRPORTNAME": "Norht Perry",
    },
    {
        "CITYNAME": "Hay",
        "CITYCODE": "HXX",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "HXX",
        "AIRPORTNAME": "Hay",
    },
    {
        "CITYNAME": "Hyannis",
        "CITYCODE": "HYA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HYA",
        "AIRPORTNAME": "Barnstable",
    },
    {
        "CITYNAME": "Hyderabad",
        "CITYCODE": "HYD",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "HYD",
        "AIRPORTNAME": "Rajiv Gandhi International Airport",
    },
    {
        "CITYNAME": "Hydaburg",
        "CITYCODE": "HYG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HYG",
        "AIRPORTNAME": "Hydaburg",
    },
    {
        "CITYNAME": "Hollis",
        "CITYCODE": "HYL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HYL",
        "AIRPORTNAME": "Hollis",
    },
    {
        "CITYNAME": "Huangyan",
        "CITYCODE": "HYN",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "HYN",
        "AIRPORTNAME": "Huangyan",
    },
    {
        "CITYNAME": "Hays",
        "CITYCODE": "HYS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HYS",
        "AIRPORTNAME": "Hays Municipal",
    },
    {
        "CITYNAME": "Hangzhou",
        "CITYCODE": "HZD",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "HZD",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Hanzhong",
        "CITYCODE": "HZG",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "HZG",
        "AIRPORTNAME": "Hanzhong",
    },
    {
        "CITYNAME": "Husavik",
        "CITYCODE": "HZK",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "HZK",
        "AIRPORTNAME": "Husavik",
    },
    {
        "CITYNAME": "Igarka",
        "CITYCODE": "IAA",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "IAA",
        "AIRPORTNAME": "Igarka",
    },
    {
        "CITYNAME": "Niagara Falls",
        "CITYCODE": "IAG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "IAG",
        "AIRPORTNAME": "Niagara Falls Int'l",
    },
    {
        "CITYNAME": "In Amenas",
        "CITYCODE": "IAM",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "IAM",
        "AIRPORTNAME": "In Amenas",
    },
    {
        "CITYNAME": "Kiana",
        "CITYCODE": "IAN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "IAN",
        "AIRPORTNAME": "Bob Barker Memorial",
    },
    {
        "CITYNAME": "Siargao Island",
        "CITYCODE": "IAO",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "IAO",
        "AIRPORTNAME": "Sayak Airport",
    },
    {
        "CITYNAME": "Yaroslavl",
        "CITYCODE": "IAR",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "IAR",
        "AIRPORTNAME": "Tunoshna Airport",
    },
    {
        "CITYNAME": "Iasi",
        "CITYCODE": "IAS",
        "COUNTRYCODE": "RO",
        "COUNTRYNAME": "Romania",
        "AIRPORTCODE": "IAS",
        "AIRPORTNAME": "Iasi",
    },
    {
        "CITYNAME": "Ibadan",
        "CITYCODE": "IBA",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "IBA",
        "AIRPORTNAME": "Ibadan",
    },
    {
        "CITYNAME": "Ibague",
        "CITYCODE": "IBE",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "IBE",
        "AIRPORTNAME": "Ibague",
    },
    {
        "CITYNAME": "Omitama",
        "CITYCODE": "IBR",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "IBR",
        "AIRPORTNAME": "Ibaraki Airport",
    },
    {
        "CITYNAME": "Bologna",
        "CITYCODE": "IBT",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "IBT",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Ibiza",
        "CITYCODE": "IBZ",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "IBZ",
        "AIRPORTNAME": "Ibiza",
    },
    {
        "CITYNAME": "Cicia",
        "CITYCODE": "ICI",
        "COUNTRYCODE": "FJ",
        "COUNTRYNAME": "Fiji Islands",
        "AIRPORTCODE": "ICI",
        "AIRPORTNAME": "Cicia",
    },
    {
        "CITYNAME": "Nieuw Nickerie",
        "CITYCODE": "ICK",
        "COUNTRYCODE": "SR",
        "COUNTRYNAME": "Suriname",
        "AIRPORTCODE": "ICK",
        "AIRPORTNAME": "Nieuw Nickerie",
    },
    {
        "CITYNAME": "Wichita",
        "CITYCODE": "ICT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BEC",
        "AIRPORTNAME": "Wichita Beech",
    },
    {
        "CITYNAME": "Wichita",
        "CITYCODE": "ICT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CEA",
        "AIRPORTNAME": "Cessna Aircraft Field",
    },
    {
        "CITYNAME": "Wichita",
        "CITYCODE": "ICT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ICT",
        "AIRPORTNAME": "Mid-Continent",
    },
    {
        "CITYNAME": "Idaho Falls",
        "CITYCODE": "IDA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "IDA",
        "AIRPORTNAME": "Fanning Field",
    },
    {
        "CITYNAME": "Indagen",
        "CITYCODE": "IDN",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "IDN",
        "AIRPORTNAME": "Indagen",
    },
    {
        "CITYNAME": "Indore",
        "CITYCODE": "IDR",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IDR",
        "AIRPORTNAME": "Devi Ahilya Bai Holkar International Airport",
    },
    {
        "CITYNAME": "Zielona Gora",
        "CITYCODE": "IEG",
        "COUNTRYCODE": "PL",
        "COUNTRYNAME": "Poland",
        "AIRPORTCODE": "IEG",
        "AIRPORTNAME": "Zielona Gora - Babimost Airport",
    },
    {
        "CITYNAME": "Kiev",
        "CITYCODE": "IEV",
        "COUNTRYCODE": "UA",
        "COUNTRYNAME": "Ukraine",
        "AIRPORTCODE": "IEV",
        "AIRPORTNAME": "Zhulhany",
    },
    {
        "CITYNAME": "Kiev",
        "CITYCODE": "IEV",
        "COUNTRYCODE": "UA",
        "COUNTRYNAME": "Ukraine",
        "AIRPORTCODE": "KBP",
        "AIRPORTNAME": "Borispol",
    },
    {
        "CITYNAME": "Isafjordur",
        "CITYCODE": "IFJ",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "IFJ",
        "AIRPORTNAME": "Isafjordur",
    },
    {
        "CITYNAME": "Isfahan",
        "CITYCODE": "IFN",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "IFN",
        "AIRPORTNAME": "Isfahan",
    },
    {
        "CITYNAME": "Ivano Frankovsk",
        "CITYCODE": "IFO",
        "COUNTRYCODE": "UA",
        "COUNTRYNAME": "Ukraine",
        "AIRPORTCODE": "IFO",
        "AIRPORTNAME": "Ivano Frankovsk",
    },
    {
        "CITYNAME": "Inagua",
        "CITYCODE": "IGA",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "IGA",
        "AIRPORTNAME": "Inagua",
    },
    {
        "CITYNAME": "Igdir",
        "CITYCODE": "IGD",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "IGD",
        "AIRPORTNAME": "Igdir Airport",
    },
    {
        "CITYNAME": "Igiugig",
        "CITYCODE": "IGG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "IGG",
        "AIRPORTNAME": "Igiugig",
    },
    {
        "CITYNAME": "Kingman",
        "CITYCODE": "IGM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "IGM",
        "AIRPORTNAME": "Kingman",
    },
    {
        "CITYNAME": "Chigorodo",
        "CITYCODE": "IGO",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "IGO",
        "AIRPORTNAME": "Chigorodo",
    },
    {
        "CITYNAME": "Iguazu",
        "CITYCODE": "IGR",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "IGR",
        "AIRPORTNAME": "Cataratas",
    },
    {
        "CITYNAME": "Magas",
        "CITYCODE": "IGT",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "IGT",
        "AIRPORTNAME": "Magas Airport",
    },
    {
        "CITYNAME": "Iguassu Falls",
        "CITYCODE": "IGU",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "IGU",
        "AIRPORTNAME": "Cataratas",
    },
    {
        "CITYNAME": "Qishn",
        "CITYCODE": "IHN",
        "COUNTRYCODE": "YE",
        "COUNTRYNAME": "Yemen",
        "AIRPORTCODE": "IHN",
        "AIRPORTNAME": "Qishn",
    },
    {
        "CITYNAME": "Ihosy",
        "CITYCODE": "IHO",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "IHO",
        "AIRPORTNAME": "Ihosy",
    },
    {
        "CITYNAME": "Ihu",
        "CITYCODE": "IHU",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "IHU",
        "AIRPORTNAME": "Ihu",
    },
    {
        "CITYNAME": "Inishmaan",
        "CITYCODE": "IIA",
        "COUNTRYCODE": "IE",
        "COUNTRYNAME": "Ireland",
        "AIRPORTCODE": "IIA",
        "AIRPORTNAME": "Inishmaan",
    },
    {
        "CITYNAME": "Izhevsk",
        "CITYCODE": "IJK",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "IJK",
        "AIRPORTNAME": "Izhevsk Airport",
    },
    {
        "CITYNAME": "Iki",
        "CITYCODE": "IKI",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "IKI",
        "AIRPORTNAME": "Iki",
    },
    {
        "CITYNAME": "Ikela",
        "CITYCODE": "IKL",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "IKL",
        "AIRPORTNAME": "Ikela",
    },
    {
        "CITYNAME": "Tiksi",
        "CITYCODE": "IKS",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "IKS",
        "AIRPORTNAME": "Tiksi",
    },
    {
        "CITYNAME": "Irkutsk",
        "CITYCODE": "IKT",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "IKT",
        "AIRPORTNAME": "Irkutsk",
    },
    {
        "CITYNAME": "Illaga",
        "CITYCODE": "ILA",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "ILA",
        "AIRPORTNAME": "Illaga",
    },
    {
        "CITYNAME": "Lleida",
        "CITYCODE": "ILD",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "ILD",
        "AIRPORTNAME": "Alguaire",
    },
    {
        "CITYNAME": "Killeen",
        "CITYCODE": "ILE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ILE",
        "AIRPORTNAME": "Killeen Municipal",
    },
    {
        "CITYNAME": "Wilmington",
        "CITYCODE": "ILG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ILG",
        "AIRPORTNAME": "Greater Wilmington",
    },
    {
        "CITYNAME": "Iliamna",
        "CITYCODE": "ILI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ILI",
        "AIRPORTNAME": "Iliamna",
    },
    {
        "CITYNAME": "Ilaka",
        "CITYCODE": "ILK",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "ILK",
        "AIRPORTNAME": "Ilaka",
    },
    {
        "CITYNAME": "Wilmington",
        "CITYCODE": "ILM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ILM",
        "AIRPORTNAME": "New Hanover County",
    },
    {
        "CITYNAME": "Iloilo",
        "CITYCODE": "ILO",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "ILO",
        "AIRPORTNAME": "Mandurriao",
    },
    {
        "CITYNAME": "Ile Des Pins",
        "CITYCODE": "ILP",
        "COUNTRYCODE": "NC",
        "COUNTRYNAME": "New Caledonia",
        "AIRPORTCODE": "ILP",
        "AIRPORTNAME": "Ile Des Pins",
    },
    {
        "CITYNAME": "Ilorin",
        "CITYCODE": "ILR",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "ILR",
        "AIRPORTNAME": "Ilorin",
    },
    {
        "CITYNAME": "Kilaguni",
        "CITYCODE": "ILU",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "ILU",
        "AIRPORTNAME": "Kilaguni",
    },
    {
        "CITYNAME": "Islay",
        "CITYCODE": "ILY",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "ILY",
        "AIRPORTNAME": "Glenegedale",
    },
    {
        "CITYNAME": "Iamalele",
        "CITYCODE": "IMA",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "IMA",
        "AIRPORTNAME": "Iamalele",
    },
    {
        "CITYNAME": "Imonda",
        "CITYCODE": "IMD",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "IMD",
        "AIRPORTNAME": "Imonda",
    },
    {
        "CITYNAME": "Imphal",
        "CITYCODE": "IMF",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IMF",
        "AIRPORTNAME": "Tulihal International Airport",
    },
    {
        "CITYNAME": "Ine Island",
        "CITYCODE": "IMI",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "IMI",
        "AIRPORTNAME": "Ine Island",
    },
    {
        "CITYNAME": "Zemio",
        "CITYCODE": "IMO",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "IMO",
        "AIRPORTNAME": "Zemio",
    },
    {
        "CITYNAME": "Imperatriz",
        "CITYCODE": "IMP",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "IMP",
        "AIRPORTNAME": "Imperatriz",
    },
    {
        "CITYNAME": "Milan",
        "CITYCODE": "IMR",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "IMR",
        "AIRPORTNAME": "Milano Rogoredo railway station",
    },
    {
        "CITYNAME": "Iron Mountain",
        "CITYCODE": "IMT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "IMT",
        "AIRPORTNAME": "Ford",
    },
    {
        "CITYNAME": "Yinchuan",
        "CITYCODE": "INC",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "INC",
        "AIRPORTNAME": "Yinchuan",
    },
    {
        "CITYNAME": "Indianapolis",
        "CITYCODE": "IND",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "IND",
        "AIRPORTNAME": "Indianapolis Int'l",
    },
    {
        "CITYNAME": "In Guezzam",
        "CITYCODE": "INF",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "INF",
        "AIRPORTNAME": "In Guezzam",
    },
    {
        "CITYNAME": "Lago Argentino",
        "CITYCODE": "ING",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "ING",
        "AIRPORTNAME": "Lago Argentino",
    },
    {
        "CITYNAME": "Inhambane",
        "CITYCODE": "INH",
        "COUNTRYCODE": "MZ",
        "COUNTRYNAME": "Mozambique",
        "AIRPORTCODE": "INH",
        "AIRPORTNAME": "Inhambane",
    },
    {
        "CITYNAME": "Nis",
        "CITYCODE": "INI",
        "COUNTRYCODE": "YU",
        "COUNTRYNAME": "Yugoslavia",
        "AIRPORTCODE": "INI",
        "AIRPORTNAME": "Nis",
    },
    {
        "CITYNAME": "Intl Falls",
        "CITYCODE": "INL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "INL",
        "AIRPORTNAME": "Int'l Falls",
    },
    {
        "CITYNAME": "Innsbruck",
        "CITYCODE": "INN",
        "COUNTRYCODE": "AT",
        "COUNTRYNAME": "Austria",
        "AIRPORTCODE": "INN",
        "AIRPORTNAME": "Kranebitten",
    },
    {
        "CITYNAME": "Inongo",
        "CITYCODE": "INO",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "INO",
        "AIRPORTNAME": "Inongo",
    },
    {
        "CITYNAME": "Naples",
        "CITYCODE": "INP",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "INP",
        "AIRPORTNAME": "Naples Central Train Station",
    },
    {
        "CITYNAME": "Inisheer",
        "CITYCODE": "INQ",
        "COUNTRYCODE": "IE",
        "COUNTRYNAME": "Ireland",
        "AIRPORTCODE": "INQ",
        "AIRPORTNAME": "Inisheer",
    },
    {
        "CITYNAME": "Nauru Island",
        "CITYCODE": "INU",
        "COUNTRYCODE": "NR",
        "COUNTRYNAME": "Nauru",
        "AIRPORTCODE": "INU",
        "AIRPORTNAME": "Nauru Island Int'l",
    },
    {
        "CITYNAME": "Inverness",
        "CITYCODE": "INV",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "INV",
        "AIRPORTNAME": "Inverness",
    },
    {
        "CITYNAME": "Inanwatan",
        "CITYCODE": "INX",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "INX",
        "AIRPORTNAME": "Inanwatan",
    },
    {
        "CITYNAME": "In Salah",
        "CITYCODE": "INZ",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "INZ",
        "AIRPORTNAME": "In Salah",
    },
    {
        "CITYNAME": "Ioannina",
        "CITYCODE": "IOA",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "IOA",
        "AIRPORTNAME": "Ioannina",
    },
    {
        "CITYNAME": "Innsbruck",
        "CITYCODE": "IOB",
        "COUNTRYCODE": "AT",
        "COUNTRYNAME": "Austria",
        "AIRPORTCODE": "IOB",
        "AIRPORTNAME": "Innsbruck Hbf",
    },
    {
        "CITYNAME": "Isle Of Man",
        "CITYCODE": "IOM",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "IOM",
        "AIRPORTNAME": "Ronaldsway",
    },
    {
        "CITYNAME": "Impfondo",
        "CITYCODE": "ION",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "ION",
        "AIRPORTNAME": "Impfondo",
    },
    {
        "CITYNAME": "Ioma",
        "CITYCODE": "IOP",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "IOP",
        "AIRPORTNAME": "Ioma",
    },
    {
        "CITYNAME": "Inishmore",
        "CITYCODE": "IOR",
        "COUNTRYCODE": "IE",
        "COUNTRYNAME": "Ireland",
        "AIRPORTCODE": "IOR",
        "AIRPORTNAME": "Kilronan",
    },
    {
        "CITYNAME": "Ilheus",
        "CITYCODE": "IOS",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "IOS",
        "AIRPORTNAME": "Eduardo Gomes",
    },
    {
        "CITYNAME": "Ipota",
        "CITYCODE": "IPA",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "IPA",
        "AIRPORTNAME": "Ipota",
    },
    {
        "CITYNAME": "Easter Island",
        "CITYCODE": "IPC",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "IPC",
        "AIRPORTNAME": "Mataveri Int'l",
    },
    {
        "CITYNAME": "Ipiranga",
        "CITYCODE": "IPG",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "IPG",
        "AIRPORTNAME": "Ipiranga",
    },
    {
        "CITYNAME": "Ipoh",
        "CITYCODE": "IPH",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "IPH",
        "AIRPORTNAME": "Ipoh",
    },
    {
        "CITYNAME": "Ipiales",
        "CITYCODE": "IPI",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "IPI",
        "AIRPORTNAME": "Ipiales",
    },
    {
        "CITYNAME": "El Centro",
        "CITYCODE": "IPL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "IPL",
        "AIRPORTNAME": "Imperial County",
    },
    {
        "CITYNAME": "Ipatinga",
        "CITYCODE": "IPN",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "IPN",
        "AIRPORTNAME": "Usiminas",
    },
    {
        "CITYNAME": "Williamsport",
        "CITYCODE": "IPT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "IPT",
        "AIRPORTNAME": "Lycoming County",
    },
    {
        "CITYNAME": "Iquique",
        "CITYCODE": "IQQ",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "IQQ",
        "AIRPORTNAME": "Cavancha",
    },
    {
        "CITYNAME": "Iquitos",
        "CITYCODE": "IQT",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "IQT",
        "AIRPORTNAME": "C.F. Secada",
    },
    {
        "CITYNAME": "Kirakira",
        "CITYCODE": "IRA",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "IRA",
        "AIRPORTNAME": "Kirakira",
    },
    {
        "CITYNAME": "Circle",
        "CITYCODE": "IRC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "IRC",
        "AIRPORTNAME": "Circle City",
    },
    {
        "CITYNAME": "Lockhart River",
        "CITYCODE": "IRG",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "IRG",
        "AIRPORTNAME": "Lockhart River",
    },
    {
        "CITYNAME": "Iringa",
        "CITYCODE": "IRI",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "IRI",
        "AIRPORTNAME": "Nduli",
    },
    {
        "CITYNAME": "La Rioja",
        "CITYCODE": "IRJ",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "IRJ",
        "AIRPORTNAME": "La Rioja",
    },
    {
        "CITYNAME": "Kirksville",
        "CITYCODE": "IRK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "IRK",
        "AIRPORTNAME": "Kirksville Municipal",
    },
    {
        "CITYNAME": "Birao",
        "CITYCODE": "IRO",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "IRO",
        "AIRPORTNAME": "Birao",
    },
    {
        "CITYNAME": "Isiro",
        "CITYCODE": "IRP",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "IRP",
        "AIRPORTNAME": "Matari",
    },
    {
        "CITYNAME": "Rome",
        "CITYCODE": "IRR",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "IRR",
        "AIRPORTNAME": "Rome Ostiense Train Station",
    },
    {
        "CITYNAME": "Santa Isabel do Rio Negro",
        "CITYCODE": "IRZ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "IRZ",
        "AIRPORTNAME": "Santa Isabel do Rio Negro Airport",
    },
    {
        "CITYNAME": "Mount Isa",
        "CITYCODE": "ISA",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "ISA",
        "AIRPORTNAME": "Mount Isa",
    },
    {
        "CITYNAME": "Islamabad",
        "CITYCODE": "ISB",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "ISB",
        "AIRPORTNAME": "Islamabad",
    },
    {
        "CITYNAME": "Isles Of Scilly",
        "CITYCODE": "ISC",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "ISC",
        "AIRPORTNAME": "Is Scilly",
    },
    {
        "CITYNAME": "Isles Of Scilly",
        "CITYCODE": "ISC",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "TSO",
        "AIRPORTNAME": "Tresco",
    },
    {
        "CITYNAME": "Isparta",
        "CITYCODE": "ISE",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "ISE",
        "AIRPORTNAME": "Isparta",
    },
    {
        "CITYNAME": "Ishigaki",
        "CITYCODE": "ISG",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "ISG",
        "AIRPORTNAME": "Ishigaki",
    },
    {
        "CITYNAME": "Nasik",
        "CITYCODE": "ISK",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "ISK",
        "AIRPORTNAME": "Ozar Airport",
    },
    {
        "CITYNAME": "Isabel Pass",
        "CITYCODE": "ISL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ISL",
        "AIRPORTNAME": "La Tortuga Punta Delgada Airport",
    },
    {
        "CITYNAME": "Williston",
        "CITYCODE": "ISN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ISN",
        "AIRPORTNAME": "Sloulin Field Int'l",
    },
    {
        "CITYNAME": "Kinston",
        "CITYCODE": "ISO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ISO",
        "AIRPORTNAME": "Stalliings Field",
    },
    {
        "CITYNAME": "Islip",
        "CITYCODE": "ISP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ISP",
        "AIRPORTNAME": "Long Island Macarthur",
    },
    {
        "CITYNAME": "Istanbul",
        "CITYCODE": "IST",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "IST",
        "AIRPORTNAME": "Ataturk",
    },
    {
        "CITYNAME": "Istanbul",
        "CITYCODE": "IST",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "SAW",
        "AIRPORTNAME": "Sabiha Gokcen",
    },
    {
        "CITYNAME": "Sulaymaniyah",
        "CITYCODE": "ISU",
        "COUNTRYCODE": "IQ",
        "COUNTRYNAME": "Iraq",
        "AIRPORTCODE": "ISU",
        "AIRPORTNAME": "Sulaimaniyah ",
    },
    {
        "CITYNAME": "Itaituba",
        "CITYCODE": "ITB",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "ITB",
        "AIRPORTNAME": "Itaituba Airport",
    },
    {
        "CITYNAME": "Ithaca",
        "CITYCODE": "ITH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ITH",
        "AIRPORTNAME": "Tompkins County",
    },
    {
        "CITYNAME": "Itokama",
        "CITYCODE": "ITK",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "ITK",
        "AIRPORTNAME": "Itokama",
    },
    {
        "CITYNAME": "Osaka",
        "CITYCODE": "ITM",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "ITM",
        "AIRPORTNAME": "Itami Airport",
    },
    {
        "CITYNAME": "Hilo",
        "CITYCODE": "ITO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ITO",
        "AIRPORTNAME": "Hilo Int'l",
    },
    {
        "CITYNAME": "Niue Island",
        "CITYCODE": "IUE",
        "COUNTRYCODE": "NU",
        "COUNTRYNAME": "Niue",
        "AIRPORTCODE": "IUE",
        "AIRPORTNAME": "Hanan",
    },
    {
        "CITYNAME": "Ilu",
        "CITYCODE": "IUL",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "IUL",
        "AIRPORTNAME": "Ilu",
    },
    {
        "CITYNAME": "Ambanja",
        "CITYCODE": "IVA",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "IVA",
        "AIRPORTNAME": "Ambanja",
    },
    {
        "CITYNAME": "Invercargill",
        "CITYCODE": "IVC",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "IVC",
        "AIRPORTNAME": "Invercargill",
    },
    {
        "CITYNAME": "Ivalo",
        "CITYCODE": "IVL",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "IVL",
        "AIRPORTNAME": "Ivalo",
    },
    {
        "CITYNAME": "Inverell",
        "CITYCODE": "IVR",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "IVR",
        "AIRPORTNAME": "Inverell",
    },
    {
        "CITYNAME": "Ironwood",
        "CITYCODE": "IWD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "IWD",
        "AIRPORTNAME": "Gogebic County",
    },
    {
        "CITYNAME": "Agartala",
        "CITYCODE": "IXA",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXA",
        "AIRPORTNAME": "Maharaja Bir Bikram Airport",
    },
    {
        "CITYNAME": "Bagdogra",
        "CITYCODE": "IXB",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXB",
        "AIRPORTNAME": "Bagdogra",
    },
    {
        "CITYNAME": "Chandigarh",
        "CITYCODE": "IXC",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXC",
        "AIRPORTNAME": "Chandigarh",
    },
    {
        "CITYNAME": "Prayagraj [Allahabad]",
        "CITYCODE": "IXD",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXD",
        "AIRPORTNAME": "Bamrauli",
    },
    {
        "CITYNAME": "Mangalore",
        "CITYCODE": "IXE",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXE",
        "AIRPORTNAME": "Mangaluru International Airport",
    },
    {
        "CITYNAME": "Belgaum",
        "CITYCODE": "IXG",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXG",
        "AIRPORTNAME": "Sambre",
    },
    {
        "CITYNAME": "Kailashahar",
        "CITYCODE": "IXH",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXH",
        "AIRPORTNAME": "Kailashahar",
    },
    {
        "CITYNAME": "Lakhimpur (Lilabari)",
        "CITYCODE": "IXI",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXI",
        "AIRPORTNAME": "Lilabari",
    },
    {
        "CITYNAME": "Jammu",
        "CITYCODE": "IXJ",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXJ",
        "AIRPORTNAME": "Satwari",
    },
    {
        "CITYNAME": "Keshod",
        "CITYCODE": "IXK",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXK",
        "AIRPORTNAME": "Keshod",
    },
    {
        "CITYNAME": "Leh",
        "CITYCODE": "IXL",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXL",
        "AIRPORTNAME": "Kushok Bakula Rimpochee Airport ",
    },
    {
        "CITYNAME": "Madurai",
        "CITYCODE": "IXM",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXM",
        "AIRPORTNAME": "Madurai",
    },
    {
        "CITYNAME": "Pathankot",
        "CITYCODE": "IXP",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXP",
        "AIRPORTNAME": "Pathankot",
    },
    {
        "CITYNAME": "Ranchi",
        "CITYCODE": "IXR",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXR",
        "AIRPORTNAME": "Birsa Munda Airport",
    },
    {
        "CITYNAME": "Silchar",
        "CITYCODE": "IXS",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXS",
        "AIRPORTNAME": "Kumbhirgram",
    },
    {
        "CITYNAME": "Pasighat",
        "CITYCODE": "IXT",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXT",
        "AIRPORTNAME": "Pasighat Airport",
    },
    {
        "CITYNAME": "Aurangabad",
        "CITYCODE": "IXU",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXU",
        "AIRPORTNAME": "Aurangabad Airport",
    },
    {
        "CITYNAME": "Jamshedpur",
        "CITYCODE": "IXW",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXW",
        "AIRPORTNAME": "Sonari",
    },
    {
        "CITYNAME": "Bidar",
        "CITYCODE": "IXX",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXX",
        "AIRPORTNAME": "Bidar Airport",
    },
    {
        "CITYNAME": "Kandla",
        "CITYCODE": "IXY",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXY",
        "AIRPORTNAME": "Kandla",
    },
    {
        "CITYNAME": "Port Blair",
        "CITYCODE": "IXZ",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "IXZ",
        "AIRPORTNAME": "Veer Savarkar International Airport",
    },
    {
        "CITYNAME": "Inyokern",
        "CITYCODE": "IYK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "IYK",
        "AIRPORTNAME": "Kern County",
    },
    {
        "CITYNAME": "Juiz de Fora",
        "CITYCODE": "IZA",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "IZA",
        "AIRPORTNAME": "Juiz de Fora Zona da Mata Regional Airport",
    },
    {
        "CITYNAME": "Izmir",
        "CITYCODE": "IZM",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "ADB",
        "AIRPORTNAME": "Iadnan Menderes",
    },
    {
        "CITYNAME": "Izumo",
        "CITYCODE": "IZO",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "IZO",
        "AIRPORTNAME": "Izumo",
    },
    {
        "CITYNAME": "Jalalabad",
        "CITYCODE": "JAA",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "JAA",
        "AIRPORTNAME": "Jalalabad",
    },
    {
        "CITYNAME": "Jackson",
        "CITYCODE": "JAC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "JAC",
        "AIRPORTNAME": "Jackson Hole",
    },
    {
        "CITYNAME": "Jandakot",
        "CITYCODE": "JAD",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "JAD",
        "AIRPORTNAME": "Jandakot",
    },
    {
        "CITYNAME": "Jaffna",
        "CITYCODE": "JAF",
        "COUNTRYCODE": "LK",
        "COUNTRYNAME": "Sri Lanka",
        "AIRPORTCODE": "JAF",
        "AIRPORTNAME": "JAFFNA",
    },
    {
        "CITYNAME": "Jacobabad",
        "CITYCODE": "JAG",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "JAG",
        "AIRPORTNAME": " JACOBABAD ",
    },
    {
        "CITYNAME": "Jaipur",
        "CITYCODE": "JAI",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "JAI",
        "AIRPORTNAME": "Jaipur",
    },
    {
        "CITYNAME": "Jacmel",
        "CITYCODE": "JAK",
        "COUNTRYCODE": "HT",
        "COUNTRYNAME": "Haiti",
        "AIRPORTCODE": "JAK",
        "AIRPORTNAME": "Jacmel",
    },
    {
        "CITYNAME": "Jackson",
        "CITYCODE": "JAN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "JAN",
        "AIRPORTNAME": "Jackson Int'l",
    },
    {
        "CITYNAME": "Arcadia",
        "CITYCODE": "JAR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "JAR",
        "AIRPORTNAME": "Jahrom Airport",
    },
    {
        "CITYNAME": "Jabot",
        "CITYCODE": "JAT",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "JAT",
        "AIRPORTNAME": "Jabot",
    },
    {
        "CITYNAME": "Ilulisaat",
        "CITYCODE": "JAV",
        "COUNTRYCODE": "GL",
        "COUNTRYNAME": "Greenland",
        "AIRPORTCODE": "JAV",
        "AIRPORTNAME": "Ilulisaat",
    },
    {
        "CITYNAME": "Jacksonville",
        "CITYCODE": "JAX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "JAX",
        "AIRPORTNAME": "Jacksonville Int'l",
    },
    {
        "CITYNAME": "Jonesboro",
        "CITYCODE": "JBR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "JBR",
        "AIRPORTNAME": "Jonesboro",
    },
    {
        "CITYNAME": "Joacaba",
        "CITYCODE": "JCB",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "JCB",
        "AIRPORTNAME": "Joacaba Airport",
    },
    {
        "CITYNAME": "Christianshaab",
        "CITYCODE": "JCH",
        "COUNTRYCODE": "GL",
        "COUNTRYNAME": "Greenland",
        "AIRPORTCODE": "JCH",
        "AIRPORTNAME": "Christianshaab",
    },
    {
        "CITYNAME": "Julia Creek",
        "CITYCODE": "JCK",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "JCK",
        "AIRPORTNAME": "Julia Creek",
    },
    {
        "CITYNAME": "Jacareacanga",
        "CITYCODE": "JCR",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "JCR",
        "AIRPORTNAME": "Jacareacanga Airport",
    },
    {
        "CITYNAME": "Juiz De Fora",
        "CITYCODE": "JDF",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "JDF",
        "AIRPORTNAME": "Francisco De Assis",
    },
    {
        "CITYNAME": "Jodhpur",
        "CITYCODE": "JDH",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "JDH",
        "AIRPORTNAME": "Jodhpur",
    },
    {
        "CITYNAME": "Juazeiro Do Norte",
        "CITYCODE": "JDO",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "JDO",
        "AIRPORTNAME": "Juazeiro Do Norte",
    },
    {
        "CITYNAME": "Sao Joao del-Rei",
        "CITYCODE": "JDR",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "JDR",
        "AIRPORTNAME": "Sao Joao del-Rei Airport",
    },
    {
        "CITYNAME": "Jingdezhen",
        "CITYCODE": "JDZ",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "JDZ",
        "AIRPORTNAME": "Jingdezhen Airport",
    },
    {
        "CITYNAME": "Jeddah",
        "CITYCODE": "JED",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "JED",
        "AIRPORTNAME": "King Abdulaziz Int'l",
    },
    {
        "CITYNAME": "Aasiaat",
        "CITYCODE": "JEG",
        "COUNTRYCODE": "GL",
        "COUNTRYNAME": "Greenland",
        "AIRPORTCODE": "JEG",
        "AIRPORTNAME": "Aasiaat",
    },
    {
        "CITYNAME": "Jeh",
        "CITYCODE": "JEJ",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "JEJ",
        "AIRPORTNAME": "Jeh",
    },
    {
        "CITYNAME": "Jersey",
        "CITYCODE": "JER",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "JER",
        "AIRPORTNAME": "Jersey States",
    },
    {
        "CITYNAME": "Frederikshaab",
        "CITYCODE": "JFR",
        "COUNTRYCODE": "GL",
        "COUNTRYNAME": "Greenland",
        "AIRPORTCODE": "JFR",
        "AIRPORTNAME": "Frederikshaab",
    },
    {
        "CITYNAME": "Jamnagar",
        "CITYCODE": "JGA",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "JGA",
        "AIRPORTNAME": "Govardhanpur",
    },
    {
        "CITYNAME": "Jagdalpur",
        "CITYCODE": "JGB",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "JGB",
        "AIRPORTNAME": "Jagdalpur",
    },
    {
        "CITYNAME": "Jiayuguan",
        "CITYCODE": "JGN",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "JGN",
        "AIRPORTNAME": "Jiayuguan",
    },
    {
        "CITYNAME": "Godhavn",
        "CITYCODE": "JGO",
        "COUNTRYCODE": "GL",
        "COUNTRYNAME": "Greenland",
        "AIRPORTCODE": "JGO",
        "AIRPORTNAME": "Godhavn",
    },
    {
        "CITYNAME": "Johor Bahru",
        "CITYCODE": "JHB",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "JHB",
        "AIRPORTNAME": "Sultan Ismail Int'l",
    },
    {
        "CITYNAME": "Jinghong",
        "CITYCODE": "JHG",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "JHG",
        "AIRPORTNAME": "Gasa",
    },
    {
        "CITYNAME": "Kapalua",
        "CITYCODE": "JHM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "JHM",
        "AIRPORTNAME": "Kapalua",
    },
    {
        "CITYNAME": "Sisimiut",
        "CITYCODE": "JHS",
        "COUNTRYCODE": "GL",
        "COUNTRYNAME": "Greenland",
        "AIRPORTCODE": "JHS",
        "AIRPORTNAME": "Sisimiut",
    },
    {
        "CITYNAME": "Jamestown",
        "CITYCODE": "JHW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "JHW",
        "AIRPORTNAME": "Jamestown",
    },
    {
        "CITYNAME": "Djibouti",
        "CITYCODE": "JIB",
        "COUNTRYCODE": "DJ",
        "COUNTRYNAME": "Djibouti",
        "AIRPORTCODE": "JIB",
        "AIRPORTNAME": "Ambouli",
    },
    {
        "CITYNAME": "Jijiga",
        "CITYCODE": "JIJ",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "JIJ",
        "AIRPORTNAME": "Wilwal International Airport",
    },
    {
        "CITYNAME": "Ikaria Island",
        "CITYCODE": "JIK",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "JIK",
        "AIRPORTNAME": "Ikaria Island National Airport",
    },
    {
        "CITYNAME": "Jimma",
        "CITYCODE": "JIM",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "JIM",
        "AIRPORTNAME": "Jimma",
    },
    {
        "CITYNAME": "Jijoca de Jericoacoara",
        "CITYCODE": "JJD",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "JJD",
        "AIRPORTNAME": "Jericoacoara airport",
    },
    {
        "CITYNAME": "Jaguaruna",
        "CITYCODE": "JJG",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "JJG",
        "AIRPORTNAME": "Jaguaruna Regional Airport",
    },
    {
        "CITYNAME": "Juanjui",
        "CITYCODE": "JJI",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "JJI",
        "AIRPORTNAME": "Juanjui",
    },
    {
        "CITYNAME": "Jinjiang",
        "CITYCODE": "JJN",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "JJN",
        "AIRPORTNAME": "Jinjiang",
    },
    {
        "CITYNAME": "Jonkoping",
        "CITYCODE": "JKG",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "JKG",
        "AIRPORTNAME": "Axamo",
    },
    {
        "CITYNAME": "Chios",
        "CITYCODE": "JKH",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "JKH",
        "AIRPORTNAME": "Chios",
    },
    {
        "CITYNAME": "Kalymnos",
        "CITYCODE": "JKL",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "JKL",
        "AIRPORTNAME": "Kalymnos Island National Airport",
    },
    {
        "CITYNAME": "Jakarta",
        "CITYCODE": "JKT",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "CGK",
        "AIRPORTNAME": "Soekarno-Hatta Int'l",
    },
    {
        "CITYNAME": "Jakarta",
        "CITYCODE": "JKT",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "HLP",
        "AIRPORTNAME": "Halim Perdana Kusuma",
    },
    {
        "CITYNAME": "Jalgaon",
        "CITYCODE": "JLG",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "JLG",
        "AIRPORTNAME": "Jalgaon Airport",
    },
    {
        "CITYNAME": "Joplin",
        "CITYCODE": "JLN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "JLN",
        "AIRPORTNAME": "Joplin",
    },
    {
        "CITYNAME": "Jabalpur",
        "CITYCODE": "JLR",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "JLR",
        "AIRPORTNAME": "Jabalpur",
    },
    {
        "CITYNAME": "Mikonos",
        "CITYCODE": "JMK",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "JMK",
        "AIRPORTNAME": "Mikonos",
    },
    {
        "CITYNAME": "Jiamusi",
        "CITYCODE": "JMU",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "JMU",
        "AIRPORTNAME": "Jiamusi Airport",
    },
    {
        "CITYNAME": "Johannesburg",
        "CITYCODE": "JNB",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "GCJ",
        "AIRPORTNAME": "Crand Central",
    },
    {
        "CITYNAME": "Johannesburg",
        "CITYCODE": "JNB",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "JNB",
        "AIRPORTNAME": "Johannesburg Int'l",
    },
    {
        "CITYNAME": "Jining",
        "CITYCODE": "JNG",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "JNG",
        "AIRPORTNAME": "Jining Qufu Airport",
    },
    {
        "CITYNAME": "Nanortalik",
        "CITYCODE": "JNN",
        "COUNTRYCODE": "GL",
        "COUNTRYNAME": "Greenland",
        "AIRPORTCODE": "JNN",
        "AIRPORTNAME": "Nanortalik",
    },
    {
        "CITYNAME": "Juneau",
        "CITYCODE": "JNU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "JNU",
        "AIRPORTNAME": "Juneau Int'l",
    },
    {
        "CITYNAME": "Naxos",
        "CITYCODE": "JNX",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "JNX",
        "AIRPORTNAME": "Naxos Island National",
    },
    {
        "CITYNAME": "Joensuu",
        "CITYCODE": "JOE",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "JOE",
        "AIRPORTNAME": "Joensuu",
    },
    {
        "CITYNAME": "Yogyakarta",
        "CITYCODE": "JOG",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "JOG",
        "AIRPORTNAME": "Adisutjipto",
    },
    {
        "CITYNAME": "Yogyakarta",
        "CITYCODE": "JOG",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "YIA",
        "AIRPORTNAME": "Yogyakarta International Airport",
    },
    {
        "CITYNAME": "Joinville",
        "CITYCODE": "JOI",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "JOI",
        "AIRPORTNAME": "Cubatao",
    },
    {
        "CITYNAME": "Jolo",
        "CITYCODE": "JOL",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "JOL",
        "AIRPORTNAME": "Jolo Airport",
    },
    {
        "CITYNAME": "Johnston Island",
        "CITYCODE": "JON",
        "COUNTRYCODE": "UM",
        "COUNTRYNAME": "U.S. Minor Outlaying Islands",
        "AIRPORTCODE": "JON",
        "AIRPORTNAME": "Johnston Island",
    },
    {
        "CITYNAME": "Jos",
        "CITYCODE": "JOS",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "JOS",
        "AIRPORTNAME": "Jos",
    },
    {
        "CITYNAME": "Joao Pessoa",
        "CITYCODE": "JPA",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "JPA",
        "AIRPORTNAME": "Castro Pinto",
    },
    {
        "CITYNAME": "Ji Parana",
        "CITYCODE": "JPR",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "JPR",
        "AIRPORTNAME": "Ji-Parana - RondÃÂ´nia",
    },
    {
        "CITYNAME": "Concord",
        "CITYCODE": "JQF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "JQF",
        "AIRPORTNAME": "Concord Regional Airport",
    },
    {
        "CITYNAME": "Jharsuguda",
        "CITYCODE": "JRG",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "JRG",
        "AIRPORTNAME": "Jharsuguda Airport",
    },
    {
        "CITYNAME": "Jorhat",
        "CITYCODE": "JRH",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "JRH",
        "AIRPORTNAME": "Rowriah",
    },
    {
        "CITYNAME": "Kilimanjaro",
        "CITYCODE": "JRO",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "JRO",
        "AIRPORTNAME": "Kilimanjaro",
    },
    {
        "CITYNAME": "Jerusalem",
        "CITYCODE": "JRS",
        "COUNTRYCODE": "IL",
        "COUNTRYNAME": "Israel",
        "AIRPORTCODE": "JRS",
        "AIRPORTNAME": "Jerusalem",
    },
    {
        "CITYNAME": "Jaisalmer",
        "CITYCODE": "JSA",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "JSA",
        "AIRPORTNAME": "Jaisalmer",
    },
    {
        "CITYNAME": "Sitia",
        "CITYCODE": "JSH",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "JSH",
        "AIRPORTNAME": "Sitia",
    },
    {
        "CITYNAME": "Skiathos",
        "CITYCODE": "JSI",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "JSI",
        "AIRPORTNAME": "Skiathos",
    },
    {
        "CITYNAME": "Jose D San Martin",
        "CITYCODE": "JSM",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "JSM",
        "AIRPORTNAME": "Jose D San Martin",
    },
    {
        "CITYNAME": "Jessore",
        "CITYCODE": "JSR",
        "COUNTRYCODE": "BD",
        "COUNTRYNAME": "Bangladesh",
        "AIRPORTCODE": "JSR",
        "AIRPORTNAME": "Jessore",
    },
    {
        "CITYNAME": "Johnstown",
        "CITYCODE": "JST",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "JST",
        "AIRPORTNAME": "Cambria County",
    },
    {
        "CITYNAME": "Syros Island",
        "CITYCODE": "JSY",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "JSY",
        "AIRPORTNAME": "Syros Island National Airport",
    },
    {
        "CITYNAME": "Bauru - Sao Paulo",
        "CITYCODE": "JTC",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "JTC",
        "AIRPORTNAME": "Bauru - Arealva",
    },
    {
        "CITYNAME": "Thira",
        "CITYCODE": "JTR",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "JTR",
        "AIRPORTNAME": "Santorini",
    },
    {
        "CITYNAME": "Astypalea Island",
        "CITYCODE": "JTY",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "JTY",
        "AIRPORTNAME": "Astypalaia Island National Airport",
    },
    {
        "CITYNAME": "Juba",
        "CITYCODE": "JUB",
        "COUNTRYCODE": "SD",
        "COUNTRYNAME": "Sudan",
        "AIRPORTCODE": "JUB",
        "AIRPORTNAME": "Juba",
    },
    {
        "CITYNAME": "Juist",
        "CITYCODE": "JUI",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "JUI",
        "AIRPORTNAME": "Juist",
    },
    {
        "CITYNAME": "Jujuy",
        "CITYCODE": "JUJ",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "JUJ",
        "AIRPORTNAME": "El Cadillal",
    },
    {
        "CITYNAME": "Juliaca",
        "CITYCODE": "JUL",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "JUL",
        "AIRPORTNAME": "Juliaca",
    },
    {
        "CITYNAME": "Ankavandra",
        "CITYCODE": "JVA",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "JVA",
        "AIRPORTNAME": "Ankavandra",
    },
    {
        "CITYNAME": "Jwaneng",
        "CITYCODE": "JWA",
        "COUNTRYCODE": "BW",
        "COUNTRYNAME": "Botswana",
        "AIRPORTCODE": "JWA",
        "AIRPORTNAME": "Jwaneng",
    },
    {
        "CITYNAME": "Zanjan",
        "CITYCODE": "JWN",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "JWN",
        "AIRPORTNAME": "Zanjan Airport",
    },
    {
        "CITYNAME": "Jyvaskyla",
        "CITYCODE": "JYV",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "JYV",
        "AIRPORTNAME": "Jyvaskyla",
    },
    {
        "CITYNAME": "Kasama",
        "CITYCODE": "KAA",
        "COUNTRYCODE": "ZM",
        "COUNTRYNAME": "Zambia",
        "AIRPORTCODE": "KAA",
        "AIRPORTNAME": "Kasama",
    },
    {
        "CITYNAME": "Kariba",
        "CITYCODE": "KAB",
        "COUNTRYCODE": "ZW",
        "COUNTRYNAME": "Zimbabwe",
        "AIRPORTCODE": "KAB",
        "AIRPORTNAME": "Kariba",
    },
    {
        "CITYNAME": "Kameshli",
        "CITYCODE": "KAC",
        "COUNTRYCODE": "SY",
        "COUNTRYNAME": "Syrian Arab Republic",
        "AIRPORTCODE": "KAC",
        "AIRPORTNAME": "Kameshli",
    },
    {
        "CITYNAME": "Kaduna",
        "CITYCODE": "KAD",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "KAD",
        "AIRPORTNAME": "Kaduna",
    },
    {
        "CITYNAME": "Kangnung",
        "CITYCODE": "KAG",
        "COUNTRYCODE": "KR",
        "COUNTRYNAME": "South Korea",
        "AIRPORTCODE": "KAG",
        "AIRPORTNAME": "Kangnung",
    },
    {
        "CITYNAME": "Kajaani",
        "CITYCODE": "KAJ",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "KAJ",
        "AIRPORTNAME": "Kajaani",
    },
    {
        "CITYNAME": "Kaltag",
        "CITYCODE": "KAL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KAL",
        "AIRPORTNAME": "Kaltag",
    },
    {
        "CITYNAME": "Kano",
        "CITYCODE": "KAN",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "KAN",
        "AIRPORTNAME": "Aminu Kano Int'l",
    },
    {
        "CITYNAME": "Kuusamo",
        "CITYCODE": "KAO",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "KAO",
        "AIRPORTNAME": "Kuusamo",
    },
    {
        "CITYNAME": "Kaitaia",
        "CITYCODE": "KAT",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "KAT",
        "AIRPORTNAME": "Kaitaia",
    },
    {
        "CITYNAME": "Kalbarri",
        "CITYCODE": "KAX",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "KAX",
        "AIRPORTNAME": "Kalbarri",
    },
    {
        "CITYNAME": "Kau",
        "CITYCODE": "KAZ",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "KAZ",
        "AIRPORTNAME": "Kau",
    },
    {
        "CITYNAME": "Kabala",
        "CITYCODE": "KBA",
        "COUNTRYCODE": "SL",
        "COUNTRYNAME": "Sierra Leone",
        "AIRPORTCODE": "KBA",
        "AIRPORTNAME": "Kabala",
    },
    {
        "CITYNAME": "Birch Creek",
        "CITYCODE": "KBC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KBC",
        "AIRPORTNAME": "Birch Creek",
    },
    {
        "CITYNAME": "Kribi",
        "CITYCODE": "KBI",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "KBI",
        "AIRPORTNAME": "Kribi",
    },
    {
        "CITYNAME": "Kushinagar",
        "CITYCODE": "KBK",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "KBK",
        "AIRPORTNAME": "Kushinagar",
    },
    {
        "CITYNAME": "Kabul",
        "CITYCODE": "KBL",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "KBL",
        "AIRPORTNAME": "Khwaja Rawash",
    },
    {
        "CITYNAME": "Kabwum",
        "CITYCODE": "KBM",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "KBM",
        "AIRPORTNAME": "Kabwum",
    },
    {
        "CITYNAME": "Kota Bharu",
        "CITYCODE": "KBR",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "KBR",
        "AIRPORTNAME": "Pengkalan Chepa",
    },
    {
        "CITYNAME": "Kaben",
        "CITYCODE": "KBT",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "KBT",
        "AIRPORTNAME": "Kaben",
    },
    {
        "CITYNAME": "Krabi",
        "CITYCODE": "KBV",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "KBV",
        "AIRPORTNAME": "KRABI",
    },
    {
        "CITYNAME": "Kambuaya",
        "CITYCODE": "KBX",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "KBX",
        "AIRPORTNAME": "Kambuaya",
    },
    {
        "CITYNAME": "Streaky Bay",
        "CITYCODE": "KBY",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "KBY",
        "AIRPORTNAME": "Streaky Bay",
    },
    {
        "CITYNAME": "Kuching",
        "CITYCODE": "KCH",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "KCH",
        "AIRPORTNAME": "Kuching",
    },
    {
        "CITYNAME": "Kansas City",
        "CITYCODE": "KCK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KCK",
        "AIRPORTNAME": "Fairfax Municipal",
    },
    {
        "CITYNAME": "Chignik",
        "CITYCODE": "KCL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KCG",
        "AIRPORTNAME": "Fisheries",
    },
    {
        "CITYNAME": "Chignik",
        "CITYCODE": "KCL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KCL",
        "AIRPORTNAME": "Chignik",
    },
    {
        "CITYNAME": "Chignik",
        "CITYCODE": "KCL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KCQ",
        "AIRPORTNAME": "Chignik Lake",
    },
    {
        "CITYNAME": "Kahramanmaras",
        "CITYCODE": "KCM",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "KCM",
        "AIRPORTNAME": "Kahramanmaras",
    },
    {
        "CITYNAME": "Kochi",
        "CITYCODE": "KCZ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "KCZ",
        "AIRPORTNAME": "Kochi",
    },
    {
        "CITYNAME": "Kolda",
        "CITYCODE": "KDA",
        "COUNTRYCODE": "SN",
        "COUNTRYNAME": "Senegal",
        "AIRPORTCODE": "KDA",
        "AIRPORTNAME": "Kolda",
    },
    {
        "CITYNAME": "Kandi",
        "CITYCODE": "KDC",
        "COUNTRYCODE": "BJ",
        "COUNTRYNAME": "Benin",
        "AIRPORTCODE": "KDC",
        "AIRPORTNAME": "Kandi",
    },
    {
        "CITYNAME": "Kandahar",
        "CITYCODE": "KDH",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "KDH",
        "AIRPORTNAME": "Kandahar",
    },
    {
        "CITYNAME": "Kendari",
        "CITYCODE": "KDI",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "KDI",
        "AIRPORTNAME": "Wolter Monginsidi",
    },
    {
        "CITYNAME": "Ndende",
        "CITYCODE": "KDN",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "KDN",
        "AIRPORTNAME": "Ndende",
    },
    {
        "CITYNAME": "Kandavu",
        "CITYCODE": "KDV",
        "COUNTRYCODE": "FJ",
        "COUNTRYNAME": "Fiji Islands",
        "AIRPORTCODE": "KDV",
        "AIRPORTNAME": "Kandavu",
    },
    {
        "CITYNAME": "Keisah",
        "CITYCODE": "KEA",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "KEA",
        "AIRPORTNAME": "Keisah",
    },
    {
        "CITYNAME": "Kaedi",
        "CITYCODE": "KED",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "KED",
        "AIRPORTNAME": "Kaedi",
    },
    {
        "CITYNAME": "Kelle",
        "CITYCODE": "KEE",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "KEE",
        "AIRPORTNAME": "Kelle",
    },
    {
        "CITYNAME": "Kenmore Air Harbo",
        "CITYCODE": "KEH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KEH",
        "AIRPORTNAME": "Kenmore Air Harbo",
    },
    {
        "CITYNAME": "Kemerovo",
        "CITYCODE": "KEJ",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "KEJ",
        "AIRPORTNAME": "Kemerovo",
    },
    {
        "CITYNAME": "Ekwok",
        "CITYCODE": "KEK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KEK",
        "AIRPORTNAME": "Ekwok",
    },
    {
        "CITYNAME": "Kiel",
        "CITYCODE": "KEL",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "KEL",
        "AIRPORTNAME": "Holtenau",
    },
    {
        "CITYNAME": "Kemi/Tornio",
        "CITYCODE": "KEM",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "KEM",
        "AIRPORTNAME": "Kemi Tornio",
    },
    {
        "CITYNAME": "Kenema",
        "CITYCODE": "KEN",
        "COUNTRYCODE": "SL",
        "COUNTRYNAME": "Sierra Leone",
        "AIRPORTCODE": "KEN",
        "AIRPORTNAME": "Kenema",
    },
    {
        "CITYNAME": "Odienne",
        "CITYCODE": "KEO",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "KEO",
        "AIRPORTNAME": "Odienne",
    },
    {
        "CITYNAME": "Kebar",
        "CITYCODE": "KEQ",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "KEQ",
        "AIRPORTNAME": "Kebar",
    },
    {
        "CITYNAME": "Kerman",
        "CITYCODE": "KER",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "KER",
        "AIRPORTNAME": "Kerman",
    },
    {
        "CITYNAME": "Keng Tung",
        "CITYCODE": "KET",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "KET",
        "AIRPORTNAME": "Keng Tung",
    },
    {
        "CITYNAME": "Kericho",
        "CITYCODE": "KEY",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "KEY",
        "AIRPORTNAME": "Kericho",
    },
    {
        "CITYNAME": "Kiffa",
        "CITYCODE": "KFA",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "KFA",
        "AIRPORTNAME": "Kiffa",
    },
    {
        "CITYNAME": "Kalkurung",
        "CITYCODE": "KFG",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "KFG",
        "AIRPORTNAME": "Kalkurung",
    },
    {
        "CITYNAME": "False Pass",
        "CITYCODE": "KFP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KFP",
        "AIRPORTNAME": "False Pass",
    },
    {
        "CITYNAME": "Kastamonu",
        "CITYCODE": "KFS",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "KFS",
        "AIRPORTNAME": "Kastamonu Apt",
    },
    {
        "CITYNAME": "Kananga",
        "CITYCODE": "KGA",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "KGA",
        "AIRPORTNAME": "Kananga",
    },
    {
        "CITYNAME": "Konge",
        "CITYCODE": "KGB",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "KGB",
        "AIRPORTNAME": "Konge",
    },
    {
        "CITYNAME": "Kingscote",
        "CITYCODE": "KGC",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "KGC",
        "AIRPORTNAME": "Kingscote",
    },
    {
        "CITYNAME": "Kaliningrad",
        "CITYCODE": "KGD",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "KGD",
        "AIRPORTNAME": "Kaliningrad",
    },
    {
        "CITYNAME": "Karaganda",
        "CITYCODE": "KGF",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "KGF",
        "AIRPORTNAME": "Karaganda",
    },
    {
        "CITYNAME": "Kedougou",
        "CITYCODE": "KGG",
        "COUNTRYCODE": "SN",
        "COUNTRYNAME": "Senegal",
        "AIRPORTCODE": "KGG",
        "AIRPORTNAME": "Kedougou",
    },
    {
        "CITYNAME": "Kalgoorlie",
        "CITYCODE": "KGI",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "KGI",
        "AIRPORTNAME": "Kalgoorlie",
    },
    {
        "CITYNAME": "Karonga",
        "CITYCODE": "KGJ",
        "COUNTRYCODE": "MW",
        "COUNTRYNAME": "Malawi",
        "AIRPORTCODE": "KGJ",
        "AIRPORTNAME": "Karonga",
    },
    {
        "CITYNAME": "New Koliganek",
        "CITYCODE": "KGK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KGK",
        "AIRPORTNAME": "New Koliganek",
    },
    {
        "CITYNAME": "Kigali",
        "CITYCODE": "KGL",
        "COUNTRYCODE": "RW",
        "COUNTRYNAME": "Ruanda",
        "AIRPORTCODE": "KGL",
        "AIRPORTNAME": "Gregoire Kayibanda",
    },
    {
        "CITYNAME": "Kos",
        "CITYCODE": "KGS",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "KGS",
        "AIRPORTNAME": "Kos",
    },
    {
        "CITYNAME": "Grayling",
        "CITYCODE": "KGX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KGX",
        "AIRPORTNAME": "Grayling",
    },
    {
        "CITYNAME": "Kherson",
        "CITYCODE": "KHE",
        "COUNTRYCODE": "UA",
        "COUNTRYNAME": "Ukraine",
        "AIRPORTCODE": "KHE",
        "AIRPORTNAME": "Kherson",
    },
    {
        "CITYNAME": "Kashi",
        "CITYCODE": "KHG",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "KHG",
        "AIRPORTNAME": "Kashi",
    },
    {
        "CITYNAME": "Kaohsiung",
        "CITYCODE": "KHH",
        "COUNTRYCODE": "TW",
        "COUNTRYNAME": "Taiwan",
        "AIRPORTCODE": "KHH",
        "AIRPORTNAME": "Kaohsiung Int'l",
    },
    {
        "CITYNAME": "Karachi",
        "CITYCODE": "KHI",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "KHI",
        "AIRPORTNAME": "Quaid-e-Azam Int'l",
    },
    {
        "CITYNAME": "Nanchang",
        "CITYCODE": "KHN",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "KHN",
        "AIRPORTNAME": "Nanchang",
    },
    {
        "CITYNAME": "Khasab",
        "CITYCODE": "KHS",
        "COUNTRYCODE": "OM",
        "COUNTRYNAME": "Oman",
        "AIRPORTCODE": "KHS",
        "AIRPORTNAME": "Khasab",
    },
    {
        "CITYNAME": "Khost",
        "CITYCODE": "KHT",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "KHT",
        "AIRPORTNAME": "Khost",
    },
    {
        "CITYNAME": "Khabarovsk",
        "CITYCODE": "KHV",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "KHV",
        "AIRPORTNAME": "Novy",
    },
    {
        "CITYNAME": "Khwai River Lodge",
        "CITYCODE": "KHW",
        "COUNTRYCODE": "BW",
        "COUNTRYNAME": "Botswana",
        "AIRPORTCODE": "KHW",
        "AIRPORTNAME": "Khwai River Lodge",
    },
    {
        "CITYNAME": "Kristianstad",
        "CITYCODE": "KID",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "KID",
        "AIRPORTNAME": "Kristianstad",
    },
    {
        "CITYNAME": "Kingfisher Lake",
        "CITYCODE": "KIF",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "KIF",
        "AIRPORTNAME": "Kingfisher Lake",
    },
    {
        "CITYNAME": "Kish Island",
        "CITYCODE": "KIH",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "KIH",
        "AIRPORTNAME": "Kish Island",
    },
    {
        "CITYNAME": "Niigata",
        "CITYCODE": "KIJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "KIJ",
        "AIRPORTNAME": "Niigata",
    },
    {
        "CITYNAME": "Kimberley",
        "CITYCODE": "KIM",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "KIM",
        "AIRPORTNAME": "Kimberley",
    },
    {
        "CITYNAME": "Kingston",
        "CITYCODE": "KIN",
        "COUNTRYCODE": "JM",
        "COUNTRYNAME": "Jamaica",
        "AIRPORTCODE": "KIN",
        "AIRPORTNAME": "Norman Manley",
    },
    {
        "CITYNAME": "Kingston",
        "CITYCODE": "KIN",
        "COUNTRYCODE": "JM",
        "COUNTRYNAME": "Jamaica",
        "AIRPORTCODE": "KTP",
        "AIRPORTNAME": "Tinson",
    },
    {
        "CITYNAME": "Kili Island",
        "CITYCODE": "KIO",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "KIO",
        "AIRPORTNAME": "Kili Island",
    },
    {
        "CITYNAME": "Kira",
        "CITYCODE": "KIQ",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "KIQ",
        "AIRPORTNAME": "Kira",
    },
    {
        "CITYNAME": "Kerry County",
        "CITYCODE": "KIR",
        "COUNTRYCODE": "IE",
        "COUNTRYNAME": "Ireland",
        "AIRPORTCODE": "KIR",
        "AIRPORTNAME": "Kerry County",
    },
    {
        "CITYNAME": "Kisumu",
        "CITYCODE": "KIS",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "KIS",
        "AIRPORTNAME": "Kisumu",
    },
    {
        "CITYNAME": "Kithira",
        "CITYCODE": "KIT",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "KIT",
        "AIRPORTNAME": "Kithira",
    },
    {
        "CITYNAME": "Chisinau",
        "CITYCODE": "KIV",
        "COUNTRYCODE": "MD",
        "COUNTRYNAME": "Moldova",
        "AIRPORTCODE": "KIV",
        "AIRPORTNAME": "Chisinau",
    },
    {
        "CITYNAME": "Krasnojarsk",
        "CITYCODE": "KJA",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "KJA",
        "AIRPORTNAME": "Krasnojarsk",
    },
    {
        "CITYNAME": "Kurnool",
        "CITYCODE": "KJB",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "KJB",
        "AIRPORTNAME": "Kurnool",
    },
    {
        "CITYNAME": "Karlsruhe",
        "CITYCODE": "KJR",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "KJR",
        "AIRPORTNAME": "Karlsruhe Central Station",
    },
    {
        "CITYNAME": "Koyuk",
        "CITYCODE": "KKA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KKA",
        "AIRPORTNAME": "Koyuk",
    },
    {
        "CITYNAME": "Kitoi Bay",
        "CITYCODE": "KKB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KKB",
        "AIRPORTNAME": "Kitoi Bay",
    },
    {
        "CITYNAME": "Khon Kaen",
        "CITYCODE": "KKC",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "KKC",
        "AIRPORTNAME": "Khon Kaen",
    },
    {
        "CITYNAME": "Kokoda",
        "CITYCODE": "KKD",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "KKD",
        "AIRPORTNAME": "Kokoda",
    },
    {
        "CITYNAME": "Kerikeri",
        "CITYCODE": "KKE",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "KKE",
        "AIRPORTNAME": "Kerikeri",
    },
    {
        "CITYNAME": "Kongiganak",
        "CITYCODE": "KKH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KKH",
        "AIRPORTNAME": "Kongiganak",
    },
    {
        "CITYNAME": "Kita Kyushu",
        "CITYCODE": "KKJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "KKJ",
        "AIRPORTNAME": "Kitakyushu Airport",
    },
    {
        "CITYNAME": "Kirkenes",
        "CITYCODE": "KKN",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "KKN",
        "AIRPORTNAME": "Hoeybuktmoen",
    },
    {
        "CITYNAME": "Kaukura Atoll",
        "CITYCODE": "KKR",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "KKR",
        "AIRPORTNAME": "Kaukura Atoll",
    },
    {
        "CITYNAME": "Ekuk",
        "CITYCODE": "KKU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KKU",
        "AIRPORTNAME": "Ekuk",
    },
    {
        "CITYNAME": "Kikaiga Shima",
        "CITYCODE": "KKX",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "KKX",
        "AIRPORTNAME": "Kikaiga Shima",
    },
    {
        "CITYNAME": "Kaolack",
        "CITYCODE": "KLC",
        "COUNTRYCODE": "SN",
        "COUNTRYNAME": "Senegal",
        "AIRPORTCODE": "KLC",
        "AIRPORTNAME": "Kaolack",
    },
    {
        "CITYNAME": "Kaele",
        "CITYCODE": "KLE",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "KLE",
        "AIRPORTNAME": "Kaele",
    },
    {
        "CITYNAME": "Kalskag",
        "CITYCODE": "KLG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KLG",
        "AIRPORTNAME": "Kalskag Municipal",
    },
    {
        "CITYNAME": "Kolhapur",
        "CITYCODE": "KLH",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "KLH",
        "AIRPORTNAME": "Kolhapur",
    },
    {
        "CITYNAME": "Levelock",
        "CITYCODE": "KLL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KLL",
        "AIRPORTNAME": "Levelock",
    },
    {
        "CITYNAME": "Kalibo",
        "CITYCODE": "KLO",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "KLO",
        "AIRPORTNAME": "Kalibo",
    },
    {
        "CITYNAME": "Kalmar",
        "CITYCODE": "KLR",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "KLR",
        "AIRPORTNAME": "Kalmar",
    },
    {
        "CITYNAME": "Kaiserslautern",
        "CITYCODE": "KLT",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "KLT",
        "AIRPORTNAME": "Kaiserslautern Train Station",
    },
    {
        "CITYNAME": "Klagenfurt",
        "CITYCODE": "KLU",
        "COUNTRYCODE": "AT",
        "COUNTRYNAME": "Austria",
        "AIRPORTCODE": "KGV",
        "AIRPORTNAME": "Klagenfurt Main station Airport",
    },
    {
        "CITYNAME": "Klagenfurt",
        "CITYCODE": "KLU",
        "COUNTRYCODE": "AT",
        "COUNTRYNAME": "Austria",
        "AIRPORTCODE": "KLU",
        "AIRPORTNAME": "Klagenfurt",
    },
    {
        "CITYNAME": "Karlovy Vary",
        "CITYCODE": "KLV",
        "COUNTRYCODE": "CZ",
        "COUNTRYNAME": "Czech Republic",
        "AIRPORTCODE": "KLV",
        "AIRPORTNAME": "Karlovy Vary Airport",
    },
    {
        "CITYNAME": "Klawock",
        "CITYCODE": "KLW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KLW",
        "AIRPORTNAME": "Klawock",
    },
    {
        "CITYNAME": "Kalamata",
        "CITYCODE": "KLX",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "KLX",
        "AIRPORTNAME": "Kalamata",
    },
    {
        "CITYNAME": "Kleinzee",
        "CITYCODE": "KLZ",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "KLZ",
        "AIRPORTNAME": "Kleinzee",
    },
    {
        "CITYNAME": "Kamembe",
        "CITYCODE": "KME",
        "COUNTRYCODE": "RW",
        "COUNTRYNAME": "Ruanda",
        "AIRPORTCODE": "KME",
        "AIRPORTNAME": "Kamembe",
    },
    {
        "CITYNAME": "Kunming",
        "CITYCODE": "KMG",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "KMG",
        "AIRPORTNAME": "Kunming",
    },
    {
        "CITYNAME": "Miyazaki",
        "CITYCODE": "KMI",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "KMI",
        "AIRPORTNAME": "Miyazaki",
    },
    {
        "CITYNAME": "Kumamoto",
        "CITYCODE": "KMJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "KMJ",
        "AIRPORTNAME": "Kumamoto",
    },
    {
        "CITYNAME": "Makabana",
        "CITYCODE": "KMK",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "KMK",
        "AIRPORTNAME": "Makabana",
    },
    {
        "CITYNAME": "Kimam",
        "CITYCODE": "KMM",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "KMM",
        "AIRPORTNAME": "Kimam",
    },
    {
        "CITYNAME": "Keetmanshoop",
        "CITYCODE": "KMP",
        "COUNTRYCODE": "NA",
        "COUNTRYNAME": "Namibia",
        "AIRPORTCODE": "KMP",
        "AIRPORTNAME": "J.G.H. Van Der Wath",
    },
    {
        "CITYNAME": "Komatsu",
        "CITYCODE": "KMQ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "KMQ",
        "AIRPORTNAME": "Komatsu",
    },
    {
        "CITYNAME": "Kumasi",
        "CITYCODE": "KMS",
        "COUNTRYCODE": "GH",
        "COUNTRYNAME": "Ghana",
        "AIRPORTCODE": "KMS",
        "AIRPORTNAME": "Kumasi",
    },
    {
        "CITYNAME": "Kismayu",
        "CITYCODE": "KMU",
        "COUNTRYCODE": "SO",
        "COUNTRYNAME": "Somalia",
        "AIRPORTCODE": "KMU",
        "AIRPORTNAME": "Kismayu",
    },
    {
        "CITYNAME": "Kalemyo",
        "CITYCODE": "KMV",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "KMV",
        "AIRPORTNAME": "Kalemyo",
    },
    {
        "CITYNAME": "Moser Bay",
        "CITYCODE": "KMY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KMY",
        "AIRPORTNAME": "Moser Bay",
    },
    {
        "CITYNAME": "Kindu",
        "CITYCODE": "KND",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "KND",
        "AIRPORTNAME": "Kindu",
    },
    {
        "CITYNAME": "Kaimana",
        "CITYCODE": "KNG",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "KNG",
        "AIRPORTNAME": "Kaimana",
    },
    {
        "CITYNAME": "Kinmen",
        "CITYCODE": "KNH",
        "COUNTRYCODE": "TW",
        "COUNTRYNAME": "Taiwan",
        "AIRPORTCODE": "KNH",
        "AIRPORTNAME": "Shang-Yi",
    },
    {
        "CITYNAME": "Kindamba",
        "CITYCODE": "KNJ",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "KNJ",
        "AIRPORTNAME": "Kindamba",
    },
    {
        "CITYNAME": "Kakhonak",
        "CITYCODE": "KNK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KNK",
        "AIRPORTNAME": "Kakhonak",
    },
    {
        "CITYNAME": "Kankan",
        "CITYCODE": "KNN",
        "COUNTRYCODE": "GN",
        "COUNTRYNAME": "Guinea",
        "AIRPORTCODE": "KNN",
        "AIRPORTNAME": "Kankan",
    },
    {
        "CITYNAME": "Kone",
        "CITYCODE": "KNQ",
        "COUNTRYCODE": "NC",
        "COUNTRYNAME": "New Caledonia",
        "AIRPORTCODE": "KNQ",
        "AIRPORTNAME": "Kone",
    },
    {
        "CITYNAME": "King Island",
        "CITYCODE": "KNS",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "KNS",
        "AIRPORTNAME": "King Island",
    },
    {
        "CITYNAME": "Kanpur",
        "CITYCODE": "KNU",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "KNU",
        "AIRPORTNAME": "Kanpur",
    },
    {
        "CITYNAME": "New Stuyahok",
        "CITYCODE": "KNW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KNW",
        "AIRPORTNAME": "New Stuyahok",
    },
    {
        "CITYNAME": "Kununurra",
        "CITYCODE": "KNX",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "KNX",
        "AIRPORTNAME": "Kununurra",
    },
    {
        "CITYNAME": "Kona",
        "CITYCODE": "KOA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KOA",
        "AIRPORTNAME": "Keahole",
    },
    {
        "CITYNAME": "Koutaba",
        "CITYCODE": "KOB",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "KOB",
        "AIRPORTNAME": "Koutaba",
    },
    {
        "CITYNAME": "Koumac",
        "CITYCODE": "KOC",
        "COUNTRYCODE": "NC",
        "COUNTRYNAME": "New Caledonia",
        "AIRPORTCODE": "KOC",
        "AIRPORTNAME": "Koumac",
    },
    {
        "CITYNAME": "Kupang",
        "CITYCODE": "KOE",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "KOE",
        "AIRPORTNAME": "Eltari",
    },
    {
        "CITYNAME": "Kirkwall",
        "CITYCODE": "KOI",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "KOI",
        "AIRPORTNAME": "Kirkwall",
    },
    {
        "CITYNAME": "Kagoshima",
        "CITYCODE": "KOJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "KOJ",
        "AIRPORTNAME": "Kagoshima",
    },
    {
        "CITYNAME": "Kokkola-Pietarsaari",
        "CITYCODE": "KOK",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "KOK",
        "AIRPORTNAME": "Kruunupyy",
    },
    {
        "CITYNAME": "Kongolo",
        "CITYCODE": "KOO",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "KOO",
        "AIRPORTNAME": "Kongolo",
    },
    {
        "CITYNAME": "Nakhon Phanom",
        "CITYCODE": "KOP",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "KOP",
        "AIRPORTNAME": "Nakhon Phanom Airport",
    },
    {
        "CITYNAME": "Sihanoukville",
        "CITYCODE": "KOS",
        "COUNTRYCODE": "KH",
        "COUNTRYNAME": "Cambodia",
        "AIRPORTCODE": "KOS",
        "AIRPORTNAME": "KOS",
    },
    {
        "CITYNAME": "Kotlik",
        "CITYCODE": "KOT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KOT",
        "AIRPORTNAME": "Kotlik",
    },
    {
        "CITYNAME": "Koulamoutou",
        "CITYCODE": "KOU",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "KOU",
        "AIRPORTNAME": "Koulamoutou",
    },
    {
        "CITYNAME": "Kokchetau",
        "CITYCODE": "KOV",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "KOV",
        "AIRPORTNAME": "Kokchetau",
    },
    {
        "CITYNAME": "Ganzhou",
        "CITYCODE": "KOW",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "KOW",
        "AIRPORTNAME": "Ganzhou",
    },
    {
        "CITYNAME": "Port Clarence",
        "CITYCODE": "KPC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KPC",
        "AIRPORTNAME": "Port Clarence",
    },
    {
        "CITYNAME": "Yapsiei",
        "CITYCODE": "KPE",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "KPE",
        "AIRPORTNAME": "Yapsiei",
    },
    {
        "CITYNAME": "Kapit",
        "CITYCODE": "KPI",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "KPI",
        "AIRPORTNAME": "Kapit",
    },
    {
        "CITYNAME": "Pohang",
        "CITYCODE": "KPO",
        "COUNTRYCODE": "KR",
        "COUNTRYNAME": "South Korea",
        "AIRPORTCODE": "KPO",
        "AIRPORTNAME": "Pohang",
    },
    {
        "CITYNAME": "Kempsey",
        "CITYCODE": "KPS",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "KPS",
        "AIRPORTNAME": "Kempsey",
    },
    {
        "CITYNAME": "Akutan",
        "CITYCODE": "KQA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KQA",
        "AIRPORTNAME": "Akutan",
    },
    {
        "CITYNAME": "KISHANGARH",
        "CITYCODE": "KQH",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "KQH",
        "AIRPORTNAME": "Kishangarh Airport",
    },
    {
        "CITYNAME": "Karumba",
        "CITYCODE": "KRB",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "KRB",
        "AIRPORTNAME": "Karumba",
    },
    {
        "CITYNAME": "Kirundo",
        "CITYCODE": "KRE",
        "COUNTRYCODE": "BI",
        "COUNTRYNAME": "Burundi",
        "AIRPORTCODE": "KRE",
        "AIRPORTNAME": "Kirundo",
    },
    {
        "CITYNAME": "Kramfors",
        "CITYCODE": "KRF",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "KRF",
        "AIRPORTNAME": "Kramfors",
    },
    {
        "CITYNAME": "Kikori",
        "CITYCODE": "KRI",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "KRI",
        "AIRPORTNAME": "Kikori",
    },
    {
        "CITYNAME": "Karawari",
        "CITYCODE": "KRJ",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "KRJ",
        "AIRPORTNAME": "Karawari",
    },
    {
        "CITYNAME": "Krakow",
        "CITYCODE": "KRK",
        "COUNTRYCODE": "PL",
        "COUNTRYNAME": "Poland",
        "AIRPORTCODE": "KRK",
        "AIRPORTNAME": "John Paul11 Balice Int'l",
    },
    {
        "CITYNAME": "Kiruna",
        "CITYCODE": "KRN",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "KRN",
        "AIRPORTNAME": "Kiruna",
    },
    {
        "CITYNAME": "Kurgan",
        "CITYCODE": "KRO",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "KRO",
        "AIRPORTNAME": "Kurgan",
    },
    {
        "CITYNAME": "Karup",
        "CITYCODE": "KRP",
        "COUNTRYCODE": "DK",
        "COUNTRYNAME": "Denmark",
        "AIRPORTCODE": "KRP",
        "AIRPORTNAME": "Karup",
    },
    {
        "CITYNAME": "Krasnodar",
        "CITYCODE": "KRR",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "KRR",
        "AIRPORTNAME": "Krasnodar",
    },
    {
        "CITYNAME": "Kristiansand",
        "CITYCODE": "KRS",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "KRS",
        "AIRPORTNAME": "Kjevik",
    },
    {
        "CITYNAME": "Khartoum",
        "CITYCODE": "KRT",
        "COUNTRYCODE": "SD",
        "COUNTRYNAME": "Sudan",
        "AIRPORTCODE": "KRT",
        "AIRPORTNAME": "Khartoum Civil",
    },
    {
        "CITYNAME": "Kerio Valley",
        "CITYCODE": "KRV",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "KRV",
        "AIRPORTNAME": "Kerio Valley",
    },
    {
        "CITYNAME": "Krasnowodsk",
        "CITYCODE": "KRW",
        "COUNTRYCODE": "TM",
        "COUNTRYNAME": "Turkmenistan",
        "AIRPORTCODE": "KRW",
        "AIRPORTNAME": "Krasnowodsk",
    },
    {
        "CITYNAME": "Kar Kar",
        "CITYCODE": "KRX",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "KRX",
        "AIRPORTNAME": "Kar Kar",
    },
    {
        "CITYNAME": "Kosrae",
        "CITYCODE": "KSA",
        "COUNTRYCODE": "FM",
        "COUNTRYNAME": "Micronesia",
        "AIRPORTCODE": "KSA",
        "AIRPORTNAME": "Kosrae",
    },
    {
        "CITYNAME": "Kosice",
        "CITYCODE": "KSC",
        "COUNTRYCODE": "SK",
        "COUNTRYNAME": "Slovakia",
        "AIRPORTCODE": "KSC",
        "AIRPORTNAME": "Barca",
    },
    {
        "CITYNAME": "Karlstad",
        "CITYCODE": "KSD",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "KSD",
        "AIRPORTNAME": "Karlstad",
    },
    {
        "CITYNAME": "Kasese",
        "CITYCODE": "KSE",
        "COUNTRYCODE": "UG",
        "COUNTRYNAME": "Uganda",
        "AIRPORTCODE": "KSE",
        "AIRPORTNAME": "Kasese",
    },
    {
        "CITYNAME": "Kassel",
        "CITYCODE": "KSF",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "KSF",
        "AIRPORTNAME": "Kassel Calden Airport",
    },
    {
        "CITYNAME": "Bakhtaran",
        "CITYCODE": "KSH",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "KSH",
        "AIRPORTNAME": "Bakhtaran",
    },
    {
        "CITYNAME": "Kissidougou",
        "CITYCODE": "KSI",
        "COUNTRYCODE": "GN",
        "COUNTRYNAME": "Guinea",
        "AIRPORTCODE": "KSI",
        "AIRPORTNAME": "Kissidougou",
    },
    {
        "CITYNAME": "Kasos Island",
        "CITYCODE": "KSJ",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "KSJ",
        "AIRPORTNAME": "Kasos Island",
    },
    {
        "CITYNAME": "Kassala",
        "CITYCODE": "KSL",
        "COUNTRYCODE": "SD",
        "COUNTRYNAME": "Sudan",
        "AIRPORTCODE": "KSL",
        "AIRPORTNAME": "Kassala",
    },
    {
        "CITYNAME": "Saint Marys",
        "CITYCODE": "KSM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KSM",
        "AIRPORTNAME": "Saint Marys",
    },
    {
        "CITYNAME": "Kostanay",
        "CITYCODE": "KSN",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "KSN",
        "AIRPORTNAME": "Kostanay",
    },
    {
        "CITYNAME": "Kastoria",
        "CITYCODE": "KSO",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "KSO",
        "AIRPORTNAME": "Aristoteles",
    },
    {
        "CITYNAME": "Karshi",
        "CITYCODE": "KSQ",
        "COUNTRYCODE": "UZ",
        "COUNTRYNAME": "Uzbekistan",
        "AIRPORTCODE": "KSQ",
        "AIRPORTNAME": "Karshi",
    },
    {
        "CITYNAME": "Sikasso",
        "CITYCODE": "KSS",
        "COUNTRYCODE": "ML",
        "COUNTRYNAME": "Mali",
        "AIRPORTCODE": "KSS",
        "AIRPORTNAME": "Sikasso",
    },
    {
        "CITYNAME": "Kristiansund",
        "CITYCODE": "KSU",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "KSU",
        "AIRPORTNAME": "Kvernberget",
    },
    {
        "CITYNAME": "Kars",
        "CITYCODE": "KSY",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "KSY",
        "AIRPORTNAME": "Kars",
    },
    {
        "CITYNAME": "Karratha",
        "CITYCODE": "KTA",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "KTA",
        "AIRPORTNAME": "Karratha",
    },
    {
        "CITYNAME": "Thorne Bay",
        "CITYCODE": "KTB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KTB",
        "AIRPORTNAME": "Thorne Bay",
    },
    {
        "CITYNAME": "Katiola",
        "CITYCODE": "KTC",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "KTC",
        "AIRPORTNAME": "Katiola",
    },
    {
        "CITYNAME": "Kitadaito",
        "CITYCODE": "KTD",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "KTD",
        "AIRPORTNAME": "Kitadaito",
    },
    {
        "CITYNAME": "Kerteh",
        "CITYCODE": "KTE",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "KTE",
        "AIRPORTNAME": "Kerteh",
    },
    {
        "CITYNAME": "Ketapang",
        "CITYCODE": "KTG",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "KTG",
        "AIRPORTNAME": "Ketapang",
    },
    {
        "CITYNAME": "Kitale",
        "CITYCODE": "KTL",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "KTL",
        "AIRPORTNAME": "Kitale",
    },
    {
        "CITYNAME": "Kathmandu",
        "CITYCODE": "KTM",
        "COUNTRYCODE": "NP",
        "COUNTRYNAME": "Nepal",
        "AIRPORTCODE": "KTM",
        "AIRPORTNAME": "Tribhuvan",
    },
    {
        "CITYNAME": "Ketchikan",
        "CITYCODE": "KTN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KTN",
        "AIRPORTNAME": "Ketchikan int'l",
    },
    {
        "CITYNAME": "Katherine",
        "CITYCODE": "KTR",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "KTR",
        "AIRPORTNAME": "Tindal",
    },
    {
        "CITYNAME": "Teller Mission",
        "CITYCODE": "KTS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KTS",
        "AIRPORTNAME": "Brevig Mission",
    },
    {
        "CITYNAME": "Kittila",
        "CITYCODE": "KTT",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "KTT",
        "AIRPORTNAME": "Kittila",
    },
    {
        "CITYNAME": "Kota",
        "CITYCODE": "KTU",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "KTU",
        "AIRPORTNAME": "Kota",
    },
    {
        "CITYNAME": "Katowice",
        "CITYCODE": "KTW",
        "COUNTRYCODE": "PL",
        "COUNTRYNAME": "Poland",
        "AIRPORTCODE": "KTW",
        "AIRPORTNAME": "Pyrzowice",
    },
    {
        "CITYNAME": "Koutiala",
        "CITYCODE": "KTX",
        "COUNTRYCODE": "ML",
        "COUNTRYNAME": "Mali",
        "AIRPORTCODE": "KTX",
        "AIRPORTNAME": "Koutiala",
    },
    {
        "CITYNAME": "Kuantan",
        "CITYCODE": "KUA",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "KUA",
        "AIRPORTNAME": "Kuantan",
    },
    {
        "CITYNAME": "Kuria",
        "CITYCODE": "KUC",
        "COUNTRYCODE": "KI",
        "COUNTRYNAME": "Kiribati",
        "AIRPORTCODE": "KUC",
        "AIRPORTNAME": "Kuria",
    },
    {
        "CITYNAME": "Kudat",
        "CITYCODE": "KUD",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "KUD",
        "AIRPORTNAME": "Kudat",
    },
    {
        "CITYNAME": "Samara",
        "CITYCODE": "KUF",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "KUF",
        "AIRPORTNAME": "Samara",
    },
    {
        "CITYNAME": "Kubin Island",
        "CITYCODE": "KUG",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "KUG",
        "AIRPORTNAME": "Kubin Island",
    },
    {
        "CITYNAME": "Kushiro",
        "CITYCODE": "KUH",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "KUH",
        "AIRPORTNAME": "Kushiro",
    },
    {
        "CITYNAME": "Kasigluk",
        "CITYCODE": "KUK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KUK",
        "AIRPORTNAME": "Kasigluk",
    },
    {
        "CITYNAME": "Kualalumpur",
        "CITYCODE": "KUL",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "KUL",
        "AIRPORTNAME": "Kuala Lum Intl.",
    },
    {
        "CITYNAME": "Kualalumpur",
        "CITYCODE": "KUL",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "SZB",
        "AIRPORTNAME": "SULTAN ABDUL AZIZ SHAH",
    },
    {
        "CITYNAME": "Yakushima",
        "CITYCODE": "KUM",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "KUM",
        "AIRPORTNAME": "Yakushima",
    },
    {
        "CITYNAME": "Kaunas",
        "CITYCODE": "KUN",
        "COUNTRYCODE": "LT",
        "COUNTRYNAME": "Lithuania",
        "AIRPORTCODE": "KUN",
        "AIRPORTNAME": "Kaunas",
    },
    {
        "CITYNAME": "Kuopio",
        "CITYCODE": "KUO",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "KUO",
        "AIRPORTNAME": "Kuopio",
    },
    {
        "CITYNAME": "Kupiano",
        "CITYCODE": "KUP",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "KUP",
        "AIRPORTNAME": "Kupiano",
    },
    {
        "CITYNAME": "Kulusuk",
        "CITYCODE": "KUS",
        "COUNTRYCODE": "GL",
        "COUNTRYNAME": "Greenland",
        "AIRPORTCODE": "KUS",
        "AIRPORTNAME": "Kulusuk",
    },
    {
        "CITYNAME": "Kutaisi",
        "CITYCODE": "KUT",
        "COUNTRYCODE": "GE",
        "COUNTRYNAME": "Georgia",
        "AIRPORTCODE": "KUT",
        "AIRPORTNAME": "Kutaisi",
    },
    {
        "CITYNAME": "Kulu",
        "CITYCODE": "KUU",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "KUU",
        "AIRPORTNAME": "Bhuntar",
    },
    {
        "CITYNAME": "Kavala",
        "CITYCODE": "KVA",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "KVA",
        "AIRPORTNAME": "Megas Alexandros",
    },
    {
        "CITYNAME": "Skovde",
        "CITYCODE": "KVB",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "KVB",
        "AIRPORTNAME": "Skovde",
    },
    {
        "CITYNAME": "King Cove",
        "CITYCODE": "KVC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KVC",
        "AIRPORTNAME": "King Cove",
    },
    {
        "CITYNAME": "Gyandzha",
        "CITYCODE": "KVD",
        "COUNTRYCODE": "AZ",
        "COUNTRYNAME": "Azerbaijan",
        "AIRPORTCODE": "KVD",
        "AIRPORTNAME": "Ganja Airport",
    },
    {
        "CITYNAME": "Kavieng",
        "CITYCODE": "KVG",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "KVG",
        "AIRPORTNAME": "Kavieng",
    },
    {
        "CITYNAME": "Kirovsk",
        "CITYCODE": "KVK",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "KVK",
        "AIRPORTNAME": "Kirovsk",
    },
    {
        "CITYNAME": "Kivalina",
        "CITYCODE": "KVL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KVL",
        "AIRPORTNAME": "Kivalina",
    },
    {
        "CITYNAME": "Kirov",
        "CITYCODE": "KVX",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "KVX",
        "AIRPORTNAME": "Kirov",
    },
    {
        "CITYNAME": "Kwajalein",
        "CITYCODE": "KWA",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "KWA",
        "AIRPORTNAME": "Kwajalein",
    },
    {
        "CITYNAME": "Guiyang",
        "CITYCODE": "KWE",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "KWE",
        "AIRPORTNAME": "Guiyang",
    },
    {
        "CITYNAME": "Krivoy Rog",
        "CITYCODE": "KWG",
        "COUNTRYCODE": "UA",
        "COUNTRYNAME": "Ukraine",
        "AIRPORTCODE": "KWG",
        "AIRPORTNAME": "Krivoy Rog",
    },
    {
        "CITYNAME": "Khwahan",
        "CITYCODE": "KWH",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "KWH",
        "AIRPORTNAME": "Khwahan",
    },
    {
        "CITYNAME": "Kuwait City",
        "CITYCODE": "KWI",
        "COUNTRYCODE": "KW",
        "COUNTRYNAME": "Kuwait",
        "AIRPORTCODE": "KWI",
        "AIRPORTNAME": "Kuwait Int'l",
    },
    {
        "CITYNAME": "Kwangju",
        "CITYCODE": "KWJ",
        "COUNTRYCODE": "KR",
        "COUNTRYNAME": "South Korea",
        "AIRPORTCODE": "KWJ",
        "AIRPORTNAME": "Kwangju",
    },
    {
        "CITYNAME": "Kwigillingok",
        "CITYCODE": "KWK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KWK",
        "AIRPORTNAME": "Kwigillingok",
    },
    {
        "CITYNAME": "Guilin",
        "CITYCODE": "KWL",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "KWL",
        "AIRPORTNAME": "Guilin",
    },
    {
        "CITYNAME": "Kowanyama",
        "CITYCODE": "KWM",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "KWM",
        "AIRPORTNAME": "Kowanyama",
    },
    {
        "CITYNAME": "Quinhagak",
        "CITYCODE": "KWN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KWN",
        "AIRPORTNAME": "Kwinhagak",
    },
    {
        "CITYNAME": "Kwethluk",
        "CITYCODE": "KWT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KWT",
        "AIRPORTNAME": "Kwethluk",
    },
    {
        "CITYNAME": "Kiwayu",
        "CITYCODE": "KWY",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "KWY",
        "AIRPORTNAME": "Kiwayu",
    },
    {
        "CITYNAME": "Konya",
        "CITYCODE": "KYA",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "KYA",
        "AIRPORTNAME": "Konya",
    },
    {
        "CITYNAME": "Orchid Island",
        "CITYCODE": "KYD",
        "COUNTRYCODE": "TW",
        "COUNTRYNAME": "Taiwan",
        "AIRPORTCODE": "KYD",
        "AIRPORTNAME": "Orchid Island",
    },
    {
        "CITYNAME": "Tripoli",
        "CITYCODE": "KYE",
        "COUNTRYCODE": "LB",
        "COUNTRYNAME": "Lebanon",
        "AIRPORTCODE": "KYE",
        "AIRPORTNAME": "Kleyate",
    },
    {
        "CITYNAME": "Karluk",
        "CITYCODE": "KYK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KYK",
        "AIRPORTNAME": "Karluk",
    },
    {
        "CITYNAME": "Kyaukpyu",
        "CITYCODE": "KYP",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "KYP",
        "AIRPORTNAME": "Kyaukpyu",
    },
    {
        "CITYNAME": "Kayes",
        "CITYCODE": "KYS",
        "COUNTRYCODE": "ML",
        "COUNTRYNAME": "Mali",
        "AIRPORTCODE": "KYS",
        "AIRPORTNAME": "Kayes",
    },
    {
        "CITYNAME": "Koyukuk",
        "CITYCODE": "KYU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "KYU",
        "AIRPORTNAME": "Koyukuk",
    },
    {
        "CITYNAME": "Yalumet",
        "CITYCODE": "KYX",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "KYX",
        "AIRPORTNAME": "Yalumet",
    },
    {
        "CITYNAME": "Kyzyl",
        "CITYCODE": "KYZ",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "KYZ",
        "AIRPORTNAME": "Kyzyl",
    },
    {
        "CITYNAME": "Kaintiba",
        "CITYCODE": "KZF",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "KZF",
        "AIRPORTNAME": "Kaintiba",
    },
    {
        "CITYNAME": "Kozani",
        "CITYCODE": "KZI",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "KZI",
        "AIRPORTNAME": "Philippos",
    },
    {
        "CITYNAME": "Kazan",
        "CITYCODE": "KZN",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "KZN",
        "AIRPORTNAME": "Kazan",
    },
    {
        "CITYNAME": "Kzyl Orda",
        "CITYCODE": "KZO",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "KZO",
        "AIRPORTNAME": "Kyzylorda Airport",
    },
    {
        "CITYNAME": "Kutahya",
        "CITYCODE": "KZR",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "KZR",
        "AIRPORTNAME": "Kutahya Zafer Airport",
    },
    {
        "CITYNAME": "Kastelorizo",
        "CITYCODE": "KZS",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "KZS",
        "AIRPORTNAME": "Kastelorizo",
    },
    {
        "CITYNAME": "Lamar",
        "CITYCODE": "LAA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LAA",
        "AIRPORTNAME": "Lamar Field",
    },
    {
        "CITYNAME": "Lablab",
        "CITYCODE": "LAB",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "LAB",
        "AIRPORTNAME": "Lablab",
    },
    {
        "CITYNAME": "Luanda",
        "CITYCODE": "LAD",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "LAD",
        "AIRPORTNAME": "4 De Fevereiro",
    },
    {
        "CITYNAME": "Lae",
        "CITYCODE": "LAE",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "LAE",
        "AIRPORTNAME": "Nadzab",
    },
    {
        "CITYNAME": "Lafayette",
        "CITYCODE": "LAF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LAF",
        "AIRPORTNAME": "Purdue University",
    },
    {
        "CITYNAME": "Labuha",
        "CITYCODE": "LAH",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "LAH",
        "AIRPORTNAME": "Labuha",
    },
    {
        "CITYNAME": "Lannion",
        "CITYCODE": "LAI",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "LAI",
        "AIRPORTNAME": "Servel",
    },
    {
        "CITYNAME": "Lages",
        "CITYCODE": "LAJ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "LAJ",
        "AIRPORTNAME": "Lages",
    },
    {
        "CITYNAME": "Aklavik",
        "CITYCODE": "LAK",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "LAK",
        "AIRPORTNAME": "Aklavik",
    },
    {
        "CITYNAME": "Los Alamos",
        "CITYCODE": "LAM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LAM",
        "AIRPORTNAME": "Los Alamos",
    },
    {
        "CITYNAME": "Lansing",
        "CITYCODE": "LAN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LAN",
        "AIRPORTNAME": "Capital City",
    },
    {
        "CITYNAME": "Laoag",
        "CITYCODE": "LAO",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "LAO",
        "AIRPORTNAME": "Laoag Int Airport",
    },
    {
        "CITYNAME": "La Paz",
        "CITYCODE": "LAP",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "LAP",
        "AIRPORTNAME": "Leon",
    },
    {
        "CITYNAME": "Beida",
        "CITYCODE": "LAQ",
        "COUNTRYCODE": "LY",
        "COUNTRYNAME": "Libyan Arab Jamahiriya",
        "AIRPORTCODE": "LAQ",
        "AIRPORTNAME": "La Braq",
    },
    {
        "CITYNAME": "Laramie",
        "CITYCODE": "LAR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LAR",
        "AIRPORTNAME": "General Brees Field",
    },
    {
        "CITYNAME": "Las Vegas",
        "CITYCODE": "LAS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LAS",
        "AIRPORTNAME": "Mccarran Int'l",
    },
    {
        "CITYNAME": "La Uribe",
        "CITYCODE": "LAT",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "LAT",
        "AIRPORTNAME": "La Uribe",
    },
    {
        "CITYNAME": "Lamu",
        "CITYCODE": "LAU",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "LAU",
        "AIRPORTNAME": "Lamu",
    },
    {
        "CITYNAME": "Lawton",
        "CITYCODE": "LAW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LAW",
        "AIRPORTNAME": "Lawton Municipal",
    },
    {
        "CITYNAME": "Los Angeles",
        "CITYCODE": "LAX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LAX",
        "AIRPORTNAME": "Los Angeles Int'l",
    },
    {
        "CITYNAME": "Ladysmith",
        "CITYCODE": "LAY",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "LAY",
        "AIRPORTNAME": "Ladysmith",
    },
    {
        "CITYNAME": "Bom Jesus Da Lapa",
        "CITYCODE": "LAZ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "LAZ",
        "AIRPORTNAME": "Bom Jesus Da Lapa",
    },
    {
        "CITYNAME": "Leeds",
        "CITYCODE": "LBA",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "LBA",
        "AIRPORTNAME": "Leeds/Bradford",
    },
    {
        "CITYNAME": "Lubbock",
        "CITYCODE": "LBB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LBB",
        "AIRPORTNAME": "Lubbock Int'l",
    },
    {
        "CITYNAME": "Khudzand",
        "CITYCODE": "LBD",
        "COUNTRYCODE": "TJ",
        "COUNTRYNAME": "Tajikistan",
        "AIRPORTCODE": "LBD",
        "AIRPORTNAME": "Khudzhand Khujand Airport",
    },
    {
        "CITYNAME": "Latrobe",
        "CITYCODE": "LBE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LBE",
        "AIRPORTNAME": "Westmoreland County",
    },
    {
        "CITYNAME": "North Platte",
        "CITYCODE": "LBF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LBF",
        "AIRPORTNAME": "Lee Bird Field",
    },
    {
        "CITYNAME": "Albi",
        "CITYCODE": "LBI",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "LBI",
        "AIRPORTNAME": "Le Sequestre",
    },
    {
        "CITYNAME": "Labuan Bajo",
        "CITYCODE": "LBJ",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "LBJ",
        "AIRPORTNAME": "Mutiara",
    },
    {
        "CITYNAME": "Liberal",
        "CITYCODE": "LBL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LBL",
        "AIRPORTNAME": "Liberal Municipal",
    },
    {
        "CITYNAME": "Lambarene",
        "CITYCODE": "LBQ",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "LBQ",
        "AIRPORTNAME": "Lambarene",
    },
    {
        "CITYNAME": "Labrea",
        "CITYCODE": "LBR",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "LBR",
        "AIRPORTNAME": "Labrea Airport",
    },
    {
        "CITYNAME": "Labasa",
        "CITYCODE": "LBS",
        "COUNTRYCODE": "FJ",
        "COUNTRYNAME": "Fiji Islands",
        "AIRPORTCODE": "LBS",
        "AIRPORTNAME": "Labasa",
    },
    {
        "CITYNAME": "Lumberton",
        "CITYCODE": "LBT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LBT",
        "AIRPORTNAME": "LUMBERTON",
    },
    {
        "CITYNAME": "Labuan",
        "CITYCODE": "LBU",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "LBU",
        "AIRPORTNAME": "Labuan",
    },
    {
        "CITYNAME": "Libreville",
        "CITYCODE": "LBV",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "LBV",
        "AIRPORTNAME": "Libreville",
    },
    {
        "CITYNAME": "Long Bawan",
        "CITYCODE": "LBW",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "LBW",
        "AIRPORTNAME": "Long Bawan",
    },
    {
        "CITYNAME": "Larnaca",
        "CITYCODE": "LCA",
        "COUNTRYCODE": "CY",
        "COUNTRYNAME": "Cyprus",
        "AIRPORTCODE": "LCA",
        "AIRPORTNAME": "Larnaca",
    },
    {
        "CITYNAME": "Pontes E Lacerda",
        "CITYCODE": "LCB",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "LCB",
        "AIRPORTNAME": "Pontes E Lacerda",
    },
    {
        "CITYNAME": "Louis Trichardt",
        "CITYCODE": "LCD",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "LCD",
        "AIRPORTNAME": "Louis Trichardt",
    },
    {
        "CITYNAME": "La Ceiba",
        "CITYCODE": "LCE",
        "COUNTRYCODE": "HN",
        "COUNTRYNAME": "Honduras",
        "AIRPORTCODE": "LCE",
        "AIRPORTNAME": "Goloson Int'l",
    },
    {
        "CITYNAME": "La CoruÂ±a",
        "CITYCODE": "LCG",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "LCG",
        "AIRPORTNAME": "La Coruna",
    },
    {
        "CITYNAME": "Lake Charles",
        "CITYCODE": "LCH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LCH",
        "AIRPORTNAME": "Lake Charles Municipal",
    },
    {
        "CITYNAME": "Laconia",
        "CITYCODE": "LCI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LCI",
        "AIRPORTNAME": "Laconia Municipal",
    },
    {
        "CITYNAME": "Lodz",
        "CITYCODE": "LCJ",
        "COUNTRYCODE": "PL",
        "COUNTRYNAME": "Poland",
        "AIRPORTCODE": "LCJ",
        "AIRPORTNAME": "Wladyslaw Reymont",
    },
    {
        "CITYNAME": "La Coloma",
        "CITYCODE": "LCL",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "LCL",
        "AIRPORTNAME": "La Coloma",
    },
    {
        "CITYNAME": "Lague",
        "CITYCODE": "LCO",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "LCO",
        "AIRPORTNAME": "Lague",
    },
    {
        "CITYNAME": "La Chorrera",
        "CITYCODE": "LCR",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "LCR",
        "AIRPORTNAME": "La Chorrera",
    },
    {
        "CITYNAME": "Malda",
        "CITYCODE": "LDA",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "LDA",
        "AIRPORTNAME": "Malda",
    },
    {
        "CITYNAME": "Londrina",
        "CITYCODE": "LDB",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "LDB",
        "AIRPORTNAME": "Londrina",
    },
    {
        "CITYNAME": "Lindeman Island",
        "CITYCODE": "LDC",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "LDC",
        "AIRPORTNAME": "Lindeman Island",
    },
    {
        "CITYNAME": "Lourdes Tarbes",
        "CITYCODE": "LDE",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "LDE",
        "AIRPORTNAME": "Lourdes Tarbes Int'l",
    },
    {
        "CITYNAME": "Lord Howe Island",
        "CITYCODE": "LDH",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "LDH",
        "AIRPORTNAME": "Lord Howe Island",
    },
    {
        "CITYNAME": "Lindi",
        "CITYCODE": "LDI",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "LDI",
        "AIRPORTNAME": "Kikwetu",
    },
    {
        "CITYNAME": "Lidkoping",
        "CITYCODE": "LDK",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "LDK",
        "AIRPORTNAME": "Hovby",
    },
    {
        "CITYNAME": "Lahad Datu",
        "CITYCODE": "LDU",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "LDU",
        "AIRPORTNAME": "Lahad Datu",
    },
    {
        "CITYNAME": "Londonderry",
        "CITYCODE": "LDY",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "LDY",
        "AIRPORTNAME": "Eglinton",
    },
    {
        "CITYNAME": "Learmonth",
        "CITYCODE": "LEA",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "LEA",
        "AIRPORTNAME": "Learmonth",
    },
    {
        "CITYNAME": "Lebanon",
        "CITYCODE": "LEB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LEB",
        "AIRPORTNAME": "Lebanon Rgnl",
    },
    {
        "CITYNAME": "Lencoise",
        "CITYCODE": "LEC",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "LEC",
        "AIRPORTNAME": "Lencois Airport",
    },
    {
        "CITYNAME": "St Petersburg",
        "CITYCODE": "LED",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "LED",
        "AIRPORTNAME": "Pulkovo",
    },
    {
        "CITYNAME": "Le Havre",
        "CITYCODE": "LEH",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "LEH",
        "AIRPORTNAME": "Octeville",
    },
    {
        "CITYNAME": "Almeria",
        "CITYCODE": "LEI",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "LEI",
        "AIRPORTNAME": "Almeria",
    },
    {
        "CITYNAME": "Leipzig",
        "CITYCODE": "LEJ",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "LEJ",
        "AIRPORTNAME": "Leipzig",
    },
    {
        "CITYNAME": "Labe",
        "CITYCODE": "LEK",
        "COUNTRYCODE": "GN",
        "COUNTRYNAME": "Guinea",
        "AIRPORTCODE": "LEK",
        "AIRPORTNAME": "Labe",
    },
    {
        "CITYNAME": "Lake Evella",
        "CITYCODE": "LEL",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "LEL",
        "AIRPORTNAME": "Lake Evella",
    },
    {
        "CITYNAME": "Lemmon",
        "CITYCODE": "LEM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LEM",
        "AIRPORTNAME": "Lemmon",
    },
    {
        "CITYNAME": "Leon",
        "CITYCODE": "LEN",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "LEN",
        "AIRPORTNAME": "Leon",
    },
    {
        "CITYNAME": "Leinster",
        "CITYCODE": "LER",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "LER",
        "AIRPORTNAME": "Leinster",
    },
    {
        "CITYNAME": "Lesobeng",
        "CITYCODE": "LES",
        "COUNTRYCODE": "LS",
        "COUNTRYNAME": "Lesotho",
        "AIRPORTCODE": "LES",
        "AIRPORTNAME": "Lesobeng",
    },
    {
        "CITYNAME": "Leticia",
        "CITYCODE": "LET",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "LET",
        "AIRPORTNAME": "Leticia",
    },
    {
        "CITYNAME": "Bureta",
        "CITYCODE": "LEV",
        "COUNTRYCODE": "FJ",
        "COUNTRYNAME": "Fiji Islands",
        "AIRPORTCODE": "LEV",
        "AIRPORTNAME": "Levuka Airfield",
    },
    {
        "CITYNAME": "Lexington",
        "CITYCODE": "LEX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LEX",
        "AIRPORTNAME": "Blue Grass",
    },
    {
        "CITYNAME": "Beijing",
        "CITYCODE": "LFG",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "PKX",
        "AIRPORTNAME": "Beijing Daxing International Airport",
    },
    {
        "CITYNAME": "La Fria",
        "CITYCODE": "LFR",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "LFR",
        "AIRPORTNAME": "La Fria",
    },
    {
        "CITYNAME": "Lome",
        "CITYCODE": "LFW",
        "COUNTRYCODE": "TG",
        "COUNTRYNAME": "Togo",
        "AIRPORTCODE": "LFW",
        "AIRPORTNAME": "Lome",
    },
    {
        "CITYNAME": "Long Beach",
        "CITYCODE": "LGB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LGB",
        "AIRPORTNAME": "Long Beach Municipal",
    },
    {
        "CITYNAME": "Liege",
        "CITYCODE": "LGG",
        "COUNTRYCODE": "BE",
        "COUNTRYNAME": "Belgium",
        "AIRPORTCODE": "LGG",
        "AIRPORTNAME": "Bierset",
    },
    {
        "CITYNAME": "Leigh Creek",
        "CITYCODE": "LGH",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "LGH",
        "AIRPORTNAME": "Leigh Creek",
    },
    {
        "CITYNAME": "Deadmans Cay",
        "CITYCODE": "LGI",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "LGI",
        "AIRPORTNAME": "Deadmans Cay",
    },
    {
        "CITYNAME": "Langkawi",
        "CITYCODE": "LGK",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "LGK",
        "AIRPORTNAME": "Langkawi",
    },
    {
        "CITYNAME": "Long Lellang",
        "CITYCODE": "LGL",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "LGL",
        "AIRPORTNAME": "Long Lellang",
    },
    {
        "CITYNAME": "Legaspi",
        "CITYCODE": "LGP",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "LGP",
        "AIRPORTNAME": "Legaspi",
    },
    {
        "CITYNAME": "Lago Agrio",
        "CITYCODE": "LGQ",
        "COUNTRYCODE": "EC",
        "COUNTRYNAME": "Ecuador",
        "AIRPORTCODE": "LGQ",
        "AIRPORTNAME": "Lago Agrio",
    },
    {
        "CITYNAME": "Logan",
        "CITYCODE": "LGU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LGU",
        "AIRPORTNAME": "Logan - Cache Airport",
    },
    {
        "CITYNAME": "Leguizamo",
        "CITYCODE": "LGZ",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "LGZ",
        "AIRPORTNAME": "Leguizamo",
    },
    {
        "CITYNAME": "Lahore",
        "CITYCODE": "LHE",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "LHE",
        "AIRPORTNAME": "Lahore",
    },
    {
        "CITYNAME": "Lereh",
        "CITYCODE": "LHI",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "LHI",
        "AIRPORTNAME": "Lereh",
    },
    {
        "CITYNAME": "Lanzhou",
        "CITYCODE": "LHW",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "LHW",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Lifou",
        "CITYCODE": "LIF",
        "COUNTRYCODE": "NC",
        "COUNTRYNAME": "New Caledonia",
        "AIRPORTCODE": "LIF",
        "AIRPORTNAME": "Lifou",
    },
    {
        "CITYNAME": "Limoges",
        "CITYCODE": "LIG",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "LIG",
        "AIRPORTNAME": "Bellegarde",
    },
    {
        "CITYNAME": "Kauai Island",
        "CITYCODE": "LIH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HPV",
        "AIRPORTNAME": "Princeville",
    },
    {
        "CITYNAME": "Kauai Island",
        "CITYCODE": "LIH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LIH",
        "AIRPORTNAME": "Lihue",
    },
    {
        "CITYNAME": "Mulia",
        "CITYCODE": "LII",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "LII",
        "AIRPORTNAME": "Mulia",
    },
    {
        "CITYNAME": "Long Island",
        "CITYCODE": "LIJ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LIJ",
        "AIRPORTNAME": "Long Island",
    },
    {
        "CITYNAME": "Likiep Island",
        "CITYCODE": "LIK",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "LIK",
        "AIRPORTNAME": "Likiep Island",
    },
    {
        "CITYNAME": "Lille",
        "CITYCODE": "LIL",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "LIL",
        "AIRPORTNAME": "Lesquin",
    },
    {
        "CITYNAME": "Lima",
        "CITYCODE": "LIM",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "LIM",
        "AIRPORTNAME": "J Chavez Int'l",
    },
    {
        "CITYNAME": "Lins",
        "CITYCODE": "LIP",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "LIP",
        "AIRPORTNAME": "Lins",
    },
    {
        "CITYNAME": "Lisala",
        "CITYCODE": "LIQ",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "LIQ",
        "AIRPORTNAME": "Lisala",
    },
    {
        "CITYNAME": "Liberia",
        "CITYCODE": "LIR",
        "COUNTRYCODE": "CR",
        "COUNTRYNAME": "Costa Rica",
        "AIRPORTCODE": "LIR",
        "AIRPORTNAME": "Liberia",
    },
    {
        "CITYNAME": "Lisbon",
        "CITYCODE": "LIS",
        "COUNTRYCODE": "PT",
        "COUNTRYNAME": "Portugal",
        "AIRPORTCODE": "LIS",
        "AIRPORTNAME": "Lisboa",
    },
    {
        "CITYNAME": "Little Rock",
        "CITYCODE": "LIT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LIT",
        "AIRPORTNAME": "Little Rock Regional",
    },
    {
        "CITYNAME": "Loikaw",
        "CITYCODE": "LIW",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "LIW",
        "AIRPORTNAME": "Loikaw",
    },
    {
        "CITYNAME": "Lodja",
        "CITYCODE": "LJA",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "LJA",
        "AIRPORTNAME": "Lodja",
    },
    {
        "CITYNAME": "Lijiang",
        "CITYCODE": "LJG",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "LJG",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Ljubljana",
        "CITYCODE": "LJR",
        "COUNTRYCODE": "SI",
        "COUNTRYNAME": "Slovenia",
        "AIRPORTCODE": "LJR",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Ljubljana",
        "CITYCODE": "LJU",
        "COUNTRYCODE": "SI",
        "COUNTRYNAME": "Slovenia",
        "AIRPORTCODE": "LJU",
        "AIRPORTNAME": "Brnik",
    },
    {
        "CITYNAME": "Larantuka",
        "CITYCODE": "LKA",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "LKA",
        "AIRPORTNAME": "Larantuka",
    },
    {
        "CITYNAME": "Lakeba",
        "CITYCODE": "LKB",
        "COUNTRYCODE": "FJ",
        "COUNTRYNAME": "Fiji Islands",
        "AIRPORTCODE": "LKB",
        "AIRPORTNAME": "Lakeba",
    },
    {
        "CITYNAME": "Lakselv",
        "CITYCODE": "LKL",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "LKL",
        "AIRPORTNAME": "Banak",
    },
    {
        "CITYNAME": "Leknes",
        "CITYCODE": "LKN",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "LKN",
        "AIRPORTNAME": "Leknes",
    },
    {
        "CITYNAME": "Lucknow",
        "CITYCODE": "LKO",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "LKO",
        "AIRPORTNAME": "Amausi",
    },
    {
        "CITYNAME": "Lulea",
        "CITYCODE": "LLA",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "LLA",
        "AIRPORTNAME": "Kallax",
    },
    {
        "CITYNAME": "Lalibela",
        "CITYCODE": "LLI",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "LLI",
        "AIRPORTNAME": "Lalibela",
    },
    {
        "CITYNAME": "Kelila",
        "CITYCODE": "LLN",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "LLN",
        "AIRPORTNAME": "Kelila",
    },
    {
        "CITYNAME": "Lilongwe",
        "CITYCODE": "LLW",
        "COUNTRYCODE": "MW",
        "COUNTRYNAME": "Malawi",
        "AIRPORTCODE": "LLW",
        "AIRPORTNAME": "Kamuzu Int'l",
    },
    {
        "CITYNAME": "Lake Minchumina",
        "CITYCODE": "LMA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LMA",
        "AIRPORTNAME": "Lake Minchumina",
    },
    {
        "CITYNAME": "Salima",
        "CITYCODE": "LMB",
        "COUNTRYCODE": "MW",
        "COUNTRYNAME": "Malawi",
        "AIRPORTCODE": "LMB",
        "AIRPORTNAME": "Salima",
    },
    {
        "CITYNAME": "Lamacarena",
        "CITYCODE": "LMC",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "LMC",
        "AIRPORTNAME": "Lamacarena",
    },
    {
        "CITYNAME": "Lumi",
        "CITYCODE": "LMI",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "LMI",
        "AIRPORTNAME": "Lumi",
    },
    {
        "CITYNAME": "Lae Island",
        "CITYCODE": "LML",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "LML",
        "AIRPORTNAME": "Lae Island",
    },
    {
        "CITYNAME": "Los Mochis",
        "CITYCODE": "LMM",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "LMM",
        "AIRPORTNAME": "Federl",
    },
    {
        "CITYNAME": "Limbang",
        "CITYCODE": "LMN",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "LMN",
        "AIRPORTNAME": "Limbang",
    },
    {
        "CITYNAME": "Lampedusa",
        "CITYCODE": "LMP",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "LMP",
        "AIRPORTNAME": "Lampedusa",
    },
    {
        "CITYNAME": "Klamath Falls",
        "CITYCODE": "LMT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LMT",
        "AIRPORTNAME": "Kinsley Field",
    },
    {
        "CITYNAME": "Lopez De Micay",
        "CITYCODE": "LMX",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "LMX",
        "AIRPORTNAME": "Lopez De Micay",
    },
    {
        "CITYNAME": "Lake Murray",
        "CITYCODE": "LMY",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "LMY",
        "AIRPORTNAME": "Lake Murray",
    },
    {
        "CITYNAME": "Lamen Bay",
        "CITYCODE": "LNB",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "LNB",
        "AIRPORTNAME": "Lamen Bay",
    },
    {
        "CITYNAME": "Lonorore",
        "CITYCODE": "LNE",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "LNE",
        "AIRPORTNAME": "Lonorore",
    },
    {
        "CITYNAME": "Munbil",
        "CITYCODE": "LNF",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "LNF",
        "AIRPORTNAME": "Munbil",
    },
    {
        "CITYNAME": "Lincoln",
        "CITYCODE": "LNK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LNK",
        "AIRPORTNAME": "Lincoln Municipal",
    },
    {
        "CITYNAME": "Leonora",
        "CITYCODE": "LNO",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "LNO",
        "AIRPORTNAME": "Leonora",
    },
    {
        "CITYNAME": "Lancaster",
        "CITYCODE": "LNS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LNS",
        "AIRPORTNAME": "Lancaster",
    },
    {
        "CITYNAME": "Lanai City",
        "CITYCODE": "LNY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LNY",
        "AIRPORTNAME": "Lanai City",
    },
    {
        "CITYNAME": "Linz",
        "CITYCODE": "LNZ",
        "COUNTRYCODE": "AT",
        "COUNTRYNAME": "Austria",
        "AIRPORTCODE": "LNZ",
        "AIRPORTNAME": "Hoersching",
    },
    {
        "CITYNAME": "Linz",
        "CITYCODE": "LNZ",
        "COUNTRYCODE": "AT",
        "COUNTRYNAME": "Austria",
        "AIRPORTCODE": "LZS",
        "AIRPORTNAME": "Linz Central Station Airport",
    },
    {
        "CITYNAME": "Longana",
        "CITYCODE": "LOD",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "LOD",
        "AIRPORTNAME": "Longana",
    },
    {
        "CITYNAME": "Loei",
        "CITYCODE": "LOE",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "LOE",
        "AIRPORTNAME": "Loei",
    },
    {
        "CITYNAME": "Loen",
        "CITYCODE": "LOF",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "LOF",
        "AIRPORTNAME": "Loen",
    },
    {
        "CITYNAME": "Loja",
        "CITYCODE": "LOH",
        "COUNTRYCODE": "EC",
        "COUNTRYNAME": "Ecuador",
        "AIRPORTCODE": "LOH",
        "AIRPORTNAME": "Loja",
    },
    {
        "CITYNAME": "Lodwar",
        "CITYCODE": "LOK",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "LOK",
        "AIRPORTNAME": "Lodwar",
    },
    {
        "CITYNAME": "London",
        "CITYCODE": "LON",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "LCY",
        "AIRPORTNAME": "London City Apt",
    },
    {
        "CITYNAME": "London",
        "CITYCODE": "LON",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "LGW",
        "AIRPORTNAME": "Gatwick",
    },
    {
        "CITYNAME": "London",
        "CITYCODE": "LON",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "LHR",
        "AIRPORTNAME": "Heathrow",
    },
    {
        "CITYNAME": "London",
        "CITYCODE": "LON",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "LTN",
        "AIRPORTNAME": "Luton Airport",
    },
    {
        "CITYNAME": "London",
        "CITYCODE": "LON",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "QQK",
        "AIRPORTNAME": "Kings Cross Railway Station Airport",
    },
    {
        "CITYNAME": "London",
        "CITYCODE": "LON",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "STN",
        "AIRPORTNAME": "Stansted",
    },
    {
        "CITYNAME": "Lombok",
        "CITYCODE": "LOP",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "LOP",
        "AIRPORTNAME": "Lombok",
    },
    {
        "CITYNAME": "Lagos",
        "CITYCODE": "LOS",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "LOS",
        "AIRPORTNAME": "Murtala Muhammed",
    },
    {
        "CITYNAME": "Louisa",
        "CITYCODE": "LOW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LOW",
        "AIRPORTNAME": "LOUISA",
    },
    {
        "CITYNAME": "Loyangalani",
        "CITYCODE": "LOY",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "LOY",
        "AIRPORTNAME": "Loyangalani",
    },
    {
        "CITYNAME": "Gran Canaria",
        "CITYCODE": "LPA",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "LPA",
        "AIRPORTNAME": "Las Palmas",
    },
    {
        "CITYNAME": "La Paz",
        "CITYCODE": "LPB",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "LPB",
        "AIRPORTNAME": "El Alto",
    },
    {
        "CITYNAME": "La Pedrera",
        "CITYCODE": "LPD",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "LPD",
        "AIRPORTNAME": "La Pedrera",
    },
    {
        "CITYNAME": "La Primavera",
        "CITYCODE": "LPE",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "LPE",
        "AIRPORTNAME": "La Primavera",
    },
    {
        "CITYNAME": "La Plata",
        "CITYCODE": "LPG",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "LPG",
        "AIRPORTNAME": "La Plata",
    },
    {
        "CITYNAME": "Linkoping",
        "CITYCODE": "LPI",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "LPI",
        "AIRPORTNAME": "Linkoping",
    },
    {
        "CITYNAME": "Liverpool",
        "CITYCODE": "LPL",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "LPL",
        "AIRPORTNAME": "Liverpool Int'l",
    },
    {
        "CITYNAME": "Lamap",
        "CITYCODE": "LPM",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "LPM",
        "AIRPORTNAME": "Lamap",
    },
    {
        "CITYNAME": "Lappeenranta",
        "CITYCODE": "LPP",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "LPP",
        "AIRPORTNAME": "Lappeenranta",
    },
    {
        "CITYNAME": "Luang Prabang",
        "CITYCODE": "LPQ",
        "COUNTRYCODE": "LA",
        "COUNTRYNAME": "Lao People's Democratic Republic",
        "AIRPORTCODE": "LPQ",
        "AIRPORTNAME": "Luang Prabang",
    },
    {
        "CITYNAME": "Lopez Island",
        "CITYCODE": "LPS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LPS",
        "AIRPORTNAME": "Lopez Island",
    },
    {
        "CITYNAME": "Lampang",
        "CITYCODE": "LPT",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "LPT",
        "AIRPORTNAME": "Lampang",
    },
    {
        "CITYNAME": "Long Apung",
        "CITYCODE": "LPU",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "LPU",
        "AIRPORTNAME": "Long Apung",
    },
    {
        "CITYNAME": "Little Port Walte",
        "CITYCODE": "LPW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LPW",
        "AIRPORTNAME": "Little Port Walte",
    },
    {
        "CITYNAME": "Liepaya",
        "CITYCODE": "LPX",
        "COUNTRYCODE": "LV",
        "COUNTRYNAME": "Latvia",
        "AIRPORTCODE": "LPX",
        "AIRPORTNAME": "Liepaja Intl Airport",
    },
    {
        "CITYNAME": "Le Puy",
        "CITYCODE": "LPY",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "LPY",
        "AIRPORTNAME": "Loudes",
    },
    {
        "CITYNAME": "Qala Nau",
        "CITYCODE": "LQN",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "LQN",
        "AIRPORTNAME": "Qala Nau",
    },
    {
        "CITYNAME": "Larisa",
        "CITYCODE": "LRA",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "LRA",
        "AIRPORTNAME": "Larisa",
    },
    {
        "CITYNAME": "Leribe",
        "CITYCODE": "LRB",
        "COUNTRYCODE": "LS",
        "COUNTRYNAME": "Lesotho",
        "AIRPORTCODE": "LRB",
        "AIRPORTNAME": "Leribe",
    },
    {
        "CITYNAME": "Laredo",
        "CITYCODE": "LRD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LRD",
        "AIRPORTNAME": "Laredo Int'l",
    },
    {
        "CITYNAME": "Longreach",
        "CITYCODE": "LRE",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "LRE",
        "AIRPORTNAME": "Longreach",
    },
    {
        "CITYNAME": "La Rochelle",
        "CITYCODE": "LRH",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "LRH",
        "AIRPORTNAME": "Laleu",
    },
    {
        "CITYNAME": "La Romana",
        "CITYCODE": "LRM",
        "COUNTRYCODE": "DO",
        "COUNTRYNAME": "Dominican Republic",
        "AIRPORTCODE": "LRM",
        "AIRPORTNAME": "La Romana",
    },
    {
        "CITYNAME": "LAR",
        "CITYCODE": "LRR",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "LRR",
        "AIRPORTNAME": "Lar airport ",
    },
    {
        "CITYNAME": "Leros",
        "CITYCODE": "LRS",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "LRS",
        "AIRPORTNAME": "Leros",
    },
    {
        "CITYNAME": "Lorient",
        "CITYCODE": "LRT",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "LRT",
        "AIRPORTNAME": "Lann Bihoue",
    },
    {
        "CITYNAME": "Las Cruces",
        "CITYCODE": "LRU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LRU",
        "AIRPORTNAME": "Las Cruces Municipal",
    },
    {
        "CITYNAME": "Los Roques",
        "CITYCODE": "LRV",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "LRV",
        "AIRPORTNAME": "Los Roques",
    },
    {
        "CITYNAME": "Losuia",
        "CITYCODE": "LSA",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "LSA",
        "AIRPORTNAME": "Losuia",
    },
    {
        "CITYNAME": "La Serena",
        "CITYCODE": "LSC",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "LSC",
        "AIRPORTNAME": "La Florida",
    },
    {
        "CITYNAME": "La Crosse",
        "CITYCODE": "LSE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LSE",
        "AIRPORTNAME": "La Crosse Municipal",
    },
    {
        "CITYNAME": "Lashio",
        "CITYCODE": "LSH",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "LSH",
        "AIRPORTNAME": "Lashio",
    },
    {
        "CITYNAME": "Long Semado",
        "CITYCODE": "LSM",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "LSM",
        "AIRPORTNAME": "Lawas",
    },
    {
        "CITYNAME": "Las Piedras",
        "CITYCODE": "LSP",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "LSP",
        "AIRPORTNAME": "Josefa Camejo",
    },
    {
        "CITYNAME": "Los Angeles",
        "CITYCODE": "LSQ",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "LSQ",
        "AIRPORTNAME": "Los Angeles",
    },
    {
        "CITYNAME": "Terre De Haut",
        "CITYCODE": "LSS",
        "COUNTRYCODE": "GP",
        "COUNTRYNAME": "Guadeloupe",
        "AIRPORTCODE": "LSS",
        "AIRPORTNAME": "Terre De Haut",
    },
    {
        "CITYNAME": "Launceston",
        "CITYCODE": "LST",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "LST",
        "AIRPORTNAME": "Launceston",
    },
    {
        "CITYNAME": "Lhoksumawe",
        "CITYCODE": "LSW",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "LSW",
        "AIRPORTNAME": "Lhoksumawe",
    },
    {
        "CITYNAME": "Lismore",
        "CITYCODE": "LSY",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "LSY",
        "AIRPORTNAME": "Lismore",
    },
    {
        "CITYNAME": "Ghadames",
        "CITYCODE": "LTD",
        "COUNTRYCODE": "LY",
        "COUNTRYNAME": "Libyan Arab Jamahiriya",
        "AIRPORTCODE": "LTD",
        "AIRPORTNAME": "Ghadames",
    },
    {
        "CITYNAME": "Latakia",
        "CITYCODE": "LTK",
        "COUNTRYCODE": "SY",
        "COUNTRYNAME": "Syrian Arab Republic",
        "AIRPORTCODE": "LTK",
        "AIRPORTNAME": "Latakia",
    },
    {
        "CITYNAME": "Lastourville",
        "CITYCODE": "LTL",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "LTL",
        "AIRPORTNAME": "Lastourville",
    },
    {
        "CITYNAME": "Loreto",
        "CITYCODE": "LTO",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "LTO",
        "AIRPORTNAME": "Loreto",
    },
    {
        "CITYNAME": "Le Touquet",
        "CITYCODE": "LTQ",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "LTQ",
        "AIRPORTNAME": "Le Touquet",
    },
    {
        "CITYNAME": "Saint Tropez",
        "CITYCODE": "LTT",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "LTT",
        "AIRPORTNAME": "St Tropez du Golfe Airport",
    },
    {
        "CITYNAME": "latur",
        "CITYCODE": "ltu",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "ltu",
        "AIRPORTNAME": "LATUR",
    },
    {
        "CITYNAME": "Lukla",
        "CITYCODE": "LUA",
        "COUNTRYCODE": "NP",
        "COUNTRYNAME": "Nepal",
        "AIRPORTCODE": "LUA",
        "AIRPORTNAME": "Lukla",
    },
    {
        "CITYNAME": "Luderitz",
        "CITYCODE": "LUD",
        "COUNTRYCODE": "NA",
        "COUNTRYNAME": "Namibia",
        "AIRPORTCODE": "LUD",
        "AIRPORTNAME": "Luderitz",
    },
    {
        "CITYNAME": "Lugano",
        "CITYCODE": "LUG",
        "COUNTRYCODE": "CH",
        "COUNTRYNAME": "Switzerland",
        "AIRPORTCODE": "LUG",
        "AIRPORTNAME": "Lugano",
    },
    {
        "CITYNAME": "Lugano",
        "CITYCODE": "LUG",
        "COUNTRYCODE": "CH",
        "COUNTRYNAME": "Switzerland",
        "AIRPORTCODE": "QDL",
        "AIRPORTNAME": "Lugano Railway Station",
    },
    {
        "CITYNAME": "Ludhiana",
        "CITYCODE": "LUH",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "LUH",
        "AIRPORTNAME": "Sahnewal Airport",
    },
    {
        "CITYNAME": "Lusikisiki",
        "CITYCODE": "LUJ",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "LUJ",
        "AIRPORTNAME": "Lusikisiki",
    },
    {
        "CITYNAME": "Laurel",
        "CITYCODE": "LUL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LUL",
        "AIRPORTNAME": "Hesler-Noble Field",
    },
    {
        "CITYNAME": "Laurel",
        "CITYCODE": "LUL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PIB",
        "AIRPORTNAME": "Hattisburg-Laurel Regional",
    },
    {
        "CITYNAME": "Lusaka",
        "CITYCODE": "LUN",
        "COUNTRYCODE": "ZM",
        "COUNTRYNAME": "Zambia",
        "AIRPORTCODE": "LUN",
        "AIRPORTNAME": "Lusaka",
    },
    {
        "CITYNAME": "Luena",
        "CITYCODE": "LUO",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "LUO",
        "AIRPORTNAME": "Luena",
    },
    {
        "CITYNAME": "Kalaupapa",
        "CITYCODE": "LUP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LUP",
        "AIRPORTNAME": "Kalaupapa",
    },
    {
        "CITYNAME": "San Luis",
        "CITYCODE": "LUQ",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "LUQ",
        "AIRPORTNAME": "San Luis",
    },
    {
        "CITYNAME": "Langgur",
        "CITYCODE": "LUV",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "LUV",
        "AIRPORTNAME": "Langgur",
    },
    {
        "CITYNAME": "Luwuk",
        "CITYCODE": "LUW",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "LUW",
        "AIRPORTNAME": "Luwuk",
    },
    {
        "CITYNAME": "Luxembourg",
        "CITYCODE": "LUX",
        "COUNTRYCODE": "LU",
        "COUNTRYNAME": "Luxembourg",
        "AIRPORTCODE": "LUX",
        "AIRPORTNAME": "Luxembourg",
    },
    {
        "CITYNAME": "Lushan",
        "CITYCODE": "LUZ",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "LUZ",
        "AIRPORTNAME": "Lublin Airport",
    },
    {
        "CITYNAME": "Livramento",
        "CITYCODE": "LVB",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "LVB",
        "AIRPORTNAME": "Dos Galpoes",
    },
    {
        "CITYNAME": "Lime Village",
        "CITYCODE": "LVD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LVD",
        "AIRPORTNAME": "Lime Village",
    },
    {
        "CITYNAME": "Livingstone",
        "CITYCODE": "LVI",
        "COUNTRYCODE": "ZM",
        "COUNTRYNAME": "Zambia",
        "AIRPORTCODE": "LVI",
        "AIRPORTNAME": "Livingstone",
    },
    {
        "CITYNAME": "Laverton",
        "CITYCODE": "LVO",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "LVO",
        "AIRPORTNAME": "Laverton",
    },
    {
        "CITYNAME": "Las Vegas",
        "CITYCODE": "LVS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LVS",
        "AIRPORTNAME": "Las Vegas",
    },
    {
        "CITYNAME": "Lewisburg",
        "CITYCODE": "LWB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LWB",
        "AIRPORTNAME": "Greenbrier Valley",
    },
    {
        "CITYNAME": "Lewoleba",
        "CITYCODE": "LWE",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "LWE",
        "AIRPORTNAME": "Lewoleba",
    },
    {
        "CITYNAME": "Leninakan",
        "CITYCODE": "LWN",
        "COUNTRYCODE": "AM",
        "COUNTRYNAME": "Armenia",
        "AIRPORTCODE": "LWN",
        "AIRPORTNAME": "Leninakan",
    },
    {
        "CITYNAME": "Lvov",
        "CITYCODE": "LWO",
        "COUNTRYCODE": "UA",
        "COUNTRYNAME": "Ukraine",
        "AIRPORTCODE": "LWO",
        "AIRPORTNAME": "Snilow",
    },
    {
        "CITYNAME": "Lewiston",
        "CITYCODE": "LWS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LWS",
        "AIRPORTNAME": "Nez Perce County Regional",
    },
    {
        "CITYNAME": "Lewistown",
        "CITYCODE": "LWT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LWT",
        "AIRPORTNAME": "Lewistown Municipal",
    },
    {
        "CITYNAME": "Lhasa",
        "CITYCODE": "LXA",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "LXA",
        "AIRPORTNAME": "Lhasa",
    },
    {
        "CITYNAME": "Liverpool",
        "CITYCODE": "LXC",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "LXC",
        "AIRPORTNAME": "Liverpool Central railway station",
    },
    {
        "CITYNAME": "Luang Namtha",
        "CITYCODE": "LXG",
        "COUNTRYCODE": "LA",
        "COUNTRYNAME": "Lao People's Democratic Republic",
        "AIRPORTCODE": "LXG",
        "AIRPORTNAME": "Luang Namtha",
    },
    {
        "CITYNAME": "Luxor",
        "CITYCODE": "LXR",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "LXR",
        "AIRPORTNAME": "Luxor",
    },
    {
        "CITYNAME": "Lemnos",
        "CITYCODE": "LXS",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "LXS",
        "AIRPORTNAME": "Lemnos",
    },
    {
        "CITYNAME": "Luoyang",
        "CITYCODE": "LYA",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "LYA",
        "AIRPORTNAME": "Luoyang",
    },
    {
        "CITYNAME": "Little Cayman",
        "CITYCODE": "LYB",
        "COUNTRYCODE": "KY",
        "COUNTRYNAME": "Cayman Islands",
        "AIRPORTCODE": "LYB",
        "AIRPORTNAME": "Little Cayman",
    },
    {
        "CITYNAME": "Lycksele",
        "CITYCODE": "LYC",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "LYC",
        "AIRPORTNAME": "Lycksele",
    },
    {
        "CITYNAME": "Lianyungang",
        "CITYCODE": "LYG",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "LYG",
        "AIRPORTNAME": "Lianyungang",
    },
    {
        "CITYNAME": "Lynchburg",
        "CITYCODE": "LYH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LYH",
        "AIRPORTNAME": "Preston-Glenn Field",
    },
    {
        "CITYNAME": "Linyi",
        "CITYCODE": "LYI",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "LYI",
        "AIRPORTNAME": "LINYI",
    },
    {
        "CITYNAME": "Faisalabad",
        "CITYCODE": "LYP",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "LYP",
        "AIRPORTNAME": "Faisalabad",
    },
    {
        "CITYNAME": "Longyearbyen",
        "CITYCODE": "LYR",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "LYR",
        "AIRPORTNAME": "Svalbard",
    },
    {
        "CITYNAME": "Lyon",
        "CITYCODE": "LYS",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "LYN",
        "AIRPORTNAME": "Bron",
    },
    {
        "CITYNAME": "Lyon",
        "CITYCODE": "LYS",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "LYS",
        "AIRPORTNAME": "St ExupÃ©ry",
    },
    {
        "CITYNAME": "Lydd",
        "CITYCODE": "LYX",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "LYX",
        "AIRPORTNAME": "Lydd",
    },
    {
        "CITYNAME": "Lazaro Cardenas",
        "CITYCODE": "LZC",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "LZC",
        "AIRPORTNAME": "Lazaro Cardenas",
    },
    {
        "CITYNAME": "Liuzhou",
        "CITYCODE": "LZH",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "LZH",
        "AIRPORTNAME": "Liuzhou",
    },
    {
        "CITYNAME": "Lizard Island",
        "CITYCODE": "LZR",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "LZR",
        "AIRPORTNAME": "Lizard Island",
    },
    {
        "CITYNAME": "Chennai",
        "CITYCODE": "MAA",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "MAA",
        "AIRPORTNAME": "Chennai International Airport",
    },
    {
        "CITYNAME": "Maraba",
        "CITYCODE": "MAB",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "MAB",
        "AIRPORTNAME": "Maraba",
    },
    {
        "CITYNAME": "Madrid",
        "CITYCODE": "MAD",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "MAD",
        "AIRPORTNAME": "Barajas",
    },
    {
        "CITYNAME": "Madrid",
        "CITYCODE": "MAD",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "XTI",
        "AIRPORTNAME": "Chamartin Railway Station Airport",
    },
    {
        "CITYNAME": "Madera",
        "CITYCODE": "MAE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MAE",
        "AIRPORTNAME": "Madera",
    },
    {
        "CITYNAME": "Midland",
        "CITYCODE": "MAF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MAF",
        "AIRPORTNAME": "Odessa Regional",
    },
    {
        "CITYNAME": "Madang",
        "CITYCODE": "MAG",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "MAG",
        "AIRPORTNAME": "Madang",
    },
    {
        "CITYNAME": "Mahon",
        "CITYCODE": "MAH",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "MAH",
        "AIRPORTNAME": "Menorca",
    },
    {
        "CITYNAME": "Majuro",
        "CITYCODE": "MAJ",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "MAJ",
        "AIRPORTNAME": "Amata Kabua Int'l",
    },
    {
        "CITYNAME": "Mangole",
        "CITYCODE": "MAL",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "MAL",
        "AIRPORTNAME": "Mangole",
    },
    {
        "CITYNAME": "Manchester",
        "CITYCODE": "MAN",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "MAN",
        "AIRPORTNAME": "Manchester Int'l",
    },
    {
        "CITYNAME": "Manaus",
        "CITYCODE": "MAO",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "MAO",
        "AIRPORTNAME": "Eduardo Gomes Int'l",
    },
    {
        "CITYNAME": "Mamai",
        "CITYCODE": "MAP",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "MAP",
        "AIRPORTNAME": "Mamai",
    },
    {
        "CITYNAME": "Mae Sot",
        "CITYCODE": "MAQ",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "MAQ",
        "AIRPORTNAME": "Mae Sot",
    },
    {
        "CITYNAME": "Maracaibo",
        "CITYCODE": "MAR",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "MAR",
        "AIRPORTNAME": "La Chinita",
    },
    {
        "CITYNAME": "Manus Island",
        "CITYCODE": "MAS",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "MAS",
        "AIRPORTNAME": "Momote",
    },
    {
        "CITYNAME": "Maupiti",
        "CITYCODE": "MAU",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "MAU",
        "AIRPORTNAME": "Maupiti",
    },
    {
        "CITYNAME": "Maloelap Island",
        "CITYCODE": "MAV",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "MAV",
        "AIRPORTNAME": "Maloelap Island",
    },
    {
        "CITYNAME": "Matam",
        "CITYCODE": "MAX",
        "COUNTRYCODE": "SN",
        "COUNTRYNAME": "Senegal",
        "AIRPORTCODE": "MAX",
        "AIRPORTNAME": "Matam",
    },
    {
        "CITYNAME": "Mangrove Cay",
        "CITYCODE": "MAY",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "MAY",
        "AIRPORTNAME": "Mangrove Cay",
    },
    {
        "CITYNAME": "Mayaguez",
        "CITYCODE": "MAZ",
        "COUNTRYCODE": "PR",
        "COUNTRYNAME": "Puerto Rico",
        "AIRPORTCODE": "MAZ",
        "AIRPORTNAME": "Eugenio M De Hostos",
    },
    {
        "CITYNAME": "Mombasa",
        "CITYCODE": "MBA",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "MBA",
        "AIRPORTNAME": "Moi Int'l",
    },
    {
        "CITYNAME": "Marble Bar",
        "CITYCODE": "MBB",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MBB",
        "AIRPORTNAME": "Marble Bar",
    },
    {
        "CITYNAME": "Mbigou",
        "CITYCODE": "MBC",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "MBC",
        "AIRPORTNAME": "Mbigou",
    },
    {
        "CITYNAME": "Mmabatho",
        "CITYCODE": "MBD",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "MBD",
        "AIRPORTNAME": "Mmabatho Int'l",
    },
    {
        "CITYNAME": "Monbetsu",
        "CITYCODE": "MBE",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "MBE",
        "AIRPORTNAME": "Monbetsu",
    },
    {
        "CITYNAME": "Maryborough",
        "CITYCODE": "MBH",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MBH",
        "AIRPORTNAME": "Maryborough",
    },
    {
        "CITYNAME": "Mbeya",
        "CITYCODE": "MBI",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "MBI",
        "AIRPORTNAME": "Mbeya Airport",
    },
    {
        "CITYNAME": "Montego Bay",
        "CITYCODE": "MBJ",
        "COUNTRYCODE": "JM",
        "COUNTRYNAME": "Jamaica",
        "AIRPORTCODE": "MBJ",
        "AIRPORTNAME": "Sangster Int'l",
    },
    {
        "CITYNAME": "Manistee",
        "CITYCODE": "MBL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MBL",
        "AIRPORTNAME": "Blacker",
    },
    {
        "CITYNAME": "Saginaw Baycity",
        "CITYCODE": "MBS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MBS",
        "AIRPORTNAME": "Tri City",
    },
    {
        "CITYNAME": "Masbate",
        "CITYCODE": "MBT",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "MBT",
        "AIRPORTNAME": "Moises R Espinosa Airport",
    },
    {
        "CITYNAME": "Mbambanakira",
        "CITYCODE": "MBU",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "MBU",
        "AIRPORTNAME": "Mbambanakira",
    },
    {
        "CITYNAME": "Moorabbin",
        "CITYCODE": "MBW",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MBW",
        "AIRPORTNAME": "Moorabbin",
    },
    {
        "CITYNAME": "Maribor",
        "CITYCODE": "MBX",
        "COUNTRYCODE": "SI",
        "COUNTRYNAME": "Slovenia",
        "AIRPORTCODE": "MBX",
        "AIRPORTNAME": "Maribor",
    },
    {
        "CITYNAME": "Macenta",
        "CITYCODE": "MCA",
        "COUNTRYCODE": "GN",
        "COUNTRYNAME": "Guinea",
        "AIRPORTCODE": "MCA",
        "AIRPORTNAME": "Macenta",
    },
    {
        "CITYNAME": "Merced",
        "CITYCODE": "MCE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MCE",
        "AIRPORTNAME": "Merced Municipal",
    },
    {
        "CITYNAME": "Mcgrath",
        "CITYCODE": "MCG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MCG",
        "AIRPORTNAME": "Mcgrath",
    },
    {
        "CITYNAME": "Machala",
        "CITYCODE": "MCH",
        "COUNTRYCODE": "EC",
        "COUNTRYNAME": "Ecuador",
        "AIRPORTCODE": "MCH",
        "AIRPORTNAME": "Machala",
    },
    {
        "CITYNAME": "Mccook",
        "CITYCODE": "MCK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MCK",
        "AIRPORTNAME": "Mccook",
    },
    {
        "CITYNAME": "Monte Carlo",
        "CITYCODE": "MCM",
        "COUNTRYCODE": "MC",
        "COUNTRYNAME": "Monaco",
        "AIRPORTCODE": "MCM",
        "AIRPORTNAME": "Monaco",
    },
    {
        "CITYNAME": "Macon",
        "CITYCODE": "MCN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MCN",
        "AIRPORTNAME": "Lewis B Wilson",
    },
    {
        "CITYNAME": "Macapa",
        "CITYCODE": "MCP",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "MCP",
        "AIRPORTNAME": "Macapa Int'l",
    },
    {
        "CITYNAME": "Muscat",
        "CITYCODE": "MCT",
        "COUNTRYCODE": "OM",
        "COUNTRYNAME": "Oman",
        "AIRPORTCODE": "MCT",
        "AIRPORTNAME": "Seeb",
    },
    {
        "CITYNAME": "Montlucon",
        "CITYCODE": "MCU",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "MCU",
        "AIRPORTNAME": "Gueret (Lepaud)",
    },
    {
        "CITYNAME": "Mason City",
        "CITYCODE": "MCW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MCW",
        "AIRPORTNAME": "Mason City",
    },
    {
        "CITYNAME": "Makhachkala",
        "CITYCODE": "MCX",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "MCX",
        "AIRPORTNAME": "Makhachkala",
    },
    {
        "CITYNAME": "Maroochydore",
        "CITYCODE": "MCY",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MCY",
        "AIRPORTNAME": "Maroochydore",
    },
    {
        "CITYNAME": "Maceio",
        "CITYCODE": "MCZ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "MCZ",
        "AIRPORTNAME": "Palmares",
    },
    {
        "CITYNAME": "Manado",
        "CITYCODE": "MDC",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "MDC",
        "AIRPORTNAME": "Samratulangi",
    },
    {
        "CITYNAME": "Medellin",
        "CITYCODE": "MDE",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "MDE",
        "AIRPORTNAME": "Jose Marie Cordova",
    },
    {
        "CITYNAME": "Medford",
        "CITYCODE": "MDF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MDF",
        "AIRPORTNAME": "Medford",
    },
    {
        "CITYNAME": "Makurdi",
        "CITYCODE": "MDI",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "MDI",
        "AIRPORTNAME": "Makurdi",
    },
    {
        "CITYNAME": "Mbandaka",
        "CITYCODE": "MDK",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "MDK",
        "AIRPORTNAME": "Mbandaka",
    },
    {
        "CITYNAME": "Mandalay",
        "CITYCODE": "MDL",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "MDL",
        "AIRPORTNAME": "Annisaton",
    },
    {
        "CITYNAME": "Mindiptana",
        "CITYCODE": "MDP",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "MDP",
        "AIRPORTNAME": "Mindiptana",
    },
    {
        "CITYNAME": "Mar Del Plata",
        "CITYCODE": "MDQ",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "MDQ",
        "AIRPORTNAME": "Mar Del Plata",
    },
    {
        "CITYNAME": "Middle Caicos",
        "CITYCODE": "MDS",
        "COUNTRYCODE": "TC",
        "COUNTRYNAME": "Turcs & Caicos Islands",
        "AIRPORTCODE": "MDS",
        "AIRPORTNAME": "Middle Caicos",
    },
    {
        "CITYNAME": "Mendi",
        "CITYCODE": "MDU",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "MDU",
        "AIRPORTNAME": "Mendi",
    },
    {
        "CITYNAME": "Medouneu",
        "CITYCODE": "MDV",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "MDV",
        "AIRPORTNAME": "Medouneu",
    },
    {
        "CITYNAME": "Mendoza",
        "CITYCODE": "MDZ",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "MDZ",
        "AIRPORTNAME": "El Plumerillo",
    },
    {
        "CITYNAME": "Macae",
        "CITYCODE": "MEA",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "MEA",
        "AIRPORTNAME": "Macae Benedito Lacerda Airport",
    },
    {
        "CITYNAME": "Manta",
        "CITYCODE": "MEC",
        "COUNTRYCODE": "EC",
        "COUNTRYNAME": "Ecuador",
        "AIRPORTCODE": "MEC",
        "AIRPORTNAME": "Manta",
    },
    {
        "CITYNAME": "Madinah",
        "CITYCODE": "MED",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "MED",
        "AIRPORTNAME": "MADINAH",
    },
    {
        "CITYNAME": "Mare",
        "CITYCODE": "MEE",
        "COUNTRYCODE": "NC",
        "COUNTRYNAME": "New Caledonia",
        "AIRPORTCODE": "MEE",
        "AIRPORTNAME": "Mare",
    },
    {
        "CITYNAME": "Melfi",
        "CITYCODE": "MEF",
        "COUNTRYCODE": "TD",
        "COUNTRYNAME": "Chad",
        "AIRPORTCODE": "MEF",
        "AIRPORTNAME": "Melfi",
    },
    {
        "CITYNAME": "Malange",
        "CITYCODE": "MEG",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "MEG",
        "AIRPORTNAME": "Malange",
    },
    {
        "CITYNAME": "Mehamn",
        "CITYCODE": "MEH",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "MEH",
        "AIRPORTNAME": "Mehamn",
    },
    {
        "CITYNAME": "Melbourne",
        "CITYCODE": "MEL",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MEB",
        "AIRPORTNAME": "Essendon",
    },
    {
        "CITYNAME": "Melbourne",
        "CITYCODE": "MEL",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MEL",
        "AIRPORTNAME": "Tullamarine",
    },
    {
        "CITYNAME": "Memphis",
        "CITYCODE": "MEM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MEM",
        "AIRPORTNAME": "Memphis Int'l",
    },
    {
        "CITYNAME": "Medan",
        "CITYCODE": "MES",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "KNO",
        "AIRPORTNAME": "Kuala Namu International Airport",
    },
    {
        "CITYNAME": "Medan",
        "CITYCODE": "MES",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "MES",
        "AIRPORTNAME": "Polania",
    },
    {
        "CITYNAME": "Mexico City",
        "CITYCODE": "MEX",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "AZP",
        "AIRPORTNAME": "Atizapan",
    },
    {
        "CITYNAME": "Mexico City",
        "CITYCODE": "MEX",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "MEX",
        "AIRPORTNAME": "Juarez Int'l",
    },
    {
        "CITYNAME": "Mexico City",
        "CITYCODE": "MEX",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "TLC",
        "AIRPORTNAME": "Toluca",
    },
    {
        "CITYNAME": "Meghauli",
        "CITYCODE": "MEY",
        "COUNTRYCODE": "NP",
        "COUNTRYNAME": "Nepal",
        "AIRPORTCODE": "MEY",
        "AIRPORTNAME": "Meghauli",
    },
    {
        "CITYNAME": "Messina",
        "CITYCODE": "MEZ",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "MEZ",
        "AIRPORTNAME": "Messina",
    },
    {
        "CITYNAME": "Mafia",
        "CITYCODE": "MFA",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "MFA",
        "AIRPORTNAME": "Mafia",
    },
    {
        "CITYNAME": "Mafeteng",
        "CITYCODE": "MFC",
        "COUNTRYCODE": "LS",
        "COUNTRYNAME": "Lesotho",
        "AIRPORTCODE": "MFC",
        "AIRPORTNAME": "Mafeteng",
    },
    {
        "CITYNAME": "Mcallen",
        "CITYCODE": "MFE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MFE",
        "AIRPORTNAME": "Miller Int'l",
    },
    {
        "CITYNAME": "Moanda",
        "CITYCODE": "MFF",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "MFF",
        "AIRPORTNAME": "Moanda",
    },
    {
        "CITYNAME": "Muzaffarabad",
        "CITYCODE": "MFG",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "MFG",
        "AIRPORTNAME": "Muzaffarabad",
    },
    {
        "CITYNAME": "Moala",
        "CITYCODE": "MFJ",
        "COUNTRYCODE": "FJ",
        "COUNTRYNAME": "Fiji Islands",
        "AIRPORTCODE": "MFJ",
        "AIRPORTNAME": "Moala",
    },
    {
        "CITYNAME": "Macau",
        "CITYCODE": "MFM",
        "COUNTRYCODE": "MO",
        "COUNTRYNAME": "Macau",
        "AIRPORTCODE": "MFM",
        "AIRPORTNAME": "Macau",
    },
    {
        "CITYNAME": "Macau",
        "CITYCODE": "MFM",
        "COUNTRYCODE": "MO",
        "COUNTRYNAME": "Macau",
        "AIRPORTCODE": "YFT",
        "AIRPORTNAME": "Taipa Ferry Terminal",
    },
    {
        "CITYNAME": "Milford Sound",
        "CITYCODE": "MFN",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "MFN",
        "AIRPORTNAME": "Milford Sound",
    },
    {
        "CITYNAME": "Maradi",
        "CITYCODE": "MFQ",
        "COUNTRYCODE": "NE",
        "COUNTRYNAME": "Niger",
        "AIRPORTCODE": "MFQ",
        "AIRPORTNAME": "Maradi",
    },
    {
        "CITYNAME": "Medford",
        "CITYCODE": "MFR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MFR",
        "AIRPORTNAME": "Jackson County",
    },
    {
        "CITYNAME": "Mfuwe",
        "CITYCODE": "MFU",
        "COUNTRYCODE": "ZM",
        "COUNTRYNAME": "Zambia",
        "AIRPORTCODE": "MFU",
        "AIRPORTNAME": "Mfuwe",
    },
    {
        "CITYNAME": "Managua",
        "CITYCODE": "MGA",
        "COUNTRYCODE": "NI",
        "COUNTRYNAME": "Nicaragua",
        "AIRPORTCODE": "MGA",
        "AIRPORTNAME": "Augusto C Sandino",
    },
    {
        "CITYNAME": "Mount Gambier",
        "CITYCODE": "MGB",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MGB",
        "AIRPORTNAME": "Mount Gambier",
    },
    {
        "CITYNAME": "Magdalena",
        "CITYCODE": "MGD",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "MGD",
        "AIRPORTNAME": "Magdalena",
    },
    {
        "CITYNAME": "Maringa",
        "CITYCODE": "MGF",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "MGF",
        "AIRPORTNAME": "Maringa",
    },
    {
        "CITYNAME": "Margate",
        "CITYCODE": "MGH",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "MGH",
        "AIRPORTNAME": "Margate",
    },
    {
        "CITYNAME": "MÃ·nchengladbach",
        "CITYCODE": "MGL",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "MGL",
        "AIRPORTNAME": "Monchengladbach",
    },
    {
        "CITYNAME": "Montgomery",
        "CITYCODE": "MGM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MGM",
        "AIRPORTNAME": "Dannelly Field",
    },
    {
        "CITYNAME": "Mogadishu",
        "CITYCODE": "MGQ",
        "COUNTRYCODE": "SO",
        "COUNTRYNAME": "Somalia",
        "AIRPORTCODE": "MGQ",
        "AIRPORTNAME": "Mogadishu Int'l",
    },
    {
        "CITYNAME": "Mangaia Island",
        "CITYCODE": "MGS",
        "COUNTRYCODE": "CK",
        "COUNTRYNAME": "Cook Islands",
        "AIRPORTCODE": "MGS",
        "AIRPORTNAME": "Mangaia Island",
    },
    {
        "CITYNAME": "Millingimbi",
        "CITYCODE": "MGT",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MGT",
        "AIRPORTNAME": "Millingimbi",
    },
    {
        "CITYNAME": "Morgantown",
        "CITYCODE": "MGW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MGW",
        "AIRPORTNAME": "Morgantown",
    },
    {
        "CITYNAME": "Moabi",
        "CITYCODE": "MGX",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "MGX",
        "AIRPORTNAME": "Moabi",
    },
    {
        "CITYNAME": "Myeik",
        "CITYCODE": "MGZ",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "MGZ",
        "AIRPORTNAME": "Myeik",
    },
    {
        "CITYNAME": "Mashad",
        "CITYCODE": "MHD",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "MHD",
        "AIRPORTNAME": "Mashad",
    },
    {
        "CITYNAME": "Mitchell",
        "CITYCODE": "MHE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MHE",
        "AIRPORTNAME": "Mitchell Municipal",
    },
    {
        "CITYNAME": "Marsh Harbour",
        "CITYCODE": "MHH",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "MHH",
        "AIRPORTNAME": "Marsh Harbour Int'l",
    },
    {
        "CITYNAME": "Manhattan",
        "CITYCODE": "MHK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MHK",
        "AIRPORTNAME": "Manhattan Municipal",
    },
    {
        "CITYNAME": "Mariehamn",
        "CITYCODE": "MHQ",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "MHQ",
        "AIRPORTNAME": "Mariehamn",
    },
    {
        "CITYNAME": "Manchester",
        "CITYCODE": "MHT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MHT",
        "AIRPORTNAME": "Manchester",
    },
    {
        "CITYNAME": "Mojave",
        "CITYCODE": "MHV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MHV",
        "AIRPORTNAME": "Kern County",
    },
    {
        "CITYNAME": "Manihiki Island",
        "CITYCODE": "MHX",
        "COUNTRYCODE": "CK",
        "COUNTRYNAME": "Cook Islands",
        "AIRPORTCODE": "MHX",
        "AIRPORTNAME": "Manihiki Island",
    },
    {
        "CITYNAME": "Morehead",
        "CITYCODE": "MHY",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "MHY",
        "AIRPORTNAME": "Morehead",
    },
    {
        "CITYNAME": "Miami",
        "CITYCODE": "MIA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MIA",
        "AIRPORTNAME": "Miami Int'l",
    },
    {
        "CITYNAME": "Merida",
        "CITYCODE": "MID",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "MID",
        "AIRPORTNAME": "Rejon",
    },
    {
        "CITYNAME": "Muncie",
        "CITYCODE": "MIE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MIE",
        "AIRPORTNAME": "Delaware County",
    },
    {
        "CITYNAME": "Mianyang",
        "CITYCODE": "MIG",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "MIG",
        "AIRPORTNAME": "Mianyang Airport",
    },
    {
        "CITYNAME": "Marilia",
        "CITYCODE": "MII",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "MII",
        "AIRPORTNAME": "Dr Gasto Vidigal",
    },
    {
        "CITYNAME": "Mikkeli",
        "CITYCODE": "MIK",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "MIK",
        "AIRPORTNAME": "Mikkeli",
    },
    {
        "CITYNAME": "Milan",
        "CITYCODE": "MIL",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "BGY",
        "AIRPORTNAME": "Orio Al Serio",
    },
    {
        "CITYNAME": "Milan",
        "CITYCODE": "MIL",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "LIN",
        "AIRPORTNAME": "Linate",
    },
    {
        "CITYNAME": "Milan",
        "CITYCODE": "MIL",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "MXP",
        "AIRPORTNAME": "Malpensa",
    },
    {
        "CITYNAME": "Milan",
        "CITYCODE": "MIL",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "XIK",
        "AIRPORTNAME": "Central Station Airport",
    },
    {
        "CITYNAME": "Merimbula",
        "CITYCODE": "MIM",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MIM",
        "AIRPORTNAME": "Merimbula",
    },
    {
        "CITYNAME": "Miami",
        "CITYCODE": "MIO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MIO",
        "AIRPORTNAME": "Miami Municipal Airport",
    },
    {
        "CITYNAME": "Monastir",
        "CITYCODE": "MIR",
        "COUNTRYCODE": "TN",
        "COUNTRYNAME": "Tunisia",
        "AIRPORTCODE": "MIR",
        "AIRPORTNAME": "Habib Bourguiba Int'l",
    },
    {
        "CITYNAME": "Misima Island",
        "CITYCODE": "MIS",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "MIS",
        "AIRPORTNAME": "Misima Island",
    },
    {
        "CITYNAME": "Maiduguri",
        "CITYCODE": "MIU",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "MIU",
        "AIRPORTNAME": "Maiduguri",
    },
    {
        "CITYNAME": "Manja",
        "CITYCODE": "MJA",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "MJA",
        "AIRPORTNAME": "Manja",
    },
    {
        "CITYNAME": "Mejit Island",
        "CITYCODE": "MJB",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "MJB",
        "AIRPORTNAME": "Mejit Island",
    },
    {
        "CITYNAME": "Man",
        "CITYCODE": "MJC",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "MJC",
        "AIRPORTNAME": "Man",
    },
    {
        "CITYNAME": "Mohenjodaro",
        "CITYCODE": "MJD",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "MJD",
        "AIRPORTNAME": "Mohenjodaro",
    },
    {
        "CITYNAME": "Majkin",
        "CITYCODE": "MJE",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "MJE",
        "AIRPORTNAME": "Majkin",
    },
    {
        "CITYNAME": "Mosjoen",
        "CITYCODE": "MJF",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "MJF",
        "AIRPORTNAME": "Kjaerstad",
    },
    {
        "CITYNAME": "Mitiga",
        "CITYCODE": "MJI",
        "COUNTRYCODE": "LY",
        "COUNTRYNAME": "Libyan Arab Jamahiriya",
        "AIRPORTCODE": "MJI",
        "AIRPORTNAME": "Mitiga Airport",
    },
    {
        "CITYNAME": "Monkey Mia",
        "CITYCODE": "MJK",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MJK",
        "AIRPORTNAME": "Shark Bay - Denham",
    },
    {
        "CITYNAME": "Mouila",
        "CITYCODE": "MJL",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "MJL",
        "AIRPORTNAME": "Mouila",
    },
    {
        "CITYNAME": "Mbuji Mayi",
        "CITYCODE": "MJM",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "MJM",
        "AIRPORTNAME": "Mbuji Mayi",
    },
    {
        "CITYNAME": "Majunga",
        "CITYCODE": "MJN",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "MJN",
        "AIRPORTNAME": "Amborovy",
    },
    {
        "CITYNAME": "Mytilene",
        "CITYCODE": "MJT",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "MJT",
        "AIRPORTNAME": "Mytilene",
    },
    {
        "CITYNAME": "Mamuju",
        "CITYCODE": "MJU",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "MJU",
        "AIRPORTNAME": "Mamuju",
    },
    {
        "CITYNAME": "Murcia",
        "CITYCODE": "MJV",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "MJV",
        "AIRPORTNAME": "San Javier",
    },
    {
        "CITYNAME": "Murcia",
        "CITYCODE": "MJV",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "XUT",
        "AIRPORTNAME": "Murcia del Carmen Railway Station Airport",
    },
    {
        "CITYNAME": "Mirnyj",
        "CITYCODE": "MJZ",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "MJZ",
        "AIRPORTNAME": "Mirnyj",
    },
    {
        "CITYNAME": "Mekambo",
        "CITYCODE": "MKB",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "MKB",
        "AIRPORTNAME": "Mekambo",
    },
    {
        "CITYNAME": "Kansas City",
        "CITYCODE": "MKC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "JCI",
        "AIRPORTNAME": "Johnson Industrial",
    },
    {
        "CITYNAME": "Kansas City",
        "CITYCODE": "MKC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MCI",
        "AIRPORTNAME": "Kansas City Int'l",
    },
    {
        "CITYNAME": "Kansas City",
        "CITYCODE": "MKC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MKC",
        "AIRPORTNAME": "Kansas City Downtown Airport",
    },
    {
        "CITYNAME": "Kansas City",
        "CITYCODE": "MKC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OJC",
        "AIRPORTNAME": "Johnson Executive",
    },
    {
        "CITYNAME": "Milwaukee",
        "CITYCODE": "MKE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MKE",
        "AIRPORTNAME": "General Mitchell",
    },
    {
        "CITYNAME": "Muskegon",
        "CITYCODE": "MKG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MKG",
        "AIRPORTNAME": "Muskegon",
    },
    {
        "CITYNAME": "Mokhotlong",
        "CITYCODE": "MKH",
        "COUNTRYCODE": "LS",
        "COUNTRYNAME": "Lesotho",
        "AIRPORTCODE": "MKH",
        "AIRPORTNAME": "Mokhotlong",
    },
    {
        "CITYNAME": "M'Boki",
        "CITYCODE": "MKI",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "MKI",
        "AIRPORTNAME": "M'Boki",
    },
    {
        "CITYNAME": "Makoua",
        "CITYCODE": "MKJ",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "MKJ",
        "AIRPORTNAME": "Makoua",
    },
    {
        "CITYNAME": "Hoolehua",
        "CITYCODE": "MKK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MKK",
        "AIRPORTNAME": "Molokai",
    },
    {
        "CITYNAME": "Jackson",
        "CITYCODE": "MKL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MKL",
        "AIRPORTNAME": "Mckellar",
    },
    {
        "CITYNAME": "Mukah",
        "CITYCODE": "MKM",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "MKM",
        "AIRPORTNAME": "Mukah",
    },
    {
        "CITYNAME": "Makemo",
        "CITYCODE": "MKP",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "MKP",
        "AIRPORTNAME": "Makemo",
    },
    {
        "CITYNAME": "Merauke",
        "CITYCODE": "MKQ",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "MKQ",
        "AIRPORTNAME": "Mopah",
    },
    {
        "CITYNAME": "Meekatharra",
        "CITYCODE": "MKR",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MKR",
        "AIRPORTNAME": "Meekatharra",
    },
    {
        "CITYNAME": "Mekane Selam",
        "CITYCODE": "MKS",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "MKS",
        "AIRPORTNAME": "Mekane Selam",
    },
    {
        "CITYNAME": "Makokou",
        "CITYCODE": "MKU",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "MKU",
        "AIRPORTNAME": "Makokou",
    },
    {
        "CITYNAME": "Manokwari",
        "CITYCODE": "MKW",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "MKW",
        "AIRPORTNAME": "Rendani",
    },
    {
        "CITYNAME": "Mackay",
        "CITYCODE": "MKY",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MKY",
        "AIRPORTNAME": "Mackay",
    },
    {
        "CITYNAME": "Malacca",
        "CITYCODE": "MKZ",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "MKZ",
        "AIRPORTNAME": "Batu Berendum",
    },
    {
        "CITYNAME": "Malta",
        "CITYCODE": "MLA",
        "COUNTRYCODE": "MT",
        "COUNTRYNAME": "Malta",
        "AIRPORTCODE": "MLA",
        "AIRPORTNAME": "Luqa",
    },
    {
        "CITYNAME": "Melbourne",
        "CITYCODE": "MLB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MLB",
        "AIRPORTNAME": "Melbourne Int'l",
    },
    {
        "CITYNAME": "Male",
        "CITYCODE": "MLE",
        "COUNTRYCODE": "MV",
        "COUNTRYNAME": "Maldives",
        "AIRPORTCODE": "MLE",
        "AIRPORTNAME": "Male Int'l",
    },
    {
        "CITYNAME": "Malang",
        "CITYCODE": "MLG",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "MLG",
        "AIRPORTNAME": "Malang",
    },
    {
        "CITYNAME": "Moline",
        "CITYCODE": "MLI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MLI",
        "AIRPORTNAME": "Quad-City",
    },
    {
        "CITYNAME": "Marshall Fortuna",
        "CITYCODE": "MLL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MLL",
        "AIRPORTNAME": "Marshall Fortuna",
    },
    {
        "CITYNAME": "Morelia",
        "CITYCODE": "MLM",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "MLM",
        "AIRPORTNAME": "Morelia",
    },
    {
        "CITYNAME": "Melilla",
        "CITYCODE": "MLN",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "MLN",
        "AIRPORTNAME": "Melilla",
    },
    {
        "CITYNAME": "Milos",
        "CITYCODE": "MLO",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "MLO",
        "AIRPORTNAME": "Milos",
    },
    {
        "CITYNAME": "Malalaua",
        "CITYCODE": "MLQ",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "MLQ",
        "AIRPORTNAME": "Malalaua",
    },
    {
        "CITYNAME": "Miles City",
        "CITYCODE": "MLS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MLS",
        "AIRPORTNAME": "Miles City Municipal",
    },
    {
        "CITYNAME": "Monroe",
        "CITYCODE": "MLU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MLU",
        "AIRPORTNAME": "Monroe Municipal",
    },
    {
        "CITYNAME": "Monrovia",
        "CITYCODE": "MLW",
        "COUNTRYCODE": "LR",
        "COUNTRYNAME": "Liberia",
        "AIRPORTCODE": "MLW",
        "AIRPORTNAME": "Sprigg Payne",
    },
    {
        "CITYNAME": "Monrovia",
        "CITYCODE": "MLW",
        "COUNTRYCODE": "LR",
        "COUNTRYNAME": "Liberia",
        "AIRPORTCODE": "ROB",
        "AIRPORTNAME": "Roberts Int'l",
    },
    {
        "CITYNAME": "Malatya",
        "CITYCODE": "MLX",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "MLX",
        "AIRPORTNAME": "Malatya",
    },
    {
        "CITYNAME": "Manley Hot Spring",
        "CITYCODE": "MLY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MLY",
        "AIRPORTNAME": "Manley Hot Spring",
    },
    {
        "CITYNAME": "Melo",
        "CITYCODE": "MLZ",
        "COUNTRYCODE": "UY",
        "COUNTRYNAME": "Uruguay",
        "AIRPORTCODE": "MLZ",
        "AIRPORTNAME": "Melo",
    },
    {
        "CITYNAME": "Malmo",
        "CITYCODE": "MMA",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "MMX",
        "AIRPORTNAME": "Sturup",
    },
    {
        "CITYNAME": "Memanbetsu",
        "CITYCODE": "MMB",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "MMB",
        "AIRPORTNAME": "Memanbetsu",
    },
    {
        "CITYNAME": "Minami Daito",
        "CITYCODE": "MMD",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "MMD",
        "AIRPORTNAME": "Maridor",
    },
    {
        "CITYNAME": "Teesside",
        "CITYCODE": "MME",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "MME",
        "AIRPORTNAME": "Teesside int'l",
    },
    {
        "CITYNAME": "Mamfe",
        "CITYCODE": "MMF",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "MMF",
        "AIRPORTNAME": "Mamfe",
    },
    {
        "CITYNAME": "Mount Magnet",
        "CITYCODE": "MMG",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MMG",
        "AIRPORTNAME": "Mount Magnet",
    },
    {
        "CITYNAME": "Mammoth Lakes",
        "CITYCODE": "MMH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MMH",
        "AIRPORTNAME": "Mammoth Lakes",
    },
    {
        "CITYNAME": "Matsumoto",
        "CITYCODE": "MMJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "MMJ",
        "AIRPORTNAME": "Matsumoto",
    },
    {
        "CITYNAME": "Murmansk",
        "CITYCODE": "MMK",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "MMK",
        "AIRPORTNAME": "Murmansk",
    },
    {
        "CITYNAME": "Marshall",
        "CITYCODE": "MML",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MML",
        "AIRPORTNAME": "Marshall Municipal-Ryan Field",
    },
    {
        "CITYNAME": "Middlemount",
        "CITYCODE": "MMM",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MMM",
        "AIRPORTNAME": "Middlemount",
    },
    {
        "CITYNAME": "Maio",
        "CITYCODE": "MMO",
        "COUNTRYCODE": "CV",
        "COUNTRYNAME": "Cape Verde",
        "AIRPORTCODE": "MMO",
        "AIRPORTNAME": "Vila Do Maio",
    },
    {
        "CITYNAME": "Mompos",
        "CITYCODE": "MMP",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "MMP",
        "AIRPORTNAME": "Mompos",
    },
    {
        "CITYNAME": "Morristown",
        "CITYCODE": "MMU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MMU",
        "AIRPORTNAME": "Morristown Municipal",
    },
    {
        "CITYNAME": "Miyako Jima",
        "CITYCODE": "MMY",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "MMY",
        "AIRPORTNAME": "Hirara",
    },
    {
        "CITYNAME": "Maimana",
        "CITYCODE": "MMZ",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "MMZ",
        "AIRPORTNAME": "Maimana",
    },
    {
        "CITYNAME": "Melangguane",
        "CITYCODE": "MNA",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "MNA",
        "AIRPORTNAME": "Melangguane",
    },
    {
        "CITYNAME": "Moanda",
        "CITYCODE": "MNB",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "MNB",
        "AIRPORTNAME": "Moanda",
    },
    {
        "CITYNAME": "Nacala",
        "CITYCODE": "MNC",
        "COUNTRYCODE": "MZ",
        "COUNTRYNAME": "Mozambique",
        "AIRPORTCODE": "MNC",
        "AIRPORTNAME": "Nacala",
    },
    {
        "CITYNAME": "Mana Islands",
        "CITYCODE": "MNF",
        "COUNTRYCODE": "FJ",
        "COUNTRYNAME": "Fiji Islands",
        "AIRPORTCODE": "MNF",
        "AIRPORTNAME": "Mana Islands",
    },
    {
        "CITYNAME": "Montserrat",
        "CITYCODE": "MNI",
        "COUNTRYCODE": "MS",
        "COUNTRYNAME": "Montserrat",
        "AIRPORTCODE": "MNI",
        "AIRPORTNAME": "Blackburne",
    },
    {
        "CITYNAME": "Mananjary",
        "CITYCODE": "MNJ",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "MNJ",
        "AIRPORTNAME": "Mananjary",
    },
    {
        "CITYNAME": "Maiana",
        "CITYCODE": "MNK",
        "COUNTRYCODE": "KI",
        "COUNTRYNAME": "Kiribati",
        "AIRPORTCODE": "MNK",
        "AIRPORTNAME": "Maiana",
    },
    {
        "CITYNAME": "Manila",
        "CITYCODE": "MNL",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "MNL",
        "AIRPORTNAME": "Ninoy Aquino Int'l",
    },
    {
        "CITYNAME": "Marinette Menomin",
        "CITYCODE": "MNM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MNM",
        "AIRPORTNAME": "Marinette Menomin",
    },
    {
        "CITYNAME": "Minto",
        "CITYCODE": "MNT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MNT",
        "AIRPORTNAME": "Minto",
    },
    {
        "CITYNAME": "Maulmyine",
        "CITYCODE": "MNU",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "MNU",
        "AIRPORTNAME": "Maulmyine",
    },
    {
        "CITYNAME": "Mono",
        "CITYCODE": "MNY",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "MNY",
        "AIRPORTNAME": "Mono",
    },
    {
        "CITYNAME": "Moa",
        "CITYCODE": "MOA",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "MOA",
        "AIRPORTNAME": "Orestes Acosta",
    },
    {
        "CITYNAME": "Mobile",
        "CITYCODE": "MOB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MOB",
        "AIRPORTNAME": "Mobile Municipal",
    },
    {
        "CITYNAME": "Montes Claros",
        "CITYCODE": "MOC",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "MOC",
        "AIRPORTNAME": "Montes Claros",
    },
    {
        "CITYNAME": "Modesto",
        "CITYCODE": "MOD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MOD",
        "AIRPORTNAME": "Modesto Municipal",
    },
    {
        "CITYNAME": "Maumere",
        "CITYCODE": "MOF",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "MOF",
        "AIRPORTNAME": "Waioti",
    },
    {
        "CITYNAME": "Mong Hsat",
        "CITYCODE": "MOG",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "MOG",
        "AIRPORTNAME": "Mong Hsat",
    },
    {
        "CITYNAME": "Mitiaro Island",
        "CITYCODE": "MOI",
        "COUNTRYCODE": "CK",
        "COUNTRYNAME": "Cook Islands",
        "AIRPORTCODE": "MOI",
        "AIRPORTNAME": "Mitiaro Island",
    },
    {
        "CITYNAME": "Molde",
        "CITYCODE": "MOL",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "MOL",
        "AIRPORTNAME": "Aro",
    },
    {
        "CITYNAME": "Moudjeria",
        "CITYCODE": "MOM",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "MOM",
        "AIRPORTNAME": "Moudjeria",
    },
    {
        "CITYNAME": "Mount Cook",
        "CITYCODE": "MON",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "GTN",
        "AIRPORTNAME": "Glentanner",
    },
    {
        "CITYNAME": "Mount Cook",
        "CITYCODE": "MON",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "MON",
        "AIRPORTNAME": "Mount Cook",
    },
    {
        "CITYNAME": "Morondava",
        "CITYCODE": "MOQ",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "MOQ",
        "AIRPORTNAME": "Morondava",
    },
    {
        "CITYNAME": "Minot",
        "CITYCODE": "MOT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MOT",
        "AIRPORTNAME": "Minot Int'l",
    },
    {
        "CITYNAME": "Mountain Village",
        "CITYCODE": "MOU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MOU",
        "AIRPORTNAME": "Mountain Village",
    },
    {
        "CITYNAME": "Moranbah",
        "CITYCODE": "MOV",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MOV",
        "AIRPORTNAME": "Moranbah",
    },
    {
        "CITYNAME": "Moscow",
        "CITYCODE": "MOW",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "BKA",
        "AIRPORTNAME": "Bykovo",
    },
    {
        "CITYNAME": "Moscow",
        "CITYCODE": "MOW",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "DME",
        "AIRPORTNAME": "Domodedovo",
    },
    {
        "CITYNAME": "Moscow",
        "CITYCODE": "MOW",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "SVO",
        "AIRPORTNAME": "Sheremetyevo",
    },
    {
        "CITYNAME": "Moscow",
        "CITYCODE": "MOW",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "VKO",
        "AIRPORTNAME": "Vnukovo",
    },
    {
        "CITYNAME": "Moorea",
        "CITYCODE": "MOZ",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "MOZ",
        "AIRPORTNAME": "Temae",
    },
    {
        "CITYNAME": "Mpacha",
        "CITYCODE": "MPA",
        "COUNTRYCODE": "NA",
        "COUNTRYNAME": "Namibia",
        "AIRPORTCODE": "MPA",
        "AIRPORTNAME": "Mpacha",
    },
    {
        "CITYNAME": "Mirpur Khas",
        "CITYCODE": "MPD",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "MPD",
        "AIRPORTNAME": "Mirpur Khas",
    },
    {
        "CITYNAME": "Caticlan",
        "CITYCODE": "MPH",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "MPH",
        "AIRPORTNAME": "Godofredo P. Ramos Airport",
    },
    {
        "CITYNAME": "Montpellier",
        "CITYCODE": "MPL",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "MPL",
        "AIRPORTNAME": "Frejorgues",
    },
    {
        "CITYNAME": "Maputo",
        "CITYCODE": "MPM",
        "COUNTRYCODE": "MZ",
        "COUNTRYNAME": "Mozambique",
        "AIRPORTCODE": "MPM",
        "AIRPORTNAME": "Maputo Int'l",
    },
    {
        "CITYNAME": "Mount Pleasant",
        "CITYCODE": "MPN",
        "COUNTRYCODE": "FK",
        "COUNTRYNAME": "Falkland Islands",
        "AIRPORTCODE": "MPN",
        "AIRPORTNAME": "Mount Pleasant",
    },
    {
        "CITYNAME": "Maliana",
        "CITYCODE": "MPT",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "MPT",
        "AIRPORTNAME": "Maliana",
    },
    {
        "CITYNAME": "Mariupol",
        "CITYCODE": "MPW",
        "COUNTRYCODE": "UA",
        "COUNTRYNAME": "Ukraine",
        "AIRPORTCODE": "MPW",
        "AIRPORTNAME": "Mariupol",
    },
    {
        "CITYNAME": "Magnitogorsk",
        "CITYCODE": "MQF",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "MQF",
        "AIRPORTNAME": "Magnitogorsk",
    },
    {
        "CITYNAME": "Minacu",
        "CITYCODE": "MQH",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "MQH",
        "AIRPORTNAME": "Minacu Airport",
    },
    {
        "CITYNAME": "Mildura",
        "CITYCODE": "MQL",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MQL",
        "AIRPORTNAME": "Mildura",
    },
    {
        "CITYNAME": "Mardin",
        "CITYCODE": "MQM",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "MQM",
        "AIRPORTNAME": "Mardin Airport",
    },
    {
        "CITYNAME": "Mo I Rana",
        "CITYCODE": "MQN",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "MQN",
        "AIRPORTNAME": "Mo I Rana",
    },
    {
        "CITYNAME": "Moundou",
        "CITYCODE": "MQQ",
        "COUNTRYCODE": "TD",
        "COUNTRYNAME": "Chad",
        "AIRPORTCODE": "MQQ",
        "AIRPORTNAME": "Moundou",
    },
    {
        "CITYNAME": "Mustique Island",
        "CITYCODE": "MQS",
        "COUNTRYCODE": "VC",
        "COUNTRYNAME": "St. Vincent & the Grenadines",
        "AIRPORTCODE": "MQS",
        "AIRPORTNAME": "Mustique Island",
    },
    {
        "CITYNAME": "Marquette",
        "CITYCODE": "MQT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MQT",
        "AIRPORTNAME": "Marquette County",
    },
    {
        "CITYNAME": "Makale",
        "CITYCODE": "MQX",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "MQX",
        "AIRPORTNAME": "Makale",
    },
    {
        "CITYNAME": "Smyrna",
        "CITYCODE": "MQY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MQY",
        "AIRPORTNAME": "Smyrna Airport",
    },
    {
        "CITYNAME": "Misurata",
        "CITYCODE": "MRA",
        "COUNTRYCODE": "LY",
        "COUNTRYNAME": "Libyan Arab Jamahiriya",
        "AIRPORTCODE": "MRA",
        "AIRPORTNAME": "Misurata",
    },
    {
        "CITYNAME": "Merida",
        "CITYCODE": "MRD",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "MRD",
        "AIRPORTNAME": "A Carnevalli",
    },
    {
        "CITYNAME": "Mara Lodges",
        "CITYCODE": "MRE",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "MRE",
        "AIRPORTNAME": "Mara Lodges",
    },
    {
        "CITYNAME": "Marco Island",
        "CITYCODE": "MRK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MRK",
        "AIRPORTNAME": "Marco Island",
    },
    {
        "CITYNAME": "Masterton",
        "CITYCODE": "MRO",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "MRO",
        "AIRPORTNAME": "Masterton",
    },
    {
        "CITYNAME": "Marseille",
        "CITYCODE": "MRS",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "MRS",
        "AIRPORTNAME": "Marseille",
    },
    {
        "CITYNAME": "Mauritius",
        "CITYCODE": "MRU",
        "COUNTRYCODE": "MU",
        "COUNTRYNAME": "Mauritius",
        "AIRPORTCODE": "MRU",
        "AIRPORTNAME": "Sir Seewoosagur",
    },
    {
        "CITYNAME": "Mineralnye Vody",
        "CITYCODE": "MRV",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "MRV",
        "AIRPORTNAME": "Mineralnye Vody",
    },
    {
        "CITYNAME": "Monterey",
        "CITYCODE": "MRY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MRY",
        "AIRPORTNAME": "Monterey Peninsula",
    },
    {
        "CITYNAME": "Moree",
        "CITYCODE": "MRZ",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MRZ",
        "AIRPORTNAME": "Moree",
    },
    {
        "CITYNAME": "Muskrat Dam",
        "CITYCODE": "MSA",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "MSA",
        "AIRPORTNAME": "Muskrat Dam",
    },
    {
        "CITYNAME": "Mesa",
        "CITYCODE": "MSC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MSC",
        "AIRPORTNAME": "Falcon Field",
    },
    {
        "CITYNAME": "Mt Pleasant",
        "CITYCODE": "MSD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MSD",
        "AIRPORTNAME": "Mt Pleasant",
    },
    {
        "CITYNAME": "Manston",
        "CITYCODE": "MSE",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "MSE",
        "AIRPORTNAME": "Kent Int'l",
    },
    {
        "CITYNAME": "Masirah",
        "CITYCODE": "MSH",
        "COUNTRYCODE": "OM",
        "COUNTRYNAME": "Oman",
        "AIRPORTCODE": "MSH",
        "AIRPORTNAME": "Masirah",
    },
    {
        "CITYNAME": "Misawa",
        "CITYCODE": "MSJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "MSJ",
        "AIRPORTNAME": "Misawa",
    },
    {
        "CITYNAME": "Muscle Shoals",
        "CITYCODE": "MSL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MSL",
        "AIRPORTNAME": "Muscle Shoals",
    },
    {
        "CITYNAME": "Madison",
        "CITYCODE": "MSN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MSN",
        "AIRPORTNAME": "Dane County Regional",
    },
    {
        "CITYNAME": "Missoula",
        "CITYCODE": "MSO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MSO",
        "AIRPORTNAME": "Johnson Bell Field",
    },
    {
        "CITYNAME": "Minneapolis",
        "CITYCODE": "MSP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MSP",
        "AIRPORTNAME": "St Paul Int'l",
    },
    {
        "CITYNAME": "Minsk",
        "CITYCODE": "MSQ",
        "COUNTRYCODE": "BY",
        "COUNTRYNAME": "Belarus (Belorussia)",
        "AIRPORTCODE": "MSQ",
        "AIRPORTNAME": "Minsk",
    },
    {
        "CITYNAME": "Mus",
        "CITYCODE": "MSR",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "MSR",
        "AIRPORTNAME": "Mus Airport",
    },
    {
        "CITYNAME": "Massena",
        "CITYCODE": "MSS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MSS",
        "AIRPORTNAME": "Richards Field",
    },
    {
        "CITYNAME": "Maastricht",
        "CITYCODE": "MST",
        "COUNTRYCODE": "NL",
        "COUNTRYNAME": "Netherlands",
        "AIRPORTCODE": "MST",
        "AIRPORTNAME": "Maastricht/Aachen",
    },
    {
        "CITYNAME": "Maseru",
        "CITYCODE": "MSU",
        "COUNTRYCODE": "LS",
        "COUNTRYNAME": "Lesotho",
        "AIRPORTCODE": "MSU",
        "AIRPORTNAME": "Moshoeshoe Int'l",
    },
    {
        "CITYNAME": "Monticello",
        "CITYCODE": "MSV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MSV",
        "AIRPORTNAME": "Sullivan County Int'l",
    },
    {
        "CITYNAME": "Massawa",
        "CITYCODE": "MSW",
        "COUNTRYCODE": "ER",
        "COUNTRYNAME": "Eritrea",
        "AIRPORTCODE": "MSW",
        "AIRPORTNAME": "Massawa",
    },
    {
        "CITYNAME": "New Orleans",
        "CITYCODE": "MSY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MSY",
        "AIRPORTNAME": "New Orleans Int'l",
    },
    {
        "CITYNAME": "Namibe",
        "CITYCODE": "MSZ",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "MSZ",
        "AIRPORTNAME": "Namibe",
    },
    {
        "CITYNAME": "Mizan Teferi",
        "CITYCODE": "MTF",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "MTF",
        "AIRPORTNAME": "Mizan Teferi",
    },
    {
        "CITYNAME": "Marathon",
        "CITYCODE": "MTH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MTH",
        "AIRPORTNAME": "Marathon Flight Strip",
    },
    {
        "CITYNAME": "Montrose",
        "CITYCODE": "MTJ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MTJ",
        "AIRPORTNAME": "Montrose County",
    },
    {
        "CITYNAME": "Makin Island",
        "CITYCODE": "MTK",
        "COUNTRYCODE": "KI",
        "COUNTRYNAME": "Kiribati",
        "AIRPORTCODE": "MTK",
        "AIRPORTNAME": "Makin Island",
    },
    {
        "CITYNAME": "Maitland",
        "CITYCODE": "MTL",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MTL",
        "AIRPORTNAME": "Maitland",
    },
    {
        "CITYNAME": "Mattoon",
        "CITYCODE": "MTO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MTO",
        "AIRPORTNAME": "Coles County Memorial",
    },
    {
        "CITYNAME": "Monteria",
        "CITYCODE": "MTR",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "MTR",
        "AIRPORTNAME": "S. Jeronimo",
    },
    {
        "CITYNAME": "Manzini",
        "CITYCODE": "MTS",
        "COUNTRYCODE": "SZ",
        "COUNTRYNAME": "Swaziland",
        "AIRPORTCODE": "MTS",
        "AIRPORTNAME": "Matsapha Int'l",
    },
    {
        "CITYNAME": "Minatitlan",
        "CITYCODE": "MTT",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "MTT",
        "AIRPORTNAME": "Minatitlan",
    },
    {
        "CITYNAME": "Mota Lava",
        "CITYCODE": "MTV",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "MTV",
        "AIRPORTNAME": "Mota Lava",
    },
    {
        "CITYNAME": "Monterrey",
        "CITYCODE": "MTY",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "MTY",
        "AIRPORTNAME": "Gen Mariano Escobedo",
    },
    {
        "CITYNAME": "Masada",
        "CITYCODE": "MTZ",
        "COUNTRYCODE": "IL",
        "COUNTRYNAME": "Israel",
        "AIRPORTCODE": "MTZ",
        "AIRPORTNAME": "Masada",
    },
    {
        "CITYNAME": "Munda",
        "CITYCODE": "MUA",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "MUA",
        "AIRPORTNAME": "Munda",
    },
    {
        "CITYNAME": "Maun",
        "CITYCODE": "MUB",
        "COUNTRYCODE": "BW",
        "COUNTRYNAME": "Botswana",
        "AIRPORTCODE": "MUB",
        "AIRPORTNAME": "Maun",
    },
    {
        "CITYNAME": "Munich",
        "CITYCODE": "MUC",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "AGB",
        "AIRPORTNAME": "Augsburg Muehlhausen",
    },
    {
        "CITYNAME": "Munich",
        "CITYCODE": "MUC",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "MUC",
        "AIRPORTNAME": "Franz Josef Strauss",
    },
    {
        "CITYNAME": "Munich",
        "CITYCODE": "MUC",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "ZMU",
        "AIRPORTNAME": "Munich Airport",
    },
    {
        "CITYNAME": "Mueda",
        "CITYCODE": "MUD",
        "COUNTRYCODE": "MZ",
        "COUNTRYNAME": "Mozambique",
        "AIRPORTCODE": "MUD",
        "AIRPORTNAME": "Mueda Airport",
    },
    {
        "CITYNAME": "Kamuela",
        "CITYCODE": "MUE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MUE",
        "AIRPORTNAME": "Kamuela",
    },
    {
        "CITYNAME": "Muting",
        "CITYCODE": "MUF",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "MUF",
        "AIRPORTNAME": "Muting",
    },
    {
        "CITYNAME": "Mersa Matruh",
        "CITYCODE": "MUH",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "MUH",
        "AIRPORTNAME": "Marsa Matruh Airport",
    },
    {
        "CITYNAME": "Mui",
        "CITYCODE": "MUJ",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "MUJ",
        "AIRPORTNAME": "Mui",
    },
    {
        "CITYNAME": "Mauke Island",
        "CITYCODE": "MUK",
        "COUNTRYCODE": "CK",
        "COUNTRYNAME": "Cook Islands",
        "AIRPORTCODE": "MUK",
        "AIRPORTNAME": "Mauke Island",
    },
    {
        "CITYNAME": "Mumias",
        "CITYCODE": "MUM",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "MUM",
        "AIRPORTNAME": "MUMIAS",
    },
    {
        "CITYNAME": "Maturin",
        "CITYCODE": "MUN",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "MUN",
        "AIRPORTNAME": "Quiriquire",
    },
    {
        "CITYNAME": "Marudi",
        "CITYCODE": "MUR",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "MUR",
        "AIRPORTNAME": "Marudi",
    },
    {
        "CITYNAME": "Multan",
        "CITYCODE": "MUX",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "MUX",
        "AIRPORTNAME": "Multan",
    },
    {
        "CITYNAME": "Mouyondzi",
        "CITYCODE": "MUY",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "MUY",
        "AIRPORTNAME": "Mouyondzi",
    },
    {
        "CITYNAME": "Musoma",
        "CITYCODE": "MUZ",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "MUZ",
        "AIRPORTNAME": "Musoma",
    },
    {
        "CITYNAME": "Franceville",
        "CITYCODE": "MVB",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "MVB",
        "AIRPORTNAME": "Franceville/Mvengue",
    },
    {
        "CITYNAME": "Montevideo",
        "CITYCODE": "MVD",
        "COUNTRYCODE": "UY",
        "COUNTRYNAME": "Uruguay",
        "AIRPORTCODE": "MVD",
        "AIRPORTNAME": "Carrasco",
    },
    {
        "CITYNAME": "Mt Vernon",
        "CITYCODE": "MVN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MVN",
        "AIRPORTNAME": "Mt Vernon",
    },
    {
        "CITYNAME": "Mongo",
        "CITYCODE": "MVO",
        "COUNTRYCODE": "TD",
        "COUNTRYNAME": "Chad",
        "AIRPORTCODE": "MVO",
        "AIRPORTNAME": "Mongo",
    },
    {
        "CITYNAME": "Mitu",
        "CITYCODE": "MVP",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "MVP",
        "AIRPORTNAME": "Mitu",
    },
    {
        "CITYNAME": "Mogilev",
        "CITYCODE": "MVQ",
        "COUNTRYCODE": "BY",
        "COUNTRYNAME": "Belarus (Belorussia)",
        "AIRPORTCODE": "MVQ",
        "AIRPORTNAME": "Mogilev",
    },
    {
        "CITYNAME": "Maroua",
        "CITYCODE": "MVR",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "MVR",
        "AIRPORTNAME": "Salam",
    },
    {
        "CITYNAME": "Mataiva",
        "CITYCODE": "MVT",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "MVT",
        "AIRPORTNAME": "Mataiva",
    },
    {
        "CITYNAME": "Minvoul",
        "CITYCODE": "MVX",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "MVX",
        "AIRPORTNAME": "Minvoul",
    },
    {
        "CITYNAME": "Marthas Vineyard",
        "CITYCODE": "MVY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MVY",
        "AIRPORTNAME": "Marthas Vineyard",
    },
    {
        "CITYNAME": "Masvingo",
        "CITYCODE": "MVZ",
        "COUNTRYCODE": "ZW",
        "COUNTRYNAME": "Zimbabwe",
        "AIRPORTCODE": "MVZ",
        "AIRPORTNAME": "Masvingo",
    },
    {
        "CITYNAME": "Marion",
        "CITYCODE": "MWA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MWA",
        "AIRPORTNAME": "Williamson County",
    },
    {
        "CITYNAME": "Mianwali",
        "CITYCODE": "MWD",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "MWD",
        "AIRPORTNAME": "Mianwali",
    },
    {
        "CITYNAME": "Merowe",
        "CITYCODE": "MWE",
        "COUNTRYCODE": "SD",
        "COUNTRYNAME": "Sudan",
        "AIRPORTCODE": "MWE",
        "AIRPORTNAME": "Merowe",
    },
    {
        "CITYNAME": "Maewo",
        "CITYCODE": "MWF",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "MWF",
        "AIRPORTNAME": "Maewo",
    },
    {
        "CITYNAME": "Moses Lake",
        "CITYCODE": "MWH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MWH",
        "AIRPORTNAME": "Grant County",
    },
    {
        "CITYNAME": "Maramuni",
        "CITYCODE": "MWI",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "MWI",
        "AIRPORTNAME": "Maramuni",
    },
    {
        "CITYNAME": "Muan",
        "CITYCODE": "MWX",
        "COUNTRYCODE": "KR",
        "COUNTRYNAME": "South Korea",
        "AIRPORTCODE": "MWX",
        "AIRPORTNAME": "Muan Intl Airport",
    },
    {
        "CITYNAME": "Mwanza",
        "CITYCODE": "MWZ",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "MWZ",
        "AIRPORTNAME": "Mwanza",
    },
    {
        "CITYNAME": "Minna",
        "CITYCODE": "MXJ",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "MXJ",
        "AIRPORTNAME": "Minna",
    },
    {
        "CITYNAME": "Mexicali",
        "CITYCODE": "MXL",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "MXL",
        "AIRPORTNAME": "Mexicali",
    },
    {
        "CITYNAME": "Morombe",
        "CITYCODE": "MXM",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "MXM",
        "AIRPORTNAME": "Morombe",
    },
    {
        "CITYNAME": "Maota Savaii Island",
        "CITYCODE": "MXS",
        "COUNTRYCODE": "WS",
        "COUNTRYNAME": "Samoa",
        "AIRPORTCODE": "MXS",
        "AIRPORTNAME": "Maota Savaii Island",
    },
    {
        "CITYNAME": "Maintirano",
        "CITYCODE": "MXT",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "MXT",
        "AIRPORTNAME": "Maintirano",
    },
    {
        "CITYNAME": "Mora",
        "CITYCODE": "MXX",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "MXX",
        "AIRPORTNAME": "Mora",
    },
    {
        "CITYNAME": "Meixian",
        "CITYCODE": "MXZ",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "MXZ",
        "AIRPORTNAME": "Meixian",
    },
    {
        "CITYNAME": "Moruya",
        "CITYCODE": "MYA",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MYA",
        "AIRPORTNAME": "Moruya",
    },
    {
        "CITYNAME": "Mayoumba",
        "CITYCODE": "MYB",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "MYB",
        "AIRPORTNAME": "Mayoumba",
    },
    {
        "CITYNAME": "Malindi",
        "CITYCODE": "MYD",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "MYD",
        "AIRPORTNAME": "Malindi",
    },
    {
        "CITYNAME": "Miyake Jima",
        "CITYCODE": "MYE",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "MYE",
        "AIRPORTNAME": "Miyake Jima",
    },
    {
        "CITYNAME": "Mayaguana",
        "CITYCODE": "MYG",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "MYG",
        "AIRPORTNAME": "Mayaguana",
    },
    {
        "CITYNAME": "Murray Island",
        "CITYCODE": "MYI",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "MYI",
        "AIRPORTNAME": "Murray Island",
    },
    {
        "CITYNAME": "Matsuyama",
        "CITYCODE": "MYJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "MYJ",
        "AIRPORTNAME": "Matsuyama",
    },
    {
        "CITYNAME": "Mysore",
        "CITYCODE": "MYQ",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "MYQ",
        "AIRPORTNAME": "Mysore",
    },
    {
        "CITYNAME": "Myrtle Beach",
        "CITYCODE": "MYR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MYR",
        "AIRPORTNAME": "Myrtle AFB",
    },
    {
        "CITYNAME": "Moyale",
        "CITYCODE": "MYS",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "MYS",
        "AIRPORTNAME": "Moyale",
    },
    {
        "CITYNAME": "Myitkyina",
        "CITYCODE": "MYT",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "MYT",
        "AIRPORTNAME": "Myitkyina",
    },
    {
        "CITYNAME": "Mekoryuk",
        "CITYCODE": "MYU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MYU",
        "AIRPORTNAME": "Ellis Field",
    },
    {
        "CITYNAME": "Mtwara",
        "CITYCODE": "MYW",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "MYW",
        "AIRPORTNAME": "Mtwara",
    },
    {
        "CITYNAME": "Miri",
        "CITYCODE": "MYY",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "MYY",
        "AIRPORTNAME": "Miri",
    },
    {
        "CITYNAME": "Monkey Bay",
        "CITYCODE": "MYZ",
        "COUNTRYCODE": "MW",
        "COUNTRYNAME": "Malawi",
        "AIRPORTCODE": "MYZ",
        "AIRPORTNAME": "Monkey Bay",
    },
    {
        "CITYNAME": "Mocimboa Praia",
        "CITYCODE": "MZB",
        "COUNTRYCODE": "MZ",
        "COUNTRYNAME": "Mozambique",
        "AIRPORTCODE": "MZB",
        "AIRPORTNAME": "Mocimboa Praia",
    },
    {
        "CITYNAME": "Mitzic",
        "CITYCODE": "MZC",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "MZC",
        "AIRPORTNAME": "Mitzic",
    },
    {
        "CITYNAME": "Mzamba (Wild Coast)",
        "CITYCODE": "MZF",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "MZF",
        "AIRPORTNAME": "Mzamba (Wild Coas",
    },
    {
        "CITYNAME": "Makung",
        "CITYCODE": "MZG",
        "COUNTRYCODE": "TW",
        "COUNTRYNAME": "Taiwan",
        "AIRPORTCODE": "MZG",
        "AIRPORTNAME": "Makung",
    },
    {
        "CITYNAME": "Merzifon",
        "CITYCODE": "MZH",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "MZH",
        "AIRPORTNAME": "Amasya Merzifon Airport",
    },
    {
        "CITYNAME": "Mopti",
        "CITYCODE": "MZI",
        "COUNTRYCODE": "ML",
        "COUNTRYNAME": "Mali",
        "AIRPORTCODE": "MZI",
        "AIRPORTNAME": "Mopti",
    },
    {
        "CITYNAME": "Marana",
        "CITYCODE": "MZJ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MZJ",
        "AIRPORTNAME": "Pinal Airpark",
    },
    {
        "CITYNAME": "Marakei",
        "CITYCODE": "MZK",
        "COUNTRYCODE": "KI",
        "COUNTRYNAME": "Kiribati",
        "AIRPORTCODE": "MZK",
        "AIRPORTNAME": "Marakei",
    },
    {
        "CITYNAME": "Manizales",
        "CITYCODE": "MZL",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "MZL",
        "AIRPORTNAME": "Manizales",
    },
    {
        "CITYNAME": "Manzanillo",
        "CITYCODE": "MZO",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "MZO",
        "AIRPORTNAME": "Sierra Maestra",
    },
    {
        "CITYNAME": "Motueka",
        "CITYCODE": "MZP",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "MZP",
        "AIRPORTNAME": "Motueka",
    },
    {
        "CITYNAME": "Mazar I Sharif",
        "CITYCODE": "MZR",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "MZR",
        "AIRPORTNAME": "Mazar I Sharif",
    },
    {
        "CITYNAME": "Mazatlan",
        "CITYCODE": "MZT",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "MZT",
        "AIRPORTNAME": "Gen Rafael Buelna",
    },
    {
        "CITYNAME": "Mena",
        "CITYCODE": "MZX",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "MZX",
        "AIRPORTNAME": "Mena",
    },
    {
        "CITYNAME": "Narrabri",
        "CITYCODE": "NAA",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "NAA",
        "AIRPORTNAME": "Narrabri",
    },
    {
        "CITYNAME": "Macanal",
        "CITYCODE": "NAD",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "NAD",
        "AIRPORTNAME": "MACANAL",
    },
    {
        "CITYNAME": "Natitingou",
        "CITYCODE": "NAE",
        "COUNTRYCODE": "BJ",
        "COUNTRYNAME": "Benin",
        "AIRPORTCODE": "NAE",
        "AIRPORTNAME": "Natitingou",
    },
    {
        "CITYNAME": "Nagpur",
        "CITYCODE": "NAG",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "NAG",
        "AIRPORTNAME": "Sonegaon",
    },
    {
        "CITYNAME": "Naha",
        "CITYCODE": "NAH",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "NAH",
        "AIRPORTNAME": "Naha",
    },
    {
        "CITYNAME": "Nakhon Ratchasima",
        "CITYCODE": "NAK",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "NAK",
        "AIRPORTNAME": "Nakhon Ratchasima",
    },
    {
        "CITYNAME": "Nalchik",
        "CITYCODE": "NAL",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "NAL",
        "AIRPORTNAME": "Nalchik",
    },
    {
        "CITYNAME": "Nadi",
        "CITYCODE": "NAN",
        "COUNTRYCODE": "FJ",
        "COUNTRYNAME": "Fiji Islands",
        "AIRPORTCODE": "NAN",
        "AIRPORTNAME": "Nadi Int'l",
    },
    {
        "CITYNAME": "Naples",
        "CITYCODE": "NAP",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "NAP",
        "AIRPORTNAME": "Naples",
    },
    {
        "CITYNAME": "Nare",
        "CITYCODE": "NAR",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "NAR",
        "AIRPORTNAME": "Nare",
    },
    {
        "CITYNAME": "Nassau",
        "CITYCODE": "NAS",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "NAS",
        "AIRPORTNAME": "Nassau Int'l",
    },
    {
        "CITYNAME": "Nassau",
        "CITYCODE": "NAS",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "PID",
        "AIRPORTNAME": "Paradise Island",
    },
    {
        "CITYNAME": "Natal",
        "CITYCODE": "NAT",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "NAT",
        "AIRPORTNAME": "Augusto Severo",
    },
    {
        "CITYNAME": "Napuka Island",
        "CITYCODE": "NAU",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "NAU",
        "AIRPORTNAME": "Napuka Island",
    },
    {
        "CITYNAME": "Nevsehir",
        "CITYCODE": "NAV",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "NAV",
        "AIRPORTNAME": "Nevsehir Kapadokya Airport",
    },
    {
        "CITYNAME": "Narathiwat",
        "CITYCODE": "NAW",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "NAW",
        "AIRPORTNAME": "Narathiwat",
    },
    {
        "CITYNAME": "Barrancominas",
        "CITYCODE": "NBB",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "NBB",
        "AIRPORTNAME": "Barrancominas",
    },
    {
        "CITYNAME": "Naberevnye Chelny",
        "CITYCODE": "NBC",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "NBC",
        "AIRPORTNAME": "Naberevnye Chelny",
    },
    {
        "CITYNAME": "Enfidha",
        "CITYCODE": "NBE",
        "COUNTRYCODE": "TN",
        "COUNTRYNAME": "Tunisia",
        "AIRPORTCODE": "NBE",
        "AIRPORTNAME": "Hammamet",
    },
    {
        "CITYNAME": "Nairobi",
        "CITYCODE": "NBO",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "NBO",
        "AIRPORTNAME": "Jomo Kenyatta Int'l",
    },
    {
        "CITYNAME": "Nairobi",
        "CITYCODE": "NBO",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "WIL",
        "AIRPORTNAME": "Wilson",
    },
    {
        "CITYNAME": "Nabire",
        "CITYCODE": "NBX",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "NBX",
        "AIRPORTNAME": "Nabire",
    },
    {
        "CITYNAME": "North Caicos",
        "CITYCODE": "NCA",
        "COUNTRYCODE": "TC",
        "COUNTRYNAME": "Turcs & Caicos Islands",
        "AIRPORTCODE": "NCA",
        "AIRPORTNAME": "North Caicos",
    },
    {
        "CITYNAME": "Nice",
        "CITYCODE": "NCE",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "NCE",
        "AIRPORTNAME": "Cote D'Azur",
    },
    {
        "CITYNAME": "Nachingwea",
        "CITYCODE": "NCH",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "NCH",
        "AIRPORTNAME": "Nachingwea",
    },
    {
        "CITYNAME": "Necocli",
        "CITYCODE": "NCI",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "NCI",
        "AIRPORTNAME": "Necocli",
    },
    {
        "CITYNAME": "Newcastle",
        "CITYCODE": "NCL",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "NCL",
        "AIRPORTNAME": "Newcastle Int'l",
    },
    {
        "CITYNAME": "Luzon",
        "CITYCODE": "NCP",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "NCP",
        "AIRPORTNAME": "Cubi Pt NAS Airport",
    },
    {
        "CITYNAME": "Newcastle",
        "CITYCODE": "NCS",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "NCS",
        "AIRPORTNAME": "Newcastle",
    },
    {
        "CITYNAME": "Nukus",
        "CITYCODE": "NCU",
        "COUNTRYCODE": "UZ",
        "COUNTRYNAME": "Uzbekistan",
        "AIRPORTCODE": "NCU",
        "AIRPORTNAME": "Nukus",
    },
    {
        "CITYNAME": "Annecy",
        "CITYCODE": "NCY",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "NCY",
        "AIRPORTNAME": "Annecy-Meythe",
    },
    {
        "CITYNAME": "Nouadhibou",
        "CITYCODE": "NDB",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "NDB",
        "AIRPORTNAME": "Nouadhibou",
    },
    {
        "CITYNAME": "Nanded",
        "CITYCODE": "NDC",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "NDC",
        "AIRPORTNAME": "Nanded",
    },
    {
        "CITYNAME": "Mandera",
        "CITYCODE": "NDE",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "NDE",
        "AIRPORTNAME": "Mandera",
    },
    {
        "CITYNAME": "Qiqihar",
        "CITYCODE": "NDG",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "NDG",
        "AIRPORTNAME": "Qiqihar Sanjiazi Airport",
    },
    {
        "CITYNAME": "Ndjamena",
        "CITYCODE": "NDJ",
        "COUNTRYCODE": "TD",
        "COUNTRYNAME": "Chad",
        "AIRPORTCODE": "NDJ",
        "AIRPORTNAME": "Ndjamena",
    },
    {
        "CITYNAME": "Namdrik Island",
        "CITYCODE": "NDK",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "NDK",
        "AIRPORTNAME": "Namdrik Island",
    },
    {
        "CITYNAME": "Ndele",
        "CITYCODE": "NDL",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "NDL",
        "AIRPORTNAME": "Ndele",
    },
    {
        "CITYNAME": "Mendi",
        "CITYCODE": "NDM",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "NDM",
        "AIRPORTNAME": "Mendi",
    },
    {
        "CITYNAME": "Nador",
        "CITYCODE": "NDR",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "NDR",
        "AIRPORTNAME": "El Aroui",
    },
    {
        "CITYNAME": "Rundu",
        "CITYCODE": "NDU",
        "COUNTRYCODE": "NA",
        "COUNTRYNAME": "Namibia",
        "AIRPORTCODE": "NDU",
        "AIRPORTNAME": "Rundu",
    },
    {
        "CITYNAME": "Sanday",
        "CITYCODE": "NDY",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "NDY",
        "AIRPORTNAME": "Sanday",
    },
    {
        "CITYNAME": "Negril",
        "CITYCODE": "NEG",
        "COUNTRYCODE": "JM",
        "COUNTRYNAME": "Jamaica",
        "AIRPORTCODE": "NEG",
        "AIRPORTNAME": "Negril",
    },
    {
        "CITYNAME": "Nekemt",
        "CITYCODE": "NEK",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "NEK",
        "AIRPORTNAME": "Nekemt",
    },
    {
        "CITYNAME": "Neryungri",
        "CITYCODE": "NER",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "NER",
        "AIRPORTNAME": "Neryungri",
    },
    {
        "CITYNAME": "Nevis",
        "CITYCODE": "NEV",
        "COUNTRYCODE": "KN",
        "COUNTRYNAME": "St. Kitts and Nevis",
        "AIRPORTCODE": "NEV",
        "AIRPORTNAME": "Newcastle",
    },
    {
        "CITYNAME": "Niuafo'ou",
        "CITYCODE": "NFO",
        "COUNTRYCODE": "TO",
        "COUNTRYNAME": "Tonga",
        "AIRPORTCODE": "NFO",
        "AIRPORTNAME": "Mataâaho",
    },
    {
        "CITYNAME": "Ningbo",
        "CITYCODE": "NGB",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "NGB",
        "AIRPORTNAME": "Ningbo",
    },
    {
        "CITYNAME": "Anegada",
        "CITYCODE": "NGD",
        "COUNTRYCODE": "VG",
        "COUNTRYNAME": "British Virgin Islands",
        "AIRPORTCODE": "NGD",
        "AIRPORTNAME": "Anegada",
    },
    {
        "CITYNAME": "Ngaoundere",
        "CITYCODE": "NGE",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "NGE",
        "AIRPORTNAME": "Ngaoundere",
    },
    {
        "CITYNAME": "Nagoya",
        "CITYCODE": "NGO",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "NGO",
        "AIRPORTNAME": "Komaki AFB",
    },
    {
        "CITYNAME": "Nagoya",
        "CITYCODE": "NGO",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "NGY",
        "AIRPORTNAME": "Nagoya Bus Station Airport",
    },
    {
        "CITYNAME": "Nagasaki",
        "CITYCODE": "NGS",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "NGS",
        "AIRPORTNAME": "Nagasaki",
    },
    {
        "CITYNAME": "Ngiva",
        "CITYCODE": "NGV",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "NGV",
        "AIRPORTNAME": "Ngiva",
    },
    {
        "CITYNAME": "Nuku Hiva",
        "CITYCODE": "NHV",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "NHV",
        "AIRPORTNAME": "Nuku Hiva",
    },
    {
        "CITYNAME": "Nimba",
        "CITYCODE": "NIA",
        "COUNTRYCODE": "LR",
        "COUNTRYNAME": "Liberia",
        "AIRPORTCODE": "NIA",
        "AIRPORTNAME": "Nimba",
    },
    {
        "CITYNAME": "Nikolai",
        "CITYCODE": "NIB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "NIB",
        "AIRPORTNAME": "Nikolai",
    },
    {
        "CITYNAME": "Nikunau",
        "CITYCODE": "NIG",
        "COUNTRYCODE": "KI",
        "COUNTRYNAME": "Kiribati",
        "AIRPORTCODE": "NIG",
        "AIRPORTNAME": "Nikunau",
    },
    {
        "CITYNAME": "Niamey",
        "CITYCODE": "NIM",
        "COUNTRYCODE": "NE",
        "COUNTRYNAME": "Niger",
        "AIRPORTCODE": "NIM",
        "AIRPORTNAME": "Niamey",
    },
    {
        "CITYNAME": "Nioro",
        "CITYCODE": "NIX",
        "COUNTRYCODE": "ML",
        "COUNTRYNAME": "Mali",
        "AIRPORTCODE": "NIX",
        "AIRPORTNAME": "Nioro",
    },
    {
        "CITYNAME": "Nizhnevartovsk",
        "CITYCODE": "NJC",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "NJC",
        "AIRPORTNAME": "Nizhnevartovsk Airport",
    },
    {
        "CITYNAME": "Najaf",
        "CITYCODE": "NJF",
        "COUNTRYCODE": "IQ",
        "COUNTRYNAME": "Iraq",
        "AIRPORTCODE": "NJF",
        "AIRPORTNAME": "Najaf",
    },
    {
        "CITYNAME": "Nouakchott",
        "CITYCODE": "NKC",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "NKC",
        "AIRPORTNAME": "Nouakchott",
    },
    {
        "CITYNAME": "Nanjing",
        "CITYCODE": "NKG",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "NKG",
        "AIRPORTNAME": "Nanjing",
    },
    {
        "CITYNAME": "Naukiti",
        "CITYCODE": "NKI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "NKI",
        "AIRPORTNAME": "Naukiti",
    },
    {
        "CITYNAME": "Sirnak",
        "CITYCODE": "NKT",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "NKT",
        "AIRPORTNAME": "Sirnak Airport",
    },
    {
        "CITYNAME": "Nkaus",
        "CITYCODE": "NKU",
        "COUNTRYCODE": "LS",
        "COUNTRYNAME": "Lesotho",
        "AIRPORTCODE": "NKU",
        "AIRPORTNAME": "Nkaus",
    },
    {
        "CITYNAME": "Nkayi",
        "CITYCODE": "NKY",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "NKY",
        "AIRPORTNAME": "Nkayi",
    },
    {
        "CITYNAME": "Ndola",
        "CITYCODE": "NLA",
        "COUNTRYCODE": "ZM",
        "COUNTRYNAME": "Zambia",
        "AIRPORTCODE": "NLA",
        "AIRPORTNAME": "Ndola",
    },
    {
        "CITYNAME": "Nuevo Laredo",
        "CITYCODE": "NLD",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "NLD",
        "AIRPORTNAME": "Nuevo Laredo Intl",
    },
    {
        "CITYNAME": "Darnley Island",
        "CITYCODE": "NLF",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "NLF",
        "AIRPORTNAME": "Darnley Island",
    },
    {
        "CITYNAME": "Nelson Lagoon",
        "CITYCODE": "NLG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "NLG",
        "AIRPORTNAME": "Nelson Lagoon",
    },
    {
        "CITYNAME": "Norfolk Island",
        "CITYCODE": "NLK",
        "COUNTRYCODE": "NF",
        "COUNTRYNAME": "Norfolk Islands",
        "AIRPORTCODE": "NLK",
        "AIRPORTNAME": "Norfolk Island",
    },
    {
        "CITYNAME": "Nullagine",
        "CITYCODE": "NLL",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "NLL",
        "AIRPORTNAME": "Nullagine",
    },
    {
        "CITYNAME": "Nelspruit",
        "CITYCODE": "NLP",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "NLP",
        "AIRPORTNAME": "Nelspruit",
    },
    {
        "CITYNAME": "Namangan",
        "CITYCODE": "NMA",
        "COUNTRYCODE": "UZ",
        "COUNTRYNAME": "Uzbekistan",
        "AIRPORTCODE": "NMA",
        "AIRPORTNAME": "Namangan Airport",
    },
    {
        "CITYNAME": "Daman",
        "CITYCODE": "NMB",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "NMB",
        "AIRPORTNAME": "Daman",
    },
    {
        "CITYNAME": "Nightmute",
        "CITYCODE": "NME",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "NME",
        "AIRPORTNAME": "Nightmute",
    },
    {
        "CITYNAME": "San Miguel",
        "CITYCODE": "NMG",
        "COUNTRYCODE": "PA",
        "COUNTRYNAME": "Panama",
        "AIRPORTCODE": "NMG",
        "AIRPORTNAME": "San Miguel",
    },
    {
        "CITYNAME": "Santa Ana",
        "CITYCODE": "NNB",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "NNB",
        "AIRPORTNAME": "Santa Ana",
    },
    {
        "CITYNAME": "Nanning",
        "CITYCODE": "NNG",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "NNG",
        "AIRPORTNAME": "Nanning Airport",
    },
    {
        "CITYNAME": "Namutoni",
        "CITYCODE": "NNI",
        "COUNTRYCODE": "NA",
        "COUNTRYNAME": "Namibia",
        "AIRPORTCODE": "NNI",
        "AIRPORTNAME": "Namutoni",
    },
    {
        "CITYNAME": "Naknek",
        "CITYCODE": "NNK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "NNK",
        "AIRPORTNAME": "Naknek",
    },
    {
        "CITYNAME": "Nondalton",
        "CITYCODE": "NNL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "NNL",
        "AIRPORTNAME": "Nondalton",
    },
    {
        "CITYNAME": "Naryan Mar",
        "CITYCODE": "NNM",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "NNM",
        "AIRPORTNAME": "Naryan - Mar Airport",
    },
    {
        "CITYNAME": "Spiddal",
        "CITYCODE": "NNR",
        "COUNTRYCODE": "IE",
        "COUNTRYNAME": "Ireland",
        "AIRPORTCODE": "NNR",
        "AIRPORTNAME": "Connemara Airport",
    },
    {
        "CITYNAME": "Nan",
        "CITYCODE": "NNT",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "NNT",
        "AIRPORTNAME": "Nan",
    },
    {
        "CITYNAME": "Nanyang",
        "CITYCODE": "NNY",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "NNY",
        "AIRPORTNAME": "Nanyang Jiangying Airport",
    },
    {
        "CITYNAME": "Nowra",
        "CITYCODE": "NOA",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "NOA",
        "AIRPORTNAME": "Nowra",
    },
    {
        "CITYNAME": "Nosara Beach",
        "CITYCODE": "NOB",
        "COUNTRYCODE": "CR",
        "COUNTRYNAME": "Costa Rica",
        "AIRPORTCODE": "NOB",
        "AIRPORTNAME": "Nosara Beach",
    },
    {
        "CITYNAME": "Knock",
        "CITYCODE": "NOC",
        "COUNTRYCODE": "IE",
        "COUNTRYNAME": "Ireland",
        "AIRPORTCODE": "NOC",
        "AIRPORTNAME": "Knock Int'l",
    },
    {
        "CITYNAME": "Nojabrxsk",
        "CITYCODE": "NOJ",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "NOJ",
        "AIRPORTNAME": "Noyabrsk",
    },
    {
        "CITYNAME": "Nomad River",
        "CITYCODE": "NOM",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "NOM",
        "AIRPORTNAME": "Nomad River",
    },
    {
        "CITYNAME": "Nonouti",
        "CITYCODE": "NON",
        "COUNTRYCODE": "KI",
        "COUNTRYNAME": "Kiribati",
        "AIRPORTCODE": "NON",
        "AIRPORTNAME": "Nonouti",
    },
    {
        "CITYNAME": "Mactan Island",
        "CITYCODE": "NOP",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "NOP",
        "AIRPORTNAME": "Sinop Airport",
    },
    {
        "CITYNAME": "Nordfjordur",
        "CITYCODE": "NOR",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "NOR",
        "AIRPORTNAME": "Nordfjordur",
    },
    {
        "CITYNAME": "Nossi Be",
        "CITYCODE": "NOS",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "NOS",
        "AIRPORTNAME": "Fascene",
    },
    {
        "CITYNAME": "Noumea",
        "CITYCODE": "NOU",
        "COUNTRYCODE": "NC",
        "COUNTRYNAME": "New Caledonia",
        "AIRPORTCODE": "GEA",
        "AIRPORTNAME": "Magenta",
    },
    {
        "CITYNAME": "Noumea",
        "CITYCODE": "NOU",
        "COUNTRYCODE": "NC",
        "COUNTRYNAME": "New Caledonia",
        "AIRPORTCODE": "NOU",
        "AIRPORTNAME": "Tontouta",
    },
    {
        "CITYNAME": "Huambo",
        "CITYCODE": "NOV",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "NOV",
        "AIRPORTNAME": "Huambo",
    },
    {
        "CITYNAME": "Novokuznetsk",
        "CITYCODE": "NOZ",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "NOZ",
        "AIRPORTNAME": "Novokuznetsk",
    },
    {
        "CITYNAME": "Napier",
        "CITYCODE": "NPE",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "NPE",
        "AIRPORTNAME": "Hawkes Bay",
    },
    {
        "CITYNAME": "Nephi",
        "CITYCODE": "NPH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "NPH",
        "AIRPORTNAME": "Nephi",
    },
    {
        "CITYNAME": "New Plymouth",
        "CITYCODE": "NPL",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "NPL",
        "AIRPORTNAME": "New Plymouth",
    },
    {
        "CITYNAME": "Neuquen",
        "CITYCODE": "NQN",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "NQN",
        "AIRPORTNAME": "Neuquen",
    },
    {
        "CITYNAME": "Nottingham",
        "CITYCODE": "NQT",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "EMA",
        "AIRPORTNAME": "East Midlands",
    },
    {
        "CITYNAME": "Nottingham",
        "CITYCODE": "NQT",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "NQT",
        "AIRPORTNAME": "Nottingham Airport",
    },
    {
        "CITYNAME": "Nottingham",
        "CITYCODE": "NQT",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "ZGB",
        "AIRPORTNAME": "Nottingham Bus Station Airport",
    },
    {
        "CITYNAME": "Nuqui",
        "CITYCODE": "NQU",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "NQU",
        "AIRPORTNAME": "Nuqui",
    },
    {
        "CITYNAME": "Newquay",
        "CITYCODE": "NQY",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "NQY",
        "AIRPORTNAME": "Newquay",
    },
    {
        "CITYNAME": "Narrandera",
        "CITYCODE": "NRA",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "NRA",
        "AIRPORTNAME": "Narrandera",
    },
    {
        "CITYNAME": "Norderney",
        "CITYCODE": "NRD",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "NRD",
        "AIRPORTNAME": "Norderney",
    },
    {
        "CITYNAME": "Norrkoping",
        "CITYCODE": "NRK",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "NRK",
        "AIRPORTNAME": "Kungsangen",
    },
    {
        "CITYNAME": "North Ronaldsay",
        "CITYCODE": "NRL",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "NRL",
        "AIRPORTNAME": "North Ronaldsay",
    },
    {
        "CITYNAME": "Nara",
        "CITYCODE": "NRM",
        "COUNTRYCODE": "ML",
        "COUNTRYNAME": "Mali",
        "AIRPORTCODE": "NRM",
        "AIRPORTNAME": "Nara",
    },
    {
        "CITYNAME": "Noosa",
        "CITYCODE": "NSA",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "NSA",
        "AIRPORTNAME": "Noosa",
    },
    {
        "CITYNAME": "Norilsk",
        "CITYCODE": "NSK",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "NSK",
        "AIRPORTNAME": "Norilsk",
    },
    {
        "CITYNAME": "Norseman",
        "CITYCODE": "NSM",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "NSM",
        "AIRPORTNAME": "Norseman",
    },
    {
        "CITYNAME": "Nelson",
        "CITYCODE": "NSN",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "NSN",
        "AIRPORTNAME": "Nelson",
    },
    {
        "CITYNAME": "Scone",
        "CITYCODE": "NSO",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "NSO",
        "AIRPORTNAME": "Scone",
    },
    {
        "CITYNAME": "Nakon Si Thammarat",
        "CITYCODE": "NST",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "NST",
        "AIRPORTNAME": "Nakon Si Thammarat",
    },
    {
        "CITYNAME": "Sigonella",
        "CITYCODE": "NSY",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "NSY",
        "AIRPORTNAME": "Sigonella",
    },
    {
        "CITYNAME": "Nansha",
        "CITYCODE": "NSZ",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "NSZ",
        "AIRPORTNAME": "\"Nansha Ferry Port Airport",
    },
    {
        "CITYNAME": "Nantes",
        "CITYCODE": "NTE",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "NTE",
        "AIRPORTNAME": "Nantes Atlantique",
    },
    {
        "CITYNAME": "Nantes",
        "CITYCODE": "NTE",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "QJZ",
        "AIRPORTNAME": "Nantes Railway Station Airport",
    },
    {
        "CITYNAME": "Nantong",
        "CITYCODE": "NTG",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "NTG",
        "AIRPORTNAME": "Nantong Xingdong Airport",
    },
    {
        "CITYNAME": "Bintuni",
        "CITYCODE": "NTI",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "NTI",
        "AIRPORTNAME": "Bintuni",
    },
    {
        "CITYNAME": "Newcastle",
        "CITYCODE": "NTL",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "BEO",
        "AIRPORTNAME": "Belmont",
    },
    {
        "CITYNAME": "Newcastle",
        "CITYCODE": "NTL",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "NTL",
        "AIRPORTNAME": "Williamtown",
    },
    {
        "CITYNAME": "Miracema Do Norte",
        "CITYCODE": "NTM",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "NTM",
        "AIRPORTNAME": "Miracema Do Norte",
    },
    {
        "CITYNAME": "Normanton",
        "CITYCODE": "NTN",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "NTN",
        "AIRPORTNAME": "Normanton",
    },
    {
        "CITYNAME": "Niuatoputapu",
        "CITYCODE": "NTT",
        "COUNTRYCODE": "TO",
        "COUNTRYNAME": "Tonga",
        "AIRPORTCODE": "NTT",
        "AIRPORTNAME": "Kuini Lavenia",
    },
    {
        "CITYNAME": "Natuna Ranai",
        "CITYCODE": "NTX",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "NTX",
        "AIRPORTNAME": "Natuna Ranai",
    },
    {
        "CITYNAME": "Sun City",
        "CITYCODE": "NTY",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "NTY",
        "AIRPORTNAME": "Pilansberg",
    },
    {
        "CITYNAME": "Numbulwar",
        "CITYCODE": "NUB",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "NUB",
        "AIRPORTNAME": "Numbulwar",
    },
    {
        "CITYNAME": "Nuremberg",
        "CITYCODE": "NUE",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "NUE",
        "AIRPORTNAME": "Nuremberg",
    },
    {
        "CITYNAME": "Nuiqsut",
        "CITYCODE": "NUI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "NUI",
        "AIRPORTNAME": "Nuiqsut",
    },
    {
        "CITYNAME": "Nukutavake",
        "CITYCODE": "NUK",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "NUK",
        "AIRPORTNAME": "Nukutavake",
    },
    {
        "CITYNAME": "Nulato",
        "CITYCODE": "NUL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "NUL",
        "AIRPORTNAME": "Nulato",
    },
    {
        "CITYNAME": "Neom",
        "CITYCODE": "NUM",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "NUM",
        "AIRPORTNAME": "Neom Airport",
    },
    {
        "CITYNAME": "Nunapitchuk",
        "CITYCODE": "NUP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "NUP",
        "AIRPORTNAME": "Nunapitchuk",
    },
    {
        "CITYNAME": "Norsup",
        "CITYCODE": "NUS",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "NUS",
        "AIRPORTNAME": "Norsup",
    },
    {
        "CITYNAME": "Nakuru",
        "CITYCODE": "NUU",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "NUU",
        "AIRPORTNAME": "Nakuru",
    },
    {
        "CITYNAME": "Novy Urengov",
        "CITYCODE": "NUX",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "NUX",
        "AIRPORTNAME": "Novy Urengoy Airport",
    },
    {
        "CITYNAME": "Neiva",
        "CITYCODE": "NVA",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "NVA",
        "AIRPORTNAME": "La Marguita",
    },
    {
        "CITYNAME": "Navoi",
        "CITYCODE": "NVI",
        "COUNTRYCODE": "UZ",
        "COUNTRYNAME": "Uzbekistan",
        "AIRPORTCODE": "NVI",
        "AIRPORTNAME": "Navoi Intl Airport",
    },
    {
        "CITYNAME": "Narvik",
        "CITYCODE": "NVK",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "NVK",
        "AIRPORTNAME": "Framnes",
    },
    {
        "CITYNAME": "Nevers",
        "CITYCODE": "NVS",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "NVS",
        "AIRPORTNAME": "FOURCHAMBAULT",
    },
    {
        "CITYNAME": "Navegantes",
        "CITYCODE": "NVT",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "NVT",
        "AIRPORTNAME": "Navegantes",
    },
    {
        "CITYNAME": "Neyveli",
        "CITYCODE": "NVY",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "NVY",
        "AIRPORTNAME": "Neyveli",
    },
    {
        "CITYNAME": "Moheli",
        "CITYCODE": "NWA",
        "COUNTRYCODE": "KM",
        "COUNTRYNAME": "Comoros",
        "AIRPORTCODE": "NWA",
        "AIRPORTNAME": "Moheli",
    },
    {
        "CITYNAME": "Norwich",
        "CITYCODE": "NWI",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "NWI",
        "AIRPORTNAME": "Norwich",
    },
    {
        "CITYNAME": "Nowata",
        "CITYCODE": "NWT",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "NWT",
        "AIRPORTNAME": "Nowata",
    },
    {
        "CITYNAME": "New York",
        "CITYCODE": "NYC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "EWR",
        "AIRPORTNAME": "Newark Int'l",
    },
    {
        "CITYNAME": "New York",
        "CITYCODE": "NYC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "JFK",
        "AIRPORTNAME": "John F Kennedy Int'l",
    },
    {
        "CITYNAME": "New York",
        "CITYCODE": "NYC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "JRB",
        "AIRPORTNAME": "Downtown Manhattan H/p",
    },
    {
        "CITYNAME": "New York",
        "CITYCODE": "NYC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "LGA",
        "AIRPORTNAME": "La Guardia",
    },
    {
        "CITYNAME": "Nyeri",
        "CITYCODE": "NYE",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "NYE",
        "AIRPORTNAME": "Nyeri",
    },
    {
        "CITYNAME": "Sunyani",
        "CITYCODE": "NYI",
        "COUNTRYCODE": "GH",
        "COUNTRYNAME": "Ghana",
        "AIRPORTCODE": "NYI",
        "AIRPORTNAME": "Sunyani",
    },
    {
        "CITYNAME": "Nanyuki",
        "CITYCODE": "NYK",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "NYK",
        "AIRPORTNAME": "Nanyuki",
    },
    {
        "CITYNAME": "Nadym",
        "CITYCODE": "NYM",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "NYM",
        "AIRPORTNAME": "Nadym Airport",
    },
    {
        "CITYNAME": "Naypyidaw",
        "CITYCODE": "NYT",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "NYT",
        "AIRPORTNAME": "Naypyidaw Intl Airport",
    },
    {
        "CITYNAME": "Nyaung U",
        "CITYCODE": "NYU",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "NYU",
        "AIRPORTNAME": "Nyaung U",
    },
    {
        "CITYNAME": "Nzerekore",
        "CITYCODE": "NZE",
        "COUNTRYCODE": "GN",
        "COUNTRYNAME": "Guinea",
        "AIRPORTCODE": "NZE",
        "AIRPORTNAME": "Nzerekore",
    },
    {
        "CITYNAME": "Orange Cudal",
        "CITYCODE": "OAG",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "OAG",
        "AIRPORTNAME": "Orange Springhill Spr",
    },
    {
        "CITYNAME": "Jacksonville",
        "CITYCODE": "OAJ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OAJ",
        "AIRPORTNAME": "Albert J Ellis",
    },
    {
        "CITYNAME": "Oakland",
        "CITYCODE": "OAK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OAK",
        "AIRPORTNAME": "Oakland Int'l",
    },
    {
        "CITYNAME": "Oamaru",
        "CITYCODE": "OAM",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "OAM",
        "AIRPORTNAME": "Oamaru",
    },
    {
        "CITYNAME": "Oaxaca",
        "CITYCODE": "OAX",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "OAX",
        "AIRPORTNAME": "Xoxocotlan",
    },
    {
        "CITYNAME": "Obock",
        "CITYCODE": "OBC",
        "COUNTRYCODE": "DJ",
        "COUNTRYNAME": "Djibouti",
        "AIRPORTCODE": "OBC",
        "AIRPORTNAME": "Obock",
    },
    {
        "CITYNAME": "Obano",
        "CITYCODE": "OBD",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "OBD",
        "AIRPORTNAME": "Obano",
    },
    {
        "CITYNAME": "Oberpfaffenhofen",
        "CITYCODE": "OBF",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "OBF",
        "AIRPORTNAME": "Oberpfaffenhofen",
    },
    {
        "CITYNAME": "Morobe",
        "CITYCODE": "OBM",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "OBM",
        "AIRPORTNAME": "Morobe",
    },
    {
        "CITYNAME": "Obihiro",
        "CITYCODE": "OBO",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "OBO",
        "AIRPORTNAME": "Obihiro",
    },
    {
        "CITYNAME": "Kobuk",
        "CITYCODE": "OBU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OBU",
        "AIRPORTNAME": "Kobuk/Wien",
    },
    {
        "CITYNAME": "Obo",
        "CITYCODE": "OBX",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "OBX",
        "AIRPORTNAME": "Obo",
    },
    {
        "CITYNAME": "Ocean Reef",
        "CITYCODE": "OCA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OCA",
        "AIRPORTNAME": "Ocean Reef",
    },
    {
        "CITYNAME": "Coca",
        "CITYCODE": "OCC",
        "COUNTRYCODE": "EC",
        "COUNTRYNAME": "Ecuador",
        "AIRPORTCODE": "OCC",
        "AIRPORTNAME": "Coca",
    },
    {
        "CITYNAME": "Lufkin",
        "CITYCODE": "OCH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OCH",
        "AIRPORTNAME": "Nacogdoches",
    },
    {
        "CITYNAME": "Ocho Rios",
        "CITYCODE": "OCJ",
        "COUNTRYCODE": "JM",
        "COUNTRYNAME": "Jamaica",
        "AIRPORTCODE": "OCJ",
        "AIRPORTNAME": "Boscobel",
    },
    {
        "CITYNAME": "Ocana",
        "CITYCODE": "OCV",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "OCV",
        "AIRPORTNAME": "Ocana",
    },
    {
        "CITYNAME": "Ouadda",
        "CITYCODE": "ODA",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "ODA",
        "AIRPORTNAME": "Ouadda",
    },
    {
        "CITYNAME": "Odense",
        "CITYCODE": "ODE",
        "COUNTRYCODE": "DK",
        "COUNTRYNAME": "Denmark",
        "AIRPORTCODE": "ODE",
        "AIRPORTNAME": "Beldringe",
    },
    {
        "CITYNAME": "Ouanda Djalle",
        "CITYCODE": "ODJ",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "ODJ",
        "AIRPORTNAME": "Ouanda Djalle",
    },
    {
        "CITYNAME": "Long Seridan",
        "CITYCODE": "ODN",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "ODN",
        "AIRPORTNAME": "Long Seridan",
    },
    {
        "CITYNAME": "Ord River",
        "CITYCODE": "ODR",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "ODR",
        "AIRPORTNAME": "ORD RIVER",
    },
    {
        "CITYNAME": "Odessa",
        "CITYCODE": "ODS",
        "COUNTRYCODE": "UA",
        "COUNTRYNAME": "Ukraine",
        "AIRPORTCODE": "ODS",
        "AIRPORTNAME": "Odessa Central",
    },
    {
        "CITYNAME": "Oak Harbor",
        "CITYCODE": "ODW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ODW",
        "AIRPORTNAME": "Oak Harbor",
    },
    {
        "CITYNAME": "Oudomxay",
        "CITYCODE": "ODY",
        "COUNTRYCODE": "LA",
        "COUNTRYNAME": "Lao People's Democratic Republic",
        "AIRPORTCODE": "ODY",
        "AIRPORTNAME": "Oudomxay",
    },
    {
        "CITYNAME": "Ocussi",
        "CITYCODE": "OEC",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "OEC",
        "AIRPORTNAME": "Ocussi",
    },
    {
        "CITYNAME": "Ornskoldsvik",
        "CITYCODE": "OER",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "OER",
        "AIRPORTNAME": "Ornskoldsvik",
    },
    {
        "CITYNAME": "San Antonio Oeste",
        "CITYCODE": "OES",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "OES",
        "AIRPORTNAME": "San Antonio Oeste",
    },
    {
        "CITYNAME": "Ouango Fitini",
        "CITYCODE": "OFI",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "OFI",
        "AIRPORTNAME": "Ouango Fitini",
    },
    {
        "CITYNAME": "Olafsfjordur",
        "CITYCODE": "OFJ",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "OFJ",
        "AIRPORTNAME": "Olafsfjordur",
    },
    {
        "CITYNAME": "Norfolk",
        "CITYCODE": "OFK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OFK",
        "AIRPORTNAME": "Stefan Field",
    },
    {
        "CITYNAME": "Ofu",
        "CITYCODE": "OFU",
        "COUNTRYCODE": "AS",
        "COUNTRYNAME": "American Samoa",
        "AIRPORTCODE": "OFU",
        "AIRPORTNAME": "Ofu",
    },
    {
        "CITYNAME": "Kahului",
        "CITYCODE": "OGG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OGG",
        "AIRPORTNAME": "Kahului",
    },
    {
        "CITYNAME": "Ogle",
        "CITYCODE": "OGL",
        "COUNTRYCODE": "GY",
        "COUNTRYNAME": "Guyana",
        "AIRPORTCODE": "OGL",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Yonaguni Jima",
        "CITYCODE": "OGN",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "OGN",
        "AIRPORTNAME": "Yonaguni Jima",
    },
    {
        "CITYNAME": "Abengourou",
        "CITYCODE": "OGO",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "OGO",
        "AIRPORTNAME": "Abengourou",
    },
    {
        "CITYNAME": "Bongor",
        "CITYCODE": "OGR",
        "COUNTRYCODE": "TD",
        "COUNTRYNAME": "Chad",
        "AIRPORTCODE": "OGR",
        "AIRPORTNAME": "Bongor",
    },
    {
        "CITYNAME": "Ogdensburg",
        "CITYCODE": "OGS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OGS",
        "AIRPORTNAME": "Ogdensburg",
    },
    {
        "CITYNAME": "Ordu",
        "CITYCODE": "OGU",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "OGU",
        "AIRPORTNAME": "Ordu Giresun Airport",
    },
    {
        "CITYNAME": "Ouargla",
        "CITYCODE": "OGX",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "OGX",
        "AIRPORTNAME": "Ain Beida",
    },
    {
        "CITYNAME": "Vladikavkaz",
        "CITYCODE": "OGZ",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "OGZ",
        "AIRPORTNAME": "Vladikavkaz",
    },
    {
        "CITYNAME": "Ohrid",
        "CITYCODE": "OHD",
        "COUNTRYCODE": "MK",
        "COUNTRYNAME": "Macedonia",
        "AIRPORTCODE": "OHD",
        "AIRPORTNAME": "Ohrid",
    },
    {
        "CITYNAME": "Oshakati",
        "CITYCODE": "OHI",
        "COUNTRYCODE": "NA",
        "COUNTRYNAME": "Namibia",
        "AIRPORTCODE": "OHI",
        "AIRPORTNAME": "Oshakati",
    },
    {
        "CITYNAME": "Wyk Auf Foehr",
        "CITYCODE": "OHR",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "OHR",
        "AIRPORTNAME": "Wyk Auf Foehr",
    },
    {
        "CITYNAME": "Sohar",
        "CITYCODE": "OHS",
        "COUNTRYCODE": "OM",
        "COUNTRYNAME": "Oman",
        "AIRPORTCODE": "OHS",
        "AIRPORTNAME": "Sohar",
    },
    {
        "CITYNAME": "Kohat",
        "CITYCODE": "OHT",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "OHT",
        "AIRPORTNAME": "Kohat",
    },
    {
        "CITYNAME": "Oshima",
        "CITYCODE": "OIM",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "OIM",
        "AIRPORTNAME": "Oshima",
    },
    {
        "CITYNAME": "Okushiri",
        "CITYCODE": "OIR",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "OIR",
        "AIRPORTNAME": "Okushiri",
    },
    {
        "CITYNAME": "Oita",
        "CITYCODE": "OIT",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "OIT",
        "AIRPORTNAME": "Oita",
    },
    {
        "CITYNAME": "Okinawa",
        "CITYCODE": "OKA",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "OKA",
        "AIRPORTNAME": "Naha",
    },
    {
        "CITYNAME": "Oklahoma City",
        "CITYCODE": "OKC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OKC",
        "AIRPORTNAME": "Will Rogers World",
    },
    {
        "CITYNAME": "Okino Erabu",
        "CITYCODE": "OKE",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "OKE",
        "AIRPORTNAME": "Okino Erabu",
    },
    {
        "CITYNAME": "Okaukuejo",
        "CITYCODE": "OKF",
        "COUNTRYCODE": "NA",
        "COUNTRYNAME": "Namibia",
        "AIRPORTCODE": "OKF",
        "AIRPORTNAME": "Okaukuejo",
    },
    {
        "CITYNAME": "Oki Island",
        "CITYCODE": "OKI",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "OKI",
        "AIRPORTNAME": "Oki Island",
    },
    {
        "CITYNAME": "Okayama",
        "CITYCODE": "OKJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "OKJ",
        "AIRPORTNAME": "Okayama",
    },
    {
        "CITYNAME": "Oksibil",
        "CITYCODE": "OKL",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "OKL",
        "AIRPORTNAME": "Oksibil",
    },
    {
        "CITYNAME": "Okondja",
        "CITYCODE": "OKN",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "OKN",
        "AIRPORTNAME": "Okondja",
    },
    {
        "CITYNAME": "Oksapmin",
        "CITYCODE": "OKP",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "OKP",
        "AIRPORTNAME": "Oksapmin",
    },
    {
        "CITYNAME": "Okaba",
        "CITYCODE": "OKQ",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "OKQ",
        "AIRPORTNAME": "Okaba",
    },
    {
        "CITYNAME": "Yorke Island",
        "CITYCODE": "OKR",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "OKR",
        "AIRPORTNAME": "Yorke Island",
    },
    {
        "CITYNAME": "Orland",
        "CITYCODE": "OLA",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "OLA",
        "AIRPORTNAME": "Orland",
    },
    {
        "CITYNAME": "Olbia",
        "CITYCODE": "OLB",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "OLB",
        "AIRPORTNAME": "Costa Smeralda",
    },
    {
        "CITYNAME": "Sao Paulo de Olivenca",
        "CITYCODE": "OLC",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "OLC",
        "AIRPORTNAME": "Sao Paulo de Olivenca Airport",
    },
    {
        "CITYNAME": "Wolf Point",
        "CITYCODE": "OLF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OLF",
        "AIRPORTNAME": "Wolf Point Int'l",
    },
    {
        "CITYNAME": "Olpoi",
        "CITYCODE": "OLJ",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "OLJ",
        "AIRPORTNAME": "Olpoi",
    },
    {
        "CITYNAME": "Olympia",
        "CITYCODE": "OLM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OLM",
        "AIRPORTNAME": "Olympia",
    },
    {
        "CITYNAME": "Olympic Dam",
        "CITYCODE": "OLP",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "OLP",
        "AIRPORTNAME": "Olympic Dam",
    },
    {
        "CITYNAME": "Olsobip",
        "CITYCODE": "OLQ",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "OLQ",
        "AIRPORTNAME": "Olsobip",
    },
    {
        "CITYNAME": "Omaha",
        "CITYCODE": "OMA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OMA",
        "AIRPORTNAME": "Eppley Airfield",
    },
    {
        "CITYNAME": "Omboue",
        "CITYCODE": "OMB",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "OMB",
        "AIRPORTNAME": "Omboue",
    },
    {
        "CITYNAME": "Ormoc",
        "CITYCODE": "OMC",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "OMC",
        "AIRPORTNAME": "Ormoc Airport",
    },
    {
        "CITYNAME": "Oranjemund",
        "CITYCODE": "OMD",
        "COUNTRYCODE": "NA",
        "COUNTRYNAME": "Namibia",
        "AIRPORTCODE": "OMD",
        "AIRPORTNAME": "Oranjemund",
    },
    {
        "CITYNAME": "Nome",
        "CITYCODE": "OME",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OME",
        "AIRPORTNAME": "Nome",
    },
    {
        "CITYNAME": "Urmieh",
        "CITYCODE": "OMH",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "OMH",
        "AIRPORTNAME": "Urmieh",
    },
    {
        "CITYNAME": "Mostar",
        "CITYCODE": "OMO",
        "COUNTRYCODE": "BA",
        "COUNTRYNAME": "Bosnia and Herzegowina",
        "AIRPORTCODE": "OMO",
        "AIRPORTNAME": "Mostar",
    },
    {
        "CITYNAME": "Oradea",
        "CITYCODE": "OMR",
        "COUNTRYCODE": "RO",
        "COUNTRYNAME": "Romania",
        "AIRPORTCODE": "OMR",
        "AIRPORTNAME": "Oradea",
    },
    {
        "CITYNAME": "Omsk",
        "CITYCODE": "OMS",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "OMS",
        "AIRPORTNAME": "Omsk",
    },
    {
        "CITYNAME": "Ondangwa",
        "CITYCODE": "OND",
        "COUNTRYCODE": "NA",
        "COUNTRYNAME": "Namibia",
        "AIRPORTCODE": "OND",
        "AIRPORTNAME": "Ondangwa",
    },
    {
        "CITYNAME": "Mornington",
        "CITYCODE": "ONG",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "ONG",
        "AIRPORTNAME": "Mornington",
    },
    {
        "CITYNAME": "Moanamani",
        "CITYCODE": "ONI",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "ONI",
        "AIRPORTNAME": "Moanamani",
    },
    {
        "CITYNAME": "Newport",
        "CITYCODE": "ONP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ONP",
        "AIRPORTNAME": "NEWPORT",
    },
    {
        "CITYNAME": "Zonguldak",
        "CITYCODE": "ONQ",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "ONQ",
        "AIRPORTNAME": "Zonguldak Airport",
    },
    {
        "CITYNAME": "Onslow",
        "CITYCODE": "ONS",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "ONS",
        "AIRPORTNAME": "Onslow",
    },
    {
        "CITYNAME": "Ontario",
        "CITYCODE": "ONT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ONT",
        "AIRPORTNAME": "Ontario Internationa",
    },
    {
        "CITYNAME": "Colon",
        "CITYCODE": "ONX",
        "COUNTRYCODE": "PA",
        "COUNTRYNAME": "Panama",
        "AIRPORTCODE": "ONX",
        "AIRPORTNAME": "Colon",
    },
    {
        "CITYNAME": "Toksook Bay",
        "CITYCODE": "OOK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OOK",
        "AIRPORTNAME": "Toksook Bay",
    },
    {
        "CITYNAME": "Gold Coast",
        "CITYCODE": "OOL",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "OOL",
        "AIRPORTNAME": "Coolangatta",
    },
    {
        "CITYNAME": "Cooma",
        "CITYCODE": "OOM",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "OOM",
        "AIRPORTNAME": "Cooma",
    },
    {
        "CITYNAME": "Onotoa",
        "CITYCODE": "OOT",
        "COUNTRYCODE": "KI",
        "COUNTRYNAME": "Kiribati",
        "AIRPORTCODE": "OOT",
        "AIRPORTNAME": "Onotoa",
    },
    {
        "CITYNAME": "Kopasker",
        "CITYCODE": "OPA",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "OPA",
        "AIRPORTNAME": "Kopasker",
    },
    {
        "CITYNAME": "Porto",
        "CITYCODE": "OPO",
        "COUNTRYCODE": "PT",
        "COUNTRYNAME": "Portugal",
        "AIRPORTCODE": "OPO",
        "AIRPORTNAME": "Porto",
    },
    {
        "CITYNAME": "Sinop",
        "CITYCODE": "OPS",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "OPS",
        "AIRPORTNAME": "Sinop - Mato Grosso",
    },
    {
        "CITYNAME": "Balimo",
        "CITYCODE": "OPU",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "OPU",
        "AIRPORTNAME": "Balimo",
    },
    {
        "CITYNAME": "Orebro",
        "CITYCODE": "ORB",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "ORB",
        "AIRPORTNAME": "Orebro-Bofors",
    },
    {
        "CITYNAME": "Orocue",
        "CITYCODE": "ORC",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "ORC",
        "AIRPORTNAME": "Orocue",
    },
    {
        "CITYNAME": "Norfolk",
        "CITYCODE": "ORF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ORF",
        "AIRPORTNAME": "Norfolk Int'l",
    },
    {
        "CITYNAME": "Worcester",
        "CITYCODE": "ORH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ORH",
        "AIRPORTNAME": "Worcester",
    },
    {
        "CITYNAME": "Cork",
        "CITYCODE": "ORK",
        "COUNTRYCODE": "IE",
        "COUNTRYNAME": "Ireland",
        "AIRPORTCODE": "ORK",
        "AIRPORTNAME": "Cork",
    },
    {
        "CITYNAME": "Orlando",
        "CITYCODE": "ORL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MCO",
        "AIRPORTNAME": "Orlando Int'l",
    },
    {
        "CITYNAME": "Orlando",
        "CITYCODE": "ORL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ORL",
        "AIRPORTNAME": "Orlando Executive Airport",
    },
    {
        "CITYNAME": "Orlando",
        "CITYCODE": "ORL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SFB",
        "AIRPORTNAME": "Orlando Sanford Intl",
    },
    {
        "CITYNAME": "Oran",
        "CITYCODE": "ORN",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "ORN",
        "AIRPORTNAME": "Es Senia",
    },
    {
        "CITYNAME": "Orapa",
        "CITYCODE": "ORP",
        "COUNTRYCODE": "BW",
        "COUNTRYNAME": "Botswana",
        "AIRPORTCODE": "ORP",
        "AIRPORTNAME": "Orapa",
    },
    {
        "CITYNAME": "Noorvik",
        "CITYCODE": "ORV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ORV",
        "AIRPORTNAME": "Curtis Memorial",
    },
    {
        "CITYNAME": "Ormara",
        "CITYCODE": "ORW",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "ORW",
        "AIRPORTNAME": "Ormara",
    },
    {
        "CITYNAME": "Osaka",
        "CITYCODE": "OSA",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "KIX",
        "AIRPORTNAME": "Kansai Int'l",
    },
    {
        "CITYNAME": "Osage Beach",
        "CITYCODE": "OSB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OSB",
        "AIRPORTNAME": "Mosul International Airport",
    },
    {
        "CITYNAME": "Ostersund",
        "CITYCODE": "OSD",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "OSD",
        "AIRPORTNAME": "Froesoe",
    },
    {
        "CITYNAME": "Oshkosh",
        "CITYCODE": "OSH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OSH",
        "AIRPORTNAME": "Wittman Field",
    },
    {
        "CITYNAME": "Osijek",
        "CITYCODE": "OSI",
        "COUNTRYCODE": "HR",
        "COUNTRYNAME": "Croatia",
        "AIRPORTCODE": "OSI",
        "AIRPORTNAME": "Osijek Intl Airport",
    },
    {
        "CITYNAME": "Oskarshamn",
        "CITYCODE": "OSK",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "OSK",
        "AIRPORTNAME": "Oskarshamn",
    },
    {
        "CITYNAME": "Oslo",
        "CITYCODE": "OSL",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "OSL",
        "AIRPORTNAME": "Oslo Airport",
    },
    {
        "CITYNAME": "Oslo",
        "CITYCODE": "OSL",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "RYG",
        "AIRPORTNAME": "Moss-Rygge",
    },
    {
        "CITYNAME": "Oslo",
        "CITYCODE": "OSL",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "TRF",
        "AIRPORTNAME": "Sandefjord",
    },
    {
        "CITYNAME": "Slupsk",
        "CITYCODE": "OSP",
        "COUNTRYCODE": "PL",
        "COUNTRYNAME": "Poland",
        "AIRPORTCODE": "OSP",
        "AIRPORTNAME": "Slupsk - Redzikowo Airport",
    },
    {
        "CITYNAME": "Ostrava",
        "CITYCODE": "OSR",
        "COUNTRYCODE": "CZ",
        "COUNTRYNAME": "Czech Republic",
        "AIRPORTCODE": "OSR",
        "AIRPORTNAME": "Mosnov",
    },
    {
        "CITYNAME": "Osh",
        "CITYCODE": "OSS",
        "COUNTRYCODE": "KG",
        "COUNTRYNAME": "Kyrgyzstan",
        "AIRPORTCODE": "OSS",
        "AIRPORTNAME": "Osh",
    },
    {
        "CITYNAME": "Ostend",
        "CITYCODE": "OST",
        "COUNTRYCODE": "BE",
        "COUNTRYNAME": "Belgium",
        "AIRPORTCODE": "OST",
        "AIRPORTNAME": "Ostend",
    },
    {
        "CITYNAME": "Namsos",
        "CITYCODE": "OSY",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "OSY",
        "AIRPORTNAME": "Namsos",
    },
    {
        "CITYNAME": "Mota",
        "CITYCODE": "OTA",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "OTA",
        "AIRPORTNAME": "Mota",
    },
    {
        "CITYNAME": "Bol",
        "CITYCODE": "OTC",
        "COUNTRYCODE": "TD",
        "COUNTRYNAME": "Chad",
        "AIRPORTCODE": "OTC",
        "AIRPORTNAME": "Bol",
    },
    {
        "CITYNAME": "Contadora",
        "CITYCODE": "OTD",
        "COUNTRYCODE": "PA",
        "COUNTRYNAME": "Panama",
        "AIRPORTCODE": "OTD",
        "AIRPORTNAME": "Contadora",
    },
    {
        "CITYNAME": "N Bend Coosbay",
        "CITYCODE": "OTH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OTH",
        "AIRPORTNAME": "N Bend Coosbay",
    },
    {
        "CITYNAME": "Morotai Island",
        "CITYCODE": "OTI",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "OTI",
        "AIRPORTNAME": "Morotai Island",
    },
    {
        "CITYNAME": "Boutilimit",
        "CITYCODE": "OTL",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "OTL",
        "AIRPORTNAME": "Boutilimit",
    },
    {
        "CITYNAME": "Ottumwa",
        "CITYCODE": "OTM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OTM",
        "AIRPORTNAME": "Ottumwa Industrial",
    },
    {
        "CITYNAME": "Coto 47",
        "CITYCODE": "OTR",
        "COUNTRYCODE": "CR",
        "COUNTRYNAME": "Costa Rica",
        "AIRPORTCODE": "OTR",
        "AIRPORTNAME": "Coto 47",
    },
    {
        "CITYNAME": "Anacortes",
        "CITYCODE": "OTS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OTS",
        "AIRPORTNAME": "Anacortes",
    },
    {
        "CITYNAME": "Otu",
        "CITYCODE": "OTU",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "OTU",
        "AIRPORTNAME": "Otu",
    },
    {
        "CITYNAME": "Kotzebue",
        "CITYCODE": "OTZ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OTZ",
        "AIRPORTNAME": "Kotzebue",
    },
    {
        "CITYNAME": "Ouagadougou",
        "CITYCODE": "OUA",
        "COUNTRYCODE": "BF",
        "COUNTRYNAME": "Burkina Faso",
        "AIRPORTCODE": "OUA",
        "AIRPORTNAME": "Ouagadougou",
    },
    {
        "CITYNAME": "Oujda",
        "CITYCODE": "OUD",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "OUD",
        "AIRPORTNAME": "Les Angades",
    },
    {
        "CITYNAME": "Ouesso",
        "CITYCODE": "OUE",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "OUE",
        "AIRPORTNAME": "Ouesso",
    },
    {
        "CITYNAME": "Ouahigouya",
        "CITYCODE": "OUG",
        "COUNTRYCODE": "BF",
        "COUNTRYNAME": "Burkina Faso",
        "AIRPORTCODE": "OUG",
        "AIRPORTNAME": "Ouahigouya",
    },
    {
        "CITYNAME": "Oulu",
        "CITYCODE": "OUL",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "OUL",
        "AIRPORTNAME": "Oulu",
    },
    {
        "CITYNAME": "Oum Hadjer",
        "CITYCODE": "OUM",
        "COUNTRYCODE": "TD",
        "COUNTRYNAME": "Chad",
        "AIRPORTCODE": "OUM",
        "AIRPORTNAME": "Oum Hadjer",
    },
    {
        "CITYNAME": "Batouri",
        "CITYCODE": "OUR",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "OUR",
        "AIRPORTNAME": "Batouri",
    },
    {
        "CITYNAME": "Ourinhos",
        "CITYCODE": "OUS",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "OUS",
        "AIRPORTNAME": "Ourinhos",
    },
    {
        "CITYNAME": "Bousso",
        "CITYCODE": "OUT",
        "COUNTRYCODE": "TD",
        "COUNTRYNAME": "Chad",
        "AIRPORTCODE": "OUT",
        "AIRPORTNAME": "Bousso",
    },
    {
        "CITYNAME": "Zouerate",
        "CITYCODE": "OUZ",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "OUZ",
        "AIRPORTNAME": "Zouerate",
    },
    {
        "CITYNAME": "Bekily",
        "CITYCODE": "OVA",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "OVA",
        "AIRPORTNAME": "Bekily",
    },
    {
        "CITYNAME": "Novosibirsk",
        "CITYCODE": "OVB",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "OVB",
        "AIRPORTNAME": "Tolmachevo",
    },
    {
        "CITYNAME": "Oviedo",
        "CITYCODE": "OVD",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "OVD",
        "AIRPORTNAME": "Asturias",
    },
    {
        "CITYNAME": "Oviedo",
        "CITYCODE": "OVD",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "OVI",
        "AIRPORTNAME": "Oviedo Railway Station Airport",
    },
    {
        "CITYNAME": "Owensboro",
        "CITYCODE": "OWB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OWB",
        "AIRPORTNAME": "Daviess County",
    },
    {
        "CITYNAME": "Bissau",
        "CITYCODE": "OXB",
        "COUNTRYCODE": "GW",
        "COUNTRYNAME": "Guinea-Bissau",
        "AIRPORTCODE": "OXB",
        "AIRPORTNAME": "Osvaldo Vieira",
    },
    {
        "CITYNAME": "Oxford",
        "CITYCODE": "OXC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HNZ",
        "AIRPORTNAME": "HendersonâOxford Airport",
    },
    {
        "CITYNAME": "Oxford",
        "CITYCODE": "OXF",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "OXF",
        "AIRPORTNAME": "London Oxford",
    },
    {
        "CITYNAME": "Oxford",
        "CITYCODE": "OXQ",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "OXQ",
        "AIRPORTNAME": "Oxford Railway Station",
    },
    {
        "CITYNAME": "Oxnard",
        "CITYCODE": "OXR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "OXR",
        "AIRPORTNAME": "Oxnard",
    },
    {
        "CITYNAME": "Oyem",
        "CITYCODE": "OYE",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "OYE",
        "AIRPORTNAME": "Oyem",
    },
    {
        "CITYNAME": "Moyale",
        "CITYCODE": "OYL",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "OYL",
        "AIRPORTNAME": "Moyale",
    },
    {
        "CITYNAME": "Ozamis City",
        "CITYCODE": "OZC",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "OZC",
        "AIRPORTNAME": "Labo Airport",
    },
    {
        "CITYNAME": "Zaporozhye",
        "CITYCODE": "OZH",
        "COUNTRYCODE": "UA",
        "COUNTRYNAME": "Ukraine",
        "AIRPORTCODE": "OZH",
        "AIRPORTNAME": "Zaporozhye",
    },
    {
        "CITYNAME": "Ouarzazate",
        "CITYCODE": "OZZ",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "OZZ",
        "AIRPORTNAME": "Ouarzazate",
    },
    {
        "CITYNAME": "Bilaspur",
        "CITYCODE": "PAB",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "PAB",
        "AIRPORTNAME": "Bilaspur",
    },
    {
        "CITYNAME": "Paderborn",
        "CITYCODE": "PAD",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "PAD",
        "AIRPORTNAME": "Paderborn",
    },
    {
        "CITYNAME": "Everett",
        "CITYCODE": "PAE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PAE",
        "AIRPORTNAME": "Snohomish County",
    },
    {
        "CITYNAME": "Pagadian",
        "CITYCODE": "PAG",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "PAG",
        "AIRPORTNAME": "Pagadian Airport",
    },
    {
        "CITYNAME": "Paducah",
        "CITYCODE": "PAH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PAH",
        "AIRPORTNAME": "Barkley Regional",
    },
    {
        "CITYNAME": "Pattani",
        "CITYCODE": "PAN",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "PAN",
        "AIRPORTNAME": "Pattani",
    },
    {
        "CITYNAME": "Port Au Prince",
        "CITYCODE": "PAP",
        "COUNTRYCODE": "HT",
        "COUNTRYNAME": "Haiti",
        "AIRPORTCODE": "PAP",
        "AIRPORTNAME": "Mais Gate",
    },
    {
        "CITYNAME": "Palmer",
        "CITYCODE": "PAQ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PAQ",
        "AIRPORTNAME": "Palmer Municipal",
    },
    {
        "CITYNAME": "Paris",
        "CITYCODE": "PAR",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "BVA",
        "AIRPORTNAME": "Beauvais-Tille",
    },
    {
        "CITYNAME": "Paris",
        "CITYCODE": "PAR",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "CDG",
        "AIRPORTNAME": "Charles De Gaulle",
    },
    {
        "CITYNAME": "Paris",
        "CITYCODE": "PAR",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "LBG",
        "AIRPORTNAME": "Le Bourget Airport",
    },
    {
        "CITYNAME": "Paris",
        "CITYCODE": "PAR",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "ORY",
        "AIRPORTNAME": "Orly",
    },
    {
        "CITYNAME": "Paris",
        "CITYCODE": "PAR",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "XCR",
        "AIRPORTNAME": "Chalons-Vatry",
    },
    {
        "CITYNAME": "Paris",
        "CITYCODE": "PAR",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "XPG",
        "AIRPORTNAME": "Gare du Nord Airport",
    },
    {
        "CITYNAME": "Paros",
        "CITYCODE": "PAS",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "PAS",
        "AIRPORTNAME": "Paros",
    },
    {
        "CITYNAME": "Patna",
        "CITYCODE": "PAT",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "PAT",
        "AIRPORTNAME": "Patna",
    },
    {
        "CITYNAME": "Paulo Afonso",
        "CITYCODE": "PAV",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "PAV",
        "AIRPORTNAME": "Paulo Afonso",
    },
    {
        "CITYNAME": "Pamol",
        "CITYCODE": "PAY",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "PAY",
        "AIRPORTNAME": "Pamol",
    },
    {
        "CITYNAME": "Poza Rica",
        "CITYCODE": "PAZ",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "PAZ",
        "AIRPORTNAME": "Tajin",
    },
    {
        "CITYNAME": "Puebla",
        "CITYCODE": "PBC",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "PBC",
        "AIRPORTNAME": "Huejotsingo",
    },
    {
        "CITYNAME": "Porbandar",
        "CITYCODE": "PBD",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "PBD",
        "AIRPORTNAME": "Porbandar",
    },
    {
        "CITYNAME": "Puerto Berrio",
        "CITYCODE": "PBE",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "PBE",
        "AIRPORTNAME": "Puerto Berrio",
    },
    {
        "CITYNAME": "Plattsburgh",
        "CITYCODE": "PBG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PBG",
        "AIRPORTNAME": "Plattsburgh Intl Airport",
    },
    {
        "CITYNAME": "Paro",
        "CITYCODE": "PBH",
        "COUNTRYCODE": "BT",
        "COUNTRYNAME": "Bhutan",
        "AIRPORTCODE": "PBH",
        "AIRPORTNAME": "Paro",
    },
    {
        "CITYNAME": "West Palm Beach",
        "CITYCODE": "PBI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PBI",
        "AIRPORTNAME": "Palm Beach Intl",
    },
    {
        "CITYNAME": "Puerto Cabello",
        "CITYCODE": "PBL",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "PBL",
        "AIRPORTNAME": "Puerto Cabello",
    },
    {
        "CITYNAME": "Paramaribo",
        "CITYCODE": "PBM",
        "COUNTRYCODE": "SR",
        "COUNTRYNAME": "Suriname",
        "AIRPORTCODE": "ORG",
        "AIRPORTNAME": "Zorg En Hoop",
    },
    {
        "CITYNAME": "Paramaribo",
        "CITYCODE": "PBM",
        "COUNTRYCODE": "SR",
        "COUNTRYNAME": "Suriname",
        "AIRPORTCODE": "PBM",
        "AIRPORTNAME": "Zanderij Internatiional",
    },
    {
        "CITYNAME": "Porto Amboim",
        "CITYCODE": "PBN",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "PBN",
        "AIRPORTNAME": "Porto Amboim",
    },
    {
        "CITYNAME": "Paraburdoo",
        "CITYCODE": "PBO",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "PBO",
        "AIRPORTNAME": "Paraburdoo",
    },
    {
        "CITYNAME": "Putao",
        "CITYCODE": "PBU",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "PBU",
        "AIRPORTNAME": "Putao",
    },
    {
        "CITYNAME": "Portage Creek",
        "CITYCODE": "PCA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PCA",
        "AIRPORTNAME": "Portage Creek",
    },
    {
        "CITYNAME": "Puerto Rico",
        "CITYCODE": "PCC",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "PCC",
        "AIRPORTNAME": "Puerto Rico Airport",
    },
    {
        "CITYNAME": "Painter Creek",
        "CITYCODE": "PCE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PCE",
        "AIRPORTNAME": "Painter Creek Airport",
    },
    {
        "CITYNAME": "Pucallpa",
        "CITYCODE": "PCL",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "PCL",
        "AIRPORTNAME": "Capitan Rolden",
    },
    {
        "CITYNAME": "Picton",
        "CITYCODE": "PCN",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "PCN",
        "AIRPORTNAME": "Koromiko",
    },
    {
        "CITYNAME": "Principe",
        "CITYCODE": "PCP",
        "COUNTRYCODE": "ST",
        "COUNTRYNAME": "Sao Tome & Principe",
        "AIRPORTCODE": "PCP",
        "AIRPORTNAME": "Principe",
    },
    {
        "CITYNAME": "Puerto Carreno",
        "CITYCODE": "PCR",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "PCR",
        "AIRPORTNAME": "Puerto Carreno",
    },
    {
        "CITYNAME": "Puerto Inirida",
        "CITYCODE": "PDA",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "PDA",
        "AIRPORTNAME": "Puerto Inirida",
    },
    {
        "CITYNAME": "Pedro Bay",
        "CITYCODE": "PDB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PDB",
        "AIRPORTNAME": "Pedro Bay",
    },
    {
        "CITYNAME": "Prado",
        "CITYCODE": "PDF",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "PDF",
        "AIRPORTNAME": "Prado Airport",
    },
    {
        "CITYNAME": "Padang",
        "CITYCODE": "PDG",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "PDG",
        "AIRPORTNAME": "Tabing",
    },
    {
        "CITYNAME": "Ponta Delgada Azore",
        "CITYCODE": "PDL",
        "COUNTRYCODE": "PT",
        "COUNTRYNAME": "Portugal",
        "AIRPORTCODE": "PDL",
        "AIRPORTNAME": "Nordela",
    },
    {
        "CITYNAME": "Parndana",
        "CITYCODE": "PDN",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "PDN",
        "AIRPORTNAME": "Parndana",
    },
    {
        "CITYNAME": "Punta Del Este",
        "CITYCODE": "PDP",
        "COUNTRYCODE": "UY",
        "COUNTRYNAME": "Uruguay",
        "AIRPORTCODE": "PDP",
        "AIRPORTNAME": "Punta Del Este",
    },
    {
        "CITYNAME": "Pendleton",
        "CITYCODE": "PDT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PDT",
        "AIRPORTNAME": "Pendleton",
    },
    {
        "CITYNAME": "Paysandu",
        "CITYCODE": "PDU",
        "COUNTRYCODE": "UY",
        "COUNTRYNAME": "Uruguay",
        "AIRPORTCODE": "PDU",
        "AIRPORTNAME": "Paysandu",
    },
    {
        "CITYNAME": "Plovdiv",
        "CITYCODE": "PDV",
        "COUNTRYCODE": "BG",
        "COUNTRYNAME": "Bulgaria",
        "AIRPORTCODE": "PDV",
        "AIRPORTNAME": "Plovdiv",
    },
    {
        "CITYNAME": "Portland",
        "CITYCODE": "PDX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PDX",
        "AIRPORTNAME": "Portland Int'l",
    },
    {
        "CITYNAME": "Penneshaw",
        "CITYCODE": "PEA",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "PEA",
        "AIRPORTNAME": "Penneshaw",
    },
    {
        "CITYNAME": "Pardubice",
        "CITYCODE": "PED",
        "COUNTRYCODE": "CZ",
        "COUNTRYNAME": "Czech Republic",
        "AIRPORTCODE": "PED",
        "AIRPORTNAME": "Pardubice",
    },
    {
        "CITYNAME": "Perm",
        "CITYCODE": "PEE",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "PEE",
        "AIRPORTNAME": "Perm",
    },
    {
        "CITYNAME": "Perugia",
        "CITYCODE": "PEG",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "PEG",
        "AIRPORTNAME": "Sant Egidio",
    },
    {
        "CITYNAME": "Pereira",
        "CITYCODE": "PEI",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "PEI",
        "AIRPORTNAME": "Pereira",
    },
    {
        "CITYNAME": "Puerto Maldonado",
        "CITYCODE": "PEM",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "PEM",
        "AIRPORTNAME": "Puerto Maldonado",
    },
    {
        "CITYNAME": "Penang",
        "CITYCODE": "PEN",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "PEN",
        "AIRPORTNAME": "Penang Int'l",
    },
    {
        "CITYNAME": "Perth",
        "CITYCODE": "PER",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "PER",
        "AIRPORTNAME": "Perth",
    },
    {
        "CITYNAME": "Pelotas",
        "CITYCODE": "PET",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "PET",
        "AIRPORTNAME": "Pelotas",
    },
    {
        "CITYNAME": "Puerto Lempira",
        "CITYCODE": "PEU",
        "COUNTRYCODE": "HN",
        "COUNTRYNAME": "Honduras",
        "AIRPORTCODE": "PEU",
        "AIRPORTNAME": "Puerto Lempira",
    },
    {
        "CITYNAME": "Peshawar",
        "CITYCODE": "PEW",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "PEW",
        "AIRPORTNAME": "Peshawar",
    },
    {
        "CITYNAME": "Penza",
        "CITYCODE": "PEZ",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "PEZ",
        "AIRPORTNAME": "Ternovka",
    },
    {
        "CITYNAME": "Passo Fundo",
        "CITYCODE": "PFB",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "PFB",
        "AIRPORTNAME": "Passo Fundo",
    },
    {
        "CITYNAME": "Patreksfjordur",
        "CITYCODE": "PFJ",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "PFJ",
        "AIRPORTNAME": "Patreksfjordur",
    },
    {
        "CITYNAME": "Panama City",
        "CITYCODE": "PFN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PFN",
        "AIRPORTNAME": "Bay County",
    },
    {
        "CITYNAME": "Paphos",
        "CITYCODE": "PFO",
        "COUNTRYCODE": "CY",
        "COUNTRYNAME": "Cyprus",
        "AIRPORTCODE": "PFO",
        "AIRPORTNAME": "Paphos Int'l",
    },
    {
        "CITYNAME": "Page",
        "CITYCODE": "PGA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PGA",
        "AIRPORTNAME": "Page",
    },
    {
        "CITYNAME": "Punta Gorda",
        "CITYCODE": "PGD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PGD",
        "AIRPORTNAME": "Punta Gorda Airport",
    },
    {
        "CITYNAME": "Perpignan",
        "CITYCODE": "PGF",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "PGF",
        "AIRPORTNAME": "Rivesaltes",
    },
    {
        "CITYNAME": "Pantnagar",
        "CITYCODE": "PGH",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "PGH",
        "AIRPORTNAME": "Pantnagar",
    },
    {
        "CITYNAME": "Chitato",
        "CITYCODE": "PGI",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "PGI",
        "AIRPORTNAME": "Chitato",
    },
    {
        "CITYNAME": "Pangkalpinang",
        "CITYCODE": "PGK",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "PGK",
        "AIRPORTNAME": "Pangkalpinang",
    },
    {
        "CITYNAME": "Port Graham",
        "CITYCODE": "PGM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PGM",
        "AIRPORTNAME": "Port Graham",
    },
    {
        "CITYNAME": "Greenville",
        "CITYCODE": "PGV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PGV",
        "AIRPORTNAME": "Pitt-Greenville",
    },
    {
        "CITYNAME": "Perigueux",
        "CITYCODE": "PGX",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "PGX",
        "AIRPORTNAME": "Perigueux",
    },
    {
        "CITYNAME": "Ponta Grossa",
        "CITYCODE": "PGZ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "PGZ",
        "AIRPORTNAME": "Ponta Grossa Airport",
    },
    {
        "CITYNAME": "Parnaiba",
        "CITYCODE": "PHB",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "PHB",
        "AIRPORTNAME": "Parnaiba - Prefeito Dr Filho",
    },
    {
        "CITYNAME": "Port Harcourt",
        "CITYCODE": "PHC",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "PHC",
        "AIRPORTNAME": "Port Harcourt",
    },
    {
        "CITYNAME": "Port Hedland",
        "CITYCODE": "PHE",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "PHE",
        "AIRPORTNAME": "Port Hedland",
    },
    {
        "CITYNAME": "Hampton",
        "CITYCODE": "PHF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PHF",
        "AIRPORTNAME": "Newportnews/Williamsburg",
    },
    {
        "CITYNAME": "Port Hunter",
        "CITYCODE": "PHJ",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "PHJ",
        "AIRPORTNAME": "Port Hunter",
    },
    {
        "CITYNAME": "Philadelphia",
        "CITYCODE": "PHL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BBX",
        "AIRPORTNAME": "Wings Field",
    },
    {
        "CITYNAME": "Philadelphia",
        "CITYCODE": "PHL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PHL",
        "AIRPORTNAME": "Philadelphia Int'l",
    },
    {
        "CITYNAME": "Philadelphia",
        "CITYCODE": "PHL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PNE",
        "AIRPORTNAME": "North Philadelphia",
    },
    {
        "CITYNAME": "Port Huron",
        "CITYCODE": "PHN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PHN",
        "AIRPORTNAME": "SAINT CLAIR INTL",
    },
    {
        "CITYNAME": "Point Hope",
        "CITYCODE": "PHO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PHO",
        "AIRPORTNAME": "Point Hope",
    },
    {
        "CITYNAME": "Pacific Harbor",
        "CITYCODE": "PHR",
        "COUNTRYCODE": "FJ",
        "COUNTRYNAME": "Fiji Islands",
        "AIRPORTCODE": "PHR",
        "AIRPORTNAME": "Pacific Harbor",
    },
    {
        "CITYNAME": "Phitsanulok",
        "CITYCODE": "PHS",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "PHS",
        "AIRPORTNAME": "Phitsanulok",
    },
    {
        "CITYNAME": "Phalaborwa",
        "CITYCODE": "PHW",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "PHW",
        "AIRPORTNAME": "H Van Eck",
    },
    {
        "CITYNAME": "Phoenix",
        "CITYCODE": "PHX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AZA",
        "AIRPORTNAME": "Phoenix-Mesa Gateway ",
    },
    {
        "CITYNAME": "Phoenix",
        "CITYCODE": "PHX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PHX",
        "AIRPORTNAME": "Sky Harbor Int'l",
    },
    {
        "CITYNAME": "Phoenix",
        "CITYCODE": "PHX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SCF",
        "AIRPORTNAME": "Scottsdale Municipal",
    },
    {
        "CITYNAME": "Peoria",
        "CITYCODE": "PIA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PIA",
        "AIRPORTNAME": "Greater Peoria",
    },
    {
        "CITYNAME": "St Petersburg",
        "CITYCODE": "PIE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PIE",
        "AIRPORTNAME": "St Petersburg Int'l",
    },
    {
        "CITYNAME": "Pocatello",
        "CITYCODE": "PIH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PIH",
        "AIRPORTNAME": "Pocatello",
    },
    {
        "CITYNAME": "Parintins",
        "CITYCODE": "PIN",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "PIN",
        "AIRPORTNAME": "Parintins Airport",
    },
    {
        "CITYNAME": "Pilot Point",
        "CITYCODE": "PIP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PIP",
        "AIRPORTNAME": "Pilot Point",
    },
    {
        "CITYNAME": "Pilot Point",
        "CITYCODE": "PIP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "UGB",
        "AIRPORTNAME": "Ugashik Bay",
    },
    {
        "CITYNAME": "Pierre",
        "CITYCODE": "PIR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PIR",
        "AIRPORTNAME": "Pierre",
    },
    {
        "CITYNAME": "Poitiers",
        "CITYCODE": "PIS",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "PIS",
        "AIRPORTNAME": "Biard",
    },
    {
        "CITYNAME": "Pittsburgh",
        "CITYCODE": "PIT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AGC",
        "AIRPORTNAME": "Allegheny",
    },
    {
        "CITYNAME": "Pittsburgh",
        "CITYCODE": "PIT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PIT",
        "AIRPORTNAME": "Pittsburgh Int'l",
    },
    {
        "CITYNAME": "Piura",
        "CITYCODE": "PIU",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "PIU",
        "AIRPORTNAME": "Piura",
    },
    {
        "CITYNAME": "Pico Island",
        "CITYCODE": "PIX",
        "COUNTRYCODE": "PT",
        "COUNTRYNAME": "Portugal",
        "AIRPORTCODE": "PIX",
        "AIRPORTNAME": "Pico Island",
    },
    {
        "CITYNAME": "Point Lay",
        "CITYCODE": "PIZ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PIZ",
        "AIRPORTNAME": "Dew Station",
    },
    {
        "CITYNAME": "Panjgur",
        "CITYCODE": "PJG",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "PJG",
        "AIRPORTNAME": "Panjgur",
    },
    {
        "CITYNAME": "Napaiskak",
        "CITYCODE": "PKA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PKA",
        "AIRPORTNAME": "Napaiskakspb",
    },
    {
        "CITYNAME": "Parkersburg",
        "CITYCODE": "PKB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PKB",
        "AIRPORTNAME": "Wood County",
    },
    {
        "CITYNAME": "Petropavlovsk Kam",
        "CITYCODE": "PKC",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "PKC",
        "AIRPORTNAME": "Petropavlovsk Kam",
    },
    {
        "CITYNAME": "Parkes",
        "CITYCODE": "PKE",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "PKE",
        "AIRPORTNAME": "Parkes",
    },
    {
        "CITYNAME": "Pangkalanbuun",
        "CITYCODE": "PKN",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "PKN",
        "AIRPORTNAME": "Pangkalanbuun",
    },
    {
        "CITYNAME": "Parakou",
        "CITYCODE": "PKO",
        "COUNTRYCODE": "BJ",
        "COUNTRYNAME": "Benin",
        "AIRPORTCODE": "PKO",
        "AIRPORTNAME": "Parakou",
    },
    {
        "CITYNAME": "Puka Puka",
        "CITYCODE": "PKP",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "PKP",
        "AIRPORTNAME": "Puka Puka",
    },
    {
        "CITYNAME": "Pokhara",
        "CITYCODE": "PKR",
        "COUNTRYCODE": "NP",
        "COUNTRYNAME": "Nepal",
        "AIRPORTCODE": "PKR",
        "AIRPORTNAME": "Pokhara",
    },
    {
        "CITYNAME": "Pekanbaru",
        "CITYCODE": "PKU",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "PKU",
        "AIRPORTNAME": "Simpang Tiga",
    },
    {
        "CITYNAME": "Selebi Phikwe",
        "CITYCODE": "PKW",
        "COUNTRYCODE": "BW",
        "COUNTRYNAME": "Botswana",
        "AIRPORTCODE": "PKW",
        "AIRPORTNAME": "Selebi Phikwe",
    },
    {
        "CITYNAME": "Palangkaraya",
        "CITYCODE": "PKY",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "PKY",
        "AIRPORTNAME": "Palangkaraya",
    },
    {
        "CITYNAME": "Pakse",
        "CITYCODE": "PKZ",
        "COUNTRYCODE": "LA",
        "COUNTRYNAME": "Lao People's Democratic Republic",
        "AIRPORTCODE": "PKZ",
        "AIRPORTNAME": "Pakse",
    },
    {
        "CITYNAME": "Plattsburgh",
        "CITYCODE": "PLB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PLB",
        "AIRPORTNAME": "Clinton Country",
    },
    {
        "CITYNAME": "Playa Samara",
        "CITYCODE": "PLD",
        "COUNTRYCODE": "CR",
        "COUNTRYNAME": "Costa Rica",
        "AIRPORTCODE": "PLD",
        "AIRPORTNAME": "Playa Samara",
    },
    {
        "CITYNAME": "Paiela",
        "CITYCODE": "PLE",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "PLE",
        "AIRPORTNAME": "Paiela - Papua New Guinea",
    },
    {
        "CITYNAME": "Pala",
        "CITYCODE": "PLF",
        "COUNTRYCODE": "TD",
        "COUNTRYNAME": "Chad",
        "AIRPORTCODE": "PLF",
        "AIRPORTNAME": "Pala",
    },
    {
        "CITYNAME": "Plymouth",
        "CITYCODE": "PLH",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "PLH",
        "AIRPORTNAME": "Plymouth",
    },
    {
        "CITYNAME": "Palembang",
        "CITYCODE": "PLM",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "PLM",
        "AIRPORTNAME": "Mahmud Badaruddin Ii",
    },
    {
        "CITYNAME": "Pellston",
        "CITYCODE": "PLN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PLN",
        "AIRPORTNAME": "Emmet County",
    },
    {
        "CITYNAME": "Port Lincoln",
        "CITYCODE": "PLO",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "PLO",
        "AIRPORTNAME": "Port Lincoln",
    },
    {
        "CITYNAME": "Palanga",
        "CITYCODE": "PLQ",
        "COUNTRYCODE": "LT",
        "COUNTRYNAME": "Lithuania",
        "AIRPORTCODE": "PLQ",
        "AIRPORTNAME": "Palanga",
    },
    {
        "CITYNAME": "Providenciales",
        "CITYCODE": "PLS",
        "COUNTRYCODE": "TC",
        "COUNTRYNAME": "Turcs & Caicos Islands",
        "AIRPORTCODE": "PLS",
        "AIRPORTNAME": "Providenciales",
    },
    {
        "CITYNAME": "Palu",
        "CITYCODE": "PLW",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "PLW",
        "AIRPORTNAME": "Mutiara",
    },
    {
        "CITYNAME": "Semipalatinsk",
        "CITYCODE": "PLX",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "PLX",
        "AIRPORTNAME": "Semipalatinsk",
    },
    {
        "CITYNAME": "Port Elizabeth",
        "CITYCODE": "PLZ",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "PLZ",
        "AIRPORTNAME": "Port Elizabeth Airport",
    },
    {
        "CITYNAME": "Pemba",
        "CITYCODE": "PMA",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "PMA",
        "AIRPORTNAME": "Wawi",
    },
    {
        "CITYNAME": "Puerto Montt",
        "CITYCODE": "PMC",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "PMC",
        "AIRPORTNAME": "Tepual",
    },
    {
        "CITYNAME": "Parma",
        "CITYCODE": "PMF",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "PMF",
        "AIRPORTNAME": "Parma",
    },
    {
        "CITYNAME": "Ponta Pora",
        "CITYCODE": "PMG",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "PMG",
        "AIRPORTNAME": "Ponta Pora Int'l",
    },
    {
        "CITYNAME": "Palma de Mallorca",
        "CITYCODE": "PMI",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "PMI",
        "AIRPORTNAME": "Palma Mallorca",
    },
    {
        "CITYNAME": "Pumani",
        "CITYCODE": "PMN",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "PMN",
        "AIRPORTNAME": "Pumani",
    },
    {
        "CITYNAME": "Palermo",
        "CITYCODE": "PMO",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "PMO",
        "AIRPORTNAME": "Punta Raisi",
    },
    {
        "CITYNAME": "Perito Moreno",
        "CITYCODE": "PMQ",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "PMQ",
        "AIRPORTNAME": "Perito Moreno",
    },
    {
        "CITYNAME": "Palmerston North",
        "CITYCODE": "PMR",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "PMR",
        "AIRPORTNAME": "Palmerston North",
    },
    {
        "CITYNAME": "Porlamar",
        "CITYCODE": "PMV",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "PMV",
        "AIRPORTNAME": "Delcaribe General S Marino",
    },
    {
        "CITYNAME": "Palmas",
        "CITYCODE": "PMW",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "PMW",
        "AIRPORTNAME": "Palmas",
    },
    {
        "CITYNAME": "Palmar",
        "CITYCODE": "PMZ",
        "COUNTRYCODE": "CR",
        "COUNTRYNAME": "Costa Rica",
        "AIRPORTCODE": "PMZ",
        "AIRPORTNAME": "Palmar Sur",
    },
    {
        "CITYNAME": "Pamplona",
        "CITYCODE": "PNA",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "PNA",
        "AIRPORTNAME": "Pamplona",
    },
    {
        "CITYNAME": "Porto Nacional",
        "CITYCODE": "PNB",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "PNB",
        "AIRPORTNAME": "Porto Nacional",
    },
    {
        "CITYNAME": "Ponca City",
        "CITYCODE": "PNC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PNC",
        "AIRPORTNAME": "Ponca City",
    },
    {
        "CITYNAME": "Punta Gorda",
        "CITYCODE": "PND",
        "COUNTRYCODE": "BZ",
        "COUNTRYNAME": "Belize",
        "AIRPORTCODE": "PND",
        "AIRPORTNAME": "Punta Gorda",
    },
    {
        "CITYNAME": "Phnom Penh",
        "CITYCODE": "PNH",
        "COUNTRYCODE": "KH",
        "COUNTRYNAME": "Cambodia",
        "AIRPORTCODE": "PNH",
        "AIRPORTNAME": "POCHENTONG ",
    },
    {
        "CITYNAME": "Pohnpei",
        "CITYCODE": "PNI",
        "COUNTRYCODE": "FM",
        "COUNTRYNAME": "Micronesia",
        "AIRPORTCODE": "PNI",
        "AIRPORTNAME": "Pohnpei",
    },
    {
        "CITYNAME": "Pontianak",
        "CITYCODE": "PNK",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "PNK",
        "AIRPORTNAME": "Supadio",
    },
    {
        "CITYNAME": "Pantelleria",
        "CITYCODE": "PNL",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "PNL",
        "AIRPORTNAME": "Pantelleria",
    },
    {
        "CITYNAME": "Popondetta",
        "CITYCODE": "PNP",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "PNP",
        "AIRPORTNAME": "Girua",
    },
    {
        "CITYNAME": "Pune",
        "CITYCODE": "PNQ",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "PNQ",
        "AIRPORTNAME": "Lohegaon",
    },
    {
        "CITYNAME": "Pointe Noire",
        "CITYCODE": "PNR",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "PNR",
        "AIRPORTNAME": "Pointe Noire",
    },
    {
        "CITYNAME": "Pensacola",
        "CITYCODE": "PNS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PNS",
        "AIRPORTNAME": "Pensacola Regional",
    },
    {
        "CITYNAME": "Puerto Natales",
        "CITYCODE": "PNT",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "PNT",
        "AIRPORTNAME": "Teniente Julio Gallardo Airport",
    },
    {
        "CITYNAME": "Pondicherry",
        "CITYCODE": "PNY",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "PNY",
        "AIRPORTNAME": "PONDICHERRY ",
    },
    {
        "CITYNAME": "Petrolina",
        "CITYCODE": "PNZ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "PNZ",
        "AIRPORTNAME": "Petrolina Internacional",
    },
    {
        "CITYNAME": "Porto Alegre",
        "CITYCODE": "POA",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "POA",
        "AIRPORTNAME": "Salgado Filho",
    },
    {
        "CITYNAME": "Podor",
        "CITYCODE": "POD",
        "COUNTRYCODE": "SN",
        "COUNTRYNAME": "Senegal",
        "AIRPORTCODE": "POD",
        "AIRPORTNAME": "Podor",
    },
    {
        "CITYNAME": "Port Gentil",
        "CITYCODE": "POG",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "POG",
        "AIRPORTNAME": "Port Gentil",
    },
    {
        "CITYNAME": "Patos De Minas",
        "CITYCODE": "POJ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "POJ",
        "AIRPORTNAME": "Patos de Minas Airport",
    },
    {
        "CITYNAME": "Pemba",
        "CITYCODE": "POL",
        "COUNTRYCODE": "MZ",
        "COUNTRYNAME": "Mozambique",
        "AIRPORTCODE": "POL",
        "AIRPORTNAME": "Pemba",
    },
    {
        "CITYNAME": "Port Moresby",
        "CITYCODE": "POM",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "POM",
        "AIRPORTNAME": "Jackson Field",
    },
    {
        "CITYNAME": "Pocos De Caldas",
        "CITYCODE": "POO",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "POO",
        "AIRPORTNAME": "Pocos De Caldas",
    },
    {
        "CITYNAME": "Puerto Plata",
        "CITYCODE": "POP",
        "COUNTRYCODE": "DO",
        "COUNTRYNAME": "Dominican Republic",
        "AIRPORTCODE": "POP",
        "AIRPORTNAME": "La Union",
    },
    {
        "CITYNAME": "Polk Inlet",
        "CITYCODE": "POQ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "POQ",
        "AIRPORTNAME": "Polk Inlet",
    },
    {
        "CITYNAME": "Pori",
        "CITYCODE": "POR",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "POR",
        "AIRPORTNAME": "Pori",
    },
    {
        "CITYNAME": "Port Of Spain",
        "CITYCODE": "POS",
        "COUNTRYCODE": "TT",
        "COUNTRYNAME": "Trinidad and Tobago",
        "AIRPORTCODE": "POS",
        "AIRPORTNAME": "Port Of Spain",
    },
    {
        "CITYNAME": "Port Antonio",
        "CITYCODE": "POT",
        "COUNTRYCODE": "JM",
        "COUNTRYNAME": "Jamaica",
        "AIRPORTCODE": "POT",
        "AIRPORTNAME": "Ken Jones",
    },
    {
        "CITYNAME": "Poughkeepsie",
        "CITYCODE": "POU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "POU",
        "AIRPORTNAME": "Dutchess County",
    },
    {
        "CITYNAME": "Poughkeepsie",
        "CITYCODE": "POU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SWF",
        "AIRPORTNAME": "Newburgh Stewart",
    },
    {
        "CITYNAME": "Portoroz",
        "CITYCODE": "POW",
        "COUNTRYCODE": "SI",
        "COUNTRYNAME": "Slovenia",
        "AIRPORTCODE": "POW",
        "AIRPORTNAME": "Portoroz",
    },
    {
        "CITYNAME": "Poznan",
        "CITYCODE": "POZ",
        "COUNTRYCODE": "PL",
        "COUNTRYNAME": "Poland",
        "AIRPORTCODE": "POZ",
        "AIRPORTNAME": "Poznan",
    },
    {
        "CITYNAME": "Presidente Pruden",
        "CITYCODE": "PPB",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "PPB",
        "AIRPORTNAME": "A. De Barros",
    },
    {
        "CITYNAME": "Puerto Penasco",
        "CITYCODE": "PPE",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "PPE",
        "AIRPORTNAME": "Puerto Penasco",
    },
    {
        "CITYNAME": "Pago Pago",
        "CITYCODE": "PPG",
        "COUNTRYCODE": "AS",
        "COUNTRYNAME": "American Samoa",
        "AIRPORTCODE": "PPG",
        "AIRPORTNAME": "Pago Pago Int'l",
    },
    {
        "CITYNAME": "Pompano Beach",
        "CITYCODE": "PPM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PPM",
        "AIRPORTNAME": "Pompano Beach",
    },
    {
        "CITYNAME": "Popayan",
        "CITYCODE": "PPN",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "PPN",
        "AIRPORTNAME": "Machangara",
    },
    {
        "CITYNAME": "Proserpine",
        "CITYCODE": "PPP",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "PPP",
        "AIRPORTNAME": "Proserpine",
    },
    {
        "CITYNAME": "Paraparaumu",
        "CITYCODE": "PPQ",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "PPQ",
        "AIRPORTNAME": "Paraparaumu",
    },
    {
        "CITYNAME": "Puerto Princesa",
        "CITYCODE": "PPS",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "PPS",
        "AIRPORTNAME": "Puerto Princesa",
    },
    {
        "CITYNAME": "Papeete",
        "CITYCODE": "PPT",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "PPT",
        "AIRPORTNAME": "Faaa",
    },
    {
        "CITYNAME": "Port Protection",
        "CITYCODE": "PPV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PPV",
        "AIRPORTNAME": "Port Protection",
    },
    {
        "CITYNAME": "Papa Westray",
        "CITYCODE": "PPW",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "PPW",
        "AIRPORTNAME": "Papa Westray",
    },
    {
        "CITYNAME": "Phu Quoc",
        "CITYCODE": "PQC",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "PQC",
        "AIRPORTNAME": "Phu Quoc Intl Airport",
    },
    {
        "CITYNAME": "Presque Isle",
        "CITYCODE": "PQI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PQI",
        "AIRPORTNAME": "Presque Isle Municipal",
    },
    {
        "CITYNAME": "Palenque",
        "CITYCODE": "PQM",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "PQM",
        "AIRPORTNAME": "Palenque International Airport",
    },
    {
        "CITYNAME": "Port Macquarie",
        "CITYCODE": "PQQ",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "PQQ",
        "AIRPORTNAME": "Port Macquarie",
    },
    {
        "CITYNAME": "Prescott",
        "CITYCODE": "PRC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PRC",
        "AIRPORTNAME": "Prescott",
    },
    {
        "CITYNAME": "Prague",
        "CITYCODE": "PRG",
        "COUNTRYCODE": "CZ",
        "COUNTRYNAME": "Czech Republic",
        "AIRPORTCODE": "PRG",
        "AIRPORTNAME": "Ruzyne",
    },
    {
        "CITYNAME": "Phrae",
        "CITYCODE": "PRH",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "PRH",
        "AIRPORTNAME": "Phrae",
    },
    {
        "CITYNAME": "Praslin Island",
        "CITYCODE": "PRI",
        "COUNTRYCODE": "SC",
        "COUNTRYNAME": "Seychelles",
        "AIRPORTCODE": "PRI",
        "AIRPORTNAME": "Praslin Island",
    },
    {
        "CITYNAME": "Pristina",
        "CITYCODE": "PRN",
        "COUNTRYCODE": "YU",
        "COUNTRYNAME": "Yugoslavia",
        "AIRPORTCODE": "PRN",
        "AIRPORTNAME": "Pristina",
    },
    {
        "CITYNAME": "Parasi",
        "CITYCODE": "PRS",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "PRS",
        "AIRPORTNAME": "Parasi",
    },
    {
        "CITYNAME": "Paris",
        "CITYCODE": "PRX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PRX",
        "AIRPORTNAME": "Cox Field",
    },
    {
        "CITYNAME": "Pretoria",
        "CITYCODE": "PRY",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "PRY",
        "AIRPORTNAME": "Wonderboom",
    },
    {
        "CITYNAME": "Pisa",
        "CITYCODE": "PSA",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "PSA",
        "AIRPORTNAME": "Galileo Galilei",
    },
    {
        "CITYNAME": "Pasco",
        "CITYCODE": "PSC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PSC",
        "AIRPORTNAME": "Tri-Cities",
    },
    {
        "CITYNAME": "Ponce",
        "CITYCODE": "PSE",
        "COUNTRYCODE": "PR",
        "COUNTRYNAME": "Puerto Rico",
        "AIRPORTCODE": "PSE",
        "AIRPORTNAME": "Mercedita",
    },
    {
        "CITYNAME": "Petersburg",
        "CITYCODE": "PSG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PSG",
        "AIRPORTNAME": "Petersburg Municipal",
    },
    {
        "CITYNAME": "Pasni",
        "CITYCODE": "PSI",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "PSI",
        "AIRPORTNAME": "Pasni",
    },
    {
        "CITYNAME": "Poso",
        "CITYCODE": "PSJ",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "PSJ",
        "AIRPORTNAME": "Poso",
    },
    {
        "CITYNAME": "Palm Springs",
        "CITYCODE": "PSP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PSP",
        "AIRPORTNAME": "Palm Springs Municipal",
    },
    {
        "CITYNAME": "Palm Springs",
        "CITYCODE": "PSP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "UDD",
        "AIRPORTNAME": "Bermuda Dunes",
    },
    {
        "CITYNAME": "Pescara",
        "CITYCODE": "PSR",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "PSR",
        "AIRPORTNAME": "Liberi",
    },
    {
        "CITYNAME": "Posadas",
        "CITYCODE": "PSS",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "PSS",
        "AIRPORTNAME": "Posadas",
    },
    {
        "CITYNAME": "Puerto Suarez",
        "CITYCODE": "PSZ",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "PSZ",
        "AIRPORTNAME": "Puerto Suarez",
    },
    {
        "CITYNAME": "Port Alsworth",
        "CITYCODE": "PTA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PTA",
        "AIRPORTNAME": "Port Alsworth",
    },
    {
        "CITYNAME": "Port Alice",
        "CITYCODE": "PTC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PTC",
        "AIRPORTNAME": "Port Alice",
    },
    {
        "CITYNAME": "Port Alexander",
        "CITYCODE": "PTD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PTD",
        "AIRPORTNAME": "Port Alexander",
    },
    {
        "CITYNAME": "Malololailai",
        "CITYCODE": "PTF",
        "COUNTRYCODE": "FJ",
        "COUNTRYNAME": "Fiji Islands",
        "AIRPORTCODE": "PTF",
        "AIRPORTNAME": "Malololailai",
    },
    {
        "CITYNAME": "Pietersburg",
        "CITYCODE": "PTG",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "PTG",
        "AIRPORTNAME": "Pietersburg",
    },
    {
        "CITYNAME": "Port Heiden",
        "CITYCODE": "PTH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PTH",
        "AIRPORTNAME": "Port Heiden",
    },
    {
        "CITYNAME": "Port Douglas",
        "CITYCODE": "PTI",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "PTI",
        "AIRPORTNAME": "Port Douglas",
    },
    {
        "CITYNAME": "Portland",
        "CITYCODE": "PTJ",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "PTJ",
        "AIRPORTNAME": "Portland",
    },
    {
        "CITYNAME": "Port Armstrong",
        "CITYCODE": "PTL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PTL",
        "AIRPORTNAME": "Port Armstrong",
    },
    {
        "CITYNAME": "Pointe A Pitre",
        "CITYCODE": "PTP",
        "COUNTRYCODE": "GP",
        "COUNTRYNAME": "Guadeloupe",
        "AIRPORTCODE": "PTP",
        "AIRPORTNAME": "Le Raizet",
    },
    {
        "CITYNAME": "Platinum",
        "CITYCODE": "PTU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PTU",
        "AIRPORTNAME": "Platinum",
    },
    {
        "CITYNAME": "Panama City",
        "CITYCODE": "PTY",
        "COUNTRYCODE": "PA",
        "COUNTRYNAME": "Panama",
        "AIRPORTCODE": "PAC",
        "AIRPORTNAME": "Paitilla",
    },
    {
        "CITYNAME": "Panama City",
        "CITYCODE": "PTY",
        "COUNTRYCODE": "PA",
        "COUNTRYNAME": "Panama",
        "AIRPORTCODE": "PTY",
        "AIRPORTNAME": "Tocumen Int'l",
    },
    {
        "CITYNAME": "Pueblo",
        "CITYCODE": "PUB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PUB",
        "AIRPORTNAME": "Pueblo Memorial Airport",
    },
    {
        "CITYNAME": "Price",
        "CITYCODE": "PUC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PUC",
        "AIRPORTNAME": "Carbon County",
    },
    {
        "CITYNAME": "Puerto Deseado",
        "CITYCODE": "PUD",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "PUD",
        "AIRPORTNAME": "Puerto Deseado",
    },
    {
        "CITYNAME": "Pau",
        "CITYCODE": "PUF",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "PUF",
        "AIRPORTNAME": "Uzein",
    },
    {
        "CITYNAME": "Port Augusta",
        "CITYCODE": "PUG",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "PUG",
        "AIRPORTNAME": "Port Augusta",
    },
    {
        "CITYNAME": "Punta Cana",
        "CITYCODE": "PUJ",
        "COUNTRYCODE": "DO",
        "COUNTRYNAME": "Dominican Republic",
        "AIRPORTCODE": "PUJ",
        "AIRPORTNAME": "Punta Cana",
    },
    {
        "CITYNAME": "Pukarua",
        "CITYCODE": "PUK",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "PUK",
        "AIRPORTNAME": "Pukarua",
    },
    {
        "CITYNAME": "Pomala",
        "CITYCODE": "PUM",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "PUM",
        "AIRPORTNAME": "Pomala",
    },
    {
        "CITYNAME": "Punta Arenas",
        "CITYCODE": "PUQ",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "PUQ",
        "AIRPORTNAME": "Pres Ibanez",
    },
    {
        "CITYNAME": "Pusan",
        "CITYCODE": "PUS",
        "COUNTRYCODE": "KR",
        "COUNTRYNAME": "South Korea",
        "AIRPORTCODE": "PUS",
        "AIRPORTNAME": "Kimhae",
    },
    {
        "CITYNAME": "Puttaparthi",
        "CITYCODE": "PUT",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "PUT",
        "AIRPORTNAME": "Puttiparthi",
    },
    {
        "CITYNAME": "Puerto Asis",
        "CITYCODE": "PUU",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "PUU",
        "AIRPORTNAME": "Puerto Asis",
    },
    {
        "CITYNAME": "Pullman",
        "CITYCODE": "PUW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PUW",
        "AIRPORTNAME": "Moscow Regional",
    },
    {
        "CITYNAME": "Pula",
        "CITYCODE": "PUY",
        "COUNTRYCODE": "HR",
        "COUNTRYNAME": "Croatia",
        "AIRPORTCODE": "PUY",
        "AIRPORTNAME": "Pula",
    },
    {
        "CITYNAME": "Puerto Cabezas",
        "CITYCODE": "PUZ",
        "COUNTRYCODE": "NI",
        "COUNTRYNAME": "Nicaragua",
        "AIRPORTCODE": "PUZ",
        "AIRPORTNAME": "Puerto Cabezas",
    },
    {
        "CITYNAME": "Providencia",
        "CITYCODE": "PVA",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "PVA",
        "AIRPORTNAME": "Providencia",
    },
    {
        "CITYNAME": "Provincetown",
        "CITYCODE": "PVC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PVC",
        "AIRPORTNAME": "Provincetown",
    },
    {
        "CITYNAME": "Providence",
        "CITYCODE": "PVD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PVD",
        "AIRPORTNAME": "Theodore Francis",
    },
    {
        "CITYNAME": "Shangai",
        "CITYCODE": "PVG",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "PVG",
        "AIRPORTNAME": "Pu Dong Airport",
    },
    {
        "CITYNAME": "Porto Velho",
        "CITYCODE": "PVH",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "PVH",
        "AIRPORTNAME": "Belmonte",
    },
    {
        "CITYNAME": "Preveza Lefkas",
        "CITYCODE": "PVK",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "PVK",
        "AIRPORTNAME": "Preveza Lefkas",
    },
    {
        "CITYNAME": "Portoviejo",
        "CITYCODE": "PVO",
        "COUNTRYCODE": "EC",
        "COUNTRYNAME": "Ecuador",
        "AIRPORTCODE": "PVO",
        "AIRPORTNAME": "Portoviejo",
    },
    {
        "CITYNAME": "Puerto Vallarta",
        "CITYCODE": "PVR",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "PVR",
        "AIRPORTNAME": "Ordaz",
    },
    {
        "CITYNAME": "Provo",
        "CITYCODE": "PVU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PVU",
        "AIRPORTNAME": "Provo",
    },
    {
        "CITYNAME": "Pawi",
        "CITYCODE": "PWI",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "PWI",
        "AIRPORTNAME": "Beles",
    },
    {
        "CITYNAME": "Portland",
        "CITYCODE": "PWM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "PWM",
        "AIRPORTNAME": "Portland Int'l Jetport",
    },
    {
        "CITYNAME": "Pavlodar",
        "CITYCODE": "PWQ",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "PWQ",
        "AIRPORTNAME": "Pavlodar",
    },
    {
        "CITYNAME": "Puerto Escondido",
        "CITYCODE": "PXM",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "PXM",
        "AIRPORTNAME": "Puerto Escondido",
    },
    {
        "CITYNAME": "Porto Santo",
        "CITYCODE": "PXO",
        "COUNTRYCODE": "PT",
        "COUNTRYNAME": "Portugal",
        "AIRPORTCODE": "PXO",
        "AIRPORTNAME": "Porto Santo",
    },
    {
        "CITYNAME": "Pleiku",
        "CITYCODE": "PXU",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "PXU",
        "AIRPORTNAME": "Pleiku Airport",
    },
    {
        "CITYNAME": "Penrhyn Island",
        "CITYCODE": "PYE",
        "COUNTRYCODE": "CK",
        "COUNTRYNAME": "Cook Islands",
        "AIRPORTCODE": "PYE",
        "AIRPORTNAME": "Penrhyn Island",
    },
    {
        "CITYNAME": "Pakyong",
        "CITYCODE": "PYG",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "PYG",
        "AIRPORTNAME": "Pakyong airport",
    },
    {
        "CITYNAME": "Puerto Ayacucho",
        "CITYCODE": "PYH",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "PYH",
        "AIRPORTNAME": "Puerto Ayacucho",
    },
    {
        "CITYNAME": "Pyrgos",
        "CITYCODE": "PYR",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "PYR",
        "AIRPORTNAME": "Andravida",
    },
    {
        "CITYNAME": "Paz De Ariporo",
        "CITYCODE": "PZA",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "PZA",
        "AIRPORTNAME": "Paz De Ariporo",
    },
    {
        "CITYNAME": "Pietermaritzburg",
        "CITYCODE": "PZB",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "PZB",
        "AIRPORTNAME": "Pietermaritzburg",
    },
    {
        "CITYNAME": "Penzance",
        "CITYCODE": "PZE",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "PZE",
        "AIRPORTNAME": "Penzance",
    },
    {
        "CITYNAME": "Zhob",
        "CITYCODE": "PZH",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "PZH",
        "AIRPORTNAME": "Zhob",
    },
    {
        "CITYNAME": "Puerto Ordaz",
        "CITYCODE": "PZO",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "PZO",
        "AIRPORTNAME": "Puerto Ordaz",
    },
    {
        "CITYNAME": "Port Sudan",
        "CITYCODE": "PZU",
        "COUNTRYCODE": "SD",
        "COUNTRYNAME": "Sudan",
        "AIRPORTCODE": "PZU",
        "AIRPORTNAME": "Port Sudan",
    },
    {
        "CITYNAME": "Piestany",
        "CITYCODE": "PZY",
        "COUNTRYCODE": "SK",
        "COUNTRYNAME": "Slovakia",
        "AIRPORTCODE": "PZY",
        "AIRPORTNAME": "Piestany",
    },
    {
        "CITYNAME": "Bel Abbes",
        "CITYCODE": "QBB",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "QBB",
        "AIRPORTNAME": "Airlines Serving Sidi Bel Abbes Airport",
    },
    {
        "CITYNAME": "Bella Coola",
        "CITYCODE": "QBC",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "QBC",
        "AIRPORTNAME": "Bella Coola",
    },
    {
        "CITYNAME": "Dusseldorf",
        "CITYCODE": "QDU",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "QDU",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Leicester",
        "CITYCODE": "QEW",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "QEW",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Freiburg",
        "CITYCODE": "QFB",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "QFB",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Cologne",
        "CITYCODE": "QKL",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "QKL",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Kruger National P",
        "CITYCODE": "QKP",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "MQP",
        "AIRPORTNAME": "Kruger Mpumalanga International Airport",
    },
    {
        "CITYNAME": "KÃÂ¶ln-Deutz Bhf Messe",
        "CITYCODE": "QKU",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "QKU",
        "AIRPORTNAME": "KÃÂ¶ln-Deutz",
    },
    {
        "CITYNAME": "Lucerne",
        "CITYCODE": "QLJ",
        "COUNTRYCODE": "CH",
        "COUNTRYNAME": "Switzerland",
        "AIRPORTCODE": "QLJ",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Padova",
        "CITYCODE": "QPA",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "QPA",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Prato",
        "CITYCODE": "QPR",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "QPR",
        "AIRPORTNAME": " PRATO",
    },
    {
        "CITYNAME": "Manchester",
        "CITYCODE": "QQM",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "QQM",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Britrail Rail Zone S",
        "CITYCODE": "QQS",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "QQS",
        "AIRPORTNAME": "London St. Pancras Airport",
    },
    {
        "CITYNAME": "London",
        "CITYCODE": "QQU",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "QQU",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Bath",
        "CITYCODE": "QQX",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "QQX",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "York",
        "CITYCODE": "QQY",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "QQY",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Rotterdam",
        "CITYCODE": "QRH",
        "COUNTRYCODE": "NL",
        "COUNTRYNAME": "Netherlands",
        "AIRPORTCODE": "QRH",
        "AIRPORTNAME": "Rotterdam Central Station",
    },
    {
        "CITYNAME": "Rize",
        "CITYCODE": "QRI",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "RZV",
        "AIRPORTNAME": "Rize Artvin Airport",
    },
    {
        "CITYNAME": "Queretaro",
        "CITYCODE": "QRO",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "QRO",
        "AIRPORTNAME": "Queretaro Intl Airport",
    },
    {
        "CITYNAME": "Resende",
        "CITYCODE": "QRZ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "QRZ",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Setif",
        "CITYCODE": "QSF",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "QSF",
        "AIRPORTNAME": "Ain Arnat Airport",
    },
    {
        "CITYNAME": "Ulm",
        "CITYCODE": "QUL",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "QUL",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Railway Germany",
        "CITYCODE": "QYG",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "QYG",
        "AIRPORTNAME": "Railway germany",
    },
    {
        "CITYNAME": "Uppsala",
        "CITYCODE": "QYX",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "QYX",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Rabaul",
        "CITYCODE": "RAB",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "RAB",
        "AIRPORTNAME": "Lakunai",
    },
    {
        "CITYNAME": "Arar",
        "CITYCODE": "RAE",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "RAE",
        "AIRPORTNAME": " ARAR",
    },
    {
        "CITYNAME": "Ras An Naqb",
        "CITYCODE": "RAF",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "RAF",
        "AIRPORTNAME": "Ras An Naqb",
    },
    {
        "CITYNAME": "Rafha",
        "CITYCODE": "RAH",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "RAH",
        "AIRPORTNAME": "Rafha",
    },
    {
        "CITYNAME": "Praia",
        "CITYCODE": "RAI",
        "COUNTRYCODE": "CV",
        "COUNTRYNAME": "Cape Verde",
        "AIRPORTCODE": "RAI",
        "AIRPORTNAME": "Francisco Mendes",
    },
    {
        "CITYNAME": "Rajkot",
        "CITYCODE": "RAJ",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "RAJ",
        "AIRPORTNAME": "Rajkot Civil",
    },
    {
        "CITYNAME": "Marrakech",
        "CITYCODE": "RAK",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "RAK",
        "AIRPORTNAME": "Menara",
    },
    {
        "CITYNAME": "Riverside",
        "CITYCODE": "RAL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RAL",
        "AIRPORTNAME": "Riverside Municipal",
    },
    {
        "CITYNAME": "Riverside",
        "CITYCODE": "RAL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RIR",
        "AIRPORTNAME": "Riverside Fla-Bob",
    },
    {
        "CITYNAME": "Ramingining",
        "CITYCODE": "RAM",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "RAM",
        "AIRPORTNAME": "Ramingining",
    },
    {
        "CITYNAME": "Ribeirao Preto",
        "CITYCODE": "RAO",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "RAO",
        "AIRPORTNAME": "Leite Lopes",
    },
    {
        "CITYNAME": "Rapid City",
        "CITYCODE": "RAP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RAP",
        "AIRPORTNAME": "Rapid City Regional",
    },
    {
        "CITYNAME": "Rarotonga",
        "CITYCODE": "RAR",
        "COUNTRYCODE": "CK",
        "COUNTRYNAME": "Cook Islands",
        "AIRPORTCODE": "RAR",
        "AIRPORTNAME": "Rarotonga",
    },
    {
        "CITYNAME": "Rasht",
        "CITYCODE": "RAS",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "RAS",
        "AIRPORTNAME": "Rasht",
    },
    {
        "CITYNAME": "Cravo Norte",
        "CITYCODE": "RAV",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "RAV",
        "AIRPORTNAME": "Cravo Norte",
    },
    {
        "CITYNAME": "Rawala Kot",
        "CITYCODE": "RAZ",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "RAZ",
        "AIRPORTNAME": "Rawala Kot",
    },
    {
        "CITYNAME": "Rabat",
        "CITYCODE": "RBA",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "RBA",
        "AIRPORTNAME": "Sale",
    },
    {
        "CITYNAME": "Rebun",
        "CITYCODE": "RBJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "RBJ",
        "AIRPORTNAME": "Rebun",
    },
    {
        "CITYNAME": "Rabaraba",
        "CITYCODE": "RBP",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "RBP",
        "AIRPORTNAME": "Rabaraba",
    },
    {
        "CITYNAME": "Rurrenabaque",
        "CITYCODE": "RBQ",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "RBQ",
        "AIRPORTNAME": "Rurrenabaque",
    },
    {
        "CITYNAME": "Rio Branco",
        "CITYCODE": "RBR",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "RBR",
        "AIRPORTNAME": "Pres. Medici",
    },
    {
        "CITYNAME": "Orbost",
        "CITYCODE": "RBS",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "RBS",
        "AIRPORTNAME": "Orbost",
    },
    {
        "CITYNAME": "Marsabit",
        "CITYCODE": "RBT",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "RBT",
        "AIRPORTNAME": "Marsabit",
    },
    {
        "CITYNAME": "Ruby",
        "CITYCODE": "RBY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RBY",
        "AIRPORTNAME": "Ruby",
    },
    {
        "CITYNAME": "Richards Bay",
        "CITYCODE": "RCB",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "RCB",
        "AIRPORTNAME": "Richards Bay",
    },
    {
        "CITYNAME": "Roche Harbor",
        "CITYCODE": "RCE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RCE",
        "AIRPORTNAME": "Roche Harbor",
    },
    {
        "CITYNAME": "Riohacha",
        "CITYCODE": "RCH",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "RCH",
        "AIRPORTNAME": "Riohacha",
    },
    {
        "CITYNAME": "Richmond",
        "CITYCODE": "RCM",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "RCM",
        "AIRPORTNAME": "Richmond",
    },
    {
        "CITYNAME": "American River",
        "CITYCODE": "RCN",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "RCN",
        "AIRPORTNAME": "American River",
    },
    {
        "CITYNAME": "Rochefort",
        "CITYCODE": "RCO",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "RCO",
        "AIRPORTNAME": "Rochefort Saint Agnant Airport",
    },
    {
        "CITYNAME": "Rio Cuarto",
        "CITYCODE": "RCU",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "RCU",
        "AIRPORTNAME": "Rio Cuarto",
    },
    {
        "CITYNAME": "Redencao",
        "CITYCODE": "RDC",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "RDC",
        "AIRPORTNAME": "Redencao",
    },
    {
        "CITYNAME": "Redding",
        "CITYCODE": "RDD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RDD",
        "AIRPORTNAME": "Redding",
    },
    {
        "CITYNAME": "Merdey",
        "CITYCODE": "RDE",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "RDE",
        "AIRPORTNAME": "Merdey",
    },
    {
        "CITYNAME": "Reading",
        "CITYCODE": "RDG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RDG",
        "AIRPORTNAME": "Reading Municipal/Spaatz Field",
    },
    {
        "CITYNAME": "Redmond",
        "CITYCODE": "RDM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RDM",
        "AIRPORTNAME": "Roberts Field",
    },
    {
        "CITYNAME": "Durgapur",
        "CITYCODE": "RDP",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "RDP",
        "AIRPORTNAME": "Durgapur",
    },
    {
        "CITYNAME": "Richard Toll",
        "CITYCODE": "RDT",
        "COUNTRYCODE": "SN",
        "COUNTRYNAME": "Senegal",
        "AIRPORTCODE": "RDT",
        "AIRPORTNAME": "Richard Toll",
    },
    {
        "CITYNAME": "Raleigh Durham",
        "CITYCODE": "RDU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RDU",
        "AIRPORTNAME": "Raleigh Durham",
    },
    {
        "CITYNAME": "Red Devil",
        "CITYCODE": "RDV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RDV",
        "AIRPORTNAME": "Red Devil",
    },
    {
        "CITYNAME": "Rodez",
        "CITYCODE": "RDZ",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "RDZ",
        "AIRPORTNAME": "Marcillac",
    },
    {
        "CITYNAME": "Reao",
        "CITYCODE": "REA",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "REA",
        "AIRPORTNAME": "Reao",
    },
    {
        "CITYNAME": "Recife",
        "CITYCODE": "REC",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "REC",
        "AIRPORTNAME": "Guararapes Int'l",
    },
    {
        "CITYNAME": "Reggio Calabria",
        "CITYCODE": "REG",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "REG",
        "AIRPORTNAME": "Tito Menniti",
    },
    {
        "CITYNAME": "Reykjavik",
        "CITYCODE": "REK",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "KEF",
        "AIRPORTNAME": "Keflavik Int'l",
    },
    {
        "CITYNAME": "Reykjavik",
        "CITYCODE": "REK",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "REK",
        "AIRPORTNAME": "Reykjavik Airport",
    },
    {
        "CITYNAME": "Reykjavik",
        "CITYCODE": "REK",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "RKV",
        "AIRPORTNAME": "Reykjavik Airport Domestic",
    },
    {
        "CITYNAME": "Trelew",
        "CITYCODE": "REL",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "REL",
        "AIRPORTNAME": "Trelew",
    },
    {
        "CITYNAME": "Orenburg",
        "CITYCODE": "REN",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "REN",
        "AIRPORTNAME": "Orenburg",
    },
    {
        "CITYNAME": "Siem Reap",
        "CITYCODE": "REP",
        "COUNTRYCODE": "KH",
        "COUNTRYNAME": "Cambodia",
        "AIRPORTCODE": "REP",
        "AIRPORTNAME": "Siem Reap",
    },
    {
        "CITYNAME": "Resistencia",
        "CITYCODE": "RES",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "RES",
        "AIRPORTNAME": "Resistencia",
    },
    {
        "CITYNAME": "Rost",
        "CITYCODE": "RET",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "RET",
        "AIRPORTNAME": "Stolport",
    },
    {
        "CITYNAME": "Reus",
        "CITYCODE": "REU",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "REU",
        "AIRPORTNAME": "Reus",
    },
    {
        "CITYNAME": "Rewa",
        "CITYCODE": "REW",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "REW",
        "AIRPORTNAME": "Rewa",
    },
    {
        "CITYNAME": "Reynosa",
        "CITYCODE": "REX",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "REX",
        "AIRPORTNAME": "Gen Lucio Blanco",
    },
    {
        "CITYNAME": "Resende",
        "CITYCODE": "REZ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "REZ",
        "AIRPORTNAME": "Resende Airport",
    },
    {
        "CITYNAME": "Rafai",
        "CITYCODE": "RFA",
        "COUNTRYCODE": "CF",
        "COUNTRYNAME": "Central African Republic",
        "AIRPORTCODE": "RFA",
        "AIRPORTNAME": "Rafai",
    },
    {
        "CITYNAME": "Rockford",
        "CITYCODE": "RFD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RFD",
        "AIRPORTNAME": "Greater Rockford",
    },
    {
        "CITYNAME": "Raufarhofn",
        "CITYCODE": "RFN",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "RFN",
        "AIRPORTNAME": "Raufarhofn",
    },
    {
        "CITYNAME": "Raiatea",
        "CITYCODE": "RFP",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "RFP",
        "AIRPORTNAME": "Raiatea",
    },
    {
        "CITYNAME": "Rio Grande",
        "CITYCODE": "RGA",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "RGA",
        "AIRPORTNAME": "Rio Grande",
    },
    {
        "CITYNAME": "Porgera",
        "CITYCODE": "RGE",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "RGE",
        "AIRPORTNAME": "Porgera",
    },
    {
        "CITYNAME": "Rangiroa",
        "CITYCODE": "RGI",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "RGI",
        "AIRPORTNAME": "Rangiroa",
    },
    {
        "CITYNAME": "Gorno",
        "CITYCODE": "RGK",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "RGK",
        "AIRPORTNAME": "Gorno - Altaysk Airport",
    },
    {
        "CITYNAME": "Rio Gallegos",
        "CITYCODE": "RGL",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "RGL",
        "AIRPORTNAME": "Rio Gallegos Int'l",
    },
    {
        "CITYNAME": "Yangon",
        "CITYCODE": "RGN",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "RGN",
        "AIRPORTNAME": "Mingaladon",
    },
    {
        "CITYNAME": "Rengat",
        "CITYCODE": "RGT",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "RGT",
        "AIRPORTNAME": "Japura",
    },
    {
        "CITYNAME": "Reims",
        "CITYCODE": "RHE",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "RHE",
        "AIRPORTNAME": "Reims",
    },
    {
        "CITYNAME": "Ruhengeri",
        "CITYCODE": "RHG",
        "COUNTRYCODE": "RW",
        "COUNTRYNAME": "Ruanda",
        "AIRPORTCODE": "RHG",
        "AIRPORTNAME": "Ruhengeri",
    },
    {
        "CITYNAME": "Rhinelander",
        "CITYCODE": "RHI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RHI",
        "AIRPORTNAME": "Oneide County",
    },
    {
        "CITYNAME": "Rhodes",
        "CITYCODE": "RHO",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "RHO",
        "AIRPORTNAME": "Diagoras",
    },
    {
        "CITYNAME": "Santa Maria",
        "CITYCODE": "RIA",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "RIA",
        "AIRPORTNAME": "Base Aerea",
    },
    {
        "CITYNAME": "Riberalta",
        "CITYCODE": "RIB",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "RIB",
        "AIRPORTNAME": "Gen Buech",
    },
    {
        "CITYNAME": "Richmond",
        "CITYCODE": "RIC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RIC",
        "AIRPORTNAME": "Richmond Int'l",
    },
    {
        "CITYNAME": "Richfield",
        "CITYCODE": "RIF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RIF",
        "AIRPORTNAME": "Reynolds",
    },
    {
        "CITYNAME": "Rio Grande",
        "CITYCODE": "RIG",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "RIG",
        "AIRPORTNAME": "Rio Grande",
    },
    {
        "CITYNAME": "Cocle",
        "CITYCODE": "RIH",
        "COUNTRYCODE": "PA",
        "COUNTRYNAME": "Panama",
        "AIRPORTCODE": "RIH",
        "AIRPORTNAME": "Rio Hato Airport",
    },
    {
        "CITYNAME": "Rioja",
        "CITYCODE": "RIJ",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "RIJ",
        "AIRPORTNAME": "Rioja",
    },
    {
        "CITYNAME": "Ringi Cove",
        "CITYCODE": "RIN",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "RIN",
        "AIRPORTNAME": "Ringi Cove",
    },
    {
        "CITYNAME": "Rio De Janeiro",
        "CITYCODE": "RIO",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "GIG",
        "AIRPORTNAME": "Rio De Janeiro Int'l",
    },
    {
        "CITYNAME": "Rio De Janeiro",
        "CITYCODE": "RIO",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "SDU",
        "AIRPORTNAME": "Santos Dumont",
    },
    {
        "CITYNAME": "Rishiri",
        "CITYCODE": "RIS",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "RIS",
        "AIRPORTNAME": "Rishiri",
    },
    {
        "CITYNAME": "Riverton",
        "CITYCODE": "RIW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RIW",
        "AIRPORTNAME": "Riverton",
    },
    {
        "CITYNAME": "Riga",
        "CITYCODE": "RIX",
        "COUNTRYCODE": "LV",
        "COUNTRYNAME": "Latvia",
        "AIRPORTCODE": "RIX",
        "AIRPORTNAME": "Riga",
    },
    {
        "CITYNAME": "Mukalla",
        "CITYCODE": "RIY",
        "COUNTRYCODE": "YE",
        "COUNTRYNAME": "Yemen",
        "AIRPORTCODE": "RIY",
        "AIRPORTNAME": "Riyan",
    },
    {
        "CITYNAME": "Rajahmundry",
        "CITYCODE": "RJA",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "RJA",
        "AIRPORTNAME": "Rajahmundry",
    },
    {
        "CITYNAME": "Rajshahi",
        "CITYCODE": "RJH",
        "COUNTRYCODE": "BD",
        "COUNTRYNAME": "Bangladesh",
        "AIRPORTCODE": "RJH",
        "AIRPORTNAME": "Rajshahi",
    },
    {
        "CITYNAME": "Rajouri",
        "CITYCODE": "RJI",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "RJI",
        "AIRPORTNAME": "Rajouri",
    },
    {
        "CITYNAME": "Rijeka",
        "CITYCODE": "RJK",
        "COUNTRYCODE": "HR",
        "COUNTRYNAME": "Croatia",
        "AIRPORTCODE": "RJK",
        "AIRPORTNAME": "Rijeka",
    },
    {
        "CITYNAME": "Logrono",
        "CITYCODE": "RJL",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "RJL",
        "AIRPORTNAME": "LogronoÃ¢â¬âAgoncillo Airport",
    },
    {
        "CITYNAME": "Rockland",
        "CITYCODE": "RKD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RKD",
        "AIRPORTNAME": "Knox County Regional",
    },
    {
        "CITYNAME": "Rock Springs",
        "CITYCODE": "RKS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RKS",
        "AIRPORTNAME": "Sweetwater County",
    },
    {
        "CITYNAME": "Ras Al Khaimah",
        "CITYCODE": "RKT",
        "COUNTRYCODE": "AE",
        "COUNTRYNAME": "United Arab Emirates",
        "AIRPORTCODE": "RKT",
        "AIRPORTNAME": "Ras Al Khaimah",
    },
    {
        "CITYNAME": "Rostock Laage",
        "CITYCODE": "RLG",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "RLG",
        "AIRPORTNAME": "Rostock - Laage Airport",
    },
    {
        "CITYNAME": "Roma",
        "CITYCODE": "RMA",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "RMA",
        "AIRPORTNAME": "Roma",
    },
    {
        "CITYNAME": "Marsa Alam",
        "CITYCODE": "RMF",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "RMF",
        "AIRPORTNAME": " Marsa Alam International",
    },
    {
        "CITYNAME": "Rimini",
        "CITYCODE": "RMI",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "RMI",
        "AIRPORTNAME": "Miramare",
    },
    {
        "CITYNAME": "taichung",
        "CITYCODE": "RMQ",
        "COUNTRYCODE": "TW",
        "COUNTRYNAME": "Taiwan",
        "AIRPORTCODE": "RMQ",
        "AIRPORTNAME": "Taichung",
    },
    {
        "CITYNAME": "Ronneby",
        "CITYCODE": "RNB",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "RNB",
        "AIRPORTNAME": "Kallinge",
    },
    {
        "CITYNAME": "Mcminnville",
        "CITYCODE": "RNC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RNC",
        "AIRPORTNAME": "WARREN CNTY",
    },
    {
        "CITYNAME": "Roanne",
        "CITYCODE": "RNE",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "RNE",
        "AIRPORTNAME": "Renaison",
    },
    {
        "CITYNAME": "Yoronjima",
        "CITYCODE": "RNJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "RNJ",
        "AIRPORTNAME": "Yoronjima",
    },
    {
        "CITYNAME": "SURFACE",
        "CITYCODE": "RNK",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "RNK",
        "AIRPORTNAME": "RNK Airport",
    },
    {
        "CITYNAME": "Rennell",
        "CITYCODE": "RNL",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "RNL",
        "AIRPORTNAME": "Rennell",
    },
    {
        "CITYNAME": "Bornholm",
        "CITYCODE": "RNN",
        "COUNTRYCODE": "DK",
        "COUNTRYNAME": "Denmark",
        "AIRPORTCODE": "RNN",
        "AIRPORTNAME": "Bornholm",
    },
    {
        "CITYNAME": "Reno",
        "CITYCODE": "RNO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RNO",
        "AIRPORTNAME": "Tahoe Intl.",
    },
    {
        "CITYNAME": "Robinson River",
        "CITYCODE": "RNR",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "RNR",
        "AIRPORTNAME": "Robinson River",
    },
    {
        "CITYNAME": "Rennes",
        "CITYCODE": "RNS",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "RNS",
        "AIRPORTNAME": "St Jacques",
    },
    {
        "CITYNAME": "Renton",
        "CITYCODE": "RNT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RNT",
        "AIRPORTNAME": "Renton",
    },
    {
        "CITYNAME": "Roanoke",
        "CITYCODE": "ROA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ROA",
        "AIRPORTNAME": "Roanoke Municipal",
    },
    {
        "CITYNAME": "Rochester",
        "CITYCODE": "ROC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ROC",
        "AIRPORTNAME": "Monroe County",
    },
    {
        "CITYNAME": "Roi Et",
        "CITYCODE": "ROI",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "ROI",
        "AIRPORTNAME": "Roi Et",
    },
    {
        "CITYNAME": "Rockhampton",
        "CITYCODE": "ROK",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "ROK",
        "AIRPORTNAME": "Rockhampton",
    },
    {
        "CITYNAME": "Rome",
        "CITYCODE": "ROM",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "CIA",
        "AIRPORTNAME": "Rome Ciampino",
    },
    {
        "CITYNAME": "Rome",
        "CITYCODE": "ROM",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "FCO",
        "AIRPORTNAME": "Fiumicino",
    },
    {
        "CITYNAME": "Rome",
        "CITYCODE": "ROM",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "XRJ",
        "AIRPORTNAME": "Termini Rail Stn. Airport",
    },
    {
        "CITYNAME": "Rondon",
        "CITYCODE": "RON",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "RON",
        "AIRPORTNAME": "Rondon",
    },
    {
        "CITYNAME": "Rondonopolis",
        "CITYCODE": "ROO",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "ROO",
        "AIRPORTNAME": "Rondonopolis",
    },
    {
        "CITYNAME": "Rota",
        "CITYCODE": "ROP",
        "COUNTRYCODE": "MP",
        "COUNTRYNAME": "Northern Mariana Islands",
        "AIRPORTCODE": "ROP",
        "AIRPORTNAME": "Rota",
    },
    {
        "CITYNAME": "Koror",
        "CITYCODE": "ROR",
        "COUNTRYCODE": "PW",
        "COUNTRYNAME": "Palau",
        "AIRPORTCODE": "ROR",
        "AIRPORTNAME": "Airai",
    },
    {
        "CITYNAME": "Rosario",
        "CITYCODE": "ROS",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "ROS",
        "AIRPORTNAME": "Fisherton",
    },
    {
        "CITYNAME": "Rotorua",
        "CITYCODE": "ROT",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "ROT",
        "AIRPORTNAME": "Rotorua",
    },
    {
        "CITYNAME": "Rousse",
        "CITYCODE": "ROU",
        "COUNTRYCODE": "BG",
        "COUNTRYNAME": "Bulgaria",
        "AIRPORTCODE": "ROU",
        "AIRPORTNAME": "Rousse",
    },
    {
        "CITYNAME": "Rostov",
        "CITYCODE": "ROV",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "ROV",
        "AIRPORTNAME": "Rostov",
    },
    {
        "CITYNAME": "Roswell",
        "CITYCODE": "ROW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ROW",
        "AIRPORTNAME": "Roswell Industrial",
    },
    {
        "CITYNAME": "Rio Mayo",
        "CITYCODE": "ROY",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "ROY",
        "AIRPORTNAME": "Rio Mayo",
    },
    {
        "CITYNAME": "Ngukurr",
        "CITYCODE": "RPM",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "RPM",
        "AIRPORTNAME": "Ngukurr",
    },
    {
        "CITYNAME": "Rosh Pina",
        "CITYCODE": "RPN",
        "COUNTRYCODE": "IL",
        "COUNTRYNAME": "Israel",
        "AIRPORTCODE": "RPN",
        "AIRPORTNAME": "Rosh Pina",
    },
    {
        "CITYNAME": "Raipur",
        "CITYCODE": "RPR",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "RPR",
        "AIRPORTNAME": "Swami Vivekananda Airport",
    },
    {
        "CITYNAME": "shivmogga",
        "CITYCODE": "RQY",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "RQY",
        "AIRPORTNAME": "shivmogga Airport",
    },
    {
        "CITYNAME": "Rodrigues Island",
        "CITYCODE": "RRG",
        "COUNTRYCODE": "MU",
        "COUNTRYNAME": "Mauritius",
        "AIRPORTCODE": "RRG",
        "AIRPORTNAME": "Rodrigues Island",
    },
    {
        "CITYNAME": "Rourkela",
        "CITYCODE": "RRK",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "RRK",
        "AIRPORTNAME": "Rourkela",
    },
    {
        "CITYNAME": "Sorrento",
        "CITYCODE": "RRO",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "RRO",
        "AIRPORTNAME": "Sorrento Airport",
    },
    {
        "CITYNAME": "Roros",
        "CITYCODE": "RRS",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "RRS",
        "AIRPORTNAME": "Roros",
    },
    {
        "CITYNAME": "Santa Rosa",
        "CITYCODE": "RSA",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "RSA",
        "AIRPORTNAME": "Santa Rosa",
    },
    {
        "CITYNAME": "Rock Sound",
        "CITYCODE": "RSD",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "RSD",
        "AIRPORTNAME": "S Eleuther",
    },
    {
        "CITYNAME": "Russian Mission",
        "CITYCODE": "RSH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RSH",
        "AIRPORTNAME": "Russian Mission",
    },
    {
        "CITYNAME": "Rio Sidra",
        "CITYCODE": "RSI",
        "COUNTRYCODE": "PA",
        "COUNTRYNAME": "Panama",
        "AIRPORTCODE": "RSI",
        "AIRPORTNAME": "Rio Sidra Airport",
    },
    {
        "CITYNAME": "Roseires",
        "CITYCODE": "RSS",
        "COUNTRYCODE": "SD",
        "COUNTRYNAME": "Sudan",
        "AIRPORTCODE": "RSS",
        "AIRPORTNAME": "Roseires",
    },
    {
        "CITYNAME": "Rochester",
        "CITYCODE": "RST",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RST",
        "AIRPORTNAME": "Rochester Municipal",
    },
    {
        "CITYNAME": "Yosu",
        "CITYCODE": "RSU",
        "COUNTRYCODE": "KR",
        "COUNTRYNAME": "South Korea",
        "AIRPORTCODE": "RSU",
        "AIRPORTNAME": "Yosu",
    },
    {
        "CITYNAME": "Rotuma Island",
        "CITYCODE": "RTA",
        "COUNTRYCODE": "FJ",
        "COUNTRYNAME": "Fiji Islands",
        "AIRPORTCODE": "RTA",
        "AIRPORTNAME": "Rotuma Island",
    },
    {
        "CITYNAME": "Roatan",
        "CITYCODE": "RTB",
        "COUNTRYCODE": "HN",
        "COUNTRYNAME": "Honduras",
        "AIRPORTCODE": "RTB",
        "AIRPORTNAME": "Roatan",
    },
    {
        "CITYNAME": "Ruteng",
        "CITYCODE": "RTG",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "RTG",
        "AIRPORTNAME": "Ruteng",
    },
    {
        "CITYNAME": "Roti",
        "CITYCODE": "RTI",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "RTI",
        "AIRPORTNAME": "Roti",
    },
    {
        "CITYNAME": "Rotterdam",
        "CITYCODE": "RTM",
        "COUNTRYCODE": "NL",
        "COUNTRYNAME": "Netherlands",
        "AIRPORTCODE": "RTM",
        "AIRPORTNAME": "Rotterdam",
    },
    {
        "CITYNAME": "Rottnest Island",
        "CITYCODE": "RTS",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "RTS",
        "AIRPORTNAME": "Rottnest Island",
    },
    {
        "CITYNAME": "Saratov",
        "CITYCODE": "RTW",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "RTW",
        "AIRPORTNAME": "Saratov",
    },
    {
        "CITYNAME": "Arua",
        "CITYCODE": "RUA",
        "COUNTRYCODE": "UG",
        "COUNTRYNAME": "Uganda",
        "AIRPORTCODE": "RUA",
        "AIRPORTNAME": "Arua",
    },
    {
        "CITYNAME": "Riyadh",
        "CITYCODE": "RUH",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "RUH",
        "AIRPORTNAME": "King Khaled Int'l",
    },
    {
        "CITYNAME": "Riyadh",
        "CITYCODE": "RUH",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "RUJ",
        "AIRPORTNAME": "RIYADH",
    },
    {
        "CITYNAME": "St Denis",
        "CITYCODE": "RUN",
        "COUNTRYCODE": "RE",
        "COUNTRYNAME": "Reunion",
        "AIRPORTCODE": "RUN",
        "AIRPORTNAME": "Gillot",
    },
    {
        "CITYNAME": "Rupsi",
        "CITYCODE": "RUP",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "RUP",
        "AIRPORTNAME": "Rupsi",
    },
    {
        "CITYNAME": "Rurutu",
        "CITYCODE": "RUR",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "RUR",
        "AIRPORTNAME": "Rurutu",
    },
    {
        "CITYNAME": "Marau Sound",
        "CITYCODE": "RUS",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "RUS",
        "AIRPORTNAME": "Marau Sound",
    },
    {
        "CITYNAME": "Rutland",
        "CITYCODE": "RUT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RUT",
        "AIRPORTNAME": "Rutland Southern Vermont Airport",
    },
    {
        "CITYNAME": "Farafangana",
        "CITYCODE": "RVA",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "RVA",
        "AIRPORTNAME": "Farafangana",
    },
    {
        "CITYNAME": "Rio Verde",
        "CITYCODE": "RVD",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "RVD",
        "AIRPORTNAME": "Rio Verde Airport",
    },
    {
        "CITYNAME": "Saravena",
        "CITYCODE": "RVE",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "RVE",
        "AIRPORTNAME": "Saravena",
    },
    {
        "CITYNAME": "Roervik",
        "CITYCODE": "RVK",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "RVK",
        "AIRPORTNAME": "Ryumsjoen",
    },
    {
        "CITYNAME": "Rovaniemi",
        "CITYCODE": "RVN",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "RVN",
        "AIRPORTNAME": "Rovaniemi",
    },
    {
        "CITYNAME": "Ravensthorpe",
        "CITYCODE": "RVT",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "RVT",
        "AIRPORTNAME": "Ravensthorpe Airport",
    },
    {
        "CITYNAME": "Rivera",
        "CITYCODE": "RVY",
        "COUNTRYCODE": "UY",
        "COUNTRYNAME": "Uruguay",
        "AIRPORTCODE": "RVY",
        "AIRPORTNAME": "Rivera",
    },
    {
        "CITYNAME": "Rowan Bay",
        "CITYCODE": "RWB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RWB",
        "AIRPORTNAME": "Rowan Bay",
    },
    {
        "CITYNAME": "Rocky Mount",
        "CITYCODE": "RWI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "RWI",
        "AIRPORTNAME": "Rocky Mount-Wilson",
    },
    {
        "CITYNAME": "Roxas City",
        "CITYCODE": "RXS",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "RXS",
        "AIRPORTNAME": "Roxas Airport",
    },
    {
        "CITYNAME": "Rahim Yar Khan",
        "CITYCODE": "RYK",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "RYK",
        "AIRPORTNAME": "Rahim Yar Khan",
    },
    {
        "CITYNAME": "Rio Turbio",
        "CITYCODE": "RYO",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "RYO",
        "AIRPORTNAME": "Rio Turbio",
    },
    {
        "CITYNAME": "Santa Cruz",
        "CITYCODE": "RZA",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "RZA",
        "AIRPORTNAME": "Santa Cruz",
    },
    {
        "CITYNAME": "Rzeszow",
        "CITYCODE": "RZE",
        "COUNTRYCODE": "PL",
        "COUNTRYNAME": "Poland",
        "AIRPORTCODE": "RZE",
        "AIRPORTNAME": "Jasionka",
    },
    {
        "CITYNAME": "Saba Island",
        "CITYCODE": "SAB",
        "COUNTRYCODE": "AN",
        "COUNTRYNAME": "Netherlands Antilles",
        "AIRPORTCODE": "SAB",
        "AIRPORTNAME": "J. Yrausquin",
    },
    {
        "CITYNAME": "Sacramento",
        "CITYCODE": "SAC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SAC",
        "AIRPORTNAME": "Sacramento Executive",
    },
    {
        "CITYNAME": "Sacramento",
        "CITYCODE": "SAC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SMF",
        "AIRPORTNAME": "Sacramento",
    },
    {
        "CITYNAME": "Santa Fe",
        "CITYCODE": "SAF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SAF",
        "AIRPORTNAME": "Santa Fe",
    },
    {
        "CITYNAME": "Shirdi",
        "CITYCODE": "SAG",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "SAG",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Sanaa",
        "CITYCODE": "SAH",
        "COUNTRYCODE": "YE",
        "COUNTRYNAME": "Yemen",
        "AIRPORTCODE": "SAH",
        "AIRPORTNAME": "Sanaa Int'l",
    },
    {
        "CITYNAME": "Siem Reap",
        "CITYCODE": "SAI",
        "COUNTRYCODE": "KH",
        "COUNTRYNAME": "Cambodia",
        "AIRPORTCODE": "SAI",
        "AIRPORTNAME": "Siem Reap Airport",
    },
    {
        "CITYNAME": "Saudarkrokur",
        "CITYCODE": "SAK",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "SAK",
        "AIRPORTNAME": "Saudarkrokur",
    },
    {
        "CITYNAME": "San Salvador",
        "CITYCODE": "SAL",
        "COUNTRYCODE": "SV",
        "COUNTRYNAME": "El Salvador",
        "AIRPORTCODE": "SAL",
        "AIRPORTNAME": "Comalapa Int'l",
    },
    {
        "CITYNAME": "Salamo",
        "CITYCODE": "SAM",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "SAM",
        "AIRPORTNAME": "Salamo",
    },
    {
        "CITYNAME": "San Diego",
        "CITYCODE": "SAN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "MYF",
        "AIRPORTNAME": "Montgomery Field",
    },
    {
        "CITYNAME": "San Diego",
        "CITYCODE": "SAN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SAN",
        "AIRPORTNAME": "Lindberg Field S. Diego",
    },
    {
        "CITYNAME": "San Diego",
        "CITYCODE": "SAN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SDM",
        "AIRPORTNAME": "Brown Field",
    },
    {
        "CITYNAME": "Sao Paulo",
        "CITYCODE": "SAO",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "CGH",
        "AIRPORTNAME": "Congonhas",
    },
    {
        "CITYNAME": "Sao Paulo",
        "CITYCODE": "SAO",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "GRU",
        "AIRPORTNAME": "Guarulhos Int'l",
    },
    {
        "CITYNAME": "Sao Paulo",
        "CITYCODE": "SAO",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "VCP",
        "AIRPORTNAME": "Viracopos",
    },
    {
        "CITYNAME": "San Pedro Sula",
        "CITYCODE": "SAP",
        "COUNTRYCODE": "HN",
        "COUNTRYNAME": "Honduras",
        "AIRPORTCODE": "SAP",
        "AIRPORTNAME": "Ramon Villeda Morales",
    },
    {
        "CITYNAME": "San Andros",
        "CITYCODE": "SAQ",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "SAQ",
        "AIRPORTNAME": "San Andros",
    },
    {
        "CITYNAME": "San Antonio",
        "CITYCODE": "SAT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SAT",
        "AIRPORTNAME": "San Antonio Int'l",
    },
    {
        "CITYNAME": "Sawu",
        "CITYCODE": "SAU",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "SAU",
        "AIRPORTNAME": "Sawu",
    },
    {
        "CITYNAME": "Savannah",
        "CITYCODE": "SAV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SAV",
        "AIRPORTNAME": "Savannah Int'l",
    },
    {
        "CITYNAME": "Siena",
        "CITYCODE": "SAY",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "SAY",
        "AIRPORTNAME": "Siena - Ampugnano Airport",
    },
    {
        "CITYNAME": "Santa Barbara",
        "CITYCODE": "SBA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SBA",
        "AIRPORTNAME": "St Ba Municipal",
    },
    {
        "CITYNAME": "Selbang",
        "CITYCODE": "SBC",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "SBC",
        "AIRPORTNAME": "SELBANG",
    },
    {
        "CITYNAME": "San Bernardino",
        "CITYCODE": "SBD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SBD",
        "AIRPORTNAME": "San Bernardino Intl Airport",
    },
    {
        "CITYNAME": "Suabi",
        "CITYCODE": "SBE",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "SBE",
        "AIRPORTNAME": "Suabi",
    },
    {
        "CITYNAME": "Sardeh Band",
        "CITYCODE": "SBF",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "SBF",
        "AIRPORTNAME": "Sardeh Band Airport",
    },
    {
        "CITYNAME": "St Barthelemy",
        "CITYCODE": "SBH",
        "COUNTRYCODE": "GP",
        "COUNTRYNAME": "Guadeloupe",
        "AIRPORTCODE": "SBH",
        "AIRPORTNAME": "St Barthelemy",
    },
    {
        "CITYNAME": "Koundara",
        "CITYCODE": "SBI",
        "COUNTRYCODE": "GN",
        "COUNTRYNAME": "Guinea",
        "AIRPORTCODE": "SBI",
        "AIRPORTNAME": "Sambailo",
    },
    {
        "CITYNAME": "St Brieuc",
        "CITYCODE": "SBK",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "SBK",
        "AIRPORTNAME": "Tremuson",
    },
    {
        "CITYNAME": "South Bend",
        "CITYCODE": "SBN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SBN",
        "AIRPORTNAME": "St Joseph Co",
    },
    {
        "CITYNAME": "Salina",
        "CITYCODE": "SBO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SBO",
        "AIRPORTNAME": "Salina",
    },
    {
        "CITYNAME": "Saibai Island",
        "CITYCODE": "SBR",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "SBR",
        "AIRPORTNAME": "Saibai Island",
    },
    {
        "CITYNAME": "Hayden",
        "CITYCODE": "SBS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "HDN",
        "AIRPORTNAME": "Yampa Valley",
    },
    {
        "CITYNAME": "Hayden",
        "CITYCODE": "SBS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SBS",
        "AIRPORTNAME": "Steamboat Springs",
    },
    {
        "CITYNAME": "Springbok",
        "CITYCODE": "SBU",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "SBU",
        "AIRPORTNAME": "Springbok",
    },
    {
        "CITYNAME": "Sibu",
        "CITYCODE": "SBW",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "SBW",
        "AIRPORTNAME": "Sibu",
    },
    {
        "CITYNAME": "Salisbury-Ocean City",
        "CITYCODE": "SBY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SBY",
        "AIRPORTNAME": "Wicomico County",
    },
    {
        "CITYNAME": "Sibiu",
        "CITYCODE": "SBZ",
        "COUNTRYCODE": "RO",
        "COUNTRYNAME": "Romania",
        "AIRPORTCODE": "SBZ",
        "AIRPORTNAME": "Sibiu",
    },
    {
        "CITYNAME": "Prudhoe Bay Deadh",
        "CITYCODE": "SCC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SCC",
        "AIRPORTNAME": "Prudhoe Bay Deadh",
    },
    {
        "CITYNAME": "State College",
        "CITYCODE": "SCE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SCE",
        "AIRPORTNAME": "State College",
    },
    {
        "CITYNAME": "Smith Cove",
        "CITYCODE": "SCJ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SCJ",
        "AIRPORTNAME": "Smith Cove",
    },
    {
        "CITYNAME": "Stockton",
        "CITYCODE": "SCK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SCK",
        "AIRPORTNAME": "Stockton",
    },
    {
        "CITYNAME": "Santiago",
        "CITYCODE": "SCL",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "SCL",
        "AIRPORTNAME": "Arturo Merino Benitez",
    },
    {
        "CITYNAME": "Saarbrucken",
        "CITYCODE": "SCN",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "SCN",
        "AIRPORTNAME": "Ensheim",
    },
    {
        "CITYNAME": "Aktau",
        "CITYCODE": "SCO",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "SCO",
        "AIRPORTNAME": "Aktau",
    },
    {
        "CITYNAME": "Santiago",
        "CITYCODE": "SCQ",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "SCQ",
        "AIRPORTNAME": "Santiago",
    },
    {
        "CITYNAME": "Socotra",
        "CITYCODE": "SCT",
        "COUNTRYCODE": "YE",
        "COUNTRYNAME": "Yemen",
        "AIRPORTCODE": "SCT",
        "AIRPORTNAME": "Socotra",
    },
    {
        "CITYNAME": "Santiago",
        "CITYCODE": "SCU",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "SCU",
        "AIRPORTNAME": "Antonio Maceo",
    },
    {
        "CITYNAME": "Suceava",
        "CITYCODE": "SCV",
        "COUNTRYCODE": "RO",
        "COUNTRYNAME": "Romania",
        "AIRPORTCODE": "SCV",
        "AIRPORTNAME": "Salcea",
    },
    {
        "CITYNAME": "Syktyvkar",
        "CITYCODE": "SCW",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "SCW",
        "AIRPORTNAME": "Syktyvkar",
    },
    {
        "CITYNAME": "Santa Cruz Island",
        "CITYCODE": "SCZ",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "SCZ",
        "AIRPORTNAME": "Santa Cruz Is",
    },
    {
        "CITYNAME": "Lubango",
        "CITYCODE": "SDD",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "SDD",
        "AIRPORTNAME": "Lubango",
    },
    {
        "CITYNAME": "Santiago",
        "CITYCODE": "SDE",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "SDE",
        "AIRPORTNAME": "Sgo  Del Estero",
    },
    {
        "CITYNAME": "Louisville",
        "CITYCODE": "SDF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SDF",
        "AIRPORTNAME": "Standiford Field",
    },
    {
        "CITYNAME": "Sanandaj",
        "CITYCODE": "SDG",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "SDG",
        "AIRPORTNAME": "Sanandaj",
    },
    {
        "CITYNAME": "Sendai",
        "CITYCODE": "SDJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "SDJ",
        "AIRPORTNAME": "Sendai",
    },
    {
        "CITYNAME": "Sandakan",
        "CITYCODE": "SDK",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "SDK",
        "AIRPORTNAME": "Sandakan",
    },
    {
        "CITYNAME": "Sundsvall",
        "CITYCODE": "SDL",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "SDL",
        "AIRPORTNAME": "Sundsvall Harnosa",
    },
    {
        "CITYNAME": "Sandane",
        "CITYCODE": "SDN",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "SDN",
        "AIRPORTNAME": "Sandane",
    },
    {
        "CITYNAME": "Sand Point",
        "CITYCODE": "SDP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SDP",
        "AIRPORTNAME": "Sand Point Municipal",
    },
    {
        "CITYNAME": "Santo Domingo",
        "CITYCODE": "SDQ",
        "COUNTRYCODE": "DO",
        "COUNTRYNAME": "Dominican Republic",
        "AIRPORTCODE": "HEX",
        "AIRPORTNAME": "Herrer",
    },
    {
        "CITYNAME": "Santo Domingo",
        "CITYCODE": "SDQ",
        "COUNTRYCODE": "DO",
        "COUNTRYNAME": "Dominican Republic",
        "AIRPORTCODE": "SDQ",
        "AIRPORTNAME": "Las Americas",
    },
    {
        "CITYNAME": "Santander",
        "CITYCODE": "SDR",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "SDR",
        "AIRPORTNAME": "Santander",
    },
    {
        "CITYNAME": "Saidu Sharif",
        "CITYCODE": "SDT",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "SDT",
        "AIRPORTNAME": "Saidu Sharif",
    },
    {
        "CITYNAME": "Sindhudurg",
        "CITYCODE": "SDW",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "SDW",
        "AIRPORTNAME": "Sindhudurg Airport",
    },
    {
        "CITYNAME": "Sedona",
        "CITYCODE": "SDX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SDX",
        "AIRPORTNAME": "Sedona",
    },
    {
        "CITYNAME": "Sidney",
        "CITYCODE": "SDY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SDY",
        "AIRPORTNAME": "Richland Municipal",
    },
    {
        "CITYNAME": "Shetland Islands",
        "CITYCODE": "SDZ",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "LSI",
        "AIRPORTNAME": "Sumburgh",
    },
    {
        "CITYNAME": "Shetland Islands",
        "CITYCODE": "SDZ",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "LWK",
        "AIRPORTNAME": "Lerwick/Tingwall",
    },
    {
        "CITYNAME": "Seattle",
        "CITYCODE": "SEA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SEA",
        "AIRPORTNAME": "Seattle/Tacoma Int'l",
    },
    {
        "CITYNAME": "Sebha",
        "CITYCODE": "SEB",
        "COUNTRYCODE": "LY",
        "COUNTRYNAME": "Libyan Arab Jamahiriya",
        "AIRPORTCODE": "SEB",
        "AIRPORTNAME": "Sebha",
    },
    {
        "CITYNAME": "Senggeh",
        "CITYCODE": "SEH",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "SEH",
        "AIRPORTNAME": "Senggeh",
    },
    {
        "CITYNAME": "Seoul",
        "CITYCODE": "SEL",
        "COUNTRYCODE": "KR",
        "COUNTRYNAME": "South Korea",
        "AIRPORTCODE": "GMP",
        "AIRPORTNAME": "Gimpo International",
    },
    {
        "CITYNAME": "Seoul",
        "CITYCODE": "SEL",
        "COUNTRYCODE": "KR",
        "COUNTRYNAME": "South Korea",
        "AIRPORTCODE": "ICN",
        "AIRPORTNAME": "Incheon International",
    },
    {
        "CITYNAME": "Southend",
        "CITYCODE": "SEN",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "SEN",
        "AIRPORTNAME": "Southend Municipal",
    },
    {
        "CITYNAME": "Seguela",
        "CITYCODE": "SEO",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "SEO",
        "AIRPORTNAME": "Seguela",
    },
    {
        "CITYNAME": "Seronera",
        "CITYCODE": "SEU",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "SEU",
        "AIRPORTNAME": "Seronera Airport",
    },
    {
        "CITYNAME": "Selibaby",
        "CITYCODE": "SEY",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "SEY",
        "AIRPORTNAME": "Selibaby",
    },
    {
        "CITYNAME": "Mahe Island",
        "CITYCODE": "SEZ",
        "COUNTRYCODE": "SC",
        "COUNTRYNAME": "Seychelles",
        "AIRPORTCODE": "SEZ",
        "AIRPORTNAME": "Seychelles Int'l",
    },
    {
        "CITYNAME": "Sfax",
        "CITYCODE": "SFA",
        "COUNTRYCODE": "TN",
        "COUNTRYNAME": "Tunisia",
        "AIRPORTCODE": "SFA",
        "AIRPORTNAME": "Sfax El Maou",
    },
    {
        "CITYNAME": "S Fern De Apure",
        "CITYCODE": "SFD",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "SFD",
        "AIRPORTNAME": "Las Flecheras",
    },
    {
        "CITYNAME": "St Martin",
        "CITYCODE": "SFG",
        "COUNTRYCODE": "GP",
        "COUNTRYNAME": "Guadeloupe",
        "AIRPORTCODE": "SFG",
        "AIRPORTNAME": "Esperance",
    },
    {
        "CITYNAME": "San Felipe",
        "CITYCODE": "SFH",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "SFH",
        "AIRPORTNAME": "San Felipe",
    },
    {
        "CITYNAME": "Safi",
        "CITYCODE": "SFI",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "SFI",
        "AIRPORTNAME": "Safi",
    },
    {
        "CITYNAME": "Kangerlussuaq",
        "CITYCODE": "SFJ",
        "COUNTRYCODE": "GL",
        "COUNTRYNAME": "Greenland",
        "AIRPORTCODE": "SFJ",
        "AIRPORTNAME": "Kangerlussuaq",
    },
    {
        "CITYNAME": "Santa Fe",
        "CITYCODE": "SFN",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "SFN",
        "AIRPORTNAME": "Sauce Viejo Airport",
    },
    {
        "CITYNAME": "San Francisco",
        "CITYCODE": "SFO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SFO",
        "AIRPORTNAME": "San Francisco Int'l",
    },
    {
        "CITYNAME": "Sanliurfa",
        "CITYCODE": "SFQ",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "GNY",
        "AIRPORTNAME": "ÅanlÄ±urfa GAP Airport",
    },
    {
        "CITYNAME": "Subic Bay",
        "CITYCODE": "SFS",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "SFS",
        "AIRPORTNAME": "Subic Bay Int'l",
    },
    {
        "CITYNAME": "Skelleftea",
        "CITYCODE": "SFT",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "SFT",
        "AIRPORTNAME": "Skelleftea",
    },
    {
        "CITYNAME": "Safia",
        "CITYCODE": "SFU",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "SFU",
        "AIRPORTNAME": "Safia",
    },
    {
        "CITYNAME": "Chicopee",
        "CITYCODE": "SFY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CEF",
        "AIRPORTNAME": "Westover Metro",
    },
    {
        "CITYNAME": "Sheghnan",
        "CITYCODE": "SGA",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "SGA",
        "AIRPORTNAME": "Sheghnan",
    },
    {
        "CITYNAME": "Surgut",
        "CITYCODE": "SGC",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "SGC",
        "AIRPORTNAME": "Surgut",
    },
    {
        "CITYNAME": "Sonderborg",
        "CITYCODE": "SGD",
        "COUNTRYCODE": "DK",
        "COUNTRYNAME": "Denmark",
        "AIRPORTCODE": "SGD",
        "AIRPORTNAME": "Sonderborg",
    },
    {
        "CITYNAME": "Springfield",
        "CITYCODE": "SGF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SGF",
        "AIRPORTNAME": "Springfield-Branson Regional",
    },
    {
        "CITYNAME": "Sargodha",
        "CITYCODE": "SGI",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "SGI",
        "AIRPORTNAME": "Sargodha PAF Base Mushaf",
    },
    {
        "CITYNAME": "Ho Chi Minh City",
        "CITYCODE": "SGN",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "SGN",
        "AIRPORTNAME": "Ho Chi Minh",
    },
    {
        "CITYNAME": "Sanga Sanga",
        "CITYCODE": "SGS",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "SGS",
        "AIRPORTNAME": "Sanga Sanga",
    },
    {
        "CITYNAME": "Saint George",
        "CITYCODE": "SGU",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SGU",
        "AIRPORTNAME": "Saint George Municipal",
    },
    {
        "CITYNAME": "Sierra Grande",
        "CITYCODE": "SGV",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "SGV",
        "AIRPORTNAME": "Sierra Grande",
    },
    {
        "CITYNAME": "Songea",
        "CITYCODE": "SGX",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "SGX",
        "AIRPORTNAME": "Songea",
    },
    {
        "CITYNAME": "Skagway",
        "CITYCODE": "SGY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SGY",
        "AIRPORTNAME": "Skagway Municipal",
    },
    {
        "CITYNAME": "Shanghai",
        "CITYCODE": "SHA",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "SHA",
        "AIRPORTNAME": "Hongqiao",
    },
    {
        "CITYNAME": "Nakashibetsu",
        "CITYCODE": "SHB",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "SHB",
        "AIRPORTNAME": "Nakashibetsu",
    },
    {
        "CITYNAME": "Indaselassie",
        "CITYCODE": "SHC",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "SHC",
        "AIRPORTNAME": "Indaselassie",
    },
    {
        "CITYNAME": "Staunton",
        "CITYCODE": "SHD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SHD",
        "AIRPORTNAME": "Shenandoah Valley",
    },
    {
        "CITYNAME": "Shenyang",
        "CITYCODE": "SHE",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "SHE",
        "AIRPORTNAME": "Shenyang",
    },
    {
        "CITYNAME": "Shungnak",
        "CITYCODE": "SHG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SHG",
        "AIRPORTNAME": "Shungnak",
    },
    {
        "CITYNAME": "Shishmaref",
        "CITYCODE": "SHH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SHH",
        "AIRPORTNAME": "Shishmaref",
    },
    {
        "CITYNAME": "Shimojishima",
        "CITYCODE": "SHI",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "SHI",
        "AIRPORTNAME": "Shimojishima",
    },
    {
        "CITYNAME": "Sharjah",
        "CITYCODE": "SHJ",
        "COUNTRYCODE": "AE",
        "COUNTRYNAME": "United Arab Emirates",
        "AIRPORTCODE": "SHJ",
        "AIRPORTNAME": "Sharjah",
    },
    {
        "CITYNAME": "Sehonghong",
        "CITYCODE": "SHK",
        "COUNTRYCODE": "LS",
        "COUNTRYNAME": "Lesotho",
        "AIRPORTCODE": "SHK",
        "AIRPORTNAME": "Sehonghong",
    },
    {
        "CITYNAME": "Shillong",
        "CITYCODE": "SHL",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "SHL",
        "AIRPORTNAME": "Shillong",
    },
    {
        "CITYNAME": "Shirahama",
        "CITYCODE": "SHM",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "SHM",
        "AIRPORTNAME": "Shirahama",
    },
    {
        "CITYNAME": "Sokcho",
        "CITYCODE": "SHO",
        "COUNTRYCODE": "KR",
        "COUNTRYNAME": "South Korea",
        "AIRPORTCODE": "SHO",
        "AIRPORTNAME": "Solak",
    },
    {
        "CITYNAME": "Qinhuangdao",
        "CITYCODE": "SHP",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "SHP",
        "AIRPORTNAME": "Qinhuangdao",
    },
    {
        "CITYNAME": "Sheridan",
        "CITYCODE": "SHR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SHR",
        "AIRPORTNAME": "Sheridan",
    },
    {
        "CITYNAME": "Shashi",
        "CITYCODE": "SHS",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "SHS",
        "AIRPORTNAME": "Shashi",
    },
    {
        "CITYNAME": "Shreveport",
        "CITYCODE": "SHV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SHV",
        "AIRPORTNAME": "Shreveport Regional",
    },
    {
        "CITYNAME": "Sharurah",
        "CITYCODE": "SHW",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "SHW",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Shageluk",
        "CITYCODE": "SHX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SHX",
        "AIRPORTNAME": "Shageluk",
    },
    {
        "CITYNAME": "Shinyanga",
        "CITYCODE": "SHY",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "SHY",
        "AIRPORTNAME": "Shinyanga",
    },
    {
        "CITYNAME": "Xi An",
        "CITYCODE": "SIA",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "SIA",
        "AIRPORTNAME": "Xiguan",
    },
    {
        "CITYNAME": "Xi An",
        "CITYCODE": "SIA",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "XIY",
        "AIRPORTNAME": "Xianyang",
    },
    {
        "CITYNAME": "Sibiti",
        "CITYCODE": "SIB",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "SIB",
        "AIRPORTNAME": "Sibiti",
    },
    {
        "CITYNAME": "Sal",
        "CITYCODE": "SID",
        "COUNTRYCODE": "CV",
        "COUNTRYNAME": "Cape Verde",
        "AIRPORTCODE": "SID",
        "AIRPORTNAME": "Amilcar Cabral Int'l",
    },
    {
        "CITYNAME": "Siglufjordur",
        "CITYCODE": "SIJ",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "SIJ",
        "AIRPORTNAME": "Siglufjordur",
    },
    {
        "CITYNAME": "Singapore",
        "CITYCODE": "SIN",
        "COUNTRYCODE": "SG",
        "COUNTRYNAME": "Singapore",
        "AIRPORTCODE": "QPG",
        "AIRPORTNAME": "Paya Lebar",
    },
    {
        "CITYNAME": "Singapore",
        "CITYCODE": "SIN",
        "COUNTRYCODE": "SG",
        "COUNTRYNAME": "Singapore",
        "AIRPORTCODE": "SIN",
        "AIRPORTNAME": "Changi",
    },
    {
        "CITYNAME": "Singapore",
        "CITYCODE": "SIN",
        "COUNTRYCODE": "SG",
        "COUNTRYNAME": "Singapore",
        "AIRPORTCODE": "XSP",
        "AIRPORTNAME": "Seletar",
    },
    {
        "CITYNAME": "Smithton",
        "CITYCODE": "SIO",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "SIO",
        "AIRPORTNAME": "Smithton",
    },
    {
        "CITYNAME": "Simferopol",
        "CITYCODE": "SIP",
        "COUNTRYCODE": "UA",
        "COUNTRYNAME": "Ukraine",
        "AIRPORTCODE": "SIP",
        "AIRPORTNAME": "Simferopol",
    },
    {
        "CITYNAME": "Singkep",
        "CITYCODE": "SIQ",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "SIQ",
        "AIRPORTNAME": "Dabo",
    },
    {
        "CITYNAME": "Sion",
        "CITYCODE": "SIR",
        "COUNTRYCODE": "CH",
        "COUNTRYNAME": "Switzerland",
        "AIRPORTCODE": "SIR",
        "AIRPORTNAME": "Sion",
    },
    {
        "CITYNAME": "Sishen",
        "CITYCODE": "SIS",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "SIS",
        "AIRPORTNAME": "Sishen",
    },
    {
        "CITYNAME": "Sitka",
        "CITYCODE": "SIT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SIT",
        "AIRPORTNAME": "Sitka",
    },
    {
        "CITYNAME": "Siuna",
        "CITYCODE": "SIU",
        "COUNTRYCODE": "NI",
        "COUNTRYNAME": "Nicaragua",
        "AIRPORTCODE": "SIU",
        "AIRPORTNAME": "Siuna",
    },
    {
        "CITYNAME": "San Joaquin",
        "CITYCODE": "SJB",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "SJB",
        "AIRPORTNAME": "San Joaquin",
    },
    {
        "CITYNAME": "San Jose",
        "CITYCODE": "SJC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SJC",
        "AIRPORTNAME": "San Jose Municipal",
    },
    {
        "CITYNAME": "San Jose Cabo",
        "CITYCODE": "SJD",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "SJD",
        "AIRPORTNAME": "CABO SAN LUCAS",
    },
    {
        "CITYNAME": "San Jose Del Gua",
        "CITYCODE": "SJE",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "SJE",
        "AIRPORTNAME": "San Jose Del Gua",
    },
    {
        "CITYNAME": "San Jose",
        "CITYCODE": "SJI",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "SJI",
        "AIRPORTNAME": "Mcguire Field",
    },
    {
        "CITYNAME": "Sarajevo",
        "CITYCODE": "SJJ",
        "COUNTRYCODE": "BA",
        "COUNTRYNAME": "Bosnia and Herzegowina",
        "AIRPORTCODE": "SJJ",
        "AIRPORTNAME": "Butmir",
    },
    {
        "CITYNAME": "Sao Jose Dos Campos",
        "CITYCODE": "SJK",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "SJK",
        "AIRPORTNAME": "Sao Jose Dos Campos",
    },
    {
        "CITYNAME": "Sao Gabriel",
        "CITYCODE": "SJL",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "SJL",
        "AIRPORTNAME": "Sao Gabriel da Cachoeira Airport",
    },
    {
        "CITYNAME": "San Jose",
        "CITYCODE": "SJO",
        "COUNTRYCODE": "CR",
        "COUNTRYNAME": "Costa Rica",
        "AIRPORTCODE": "SJO",
        "AIRPORTNAME": "Juan Santamaria Int'l",
    },
    {
        "CITYNAME": "Sao Jose Do Rio Preto",
        "CITYCODE": "SJP",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "SJP",
        "AIRPORTNAME": "Sao Jose Do Rio Preto",
    },
    {
        "CITYNAME": "San Angelo",
        "CITYCODE": "SJT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SJT",
        "AIRPORTNAME": "Mathis Field",
    },
    {
        "CITYNAME": "San Juan",
        "CITYCODE": "SJU",
        "COUNTRYCODE": "PR",
        "COUNTRYNAME": "Puerto Rico",
        "AIRPORTCODE": "SIG",
        "AIRPORTNAME": "Isla Grande",
    },
    {
        "CITYNAME": "San Juan",
        "CITYCODE": "SJU",
        "COUNTRYCODE": "PR",
        "COUNTRYNAME": "Puerto Rico",
        "AIRPORTCODE": "SJU",
        "AIRPORTNAME": "Luis Munoz Marin",
    },
    {
        "CITYNAME": "Shijiazhuang",
        "CITYCODE": "SJW",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "SJW",
        "AIRPORTNAME": "Shijiazhuang Zhengding Intl Airport",
    },
    {
        "CITYNAME": "Seinajoki",
        "CITYCODE": "SJY",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "SJY",
        "AIRPORTNAME": "Seinajoki Airport",
    },
    {
        "CITYNAME": "Sao Jorge Island",
        "CITYCODE": "SJZ",
        "COUNTRYCODE": "PT",
        "COUNTRYNAME": "Portugal",
        "AIRPORTCODE": "SJZ",
        "AIRPORTNAME": "Sao Jorge Island",
    },
    {
        "CITYNAME": "St Kitts",
        "CITYCODE": "SKB",
        "COUNTRYCODE": "KN",
        "COUNTRYNAME": "St. Kitts and Nevis",
        "AIRPORTCODE": "SKB",
        "AIRPORTNAME": "Robert L Bradshaw",
    },
    {
        "CITYNAME": "Suki",
        "CITYCODE": "SKC",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "SKC",
        "AIRPORTNAME": "Suki",
    },
    {
        "CITYNAME": "Samarkand",
        "CITYCODE": "SKD",
        "COUNTRYCODE": "UZ",
        "COUNTRYNAME": "Uzbekistan",
        "AIRPORTCODE": "SKD",
        "AIRPORTNAME": "Samarkand",
    },
    {
        "CITYNAME": "Skien",
        "CITYCODE": "SKE",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "SKE",
        "AIRPORTNAME": "Skien",
    },
    {
        "CITYNAME": "Thessaloniki",
        "CITYCODE": "SKG",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "SKG",
        "AIRPORTNAME": "Makedonia",
    },
    {
        "CITYNAME": "Shaktoolik",
        "CITYCODE": "SKK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SKK",
        "AIRPORTNAME": "Shaktoolik",
    },
    {
        "CITYNAME": "Stokmarknes",
        "CITYCODE": "SKN",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "SKN",
        "AIRPORTNAME": "Skagen",
    },
    {
        "CITYNAME": "Sokoto",
        "CITYCODE": "SKO",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "SKO",
        "AIRPORTNAME": "Sokoto",
    },
    {
        "CITYNAME": "Skopje",
        "CITYCODE": "SKP",
        "COUNTRYCODE": "MK",
        "COUNTRYNAME": "Macedonia",
        "AIRPORTCODE": "SKP",
        "AIRPORTNAME": "Skopje",
    },
    {
        "CITYNAME": "Shakiso",
        "CITYCODE": "SKR",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "SKR",
        "AIRPORTNAME": "Shakiso",
    },
    {
        "CITYNAME": "Vojens",
        "CITYCODE": "SKS",
        "COUNTRYCODE": "DK",
        "COUNTRYNAME": "Denmark",
        "AIRPORTCODE": "SKS",
        "AIRPORTNAME": "Vojens",
    },
    {
        "CITYNAME": "Sialkot",
        "CITYCODE": "SKT",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "SKT",
        "AIRPORTNAME": "Sialkot",
    },
    {
        "CITYNAME": "Skiros",
        "CITYCODE": "SKU",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "SKU",
        "AIRPORTNAME": "Skiros",
    },
    {
        "CITYNAME": "Santa Katarina",
        "CITYCODE": "SKV",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "SKV",
        "AIRPORTNAME": "Mount Sinai",
    },
    {
        "CITYNAME": "Saransk",
        "CITYCODE": "SKX",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "SKX",
        "AIRPORTNAME": "Saransk Airport",
    },
    {
        "CITYNAME": "Sukkur",
        "CITYCODE": "SKZ",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "SKZ",
        "AIRPORTNAME": "Sukkur",
    },
    {
        "CITYNAME": "Salta",
        "CITYCODE": "SLA",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "SLA",
        "AIRPORTNAME": "Gen Belgrano",
    },
    {
        "CITYNAME": "Salt Lake City",
        "CITYCODE": "SLC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SLC",
        "AIRPORTNAME": "Salt Lake City Int'l",
    },
    {
        "CITYNAME": "Sliac",
        "CITYCODE": "SLD",
        "COUNTRYCODE": "SK",
        "COUNTRYNAME": "Slovakia",
        "AIRPORTCODE": "SLD",
        "AIRPORTNAME": "Sliac",
    },
    {
        "CITYNAME": "Salem",
        "CITYCODE": "SLE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SLE",
        "AIRPORTNAME": "Mcnary Field",
    },
    {
        "CITYNAME": "Sola",
        "CITYCODE": "SLH",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "SLH",
        "AIRPORTNAME": "Sola",
    },
    {
        "CITYNAME": "Solwezi",
        "CITYCODE": "SLI",
        "COUNTRYCODE": "ZM",
        "COUNTRYNAME": "Zambia",
        "AIRPORTCODE": "SLI",
        "AIRPORTNAME": "Solwesi Airport",
    },
    {
        "CITYNAME": "Saranac Lake",
        "CITYCODE": "SLK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SLK",
        "AIRPORTNAME": "Adirondack",
    },
    {
        "CITYNAME": "Salalah",
        "CITYCODE": "SLL",
        "COUNTRYCODE": "OM",
        "COUNTRYNAME": "Oman",
        "AIRPORTCODE": "SLL",
        "AIRPORTNAME": "Salalah",
    },
    {
        "CITYNAME": "Salina",
        "CITYCODE": "SLN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SLN",
        "AIRPORTNAME": "Salina",
    },
    {
        "CITYNAME": "San Luis Potosi",
        "CITYCODE": "SLP",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "SLP",
        "AIRPORTNAME": "San Luis Potosi",
    },
    {
        "CITYNAME": "Sleetmute",
        "CITYCODE": "SLQ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SLQ",
        "AIRPORTNAME": "Sleetmute",
    },
    {
        "CITYNAME": "Silistra",
        "CITYCODE": "SLS",
        "COUNTRYCODE": "BG",
        "COUNTRYNAME": "Bulgaria",
        "AIRPORTCODE": "SLS",
        "AIRPORTNAME": "Silistra",
    },
    {
        "CITYNAME": "St Lucia",
        "CITYCODE": "SLU",
        "COUNTRYCODE": "LC",
        "COUNTRYNAME": "Saint Lucia",
        "AIRPORTCODE": "SLU",
        "AIRPORTNAME": "George F.L.Charles",
    },
    {
        "CITYNAME": "St Lucia",
        "CITYCODE": "SLU",
        "COUNTRYCODE": "LC",
        "COUNTRYNAME": "Saint Lucia",
        "AIRPORTCODE": "UVF",
        "AIRPORTNAME": "Hewanorra",
    },
    {
        "CITYNAME": "Shimla",
        "CITYCODE": "SLV",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "SLV",
        "AIRPORTNAME": "Simla",
    },
    {
        "CITYNAME": "Salt Cay",
        "CITYCODE": "SLX",
        "COUNTRYCODE": "TC",
        "COUNTRYNAME": "Turcs & Caicos Islands",
        "AIRPORTCODE": "SLX",
        "AIRPORTNAME": "Salt Cay",
    },
    {
        "CITYNAME": "Sao Luiz",
        "CITYCODE": "SLZ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "SLZ",
        "AIRPORTNAME": "Sao Luiz",
    },
    {
        "CITYNAME": "Santa Maria",
        "CITYCODE": "SMA",
        "COUNTRYCODE": "PT",
        "COUNTRYNAME": "Portugal",
        "AIRPORTCODE": "SMA",
        "AIRPORTNAME": "Vila Do Porto",
    },
    {
        "CITYNAME": "Samos",
        "CITYCODE": "SMI",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "SMI",
        "AIRPORTNAME": "Samos",
    },
    {
        "CITYNAME": "St Michael",
        "CITYCODE": "SMK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SMK",
        "AIRPORTNAME": "St Michael",
    },
    {
        "CITYNAME": "Stella Maris",
        "CITYCODE": "SML",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "SML",
        "AIRPORTNAME": "Stella Maris Estate Airstrip",
    },
    {
        "CITYNAME": "Semporna",
        "CITYCODE": "SMM",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "SMM",
        "AIRPORTNAME": "Semporna",
    },
    {
        "CITYNAME": "Santa Monica",
        "CITYCODE": "SMO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SMO",
        "AIRPORTNAME": "Santa Monica",
    },
    {
        "CITYNAME": "Santa Marta",
        "CITYCODE": "SMR",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "SMR",
        "AIRPORTNAME": "Simon Bolivar",
    },
    {
        "CITYNAME": "Sainte Marie",
        "CITYCODE": "SMS",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "SMS",
        "AIRPORTNAME": "Sainte Marie",
    },
    {
        "CITYNAME": "Sun Moon Lake",
        "CITYCODE": "SMT",
        "COUNTRYCODE": "TW",
        "COUNTRYNAME": "Taiwan",
        "AIRPORTCODE": "SMT",
        "AIRPORTNAME": "Sorriso Airport",
    },
    {
        "CITYNAME": "St Moritz",
        "CITYCODE": "SMV",
        "COUNTRYCODE": "CH",
        "COUNTRYNAME": "Switzerland",
        "AIRPORTCODE": "SMV",
        "AIRPORTNAME": "Samedan",
    },
    {
        "CITYNAME": "Smara",
        "CITYCODE": "SMW",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "SMW",
        "AIRPORTNAME": "Smara",
    },
    {
        "CITYNAME": "Santa Maria",
        "CITYCODE": "SMX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SMX",
        "AIRPORTNAME": "Public",
    },
    {
        "CITYNAME": "Simenti",
        "CITYCODE": "SMY",
        "COUNTRYCODE": "SN",
        "COUNTRYNAME": "Senegal",
        "AIRPORTCODE": "SMY",
        "AIRPORTNAME": "Simenti",
    },
    {
        "CITYNAME": "Santa Ana",
        "CITYCODE": "SNA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SNA",
        "AIRPORTNAME": "John Wayne",
    },
    {
        "CITYNAME": "Snake Bay",
        "CITYCODE": "SNB",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "SNB",
        "AIRPORTNAME": "Snake Bay",
    },
    {
        "CITYNAME": "Sao Nicolau",
        "CITYCODE": "SNE",
        "COUNTRYCODE": "CV",
        "COUNTRYNAME": "Cape Verde",
        "AIRPORTCODE": "SNE",
        "AIRPORTNAME": "Preguica",
    },
    {
        "CITYNAME": "San Ignacio De Ve",
        "CITYCODE": "SNG",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "SNG",
        "AIRPORTNAME": "San Ignacio De Ve",
    },
    {
        "CITYNAME": "Sinoe",
        "CITYCODE": "SNI",
        "COUNTRYCODE": "LR",
        "COUNTRYNAME": "Liberia",
        "AIRPORTCODE": "SNI",
        "AIRPORTNAME": "R.E. Murray",
    },
    {
        "CITYNAME": "Shannon",
        "CITYCODE": "SNN",
        "COUNTRYCODE": "IE",
        "COUNTRYNAME": "Ireland",
        "AIRPORTCODE": "SNN",
        "AIRPORTNAME": "Shannon",
    },
    {
        "CITYNAME": "Sakon Nakhon",
        "CITYCODE": "SNO",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "SNO",
        "AIRPORTNAME": "Sakon Nakhon",
    },
    {
        "CITYNAME": "Saint Paul Island",
        "CITYCODE": "SNP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SNP",
        "AIRPORTNAME": "Saint Paul Island",
    },
    {
        "CITYNAME": "Santa Clara",
        "CITYCODE": "SNU",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "SNU",
        "AIRPORTNAME": "Santa Clara",
    },
    {
        "CITYNAME": "Thandwe",
        "CITYCODE": "SNW",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "SNW",
        "AIRPORTNAME": "Thandwe",
    },
    {
        "CITYNAME": "Sidney",
        "CITYCODE": "SNY",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SNY",
        "AIRPORTNAME": "Sidney",
    },
    {
        "CITYNAME": "Saarmelleek",
        "CITYCODE": "SOB",
        "COUNTRYCODE": "HU",
        "COUNTRYNAME": "Hungary",
        "AIRPORTCODE": "SOB",
        "AIRPORTNAME": "Keszthely Airport",
    },
    {
        "CITYNAME": "Solo City",
        "CITYCODE": "SOC",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "SOC",
        "AIRPORTNAME": "Adi Sumarmo",
    },
    {
        "CITYNAME": "Souanke",
        "CITYCODE": "SOE",
        "COUNTRYCODE": "CG",
        "COUNTRYNAME": "Congo",
        "AIRPORTCODE": "SOE",
        "AIRPORTNAME": "Souanke",
    },
    {
        "CITYNAME": "Sofia",
        "CITYCODE": "SOF",
        "COUNTRYCODE": "BG",
        "COUNTRYNAME": "Bulgaria",
        "AIRPORTCODE": "SOF",
        "AIRPORTNAME": "Sofia",
    },
    {
        "CITYNAME": "Sogndal",
        "CITYCODE": "SOG",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "SOG",
        "AIRPORTNAME": "Haukasen",
    },
    {
        "CITYNAME": "South Molle Island",
        "CITYCODE": "SOI",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "SOI",
        "AIRPORTNAME": "South Molle Islan",
    },
    {
        "CITYNAME": "Sorkjosen",
        "CITYCODE": "SOJ",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "SOJ",
        "AIRPORTNAME": "Sorkjosen",
    },
    {
        "CITYNAME": "Semongkong",
        "CITYCODE": "SOK",
        "COUNTRYCODE": "LS",
        "COUNTRYNAME": "Lesotho",
        "AIRPORTCODE": "SOK",
        "AIRPORTNAME": "Semongkong",
    },
    {
        "CITYNAME": "San Tome",
        "CITYCODE": "SOM",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "SOM",
        "AIRPORTNAME": "El Tigre",
    },
    {
        "CITYNAME": "Espiritu Santo",
        "CITYCODE": "SON",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "SON",
        "AIRPORTNAME": "Espiritu Santo",
    },
    {
        "CITYNAME": "Soderhamn",
        "CITYCODE": "SOO",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "SOO",
        "AIRPORTNAME": "Soderhamn",
    },
    {
        "CITYNAME": "Sorong",
        "CITYCODE": "SOQ",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "SOQ",
        "AIRPORTNAME": "Jefman",
    },
    {
        "CITYNAME": "Sodankyla",
        "CITYCODE": "SOT",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "SOT",
        "AIRPORTNAME": "Sodankyla",
    },
    {
        "CITYNAME": "Southampton",
        "CITYCODE": "SOU",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "SOU",
        "AIRPORTNAME": "Eastleigh",
    },
    {
        "CITYNAME": "Seldovia",
        "CITYCODE": "SOV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SOV",
        "AIRPORTNAME": "Seldovia",
    },
    {
        "CITYNAME": "Show Low",
        "CITYCODE": "SOW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SOW",
        "AIRPORTNAME": "Show Low Regional Airport",
    },
    {
        "CITYNAME": "Stronsay",
        "CITYCODE": "SOY",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "SOY",
        "AIRPORTNAME": "Stronsay",
    },
    {
        "CITYNAME": "Santa Cruz de la Palma",
        "CITYCODE": "SPC",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "SPC",
        "AIRPORTNAME": "La Palma",
    },
    {
        "CITYNAME": "Saidpur",
        "CITYCODE": "SPD",
        "COUNTRYCODE": "BD",
        "COUNTRYNAME": "Bangladesh",
        "AIRPORTCODE": "SPD",
        "AIRPORTNAME": "Saidpur",
    },
    {
        "CITYNAME": "Spearfish",
        "CITYCODE": "SPF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SPF",
        "AIRPORTNAME": "Black Hills",
    },
    {
        "CITYNAME": "Springfield",
        "CITYCODE": "SPI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SPI",
        "AIRPORTNAME": "Capital",
    },
    {
        "CITYNAME": "Sapporo",
        "CITYCODE": "SPK",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "CTS",
        "AIRPORTNAME": "Chitose",
    },
    {
        "CITYNAME": "Sapporo",
        "CITYCODE": "SPK",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "OKD",
        "AIRPORTNAME": "Okadama",
    },
    {
        "CITYNAME": "Saipan",
        "CITYCODE": "SPN",
        "COUNTRYCODE": "MP",
        "COUNTRYNAME": "Northern Mariana Islands",
        "AIRPORTCODE": "SPN",
        "AIRPORTNAME": "Saipan Int'l",
    },
    {
        "CITYNAME": "Menongue",
        "CITYCODE": "SPP",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "SPP",
        "AIRPORTNAME": "Menongue",
    },
    {
        "CITYNAME": "San Pedro",
        "CITYCODE": "SPR",
        "COUNTRYCODE": "BZ",
        "COUNTRYNAME": "Belize",
        "AIRPORTCODE": "SPR",
        "AIRPORTNAME": "San Pedro",
    },
    {
        "CITYNAME": "Wichita Falls",
        "CITYCODE": "SPS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SPS",
        "AIRPORTNAME": "Wichita Falls Municipal Airport",
    },
    {
        "CITYNAME": "Split",
        "CITYCODE": "SPU",
        "COUNTRYCODE": "HR",
        "COUNTRYNAME": "Croatia",
        "AIRPORTCODE": "SPU",
        "AIRPORTNAME": "Split",
    },
    {
        "CITYNAME": "Spencer",
        "CITYCODE": "SPW",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SPW",
        "AIRPORTNAME": "Spencer Municipal",
    },
    {
        "CITYNAME": "San Pedro",
        "CITYCODE": "SPY",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "SPY",
        "AIRPORTNAME": "San Pedro",
    },
    {
        "CITYNAME": "Southern Cross",
        "CITYCODE": "SQC",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "SQC",
        "AIRPORTNAME": "Southern Cross",
    },
    {
        "CITYNAME": "Solano",
        "CITYCODE": "SQF",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "SQF",
        "AIRPORTNAME": "Solano",
    },
    {
        "CITYNAME": "Sterling Rockfls",
        "CITYCODE": "SQI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SQI",
        "AIRPORTNAME": "Whiteside County",
    },
    {
        "CITYNAME": "Storuman",
        "CITYCODE": "SQO",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "SQO",
        "AIRPORTNAME": "Gunnarn Airport",
    },
    {
        "CITYNAME": "Santa Rosa",
        "CITYCODE": "SRA",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "SRA",
        "AIRPORTNAME": "Santa Rosa Airport",
    },
    {
        "CITYNAME": "Sucre",
        "CITYCODE": "SRE",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "SRE",
        "AIRPORTNAME": "Sucre",
    },
    {
        "CITYNAME": "Semarang",
        "CITYCODE": "SRG",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "SRG",
        "AIRPORTNAME": "Achmad Uani",
    },
    {
        "CITYNAME": "Sarh",
        "CITYCODE": "SRH",
        "COUNTRYCODE": "TD",
        "COUNTRYNAME": "Chad",
        "AIRPORTCODE": "SRH",
        "AIRPORTNAME": "Sarh",
    },
    {
        "CITYNAME": "Samarinda",
        "CITYCODE": "SRI",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "SRI",
        "AIRPORTNAME": "Samarinda",
    },
    {
        "CITYNAME": "San Borja",
        "CITYCODE": "SRJ",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "SRJ",
        "AIRPORTNAME": "Capitan G Y Guardia",
    },
    {
        "CITYNAME": "Strahan",
        "CITYCODE": "SRN",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "SRN",
        "AIRPORTNAME": "Strahan",
    },
    {
        "CITYNAME": "Santana Ramos",
        "CITYCODE": "SRO",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "SRO",
        "AIRPORTNAME": "Santana Ramos",
    },
    {
        "CITYNAME": "Stord",
        "CITYCODE": "SRP",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "SRP",
        "AIRPORTNAME": "Stord",
    },
    {
        "CITYNAME": "Sarasota",
        "CITYCODE": "SRQ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SRQ",
        "AIRPORTNAME": "Bradenton",
    },
    {
        "CITYNAME": "Stony River",
        "CITYCODE": "SRV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SRV",
        "AIRPORTNAME": "Stony River",
    },
    {
        "CITYNAME": "Sert",
        "CITYCODE": "SRX",
        "COUNTRYCODE": "LY",
        "COUNTRYNAME": "Libyan Arab Jamahiriya",
        "AIRPORTCODE": "SRX",
        "AIRPORTNAME": "Sert",
    },
    {
        "CITYNAME": "Santa Cruz",
        "CITYCODE": "SRZ",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "SRZ",
        "AIRPORTNAME": "El Trompillo",
    },
    {
        "CITYNAME": "Santa Cruz",
        "CITYCODE": "SRZ",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "VVI",
        "AIRPORTNAME": "Viru Viru Int'l",
    },
    {
        "CITYNAME": "Salvador",
        "CITYCODE": "SSA",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "SSA",
        "AIRPORTNAME": "Dois De Julho",
    },
    {
        "CITYNAME": "Sholapur",
        "CITYCODE": "SSE",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "SSE",
        "AIRPORTNAME": "Sholapur",
    },
    {
        "CITYNAME": "Malabo",
        "CITYCODE": "SSG",
        "COUNTRYCODE": "GQ",
        "COUNTRYNAME": "Equatorial Guinea",
        "AIRPORTCODE": "SSG",
        "AIRPORTNAME": "Santa Isabel",
    },
    {
        "CITYNAME": "Sharm El Sheikh",
        "CITYCODE": "SSH",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "SSH",
        "AIRPORTNAME": "Ophira",
    },
    {
        "CITYNAME": "Brunswick",
        "CITYCODE": "SSI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BQK",
        "AIRPORTNAME": "Glynco Jetport",
    },
    {
        "CITYNAME": "Sandnessjoen",
        "CITYCODE": "SSJ",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "SSJ",
        "AIRPORTNAME": "Stokka",
    },
    {
        "CITYNAME": "Sault Ste Marie",
        "CITYCODE": "SSM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "CIU",
        "AIRPORTNAME": "Chippewa County",
    },
    {
        "CITYNAME": "Sara",
        "CITYCODE": "SSR",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "SSR",
        "AIRPORTNAME": "Sara",
    },
    {
        "CITYNAME": "Siassi",
        "CITYCODE": "SSS",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "SSS",
        "AIRPORTNAME": "Siassi",
    },
    {
        "CITYNAME": "M'Banza Congo",
        "CITYCODE": "SSY",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "SSY",
        "AIRPORTNAME": "M'Banza Congo",
    },
    {
        "CITYNAME": "Stauning",
        "CITYCODE": "STA",
        "COUNTRYCODE": "DK",
        "COUNTRYNAME": "Denmark",
        "AIRPORTCODE": "STA",
        "AIRPORTNAME": "Stauning",
    },
    {
        "CITYNAME": "Santa Barbara Ed",
        "CITYCODE": "STB",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "STB",
        "AIRPORTNAME": "L Delicias",
    },
    {
        "CITYNAME": "Santo Domingo",
        "CITYCODE": "STD",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "STD",
        "AIRPORTNAME": "Mayo Guerrero",
    },
    {
        "CITYNAME": "St George Island",
        "CITYCODE": "STG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "STG",
        "AIRPORTNAME": "St George Island",
    },
    {
        "CITYNAME": "Santiago",
        "CITYCODE": "STI",
        "COUNTRYCODE": "DO",
        "COUNTRYNAME": "Dominican Republic",
        "AIRPORTCODE": "STI",
        "AIRPORTNAME": "Santiago Municipal",
    },
    {
        "CITYNAME": "St Louis",
        "CITYCODE": "STL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "STL",
        "AIRPORTNAME": "Lambert-St Louis Int'l",
    },
    {
        "CITYNAME": "Santarem",
        "CITYCODE": "STM",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "STM",
        "AIRPORTNAME": "Eduardo Gomes",
    },
    {
        "CITYNAME": "Stockholm",
        "CITYCODE": "STO",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "ARN",
        "AIRPORTNAME": "Arlanda",
    },
    {
        "CITYNAME": "Stockholm",
        "CITYCODE": "STO",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "BMA",
        "AIRPORTNAME": "Bromma",
    },
    {
        "CITYNAME": "Stockholm",
        "CITYCODE": "STO",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "NYO",
        "AIRPORTNAME": "Skavsta",
    },
    {
        "CITYNAME": "St Paul",
        "CITYCODE": "STP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "STP",
        "AIRPORTNAME": "St Paul Downtown",
    },
    {
        "CITYNAME": "Stuttgart",
        "CITYCODE": "STR",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "STR",
        "AIRPORTNAME": "Stuttgart-Echterdingen",
    },
    {
        "CITYNAME": "Santa Rosa",
        "CITYCODE": "STS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "STS",
        "AIRPORTNAME": "Sonoma County",
    },
    {
        "CITYNAME": "St Thomas",
        "CITYCODE": "STT",
        "COUNTRYCODE": "VI",
        "COUNTRYNAME": "Virgin Islands (US)",
        "AIRPORTCODE": "STT",
        "AIRPORTNAME": "St Thomas Island",
    },
    {
        "CITYNAME": "Surat",
        "CITYCODE": "STV",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "STV",
        "AIRPORTNAME": "Surat",
    },
    {
        "CITYNAME": "Stavropol",
        "CITYCODE": "STW",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "STW",
        "AIRPORTNAME": "Stavropol",
    },
    {
        "CITYNAME": "St Croix Island",
        "CITYCODE": "STX",
        "COUNTRYCODE": "VI",
        "COUNTRYNAME": "Virgin Islands (US)",
        "AIRPORTCODE": "STX",
        "AIRPORTNAME": "Henry E Rohlsen",
    },
    {
        "CITYNAME": "Salto",
        "CITYCODE": "STY",
        "COUNTRYCODE": "UY",
        "COUNTRYNAME": "Uruguay",
        "AIRPORTCODE": "STY",
        "AIRPORTNAME": "Salto",
    },
    {
        "CITYNAME": "Santa Terezinha",
        "CITYCODE": "STZ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "STZ",
        "AIRPORTNAME": "Confresa",
    },
    {
        "CITYNAME": "Stuart",
        "CITYCODE": "SUA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SUA",
        "AIRPORTNAME": "Witham Field",
    },
    {
        "CITYNAME": "Surabaya",
        "CITYCODE": "SUB",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "SUB",
        "AIRPORTNAME": "Juanda",
    },
    {
        "CITYNAME": "Sturgeon Bay",
        "CITYCODE": "SUE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SUE",
        "AIRPORTNAME": "Door County",
    },
    {
        "CITYNAME": "Lamezia Terme",
        "CITYCODE": "SUF",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "LTZ",
        "AIRPORTNAME": "Lamezia Terme Centrale Railway Station Airport",
    },
    {
        "CITYNAME": "Lamezia Terme",
        "CITYCODE": "SUF",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "SUF",
        "AIRPORTNAME": "S Eufemia",
    },
    {
        "CITYNAME": "Surigao",
        "CITYCODE": "SUG",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "SUG",
        "AIRPORTNAME": "Surigao Airport",
    },
    {
        "CITYNAME": "Sur",
        "CITYCODE": "SUH",
        "COUNTRYCODE": "OM",
        "COUNTRYNAME": "Oman",
        "AIRPORTCODE": "SUH",
        "AIRPORTNAME": "Sur",
    },
    {
        "CITYNAME": "Sukhumi",
        "CITYCODE": "SUI",
        "COUNTRYCODE": "GE",
        "COUNTRYNAME": "Georgia",
        "AIRPORTCODE": "SUI",
        "AIRPORTNAME": "Babusheri",
    },
    {
        "CITYNAME": "Satu Mare",
        "CITYCODE": "SUJ",
        "COUNTRYCODE": "RO",
        "COUNTRYNAME": "Romania",
        "AIRPORTCODE": "SUJ",
        "AIRPORTNAME": "Satu Mare",
    },
    {
        "CITYNAME": "Sui",
        "CITYCODE": "SUL",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "SUL",
        "AIRPORTNAME": "Sui",
    },
    {
        "CITYNAME": "Hailey",
        "CITYCODE": "SUN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SUN",
        "AIRPORTNAME": "Sun Valley",
    },
    {
        "CITYNAME": "Sumbawanga",
        "CITYCODE": "SUT",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "SUT",
        "AIRPORTNAME": "Sumbawanga",
    },
    {
        "CITYNAME": "Suva",
        "CITYCODE": "SUV",
        "COUNTRYCODE": "FJ",
        "COUNTRYNAME": "Fiji Islands",
        "AIRPORTCODE": "SUV",
        "AIRPORTNAME": "Nausori",
    },
    {
        "CITYNAME": "Sioux City",
        "CITYCODE": "SUX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SUX",
        "AIRPORTNAME": "Siouc Gateway",
    },
    {
        "CITYNAME": "Savoonga",
        "CITYCODE": "SVA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SVA",
        "AIRPORTNAME": "Savoonga",
    },
    {
        "CITYNAME": "Sambava",
        "CITYCODE": "SVB",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "SVB",
        "AIRPORTNAME": "Sambava",
    },
    {
        "CITYNAME": "Silver City",
        "CITYCODE": "SVC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SVC",
        "AIRPORTNAME": "Grant County",
    },
    {
        "CITYNAME": "St Vincent",
        "CITYCODE": "SVD",
        "COUNTRYCODE": "VC",
        "COUNTRYNAME": "St. Vincent & the Grenadines",
        "AIRPORTCODE": "SVD",
        "AIRPORTNAME": "St Vincent",
    },
    {
        "CITYNAME": "Stavanger",
        "CITYCODE": "SVG",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "SVG",
        "AIRPORTNAME": "Sola",
    },
    {
        "CITYNAME": "San Vicente",
        "CITYCODE": "SVI",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "SVI",
        "AIRPORTNAME": "San Vicente",
    },
    {
        "CITYNAME": "Svolvaer",
        "CITYCODE": "SVJ",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "SVJ",
        "AIRPORTNAME": "Helle",
    },
    {
        "CITYNAME": "Savonlinna",
        "CITYCODE": "SVL",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "SVL",
        "AIRPORTNAME": "Savonlinna",
    },
    {
        "CITYNAME": "Kuito",
        "CITYCODE": "SVP",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "SVP",
        "AIRPORTNAME": "Kuito",
    },
    {
        "CITYNAME": "Sevilla",
        "CITYCODE": "SVQ",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "SVQ",
        "AIRPORTNAME": "Sevilla",
    },
    {
        "CITYNAME": "Stevens Village",
        "CITYCODE": "SVS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SVS",
        "AIRPORTNAME": "Stevens Village",
    },
    {
        "CITYNAME": "Savusavu",
        "CITYCODE": "SVU",
        "COUNTRYCODE": "FJ",
        "COUNTRYNAME": "Fiji Islands",
        "AIRPORTCODE": "SVU",
        "AIRPORTNAME": "Savusavu",
    },
    {
        "CITYNAME": "Ekaterinburg",
        "CITYCODE": "SVX",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "SVX",
        "AIRPORTNAME": "Ekaterinburg",
    },
    {
        "CITYNAME": "San Antonio",
        "CITYCODE": "SVZ",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "SVZ",
        "AIRPORTNAME": "San Antonio",
    },
    {
        "CITYNAME": "Shantou",
        "CITYCODE": "SWA",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "SWA",
        "AIRPORTNAME": "Shantou",
    },
    {
        "CITYNAME": "Seward",
        "CITYCODE": "SWD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SWD",
        "AIRPORTNAME": "Seward",
    },
    {
        "CITYNAME": "Satwag",
        "CITYCODE": "SWG",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "SWG",
        "AIRPORTNAME": "Satwag",
    },
    {
        "CITYNAME": "South West Bay",
        "CITYCODE": "SWJ",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "SWJ",
        "AIRPORTNAME": "South West Bay",
    },
    {
        "CITYNAME": "Stillwater",
        "CITYCODE": "SWO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SWO",
        "AIRPORTNAME": "Stillwater Regional Airport",
    },
    {
        "CITYNAME": "Swakopmund",
        "CITYCODE": "SWP",
        "COUNTRYCODE": "NA",
        "COUNTRYNAME": "Namibia",
        "AIRPORTCODE": "SWP",
        "AIRPORTNAME": "Swakopmund",
    },
    {
        "CITYNAME": "Sumbawa",
        "CITYCODE": "SWQ",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "SWQ",
        "AIRPORTNAME": "Brang Bidji",
    },
    {
        "CITYNAME": "Swansea",
        "CITYCODE": "SWS",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "SWS",
        "AIRPORTNAME": "Swansea Airport",
    },
    {
        "CITYNAME": "Shakawe",
        "CITYCODE": "SWX",
        "COUNTRYCODE": "BW",
        "COUNTRYNAME": "Botswana",
        "AIRPORTCODE": "SWX",
        "AIRPORTNAME": "Shakawe",
    },
    {
        "CITYNAME": "Sitiawan",
        "CITYCODE": "SWY",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "SWY",
        "AIRPORTNAME": "Sitiawan",
    },
    {
        "CITYNAME": "Strasbourg",
        "CITYCODE": "SXB",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "SXB",
        "AIRPORTNAME": "Entzheim",
    },
    {
        "CITYNAME": "Sophia Antipolis",
        "CITYCODE": "SXD",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "SXD",
        "AIRPORTNAME": "Sophia Antipolis",
    },
    {
        "CITYNAME": "Sehulea",
        "CITYCODE": "SXH",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "SXH",
        "AIRPORTNAME": "Sehulea",
    },
    {
        "CITYNAME": "Sligo",
        "CITYCODE": "SXL",
        "COUNTRYCODE": "IE",
        "COUNTRYNAME": "Ireland",
        "AIRPORTCODE": "SXL",
        "AIRPORTNAME": "Collooney",
    },
    {
        "CITYNAME": "St Maarten",
        "CITYCODE": "SXM",
        "COUNTRYCODE": "AN",
        "COUNTRYNAME": "Netherlands Antilles",
        "AIRPORTCODE": "SXM",
        "AIRPORTNAME": "St Maarten",
    },
    {
        "CITYNAME": "Sao Felix Do Arag",
        "CITYCODE": "SXO",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "SXO",
        "AIRPORTNAME": "Sao Felix Do Arag",
    },
    {
        "CITYNAME": "Srinagar",
        "CITYCODE": "SXR",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "SXR",
        "AIRPORTNAME": "Srinagar International Airport",
    },
    {
        "CITYNAME": "Sahabat 16",
        "CITYCODE": "SXS",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "SXS",
        "AIRPORTNAME": "Sahabat 16",
    },
    {
        "CITYNAME": "Taman Negara",
        "CITYCODE": "SXT",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "SXT",
        "AIRPORTNAME": "Taman Negara",
    },
    {
        "CITYNAME": "Soddu",
        "CITYCODE": "SXU",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "SXU",
        "AIRPORTNAME": "Soddu",
    },
    {
        "CITYNAME": "Salem",
        "CITYCODE": "SXV",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "SXV",
        "AIRPORTNAME": "Salem",
    },
    {
        "CITYNAME": "Siirt",
        "CITYCODE": "SXZ",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "SXZ",
        "AIRPORTNAME": "Siirt",
    },
    {
        "CITYNAME": "Seal Bay",
        "CITYCODE": "SYB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SYB",
        "AIRPORTNAME": "Seal Bay",
    },
    {
        "CITYNAME": "Sydney",
        "CITYCODE": "SYD",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "SYD",
        "AIRPORTNAME": "Kingsford Smith",
    },
    {
        "CITYNAME": "Syracuse",
        "CITYCODE": "SYR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "SYR",
        "AIRPORTNAME": "Hancock Int'l",
    },
    {
        "CITYNAME": "Sue Island",
        "CITYCODE": "SYU",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "SYU",
        "AIRPORTNAME": "Warraber Island",
    },
    {
        "CITYNAME": "Sanya",
        "CITYCODE": "SYX",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "SYX",
        "AIRPORTNAME": "Sanya",
    },
    {
        "CITYNAME": "Stornoway",
        "CITYCODE": "SYY",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "SYY",
        "AIRPORTNAME": "Stornoway",
    },
    {
        "CITYNAME": "Shiraz",
        "CITYCODE": "SYZ",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "SYZ",
        "AIRPORTNAME": "Shiraz",
    },
    {
        "CITYNAME": "Soyo",
        "CITYCODE": "SZA",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "SZA",
        "AIRPORTNAME": "Soyo",
    },
    {
        "CITYNAME": "Samsun",
        "CITYCODE": "SZF",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "SZF",
        "AIRPORTNAME": "Samsun Carsamba Airport",
    },
    {
        "CITYNAME": "Salzburg",
        "CITYCODE": "SZG",
        "COUNTRYCODE": "AT",
        "COUNTRYNAME": "Austria",
        "AIRPORTCODE": "SZG",
        "AIRPORTNAME": "Salzburg",
    },
    {
        "CITYNAME": "Skukuza",
        "CITYCODE": "SZK",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "SZK",
        "AIRPORTNAME": "Skukuza",
    },
    {
        "CITYNAME": "Stewart Island",
        "CITYCODE": "SZS",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "SZS",
        "AIRPORTNAME": "Stewart Island",
    },
    {
        "CITYNAME": "Shenzhen",
        "CITYCODE": "SZX",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "FYG",
        "AIRPORTNAME": "FUYONG FERRY PORT",
    },
    {
        "CITYNAME": "Shenzhen",
        "CITYCODE": "SZX",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "SZX",
        "AIRPORTNAME": "Shenzhen",
    },
    {
        "CITYNAME": "Szymany",
        "CITYCODE": "SZY",
        "COUNTRYCODE": "PL",
        "COUNTRYNAME": "Poland",
        "AIRPORTCODE": "SZY",
        "AIRPORTNAME": "Olsztyn - Mazury Airport",
    },
    {
        "CITYNAME": "Szczecin",
        "CITYCODE": "SZZ",
        "COUNTRYCODE": "PL",
        "COUNTRYNAME": "Poland",
        "AIRPORTCODE": "SZZ",
        "AIRPORTNAME": "Goleviow",
    },
    {
        "CITYNAME": "Tobago",
        "CITYCODE": "TAB",
        "COUNTRYCODE": "TT",
        "COUNTRYNAME": "Trinidad and Tobago",
        "AIRPORTCODE": "TAB",
        "AIRPORTNAME": "Tobago",
    },
    {
        "CITYNAME": "Tacloban",
        "CITYCODE": "TAC",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "TAC",
        "AIRPORTNAME": "D.Z. Romualdez",
    },
    {
        "CITYNAME": "Taegu",
        "CITYCODE": "TAE",
        "COUNTRYCODE": "KR",
        "COUNTRYNAME": "South Korea",
        "AIRPORTCODE": "TAE",
        "AIRPORTNAME": "Taegu",
    },
    {
        "CITYNAME": "Tagbilaran",
        "CITYCODE": "TAG",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "TAG",
        "AIRPORTNAME": "Tagbilaran Airport",
    },
    {
        "CITYNAME": "Tanna",
        "CITYCODE": "TAH",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "TAH",
        "AIRPORTNAME": "Tanna",
    },
    {
        "CITYNAME": "Taiz",
        "CITYCODE": "TAI",
        "COUNTRYCODE": "YE",
        "COUNTRYNAME": "Yemen",
        "AIRPORTCODE": "TAI",
        "AIRPORTNAME": "Tadji",
    },
    {
        "CITYNAME": "Takamatsu",
        "CITYCODE": "TAK",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "TAK",
        "AIRPORTNAME": "Takamatsu",
    },
    {
        "CITYNAME": "Tanana",
        "CITYCODE": "TAL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TAL",
        "AIRPORTNAME": "Ralph Calhoun",
    },
    {
        "CITYNAME": "Tampico",
        "CITYCODE": "TAM",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "TAM",
        "AIRPORTNAME": "Gen F Javier Mina",
    },
    {
        "CITYNAME": "Qingdao",
        "CITYCODE": "TAO",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "TAO",
        "AIRPORTNAME": "Qingdao",
    },
    {
        "CITYNAME": "Tapachula Intl",
        "CITYCODE": "TAP",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "TAP",
        "AIRPORTNAME": "Tapachula Int'l",
    },
    {
        "CITYNAME": "Tarcoola",
        "CITYCODE": "TAQ",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "TAQ",
        "AIRPORTNAME": "TARCOOLA",
    },
    {
        "CITYNAME": "Tashkent",
        "CITYCODE": "TAS",
        "COUNTRYCODE": "UZ",
        "COUNTRYNAME": "Uzbekistan",
        "AIRPORTCODE": "TAS",
        "AIRPORTNAME": "Tashkent",
    },
    {
        "CITYNAME": "Tatry Poprad",
        "CITYCODE": "TAT",
        "COUNTRYCODE": "SK",
        "COUNTRYNAME": "Slovakia",
        "AIRPORTCODE": "TAT",
        "AIRPORTNAME": "Tatry Poprad",
    },
    {
        "CITYNAME": "Tau",
        "CITYCODE": "TAV",
        "COUNTRYCODE": "AS",
        "COUNTRYNAME": "American Samoa",
        "AIRPORTCODE": "TAV",
        "AIRPORTNAME": "Tau",
    },
    {
        "CITYNAME": "Tacuarembo",
        "CITYCODE": "TAW",
        "COUNTRYCODE": "UY",
        "COUNTRYNAME": "Uruguay",
        "AIRPORTCODE": "TAW",
        "AIRPORTNAME": "Tacuarembo",
    },
    {
        "CITYNAME": "Tartu",
        "CITYCODE": "TAY",
        "COUNTRYCODE": "EE",
        "COUNTRYNAME": "Estonia",
        "AIRPORTCODE": "TAY",
        "AIRPORTNAME": "Tartu Ulenurme",
    },
    {
        "CITYNAME": "Tashauz",
        "CITYCODE": "TAZ",
        "COUNTRYCODE": "TM",
        "COUNTRYNAME": "Turkmenistan",
        "AIRPORTCODE": "TAZ",
        "AIRPORTNAME": "Tashauz",
    },
    {
        "CITYNAME": "Tuy Hoa",
        "CITYCODE": "TBB",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "TBB",
        "AIRPORTNAME": "Dong Tac Airport",
    },
    {
        "CITYNAME": "Timbunke",
        "CITYCODE": "TBE",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "TBE",
        "AIRPORTNAME": "Timbunke",
    },
    {
        "CITYNAME": "Tabiteuea North",
        "CITYCODE": "TBF",
        "COUNTRYCODE": "KI",
        "COUNTRYNAME": "Kiribati",
        "AIRPORTCODE": "TBF",
        "AIRPORTNAME": "Tabiteuea North",
    },
    {
        "CITYNAME": "Tabubil",
        "CITYCODE": "TBG",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "TBG",
        "AIRPORTNAME": "Tabubil",
    },
    {
        "CITYNAME": "Tablas",
        "CITYCODE": "TBH",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "TBH",
        "AIRPORTNAME": "Tugdan Airport",
    },
    {
        "CITYNAME": "Tabarka",
        "CITYCODE": "TBJ",
        "COUNTRYCODE": "TN",
        "COUNTRYNAME": "Tunisia",
        "AIRPORTCODE": "TBJ",
        "AIRPORTNAME": "Tabarka Ain Draham Intl Airport",
    },
    {
        "CITYNAME": "Ftlenwood Tribune",
        "CITYCODE": "TBN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TBN",
        "AIRPORTNAME": "Forney Aaf",
    },
    {
        "CITYNAME": "Tabora",
        "CITYCODE": "TBO",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "TBO",
        "AIRPORTNAME": "Tabora",
    },
    {
        "CITYNAME": "Tumbes",
        "CITYCODE": "TBP",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "TBP",
        "AIRPORTNAME": "Tumbes",
    },
    {
        "CITYNAME": "Tbilisi",
        "CITYCODE": "TBS",
        "COUNTRYCODE": "GE",
        "COUNTRYNAME": "Georgia",
        "AIRPORTCODE": "TBS",
        "AIRPORTNAME": "Novo Alexeyevka",
    },
    {
        "CITYNAME": "Tabatinga",
        "CITYCODE": "TBT",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "TBT",
        "AIRPORTNAME": "Tabatinga Internacional",
    },
    {
        "CITYNAME": "Tongatapu",
        "CITYCODE": "TBU",
        "COUNTRYCODE": "TO",
        "COUNTRYNAME": "Tonga",
        "AIRPORTCODE": "TBU",
        "AIRPORTNAME": "Tongatapu Int'l",
    },
    {
        "CITYNAME": "Tsabong",
        "CITYCODE": "TBY",
        "COUNTRYCODE": "BW",
        "COUNTRYNAME": "Botswana",
        "AIRPORTCODE": "TBY",
        "AIRPORTNAME": "Tsabong",
    },
    {
        "CITYNAME": "Tabriz",
        "CITYCODE": "TBZ",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "TBZ",
        "AIRPORTNAME": "Tabriz",
    },
    {
        "CITYNAME": "Tennant Creek",
        "CITYCODE": "TCA",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "TCA",
        "AIRPORTNAME": "Tennant Creek",
    },
    {
        "CITYNAME": "Treasure Cay",
        "CITYCODE": "TCB",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "TCB",
        "AIRPORTNAME": "Treasure Cay",
    },
    {
        "CITYNAME": "Tchibanga",
        "CITYCODE": "TCH",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "TCH",
        "AIRPORTNAME": "Tchibanga",
    },
    {
        "CITYNAME": "Tenerife",
        "CITYCODE": "TCI",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "TFN",
        "AIRPORTNAME": "Tenerife Norte Los Rodeos",
    },
    {
        "CITYNAME": "Tenerife",
        "CITYCODE": "TCI",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "TFS",
        "AIRPORTNAME": "Tenerife Sur Reina Sofia",
    },
    {
        "CITYNAME": "Tuscaloosa",
        "CITYCODE": "TCL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TCL",
        "AIRPORTNAME": "Van De Graaf",
    },
    {
        "CITYNAME": "Tehuacan",
        "CITYCODE": "TCN",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "TCN",
        "AIRPORTNAME": "Tehuacan",
    },
    {
        "CITYNAME": "Tumaco",
        "CITYCODE": "TCO",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "TCO",
        "AIRPORTNAME": "Tumaco",
    },
    {
        "CITYNAME": "Taba",
        "CITYCODE": "TCP",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "TCP",
        "AIRPORTNAME": "Taba international",
    },
    {
        "CITYNAME": "Tacna",
        "CITYCODE": "TCQ",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "TCQ",
        "AIRPORTNAME": "Tacna",
    },
    {
        "CITYNAME": "Tuticorin",
        "CITYCODE": "TCR",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "TCR",
        "AIRPORTNAME": "Tuticorin",
    },
    {
        "CITYNAME": "Takotna",
        "CITYCODE": "TCT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TCT",
        "AIRPORTNAME": "Takotna",
    },
    {
        "CITYNAME": "Thaba Nchu",
        "CITYCODE": "TCU",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "TCU",
        "AIRPORTNAME": "Thaba Nchu",
    },
    {
        "CITYNAME": "Trinidad",
        "CITYCODE": "TDA",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "TDA",
        "AIRPORTNAME": "Trinidad",
    },
    {
        "CITYNAME": "Tetabedi",
        "CITYCODE": "TDB",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "TDB",
        "AIRPORTNAME": "Tetabedi",
    },
    {
        "CITYNAME": "Trinidad",
        "CITYCODE": "TDD",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "TDD",
        "AIRPORTNAME": "Trinidad",
    },
    {
        "CITYNAME": "Tandag",
        "CITYCODE": "TDG",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "TDG",
        "AIRPORTNAME": "Tandag Apt",
    },
    {
        "CITYNAME": "Tadjoura",
        "CITYCODE": "TDJ",
        "COUNTRYCODE": "DJ",
        "COUNTRYNAME": "Djibouti",
        "AIRPORTCODE": "TDJ",
        "AIRPORTNAME": "Tadjoura",
    },
    {
        "CITYNAME": "Taldy Kurgan",
        "CITYCODE": "TDK",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "TDK",
        "AIRPORTNAME": "Taldykorgan Airport",
    },
    {
        "CITYNAME": "Tanandava",
        "CITYCODE": "TDV",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "TDV",
        "AIRPORTNAME": "Tanandava",
    },
    {
        "CITYNAME": "Trat",
        "CITYCODE": "TDX",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "TDX",
        "AIRPORTNAME": "Trat",
    },
    {
        "CITYNAME": "Teterboro",
        "CITYCODE": "TEB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TEB",
        "AIRPORTNAME": "Teterboro",
    },
    {
        "CITYNAME": "Thisted",
        "CITYCODE": "TED",
        "COUNTRYCODE": "DK",
        "COUNTRYNAME": "Denmark",
        "AIRPORTCODE": "TED",
        "AIRPORTNAME": "Thisted",
    },
    {
        "CITYNAME": "Tbessa",
        "CITYCODE": "TEE",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "TEE",
        "AIRPORTNAME": "Tbessa",
    },
    {
        "CITYNAME": "Telfer",
        "CITYCODE": "TEF",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "TEF",
        "AIRPORTNAME": "Telfer",
    },
    {
        "CITYNAME": "Tenkodogo",
        "CITYCODE": "TEG",
        "COUNTRYCODE": "BF",
        "COUNTRYNAME": "Burkina Faso",
        "AIRPORTCODE": "TEG",
        "AIRPORTNAME": "Tenkodogo",
    },
    {
        "CITYNAME": "Tezu",
        "CITYCODE": "TEI",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "TEI",
        "AIRPORTNAME": "Tezu",
    },
    {
        "CITYNAME": "Tongren",
        "CITYCODE": "TEN",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "TEN",
        "AIRPORTNAME": "Tongren Fenghuang Airport",
    },
    {
        "CITYNAME": "Terapo",
        "CITYCODE": "TEO",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "TEO",
        "AIRPORTNAME": "Terapo",
    },
    {
        "CITYNAME": "Teptep",
        "CITYCODE": "TEP",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "TEP",
        "AIRPORTNAME": "Teptep Airport",
    },
    {
        "CITYNAME": "Tekirdag",
        "CITYCODE": "TEQ",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "TEQ",
        "AIRPORTNAME": "Tekirdag Corlu Airport",
    },
    {
        "CITYNAME": "Terceira Island",
        "CITYCODE": "TER",
        "COUNTRYCODE": "PT",
        "COUNTRYNAME": "Portugal",
        "AIRPORTCODE": "TER",
        "AIRPORTNAME": "Lajes",
    },
    {
        "CITYNAME": "Tessenei",
        "CITYCODE": "TES",
        "COUNTRYCODE": "ER",
        "COUNTRYNAME": "Eritrea",
        "AIRPORTCODE": "TES",
        "AIRPORTNAME": "Tessenei",
    },
    {
        "CITYNAME": "Tete",
        "CITYCODE": "TET",
        "COUNTRYCODE": "MZ",
        "COUNTRYNAME": "Mozambique",
        "AIRPORTCODE": "TET",
        "AIRPORTNAME": "Matunda",
    },
    {
        "CITYNAME": "Te Anau",
        "CITYCODE": "TEU",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "TEU",
        "AIRPORTNAME": "Manapouri",
    },
    {
        "CITYNAME": "Telluride",
        "CITYCODE": "TEX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TEX",
        "AIRPORTNAME": "Telluride",
    },
    {
        "CITYNAME": "Thingeyri",
        "CITYCODE": "TEY",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "TEY",
        "AIRPORTNAME": "Thingeyri",
    },
    {
        "CITYNAME": "Tezpur",
        "CITYCODE": "TEZ",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "TEZ",
        "AIRPORTNAME": "Salonibari",
    },
    {
        "CITYNAME": "Tefe",
        "CITYCODE": "TFF",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "TFF",
        "AIRPORTNAME": "Tefe",
    },
    {
        "CITYNAME": "Tufi",
        "CITYCODE": "TFI",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "TFI",
        "AIRPORTNAME": "Tufi",
    },
    {
        "CITYNAME": "Teofilo Otoni",
        "CITYCODE": "TFL",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "TFL",
        "AIRPORTNAME": "Teofilo Otoni",
    },
    {
        "CITYNAME": "Telefomin",
        "CITYCODE": "TFM",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "TFM",
        "AIRPORTNAME": "Telefomin",
    },
    {
        "CITYNAME": "Podgorica",
        "CITYCODE": "TGD",
        "COUNTRYCODE": "YU",
        "COUNTRYNAME": "Yugoslavia",
        "AIRPORTCODE": "TGD",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Kuala Terengganu",
        "CITYCODE": "TGG",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "TGG",
        "AIRPORTNAME": "Sultan Mahmood",
    },
    {
        "CITYNAME": "Tongoa",
        "CITYCODE": "TGH",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "TGH",
        "AIRPORTNAME": "Tongoa",
    },
    {
        "CITYNAME": "Tingo Maria",
        "CITYCODE": "TGI",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "TGI",
        "AIRPORTNAME": "Tingo Maria",
    },
    {
        "CITYNAME": "Tiga",
        "CITYCODE": "TGJ",
        "COUNTRYCODE": "NC",
        "COUNTRYNAME": "New Caledonia",
        "AIRPORTCODE": "TGJ",
        "AIRPORTNAME": "Tiga",
    },
    {
        "CITYNAME": "Tirgu Mures",
        "CITYCODE": "TGM",
        "COUNTRYCODE": "RO",
        "COUNTRYNAME": "Romania",
        "AIRPORTCODE": "TGM",
        "AIRPORTNAME": "Tirgu Mures",
    },
    {
        "CITYNAME": "Traralgon",
        "CITYCODE": "TGN",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "TGN",
        "AIRPORTNAME": "La Trobe Traralgon",
    },
    {
        "CITYNAME": "Tongliao",
        "CITYCODE": "TGO",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "TGO",
        "AIRPORTNAME": "Tongliao",
    },
    {
        "CITYNAME": "Touggourt",
        "CITYCODE": "TGR",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "TGR",
        "AIRPORTNAME": "Touggourt",
    },
    {
        "CITYNAME": "Tanga",
        "CITYCODE": "TGT",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "TGT",
        "AIRPORTNAME": "Tanga",
    },
    {
        "CITYNAME": "Tegucigalpa",
        "CITYCODE": "TGU",
        "COUNTRYCODE": "HN",
        "COUNTRYNAME": "Honduras",
        "AIRPORTCODE": "TGU",
        "AIRPORTNAME": "Toncontin",
    },
    {
        "CITYNAME": "Targovishte",
        "CITYCODE": "TGV",
        "COUNTRYCODE": "BG",
        "COUNTRYNAME": "Bulgaria",
        "AIRPORTCODE": "TGV",
        "AIRPORTNAME": "Targovishte",
    },
    {
        "CITYNAME": "Tuxtla Gutierrez",
        "CITYCODE": "TGZ",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "TGZ",
        "AIRPORTNAME": "Tuxtla Gutierrez",
    },
    {
        "CITYNAME": "Tchien",
        "CITYCODE": "THC",
        "COUNTRYCODE": "LR",
        "COUNTRYNAME": "Liberia",
        "AIRPORTCODE": "THC",
        "AIRPORTNAME": "Tchien",
    },
    {
        "CITYNAME": "Tinh Gia",
        "CITYCODE": "THD",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "THD",
        "AIRPORTNAME": "Tinh Gia Thanh Hoa Airport",
    },
    {
        "CITYNAME": "Teresina",
        "CITYCODE": "THE",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "THE",
        "AIRPORTNAME": "Teresina",
    },
    {
        "CITYNAME": "Thangool",
        "CITYCODE": "THG",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "THG",
        "AIRPORTNAME": "Thangool",
    },
    {
        "CITYNAME": "Tichitt",
        "CITYCODE": "THI",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "THI",
        "AIRPORTNAME": "Tichitt",
    },
    {
        "CITYNAME": "Thakhek",
        "CITYCODE": "THK",
        "COUNTRYCODE": "LA",
        "COUNTRYNAME": "Lao People's Democratic Republic",
        "AIRPORTCODE": "THK",
        "AIRPORTNAME": "Thakhek",
    },
    {
        "CITYNAME": "Tachilek",
        "CITYCODE": "THL",
        "COUNTRYCODE": "MM",
        "COUNTRYNAME": "Myanmar",
        "AIRPORTCODE": "THL",
        "AIRPORTNAME": "Tachilek",
    },
    {
        "CITYNAME": "Trollhattan",
        "CITYCODE": "THN",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "THN",
        "AIRPORTNAME": "Trollhattan",
    },
    {
        "CITYNAME": "Thorshofn",
        "CITYCODE": "THO",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "THO",
        "AIRPORTNAME": "Thorshofn",
    },
    {
        "CITYNAME": "Tehran",
        "CITYCODE": "THR",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "IKA",
        "AIRPORTNAME": "Imam Khomeini Airport",
    },
    {
        "CITYNAME": "Tehran",
        "CITYCODE": "THR",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "THR",
        "AIRPORTNAME": "Mehrabad",
    },
    {
        "CITYNAME": "Sukhothai",
        "CITYCODE": "THS",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "THS",
        "AIRPORTNAME": "SUKHOTHAI",
    },
    {
        "CITYNAME": "Tamchakett",
        "CITYCODE": "THT",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "THT",
        "AIRPORTNAME": "Tamchakett",
    },
    {
        "CITYNAME": "Thohoyandou",
        "CITYCODE": "THY",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "THY",
        "AIRPORTNAME": "Thohoyandou",
    },
    {
        "CITYNAME": "Tahoua",
        "CITYCODE": "THZ",
        "COUNTRYCODE": "NE",
        "COUNTRYNAME": "Niger",
        "AIRPORTCODE": "THZ",
        "AIRPORTNAME": "Tahoua",
    },
    {
        "CITYNAME": "Tirana",
        "CITYCODE": "TIA",
        "COUNTRYCODE": "AL",
        "COUNTRYNAME": "Albania",
        "AIRPORTCODE": "TIA",
        "AIRPORTNAME": "Rinas",
    },
    {
        "CITYNAME": "Tinak Island",
        "CITYCODE": "TIC",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "TIC",
        "AIRPORTNAME": "Tinak Island",
    },
    {
        "CITYNAME": "Tiaret",
        "CITYCODE": "TID",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "TID",
        "AIRPORTNAME": "Bouchekif",
    },
    {
        "CITYNAME": "Tippi",
        "CITYCODE": "TIE",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "TIE",
        "AIRPORTNAME": "Tippi",
    },
    {
        "CITYNAME": "Taif",
        "CITYCODE": "TIF",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "TIF",
        "AIRPORTNAME": "Taif Airport",
    },
    {
        "CITYNAME": "Tikehau Atoll",
        "CITYCODE": "TIH",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "TIH",
        "AIRPORTNAME": "Tikehau Atoll",
    },
    {
        "CITYNAME": "Tirinkot",
        "CITYCODE": "TII",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "TII",
        "AIRPORTNAME": "Tirinkot",
    },
    {
        "CITYNAME": "Tijuana",
        "CITYCODE": "TIJ",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "TIJ",
        "AIRPORTNAME": "Rodriguez",
    },
    {
        "CITYNAME": "Tembagapura",
        "CITYCODE": "TIM",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "TIM",
        "AIRPORTNAME": "Timika",
    },
    {
        "CITYNAME": "Tindouf",
        "CITYCODE": "TIN",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "TIN",
        "AIRPORTNAME": "Tindouf",
    },
    {
        "CITYNAME": "Tripoli",
        "CITYCODE": "TIP",
        "COUNTRYCODE": "LY",
        "COUNTRYNAME": "Libyan Arab Jamahiriya",
        "AIRPORTCODE": "TIP",
        "AIRPORTNAME": "Tripoli Int'l",
    },
    {
        "CITYNAME": "Tinian",
        "CITYCODE": "TIQ",
        "COUNTRYCODE": "MP",
        "COUNTRYNAME": "Northern Mariana Islands",
        "AIRPORTCODE": "TIQ",
        "AIRPORTNAME": "Tinian",
    },
    {
        "CITYNAME": "Tirupati",
        "CITYCODE": "TIR",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "TIR",
        "AIRPORTNAME": "Tirupati",
    },
    {
        "CITYNAME": "Thursday Island",
        "CITYCODE": "TIS",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "TIS",
        "AIRPORTNAME": "Thursday Island",
    },
    {
        "CITYNAME": "Timaru",
        "CITYCODE": "TIU",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "TIU",
        "AIRPORTNAME": "Timaru",
    },
    {
        "CITYNAME": "Tivat",
        "CITYCODE": "TIV",
        "COUNTRYCODE": "YU",
        "COUNTRYNAME": "Yugoslavia",
        "AIRPORTCODE": "TIV",
        "AIRPORTNAME": "Tivat",
    },
    {
        "CITYNAME": "Titusville",
        "CITYCODE": "TIX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TIX",
        "AIRPORTNAME": "Space Center Exect.",
    },
    {
        "CITYNAME": "Tidjikja",
        "CITYCODE": "TIY",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "TIY",
        "AIRPORTNAME": "Tidjikja",
    },
    {
        "CITYNAME": "Tari",
        "CITYCODE": "TIZ",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "TIZ",
        "AIRPORTNAME": "Tari",
    },
    {
        "CITYNAME": "Tarija",
        "CITYCODE": "TJA",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "TJA",
        "AIRPORTNAME": "Tarija",
    },
    {
        "CITYNAME": "Toyooka",
        "CITYCODE": "TJH",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "TJH",
        "AIRPORTNAME": "Tajima Airport",
    },
    {
        "CITYNAME": "Tokat",
        "CITYCODE": "TJK",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "TJK",
        "AIRPORTNAME": "Tokat",
    },
    {
        "CITYNAME": "Tres Lagoas",
        "CITYCODE": "TJL",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "TJL",
        "AIRPORTNAME": "Tres Lagoas",
    },
    {
        "CITYNAME": "Tyumen",
        "CITYCODE": "TJM",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "TJM",
        "AIRPORTNAME": "Tyumen",
    },
    {
        "CITYNAME": "Tandjung Pandan",
        "CITYCODE": "TJQ",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "TJQ",
        "AIRPORTNAME": "Bulutumbang",
    },
    {
        "CITYNAME": "Tanjung Selor",
        "CITYCODE": "TJS",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "TJS",
        "AIRPORTNAME": "Tanjung Selor",
    },
    {
        "CITYNAME": "Kulob",
        "CITYCODE": "TJU",
        "COUNTRYCODE": "TJ",
        "COUNTRYNAME": "Tajikistan",
        "AIRPORTCODE": "TJU",
        "AIRPORTNAME": "Kulob Airport",
    },
    {
        "CITYNAME": "Tiko",
        "CITYCODE": "TKC",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "TKC",
        "AIRPORTNAME": "Tiko",
    },
    {
        "CITYNAME": "Takoradi",
        "CITYCODE": "TKD",
        "COUNTRYCODE": "GH",
        "COUNTRYNAME": "Ghana",
        "AIRPORTCODE": "TKD",
        "AIRPORTNAME": "Takoradi",
    },
    {
        "CITYNAME": "Truckee",
        "CITYCODE": "TKF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TKF",
        "AIRPORTNAME": "Truckee",
    },
    {
        "CITYNAME": "Bandar Lampung",
        "CITYCODE": "TKG",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "TKG",
        "AIRPORTNAME": "Branit",
    },
    {
        "CITYNAME": "Takhli",
        "CITYCODE": "TKH",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "TKH",
        "AIRPORTNAME": "Takhli Airport",
    },
    {
        "CITYNAME": "Tokeen",
        "CITYCODE": "TKI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TKI",
        "AIRPORTNAME": "Tokeen",
    },
    {
        "CITYNAME": "Tok",
        "CITYCODE": "TKJ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TKJ",
        "AIRPORTNAME": "Tok",
    },
    {
        "CITYNAME": "Truk",
        "CITYCODE": "TKK",
        "COUNTRYCODE": "FM",
        "COUNTRYNAME": "Micronesia",
        "AIRPORTCODE": "TKK",
        "AIRPORTNAME": "Truk",
    },
    {
        "CITYNAME": "Tokunoshima",
        "CITYCODE": "TKN",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "TKN",
        "AIRPORTNAME": "Tokunoshima",
    },
    {
        "CITYNAME": "Takapoto",
        "CITYCODE": "TKP",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "TKP",
        "AIRPORTNAME": "Takapoto",
    },
    {
        "CITYNAME": "Kigoma",
        "CITYCODE": "TKQ",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "TKQ",
        "AIRPORTNAME": "Kigoma",
    },
    {
        "CITYNAME": "Tokushima",
        "CITYCODE": "TKS",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "TKS",
        "AIRPORTNAME": "Tokushima",
    },
    {
        "CITYNAME": "Turku",
        "CITYCODE": "TKU",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "TKU",
        "AIRPORTNAME": "Turku",
    },
    {
        "CITYNAME": "Tatakoto",
        "CITYCODE": "TKV",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "TKV",
        "AIRPORTNAME": "Tatakoto",
    },
    {
        "CITYNAME": "Takaroa",
        "CITYCODE": "TKX",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "TKX",
        "AIRPORTNAME": "Takaroa",
    },
    {
        "CITYNAME": "Teller",
        "CITYCODE": "TLA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TLA",
        "AIRPORTNAME": "Teller",
    },
    {
        "CITYNAME": "Tuli Lodge",
        "CITYCODE": "TLD",
        "COUNTRYCODE": "BW",
        "COUNTRYNAME": "Botswana",
        "AIRPORTCODE": "TLD",
        "AIRPORTNAME": "Tuli Lodge",
    },
    {
        "CITYNAME": "Tulear",
        "CITYCODE": "TLE",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "TLE",
        "AIRPORTNAME": "Tulear",
    },
    {
        "CITYNAME": "Tallahassee",
        "CITYCODE": "TLH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TLH",
        "AIRPORTNAME": "Tallahassee Municipal",
    },
    {
        "CITYNAME": "Tolitoli",
        "CITYCODE": "TLI",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "TLI",
        "AIRPORTNAME": "Tolitoli",
    },
    {
        "CITYNAME": "Tallinn",
        "CITYCODE": "TLL",
        "COUNTRYCODE": "EE",
        "COUNTRYNAME": "Estonia",
        "AIRPORTCODE": "TLL",
        "AIRPORTNAME": "Ulemiste",
    },
    {
        "CITYNAME": "Tlemcen",
        "CITYCODE": "TLM",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "TLM",
        "AIRPORTNAME": "Zenata",
    },
    {
        "CITYNAME": "Tumolbil",
        "CITYCODE": "TLP",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "TLP",
        "AIRPORTNAME": "Tumolbil",
    },
    {
        "CITYNAME": "Toulouse",
        "CITYCODE": "TLS",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "TLS",
        "AIRPORTNAME": "Blagnac",
    },
    {
        "CITYNAME": "Tuluksak",
        "CITYCODE": "TLT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TLT",
        "AIRPORTNAME": "Tuluksak",
    },
    {
        "CITYNAME": "Tel Aviv",
        "CITYCODE": "TLV",
        "COUNTRYCODE": "IL",
        "COUNTRYNAME": "Israel",
        "AIRPORTCODE": "SDV",
        "AIRPORTNAME": "Sde Dov",
    },
    {
        "CITYNAME": "Tel Aviv",
        "CITYCODE": "TLV",
        "COUNTRYCODE": "IL",
        "COUNTRYNAME": "Israel",
        "AIRPORTCODE": "TLV",
        "AIRPORTNAME": "Ben Gurion Int'l",
    },
    {
        "CITYNAME": "Tambolaka",
        "CITYCODE": "TMC",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "TMC",
        "AIRPORTNAME": "Tambolaka",
    },
    {
        "CITYNAME": "Timbedra",
        "CITYCODE": "TMD",
        "COUNTRYCODE": "MR",
        "COUNTRYNAME": "Mauritania",
        "AIRPORTCODE": "TMD",
        "AIRPORTNAME": "Timbedra",
    },
    {
        "CITYNAME": "Tame",
        "CITYCODE": "TME",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "TME",
        "AIRPORTNAME": "Tame",
    },
    {
        "CITYNAME": "Tomanggong",
        "CITYCODE": "TMG",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "TMG",
        "AIRPORTNAME": "Tomanggong",
    },
    {
        "CITYNAME": "Tanahmerah",
        "CITYCODE": "TMH",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "TMH",
        "AIRPORTNAME": "Tanahmerah",
    },
    {
        "CITYNAME": "Termez Uz",
        "CITYCODE": "TMJ",
        "COUNTRYCODE": "UZ",
        "COUNTRYNAME": "Uzbekistan",
        "AIRPORTCODE": "TMJ",
        "AIRPORTNAME": "Termez",
    },
    {
        "CITYNAME": "Tamale",
        "CITYCODE": "TML",
        "COUNTRYCODE": "GH",
        "COUNTRYNAME": "Ghana",
        "AIRPORTCODE": "TML",
        "AIRPORTNAME": "Tamale",
    },
    {
        "CITYNAME": "Tamatave",
        "CITYCODE": "TMM",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "TMM",
        "AIRPORTNAME": "Tamatave",
    },
    {
        "CITYNAME": "Tamana Island",
        "CITYCODE": "TMN",
        "COUNTRYCODE": "KI",
        "COUNTRYNAME": "Kiribati",
        "AIRPORTCODE": "TMN",
        "AIRPORTNAME": "Tamana Island",
    },
    {
        "CITYNAME": "Tampere",
        "CITYCODE": "TMP",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "TMP",
        "AIRPORTNAME": "Tampere-Pirkkala",
    },
    {
        "CITYNAME": "Tamanrasset",
        "CITYCODE": "TMR",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "TMR",
        "AIRPORTNAME": "Aguemar",
    },
    {
        "CITYNAME": "Sao Tome Island",
        "CITYCODE": "TMS",
        "COUNTRYCODE": "ST",
        "COUNTRYNAME": "Sao Tome & Principe",
        "AIRPORTCODE": "TMS",
        "AIRPORTNAME": "Sao Tome Is",
    },
    {
        "CITYNAME": "Trombetas",
        "CITYCODE": "TMT",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "TMT",
        "AIRPORTNAME": "Trombetas",
    },
    {
        "CITYNAME": "Tamworth",
        "CITYCODE": "TMW",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "TMW",
        "AIRPORTNAME": "Tamworth",
    },
    {
        "CITYNAME": "Timimoun",
        "CITYCODE": "TMX",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "TMX",
        "AIRPORTNAME": "Timimoun Airport",
    },
    {
        "CITYNAME": "Tiom",
        "CITYCODE": "TMY",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "TMY",
        "AIRPORTNAME": "Tiom",
    },
    {
        "CITYNAME": "Jinan",
        "CITYCODE": "TNA",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "TNA",
        "AIRPORTNAME": "Jinan",
    },
    {
        "CITYNAME": "Tanegashima",
        "CITYCODE": "TNE",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "TNE",
        "AIRPORTNAME": "Tanegashima",
    },
    {
        "CITYNAME": "Tangier",
        "CITYCODE": "TNG",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "TNG",
        "AIRPORTNAME": "Boukhalef",
    },
    {
        "CITYNAME": "Satna",
        "CITYCODE": "TNI",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "TNI",
        "AIRPORTNAME": "Satna",
    },
    {
        "CITYNAME": "Tanjung Pinang",
        "CITYCODE": "TNJ",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "TNJ",
        "AIRPORTNAME": "Kidjang",
    },
    {
        "CITYNAME": "Tununak",
        "CITYCODE": "TNK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TNK",
        "AIRPORTNAME": "Tununak",
    },
    {
        "CITYNAME": "Tainan",
        "CITYCODE": "TNN",
        "COUNTRYCODE": "TW",
        "COUNTRYNAME": "Taiwan",
        "AIRPORTCODE": "TNN",
        "AIRPORTNAME": "Tainan",
    },
    {
        "CITYNAME": "Antananarivo",
        "CITYCODE": "TNR",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "TNR",
        "AIRPORTNAME": "Antananarivo",
    },
    {
        "CITYNAME": "Torrance",
        "CITYCODE": "TOA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TOA",
        "AIRPORTNAME": "Torrance",
    },
    {
        "CITYNAME": "Tobruk",
        "CITYCODE": "TOB",
        "COUNTRYCODE": "LY",
        "COUNTRYNAME": "Libyan Arab Jamahiriya",
        "AIRPORTCODE": "TOB",
        "AIRPORTNAME": "Tobruk",
    },
    {
        "CITYNAME": "Tioman",
        "CITYCODE": "TOD",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "TOD",
        "AIRPORTNAME": "Tioman",
    },
    {
        "CITYNAME": "Tozeur",
        "CITYCODE": "TOE",
        "COUNTRYCODE": "TN",
        "COUNTRYNAME": "Tunisia",
        "AIRPORTCODE": "TOE",
        "AIRPORTNAME": "Tozeur",
    },
    {
        "CITYNAME": "Tomsk",
        "CITYCODE": "TOF",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "TOF",
        "AIRPORTNAME": "Tomsk",
    },
    {
        "CITYNAME": "Togiak Village",
        "CITYCODE": "TOG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TOG",
        "AIRPORTNAME": "Togiak Village",
    },
    {
        "CITYNAME": "Torres",
        "CITYCODE": "TOH",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "TOH",
        "AIRPORTNAME": "Torres Airstrip",
    },
    {
        "CITYNAME": "Madrid",
        "CITYCODE": "TOJ",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "TOJ",
        "AIRPORTNAME": "Madrid - Torrejon Airport",
    },
    {
        "CITYNAME": "Toledo",
        "CITYCODE": "TOL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TOL",
        "AIRPORTNAME": "Toledo Express",
    },
    {
        "CITYNAME": "Tombouctou",
        "CITYCODE": "TOM",
        "COUNTRYCODE": "ML",
        "COUNTRYNAME": "Mali",
        "AIRPORTCODE": "TOM",
        "AIRPORTNAME": "Tombouctou",
    },
    {
        "CITYNAME": "Topeka",
        "CITYCODE": "TOP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TOP",
        "AIRPORTNAME": "Philip Billard",
    },
    {
        "CITYNAME": "Torrington",
        "CITYCODE": "TOR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TOR",
        "AIRPORTNAME": "Torrington Municipal",
    },
    {
        "CITYNAME": "Tromso",
        "CITYCODE": "TOS",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "TOS",
        "AIRPORTNAME": "Tromso/Langnes",
    },
    {
        "CITYNAME": "Touho",
        "CITYCODE": "TOU",
        "COUNTRYCODE": "NC",
        "COUNTRYNAME": "New Caledonia",
        "AIRPORTCODE": "TOU",
        "AIRPORTNAME": "Touho",
    },
    {
        "CITYNAME": "Toyama",
        "CITYCODE": "TOY",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "TOY",
        "AIRPORTNAME": "Toyama",
    },
    {
        "CITYNAME": "Touba",
        "CITYCODE": "TOZ",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "TOZ",
        "AIRPORTNAME": "Touba",
    },
    {
        "CITYNAME": "Tampa",
        "CITYCODE": "TPA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TPA",
        "AIRPORTNAME": "Tampa Int'l",
    },
    {
        "CITYNAME": "Bristol",
        "CITYCODE": "TPB",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "TPB",
        "AIRPORTNAME": "Bristol Temple Meads",
    },
    {
        "CITYNAME": "Tarapoa",
        "CITYCODE": "TPC",
        "COUNTRYCODE": "EC",
        "COUNTRYNAME": "Ecuador",
        "AIRPORTCODE": "TPC",
        "AIRPORTNAME": "Tarapoa",
    },
    {
        "CITYNAME": "Taipei",
        "CITYCODE": "TPE",
        "COUNTRYCODE": "TW",
        "COUNTRYNAME": "Taiwan",
        "AIRPORTCODE": "TPE",
        "AIRPORTNAME": "Chiang Kai Chek",
    },
    {
        "CITYNAME": "Taipei",
        "CITYCODE": "TPE",
        "COUNTRYCODE": "TW",
        "COUNTRYNAME": "Taiwan",
        "AIRPORTCODE": "TSA",
        "AIRPORTNAME": "Sung Shan",
    },
    {
        "CITYNAME": "Tarapoto",
        "CITYCODE": "TPP",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "TPP",
        "AIRPORTNAME": "Tarapoto",
    },
    {
        "CITYNAME": "Tepic",
        "CITYCODE": "TPQ",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "TPQ",
        "AIRPORTNAME": "Tepic",
    },
    {
        "CITYNAME": "Trapani",
        "CITYCODE": "TPS",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "TPS",
        "AIRPORTNAME": "Birgi",
    },
    {
        "CITYNAME": "Turbo",
        "CITYCODE": "TRB",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "TRB",
        "AIRPORTNAME": "Turbo",
    },
    {
        "CITYNAME": "Torreon",
        "CITYCODE": "TRC",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "TRC",
        "AIRPORTNAME": "Torreon",
    },
    {
        "CITYNAME": "Trondheim",
        "CITYCODE": "TRD",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "TRD",
        "AIRPORTNAME": "Vaernes",
    },
    {
        "CITYNAME": "Tiree",
        "CITYCODE": "TRE",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "TRE",
        "AIRPORTNAME": "Tiree",
    },
    {
        "CITYNAME": "Tauranga",
        "CITYCODE": "TRG",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "TRG",
        "AIRPORTNAME": "Tauranga",
    },
    {
        "CITYNAME": "Bristol",
        "CITYCODE": "TRI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TRI",
        "AIRPORTNAME": "Tri-Cities",
    },
    {
        "CITYNAME": "Tarakan",
        "CITYCODE": "TRK",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "TRK",
        "AIRPORTNAME": "Tarakan",
    },
    {
        "CITYNAME": "Turin",
        "CITYCODE": "TRN",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "TRN",
        "AIRPORTNAME": "Sandro Pertini",
    },
    {
        "CITYNAME": "Taree",
        "CITYCODE": "TRO",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "TRO",
        "AIRPORTNAME": "Taree",
    },
    {
        "CITYNAME": "Trieste",
        "CITYCODE": "TRS",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "TRS",
        "AIRPORTNAME": "Dei Legionari",
    },
    {
        "CITYNAME": "Trujillo",
        "CITYCODE": "TRU",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "TRU",
        "AIRPORTNAME": "Trujillo",
    },
    {
        "CITYNAME": "Trivandrum",
        "CITYCODE": "TRV",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "TRV",
        "AIRPORTNAME": "Trivandrum Int'l",
    },
    {
        "CITYNAME": "Tarawa",
        "CITYCODE": "TRW",
        "COUNTRYCODE": "KI",
        "COUNTRYNAME": "Kiribati",
        "AIRPORTCODE": "TRW",
        "AIRPORTNAME": "Bonriki",
    },
    {
        "CITYNAME": "Tiruchirapally",
        "CITYCODE": "TRZ",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "TRZ",
        "AIRPORTNAME": "Tiruchirapalli International Airport",
    },
    {
        "CITYNAME": "Tsumeb",
        "CITYCODE": "TSB",
        "COUNTRYCODE": "NA",
        "COUNTRYNAME": "Namibia",
        "AIRPORTCODE": "TSB",
        "AIRPORTNAME": "Tsumeb",
    },
    {
        "CITYNAME": "Tshipise",
        "CITYCODE": "TSD",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "TSD",
        "AIRPORTNAME": "Tshipise",
    },
    {
        "CITYNAME": "Astana",
        "CITYCODE": "TSE",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "NQZ",
        "AIRPORTNAME": "Nur-Sultan",
    },
    {
        "CITYNAME": "Astana",
        "CITYCODE": "TSE",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "TSE",
        "AIRPORTNAME": "Astana",
    },
    {
        "CITYNAME": "Tshikapa",
        "CITYCODE": "TSH",
        "COUNTRYCODE": "CD",
        "COUNTRYNAME": "Congo (Rep. Dem.)",
        "AIRPORTCODE": "TSH",
        "AIRPORTNAME": "Tshikapa",
    },
    {
        "CITYNAME": "Tsushima",
        "CITYCODE": "TSJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "TSJ",
        "AIRPORTNAME": "Tsushima",
    },
    {
        "CITYNAME": "Taos",
        "CITYCODE": "TSM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TSM",
        "AIRPORTNAME": "Taos",
    },
    {
        "CITYNAME": "Tianjin",
        "CITYCODE": "TSN",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "TSN",
        "AIRPORTNAME": "Tianjin",
    },
    {
        "CITYNAME": "Tehachapi",
        "CITYCODE": "TSP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TSP",
        "AIRPORTNAME": "Tehachapi Municipal Airport",
    },
    {
        "CITYNAME": "Timisoara",
        "CITYCODE": "TSR",
        "COUNTRYCODE": "RO",
        "COUNTRYNAME": "Romania",
        "AIRPORTCODE": "TSR",
        "AIRPORTNAME": "Timisoara",
    },
    {
        "CITYNAME": "Trang",
        "CITYCODE": "TST",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "TST",
        "AIRPORTNAME": "Trang",
    },
    {
        "CITYNAME": "Tabiteuea South",
        "CITYCODE": "TSU",
        "COUNTRYCODE": "KI",
        "COUNTRYNAME": "Kiribati",
        "AIRPORTCODE": "TSU",
        "AIRPORTNAME": "Tabiteuea South",
    },
    {
        "CITYNAME": "Townsville",
        "CITYCODE": "TSV",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "TSV",
        "AIRPORTNAME": "Townsville",
    },
    {
        "CITYNAME": "Tan Tan",
        "CITYCODE": "TTA",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "TTA",
        "AIRPORTNAME": "Tan Tan",
    },
    {
        "CITYNAME": "Tortoli",
        "CITYCODE": "TTB",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "TTB",
        "AIRPORTNAME": "Arbatax",
    },
    {
        "CITYNAME": "Ternate",
        "CITYCODE": "TTE",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "TTE",
        "AIRPORTNAME": "Babullah",
    },
    {
        "CITYNAME": "Tottori",
        "CITYCODE": "TTJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "TTJ",
        "AIRPORTNAME": "Tottori",
    },
    {
        "CITYNAME": "Trenton",
        "CITYCODE": "TTN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TTN",
        "AIRPORTNAME": "Mercer County",
    },
    {
        "CITYNAME": "Tana Toraja",
        "CITYCODE": "TTR",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "TTR",
        "AIRPORTNAME": "Tana Toraja",
    },
    {
        "CITYNAME": "Tsaratanana",
        "CITYCODE": "TTS",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "TTS",
        "AIRPORTNAME": "Tsaratanana",
    },
    {
        "CITYNAME": "Taitung",
        "CITYCODE": "TTT",
        "COUNTRYCODE": "TW",
        "COUNTRYNAME": "Taiwan",
        "AIRPORTCODE": "TTT",
        "AIRPORTNAME": "Taitung",
    },
    {
        "CITYNAME": "Tetuan",
        "CITYCODE": "TTU",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "TTU",
        "AIRPORTNAME": "Sania Ramel",
    },
    {
        "CITYNAME": "Tulcan",
        "CITYCODE": "TUA",
        "COUNTRYCODE": "EC",
        "COUNTRYNAME": "Ecuador",
        "AIRPORTCODE": "TUA",
        "AIRPORTNAME": "Tulcan",
    },
    {
        "CITYNAME": "Tubuai",
        "CITYCODE": "TUB",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "TUB",
        "AIRPORTNAME": "Tubuai",
    },
    {
        "CITYNAME": "Tucuman",
        "CITYCODE": "TUC",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "TUC",
        "AIRPORTNAME": "Benj Matienzo",
    },
    {
        "CITYNAME": "Tambacounda",
        "CITYCODE": "TUD",
        "COUNTRYCODE": "SN",
        "COUNTRYNAME": "Senegal",
        "AIRPORTCODE": "TUD",
        "AIRPORTNAME": "Tambacounda",
    },
    {
        "CITYNAME": "Tours",
        "CITYCODE": "TUF",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "TUF",
        "AIRPORTNAME": "St Symphorien",
    },
    {
        "CITYNAME": "Tuguegarao",
        "CITYCODE": "TUG",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "TUG",
        "AIRPORTNAME": "Tuguegarao Airport",
    },
    {
        "CITYNAME": "Turaif",
        "CITYCODE": "TUI",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "TUI",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Tum",
        "CITYCODE": "TUJ",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "TUJ",
        "AIRPORTNAME": "Tum",
    },
    {
        "CITYNAME": "Turbat",
        "CITYCODE": "TUK",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "TUK",
        "AIRPORTNAME": "Turbat",
    },
    {
        "CITYNAME": "Tulsa",
        "CITYCODE": "TUL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TUL",
        "AIRPORTNAME": "Tulsa Int'l",
    },
    {
        "CITYNAME": "Tumut",
        "CITYCODE": "TUM",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "TUM",
        "AIRPORTNAME": "Tumut",
    },
    {
        "CITYNAME": "Tunis",
        "CITYCODE": "TUN",
        "COUNTRYCODE": "TN",
        "COUNTRYNAME": "Tunisia",
        "AIRPORTCODE": "TUN",
        "AIRPORTNAME": "Carthage",
    },
    {
        "CITYNAME": "Taupo",
        "CITYCODE": "TUO",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "TUO",
        "AIRPORTNAME": "Taupo",
    },
    {
        "CITYNAME": "Tupelo",
        "CITYCODE": "TUP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TUP",
        "AIRPORTNAME": "Lemons Municipal",
    },
    {
        "CITYNAME": "Tucurui",
        "CITYCODE": "TUR",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "TUR",
        "AIRPORTNAME": "Tucurui",
    },
    {
        "CITYNAME": "Tucson",
        "CITYCODE": "TUS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "AVW",
        "AIRPORTNAME": "Avra Valley",
    },
    {
        "CITYNAME": "Tucson",
        "CITYCODE": "TUS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TUS",
        "AIRPORTNAME": "Tucson Int'l",
    },
    {
        "CITYNAME": "Tabuk",
        "CITYCODE": "TUU",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "TUU",
        "AIRPORTNAME": "TABUK",
    },
    {
        "CITYNAME": "Tucupita",
        "CITYCODE": "TUV",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "TUV",
        "AIRPORTNAME": "Tucupita",
    },
    {
        "CITYNAME": "Tucuma",
        "CITYCODE": "TUZ",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "TUZ",
        "AIRPORTNAME": "Tucuma",
    },
    {
        "CITYNAME": "Morafenobe",
        "CITYCODE": "TVA",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "TVA",
        "AIRPORTNAME": "Morafenobe",
    },
    {
        "CITYNAME": "Traverse City",
        "CITYCODE": "TVC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TVC",
        "AIRPORTNAME": "Traverse City",
    },
    {
        "CITYNAME": "Thief River Falls",
        "CITYCODE": "TVF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TVF",
        "AIRPORTNAME": "Thief River Falls",
    },
    {
        "CITYNAME": "Lake Tahoe South",
        "CITYCODE": "TVL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TVL",
        "AIRPORTNAME": "Lake Tahoe South",
    },
    {
        "CITYNAME": "Taveuni",
        "CITYCODE": "TVU",
        "COUNTRYCODE": "FJ",
        "COUNTRYNAME": "Fiji Islands",
        "AIRPORTCODE": "TVU",
        "AIRPORTNAME": "Matei",
    },
    {
        "CITYNAME": "Twin Hills",
        "CITYCODE": "TWA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TWA",
        "AIRPORTNAME": "Twin Hills",
    },
    {
        "CITYNAME": "Toowoomba",
        "CITYCODE": "TWB",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "TWB",
        "AIRPORTNAME": "Toowoomba",
    },
    {
        "CITYNAME": "Twin Falls",
        "CITYCODE": "TWF",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TWF",
        "AIRPORTNAME": "Twin Falls City County",
    },
    {
        "CITYNAME": "Tawitawi",
        "CITYCODE": "TWT",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "TWT",
        "AIRPORTNAME": "Sanga-Sanga Airport",
    },
    {
        "CITYNAME": "Tawau",
        "CITYCODE": "TWU",
        "COUNTRYCODE": "MY",
        "COUNTRYNAME": "Malaysia",
        "AIRPORTCODE": "TWU",
        "AIRPORTNAME": "Tawau",
    },
    {
        "CITYNAME": "Trieste",
        "CITYCODE": "TXB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TXB",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Teixeira Freitas",
        "CITYCODE": "TXF",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "TXF",
        "AIRPORTNAME": "Teixeira de Freitas",
    },
    {
        "CITYNAME": "Taichung",
        "CITYCODE": "TXG",
        "COUNTRYCODE": "TW",
        "COUNTRYNAME": "Taiwan",
        "AIRPORTCODE": "TXG",
        "AIRPORTNAME": "Taichung",
    },
    {
        "CITYNAME": "Texarkana",
        "CITYCODE": "TXK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TXK",
        "AIRPORTNAME": "Texarkana Municipal",
    },
    {
        "CITYNAME": "Teminabuan",
        "CITYCODE": "TXM",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "TXM",
        "AIRPORTNAME": "Teminabuan",
    },
    {
        "CITYNAME": "Tunxi",
        "CITYCODE": "TXN",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "TXN",
        "AIRPORTNAME": "Tunxi",
    },
    {
        "CITYNAME": "Tabou",
        "CITYCODE": "TXU",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "TXU",
        "AIRPORTNAME": "Tabou",
    },
    {
        "CITYNAME": "Torsby",
        "CITYCODE": "TYF",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "TYF",
        "AIRPORTNAME": "Torsby Airport",
    },
    {
        "CITYNAME": "Talara",
        "CITYCODE": "TYL",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "TYL",
        "AIRPORTNAME": "Talara",
    },
    {
        "CITYNAME": "Taiyuan",
        "CITYCODE": "TYN",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "TYN",
        "AIRPORTNAME": "Taiyuan",
    },
    {
        "CITYNAME": "Tokyo",
        "CITYCODE": "TYO",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "HND",
        "AIRPORTNAME": "Haneda",
    },
    {
        "CITYNAME": "Tokyo",
        "CITYCODE": "TYO",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "NRT",
        "AIRPORTNAME": "Narita",
    },
    {
        "CITYNAME": "Tyler",
        "CITYCODE": "TYR",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TYR",
        "AIRPORTNAME": "Pounds Field",
    },
    {
        "CITYNAME": "Knoxville",
        "CITYCODE": "TYS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "TYS",
        "AIRPORTNAME": "Mc Ghee Tyson",
    },
    {
        "CITYNAME": "Tuzla",
        "CITYCODE": "TZL",
        "COUNTRYCODE": "BA",
        "COUNTRYNAME": "Bosnia and Herzegowina",
        "AIRPORTCODE": "TZL",
        "AIRPORTNAME": "Tuzla Intl Airport",
    },
    {
        "CITYNAME": "South Andros",
        "CITYCODE": "TZN",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "TZN",
        "AIRPORTNAME": "South Andros",
    },
    {
        "CITYNAME": "Trabzon",
        "CITYCODE": "TZX",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "TZX",
        "AIRPORTNAME": "Trabzon",
    },
    {
        "CITYNAME": "San Luis Rio Colorado",
        "CITYCODE": "UAC",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "UAC",
        "AIRPORTNAME": "San Luis Rio Colorado",
    },
    {
        "CITYNAME": "Mount Aue",
        "CITYCODE": "UAE",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "UAE",
        "AIRPORTNAME": "MOUNT AUE",
    },
    {
        "CITYNAME": "Ua Huka",
        "CITYCODE": "UAH",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "UAH",
        "AIRPORTNAME": "Ua Huka",
    },
    {
        "CITYNAME": "Narsarsuaq",
        "CITYCODE": "UAK",
        "COUNTRYCODE": "GL",
        "COUNTRYNAME": "Greenland",
        "AIRPORTCODE": "UAK",
        "AIRPORTNAME": "Narsarsuaq",
    },
    {
        "CITYNAME": "Luau",
        "CITYCODE": "UAL",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "UAL",
        "AIRPORTNAME": "Luau",
    },
    {
        "CITYNAME": "Ua Pou",
        "CITYCODE": "UAP",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "UAP",
        "AIRPORTNAME": "Ua Pou",
    },
    {
        "CITYNAME": "San Juan",
        "CITYCODE": "UAQ",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "UAQ",
        "AIRPORTNAME": "San Juan",
    },
    {
        "CITYNAME": "Samburu",
        "CITYCODE": "UAS",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "UAS",
        "AIRPORTNAME": "Samburu",
    },
    {
        "CITYNAME": "Uberaba",
        "CITYCODE": "UBA",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "UBA",
        "AIRPORTNAME": "Uberaba",
    },
    {
        "CITYNAME": "Mabuiag Island",
        "CITYCODE": "UBB",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "UBB",
        "AIRPORTNAME": "Mabuiag Island",
    },
    {
        "CITYNAME": "Ube",
        "CITYCODE": "UBJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "UBJ",
        "AIRPORTNAME": "Ube",
    },
    {
        "CITYNAME": "Ubon Ratchathani",
        "CITYCODE": "UBP",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "UBP",
        "AIRPORTNAME": "Muang Ubon",
    },
    {
        "CITYNAME": "Columbus",
        "CITYCODE": "UBS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "GTR",
        "AIRPORTNAME": "Golden Triangle Regional",
    },
    {
        "CITYNAME": "Utica",
        "CITYCODE": "UCA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "UCA",
        "AIRPORTNAME": "Oneida County",
    },
    {
        "CITYNAME": "Yucca Flat",
        "CITYCODE": "UCC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "UCC",
        "AIRPORTNAME": "YUCCA FLAT",
    },
    {
        "CITYNAME": "Buchanan",
        "CITYCODE": "UCN",
        "COUNTRYCODE": "LR",
        "COUNTRYNAME": "Liberia",
        "AIRPORTCODE": "UCN",
        "AIRPORTNAME": "Buchanan",
    },
    {
        "CITYNAME": "Ukhta",
        "CITYCODE": "UCT",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "UCT",
        "AIRPORTNAME": "Ukhta",
    },
    {
        "CITYNAME": "Uberlandia",
        "CITYCODE": "UDI",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "UDI",
        "AIRPORTNAME": "Eduardo Gomes",
    },
    {
        "CITYNAME": "Uzhgorod",
        "CITYCODE": "UDJ",
        "COUNTRYCODE": "UA",
        "COUNTRYNAME": "Ukraine",
        "AIRPORTCODE": "UDJ",
        "AIRPORTNAME": "Uzhgorod",
    },
    {
        "CITYNAME": "Udaipur",
        "CITYCODE": "UDR",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "UDR",
        "AIRPORTNAME": "Dabok",
    },
    {
        "CITYNAME": "Queenstown",
        "CITYCODE": "UEE",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "UEE",
        "AIRPORTNAME": "Queenstown",
    },
    {
        "CITYNAME": "Quelimane",
        "CITYCODE": "UEL",
        "COUNTRYCODE": "MZ",
        "COUNTRYNAME": "Mozambique",
        "AIRPORTCODE": "UEL",
        "AIRPORTNAME": "Quelimane",
    },
    {
        "CITYNAME": "Kumejima",
        "CITYCODE": "UEO",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "UEO",
        "AIRPORTNAME": "Kumejima",
    },
    {
        "CITYNAME": "Quetta",
        "CITYCODE": "UET",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "UET",
        "AIRPORTNAME": "Quetta",
    },
    {
        "CITYNAME": "Ufa",
        "CITYCODE": "UFA",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "UFA",
        "AIRPORTNAME": "Ufa",
    },
    {
        "CITYNAME": "Urgench",
        "CITYCODE": "UGC",
        "COUNTRYCODE": "UZ",
        "COUNTRYNAME": "Uzbekistan",
        "AIRPORTCODE": "UGC",
        "AIRPORTNAME": "Urgench",
    },
    {
        "CITYNAME": "Uganik",
        "CITYCODE": "UGI",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "UGI",
        "AIRPORTNAME": "Uganik",
    },
    {
        "CITYNAME": "Waukegan",
        "CITYCODE": "UGN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "UGN",
        "AIRPORTNAME": "Waukegan Memorial",
    },
    {
        "CITYNAME": "Uige",
        "CITYCODE": "UGO",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "UGO",
        "AIRPORTNAME": "Uige",
    },
    {
        "CITYNAME": "Quibdo",
        "CITYCODE": "UIB",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "UIB",
        "AIRPORTNAME": "Quibdo",
    },
    {
        "CITYNAME": "Qui Nhon",
        "CITYCODE": "UIH",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "UIH",
        "AIRPORTNAME": "Qui Nhon Phu Cat Airport",
    },
    {
        "CITYNAME": "Utila",
        "CITYCODE": "UII",
        "COUNTRYCODE": "HN",
        "COUNTRYNAME": "Honduras",
        "AIRPORTCODE": "UII",
        "AIRPORTNAME": "Utila",
    },
    {
        "CITYNAME": "Quincy",
        "CITYCODE": "UIN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "UIN",
        "AIRPORTNAME": "Quincy Municipal",
    },
    {
        "CITYNAME": "Quito",
        "CITYCODE": "UIO",
        "COUNTRYCODE": "EC",
        "COUNTRYNAME": "Ecuador",
        "AIRPORTCODE": "UIO",
        "AIRPORTNAME": "Mariscal Sucr",
    },
    {
        "CITYNAME": "Quimper",
        "CITYCODE": "UIP",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "UIP",
        "AIRPORTNAME": "Pluguffan",
    },
    {
        "CITYNAME": "Quirindi",
        "CITYCODE": "UIR",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "UIR",
        "AIRPORTNAME": "Quirindi",
    },
    {
        "CITYNAME": "Jaluit Island",
        "CITYCODE": "UIT",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "UIT",
        "AIRPORTNAME": "Jaluit Island",
    },
    {
        "CITYNAME": "Ujae Island",
        "CITYCODE": "UJE",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "UJE",
        "AIRPORTNAME": "Ujae Island",
    },
    {
        "CITYNAME": "Ukunda",
        "CITYCODE": "UKA",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "UKA",
        "AIRPORTNAME": "Ukunda Airstrip",
    },
    {
        "CITYNAME": "Kobe",
        "CITYCODE": "UKB",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "UKB",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Ust Kamenogorsk",
        "CITYCODE": "UKK",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "UKK",
        "AIRPORTNAME": "Ust Kamenogorsk",
    },
    {
        "CITYNAME": "Mukeiras",
        "CITYCODE": "UKR",
        "COUNTRYCODE": "YE",
        "COUNTRYNAME": "Yemen",
        "AIRPORTCODE": "UKR",
        "AIRPORTNAME": "Mukeiras",
    },
    {
        "CITYNAME": "Nuku",
        "CITYCODE": "UKU",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "UKU",
        "AIRPORTNAME": "Nuku",
    },
    {
        "CITYNAME": "Ulei",
        "CITYCODE": "ULB",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "ULB",
        "AIRPORTNAME": "Ulei",
    },
    {
        "CITYNAME": "Al-Ula",
        "CITYCODE": "ULH",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "ULH",
        "AIRPORTNAME": "Prince Abdul Majeed bin Abdulaziz Airport",
    },
    {
        "CITYNAME": "Ulan Bator",
        "CITYCODE": "ULN",
        "COUNTRYCODE": "MN",
        "COUNTRYNAME": "Mongolia",
        "AIRPORTCODE": "ULN",
        "AIRPORTNAME": "Ulan Bator",
    },
    {
        "CITYNAME": "Quilpie",
        "CITYCODE": "ULP",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "ULP",
        "AIRPORTNAME": "Quilpie",
    },
    {
        "CITYNAME": "Tulua",
        "CITYCODE": "ULQ",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "ULQ",
        "AIRPORTNAME": "Heriberto Gil Martinez Airport",
    },
    {
        "CITYNAME": "Ulyanovsk",
        "CITYCODE": "ULV",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "ULV",
        "AIRPORTNAME": "Ulyanovsk Baratayevka Airport",
    },
    {
        "CITYNAME": "Ulyanovsk",
        "CITYCODE": "ULY",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "ULY",
        "AIRPORTNAME": "Ulyanovsk",
    },
    {
        "CITYNAME": "Uummannaq",
        "CITYCODE": "UMD",
        "COUNTRYCODE": "GL",
        "COUNTRYNAME": "Greenland",
        "AIRPORTCODE": "UMD",
        "AIRPORTNAME": "Uummannaq",
    },
    {
        "CITYNAME": "Umea",
        "CITYCODE": "UME",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "UME",
        "AIRPORTNAME": "Umea",
    },
    {
        "CITYNAME": "Woomera",
        "CITYCODE": "UMR",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "UMR",
        "AIRPORTNAME": "Woomera",
    },
    {
        "CITYNAME": "Una",
        "CITYCODE": "UNA",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "UNA",
        "AIRPORTNAME": "Una",
    },
    {
        "CITYNAME": "Kunduz",
        "CITYCODE": "UND",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "UND",
        "AIRPORTNAME": "Kunduz",
    },
    {
        "CITYNAME": "Qachas Nek",
        "CITYCODE": "UNE",
        "COUNTRYCODE": "LS",
        "COUNTRYNAME": "Lesotho",
        "AIRPORTCODE": "UNE",
        "AIRPORTNAME": "Qachas Nek",
    },
    {
        "CITYNAME": "Kiunga",
        "CITYCODE": "UNG",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "UNG",
        "AIRPORTNAME": "Kiunga",
    },
    {
        "CITYNAME": "Union Island",
        "CITYCODE": "UNI",
        "COUNTRYCODE": "VC",
        "COUNTRYNAME": "St. Vincent & the Grenadines",
        "AIRPORTCODE": "UNI",
        "AIRPORTNAME": "Union Island",
    },
    {
        "CITYNAME": "Unalakleet",
        "CITYCODE": "UNK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "UNK",
        "AIRPORTNAME": "Unalakleet",
    },
    {
        "CITYNAME": "Ranong",
        "CITYCODE": "UNN",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "UNN",
        "AIRPORTNAME": "Ranong",
    },
    {
        "CITYNAME": "Buol",
        "CITYCODE": "UOL",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "UOL",
        "AIRPORTNAME": "Buol",
    },
    {
        "CITYNAME": "Ujung Pandang",
        "CITYCODE": "UPG",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "UPG",
        "AIRPORTNAME": "Hasanudin",
    },
    {
        "CITYNAME": "Uruapan",
        "CITYCODE": "UPN",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "UPN",
        "AIRPORTNAME": "Uruapan",
    },
    {
        "CITYNAME": "Queen",
        "CITYCODE": "UQE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "UQE",
        "AIRPORTNAME": "Queen",
    },
    {
        "CITYNAME": "Uralsk",
        "CITYCODE": "URA",
        "COUNTRYCODE": "KZ",
        "COUNTRYNAME": "Kazakhstan",
        "AIRPORTCODE": "URA",
        "AIRPORTNAME": "Uralsk - Podstepnyy",
    },
    {
        "CITYNAME": "Urubupunga",
        "CITYCODE": "URB",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "URB",
        "AIRPORTNAME": "Ernesto Pochler",
    },
    {
        "CITYNAME": "Urumqi",
        "CITYCODE": "URC",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "URC",
        "AIRPORTNAME": "Urumqi",
    },
    {
        "CITYNAME": "Burg Feuerstein",
        "CITYCODE": "URD",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "URD",
        "AIRPORTNAME": "BURG FEUERSTEIN",
    },
    {
        "CITYNAME": "Kuressaare",
        "CITYCODE": "URE",
        "COUNTRYCODE": "EE",
        "COUNTRYNAME": "Estonia",
        "AIRPORTCODE": "URE",
        "AIRPORTNAME": "Kuressaare",
    },
    {
        "CITYNAME": "Uruguaiana",
        "CITYCODE": "URG",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "URG",
        "AIRPORTNAME": "Ruben Berta",
    },
    {
        "CITYNAME": "Rouen",
        "CITYCODE": "URO",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "URO",
        "AIRPORTNAME": "Boos",
    },
    {
        "CITYNAME": "Urrao",
        "CITYCODE": "URR",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "URR",
        "AIRPORTNAME": "Urrao",
    },
    {
        "CITYNAME": "Surat Thani",
        "CITYCODE": "URT",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "URT",
        "AIRPORTNAME": "Surat Thani",
    },
    {
        "CITYNAME": "Gurayat",
        "CITYCODE": "URY",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "URY",
        "AIRPORTNAME": "Gurayat",
    },
    {
        "CITYNAME": "Ushuaia",
        "CITYCODE": "USH",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "USH",
        "AIRPORTNAME": "Ushuaia",
    },
    {
        "CITYNAME": "Useless Loop",
        "CITYCODE": "USL",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "USL",
        "AIRPORTNAME": "Useless Loop",
    },
    {
        "CITYNAME": "Koh Samui",
        "CITYCODE": "USM",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "USM",
        "AIRPORTNAME": "Samui",
    },
    {
        "CITYNAME": "Ulsan",
        "CITYCODE": "USN",
        "COUNTRYCODE": "KR",
        "COUNTRYNAME": "South Korea",
        "AIRPORTCODE": "USN",
        "AIRPORTNAME": "Ulsan",
    },
    {
        "CITYNAME": "Usak",
        "CITYCODE": "USQ",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "USQ",
        "AIRPORTNAME": "Usak",
    },
    {
        "CITYNAME": "Sancti Spiritus",
        "CITYCODE": "USS",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "USS",
        "AIRPORTNAME": "Sancti Spiritus",
    },
    {
        "CITYNAME": "St Augustine",
        "CITYCODE": "UST",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "UST",
        "AIRPORTNAME": "St. Augustine Florida",
    },
    {
        "CITYNAME": "Busuanga",
        "CITYCODE": "USU",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "USU",
        "AIRPORTNAME": "Francisco B Reyes Airport",
    },
    {
        "CITYNAME": "Udon Thani",
        "CITYCODE": "UTH",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "UTH",
        "AIRPORTNAME": "Udon Thani",
    },
    {
        "CITYNAME": "Utirik Island",
        "CITYCODE": "UTK",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "UTK",
        "AIRPORTNAME": "Utirik Island",
    },
    {
        "CITYNAME": "Tunica",
        "CITYCODE": "UTM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "UTM",
        "AIRPORTNAME": "Tunica Municipal Airport",
    },
    {
        "CITYNAME": "Upington",
        "CITYCODE": "UTN",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "UTN",
        "AIRPORTNAME": "Upington Municipal",
    },
    {
        "CITYNAME": "Pattaya",
        "CITYCODE": "UTP",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "PYX",
        "AIRPORTNAME": "Pattaya",
    },
    {
        "CITYNAME": "Pattaya",
        "CITYCODE": "UTP",
        "COUNTRYCODE": "TH",
        "COUNTRYNAME": "Thailand",
        "AIRPORTCODE": "UTP",
        "AIRPORTNAME": "UTapao",
    },
    {
        "CITYNAME": "Umtata",
        "CITYCODE": "UTT",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "UTT",
        "AIRPORTNAME": "Umtata",
    },
    {
        "CITYNAME": "Ulan Ude",
        "CITYCODE": "UUD",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "UUD",
        "AIRPORTNAME": "Ulan Ude",
    },
    {
        "CITYNAME": "Yuzhno Sakhalinsk",
        "CITYCODE": "UUS",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "UUS",
        "AIRPORTNAME": "Yuzhno Sakhalinsk",
    },
    {
        "CITYNAME": "Ouvea",
        "CITYCODE": "UVE",
        "COUNTRYCODE": "NC",
        "COUNTRYNAME": "New Caledonia",
        "AIRPORTCODE": "UVE",
        "AIRPORTNAME": "Ouvea",
    },
    {
        "CITYNAME": "Kharga",
        "CITYCODE": "UVL",
        "COUNTRYCODE": "EG",
        "COUNTRYNAME": "Egypt",
        "AIRPORTCODE": "UVL",
        "AIRPORTNAME": "Kharga",
    },
    {
        "CITYNAME": "Nyala",
        "CITYCODE": "UYL",
        "COUNTRYCODE": "SD",
        "COUNTRYNAME": "Sudan",
        "AIRPORTCODE": "UYL",
        "AIRPORTNAME": "Nyala",
    },
    {
        "CITYNAME": "Uyuni",
        "CITYCODE": "UYU",
        "COUNTRYCODE": "BO",
        "COUNTRYNAME": "Bolivia",
        "AIRPORTCODE": "UYU",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Vaasa",
        "CITYCODE": "VAA",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "VAA",
        "AIRPORTNAME": "Vaasa",
    },
    {
        "CITYNAME": "Valence",
        "CITYCODE": "VAF",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "VAF",
        "AIRPORTNAME": "Chabeuil",
    },
    {
        "CITYNAME": "Varginha",
        "CITYCODE": "VAG",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "VAG",
        "AIRPORTNAME": "Maj. Brig. Tromposky",
    },
    {
        "CITYNAME": "Vanimo",
        "CITYCODE": "VAI",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "VAI",
        "AIRPORTNAME": "Vanimo",
    },
    {
        "CITYNAME": "Chevak",
        "CITYCODE": "VAK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "VAK",
        "AIRPORTNAME": "Chevak",
    },
    {
        "CITYNAME": "Valenca",
        "CITYCODE": "VAL",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "VAL",
        "AIRPORTNAME": "Valenca",
    },
    {
        "CITYNAME": "Van",
        "CITYCODE": "VAN",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "VAN",
        "AIRPORTNAME": "Van",
    },
    {
        "CITYNAME": "Varna",
        "CITYCODE": "VAR",
        "COUNTRYCODE": "BG",
        "COUNTRYNAME": "Bulgaria",
        "AIRPORTCODE": "VAR",
        "AIRPORTNAME": "Varna",
    },
    {
        "CITYNAME": "Sivas",
        "CITYCODE": "VAS",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "VAS",
        "AIRPORTNAME": "Sivas",
    },
    {
        "CITYNAME": "Vatomandry",
        "CITYCODE": "VAT",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "VAT",
        "AIRPORTNAME": "Vatomandry",
    },
    {
        "CITYNAME": "Vava'u",
        "CITYCODE": "VAV",
        "COUNTRYCODE": "TO",
        "COUNTRYNAME": "Tonga",
        "AIRPORTCODE": "VAV",
        "AIRPORTNAME": "Lupepauâu",
    },
    {
        "CITYNAME": "Vardoe",
        "CITYCODE": "VAW",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "VAW",
        "AIRPORTNAME": "Vardoe",
    },
    {
        "CITYNAME": "Brescia",
        "CITYCODE": "VBS",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "VBS",
        "AIRPORTNAME": "Brescia - Montichiari Airport",
    },
    {
        "CITYNAME": "Visby",
        "CITYCODE": "VBY",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "VBY",
        "AIRPORTNAME": "Visby",
    },
    {
        "CITYNAME": "Can Tho",
        "CITYCODE": "VCA",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "VCA",
        "AIRPORTNAME": "Tra Noc Airport",
    },
    {
        "CITYNAME": "Victoria River Do",
        "CITYCODE": "VCD",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "VCD",
        "AIRPORTNAME": "Victoria River Do",
    },
    {
        "CITYNAME": "Venice",
        "CITYCODE": "VCE",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "TSF",
        "AIRPORTNAME": "Treviso",
    },
    {
        "CITYNAME": "Venice",
        "CITYCODE": "VCE",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "VCE",
        "AIRPORTNAME": "Marco Polo",
    },
    {
        "CITYNAME": "Tam Ky",
        "CITYCODE": "VCL",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "VCL",
        "AIRPORTNAME": "Chu Lai Intl Airport",
    },
    {
        "CITYNAME": "Victoria",
        "CITYCODE": "VCT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "VCT",
        "AIRPORTNAME": "County-Foster",
    },
    {
        "CITYNAME": "Ovda",
        "CITYCODE": "VDA",
        "COUNTRYCODE": "IL",
        "COUNTRYNAME": "Israel",
        "AIRPORTCODE": "VDA",
        "AIRPORTNAME": "Ovda",
    },
    {
        "CITYNAME": "Fagernes",
        "CITYCODE": "VDB",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "VDB",
        "AIRPORTNAME": "Valdres",
    },
    {
        "CITYNAME": "Vitoria Da Cnquis",
        "CITYCODE": "VDC",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "VDC",
        "AIRPORTNAME": "Vitoria Da Cnquis",
    },
    {
        "CITYNAME": "Dong Hoi",
        "CITYCODE": "VDH",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "VDH",
        "AIRPORTNAME": "Dong Hoi Airport",
    },
    {
        "CITYNAME": "Viedma",
        "CITYCODE": "VDM",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "VDM",
        "AIRPORTNAME": "Viedma",
    },
    {
        "CITYNAME": "Valle De Pascua",
        "CITYCODE": "VDP",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "VDP",
        "AIRPORTNAME": "Valle De Pascua",
    },
    {
        "CITYNAME": "Vadso",
        "CITYCODE": "VDS",
        "COUNTRYCODE": "NO",
        "COUNTRYNAME": "Norway",
        "AIRPORTCODE": "VDS",
        "AIRPORTNAME": "Vadso",
    },
    {
        "CITYNAME": "Vidyanagar",
        "CITYCODE": "VDY",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "VDY",
        "AIRPORTNAME": "Jindal Vijaynagar Airport",
    },
    {
        "CITYNAME": "Valdez",
        "CITYCODE": "VDZ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "VDZ",
        "AIRPORTNAME": "Valdez Municipal",
    },
    {
        "CITYNAME": "Venetie",
        "CITYCODE": "VEE",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "VEE",
        "AIRPORTNAME": "Venetie",
    },
    {
        "CITYNAME": "Vernal",
        "CITYCODE": "VEL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "VEL",
        "AIRPORTNAME": "Vernal",
    },
    {
        "CITYNAME": "Veracruz",
        "CITYCODE": "VER",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "VER",
        "AIRPORTNAME": "Heriberto Jara",
    },
    {
        "CITYNAME": "Vestmannaeyjar",
        "CITYCODE": "VEY",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "VEY",
        "AIRPORTNAME": "Vestmannaeyjar",
    },
    {
        "CITYNAME": "Victoria Falls",
        "CITYCODE": "VFA",
        "COUNTRYCODE": "ZW",
        "COUNTRYNAME": "Zimbabwe",
        "AIRPORTCODE": "VFA",
        "AIRPORTNAME": "Victoria Falls",
    },
    {
        "CITYNAME": "Vijayawada",
        "CITYCODE": "VGA",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "VGA",
        "AIRPORTNAME": "Vijayawada",
    },
    {
        "CITYNAME": "Vigo",
        "CITYCODE": "VGO",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "VGO",
        "AIRPORTNAME": "Vigo",
    },
    {
        "CITYNAME": "Villagarzon",
        "CITYCODE": "VGZ",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "VGZ",
        "AIRPORTNAME": "Villagarzon",
    },
    {
        "CITYNAME": "Saurimo",
        "CITYCODE": "VHC",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "VHC",
        "AIRPORTNAME": "Saurimo",
    },
    {
        "CITYNAME": "Vilhelmina",
        "CITYCODE": "VHM",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "VHM",
        "AIRPORTNAME": "Vilhelmina",
    },
    {
        "CITYNAME": "Vahitahi",
        "CITYCODE": "VHZ",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "VHZ",
        "AIRPORTNAME": "Vahitahi",
    },
    {
        "CITYNAME": "Vidin",
        "CITYCODE": "VID",
        "COUNTRYCODE": "BG",
        "COUNTRYNAME": "Bulgaria",
        "AIRPORTCODE": "VID",
        "AIRPORTNAME": "Vidin",
    },
    {
        "CITYNAME": "Vienna",
        "CITYCODE": "VIE",
        "COUNTRYCODE": "AT",
        "COUNTRYNAME": "Austria",
        "AIRPORTCODE": "VIE",
        "AIRPORTNAME": "Vienna Int'l",
    },
    {
        "CITYNAME": "Vinh City",
        "CITYCODE": "VII",
        "COUNTRYCODE": "VN",
        "COUNTRYNAME": "Vietnam",
        "AIRPORTCODE": "VII",
        "AIRPORTNAME": "Vinh",
    },
    {
        "CITYNAME": "Virgin Gorda",
        "CITYCODE": "VIJ",
        "COUNTRYCODE": "VG",
        "COUNTRYNAME": "British Virgin Islands",
        "AIRPORTCODE": "VIJ",
        "AIRPORTNAME": "Virgin Gorda",
    },
    {
        "CITYNAME": "Dakhla",
        "CITYCODE": "VIL",
        "COUNTRYCODE": "MA",
        "COUNTRYNAME": "Morocco",
        "AIRPORTCODE": "VIL",
        "AIRPORTNAME": "Dakhla",
    },
    {
        "CITYNAME": "Visalia",
        "CITYCODE": "VIS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "VIS",
        "AIRPORTNAME": "Visalia",
    },
    {
        "CITYNAME": "Vitoria",
        "CITYCODE": "VIT",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "VIT",
        "AIRPORTNAME": "Vitoria",
    },
    {
        "CITYNAME": "Vivigani",
        "CITYCODE": "VIV",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "VIV",
        "AIRPORTNAME": "Vivigani",
    },
    {
        "CITYNAME": "Vitoria",
        "CITYCODE": "VIX",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "VIX",
        "AIRPORTNAME": "Eurico Sales",
    },
    {
        "CITYNAME": "Vorkuta",
        "CITYCODE": "VKT",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "VKT",
        "AIRPORTNAME": "Vorkuta",
    },
    {
        "CITYNAME": "Valencia",
        "CITYCODE": "VLC",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "VLC",
        "AIRPORTNAME": "Valencia",
    },
    {
        "CITYNAME": "Valencia",
        "CITYCODE": "VLC",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "YJV",
        "AIRPORTNAME": "Valencia Railway Station Airport",
    },
    {
        "CITYNAME": "Valdosta",
        "CITYCODE": "VLD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "VLD",
        "AIRPORTNAME": "Valdosta Regional",
    },
    {
        "CITYNAME": "Villa Gesell",
        "CITYCODE": "VLG",
        "COUNTRYCODE": "AR",
        "COUNTRYNAME": "Argentina",
        "AIRPORTCODE": "VLG",
        "AIRPORTNAME": "Villa Gesell",
    },
    {
        "CITYNAME": "Port Vila",
        "CITYCODE": "VLI",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "VLI",
        "AIRPORTNAME": "Bauerfield",
    },
    {
        "CITYNAME": "Valladolid",
        "CITYCODE": "VLL",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "VLL",
        "AIRPORTNAME": "Valladolid",
    },
    {
        "CITYNAME": "Valencia",
        "CITYCODE": "VLN",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "VLN",
        "AIRPORTNAME": "Valencia",
    },
    {
        "CITYNAME": "Valesdir",
        "CITYCODE": "VLS",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "VLS",
        "AIRPORTNAME": "Valesdir",
    },
    {
        "CITYNAME": "Valera",
        "CITYCODE": "VLV",
        "COUNTRYCODE": "VE",
        "COUNTRYNAME": "Venezuela",
        "AIRPORTCODE": "VLV",
        "AIRPORTNAME": "Carvajal",
    },
    {
        "CITYNAME": "Anglesey",
        "CITYCODE": "VLY",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "VLY",
        "AIRPORTNAME": "Anglesey Airport",
    },
    {
        "CITYNAME": "Baimuru",
        "CITYCODE": "VMU",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "VMU",
        "AIRPORTNAME": "Baimuru",
    },
    {
        "CITYNAME": "Saravane",
        "CITYCODE": "VNA",
        "COUNTRYCODE": "LA",
        "COUNTRYNAME": "Lao People's Democratic Republic",
        "AIRPORTCODE": "VNA",
        "AIRPORTNAME": "Saravane",
    },
    {
        "CITYNAME": "Venice",
        "CITYCODE": "VNC",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "VNC",
        "AIRPORTNAME": "Venice Municipal",
    },
    {
        "CITYNAME": "Viengxay",
        "CITYCODE": "VNG",
        "COUNTRYCODE": "LA",
        "COUNTRYNAME": "Lao People's Democratic Republic",
        "AIRPORTCODE": "VNG",
        "AIRPORTNAME": "Viengxay",
    },
    {
        "CITYNAME": "Vilnius",
        "CITYCODE": "VNO",
        "COUNTRYCODE": "LT",
        "COUNTRYNAME": "Lithuania",
        "AIRPORTCODE": "VNO",
        "AIRPORTNAME": "Vilnius",
    },
    {
        "CITYNAME": "Varanasi",
        "CITYCODE": "VNS",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "VNS",
        "AIRPORTNAME": "Varanasi",
    },
    {
        "CITYNAME": "Vilanculos",
        "CITYCODE": "VNX",
        "COUNTRYCODE": "MZ",
        "COUNTRYNAME": "Mozambique",
        "AIRPORTCODE": "VNX",
        "AIRPORTNAME": "Vilanculos",
    },
    {
        "CITYNAME": "Volgograd",
        "CITYCODE": "VOG",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "VOG",
        "AIRPORTNAME": "Volgograd",
    },
    {
        "CITYNAME": "Vohemar",
        "CITYCODE": "VOH",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "VOH",
        "AIRPORTNAME": "Vohemar",
    },
    {
        "CITYNAME": "Camp Douglas",
        "CITYCODE": "VOK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "VOK",
        "AIRPORTNAME": "VOLK FIELD",
    },
    {
        "CITYNAME": "Volos",
        "CITYCODE": "VOL",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "VOL",
        "AIRPORTNAME": "Nea Anchialos",
    },
    {
        "CITYNAME": "Voronezh",
        "CITYCODE": "VOZ",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "VOZ",
        "AIRPORTNAME": "Voronezh",
    },
    {
        "CITYNAME": "Vopnafjordur",
        "CITYCODE": "VPN",
        "COUNTRYCODE": "IS",
        "COUNTRYNAME": "Iceland",
        "AIRPORTCODE": "VPN",
        "AIRPORTNAME": "Vopnafjordur",
    },
    {
        "CITYNAME": "Valparaiso",
        "CITYCODE": "VPS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "VPS",
        "AIRPORTNAME": "Ft Walton Beach",
    },
    {
        "CITYNAME": "Vieques",
        "CITYCODE": "VQS",
        "COUNTRYCODE": "PR",
        "COUNTRYNAME": "Puerto Rico",
        "AIRPORTCODE": "VQS",
        "AIRPORTNAME": "Vieques",
    },
    {
        "CITYNAME": "Varadero",
        "CITYCODE": "VRA",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "VRA",
        "AIRPORTNAME": "Juan Gualberto Gomez",
    },
    {
        "CITYNAME": "Vero Beach",
        "CITYCODE": "VRB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "VRB",
        "AIRPORTNAME": "Vero Beach Municipal",
    },
    {
        "CITYNAME": "Virac",
        "CITYCODE": "VRC",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "VRC",
        "AIRPORTNAME": "Virac Airport",
    },
    {
        "CITYNAME": "Varkaus",
        "CITYCODE": "VRK",
        "COUNTRYCODE": "FI",
        "COUNTRYNAME": "Finland",
        "AIRPORTCODE": "VRK",
        "AIRPORTNAME": "Varkaus",
    },
    {
        "CITYNAME": "Vila Real",
        "CITYCODE": "VRL",
        "COUNTRYCODE": "PT",
        "COUNTRYNAME": "Portugal",
        "AIRPORTCODE": "VRL",
        "AIRPORTNAME": "Vila Real",
    },
    {
        "CITYNAME": "Verona",
        "CITYCODE": "VRN",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "VRN",
        "AIRPORTNAME": "Verona",
    },
    {
        "CITYNAME": "Villahermosa",
        "CITYCODE": "VSA",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "VSA",
        "AIRPORTNAME": "Capitan Carlos Perez",
    },
    {
        "CITYNAME": "Lugansk",
        "CITYCODE": "VSG",
        "COUNTRYCODE": "UA",
        "COUNTRYNAME": "Ukraine",
        "AIRPORTCODE": "VSG",
        "AIRPORTNAME": "Lugansk",
    },
    {
        "CITYNAME": "Vasteras",
        "CITYCODE": "VST",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "VST",
        "AIRPORTNAME": "Hasslo",
    },
    {
        "CITYNAME": "Vientiane",
        "CITYCODE": "VTE",
        "COUNTRYCODE": "LA",
        "COUNTRYNAME": "Lao People's Democratic Republic",
        "AIRPORTCODE": "VTE",
        "AIRPORTNAME": "Wattay",
    },
    {
        "CITYNAME": "Vitoria",
        "CITYCODE": "VTI",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "VTI",
        "AIRPORTNAME": "Vitoria Bus Station",
    },
    {
        "CITYNAME": "Las Tunas",
        "CITYCODE": "VTU",
        "COUNTRYCODE": "CU",
        "COUNTRYNAME": "Cuba",
        "AIRPORTCODE": "VTU",
        "AIRPORTNAME": "Las Tunas",
    },
    {
        "CITYNAME": "Vishakhapatnam",
        "CITYCODE": "VTZ",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "VTZ",
        "AIRPORTNAME": "Vishakhapatnam",
    },
    {
        "CITYNAME": "Valledupar",
        "CITYCODE": "VUP",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "VUP",
        "AIRPORTNAME": "Valledupar",
    },
    {
        "CITYNAME": "Mahanoro",
        "CITYCODE": "VVB",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "VVB",
        "AIRPORTNAME": "Mahanoro",
    },
    {
        "CITYNAME": "Villavicencio",
        "CITYCODE": "VVC",
        "COUNTRYCODE": "CO",
        "COUNTRYNAME": "Colombia",
        "AIRPORTCODE": "VVC",
        "AIRPORTNAME": "Villavicencio",
    },
    {
        "CITYNAME": "Vladivostok",
        "CITYCODE": "VVO",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "VVO",
        "AIRPORTNAME": "Vladivostok",
    },
    {
        "CITYNAME": "Illizi",
        "CITYCODE": "VVZ",
        "COUNTRYCODE": "DZ",
        "COUNTRYNAME": "Algeria",
        "AIRPORTCODE": "VVZ",
        "AIRPORTNAME": "Illizi",
    },
    {
        "CITYNAME": "Lichinga",
        "CITYCODE": "VXC",
        "COUNTRYCODE": "MZ",
        "COUNTRYNAME": "Mozambique",
        "AIRPORTCODE": "VXC",
        "AIRPORTNAME": "Lichinga",
    },
    {
        "CITYNAME": "Sao Vicente",
        "CITYCODE": "VXE",
        "COUNTRYCODE": "CV",
        "COUNTRYNAME": "Cape Verde",
        "AIRPORTCODE": "VXE",
        "AIRPORTNAME": "San Pedro",
    },
    {
        "CITYNAME": "Vaxjo",
        "CITYCODE": "VXO",
        "COUNTRYCODE": "SE",
        "COUNTRYNAME": "Sweden",
        "AIRPORTCODE": "VXO",
        "AIRPORTNAME": "Vaxjo",
    },
    {
        "CITYNAME": "Vryheid",
        "CITYCODE": "VYD",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "VYD",
        "AIRPORTNAME": "Vryheid",
    },
    {
        "CITYNAME": "Waca",
        "CITYCODE": "WAC",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "WAC",
        "AIRPORTNAME": "Waca",
    },
    {
        "CITYNAME": "Wadi Ad Dawasir",
        "CITYCODE": "WAE",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "WAE",
        "AIRPORTNAME": "Wadi al-Dawasir Domestic Airport",
    },
    {
        "CITYNAME": "Wanganui",
        "CITYCODE": "WAG",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "WAG",
        "AIRPORTNAME": "Wanganui",
    },
    {
        "CITYNAME": "Antsohihy",
        "CITYCODE": "WAI",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "WAI",
        "AIRPORTNAME": "Antsohihy",
    },
    {
        "CITYNAME": "Antsalova",
        "CITYCODE": "WAQ",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "WAQ",
        "AIRPORTNAME": "Antsalova",
    },
    {
        "CITYNAME": "Washington",
        "CITYCODE": "WAS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "BWI",
        "AIRPORTNAME": "Baltimore",
    },
    {
        "CITYNAME": "Washington",
        "CITYCODE": "WAS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "DCA",
        "AIRPORTNAME": "R Reagan National",
    },
    {
        "CITYNAME": "Washington",
        "CITYCODE": "WAS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "IAD",
        "AIRPORTNAME": "Dulles Int'l",
    },
    {
        "CITYNAME": "Waterford",
        "CITYCODE": "WAT",
        "COUNTRYCODE": "IE",
        "COUNTRYNAME": "Ireland",
        "AIRPORTCODE": "WAT",
        "AIRPORTNAME": "Waterford",
    },
    {
        "CITYNAME": "Warsaw",
        "CITYCODE": "WAW",
        "COUNTRYCODE": "PL",
        "COUNTRYNAME": "Poland",
        "AIRPORTCODE": "WAW",
        "AIRPORTNAME": "Warsaw Chopin",
    },
    {
        "CITYNAME": "Stebbins",
        "CITYCODE": "WBB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WBB",
        "AIRPORTNAME": "Stebbins",
    },
    {
        "CITYNAME": "Befandriana",
        "CITYCODE": "WBD",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "WBD",
        "AIRPORTNAME": "Befandriana",
    },
    {
        "CITYNAME": "Bealanana",
        "CITYCODE": "WBE",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "WBE",
        "AIRPORTNAME": "Bealanana",
    },
    {
        "CITYNAME": "Wapenamanda",
        "CITYCODE": "WBM",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "WBM",
        "AIRPORTNAME": "Wapenamanda",
    },
    {
        "CITYNAME": "Beroroha",
        "CITYCODE": "WBO",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "WBO",
        "AIRPORTNAME": "Beroroha",
    },
    {
        "CITYNAME": "Beaver",
        "CITYCODE": "WBQ",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WBQ",
        "AIRPORTNAME": "Beaver",
    },
    {
        "CITYNAME": "Enid",
        "CITYCODE": "WDG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WDG",
        "AIRPORTNAME": "Woodring Municipal",
    },
    {
        "CITYNAME": "Windhoek",
        "CITYCODE": "WDH",
        "COUNTRYCODE": "NA",
        "COUNTRYNAME": "Namibia",
        "AIRPORTCODE": "ERS",
        "AIRPORTNAME": "Eros Airport",
    },
    {
        "CITYNAME": "Windhoek",
        "CITYCODE": "WDH",
        "COUNTRYCODE": "NA",
        "COUNTRYNAME": "Namibia",
        "AIRPORTCODE": "WDH",
        "AIRPORTNAME": "Windhoek int'l",
    },
    {
        "CITYNAME": "Wedau",
        "CITYCODE": "WED",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "WED",
        "AIRPORTNAME": "Wedau",
    },
    {
        "CITYNAME": "Weihai",
        "CITYCODE": "WEH",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "WEH",
        "AIRPORTNAME": "Weihai Dashuibo Airport",
    },
    {
        "CITYNAME": "Weipa",
        "CITYCODE": "WEI",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "WEI",
        "AIRPORTNAME": "Weipa",
    },
    {
        "CITYNAME": "Welkom",
        "CITYCODE": "WEL",
        "COUNTRYCODE": "ZA",
        "COUNTRYNAME": "South Africa",
        "AIRPORTCODE": "WEL",
        "AIRPORTNAME": "Welkom",
    },
    {
        "CITYNAME": "Wagethe",
        "CITYCODE": "WET",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "WET",
        "AIRPORTNAME": "Wagethe",
    },
    {
        "CITYNAME": "Fianarantsoa",
        "CITYCODE": "WFI",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "WFI",
        "AIRPORTNAME": "Fianarantsoa",
    },
    {
        "CITYNAME": "Wagga Wagga",
        "CITYCODE": "WGA",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "WGA",
        "AIRPORTNAME": "Forrest Hill",
    },
    {
        "CITYNAME": "Waingapu",
        "CITYCODE": "WGP",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "WGP",
        "AIRPORTNAME": "Waingapu",
    },
    {
        "CITYNAME": "Wangaratta",
        "CITYCODE": "WGT",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "WGT",
        "AIRPORTNAME": "Wangaratta",
    },
    {
        "CITYNAME": "Whakatane",
        "CITYCODE": "WHK",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "WHK",
        "AIRPORTNAME": "Whakatane",
    },
    {
        "CITYNAME": "Welshpool",
        "CITYCODE": "WHL",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "WHL",
        "AIRPORTNAME": "Welshpool",
    },
    {
        "CITYNAME": "Wick",
        "CITYCODE": "WIC",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "WIC",
        "AIRPORTNAME": "Wick",
    },
    {
        "CITYNAME": "Winton",
        "CITYCODE": "WIN",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "WIN",
        "AIRPORTNAME": "Winton",
    },
    {
        "CITYNAME": "Vienna",
        "CITYCODE": "WIS",
        "COUNTRYCODE": "AT",
        "COUNTRYNAME": "Austria",
        "AIRPORTCODE": "WIS",
        "AIRPORTNAME": "Vienna Shutlebus",
    },
    {
        "CITYNAME": "Woja",
        "CITYCODE": "WJA",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "WJA",
        "AIRPORTNAME": "Woja",
    },
    {
        "CITYNAME": "Wajir",
        "CITYCODE": "WJR",
        "COUNTRYCODE": "KE",
        "COUNTRYNAME": "Kenya",
        "AIRPORTCODE": "WJR",
        "AIRPORTNAME": "Wajir",
    },
    {
        "CITYNAME": "Wanaka",
        "CITYCODE": "WKA",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "WKA",
        "AIRPORTNAME": "Wanaka",
    },
    {
        "CITYNAME": "Wakkanai",
        "CITYCODE": "WKJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "WKJ",
        "AIRPORTNAME": "Hokkaido",
    },
    {
        "CITYNAME": "Aleknagik",
        "CITYCODE": "WKK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WKK",
        "AIRPORTNAME": "Aleknagik",
    },
    {
        "CITYNAME": "Walker's Cay",
        "CITYCODE": "WKR",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "WKR",
        "AIRPORTNAME": "Walker's Cay",
    },
    {
        "CITYNAME": "Labouchere Bay",
        "CITYCODE": "WLB",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WLB",
        "AIRPORTNAME": "Labouchere Bay",
    },
    {
        "CITYNAME": "Wellington",
        "CITYCODE": "WLG",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "WLG",
        "AIRPORTNAME": "Wellington",
    },
    {
        "CITYNAME": "Walaha",
        "CITYCODE": "WLH",
        "COUNTRYCODE": "VU",
        "COUNTRYNAME": "Vanuatu",
        "AIRPORTCODE": "WLH",
        "AIRPORTNAME": "Walaha",
    },
    {
        "CITYNAME": "Selawik",
        "CITYCODE": "WLK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WLK",
        "AIRPORTNAME": "Selawik",
    },
    {
        "CITYNAME": "Waltham",
        "CITYCODE": "WLM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WLM",
        "AIRPORTNAME": "Waltham",
    },
    {
        "CITYNAME": "Wallis Island",
        "CITYCODE": "WLS",
        "COUNTRYCODE": "WF",
        "COUNTRYNAME": "Wallis & Futuna Islands",
        "AIRPORTCODE": "WLS",
        "AIRPORTNAME": "Wallis Island",
    },
    {
        "CITYNAME": "Mandritsara",
        "CITYCODE": "WMA",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "WMA",
        "AIRPORTNAME": "Mandritsara",
    },
    {
        "CITYNAME": "Mandabe",
        "CITYCODE": "WMD",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "WMD",
        "AIRPORTNAME": "Mandabe",
    },
    {
        "CITYNAME": "Mountain Home",
        "CITYCODE": "WMH",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WMH",
        "AIRPORTNAME": "Mountain Home",
    },
    {
        "CITYNAME": "Nowy Dwor Mazowiecki",
        "CITYCODE": "WMI",
        "COUNTRYCODE": "PL",
        "COUNTRYNAME": "Poland",
        "AIRPORTCODE": "WMI",
        "AIRPORTNAME": "Modlin",
    },
    {
        "CITYNAME": "Malaimbandy",
        "CITYCODE": "WML",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "WML",
        "AIRPORTNAME": "Malaimbandy",
    },
    {
        "CITYNAME": "Maroantsetra",
        "CITYCODE": "WMN",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "WMN",
        "AIRPORTNAME": "Maroantsetra",
    },
    {
        "CITYNAME": "White Mountain",
        "CITYCODE": "WMO",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WMO",
        "AIRPORTNAME": "White Mountain",
    },
    {
        "CITYNAME": "Mananara",
        "CITYCODE": "WMR",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "WMR",
        "AIRPORTNAME": "Mananara",
    },
    {
        "CITYNAME": "Wamena",
        "CITYCODE": "WMX",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "WMX",
        "AIRPORTNAME": "Wamena",
    },
    {
        "CITYNAME": "Naga",
        "CITYCODE": "WNP",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "WNP",
        "AIRPORTNAME": "Naga Airport",
    },
    {
        "CITYNAME": "Windorah",
        "CITYCODE": "WNR",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "WNR",
        "AIRPORTNAME": "Windorah",
    },
    {
        "CITYNAME": "Nawabshah",
        "CITYCODE": "WNS",
        "COUNTRYCODE": "PK",
        "COUNTRYNAME": "Pakistan",
        "AIRPORTCODE": "WNS",
        "AIRPORTNAME": "Nawabshah",
    },
    {
        "CITYNAME": "Wenzhou",
        "CITYCODE": "WNZ",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "WNZ",
        "AIRPORTNAME": "Wenzhou",
    },
    {
        "CITYNAME": "Wonan",
        "CITYCODE": "WOT",
        "COUNTRYCODE": "TW",
        "COUNTRYNAME": "Taiwan",
        "AIRPORTCODE": "WOT",
        "AIRPORTNAME": "Wonan",
    },
    {
        "CITYNAME": "Port Berge",
        "CITYCODE": "WPB",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "WPB",
        "AIRPORTNAME": "Port Berge",
    },
    {
        "CITYNAME": "Wipim",
        "CITYCODE": "WPM",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "WPM",
        "AIRPORTNAME": "Wipim",
    },
    {
        "CITYNAME": "Warder",
        "CITYCODE": "WRA",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "WRA",
        "AIRPORTNAME": "Warder",
    },
    {
        "CITYNAME": "Whangarei",
        "CITYCODE": "WRE",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "WRE",
        "AIRPORTNAME": "Whangarei",
    },
    {
        "CITYNAME": "Worland",
        "CITYCODE": "WRL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WRL",
        "AIRPORTNAME": "Worland",
    },
    {
        "CITYNAME": "Wroclaw",
        "CITYCODE": "WRO",
        "COUNTRYCODE": "PL",
        "COUNTRYNAME": "Poland",
        "AIRPORTCODE": "WRO",
        "AIRPORTNAME": "Strachowice",
    },
    {
        "CITYNAME": "Warrawagine",
        "CITYCODE": "WRW",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "WRW",
        "AIRPORTNAME": "Warrawagine Station",
    },
    {
        "CITYNAME": "Westray",
        "CITYCODE": "WRY",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "WRY",
        "AIRPORTNAME": "Westray",
    },
    {
        "CITYNAME": "South Naknek",
        "CITYCODE": "WSN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WSN",
        "AIRPORTNAME": "South Naknek",
    },
    {
        "CITYNAME": "Westerly",
        "CITYCODE": "WST",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WST",
        "AIRPORTNAME": "Sesterly State",
    },
    {
        "CITYNAME": "Wasu",
        "CITYCODE": "WSU",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "WSU",
        "AIRPORTNAME": "Wasu",
    },
    {
        "CITYNAME": "Westsound",
        "CITYCODE": "WSX",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WSX",
        "AIRPORTNAME": "Westsound",
    },
    {
        "CITYNAME": "Airlie Beach",
        "CITYCODE": "WSY",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "WSY",
        "AIRPORTNAME": "Whitsunday Airstrip",
    },
    {
        "CITYNAME": "Westport",
        "CITYCODE": "WSZ",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "WSZ",
        "AIRPORTNAME": "Westport",
    },
    {
        "CITYNAME": "Tambohorano",
        "CITYCODE": "WTA",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "WTA",
        "AIRPORTNAME": "Tambohorano",
    },
    {
        "CITYNAME": "Wellcamp",
        "CITYCODE": "WTB",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "WTB",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "West End",
        "CITYCODE": "WTD",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "WTD",
        "AIRPORTNAME": "Bahamas - West End Airport",
    },
    {
        "CITYNAME": "Wotje Island",
        "CITYCODE": "WTE",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "WTE",
        "AIRPORTNAME": "Wotje Island",
    },
    {
        "CITYNAME": "Noatak",
        "CITYCODE": "WTK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WTK",
        "AIRPORTNAME": "Noatak",
    },
    {
        "CITYNAME": "Tuntutuliak",
        "CITYCODE": "WTL",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WTL",
        "AIRPORTNAME": "Tuntutuliak",
    },
    {
        "CITYNAME": "Wotho Island",
        "CITYCODE": "WTO",
        "COUNTRYCODE": "MH",
        "COUNTRYNAME": "Marshall Islands",
        "AIRPORTCODE": "WTO",
        "AIRPORTNAME": "Wotho Island",
    },
    {
        "CITYNAME": "Tsiroanomandidy",
        "CITYCODE": "WTS",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "WTS",
        "AIRPORTNAME": "Tsiroanomandidy",
    },
    {
        "CITYNAME": "Wau",
        "CITYCODE": "WUG",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "WUG",
        "AIRPORTNAME": "Wau",
    },
    {
        "CITYNAME": "Wuhan",
        "CITYCODE": "WUH",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "WUH",
        "AIRPORTNAME": "Wuhan",
    },
    {
        "CITYNAME": "Wiluna",
        "CITYCODE": "WUN",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "WUN",
        "AIRPORTNAME": "Wiluna",
    },
    {
        "CITYNAME": "Wuvulu Island",
        "CITYCODE": "WUV",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "WUV",
        "AIRPORTNAME": "Wuvulu Is",
    },
    {
        "CITYNAME": "Wuxi",
        "CITYCODE": "WUX",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "WUX",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Walvis Bay",
        "CITYCODE": "WVB",
        "COUNTRYCODE": "NA",
        "COUNTRYNAME": "Namibia",
        "AIRPORTCODE": "WVB",
        "AIRPORTNAME": "Walvis Bay",
    },
    {
        "CITYNAME": "Manakara",
        "CITYCODE": "WVK",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "WVK",
        "AIRPORTNAME": "Manakara",
    },
    {
        "CITYNAME": "Wasilla",
        "CITYCODE": "WWA",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WWA",
        "AIRPORTNAME": "Wasilla",
    },
    {
        "CITYNAME": "Wildwood",
        "CITYCODE": "WWD",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WWD",
        "AIRPORTNAME": "Cape May County",
    },
    {
        "CITYNAME": "Wewak",
        "CITYCODE": "WWK",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "WWK",
        "AIRPORTNAME": "Boram",
    },
    {
        "CITYNAME": "Whale Pass",
        "CITYCODE": "WWP",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WWP",
        "AIRPORTNAME": "Whale Pass",
    },
    {
        "CITYNAME": "Newtok",
        "CITYCODE": "WWT",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WWT",
        "AIRPORTNAME": "Newtok",
    },
    {
        "CITYNAME": "West Wyalong",
        "CITYCODE": "WWY",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "WWY",
        "AIRPORTNAME": "West Wyalong",
    },
    {
        "CITYNAME": "Whyalla",
        "CITYCODE": "WYA",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "WYA",
        "AIRPORTNAME": "Whyalla",
    },
    {
        "CITYNAME": "Yengema",
        "CITYCODE": "WYE",
        "COUNTRYCODE": "SL",
        "COUNTRYNAME": "Sierra Leone",
        "AIRPORTCODE": "WYE",
        "AIRPORTNAME": "Yengema",
    },
    {
        "CITYNAME": "West Yellowstone",
        "CITYCODE": "WYS",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "WYS",
        "AIRPORTNAME": "Yellowstone",
    },
    {
        "CITYNAME": "Chapeco",
        "CITYCODE": "XAP",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "XAP",
        "AIRPORTNAME": "Chapeco",
    },
    {
        "CITYNAME": "Bearskin Lake",
        "CITYCODE": "XBE",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "XBE",
        "AIRPORTNAME": "Bearskin Lake",
    },
    {
        "CITYNAME": "Buno Bedelle",
        "CITYCODE": "XBL",
        "COUNTRYCODE": "ET",
        "COUNTRYNAME": "Ethiopia",
        "AIRPORTCODE": "XBL",
        "AIRPORTNAME": "Buno Bedelle",
    },
    {
        "CITYNAME": "Biniguni",
        "CITYCODE": "XBN",
        "COUNTRYCODE": "PG",
        "COUNTRYNAME": "Papua New Guinea",
        "AIRPORTCODE": "XBN",
        "AIRPORTNAME": "Biniguni",
    },
    {
        "CITYNAME": "Boulsa",
        "CITYCODE": "XBO",
        "COUNTRYCODE": "BF",
        "COUNTRYNAME": "Burkina Faso",
        "AIRPORTCODE": "XBO",
        "AIRPORTNAME": "Boulsa Airport",
    },
    {
        "CITYNAME": "Christmas Island",
        "CITYCODE": "XCH",
        "COUNTRYCODE": "CX",
        "COUNTRYNAME": "Christmas Islands",
        "AIRPORTCODE": "XCH",
        "AIRPORTNAME": "Christmas Island",
    },
    {
        "CITYNAME": "Lille",
        "CITYCODE": "XDB",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "XDB",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Dreux",
        "CITYCODE": "XDR",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "XDR",
        "AIRPORTNAME": "Vernouillet",
    },
    {
        "CITYNAME": "OTTAWA",
        "CITYCODE": "XDS",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "XDS",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Strasbourg",
        "CITYCODE": "XER",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "XER",
        "AIRPORTNAME": "Strasbourg Bus Station",
    },
    {
        "CITYNAME": "Shawinigan",
        "CITYCODE": "XFL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "XFL",
        "AIRPORTNAME": "Flagler County Airport",
    },
    {
        "CITYNAME": "Xiangfan",
        "CITYCODE": "XFN",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "XFN",
        "AIRPORTNAME": "Xiangyang Liuji Airport",
    },
    {
        "CITYNAME": "Finkenwerder",
        "CITYCODE": "XFW",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "XFW",
        "AIRPORTNAME": "Finkenwerder",
    },
    {
        "CITYNAME": "Gaoua",
        "CITYCODE": "XGA",
        "COUNTRYCODE": "BF",
        "COUNTRYNAME": "Burkina Faso",
        "AIRPORTCODE": "XGA",
        "AIRPORTNAME": "Gaoua",
    },
    {
        "CITYNAME": "Gorom Gorom",
        "CITYCODE": "XGG",
        "COUNTRYCODE": "BF",
        "COUNTRYNAME": "Burkina Faso",
        "AIRPORTCODE": "XGG",
        "AIRPORTNAME": "Gorom Gorom",
    },
    {
        "CITYNAME": "Xangongo",
        "CITYCODE": "XGN",
        "COUNTRYCODE": "AO",
        "COUNTRYNAME": "Angola",
        "AIRPORTCODE": "XGN",
        "AIRPORTNAME": "Xangongo",
    },
    {
        "CITYNAME": "Kangiqsualujjuaq",
        "CITYCODE": "XGR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "XGR",
        "AIRPORTNAME": "Kangiqsualujjuaq",
    },
    {
        "CITYNAME": "Dubai",
        "CITYCODE": "XHB",
        "COUNTRYCODE": "AE",
        "COUNTRYNAME": "United Arab Emirates",
        "AIRPORTCODE": "XHB",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Hyeres",
        "CITYCODE": "XHE",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "TLN",
        "AIRPORTNAME": "Hyeres",
    },
    {
        "CITYNAME": "Valence",
        "CITYCODE": "XHK",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "XHK",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Hopa",
        "CITYCODE": "XHQ",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "XHQ",
        "AIRPORTNAME": "Batum - Hopa Airport",
    },
    {
        "CITYNAME": "Xinguera",
        "CITYCODE": "XIG",
        "COUNTRYCODE": "BR",
        "COUNTRYNAME": "Brazil",
        "AIRPORTCODE": "XIG",
        "AIRPORTNAME": "Xinguera",
    },
    {
        "CITYNAME": "Xilinhot",
        "CITYCODE": "XIL",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "XIL",
        "AIRPORTNAME": "Xilinhot",
    },
    {
        "CITYNAME": "Leipzig",
        "CITYCODE": "XIT",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "XIT",
        "AIRPORTNAME": "Leipzig Hauptbahnhof",
    },
    {
        "CITYNAME": "Verona",
        "CITYCODE": "XIX",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "XIX",
        "AIRPORTNAME": "Porta Nuova Rail Airport",
    },
    {
        "CITYNAME": "Valladolid BusStation",
        "CITYCODE": "XJN",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "XJN",
        "AIRPORTNAME": "Valladolid Bus Station",
    },
    {
        "CITYNAME": "Kantchari",
        "CITYCODE": "XKA",
        "COUNTRYCODE": "BF",
        "COUNTRYNAME": "Burkina Faso",
        "AIRPORTCODE": "XKA",
        "AIRPORTNAME": "Kantchari",
    },
    {
        "CITYNAME": "Xieng Khouang",
        "CITYCODE": "XKH",
        "COUNTRYCODE": "LA",
        "COUNTRYNAME": "Lao People's Democratic Republic",
        "AIRPORTCODE": "XKH",
        "AIRPORTNAME": "Xieng Khouang",
    },
    {
        "CITYNAME": "Kasabonika",
        "CITYCODE": "XKS",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "XKS",
        "AIRPORTNAME": "Kasabonika",
    },
    {
        "CITYNAME": "Lac Brochet",
        "CITYCODE": "XLB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "XLB",
        "AIRPORTNAME": "Lac Brochet",
    },
    {
        "CITYNAME": "St Louis",
        "CITYCODE": "XLS",
        "COUNTRYCODE": "SN",
        "COUNTRYNAME": "Senegal",
        "AIRPORTCODE": "XLS",
        "AIRPORTNAME": "St Louis",
    },
    {
        "CITYNAME": "Mallacoota",
        "CITYCODE": "XMC",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "XMC",
        "AIRPORTNAME": "Mallacoota",
    },
    {
        "CITYNAME": "Manihi",
        "CITYCODE": "XMH",
        "COUNTRYCODE": "PF",
        "COUNTRYNAME": "French Polynesia",
        "AIRPORTCODE": "XMH",
        "AIRPORTNAME": "Manihi",
    },
    {
        "CITYNAME": "Xiamen",
        "CITYCODE": "XMN",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "XMN",
        "AIRPORTNAME": "Xiamen",
    },
    {
        "CITYNAME": "Macas",
        "CITYCODE": "XMS",
        "COUNTRYCODE": "EC",
        "COUNTRYNAME": "Ecuador",
        "AIRPORTCODE": "XMS",
        "AIRPORTNAME": "Macas",
    },
    {
        "CITYNAME": "Yam Island",
        "CITYCODE": "XMY",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "XMY",
        "AIRPORTNAME": "Yam Island",
    },
    {
        "CITYNAME": "NW Arkansas",
        "CITYCODE": "xna",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "XNA",
        "AIRPORTNAME": "Northwest Arkansas Regional Airport",
    },
    {
        "CITYNAME": "Xining",
        "CITYCODE": "XNN",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "XNN",
        "AIRPORTNAME": "Xining Caojiabao Airport",
    },
    {
        "CITYNAME": "Nouna",
        "CITYCODE": "XNU",
        "COUNTRYCODE": "BF",
        "COUNTRYNAME": "Burkina Faso",
        "AIRPORTCODE": "XNU",
        "AIRPORTNAME": "Nouna",
    },
    {
        "CITYNAME": "Madrid",
        "CITYCODE": "XOC",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "XOC",
        "AIRPORTNAME": "Madrid Atocha railway station",
    },
    {
        "CITYNAME": "Montpellier",
        "CITYCODE": "XPJ",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "XPJ",
        "AIRPORTNAME": "Montpellier Railway Airport",
    },
    {
        "CITYNAME": "Pukatawagan",
        "CITYCODE": "XPK",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "XPK",
        "AIRPORTNAME": "Pukatawagan",
    },
    {
        "CITYNAME": "Comayagua",
        "CITYCODE": "XPL",
        "COUNTRYCODE": "HN",
        "COUNTRYNAME": "Honduras",
        "AIRPORTCODE": "XPL",
        "AIRPORTNAME": "Coronel Enrique Soto Cano Air Base",
    },
    {
        "CITYNAME": "SEVILLA",
        "CITYCODE": "XQA",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "XQA",
        "AIRPORTNAME": "SANTA JUSTA RAILWAY ST",
    },
    {
        "CITYNAME": "Quepos",
        "CITYCODE": "XQP",
        "COUNTRYCODE": "CR",
        "COUNTRYNAME": "Costa Rica",
        "AIRPORTCODE": "XQP",
        "AIRPORTNAME": "Quepos",
    },
    {
        "CITYNAME": "Qualicum",
        "CITYCODE": "XQU",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "XQU",
        "AIRPORTNAME": "Qualicum",
    },
    {
        "CITYNAME": "Marseille",
        "CITYCODE": "XRF",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "XRF",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Ross River",
        "CITYCODE": "XRR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "XRR",
        "AIRPORTNAME": "Ross River",
    },
    {
        "CITYNAME": "Jerez",
        "CITYCODE": "XRY",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "XRY",
        "AIRPORTNAME": "Jerez",
    },
    {
        "CITYNAME": "Jerez",
        "CITYCODE": "XRY",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "YJW",
        "AIRPORTNAME": "Jerez Railway Station Airport",
    },
    {
        "CITYNAME": "South Caicos",
        "CITYCODE": "XSC",
        "COUNTRYCODE": "TC",
        "COUNTRYNAME": "Turcs & Caicos Islands",
        "AIRPORTCODE": "XSC",
        "AIRPORTNAME": "South Caicos Int'l",
    },
    {
        "CITYNAME": "South Indian Lake",
        "CITYCODE": "XSI",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "XSI",
        "AIRPORTNAME": "South Indian Lake",
    },
    {
        "CITYNAME": "Tadoule Lake",
        "CITYCODE": "XTL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "XTL",
        "AIRPORTNAME": "Tadoule Lake",
    },
    {
        "CITYNAME": "Xuzhou",
        "CITYCODE": "XUZ",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "XUZ",
        "AIRPORTNAME": "Xuzhou Guanyin Airport",
    },
    {
        "CITYNAME": "Venice",
        "CITYCODE": "XVQ",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "XVQ",
        "AIRPORTNAME": "Santa Lucia Rail airport",
    },
    {
        "CITYNAME": "Venice",
        "CITYCODE": "XVY",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "XVY",
        "AIRPORTNAME": "Venice Mestre Train Station",
    },
    {
        "CITYNAME": "Vienna",
        "CITYCODE": "XWC",
        "COUNTRYCODE": "AT",
        "COUNTRYNAME": "Austria",
        "AIRPORTCODE": "XWC",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Strasbourg",
        "CITYCODE": "XWG",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "XWG",
        "AIRPORTNAME": "Strasbourg Railway Station",
    },
    {
        "CITYNAME": "Cascais",
        "CITYCODE": "XXC",
        "COUNTRYCODE": "PT",
        "COUNTRYNAME": "Portugal",
        "AIRPORTCODE": "XXC",
        "AIRPORTNAME": "Cascais Aerodrome",
    },
    {
        "CITYNAME": "Budapest",
        "CITYCODE": "XXQ",
        "COUNTRYCODE": "HU",
        "COUNTRYNAME": "Hungary",
        "AIRPORTCODE": "XXQ",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Yandina",
        "CITYCODE": "XYA",
        "COUNTRYCODE": "SB",
        "COUNTRYNAME": "Solomon Islands",
        "AIRPORTCODE": "XYA",
        "AIRPORTNAME": "Yandina",
    },
    {
        "CITYNAME": "Lyon",
        "CITYCODE": "XYD",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "XYD",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Prague",
        "CITYCODE": "XYG",
        "COUNTRYCODE": "CZ",
        "COUNTRYNAME": "Czech Republic",
        "AIRPORTCODE": "XYG",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Avignon",
        "CITYCODE": "XZN",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "XZN",
        "AIRPORTNAME": "Avignon TGV Train Station",
    },
    {
        "CITYNAME": "Toulon",
        "CITYCODE": "XZV",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "XZV",
        "AIRPORTNAME": "Gare de Toulon",
    },
    {
        "CITYNAME": "Anahim Lake",
        "CITYCODE": "YAA",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YAA",
        "AIRPORTNAME": "Anahim Lake",
    },
    {
        "CITYNAME": "Arctic Bay",
        "CITYCODE": "YAB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YAB",
        "AIRPORTNAME": "Arctic Bay",
    },
    {
        "CITYNAME": "Cat Lake",
        "CITYCODE": "YAC",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YAC",
        "AIRPORTNAME": "Cat Lake",
    },
    {
        "CITYNAME": "Fort Frances",
        "CITYCODE": "YAG",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YAG",
        "AIRPORTNAME": "Fort Frances Municipal",
    },
    {
        "CITYNAME": "Yakutat",
        "CITYCODE": "YAK",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "YAK",
        "AIRPORTNAME": "Yakutat",
    },
    {
        "CITYNAME": "Alert Bay",
        "CITYCODE": "YAL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YAL",
        "AIRPORTNAME": "Alert Bay",
    },
    {
        "CITYNAME": "Sault Ste Marie",
        "CITYCODE": "YAM",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YAM",
        "AIRPORTNAME": "Sault Ste Marie",
    },
    {
        "CITYNAME": "Yaounde",
        "CITYCODE": "YAO",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "NSI",
        "AIRPORTNAME": "Nsimalen airport",
    },
    {
        "CITYNAME": "Yaounde",
        "CITYCODE": "YAO",
        "COUNTRYCODE": "CM",
        "COUNTRYNAME": "Cameroon",
        "AIRPORTCODE": "YAO",
        "AIRPORTNAME": "Yaounde Arpt",
    },
    {
        "CITYNAME": "Yap",
        "CITYCODE": "YAP",
        "COUNTRYCODE": "FM",
        "COUNTRYNAME": "Micronesia",
        "AIRPORTCODE": "YAP",
        "AIRPORTNAME": "Yap",
    },
    {
        "CITYNAME": "Attawapiskat",
        "CITYCODE": "YAT",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YAT",
        "AIRPORTNAME": "Attawapiskat",
    },
    {
        "CITYNAME": "St Anthony",
        "CITYCODE": "YAY",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YAY",
        "AIRPORTNAME": "St Anthony",
    },
    {
        "CITYNAME": "Baie Comeau",
        "CITYCODE": "YBC",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YBC",
        "AIRPORTNAME": "Baie Comeau",
    },
    {
        "CITYNAME": "Uranium City",
        "CITYCODE": "YBE",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YBE",
        "AIRPORTNAME": "Uranium City",
    },
    {
        "CITYNAME": "Bagotville",
        "CITYCODE": "YBG",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YBG",
        "AIRPORTNAME": "Bagotville",
    },
    {
        "CITYNAME": "Black Tickle",
        "CITYCODE": "YBI",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YBI",
        "AIRPORTNAME": "Black Tickle",
    },
    {
        "CITYNAME": "Baie Johan Beetz",
        "CITYCODE": "YBJ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YBJ",
        "AIRPORTNAME": "Baie Johan Beetz",
    },
    {
        "CITYNAME": "Campbell River",
        "CITYCODE": "YBL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YBL",
        "AIRPORTNAME": "Campbell River",
    },
    {
        "CITYNAME": "Bronson Creek",
        "CITYCODE": "YBM",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YBM",
        "AIRPORTNAME": "Bronson Creek",
    },
    {
        "CITYNAME": "Yibin",
        "CITYCODE": "YBP",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "YBP",
        "AIRPORTNAME": "Yibin Caiba Airport",
    },
    {
        "CITYNAME": "Brandon",
        "CITYCODE": "YBR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YBR",
        "AIRPORTNAME": "Brandon",
    },
    {
        "CITYNAME": "Brochet",
        "CITYCODE": "YBT",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YBT",
        "AIRPORTNAME": "Brochet",
    },
    {
        "CITYNAME": "Berens River",
        "CITYCODE": "YBV",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YBV",
        "AIRPORTNAME": "Berens River",
    },
    {
        "CITYNAME": "Blanc Sablon",
        "CITYCODE": "YBX",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YBX",
        "AIRPORTNAME": "Blanc Sablon",
    },
    {
        "CITYNAME": "Cambridge Bay",
        "CITYCODE": "YCB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YCB",
        "AIRPORTNAME": "Cambridge Bay",
    },
    {
        "CITYNAME": "Nanaimo",
        "CITYCODE": "YCD",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YCD",
        "AIRPORTNAME": "Nanaimo",
    },
    {
        "CITYNAME": "Castlegar",
        "CITYCODE": "YCG",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YCG",
        "AIRPORTNAME": "Castlegar",
    },
    {
        "CITYNAME": "Miramichi",
        "CITYCODE": "YCH",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YCH",
        "AIRPORTNAME": "Miramichi",
    },
    {
        "CITYNAME": "Colville Lake",
        "CITYCODE": "YCK",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YCK",
        "AIRPORTNAME": "Colville Lake",
    },
    {
        "CITYNAME": "Charlo",
        "CITYCODE": "YCL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YCL",
        "AIRPORTNAME": "Charlo",
    },
    {
        "CITYNAME": "Cochrane",
        "CITYCODE": "YCN",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YCN",
        "AIRPORTNAME": "Cochrane",
    },
    {
        "CITYNAME": "Coppermine",
        "CITYCODE": "YCO",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YCO",
        "AIRPORTNAME": "Coppermine",
    },
    {
        "CITYNAME": "Chetwynd",
        "CITYCODE": "YCQ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YCQ",
        "AIRPORTNAME": "Chetwynd",
    },
    {
        "CITYNAME": "Cross Lake",
        "CITYCODE": "YCR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YCR",
        "AIRPORTNAME": "Cross Lake",
    },
    {
        "CITYNAME": "Chesterfield Inle",
        "CITYCODE": "YCS",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YCS",
        "AIRPORTNAME": "Chesterfield Inle",
    },
    {
        "CITYNAME": "Cullaton Lake",
        "CITYCODE": "YCU",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YCU",
        "AIRPORTNAME": "Yuncheng Guangong Airport",
    },
    {
        "CITYNAME": "Cartierville",
        "CITYCODE": "YCV",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YCV",
        "AIRPORTNAME": "Cartierville",
    },
    {
        "CITYNAME": "Clyde River",
        "CITYCODE": "YCY",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YCY",
        "AIRPORTNAME": "Clyde River",
    },
    {
        "CITYNAME": "Dawson City",
        "CITYCODE": "YDA",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YDA",
        "AIRPORTNAME": "Dawson City",
    },
    {
        "CITYNAME": "Paradise River",
        "CITYCODE": "YDE",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YDE",
        "AIRPORTNAME": "Paradise River",
    },
    {
        "CITYNAME": "Deer Lake",
        "CITYCODE": "YDF",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YDF",
        "AIRPORTNAME": "Deer Lake",
    },
    {
        "CITYNAME": "Davis Inlet",
        "CITYCODE": "YDI",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YDI",
        "AIRPORTNAME": "Davis Inlet",
    },
    {
        "CITYNAME": "Dease Lake",
        "CITYCODE": "YDL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YDL",
        "AIRPORTNAME": "Dease Lake",
    },
    {
        "CITYNAME": "Dauphin",
        "CITYCODE": "YDN",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YDN",
        "AIRPORTNAME": "Dauphin",
    },
    {
        "CITYNAME": "Dolbeau",
        "CITYCODE": "YDO",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YDO",
        "AIRPORTNAME": "St Methode",
    },
    {
        "CITYNAME": "Nain",
        "CITYCODE": "YDP",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YDP",
        "AIRPORTNAME": "Nain",
    },
    {
        "CITYNAME": "Dawson Creek",
        "CITYCODE": "YDQ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YDQ",
        "AIRPORTNAME": "Dawson Creek",
    },
    {
        "CITYNAME": "Edmonton",
        "CITYCODE": "YEA",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YEG",
        "AIRPORTNAME": "Edmonton Int'l",
    },
    {
        "CITYNAME": "Edmonton",
        "CITYCODE": "YEA",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YXD",
        "AIRPORTNAME": "Edmonton Municipal",
    },
    {
        "CITYNAME": "Yechon",
        "CITYCODE": "YEC",
        "COUNTRYCODE": "KR",
        "COUNTRYNAME": "South Korea",
        "AIRPORTCODE": "YEC",
        "AIRPORTNAME": "Yechon",
    },
    {
        "CITYNAME": "Ennadai Lake",
        "CITYCODE": "YEI",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YEI",
        "AIRPORTNAME": "Bursa - Yenisehir",
    },
    {
        "CITYNAME": "Eskimo Point",
        "CITYCODE": "YEK",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YEK",
        "AIRPORTNAME": "Eskimo Point",
    },
    {
        "CITYNAME": "Elliot Lake",
        "CITYCODE": "YEL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YEL",
        "AIRPORTNAME": "Elliot Lake",
    },
    {
        "CITYNAME": "Fort Severn",
        "CITYCODE": "YER",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YER",
        "AIRPORTNAME": "Fort Severn",
    },
    {
        "CITYNAME": "Inuvik",
        "CITYCODE": "YEV",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YEV",
        "AIRPORTNAME": "Inuvik",
    },
    {
        "CITYNAME": "Fort Albany",
        "CITYCODE": "YFA",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YFA",
        "AIRPORTNAME": "Fort Albany",
    },
    {
        "CITYNAME": "Iqaluit",
        "CITYCODE": "YFB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YFB",
        "AIRPORTNAME": "Iqaluit",
    },
    {
        "CITYNAME": "Fredericton",
        "CITYCODE": "YFC",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YFC",
        "AIRPORTNAME": "Fredericton",
    },
    {
        "CITYNAME": "Fort Hope",
        "CITYCODE": "YFH",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YFH",
        "AIRPORTNAME": "Fort Hope",
    },
    {
        "CITYNAME": "Flin Flon",
        "CITYCODE": "YFO",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YFO",
        "AIRPORTNAME": "Flin Flon",
    },
    {
        "CITYNAME": "Fort Resolution",
        "CITYCODE": "YFR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YFR",
        "AIRPORTNAME": "Fort Resolution",
    },
    {
        "CITYNAME": "Fort Simpson",
        "CITYCODE": "YFS",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YFS",
        "AIRPORTNAME": "Fort Simpson",
    },
    {
        "CITYNAME": "Fox Harbour",
        "CITYCODE": "YFX",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YFX",
        "AIRPORTNAME": "Fox Harbour",
    },
    {
        "CITYNAME": "Gillies Bay",
        "CITYCODE": "YGB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YGB",
        "AIRPORTNAME": "Gillies Bay",
    },
    {
        "CITYNAME": "Fort Good Hope",
        "CITYCODE": "YGH",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YGH",
        "AIRPORTNAME": "Fort Good Hope",
    },
    {
        "CITYNAME": "Yonago",
        "CITYCODE": "YGJ",
        "COUNTRYCODE": "JP",
        "COUNTRYNAME": "Japan",
        "AIRPORTCODE": "YGJ",
        "AIRPORTNAME": "Miho",
    },
    {
        "CITYNAME": "Kingston",
        "CITYCODE": "YGK",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "XEG",
        "AIRPORTNAME": "Kingston Train Station",
    },
    {
        "CITYNAME": "Kingston",
        "CITYCODE": "YGK",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YGK",
        "AIRPORTNAME": "Kingston",
    },
    {
        "CITYNAME": "La Grande",
        "CITYCODE": "YGL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YGL",
        "AIRPORTNAME": "La Grande",
    },
    {
        "CITYNAME": "Gods Narrows",
        "CITYCODE": "YGO",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YGO",
        "AIRPORTNAME": "Gods Narrows",
    },
    {
        "CITYNAME": "Gaspe",
        "CITYCODE": "YGP",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YGP",
        "AIRPORTNAME": "Gaspe",
    },
    {
        "CITYNAME": "Geraldton",
        "CITYCODE": "YGQ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YGQ",
        "AIRPORTNAME": "Geraldton",
    },
    {
        "CITYNAME": "Iles De La Madele",
        "CITYCODE": "YGR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YGR",
        "AIRPORTNAME": "Iles De La Madele",
    },
    {
        "CITYNAME": "Igloolik",
        "CITYCODE": "YGT",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YGT",
        "AIRPORTNAME": "Igloolik",
    },
    {
        "CITYNAME": "Havre St Pierre",
        "CITYCODE": "YGV",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YGV",
        "AIRPORTNAME": "Havre St Pierre",
    },
    {
        "CITYNAME": "Kuujjuarapik",
        "CITYCODE": "YGW",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YGW",
        "AIRPORTNAME": "Kuujjuarapik",
    },
    {
        "CITYNAME": "Gillam",
        "CITYCODE": "YGX",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YGX",
        "AIRPORTNAME": "Gillam",
    },
    {
        "CITYNAME": "Grise Fiord",
        "CITYCODE": "YGZ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YGZ",
        "AIRPORTNAME": "Grise Fiord",
    },
    {
        "CITYNAME": "Port Hope Simpson",
        "CITYCODE": "YHA",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YHA",
        "AIRPORTNAME": "Port Hope Simpson",
    },
    {
        "CITYNAME": "Dryden",
        "CITYCODE": "YHD",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YHD",
        "AIRPORTNAME": "Dryden Municipal",
    },
    {
        "CITYNAME": "Hearst",
        "CITYCODE": "YHF",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YHF",
        "AIRPORTNAME": "Hearst",
    },
    {
        "CITYNAME": "Holman",
        "CITYCODE": "YHI",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YHI",
        "AIRPORTNAME": "Holman",
    },
    {
        "CITYNAME": "Gjoa Haven",
        "CITYCODE": "YHK",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YHK",
        "AIRPORTNAME": "Gjoa Haven",
    },
    {
        "CITYNAME": "Hamilton",
        "CITYCODE": "YHM",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YHM",
        "AIRPORTNAME": "Hamilton",
    },
    {
        "CITYNAME": "Hornepayne",
        "CITYCODE": "YHN",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YHN",
        "AIRPORTNAME": "Hornepayne",
    },
    {
        "CITYNAME": "Hopedale",
        "CITYCODE": "YHO",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YHO",
        "AIRPORTNAME": "Hopedale",
    },
    {
        "CITYNAME": "Poplar Hill",
        "CITYCODE": "YHP",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YHP",
        "AIRPORTNAME": "Poplar Hill",
    },
    {
        "CITYNAME": "Chevery",
        "CITYCODE": "YHR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YHR",
        "AIRPORTNAME": "Chevery",
    },
    {
        "CITYNAME": "Sechelt",
        "CITYCODE": "YHS",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YHS",
        "AIRPORTNAME": "Sechelt",
    },
    {
        "CITYNAME": "Halifax",
        "CITYCODE": "YHZ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YAW",
        "AIRPORTNAME": "Shearwater",
    },
    {
        "CITYNAME": "Halifax",
        "CITYCODE": "YHZ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YHZ",
        "AIRPORTNAME": "Halifax Int'l",
    },
    {
        "CITYNAME": "Atikokan",
        "CITYCODE": "YIB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YIB",
        "AIRPORTNAME": "Atikokan",
    },
    {
        "CITYNAME": "Pakuashipi",
        "CITYCODE": "YIF",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YIF",
        "AIRPORTNAME": "Pakuashipi",
    },
    {
        "CITYNAME": "Yichang",
        "CITYCODE": "YIH",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "YIH",
        "AIRPORTNAME": "Yichang",
    },
    {
        "CITYNAME": "Ivujivik",
        "CITYCODE": "YIK",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YIK",
        "AIRPORTNAME": "Ivujivik",
    },
    {
        "CITYNAME": "Pond Inlet",
        "CITYCODE": "YIO",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YIO",
        "AIRPORTNAME": "Pond Inlet",
    },
    {
        "CITYNAME": "Island Lk Garden",
        "CITYCODE": "YIV",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YIV",
        "AIRPORTNAME": "Island Lk Garden",
    },
    {
        "CITYNAME": "Yiwu",
        "CITYCODE": "YIW",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "YIW",
        "AIRPORTNAME": "YIWU",
    },
    {
        "CITYNAME": "Barcelona",
        "CITYCODE": "YJB",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "YJB",
        "AIRPORTNAME": "Barcelona Sants Train Station",
    },
    {
        "CITYNAME": "Alicante",
        "CITYCODE": "YJE",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "YJE",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "St Jean",
        "CITYCODE": "YJN",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YJN",
        "AIRPORTNAME": "St Jean",
    },
    {
        "CITYNAME": "Stephenville",
        "CITYCODE": "YJT",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YJT",
        "AIRPORTNAME": "Stephenville",
    },
    {
        "CITYNAME": "Kamloops",
        "CITYCODE": "YKA",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YKA",
        "AIRPORTNAME": "Kamloops",
    },
    {
        "CITYNAME": "Kitchener",
        "CITYCODE": "YKF",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YKF",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Kangirsuk",
        "CITYCODE": "YKG",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YKG",
        "AIRPORTNAME": "Kangirsuk",
    },
    {
        "CITYNAME": "Schefferville",
        "CITYCODE": "YKL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YKL",
        "AIRPORTNAME": "Schefferville",
    },
    {
        "CITYNAME": "Yakima",
        "CITYCODE": "YKM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "YKM",
        "AIRPORTNAME": "Yakima Air Terminal",
    },
    {
        "CITYNAME": "Yankton",
        "CITYCODE": "YKN",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "YKN",
        "AIRPORTNAME": "Chang Gurney",
    },
    {
        "CITYNAME": "Yuksekova",
        "CITYCODE": "YKO",
        "COUNTRYCODE": "TR",
        "COUNTRYNAME": "Turkey",
        "AIRPORTCODE": "YKO",
        "AIRPORTNAME": "Hakkari - Yuksekova Airport",
    },
    {
        "CITYNAME": "Waskaganish",
        "CITYCODE": "YKQ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YKQ",
        "AIRPORTNAME": "Waskaganish",
    },
    {
        "CITYNAME": "Yakutsk",
        "CITYCODE": "YKS",
        "COUNTRYCODE": "RU",
        "COUNTRYNAME": "Russian Federation",
        "AIRPORTCODE": "YKS",
        "AIRPORTNAME": "Yakutsk",
    },
    {
        "CITYNAME": "Klemtu",
        "CITYCODE": "YKT",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YKT",
        "AIRPORTNAME": "Klemtu",
    },
    {
        "CITYNAME": "Chisasibi",
        "CITYCODE": "YKU",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YKU",
        "AIRPORTNAME": "Chisasibi",
    },
    {
        "CITYNAME": "Kirkland Lake",
        "CITYCODE": "YKX",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YKX",
        "AIRPORTNAME": "Kirkland Lake",
    },
    {
        "CITYNAME": "Kindersley",
        "CITYCODE": "YKY",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YKY",
        "AIRPORTNAME": "Kindersley",
    },
    {
        "CITYNAME": "Lake Harbour",
        "CITYCODE": "YLC",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YLC",
        "AIRPORTNAME": "Lake Harbour",
    },
    {
        "CITYNAME": "Chapleau",
        "CITYCODE": "YLD",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YLD",
        "AIRPORTNAME": "Chapleau",
    },
    {
        "CITYNAME": "Lac La Martre",
        "CITYCODE": "YLE",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YLE",
        "AIRPORTNAME": "Lac La Martre",
    },
    {
        "CITYNAME": "Lloydminster",
        "CITYCODE": "YLL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YLL",
        "AIRPORTNAME": "Lloydminster",
    },
    {
        "CITYNAME": "Leaf Rapids",
        "CITYCODE": "YLR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YLR",
        "AIRPORTNAME": "Leaf Rapids",
    },
    {
        "CITYNAME": "Kelowna",
        "CITYCODE": "YLW",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YLW",
        "AIRPORTNAME": "Kelowna",
    },
    {
        "CITYNAME": "Mayo",
        "CITYCODE": "YMA",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YMA",
        "AIRPORTNAME": "Mayo",
    },
    {
        "CITYNAME": "Matane",
        "CITYCODE": "YME",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YME",
        "AIRPORTNAME": "Matane",
    },
    {
        "CITYNAME": "Manitouwadge",
        "CITYCODE": "YMG",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YMG",
        "AIRPORTNAME": "Manitouwadge",
    },
    {
        "CITYNAME": "Mary's Harbour",
        "CITYCODE": "YMH",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YMH",
        "AIRPORTNAME": "Mary's Harbour",
    },
    {
        "CITYNAME": "Fort Mcmurray",
        "CITYCODE": "YMM",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YMM",
        "AIRPORTNAME": "Fort Mcmurray",
    },
    {
        "CITYNAME": "Makkovik",
        "CITYCODE": "YMN",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YMN",
        "AIRPORTNAME": "Makkovik",
    },
    {
        "CITYNAME": "Moosonee",
        "CITYCODE": "YMO",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YMO",
        "AIRPORTNAME": "Moosonee",
    },
    {
        "CITYNAME": "Montreal",
        "CITYCODE": "YMQ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YHU",
        "AIRPORTNAME": "St Hubert",
    },
    {
        "CITYNAME": "Montreal",
        "CITYCODE": "YMQ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YMX",
        "AIRPORTNAME": "Mirabel",
    },
    {
        "CITYNAME": "Montreal",
        "CITYCODE": "YMQ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YUL",
        "AIRPORTNAME": "Dorval",
    },
    {
        "CITYNAME": "Yurimaguas",
        "CITYCODE": "YMS",
        "COUNTRYCODE": "PE",
        "COUNTRYNAME": "Peru",
        "AIRPORTCODE": "YMS",
        "AIRPORTNAME": "Yurimaguas",
    },
    {
        "CITYNAME": "Chibougamau",
        "CITYCODE": "YMT",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YMT",
        "AIRPORTNAME": "Chibougamau",
    },
    {
        "CITYNAME": "Montreal",
        "CITYCODE": "YMY",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YMY",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Natashquan",
        "CITYCODE": "YNA",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YNA",
        "AIRPORTNAME": "Natashquan",
    },
    {
        "CITYNAME": "Yanbo",
        "CITYCODE": "YNB",
        "COUNTRYCODE": "SA",
        "COUNTRYNAME": "Saudi Arabia",
        "AIRPORTCODE": "YNB",
        "AIRPORTNAME": "Yanbu",
    },
    {
        "CITYNAME": "Wemindji",
        "CITYCODE": "YNC",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YNC",
        "AIRPORTNAME": "Wemindji",
    },
    {
        "CITYNAME": "Gatineau",
        "CITYCODE": "YND",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YND",
        "AIRPORTNAME": "Gatineau",
    },
    {
        "CITYNAME": "Norway House",
        "CITYCODE": "YNE",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YNE",
        "AIRPORTNAME": "Norway House",
    },
    {
        "CITYNAME": "Youngstown Warren",
        "CITYCODE": "YNG",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "YNG",
        "AIRPORTNAME": "Youngstown Warren",
    },
    {
        "CITYNAME": "Yanji",
        "CITYCODE": "YNJ",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "YNJ",
        "AIRPORTNAME": "Yanji Chaoyangchuan Airport",
    },
    {
        "CITYNAME": "Points North Land",
        "CITYCODE": "YNL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YNL",
        "AIRPORTNAME": "Points North Land",
    },
    {
        "CITYNAME": "Matagami",
        "CITYCODE": "YNM",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YNM",
        "AIRPORTNAME": "Matagami",
    },
    {
        "CITYNAME": "North Spirit Lake",
        "CITYCODE": "YNO",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YNO",
        "AIRPORTNAME": "North Spirit Lake",
    },
    {
        "CITYNAME": "Nemiscau",
        "CITYCODE": "YNS",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YNS",
        "AIRPORTNAME": "Nemiscau",
    },
    {
        "CITYNAME": "Yantai",
        "CITYCODE": "YNT",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "YNT",
        "AIRPORTNAME": "Laishan",
    },
    {
        "CITYNAME": "Yancheng",
        "CITYCODE": "YNZ",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "YNZ",
        "AIRPORTNAME": "Yancheng Jiangsu Nanyang Intl Airport",
    },
    {
        "CITYNAME": "Old Crow",
        "CITYCODE": "YOC",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YOC",
        "AIRPORTNAME": "Old Crow",
    },
    {
        "CITYNAME": "Oxford House",
        "CITYCODE": "YOH",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YOH",
        "AIRPORTNAME": "Oxford House",
    },
    {
        "CITYNAME": "High Level",
        "CITYCODE": "YOJ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YOJ",
        "AIRPORTNAME": "Footner Lake",
    },
    {
        "CITYNAME": "Yola",
        "CITYCODE": "YOL",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "YOL",
        "AIRPORTNAME": "Yola",
    },
    {
        "CITYNAME": "Trashigang",
        "CITYCODE": "YON",
        "COUNTRYCODE": "BT",
        "COUNTRYNAME": "Bhutan",
        "AIRPORTCODE": "YON",
        "AIRPORTNAME": "Yongphulla Airport",
    },
    {
        "CITYNAME": "Oshawa",
        "CITYCODE": "YOO",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YOO",
        "AIRPORTNAME": "Oshawa",
    },
    {
        "CITYNAME": "Rainbow Lake",
        "CITYCODE": "YOP",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YOP",
        "AIRPORTNAME": "Rainbow Lake",
    },
    {
        "CITYNAME": "Ottawa",
        "CITYCODE": "YOW",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YOW",
        "AIRPORTNAME": "Ottawa Int'l",
    },
    {
        "CITYNAME": "Ottawa",
        "CITYCODE": "YOW",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YRO",
        "AIRPORTNAME": "Rockcliffe St",
    },
    {
        "CITYNAME": "Prince Albert",
        "CITYCODE": "YPA",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YPA",
        "AIRPORTNAME": "Prince Albert",
    },
    {
        "CITYNAME": "Port Alberni",
        "CITYCODE": "YPB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YPB",
        "AIRPORTNAME": "Port Alberni",
    },
    {
        "CITYNAME": "Paulatuk",
        "CITYCODE": "YPC",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YPC",
        "AIRPORTNAME": "Paulatuk",
    },
    {
        "CITYNAME": "Peace River",
        "CITYCODE": "YPE",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YPE",
        "AIRPORTNAME": "Peace River",
    },
    {
        "CITYNAME": "Inukjuak",
        "CITYCODE": "YPH",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YPH",
        "AIRPORTNAME": "Inukjuak",
    },
    {
        "CITYNAME": "Port Simpson",
        "CITYCODE": "YPI",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YPI",
        "AIRPORTNAME": "Port Simpson",
    },
    {
        "CITYNAME": "Aupauluk",
        "CITYCODE": "YPJ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YPJ",
        "AIRPORTNAME": "Aupauluk",
    },
    {
        "CITYNAME": "Pickle Lake",
        "CITYCODE": "YPL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YPL",
        "AIRPORTNAME": "Pickle Lake",
    },
    {
        "CITYNAME": "Pikangikum",
        "CITYCODE": "YPM",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YPM",
        "AIRPORTNAME": "Pikangikum",
    },
    {
        "CITYNAME": "Port Menier",
        "CITYCODE": "YPN",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YPN",
        "AIRPORTNAME": "Port Menier",
    },
    {
        "CITYNAME": "Peterborough",
        "CITYCODE": "YPQ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YPQ",
        "AIRPORTNAME": "Peterborough",
    },
    {
        "CITYNAME": "Prince Rupert",
        "CITYCODE": "YPR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YPR",
        "AIRPORTNAME": "Digby Island",
    },
    {
        "CITYNAME": "Prince Rupert",
        "CITYCODE": "YPR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZSW",
        "AIRPORTNAME": "Seal Cove",
    },
    {
        "CITYNAME": "Powell River",
        "CITYCODE": "YPW",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YPW",
        "AIRPORTNAME": "Powell River",
    },
    {
        "CITYNAME": "Povungnituk",
        "CITYCODE": "YPX",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YPX",
        "AIRPORTNAME": "Povungnituk",
    },
    {
        "CITYNAME": "Fort Chipewyan",
        "CITYCODE": "YPY",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YPY",
        "AIRPORTNAME": "Fort Chipewyan",
    },
    {
        "CITYNAME": "Muskoka",
        "CITYCODE": "YQA",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQA",
        "AIRPORTNAME": "Muskoka",
    },
    {
        "CITYNAME": "Quebec",
        "CITYCODE": "YQB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "XLJ",
        "AIRPORTNAME": "Quebec Stn. Rail Svce. Airport",
    },
    {
        "CITYNAME": "Quebec",
        "CITYCODE": "YQB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQB",
        "AIRPORTNAME": "Quebec",
    },
    {
        "CITYNAME": "Quaqtaq",
        "CITYCODE": "YQC",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQC",
        "AIRPORTNAME": "Quaqtaq",
    },
    {
        "CITYNAME": "The Pas",
        "CITYCODE": "YQD",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQD",
        "AIRPORTNAME": "The Pas",
    },
    {
        "CITYNAME": "Windsor",
        "CITYCODE": "YQG",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "XEC",
        "AIRPORTNAME": "Windsor Railway Station Airport",
    },
    {
        "CITYNAME": "Windsor",
        "CITYCODE": "YQG",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQG",
        "AIRPORTNAME": "Windsor",
    },
    {
        "CITYNAME": "Watson Lake",
        "CITYCODE": "YQH",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQH",
        "AIRPORTNAME": "Watson Lake",
    },
    {
        "CITYNAME": "Yarmouth",
        "CITYCODE": "YQI",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQI",
        "AIRPORTNAME": "Yarmouth",
    },
    {
        "CITYNAME": "Kenora",
        "CITYCODE": "YQK",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQK",
        "AIRPORTNAME": "Kenora",
    },
    {
        "CITYNAME": "Lethbridge",
        "CITYCODE": "YQL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQL",
        "AIRPORTNAME": "Lethbridge",
    },
    {
        "CITYNAME": "Moncton",
        "CITYCODE": "YQM",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQM",
        "AIRPORTNAME": "Moncton",
    },
    {
        "CITYNAME": "Comox",
        "CITYCODE": "YQQ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQQ",
        "AIRPORTNAME": "Comox",
    },
    {
        "CITYNAME": "Regina",
        "CITYCODE": "YQR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQR",
        "AIRPORTNAME": "Regina",
    },
    {
        "CITYNAME": "Thunder Bay",
        "CITYCODE": "YQT",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQT",
        "AIRPORTNAME": "Thunder Bay",
    },
    {
        "CITYNAME": "Grande Prairie",
        "CITYCODE": "YQU",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQU",
        "AIRPORTNAME": "Grande Prairie",
    },
    {
        "CITYNAME": "Yorkton",
        "CITYCODE": "YQV",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQV",
        "AIRPORTNAME": "Yorkton",
    },
    {
        "CITYNAME": "North Battleford",
        "CITYCODE": "YQW",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQW",
        "AIRPORTNAME": "North Battleford",
    },
    {
        "CITYNAME": "Gander",
        "CITYCODE": "YQX",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQX",
        "AIRPORTNAME": "Gander",
    },
    {
        "CITYNAME": "Sydney",
        "CITYCODE": "YQY",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQY",
        "AIRPORTNAME": "Sydney",
    },
    {
        "CITYNAME": "Quesnel",
        "CITYCODE": "YQZ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YQZ",
        "AIRPORTNAME": "Quesnel",
    },
    {
        "CITYNAME": "Rae Lakes",
        "CITYCODE": "YRA",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YRA",
        "AIRPORTNAME": "Rae Lakes",
    },
    {
        "CITYNAME": "Resolute",
        "CITYCODE": "YRB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YRB",
        "AIRPORTNAME": "Resolute",
    },
    {
        "CITYNAME": "Dean River",
        "CITYCODE": "YRD",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YRD",
        "AIRPORTNAME": "Dean River",
    },
    {
        "CITYNAME": "Cartwright",
        "CITYCODE": "YRF",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YRF",
        "AIRPORTNAME": "Cartwright",
    },
    {
        "CITYNAME": "Rigolet",
        "CITYCODE": "YRG",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YRG",
        "AIRPORTNAME": "Rigolet",
    },
    {
        "CITYNAME": "Roberval",
        "CITYCODE": "YRJ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YRJ",
        "AIRPORTNAME": "Roberval",
    },
    {
        "CITYNAME": "Red Lake",
        "CITYCODE": "YRL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YRL",
        "AIRPORTNAME": "Red Lake",
    },
    {
        "CITYNAME": "Red Sucker Lake",
        "CITYCODE": "YRS",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YRS",
        "AIRPORTNAME": "Red Sucker Lake",
    },
    {
        "CITYNAME": "Rankin Inlet",
        "CITYCODE": "YRT",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YRT",
        "AIRPORTNAME": "Rankin Inlet",
    },
    {
        "CITYNAME": "Sudbury",
        "CITYCODE": "YSB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YSB",
        "AIRPORTNAME": "Sudbury",
    },
    {
        "CITYNAME": "Squamish",
        "CITYCODE": "YSE",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YSE",
        "AIRPORTNAME": "Squamish",
    },
    {
        "CITYNAME": "Stony Rapids",
        "CITYCODE": "YSF",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YSF",
        "AIRPORTNAME": "Stony Rapids",
    },
    {
        "CITYNAME": "Snowdrift",
        "CITYCODE": "YSG",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YSG",
        "AIRPORTNAME": "Snowdrift",
    },
    {
        "CITYNAME": "Saint John",
        "CITYCODE": "YSJ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YSJ",
        "AIRPORTNAME": "Saint John",
    },
    {
        "CITYNAME": "Sanikiluaq",
        "CITYCODE": "YSK",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YSK",
        "AIRPORTNAME": "Sanikiluaq",
    },
    {
        "CITYNAME": "Fort Smith",
        "CITYCODE": "YSM",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YSM",
        "AIRPORTNAME": "Fort Smith",
    },
    {
        "CITYNAME": "Salmon Arm",
        "CITYCODE": "YSN",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YSN",
        "AIRPORTNAME": "Salmon Arm",
    },
    {
        "CITYNAME": "Postville",
        "CITYCODE": "YSO",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YSO",
        "AIRPORTNAME": "Postville",
    },
    {
        "CITYNAME": "Marathon",
        "CITYCODE": "YSP",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YSP",
        "AIRPORTNAME": "Marathon",
    },
    {
        "CITYNAME": "Nanisivik",
        "CITYCODE": "YSR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YSR",
        "AIRPORTNAME": "Nanisivik",
    },
    {
        "CITYNAME": "Ste Therese Point",
        "CITYCODE": "YST",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YST",
        "AIRPORTNAME": "Ste Therese Point",
    },
    {
        "CITYNAME": "Sachs Harbour",
        "CITYCODE": "YSY",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YSY",
        "AIRPORTNAME": "Sachs Harbour",
    },
    {
        "CITYNAME": "Pembroke",
        "CITYCODE": "YTA",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YTA",
        "AIRPORTNAME": "Pembroke And Area",
    },
    {
        "CITYNAME": "Hartley Bay",
        "CITYCODE": "YTB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YTB",
        "AIRPORTNAME": "Hartley Bay",
    },
    {
        "CITYNAME": "Sturdee",
        "CITYCODE": "YTC",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YTC",
        "AIRPORTNAME": "Sturdee",
    },
    {
        "CITYNAME": "Cape Dorset",
        "CITYCODE": "YTE",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YTE",
        "AIRPORTNAME": "Cape Dorset",
    },
    {
        "CITYNAME": "Alma",
        "CITYCODE": "YTF",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YTF",
        "AIRPORTNAME": "Alma",
    },
    {
        "CITYNAME": "Thompson",
        "CITYCODE": "YTH",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YTH",
        "AIRPORTNAME": "Thompson",
    },
    {
        "CITYNAME": "Terrace Bay",
        "CITYCODE": "YTJ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YTJ",
        "AIRPORTNAME": "Terrace Bay",
    },
    {
        "CITYNAME": "Big Trout",
        "CITYCODE": "YTL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YTL",
        "AIRPORTNAME": "Big Trout",
    },
    {
        "CITYNAME": "Mont Tremblant",
        "CITYCODE": "YTM",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YTM",
        "AIRPORTNAME": "Quebec Mont Tremblant Intl Airport",
    },
    {
        "CITYNAME": "Toronto",
        "CITYCODE": "YTO",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YBZ",
        "AIRPORTNAME": "Union Station Airport",
    },
    {
        "CITYNAME": "Toronto",
        "CITYCODE": "YTO",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YKZ",
        "AIRPORTNAME": "Buttonville",
    },
    {
        "CITYNAME": "Toronto",
        "CITYCODE": "YTO",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YTZ",
        "AIRPORTNAME": "Toronto Island",
    },
    {
        "CITYNAME": "Toronto",
        "CITYCODE": "YTO",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YYZ",
        "AIRPORTNAME": "Pearson Int'l",
    },
    {
        "CITYNAME": "Tasiujuaq",
        "CITYCODE": "YTQ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YTQ",
        "AIRPORTNAME": "Tasiujuaq",
    },
    {
        "CITYNAME": "Trenton",
        "CITYCODE": "YTR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YTR",
        "AIRPORTNAME": "Trenton",
    },
    {
        "CITYNAME": "Timmins",
        "CITYCODE": "YTS",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YTS",
        "AIRPORTNAME": "Timmins",
    },
    {
        "CITYNAME": "Tisdale",
        "CITYCODE": "YTT",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YTT",
        "AIRPORTNAME": "Tisdale",
    },
    {
        "CITYNAME": "Telegraph Creek",
        "CITYCODE": "YTX",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YTX",
        "AIRPORTNAME": "Telegraph Creek",
    },
    {
        "CITYNAME": "Tuktoyaktuk",
        "CITYCODE": "YUB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YUB",
        "AIRPORTNAME": "Tuktoyaktuk",
    },
    {
        "CITYNAME": "Umiujaq",
        "CITYCODE": "YUD",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YUD",
        "AIRPORTNAME": "Umiujaq",
    },
    {
        "CITYNAME": "Pelly Bay",
        "CITYCODE": "YUF",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YBB",
        "AIRPORTNAME": "Townsite",
    },
    {
        "CITYNAME": "Pelly Bay",
        "CITYCODE": "YUF",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YUF",
        "AIRPORTNAME": "Dewline Site",
    },
    {
        "CITYNAME": "Yuma",
        "CITYCODE": "YUM",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "YUM",
        "AIRPORTNAME": "Yuma Int'l",
    },
    {
        "CITYNAME": "Repulse Bay",
        "CITYCODE": "YUT",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YUT",
        "AIRPORTNAME": "Repulse Bay",
    },
    {
        "CITYNAME": "Hall Beach",
        "CITYCODE": "YUX",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YUX",
        "AIRPORTNAME": "Hall Beach",
    },
    {
        "CITYNAME": "Rouyn",
        "CITYCODE": "YUY",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YUY",
        "AIRPORTNAME": "Rouyn",
    },
    {
        "CITYNAME": "Moroni",
        "CITYCODE": "YVA",
        "COUNTRYCODE": "KM",
        "COUNTRYNAME": "Comoros",
        "AIRPORTCODE": "HAH",
        "AIRPORTNAME": "Hahaya Aeroport Internation",
    },
    {
        "CITYNAME": "Moroni",
        "CITYCODE": "YVA",
        "COUNTRYCODE": "KM",
        "COUNTRYNAME": "Comoros",
        "AIRPORTCODE": "YVA",
        "AIRPORTNAME": "Iconi",
    },
    {
        "CITYNAME": "Bonaventure",
        "CITYCODE": "YVB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YVB",
        "AIRPORTNAME": "Bonaventure",
    },
    {
        "CITYNAME": "La Ronge",
        "CITYCODE": "YVC",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YVC",
        "AIRPORTNAME": "La Ronge",
    },
    {
        "CITYNAME": "Vernon",
        "CITYCODE": "YVE",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YVE",
        "AIRPORTNAME": "Vernon",
    },
    {
        "CITYNAME": "Broughton Island",
        "CITYCODE": "YVM",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YVM",
        "AIRPORTNAME": "Broughton Island",
    },
    {
        "CITYNAME": "Val D'Or",
        "CITYCODE": "YVO",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YVO",
        "AIRPORTNAME": "Val D'Or",
    },
    {
        "CITYNAME": "Kuujjuaq",
        "CITYCODE": "YVP",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YVP",
        "AIRPORTNAME": "Kuujjuaq",
    },
    {
        "CITYNAME": "Norman Wells",
        "CITYCODE": "YVQ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YVQ",
        "AIRPORTNAME": "Norman Wells",
    },
    {
        "CITYNAME": "Vancouver",
        "CITYCODE": "YVR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "CXH",
        "AIRPORTNAME": "Coal Harbour",
    },
    {
        "CITYNAME": "Vancouver",
        "CITYCODE": "YVR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YDT",
        "AIRPORTNAME": "Boundary Bay",
    },
    {
        "CITYNAME": "Vancouver",
        "CITYCODE": "YVR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YVR",
        "AIRPORTNAME": "Vancouver Int'l",
    },
    {
        "CITYNAME": "Buffalo Narrows",
        "CITYCODE": "YVT",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YVT",
        "AIRPORTNAME": "Buffalo Narrows",
    },
    {
        "CITYNAME": "Deer Lake",
        "CITYCODE": "YVZ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YVZ",
        "AIRPORTNAME": "Deer Lake",
    },
    {
        "CITYNAME": "Kangiqsujuaq",
        "CITYCODE": "YWB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YWB",
        "AIRPORTNAME": "Kangiqsujuaq",
    },
    {
        "CITYNAME": "Winnipeg",
        "CITYCODE": "YWG",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YWG",
        "AIRPORTNAME": "Winnipeg",
    },
    {
        "CITYNAME": "Fort Franklin",
        "CITYCODE": "YWJ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YWJ",
        "AIRPORTNAME": "Fort Franklin",
    },
    {
        "CITYNAME": "Wabush",
        "CITYCODE": "YWK",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YWK",
        "AIRPORTNAME": "Wabush",
    },
    {
        "CITYNAME": "Williams Lake",
        "CITYCODE": "YWL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YWL",
        "AIRPORTNAME": "Williams Lake",
    },
    {
        "CITYNAME": "Webequie",
        "CITYCODE": "YWP",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YWP",
        "AIRPORTNAME": "Webequie",
    },
    {
        "CITYNAME": "Whistler",
        "CITYCODE": "YWS",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YWS",
        "AIRPORTNAME": "Whistler",
    },
    {
        "CITYNAME": "Wrigley",
        "CITYCODE": "YWY",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YWY",
        "AIRPORTNAME": "Wrigley",
    },
    {
        "CITYNAME": "Cranbrook",
        "CITYCODE": "YXC",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YXC",
        "AIRPORTNAME": "Cranbrook",
    },
    {
        "CITYNAME": "Saskatoon",
        "CITYCODE": "YXE",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YXE",
        "AIRPORTNAME": "Saskatoon",
    },
    {
        "CITYNAME": "Medicine Hat",
        "CITYCODE": "YXH",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YXH",
        "AIRPORTNAME": "Medicine Hat",
    },
    {
        "CITYNAME": "Fort St John",
        "CITYCODE": "YXJ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YXJ",
        "AIRPORTNAME": "Fort St John",
    },
    {
        "CITYNAME": "Rimouski",
        "CITYCODE": "YXK",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YXK",
        "AIRPORTNAME": "Rimouski",
    },
    {
        "CITYNAME": "Sioux Lookout",
        "CITYCODE": "YXL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YXL",
        "AIRPORTNAME": "Sioux Lookout",
    },
    {
        "CITYNAME": "Whale Cove",
        "CITYCODE": "YXN",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YXN",
        "AIRPORTNAME": "Whale Cove",
    },
    {
        "CITYNAME": "Pangnirtung",
        "CITYCODE": "YXP",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YXP",
        "AIRPORTNAME": "Pangnirtung",
    },
    {
        "CITYNAME": "Earlton",
        "CITYCODE": "YXR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YXR",
        "AIRPORTNAME": "Earlton",
    },
    {
        "CITYNAME": "Prince George",
        "CITYCODE": "YXS",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YXS",
        "AIRPORTNAME": "Prince George",
    },
    {
        "CITYNAME": "Terrace",
        "CITYCODE": "YXT",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YXT",
        "AIRPORTNAME": "Terrace",
    },
    {
        "CITYNAME": "London",
        "CITYCODE": "YXU",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YXU",
        "AIRPORTNAME": "London",
    },
    {
        "CITYNAME": "Abbotsford",
        "CITYCODE": "YXX",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YXX",
        "AIRPORTNAME": "Abbotsford",
    },
    {
        "CITYNAME": "Whitehorse",
        "CITYCODE": "YXY",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YXY",
        "AIRPORTNAME": "Whitehorse",
    },
    {
        "CITYNAME": "Wawa",
        "CITYCODE": "YXZ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YXZ",
        "AIRPORTNAME": "Wawa",
    },
    {
        "CITYNAME": "North Bay",
        "CITYCODE": "YYB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YYB",
        "AIRPORTNAME": "North Bay",
    },
    {
        "CITYNAME": "Calgary",
        "CITYCODE": "YYC",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YYC",
        "AIRPORTNAME": "Calgary Int'l",
    },
    {
        "CITYNAME": "Smithers",
        "CITYCODE": "YYD",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YYD",
        "AIRPORTNAME": "Smithers",
    },
    {
        "CITYNAME": "Fort Nelson",
        "CITYCODE": "YYE",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YYE",
        "AIRPORTNAME": "Fort Nelson",
    },
    {
        "CITYNAME": "Penticton",
        "CITYCODE": "YYF",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YYF",
        "AIRPORTNAME": "Penticton Regional",
    },
    {
        "CITYNAME": "Charlottetown",
        "CITYCODE": "YYG",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YYG",
        "AIRPORTNAME": "Charlottetown",
    },
    {
        "CITYNAME": "Taloyoak",
        "CITYCODE": "YYH",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YYH",
        "AIRPORTNAME": "Taloyoak",
    },
    {
        "CITYNAME": "Victoria",
        "CITYCODE": "YYJ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YWH",
        "AIRPORTNAME": "Inner Harbor",
    },
    {
        "CITYNAME": "Victoria",
        "CITYCODE": "YYJ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YYJ",
        "AIRPORTNAME": "Victoria Int'l",
    },
    {
        "CITYNAME": "Lynn Lake",
        "CITYCODE": "YYL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YYL",
        "AIRPORTNAME": "Lynn Lake",
    },
    {
        "CITYNAME": "Swift Current",
        "CITYCODE": "YYN",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YYN",
        "AIRPORTNAME": "Swift Current",
    },
    {
        "CITYNAME": "Churchill",
        "CITYCODE": "YYQ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YYQ",
        "AIRPORTNAME": "Churchill",
    },
    {
        "CITYNAME": "Goose Bay",
        "CITYCODE": "YYR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YYR",
        "AIRPORTNAME": "Goose Bay",
    },
    {
        "CITYNAME": "St Johns",
        "CITYCODE": "YYT",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YYT",
        "AIRPORTNAME": "St Johns",
    },
    {
        "CITYNAME": "Kapuskasing",
        "CITYCODE": "YYU",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YYU",
        "AIRPORTNAME": "Kapuskasing",
    },
    {
        "CITYNAME": "Mont Joli",
        "CITYCODE": "YYY",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YYY",
        "AIRPORTNAME": "Mont Joli",
    },
    {
        "CITYNAME": "Gore Bay",
        "CITYCODE": "YZE",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YZE",
        "AIRPORTNAME": "Gore Bay",
    },
    {
        "CITYNAME": "Yellowknife",
        "CITYCODE": "YZF",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YZF",
        "AIRPORTNAME": "Yellowknife",
    },
    {
        "CITYNAME": "Salluit",
        "CITYCODE": "YZG",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YZG",
        "AIRPORTNAME": "Salluit",
    },
    {
        "CITYNAME": "Sandspit",
        "CITYCODE": "YZP",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YZP",
        "AIRPORTNAME": "Sandspit",
    },
    {
        "CITYNAME": "Sarnia",
        "CITYCODE": "YZR",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YZR",
        "AIRPORTNAME": "Sarnia",
    },
    {
        "CITYNAME": "Coral Harbour",
        "CITYCODE": "YZS",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YZS",
        "AIRPORTNAME": "Coral Harbour",
    },
    {
        "CITYNAME": "Port Hardy",
        "CITYCODE": "YZT",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YZT",
        "AIRPORTNAME": "Port Hardy",
    },
    {
        "CITYNAME": "Sept Iles",
        "CITYCODE": "YZV",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "YZV",
        "AIRPORTNAME": "Sept Iles",
    },
    {
        "CITYNAME": "Zadar",
        "CITYCODE": "ZAD",
        "COUNTRYCODE": "HR",
        "COUNTRYNAME": "Croatia",
        "AIRPORTCODE": "ZAD",
        "AIRPORTNAME": "Zadar",
    },
    {
        "CITYNAME": "Zagreb",
        "CITYCODE": "ZAG",
        "COUNTRYCODE": "HR",
        "COUNTRYNAME": "Croatia",
        "AIRPORTCODE": "ZAG",
        "AIRPORTNAME": "Pleso",
    },
    {
        "CITYNAME": "Zahedan",
        "CITYCODE": "ZAH",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "ZAH",
        "AIRPORTNAME": "Zahedan",
    },
    {
        "CITYNAME": "Zaranj",
        "CITYCODE": "ZAJ",
        "COUNTRYCODE": "AF",
        "COUNTRYNAME": "Afghanistan",
        "AIRPORTCODE": "ZAJ",
        "AIRPORTNAME": "Zaranj",
    },
    {
        "CITYNAME": "Valdivia",
        "CITYCODE": "ZAL",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "ZAL",
        "AIRPORTNAME": "Pichoy",
    },
    {
        "CITYNAME": "Zamboanga",
        "CITYCODE": "ZAM",
        "COUNTRYCODE": "PH",
        "COUNTRYNAME": "Philippines",
        "AIRPORTCODE": "ZAM",
        "AIRPORTNAME": "Zamboanga",
    },
    {
        "CITYNAME": "Nurnberg",
        "CITYCODE": "ZAQ",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "ZAQ",
        "AIRPORTNAME": "Nuremberg Railway Station",
    },
    {
        "CITYNAME": "Zaria",
        "CITYCODE": "ZAR",
        "COUNTRYCODE": "NG",
        "COUNTRYNAME": "Nigeria",
        "AIRPORTCODE": "ZAR",
        "AIRPORTNAME": "Zaria",
    },
    {
        "CITYNAME": "Zaragoza",
        "CITYCODE": "ZAZ",
        "COUNTRYCODE": "ES",
        "COUNTRYNAME": "Spain",
        "AIRPORTCODE": "ZAZ",
        "AIRPORTNAME": "Zaragoza",
    },
    {
        "CITYNAME": "Bathurst",
        "CITYCODE": "ZBF",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZBF",
        "AIRPORTNAME": "Bathurst",
    },
    {
        "CITYNAME": "Chah Bahar",
        "CITYCODE": "ZBR",
        "COUNTRYCODE": "IR",
        "COUNTRYNAME": "Iran",
        "AIRPORTCODE": "ZBR",
        "AIRPORTNAME": "Chah Bahar",
    },
    {
        "CITYNAME": "Sayaboury",
        "CITYCODE": "ZBY",
        "COUNTRYCODE": "LA",
        "COUNTRYNAME": "Lao People's Democratic Republic",
        "AIRPORTCODE": "ZBY",
        "AIRPORTNAME": "Sayaboury",
    },
    {
        "CITYNAME": "Zacatecas",
        "CITYCODE": "ZCL",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "ZCL",
        "AIRPORTNAME": "La Calera",
    },
    {
        "CITYNAME": "Temuco",
        "CITYCODE": "ZCO",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "ZCO",
        "AIRPORTNAME": "Temuco",
    },
    {
        "CITYNAME": "Basel",
        "CITYCODE": "ZDH",
        "COUNTRYCODE": "CH",
        "COUNTRYNAME": "Switzerland",
        "AIRPORTCODE": "ZDH",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Berne",
        "CITYCODE": "ZDJ",
        "COUNTRYCODE": "CH",
        "COUNTRYNAME": "Switzerland",
        "AIRPORTCODE": "ZDJ",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Brno",
        "CITYCODE": "ZDN",
        "COUNTRYCODE": "CZ",
        "COUNTRYNAME": "Czech Republic",
        "AIRPORTCODE": "ZDN",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Zero",
        "CITYCODE": "ZER",
        "COUNTRYCODE": "IN",
        "COUNTRYNAME": "India",
        "AIRPORTCODE": "ZER",
        "AIRPORTNAME": "Zero Airport",
    },
    {
        "CITYNAME": "Fond Du Lac",
        "CITYCODE": "ZFD",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZFD",
        "AIRPORTNAME": "Fond Du Lac",
    },
    {
        "CITYNAME": "Rennes",
        "CITYCODE": "ZFJ",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "ZFJ",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Fort Mcpherson",
        "CITYCODE": "ZFM",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZFM",
        "AIRPORTNAME": "Fort Mcpherson",
    },
    {
        "CITYNAME": "Fort Norman",
        "CITYCODE": "ZFN",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZFN",
        "AIRPORTNAME": "Fort Norman",
    },
    {
        "CITYNAME": "Bordeaux",
        "CITYCODE": "ZFQ",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "ZFQ",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Philadelphia",
        "CITYCODE": "ZFV",
        "COUNTRYCODE": "US",
        "COUNTRYNAME": "United States",
        "AIRPORTCODE": "ZFV",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Zagreb",
        "CITYCODE": "ZGC",
        "COUNTRYCODE": "HR",
        "COUNTRYNAME": "Croatia",
        "AIRPORTCODE": "ZGC",
        "AIRPORTNAME": "Zagreb Bus Station",
    },
    {
        "CITYNAME": "Glasgow",
        "CITYCODE": "ZGG",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "ZGG",
        "AIRPORTNAME": "Glasgow Central station",
    },
    {
        "CITYNAME": "Gods River",
        "CITYCODE": "ZGI",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZGI",
        "AIRPORTNAME": "Gods River",
    },
    {
        "CITYNAME": "Gutenfuerst",
        "CITYCODE": "ZGN",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "ZGN",
        "AIRPORTNAME": "Zhongshan Ferry Port Airport",
    },
    {
        "CITYNAME": "Gethsemani",
        "CITYCODE": "ZGS",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZGS",
        "AIRPORTNAME": "Gethsemani",
    },
    {
        "CITYNAME": "Zhanjiang",
        "CITYCODE": "ZHA",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "ZHA",
        "AIRPORTNAME": "Zhanjiang",
    },
    {
        "CITYNAME": "Geneva Cornavin",
        "CITYCODE": "ZHT",
        "COUNTRYCODE": "CH",
        "COUNTRYNAME": "Switzerland",
        "AIRPORTCODE": "ZHT",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Trento",
        "CITYCODE": "ZIA",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "ZIA",
        "AIRPORTNAME": "Zhukovsky International Airport",
    },
    {
        "CITYNAME": "Ziguinchor",
        "CITYCODE": "ZIG",
        "COUNTRYCODE": "SN",
        "COUNTRYNAME": "Senegal",
        "AIRPORTCODE": "ZIG",
        "AIRPORTNAME": "Ziguinchor",
    },
    {
        "CITYNAME": "Ixtapa",
        "CITYCODE": "ZIH",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "ZIH",
        "AIRPORTNAME": "Ixtapa Zihuatanej Int'l",
    },
    {
        "CITYNAME": "Interlaken Ost",
        "CITYCODE": "ZIN",
        "COUNTRYCODE": "CH",
        "COUNTRYNAME": "Switzerland",
        "AIRPORTCODE": "ZIN",
        "AIRPORTNAME": "Interlaken Airport",
    },
    {
        "CITYNAME": "Lianhuashan",
        "CITYCODE": "ZIY",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "ZIY",
        "AIRPORTNAME": "Lianhuashan Port Airport",
    },
    {
        "CITYNAME": "Jenpeg",
        "CITYCODE": "ZJG",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZJG",
        "AIRPORTNAME": "Jenpeg",
    },
    {
        "CITYNAME": "Kasaba Bay",
        "CITYCODE": "ZKB",
        "COUNTRYCODE": "ZM",
        "COUNTRYNAME": "Zambia",
        "AIRPORTCODE": "ZKB",
        "AIRPORTNAME": "Kasaba Bay",
    },
    {
        "CITYNAME": "Kaschechewan",
        "CITYCODE": "ZKE",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZKE",
        "AIRPORTNAME": "Kaschechewan",
    },
    {
        "CITYNAME": "Kegaska",
        "CITYCODE": "ZKG",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZKG",
        "AIRPORTNAME": "Kegaska",
    },
    {
        "CITYNAME": "Sette Cama",
        "CITYCODE": "ZKM",
        "COUNTRYCODE": "GA",
        "COUNTRYNAME": "Gabon",
        "AIRPORTCODE": "ZKM",
        "AIRPORTNAME": "Sette Cama",
    },
    {
        "CITYNAME": "Manzanillo",
        "CITYCODE": "ZLO",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "ZLO",
        "AIRPORTNAME": "Manzanillo",
    },
    {
        "CITYNAME": "La Tabatiere",
        "CITYCODE": "ZLT",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZLT",
        "AIRPORTNAME": "La Tabatiere",
    },
    {
        "CITYNAME": "Leeds",
        "CITYCODE": "ZLZ",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "ZLZ",
        "AIRPORTNAME": "Leeds Bus Station",
    },
    {
        "CITYNAME": "Hamburg Hbf",
        "CITYCODE": "ZMB",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "ZMB",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Zamora",
        "CITYCODE": "ZMM",
        "COUNTRYCODE": "MX",
        "COUNTRYNAME": "Mexico",
        "AIRPORTCODE": "ZMM",
        "AIRPORTNAME": "Zamora",
    },
    {
        "CITYNAME": "Florence",
        "CITYCODE": "ZMS",
        "COUNTRYCODE": "IT",
        "COUNTRYNAME": "Italy",
        "AIRPORTCODE": "ZMS",
        "AIRPORTNAME": "S.M. Novella Rail.Svc Airport",
    },
    {
        "CITYNAME": "Masset",
        "CITYCODE": "ZMT",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZMT",
        "AIRPORTNAME": "Masset",
    },
    {
        "CITYNAME": "Zinder",
        "CITYCODE": "ZND",
        "COUNTRYCODE": "NE",
        "COUNTRYNAME": "Niger",
        "AIRPORTCODE": "ZND",
        "AIRPORTNAME": "Zinder",
    },
    {
        "CITYNAME": "Newman",
        "CITYCODE": "ZNE",
        "COUNTRYCODE": "AU",
        "COUNTRYNAME": "Australia",
        "AIRPORTCODE": "ZNE",
        "AIRPORTNAME": "Newman",
    },
    {
        "CITYNAME": "Zanzibar",
        "CITYCODE": "ZNZ",
        "COUNTRYCODE": "TZ",
        "COUNTRYNAME": "Tanzania",
        "AIRPORTCODE": "ZNZ",
        "AIRPORTNAME": "Kisauni",
    },
    {
        "CITYNAME": "Osorno",
        "CITYCODE": "ZOS",
        "COUNTRYCODE": "CL",
        "COUNTRYNAME": "Chile",
        "AIRPORTCODE": "ZOS",
        "AIRPORTNAME": "Canal Balo",
    },
    {
        "CITYNAME": "Sachigo Lake",
        "CITYCODE": "ZPB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZPB",
        "AIRPORTNAME": "Sachigo Lake",
    },
    {
        "CITYNAME": "Queenstown",
        "CITYCODE": "ZQN",
        "COUNTRYCODE": "NZ",
        "COUNTRYNAME": "New Zealand",
        "AIRPORTCODE": "ZQN",
        "AIRPORTNAME": "Frnkton",
    },
    {
        "CITYNAME": "Zweibruecken",
        "CITYCODE": "ZQW",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "ZQW",
        "AIRPORTNAME": "Zweibrucken Airport",
    },
    {
        "CITYNAME": "Zurich",
        "CITYCODE": "ZRH",
        "COUNTRYCODE": "CH",
        "COUNTRYNAME": "Switzerland",
        "AIRPORTCODE": "ZLP",
        "AIRPORTNAME": "Zurich Airport",
    },
    {
        "CITYNAME": "Zurich",
        "CITYCODE": "ZRH",
        "COUNTRYCODE": "CH",
        "COUNTRYNAME": "Switzerland",
        "AIRPORTCODE": "ZRH",
        "AIRPORTNAME": "Zurich",
    },
    {
        "CITYNAME": "Serui",
        "CITYCODE": "ZRI",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "ZRI",
        "AIRPORTNAME": "Serui",
    },
    {
        "CITYNAME": "Round Lake",
        "CITYCODE": "ZRJ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZRJ",
        "AIRPORTNAME": "Round Lake",
    },
    {
        "CITYNAME": "Sarmi",
        "CITYCODE": "ZRM",
        "COUNTRYCODE": "\"ID\"",
        "COUNTRYNAME": "Indonesia",
        "AIRPORTCODE": "ZRM",
        "AIRPORTNAME": "Sarmi",
    },
    {
        "CITYNAME": "San Salvador",
        "CITYCODE": "ZSA",
        "COUNTRYCODE": "BS",
        "COUNTRYNAME": "Bahamas",
        "AIRPORTCODE": "ZSA",
        "AIRPORTNAME": "San Salvador",
    },
    {
        "CITYNAME": "Salzburg",
        "CITYCODE": "ZSB",
        "COUNTRYCODE": "AT",
        "COUNTRYNAME": "Austria",
        "AIRPORTCODE": "ZSB",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "St Pierre De La Reunion",
        "CITYCODE": "ZSE",
        "COUNTRYCODE": "RE",
        "COUNTRYNAME": "Reunion",
        "AIRPORTCODE": "ZSE",
        "AIRPORTNAME": "St Pierre De La Reunion",
    },
    {
        "CITYNAME": "Sandy Lake",
        "CITYCODE": "ZSJ",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZSJ",
        "AIRPORTNAME": "Sandy Lake",
    },
    {
        "CITYNAME": "Sassandra",
        "CITYCODE": "ZSS",
        "COUNTRYCODE": "CI",
        "COUNTRYNAME": "Ivory Coast",
        "AIRPORTCODE": "ZSS",
        "AIRPORTNAME": "Sassandra",
    },
    {
        "CITYNAME": "Tete A La Baleine",
        "CITYCODE": "ZTB",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZTB",
        "AIRPORTNAME": "Tete A La Baleine",
    },
    {
        "CITYNAME": "Zakinthos Island",
        "CITYCODE": "ZTH",
        "COUNTRYCODE": "GR",
        "COUNTRYNAME": "Greece",
        "AIRPORTCODE": "ZTH",
        "AIRPORTNAME": "Zakinthos Is",
    },
    {
        "CITYNAME": "Humen",
        "CITYCODE": "ZTI",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "ZTI",
        "AIRPORTNAME": "Humen Port Airport",
    },
    {
        "CITYNAME": "Shamattawa",
        "CITYCODE": "ZTM",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZTM",
        "AIRPORTNAME": "Shamattawa",
    },
    {
        "CITYNAME": "Zhuhai",
        "CITYCODE": "ZUH",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "ZUH",
        "AIRPORTNAME": "Zhuhai Jinwan Airport",
    },
    {
        "CITYNAME": "GUANGDONG",
        "CITYCODE": "ZUI",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "ZUI",
        "AIRPORTNAME": "GUANGDONG",
    },
    {
        "CITYNAME": "Churchill Falls",
        "CITYCODE": "ZUM",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZUM",
        "AIRPORTNAME": "Churchill Falls",
    },
    {
        "CITYNAME": "Miandrivazo",
        "CITYCODE": "ZVA",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "ZVA",
        "AIRPORTNAME": "Miandrivazo",
    },
    {
        "CITYNAME": "Veldoven",
        "CITYCODE": "ZVH",
        "COUNTRYCODE": "NL",
        "COUNTRYNAME": "Netherlands",
        "AIRPORTCODE": "ZVH",
        "AIRPORTNAME": "Al Ain Bus Station",
    },
    {
        "CITYNAME": "Savannakhet",
        "CITYCODE": "ZVK",
        "COUNTRYCODE": "LA",
        "COUNTRYNAME": "Lao People's Democratic Republic",
        "AIRPORTCODE": "ZVK",
        "AIRPORTNAME": "Savannakhet",
    },
    {
        "CITYNAME": "Hanover Hbf",
        "CITYCODE": "ZVR",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "ZVR",
        "AIRPORTNAME": "Hannover Hauptbahnhof",
    },
    {
        "CITYNAME": "Andapa",
        "CITYCODE": "ZWA",
        "COUNTRYCODE": "MG",
        "COUNTRYNAME": "Madagascar",
        "AIRPORTCODE": "ZWA",
        "AIRPORTNAME": "Andapa",
    },
    {
        "CITYNAME": "Antwerp",
        "CITYCODE": "ZWE",
        "COUNTRYCODE": "BE",
        "COUNTRYNAME": "Belgium",
        "AIRPORTCODE": "ZWE",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Wollaston Lake",
        "CITYCODE": "ZWL",
        "COUNTRYCODE": "CA",
        "COUNTRYNAME": "Canada",
        "AIRPORTCODE": "ZWL",
        "AIRPORTNAME": "Wollaston Lake",
    },
    {
        "CITYNAME": "Stuttgart",
        "CITYCODE": "ZWS",
        "COUNTRYCODE": "DE",
        "COUNTRYNAME": "Germany",
        "AIRPORTCODE": "ZWS",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Edinburgh",
        "CITYCODE": "ZXE",
        "COUNTRYCODE": "GB",
        "COUNTRYNAME": "United Kingdom",
        "AIRPORTCODE": "ZXE",
        "AIRPORTNAME": "Edinburgh Waverley station",
    },
    {
        "CITYNAME": "Amsterdam",
        "CITYCODE": "ZYA",
        "COUNTRYCODE": "NL",
        "COUNTRYNAME": "Netherlands",
        "AIRPORTCODE": "ZYA",
        "AIRPORTNAME": "Amsterdam Central Station",
    },
    {
        "CITYNAME": "Shekou",
        "CITYCODE": "ZYK",
        "COUNTRYCODE": "CN",
        "COUNTRYNAME": "China",
        "AIRPORTCODE": "ZYK",
        "AIRPORTNAME": "Shekou Port Airport",
    },
    {
        "CITYNAME": "Sylhet",
        "CITYCODE": "ZYL",
        "COUNTRYCODE": "BD",
        "COUNTRYNAME": "Bangladesh",
        "AIRPORTCODE": "ZYL",
        "AIRPORTNAME": "Sylhet Civil",
    },
    {
        "CITYNAME": "Nimes",
        "CITYCODE": "ZYN",
        "COUNTRYCODE": "FR",
        "COUNTRYNAME": "France",
        "AIRPORTCODE": "ZYN",
        "AIRPORTNAME": "Nimes Railway Station",
    },
    {
        "CITYNAME": "Maastricht",
        "CITYCODE": "ZYT",
        "COUNTRYCODE": "NL",
        "COUNTRYNAME": "Netherlands",
        "AIRPORTCODE": "ZYT",
        "AIRPORTNAME": "",
    },
    {
        "CITYNAME": "Mzuzu",
        "CITYCODE": "ZZU",
        "COUNTRYCODE": "MW",
        "COUNTRYNAME": "Malawi",
        "AIRPORTCODE": "ZZU",
        "AIRPORTNAME": "Mzuzu",
    }
  ];