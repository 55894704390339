import React, { useEffect, useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js"; // Make sure to install this

const PhonePePayment = () => {
  const [amount, setAmount] = useState(0);
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [transactionId, setTransactionId] = useState(null); // Store transaction ID

  const handlePayment = async () => {
    setLoading(true);
    setError(null);

    // Replace these with your actual values
    const merchantId = "LELOTRIPUAT";
    const saltKey = "982bd35b-ea55-4df2-a5af-3d9489d83f4b";
    const saltIndex = 1;
    const merchantTransactionId =
      "REF_PREFIX" + new Date().toISOString().replace(/[-:.]/g, ""); // Unique transaction ID

    const amountInPaise = amount * 100;

    const data = {
      merchantId,
      merchantTransactionId,
      merchantUserId: "MUID12875",
      amount: amountInPaise,
      redirectUrl: "http://localhost:3000/check", // Replace with actual route
      redirectMode: "REDIRECT",
      callbackUrl: `http://localhost:3000/phonepe/callback?transactionId=${merchantTransactionId}`, // Set callback URL
      callbackMode: "POST",
      mobileNumber: phone,
      paymentInstrument: {
        type: "PAY_PAGE",
      },
    };

    const encodedData = btoa(JSON.stringify(data, null, 2));
    const stringToHash = `${encodedData}/pg/v1/pay${saltKey}`;
    const sha256Hash = CryptoJS.SHA256(stringToHash).toString();
    const finalXHeader = `${sha256Hash}###${saltIndex}`;

    try {
      const response = await axios.post(
        "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay",
        {
          request: encodedData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-VERIFY": finalXHeader,
            accept: "application/json",
          },
        }
      );

      console.log("Payment Response:", response.data);
      // Assuming the response contains the transaction ID
      setTransactionId(response.data.transactionId); // Set the transaction ID from the response
      const datas = response.data.data.instrumentResponse;
      console.log(datas);

      window.location.href = datas.redirectInfo.url; // Redirect to PhonePe for payment
    } catch (err) {
      if (err.response) {
        console.error("Payment Error:", err.response.data);
        if (err.response.status === 401) {
          setError("Unauthorized access. Please check your credentials.");
        } else {
          setError("Payment failed. Please try again.");
        }
      } else {
        console.error("Network or other error:", err.message);
        setError("An error occurred. Please check your network connection.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (!transactionId) return; // If there's no transaction ID, exit early

      try {
        const response = await axios.post("/api/phonepe/callback", {
          transactionId,
        });
        // Handle success case
        window.location.href = "https://lelotrip.com/flight-ticket/SrdvTJ"; // Redirect on success
      } catch (err) {
        console.error(
          "Error checking payment status:",
          err.response?.data || err.message
        );
        setError("Failed to verify payment status. Please try again.");
      }
    };

    checkPaymentStatus(); // Call this function when the transactionId is set
  }, [transactionId]);

  return (
    <div>
      <h2>PhonePe Payment</h2>
      <input
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="Enter amount"
      />
      <input
        type="text"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        placeholder="Enter mobile number"
      />
      <button onClick={handlePayment} disabled={loading}>
        {loading ? "Processing..." : "Pay with PhonePe"}
      </button>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {/* Optionally, you can provide user feedback here */}
    </div>
  );
};

export default PhonePePayment;
