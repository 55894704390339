import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import './Quotes.css'
function QuotesModal({show,handleClose}) {

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add your form submission logic here
    console.log("Form submitted");
  };

  return (
   <div id="inquirymodal" className="inquirymodal">
     <Modal show={show} onHide={handleClose} centered>
      <Modal.Header style={{background:'#002543'}} closeButton>
        <Modal.Title >Quick Inquiry</Modal.Title>
      </Modal.Header>
     <div className="pkgform-wrapper">
      <div className="pkgform-box">
      <Modal.Body>
        <Form
          method="POST"
          action="https://tripoholidays.in/enquiry-contact"
          acceptCharset="UTF-8"
          name="queryform"
          autoComplete="off"
          id="popenquiryco"
        >
          <input
            name="_token"
            type="hidden"
            defaultValue="PmyF8ts6eshYY0QoZBLsasQzzug0LSxJG34HwKys"
          />
          <Form.Group controlId="formName">
            <Form.Control type="text" name="name" placeholder="Name" required />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Control
              type="email"
              name="email"
              placeholder="Email"
              required
            />
          </Form.Group>
          <Form.Group controlId="formPhone">
            <Form.Control
              type="text"
              name="phone"
              placeholder="Phone"
              required
            />
          </Form.Group>
          <Form.Group controlId="formCity">
            <Form.Control type="text" name="city" placeholder="City" required />
          </Form.Group>
          <Form.Group controlId="formTravelDate">
            <Form.Control
              type="text"
              name="traveldate"
              placeholder="Travel Date"
              required
            />
          </Form.Group>
          <Row>
            <Col sm={6}>
              <Form.Group controlId="formAdults">
                <Form.Control as="select" name="adults">
                  <option value="">Adults*</option>
                  {[...Array(10).keys()].map((i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="formChildren">
                <Form.Control as="select" name="children">
                  <option value="">Children (5-12 yr)</option>
                  {[...Array(10).keys()].map((i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="formAdditionalInfo">
            <Form.Control
              as="textarea"
              name="add_info"
              placeholder="Want to customize this package? Tell us more"
            />
          </Form.Group>
          <Row>
            <Col sm={7}>
              <Form.Group controlId="formCaptcha">
                <Form.Control
                  type="text"
                  name="captcha"
                  placeholder="Enter Code"
                  maxLength={4}
                  required
                />
              </Form.Group>
            </Col>
            <Col sm={5}>
              <img
                src="https://tripoholidays.in/sicaptcha?code=5057"
                className="img-responsive"
                alt="Captcha"
                width={65}
                height={25}
              />
              <input type="hidden" name="code" defaultValue={5057} />
            </Col>
          </Row>
          <input
            type="hidden"
            id="mpackage_id"
            name="package_id"
            defaultValue={56}
          />
          <Button variant="primary" type="submit" className="mt-3">
            Submit
          </Button>
        </Form>
      </Modal.Body>
      </div>
     </div>
    </Modal>
   </div>
  );
}

export default QuotesModal;
