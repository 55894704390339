import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const RoundTripSkeleton = () => {
  return (
    <div className="content-main">
      <div className="block_content roundtrip_header">
        <ul>
          <li className="flight_namess">
            <Skeleton />
          </li>
          <li className="date">
            <Skeleton width={50} />
          </li>
          <li className="prev_next_btn">
            <Skeleton width={30} />
            <span />
          
          </li>
        </ul>
        <div className="clearfix" />
      </div>
      <div className="block_content">
        <div className="flight_info">
          <Skeleton count={2} />
          <div className="clearfix" />
        </div>
      </div>
      <div className="round_trip_list">
        <div
          id="divOB2"
          className="Price8083 allshow block-content-2 custom_block_content flight-list-v2 DRTFilter 6E Indigo 0Stops bingo_button_4"
        >
          <div className="box-result custom_box_result active">
            <div className="obflight_namess">
              <Skeleton width={110} />
            </div>
            <ul className="list-search-resultss result_list">
              <li>
                <Skeleton width={40} height={40} />
              </li>
              <li className="price priced">
                <span className="airlineprice">
                  <span className="mainprice">
                    <Skeleton width={70} height={30} />
                  </span>
                </span>
              </li>
              <li className="round_check">
                <Skeleton width={20} height={20} />
              </li>
              <li className="pad_left10">
                <span className="date departdate">
                  <Skeleton width={50} />
                </span>
                <Skeleton width={50} />
              </li>
              <li>
                <span className="">
                  <Skeleton width={80} />
                  {/* <span>
                    <div className="cus_tooltip">
                      <Skeleton width={30} />
                    </div>
                  </span> */}
                </span>
              </li>
              <li className="pad_left10">
                <span className="date departdate">
                  <Skeleton width={50} />
                </span>
                <Skeleton width={50} />
              </li>
            </ul>
            <div className="clearfix" />
            <div className="flight_details">
              <Skeleton/>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </div>
      <div className="round_trip_list">
        <div
          id="divOB2"
          className="Price8083 allshow block-content-2 custom_block_content flight-list-v2 DRTFilter 6E Indigo 0Stops bingo_button_4"
        >
          <div className="box-result custom_box_result active">
            <div className="obflight_namess">
              <Skeleton width={110} />
            </div>
            <ul className="list-search-resultss result_list">
              <li>
                <Skeleton width={40} height={40} />
              </li>
              <li className="price priced">
                <span className="airlineprice">
                  <span className="mainprice">
                    <Skeleton width={70} height={30} />
                  </span>
                </span>
              </li>
              <li className="round_check">
                <Skeleton width={20} height={20} />
              </li>
              <li className="pad_left10">
                <span className="date departdate">
                  <Skeleton width={50} />
                </span>
                <Skeleton width={50} />
              </li>
              <li>
                <span className="">
                  <Skeleton width={80} />
                  {/* <span>
                    <div className="cus_tooltip">
                      <Skeleton width={30} />
                    </div>
                  </span> */}
                </span>
              </li>
              <li className="pad_left10">
                <span className="date departdate">
                  <Skeleton width={50} />
                </span>
                <Skeleton width={50} />
              </li>
            </ul>
            <div className="clearfix" />
            <div className="flight_details">
              <Skeleton/>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </div>
      <div className="round_trip_list">
        <div
          id="divOB2"
          className="Price8083 allshow block-content-2 custom_block_content flight-list-v2 DRTFilter 6E Indigo 0Stops bingo_button_4"
        >
          <div className="box-result custom_box_result active">
            <div className="obflight_namess">
              <Skeleton width={110} />
            </div>
            <ul className="list-search-resultss result_list">
              <li>
                <Skeleton width={40} height={40} />
              </li>
              <li className="price priced">
                <span className="airlineprice">
                  <span className="mainprice">
                    <Skeleton width={70} height={30} />
                  </span>
                </span>
              </li>
              <li className="round_check">
                <Skeleton width={20} height={20} />
              </li>
              <li className="pad_left10">
                <span className="date departdate">
                  <Skeleton width={50} />
                </span>
                <Skeleton width={50} />
              </li>
              <li>
                <span className="">
                  <Skeleton width={80} />
                  {/* <span>
                    <div className="cus_tooltip">
                      <Skeleton width={30} />
                    </div>
                  </span> */}
                </span>
              </li>
              <li className="pad_left10">
                <span className="date departdate">
                  <Skeleton width={50} />
                </span>
                <Skeleton width={50} />
              </li>
            </ul>
            <div className="clearfix" />
            <div className="flight_details">
              <Skeleton/>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </div>
      <div className="round_trip_list">
        <div
          id="divOB2"
          className="Price8083 allshow block-content-2 custom_block_content flight-list-v2 DRTFilter 6E Indigo 0Stops bingo_button_4"
        >
          <div className="box-result custom_box_result active">
            <div className="obflight_namess">
              <Skeleton width={110} />
            </div>
            <ul className="list-search-resultss result_list">
              <li>
                <Skeleton width={40} height={40} />
              </li>
              <li className="price priced">
                <span className="airlineprice">
                  <span className="mainprice">
                    <Skeleton width={70} height={30} />
                  </span>
                </span>
              </li>
              <li className="round_check">
                <Skeleton width={20} height={20} />
              </li>
              <li className="pad_left10">
                <span className="date departdate">
                  <Skeleton width={50} />
                </span>
                <Skeleton width={50} />
              </li>
              <li>
                <span className="">
                  <Skeleton width={80} />
                  {/* <span>
                    <div className="cus_tooltip">
                      <Skeleton width={30} />
                    </div>
                  </span> */}
                </span>
              </li>
              <li className="pad_left10">
                <span className="date departdate">
                  <Skeleton width={50} />
                </span>
                <Skeleton width={50} />
              </li>
            </ul>
            <div className="clearfix" />
            <div className="flight_details">
              <Skeleton/>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoundTripSkeleton;
