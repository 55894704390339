import React from "react";
import { Card, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FlightListInfo } from "./FlightListInfo";

const FlightListCard = ({
  e,
  handleMoreFare,
  handleClick,
  activeId,
  showModal,
  setShowModal,
  formatTime,
  handlebookmodal,
  handleChnageCurrency,
  fareRules
}) => {
  const index = encodeURIComponent(e.ResultIndex);
  const SrdvIndex = e.SrdvIndex ? encodeURIComponent(e.SrdvIndex) : null;

  // console.log("farerules2", fareRules);
  let url = `/flight-detail/${index}/null`;
  if (SrdvIndex) url += `/${SrdvIndex}`;

  return (
    <Card style={{ marginBottom: "15px"
    // , display: e.SrdvIndex==="SrdvTJ" && "none" 
    }}>
      <Card.Body style={{ padding: "0px" }}>
        <Row style={{ margin: "0px", padding: "0px" }}>
          <div key={e.ResultIndex} style={{ width: "100%" }}>
            <div key={e.ResultIndex} className="flightDetailDiv">
              <Row
                style={{
                  margin: "0px",
                  padding: "0px",
                  marginBottom: 5,
                }}
              >
                <div className="flightDetails">
                  <div className="flightImage">
                    <img
                      src={`/Images/AirlineLogo/${e.Segments[0][0].Airline.AirlineCode}.gif`}
                      alt=""
                    />
                    <div className="flightInerPhotoComp">
                      <p style={{ marginBottom: 0 }}>
                        {e.Segments[0][0].Airline.AirlineName}{" "}
                        {e.Segments[0][0].Airline.FlightNumber}
                      </p>
                      {/* <p className="flightBookingDetailslightNumer">{e.flightNumber}</p> */}
                      <p className="flightBookingDetailPrice">
                        {handleChnageCurrency(e.Fare.PublishedFare)}
                      </p>
                    </div>
                  </div>
                  <div className="flightDetailsDepartureDetails">
                    <div className="departDetails">
                      <p>
                        {new Date(
                          e.Segments[0][0].Origin.DepTime
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })}
                      </p>
                      <p>{e.Segments[0][0].Origin.Airport.CityName}</p>
                    </div>
                    <div className="durationDetails">
                      <p>{e.Segments[0][0].Duration} m</p>
                      <div className="arrow-md-lm flightHorizontal" />
                      <p style={{ textWrap: "nowrap" }}>
                        {e.flightType}
                        {e.Segments[0].length - 1 == 0
                          ? "Non-Stop"
                          : `${e.Segments[0].length - 1} Stops`}
                      </p>
                    </div>
                    <div className="arriveDetails">
                      <p>
                        {new Date(
                          e.Segments[0][e.Segments[0].length - 1].Destination.ArrTime
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })}
                      </p>
                      <p>{e.Segments[0][e.Segments[0].length - 1].Destination.Airport.CityName}</p>
                    </div>
                  </div>
                  <div className="priceDetails">
                    <div style={{ display: "flex" }}>
                      {/* <img
                                  height="15px"
                                  width="15px"
                                  style={{ marginTop: "22px" }}
                                  src={rupee}
                                  alt=""
                              /> */}
                      <p>
                        {/* <FaRupeeSign /> */}INR
                        {handleChnageCurrency(e.Fare.PublishedFare)}
                      </p>
                    </div>
                    <button
                      className="flightBookingMoreFare"
                      onClick={() => handleMoreFare(e.ResultIndex)}
                    >
                      + More Fare
                    </button>
                  </div>
                  <Link
                    to={url}
                    // to={{
                    //   pathname: "/flight-detail",
                    //   state: { flightdetails: e },
                    // }}
                    // onClick={()=>handlebooknow()}
                    className="FlightBookingLinkTag"
                  >
                    {" "}
                    <button className="bookButton">BOOK</button>
                  </Link>
                </div>
              </Row>
              <Row style={{ margin: "0px", padding: "0px" }}>
                {/* <div className="offPriceDiv">
                <p style={{ color: "black" }}>
                  Use Promo code: EASEFLY to get $ 300
                instant discount on
                  this flight
                </p>
              </div> */}
              </Row>
              {/* <FlightDetails style={{marginTop: "5px", marginBottom: "5px"}} {...e} /> */}
              <Row style={{ margin: "0px", padding: "0px" }}>
                <div className="lastDetailDiv">
                  <p
                    onClick={() => {
                      handleClick(e.ResultIndex, e.SrdvIndex);
                      // setCount(count + 1);
                      // setActive(!active);
                    }}
                    style={{ width: "max-content" }}
                  >
                    Flight Detail
                  </p>
                </div>
              </Row>
            </div>
            {activeId === e.ResultIndex && (
              <FlightListInfo
                idx={e.ResultIndex}
                flight={e}
                handleChnageCurrency={handleChnageCurrency}
                fareRules={fareRules}
                SrdvIndex={e.SrdvIndex}
              />
            )}
          </div>
        </Row>
      </Card.Body>

      {showModal === e.ResultIndex && (
        <div
          id="modal-newb2b"
          className="ng-scope"
          style={{ display: "block" }}
        ></div>
      )}

      {showModal === e.ResultIndex && (
        <div
          id="frOption0"
          className="modal-newb2b sidenav morefarediv"
          style={{
            display: "block",
            width: "100%",
          }}
        >
          <div className="modal-content-newb2b">
            <div className="_lopogt_nhy flx">
              <div className="_lopogt">
                <div className="_newrthed">More Fare Options Available</div>
              </div>
              <div className="_clouse" onClick={() => setShowModal(false)}>
                <span className="close-newb2b">✖</span>
              </div>
            </div>

            <div className="_nhty flx">
              <div className="_newdiv-left">
                <div className="_flitdetls">
                  <div className="flx _bgcls acntr">
                    <div className="_airlilogo">
                      <img
                        src="https://flight.easemytrip.com/Content/AirlineLogon/AI.png"
                        alt="airline"
                      />
                    </div>
                    <div className="_detislsnp">
                      <span>
                        {e.Segments[0][0].Origin.Airport.CityName} -{" "}
                        {e.Segments[0][0].Destination.Airport.CityName}
                      </span>{" "}
                      {e.Segments[0][0].Airline.AirlineName}•{" "}
                      {formatTime(e.Segments[0][0].Origin.DepTime)} • Departure
                      at{" "}
                      <strong>
                        {new Date(
                          e.Segments[0][0].Origin.DepTime
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })}
                      </strong>{" "}
                      - Arrival at{" "}
                      <strong>
                        {new Date(
                          e.Segments[0][0].Destination.ArrTime
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })}
                      </strong>
                    </div>
                    <div
                      className="_onwdr marg-left"
                      style={{ display: "none" }}
                    >
                      Onward Trip
                    </div>
                    <div className="_fltDtlsd" style={{ display: "none" }}>
                      Flight Details <i className="dwnarrowfl"></i>
                    </div>
                  </div>
                </div>

                <div className="_bxses">
                  <div className="al_flx_opt">
                    <label className="ng-scope">
                      <div className="pls_bxs f_bx lun frOUT actve">
                        <div className="upr_sctn">
                          <div className="flx_p">
                            <div className="dtl_plus">
                              <div className="optn_head">
                                <span>Regular</span>
                              </div>
                              <div className="fflx acntr">
                                <div className="prce_mn ng-binding">
                                  {/* <FaRupeeSign /> */}
                                  {/* {e.Fare.Currency} */}
                                  INR {handleChnageCurrency(e.Fare.PublishedFare)}
                                </div>
                                <div className="clr"></div>
                              </div>
                              {/* <span className="discbxd ng-binding">
                                Get Rs.1750 OFF with BOOKNOW
                              </span> */}
                            </div>
                            <div className="slct_optn">
                              <label className="conta-radio">
                                <input
                                  type="radio"
                                  className="ddsd"
                                  ng-checked="frid==0"
                                  s="1"
                                  fr="0"
                                  id="fr10"
                                  name="fr1"
                                  ng-click="SelectOptionAndFareUpdateV1('fr',s,fr,frid,segID)"
                                  // checked="checked"
                                />
                                <span className="checkk-radio"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="faclty">
                          <div className="_fcltechdt fflx fjsb">
                            <i>
                              <img
                                src="https://flight.easemytrip.com/Content/img/more-fare/bageicn.svg"
                                alt="baggage"
                              />
                            </i>
                            <div className="_dsrcp_rpt fclm">
                              <div className="_tpbldtle">Baggage</div>
                              <ul>
                                <li className="_cabinfcnl">
                                  <strong>
                                    {e.Segments[0][0].CabinBaggage}
                                  </strong>{" "}
                                  Cabin Baggage
                                </li>
                                <li className="_cabinfcnl">
                                  <strong>{e.Segments[0][0].Baggage}</strong>
                                  <span>Check-in Baggage</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="_fcltechdt fflx fjsb">
                            <i>
                              <img
                                src="https://flight.easemytrip.com/Content/img/more-fare/flxicn.svg"
                                alt="flexibility"
                              />
                            </i>
                            <div className="_dsrcp_rpt fclm">
                              <div className="_tpbldtle">Changeability</div>
                              <ul>
                                <li className="_cabinfcnl">
                                  Cancellation fee starts at{" "}
                                  <strong>INR {handleChnageCurrency("2500")}</strong>
                                </li>
                                <li className="_cabinfcnl">
                                  Date Change fee starts at{" "}
                                  <strong>INR {handleChnageCurrency("2750")}</strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {e.IsRefundable && (
                            <div className="_rfndle _rfndle">Refundable</div>
                          )}
                        </div>
                        <div className="al_flx_opt pd15 slect-bttn-out">
                          <div className="sclt_bt hd2 sclted_bt chu2">
                            <a href="#">Selected</a>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="_btmftrdv">
              <div className="_emtfxder">
                <div className="_btnm flx acntr jsb">
                  <div className="_nhyuop">
                    <div className="_prgty">Grand Total</div>
                    <div className="_finpric f30">
                      <span>
                        INR {handleChnageCurrency(e.Fare.PublishedFare)}
                      </span>
                    </div>
                  </div>
                  <div
                    className="book-bt-nwap marg-left"
                    onClick={() => handlebookmodal(url)}
                  >
                    Book Now
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default FlightListCard;
