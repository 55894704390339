const hotelBookingsData = [
    {
      id: 20,
      bookingDate: '05:07 PM, 26 Dec 2023',
      status: 'Pending',
      hotelName: 'Mahaveer BnB',
      checkIn: '27 Dec 2023',
      checkOut: '28 Dec 2023',
      fare: '3110',
      detailLink: 'https://tripoholidays.com/bookings/hotel/detail/IixDYGAKYAo='
    },
    {
      id: 18,
      bookingDate: '07:35 PM, 10 Oct 2023',
      status: 'Failed',
      hotelName: 'Hotel Gems By OYO Rooms',
      checkIn: '11 Oct 2023',
      checkOut: '12 Oct 2023',
      fare: '1478',
      detailLink: 'https://tripoholidays.com/bookings/hotel/detail/IiwzQGAKYAo='
    },
    {
      id: 18,
      bookingDate: '07:35 PM, 10 Oct 2023',
      status: 'Success',
      hotelName: 'Hotel Gems By OYO Rooms',
      checkIn: '11 Oct 2023',
      checkOut: '12 Oct 2023',
      fare: '1478',
      detailLink: 'https://tripoholidays.com/bookings/hotel/detail/IiwzQGAKYAo='
    },
    {
      id: 18,
      bookingDate: '07:35 PM, 10 Oct 2023',
      status: 'Pending',
      hotelName: 'Hotel Gems By OYO Rooms',
      checkIn: '11 Oct 2023',
      checkOut: '12 Oct 2023',
      fare: '1478',
      detailLink: 'https://tripoholidays.com/bookings/hotel/detail/IiwzQGAKYAo='
    },
    {
      id: 18,
      bookingDate: '07:35 PM, 10 Oct 2023',
      status: 'Pending',
      hotelName: 'Hotel Gems By OYO Rooms',
      checkIn: '11 Oct 2023',
      checkOut: '12 Oct 2023',
      fare: '1478',
      detailLink: 'https://tripoholidays.com/bookings/hotel/detail/IiwzQGAKYAo='
    },
    {
      id: 18,
      bookingDate: '07:35 PM, 10 Oct 2023',
      status: 'Pending',
      hotelName: 'Hotel Gems By OYO Rooms',
      checkIn: '11 Oct 2023',
      checkOut: '12 Oct 2023',
      fare: '1478',
      detailLink: 'https://tripoholidays.com/bookings/hotel/detail/IiwzQGAKYAo='
    },
    {
      id: 18,
      bookingDate: '07:35 PM, 10 Oct 2023',
      status: 'Pending',
      hotelName: 'Hotel Gems By OYO Rooms',
      checkIn: '11 Oct 2023',
      checkOut: '12 Oct 2023',
      fare: '1478',
      detailLink: 'https://tripoholidays.com/bookings/hotel/detail/IiwzQGAKYAo='
    },
    {
      id: 18,
      bookingDate: '07:35 PM, 10 Oct 2023',
      status: 'Pending',
      hotelName: 'Hotel Gems By OYO Rooms',
      checkIn: '11 Oct 2023',
      checkOut: '12 Oct 2023',
      fare: '1478',
      detailLink: 'https://tripoholidays.com/bookings/hotel/detail/IiwzQGAKYAo='
    },
    {
      id: 18,
      bookingDate: '07:35 PM, 10 Oct 2023',
      status: 'Pending',
      hotelName: 'Hotel Gems By OYO Rooms',
      checkIn: '11 Oct 2023',
      checkOut: '12 Oct 2023',
      fare: '1478',
      detailLink: 'https://tripoholidays.com/bookings/hotel/detail/IiwzQGAKYAo='
    },
    {
      id: 18,
      bookingDate: '07:35 PM, 10 Oct 2023',
      status: 'Pending',
      hotelName: 'Hotel Gems By OYO Rooms',
      checkIn: '11 Oct 2023',
      checkOut: '12 Oct 2023',
      fare: '1478',
      detailLink: 'https://tripoholidays.com/bookings/hotel/detail/IiwzQGAKYAo='
    },
    {
      id: 18,
      bookingDate: '07:35 PM, 10 Oct 2023',
      status: 'Pending',
      hotelName: 'Hotel Gems By OYO Rooms',
      checkIn: '11 Oct 2023',
      checkOut: '12 Oct 2023',
      fare: '1478',
      detailLink: 'https://tripoholidays.com/bookings/hotel/detail/IiwzQGAKYAo='
    },
    {
      id: 18,
      bookingDate: '07:35 PM, 10 Oct 2023',
      status: 'Pending',
      hotelName: 'Hotel Gems By OYO Rooms',
      checkIn: '11 Oct 2023',
      checkOut: '12 Oct 2023',
      fare: '1478',
      detailLink: 'https://tripoholidays.com/bookings/hotel/detail/IiwzQGAKYAo='
    },
    {
      id: 18,
      bookingDate: '07:35 PM, 10 Oct 2023',
      status: 'Pending',
      hotelName: 'Hotel Gems By OYO Rooms',
      checkIn: '11 Oct 2023',
      checkOut: '12 Oct 2023',
      fare: '1478',
      detailLink: 'https://tripoholidays.com/bookings/hotel/detail/IiwzQGAKYAo='
    },
    {
      id: 18,
      bookingDate: '07:35 PM, 10 Oct 2023',
      status: 'Pending',
      hotelName: 'Hotel Gems By OYO Rooms',
      checkIn: '11 Oct 2023',
      checkOut: '12 Oct 2023',
      fare: '1478',
      detailLink: 'https://tripoholidays.com/bookings/hotel/detail/IiwzQGAKYAo='
    },
    {
      id: 18,
      bookingDate: '07:35 PM, 10 Oct 2023',
      status: 'Pending',
      hotelName: 'Hotel Gems By OYO Rooms',
      checkIn: '11 Oct 2023',
      checkOut: '12 Oct 2023',
      fare: '1478',
      detailLink: 'https://tripoholidays.com/bookings/hotel/detail/IiwzQGAKYAo='
    },
    
    
  ];

  export default hotelBookingsData;