import axios from "axios";
import { apiConnector } from "../apiconnector";
import {
  setSearch,
  setLoading,
  setTraceId,
  setErrors,
} from "../../slices/flightSlice";
// import { setErrors } from "../../slices/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const URLmulticurl = "https://admin.lelotrip.com/api/search";
const URL = "https://admin.lelotrip.com/api/flight_search";
const URLtjRound = "https://admin.lelotrip.com/api/flight_search";

export function flightSearch(searchData, isMulticurl, isRoundTJ, navigate) {
  console.log("searchdata in redux", searchData);
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        isMulticurl === true ? URLmulticurl : isRoundTJ ? URLtjRound : URL,
        searchData
      );
      console.log("FLIGHTSEARCH API RESPONSE............", response);

      // if (!response.data.success) {
      //   throw new Error(response.data.message);
      // }
      // console.log(response.data);

      if (isMulticurl === true) {
        dispatch(setSearch(response.data.Results));
        dispatch(setTraceId(response.data.TraceId));
        sessionStorage.setItem("traceId", response.data.TraceId);
      } else if (isRoundTJ === true) {
        dispatch(setSearch(response.data.data.searchResult.tripInfos));
      } else {
        dispatch(setSearch(response.data.data.searchResult));
        // dispatch(setTraceId(response.data.data.TraceId));
        // sessionStorage.setItem("traceId", response.data.data.TraceId);
      }
    } catch (error) {
      console.log("FLIGHTSEARCH API ERROR............", error);
      toast.error("Session expired", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        // transition: Bounce,
      });
      // navigate("/");
      dispatch(setErrors("Axios Error"));
    }
    dispatch(setLoading(false));
  };
}
