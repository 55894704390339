import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Home.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const HolidayPackages = () => {
  const data = [
    {
      img: "https://bookurticket.com/images/DXB.webp",
      destination: "Dubai",
      duration: "3N/4D",
      discount: "save upto 30% off",
      price: "₹ 17,869",
      link: "/tour/dubai",
    },
    {
      img: "https://bookurticket.com/images/KUL.jpg",
      destination: "Kuala Lumpur",
      duration: "3N/4D",
      discount: "save upto 30% off",
      price: "₹ 18,643",
      link: "/tour/kuala-lumpur",
    },
    {
      img: "https://bookurticket.com/images/HKG.jpg",
      destination: "Singapore",
      duration: "3N/4D",
      discount: "save upto 30% off",
      price: "₹ 19,489",
      link: "/tour/singapore",
    },
    {
      img: "https://bookurticket.com/images/SIN.jpg",
      destination: "Hong Kong",
      duration: "3N/4D",
      discount: "save upto 30% off",
      price: "₹ 22,864",
      link: "/tour/hong-kong",
    },
    {
      img: "https://bookurticket.com/images/KUL.jpg",
      destination: "Kuala Lumpur",
      duration: "3N/4D",
      discount: "save upto 30% off",
      price: "₹ 18,643",
      link: "/tour/kuala-lumpur",
    },
  ];

  const swiperRef = useRef(null);

  return (
    <section className="pt-60">
      <Container style={{position:"relative"}}>
        <h2 className=" mb-4 holiday-pack-h2">
          <strong>Best Selling International Holiday Packages</strong>
        </h2>
        <p className="holidays-pack-para mb-4">
          Explore Top International Holidays
        </p>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
          modules={[Pagination, Autoplay]}
          autoplay
          loop
          className="mySwiper"
          style={{paddingBottom:"20px"}}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {data.map((item, index) => (
            <SwiperSlide style={{ width: "301px" }} key={index}>
              <Link to={item.link} className="travel-card" style={{boxShadow:"none"}}>
                <div className="image">
                  <img
                    className="img-fluid"
                    src={item.img}
                    alt={item.destination}
                  />
                </div>
                <div className="content">
                  <h1 className="topic">{item.destination}</h1>
                  <div className="recommendation">
                    <div className="score">
                      <span>{item.duration}</span>
                    </div>
                  </div>
                  <div className="price">
                    <div className="discount-info">{item.discount}</div>
                    <div className="original-price">
                      {/* <i className="fa fa-rupee-sign" /> */}{item.price}
                    </div>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="custom-next3" onClick={() => swiperRef.current?.slideNext()}>
            <FaChevronRight />
          </div>
          <div className="custom-prev3" onClick={() => swiperRef.current?.slidePrev()}>
            <FaChevronLeft />
          </div>
        {/* <Row className="g-4">
          <Col md={3}>
            <a href="#" className="travel-card">
              <div className="image">
                <img
                  className="img-fluid"
                  src="https://bookurticket.com/images/DXB.webp"
                  alt="Dubai"
                />
              </div>
              <div className="content">
                <h1 className="topic">Dubai</h1>
                <div className="recommendation">
                  <div className="score">
                    <span>3N/4D</span>
                  </div>
                </div>
                <div className="price">
                  <div className="discount-info">save upto 30% off</div>
                  <div className="original-price">
                    ₹ 17,869
                  </div>
                </div>
              </div>
            </a>
          </Col>
          <Col md={3}>
            <a href="#" className="travel-card">
              <div className="image">
                <img
                  className="img-fluid"
                  src="https://bookurticket.com/images/KUL.jpg"
                  alt="Kuala Lumpur"
                />
              </div>
              <div className="content">
                <h1 className="topic">Kuala Lumpur</h1>
                <div className="recommendation">
                  <div className="score">
                    <span>3N/4D</span>
                  </div>
                </div>
                <div className="price">
                  <div className="discount-info">save upto 30% off</div>
                  <div className="original-price">
                 ₹ 18,643
                  </div>
                </div>
              </div>
            </a>
          </Col>
          <Col md={3}>
            <a href="#" className="travel-card">
              <div className="image">
                <img
                  className="img-fluid"
                  src="https://bookurticket.com/images/HKG.jpg"
                  alt="Singapore"
                />
              </div>
              <div className="content">
                <h1 className="topic">Singapore</h1>
                <div className="recommendation">
                  <div className="score">
                    <span>3N/4D</span>
                  </div>
                </div>
                <div className="price">
                  <div className="discount-info">save upto 30% off</div>
                  <div className="original-price">
                   ₹ 19,489
                  </div>
                </div>
              </div>
            </a>
          </Col>
          <Col md={3}>
            <a href="#" className="travel-card">
              <div className="image">
                <img
                  className="img-fluid"
                  src="https://bookurticket.com/images/SIN.jpg"
                  alt="Hong Kong"
                />
              </div>
              <div className="content">
                <h1 className="topic">Hong Kong</h1>
                <div className="recommendation">
                  <div className="score">
                    <span>3N/4D</span>
                  </div>
                </div>
                <div className="price">
                  <div className="discount-info">save upto 30% off</div>
                  <div className="original-price">
                    ₹ 22,864
                  </div>
                </div>
              </div>
            </a>
          </Col>
        </Row> */}
      </Container>
    </section>
  );
};

export default HolidayPackages;
