import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import "./FlightList.css";
import { MdFlight, MdOutlineFlight } from "react-icons/md";
// import leftArrow from "./images/leftArrow.png";
// import rightArrow from "./images/rightArrow.png";
import { GiAirplaneDeparture } from "react-icons/gi";
import { ImPriceTags } from "react-icons/im";
import { GiAirplaneArrival } from "react-icons/gi";
import { GoStopwatch } from "react-icons/go";
import { FlightListInfo, formatTime } from "./FlightListInfo";
import { BsArrowLeftRight } from "react-icons/bs";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FaFilter, FaRupeeSign } from "react-icons/fa";
import { flightSearch } from "../../../redux/services/operations/flight";
import { useSelector, useDispatch } from "react-redux";
import FlightListSkeleton from "./FlightListSkeleton";
import { DayPickerRangeController } from "react-dates";
import {
  IoRadioButtonOff,
  IoRadioButtonOffSharp,
  IoRadioButtonOnOutline,
  IoRadioButtonOnSharp,
} from "react-icons/io5";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { cities, fares } from "../BookingSearch/FlightBooking";
import moment from "moment";
import Slider from "rc-slider";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import "rc-slider/assets/index.css";
import { RxCross2 } from "react-icons/rx";
import { FaChevronDown } from "react-icons/fa";
// import { cities2 } from "../BookingSearch/Cities";
import { cities12 } from "../../../Cities";
import FlightListCard from "./FlightListCard";
import FilterBar from "./FilterBar";
import ReSearchForm from "./ReSearchForm";
import axios from "axios";

const flightClassDefaultValue = (flightCabinClass) => {
  switch (parseInt(flightCabinClass)) {
    case 2:
      return "2"; // Economy
    case 3:
      return "3"; // PremiumEconomy
    case 4:
      return "4"; // Business
    case 6:
      return "6"; // First
    default:
      return ""; // Default to no selection
  }
};

const sliderItems = [
  { date: "Oct 03", price: "7845" },
  { date: "Oct 04", price: "5954" },
  { date: "Oct 05", price: "4155" },
  { date: "Oct 06", price: "5953" },
  { date: "Oct 07", price: "5495" },
  { date: "Oct 08", price: "5953" },
  { date: "Oct 09", price: "4155" },
];

const CalenderSliderContent = styled.div`
  border: 1px solid rgb(228, 228, 228);
  border-right: none;
  border-block: none;
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FlightList = () => {
  const [fromCurrency, setFromCurrency] = useState("INR");
  const [toCurrency, setToCurrency] = useState("USD");
  const [convertedAmount, setConvertedAmount] = useState(null);
  const [exchangeRate, setExchangeRate] = useState(null);

  useEffect(() => {
    const URL = `https://admin.lelotrip.com/api/currency_convert/${fromCurrency}/${toCurrency}`;
    const getExchangeRate = async () => {
      try {
        const response = await axios.get(URL);
        const rate = response.data.data.Conversion_Rate;
        console.log("rate inr to usd", rate);
        setExchangeRate(rate);
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
      }
    };
    if (fromCurrency !== toCurrency) {
      getExchangeRate(URL);
    } else {
      setExchangeRate(1);
    }
    // getExchangeRate()
  }, []);

  const handleChnageCurrency = (amount) => {
    if (!isNaN(amount) && exchangeRate) {
      // const convertedValue = amount * exchangeRate;
      // return convertedValue.toFixed(2);
      return amount;
    }
  };
  const token = localStorage.getItem("token");
  const sessionId = localStorage.getItem("sessionId");
  const navigate = useNavigate();
  const { data: routeParams } = useParams();
  const dispatch = useDispatch();
  const search = useSelector((state) => state.flight.search);
  const [sliderValue, setSliderValue] = useState([0, 0]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSearch, setDataSearch] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [rooms, setRooms] = useState([{ adults: 1, children: 0, infants: 0 }]);
  const [labelClicked, setLabelClicked] = useState(false);
  const [active, setActive] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [numberOfMonths, setNumberOfMonths] = useState(2);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [calVisible, setCalVisible] = useState(false);
  const [clickDestination, SetClickDestination] = useState(false);
  const [clickDestination2, SetClickDestination2] = useState(false);
  const [destinationCity, setDestinationCity] = useState("");
  const [selectedCityCode, setSelectedCityCode] = useState("");
  const [destinationCity2, setDestinationCity2] = useState("");
  const [selectedCityCode2, setSelectedCityCode2] = useState("");
  const [travellerActive, setTravellerActive] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [Visible, setVisible] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [tripsActive, setTripsActive] = useState(false);
  const [checkedStops, setCheckedStops] = useState([
    "non-stop",
    "1-stop",
    "2-stop",
  ]);
  const [deptimeRange, setdepTimeRange] = useState([0, 0]);
  const [arrtimeRange, setarrTimeRange] = useState([0, 0]);
  const [filteredData, setFilteredData] = useState(
    search.length > 0 ? search[0] : []
  );
  const traceId = String(sessionStorage.getItem("traceId"));
  const [airlines, setAirlines] = useState([]);
  const [minFare, setMinFare] = useState(0);
  const [maxFare, setMaxFare] = useState(0);
  const [selectedOption, setSelectedOption] = useState();

  const handleOptionChange = (val) => {
    setSelectedOption(val);
  };

  useEffect(() => {
    const searchData = parseSearchParams(routeParams);
    setDataSearch(searchData);
    updateRoomsData(searchData);
    updateDates(searchData);
    if (parseInt(searchData.JourneyType) === 1) handleSearchFlight();
    else if (parseInt(searchData.JourneyType) === 2) handleSearchFlightRound();
    else if (parseInt(searchData.JourneyType) === 3)
      handleSearchFlightMultiPle();
    setSearchInput(searchData.Segments[0].Origin);
    setSearchInput2(searchData.Segments[0].Destination);
    setSelectedOption(parseInt(searchData.Segments[0].FlightCabinClass));

    const destCity = cities12.find(
      (city) => city.AIRPORTCODE === searchData.Segments[0].Origin
    );
    const destCity2 = cities12.find(
      (city) => city.AIRPORTCODE === searchData.Segments[0].Destination
    );
    setDestination1(destCity);
    setDestination2(destCity2);
  }, [routeParams]);

  useEffect(() => {
    if (dataSearch) {
      setIsLoading(true);
      const updatedSearchData = { ...dataSearch };
      updatedSearchData.TokenId = token;
      updatedSearchData.EndUserIp = "192.168.10.10";
      // console.log("Updated Search Data", updatedSearchData);
      // dispatch(flightSearch(updatedSearchData, navigate));

      const searchdatamulticurl = {
        origin: updatedSearchData.Segments[0].Origin,
        destination: updatedSearchData.Segments[0].Destination,
        departureDate: updatedSearchData.Segments[0].PreferredDepartureTime,
        adult: parseInt(updatedSearchData.AdultCount),
        child: parseInt(updatedSearchData.ChildCount),
        infant: parseInt(updatedSearchData.InfantCount),
        type: parseInt(updatedSearchData.JourneyType),
        cabin: parseInt(updatedSearchData.Segments[0].FlightCabinClass),
        tboToken: token,
        partocrsSession: sessionId,
      };

      console.log("search data multicurl", searchdatamulticurl);
      dispatch(flightSearch(searchdatamulticurl, true, false, navigate))
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          // console.error("Error:", error);
          setIsLoading(false);
        });
    }
  }, [dataSearch, dispatch, navigate, token]);

  useEffect(() => {
    const handleResize = () => {
      setBreakpoints();
    };
    window.addEventListener("resize", handleResize);
    setBreakpoints();
    return () => window.removeEventListener("resize", handleResize);
  });

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (search.length > 0) {
      setFilteredData(search[0]);
    }
  }, [search]);

  useEffect(() => {
    if (search && search[0]) {
      const airlineNames = search[0].map((flight) => ({
        name: flight.Segments[0][0].Airline.AirlineName,
        selected: true,
      }));
      const uniqueAirlines = Array.from(
        new Set(airlineNames.map((airline) => airline.name))
      );
      const uniqueAirlinesWithSelected = uniqueAirlines.map((airlineName) => ({
        name: airlineName,
        selected: true,
      }));
      setAirlines(uniqueAirlinesWithSelected);
      console.log("airlines", uniqueAirlines);
    }
  }, [search]);

  useEffect(() => {
    if (search.length > 0) {
      const fares = search[0].map((flight) => flight.Fare.PublishedFare);

      const minFare = Math.min(...fares);
      const maxFare = Math.max(...fares);
      setMinFare(minFare);
      setMaxFare(maxFare);

      setSliderValue([minFare, maxFare]);
    }
  }, [search]);

  useEffect(() => {
    applyFilters();
  }, [search, sliderValue, deptimeRange, arrtimeRange, airlines, checkedStops]);

  const parseSearchParams = (data) => {
    const searchData = {
      Segments: [{}],
    };
    const params = decodeURIComponent(data).split("*");

    params.forEach((param) => {
      const [key, value] = param.split("_");
      switch (key) {
        case "dest":
          searchData.Segments[0].Destination = value;
          break;
        case "org":
          searchData.Segments[0].Origin = value;
          break;
        case "dep":
          searchData.Segments[0].PreferredDepartureTime = value;
          break;
        case "arr":
          searchData.Segments[0].PreferredArrivalTime = value;
          break;
        case "px":
          const [adultCount, childCount, infantCount] = value.split("-");
          searchData.AdultCount = adultCount;
          searchData.ChildCount = childCount;
          searchData.InfantCount = infantCount;
          break;
        case "jt":
          searchData.JourneyType = value;
          break;
        case "cbn":
          searchData.Segments[0].FlightCabinClass = value;
          break;
        default:
          break;
      }
    });

    return searchData;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const tripTypeMapping = {
      OneWay: 1,
      RoundTrip: 2,
      MultiCity: 3,
    };

    // const cabin = event.target.cabinClass.value;
    const tripType = tripTypeMapping[!active ? "RoundTrip" : "OneWay"];

    const SearchData = {
      EndUserIp: "192.168.10.10",
      AdultCount: rooms[0].adults,
      ChildCount: rooms[0].children,
      InfantCount: rooms[0].infants,
      JourneyType: tripType,
      Segments: [
        {
          Origin: event.target.from.value,
          Destination: event.target.to.value,
          FlightCabinClass: selectedOption,
          PreferredDepartureTime: startDate.startOf("day").format("YYYY-MM-DD"),
          PreferredArrivalTime: endDate
            ? endDate.startOf("day").format("YYYY-MM-DD")
            : startDate.startOf("day").format("YYYY-MM-DD"),
        },
      ],
    };
    if (active) {
      window.location.assign(
        `/flightList/${encodeURIComponent(
          `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
        )}`
      );
    }
    if (active2) {
      // if (destination1.COUNTRYCODE !== destination2.COUNTRYCODE) {
      window.location.assign(
        `/international-round/${encodeURIComponent(
          `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
        )}`
      );
      // } else {
      //   window.location.assign(
      //     `/round/${encodeURIComponent(
      //       `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
      //     )}`
      //   );
      // }
    }
  };

  const handleSearchFlight = () => {
    setActive(true);
    setActive2(false);
    setActive3(false);
    setEndDate(null);
  };
  const handleSearchFlightRound = () => {
    setActive2(true);
    setActive(false);
    setActive3(false);
  };
  const handleSearchFlightMultiPle = () => {
    setActive3(true);
    setActive(false);
    setActive2(false);
  };

  const setBreakpoints = () => {
    const isSmallScreen = window.innerWidth <= 768;
    const isMediumScreen = window.innerWidth > 768 && window.innerWidth <= 992;

    if (isSmallScreen) {
      setDateRangeConfig(1, 30, 30);
    } else if (isMediumScreen) {
      setDateRangeConfig(1, 40, 60);
    } else {
      setDateRangeConfig(2, 40, 50);
    }
  };

  const setDateRangeConfig = (numberOfMonths, daysize) => {
    setNumberOfMonths(numberOfMonths);
  };

  const isSameDay = (date1, date2) => date1.isSame(date2, "day");

  const renderDayContents = (day) => {
    // const fare = fares.find((item) => isSameDay(day, item.date));
    const isStartDate = startDate && isSameDay(day, startDate);
    const isEndDate = endDate && isSameDay(day, endDate);
    const isInRange =
      startDate && endDate && day.isBetween(startDate, endDate, "day", "[]");

    let classNames = ["DayPicker-Day"];
    if (isStartDate) classNames.push("DayPicker-Day--start");
    if (isEndDate) classNames.push("DayPicker-Day--end");
    if (isInRange) classNames.push("DayPicker-Day--range");

    return (
      <div className={classNames.join(" ")}>
        <span style={{ fontWeight: "600", fontSize: "13px" }}>
          {day.format("D")}
        </span>
        <br />
        {/* {fare && (
          <span
            style={{ fontSize: "10px", color: "#002543", fontWeight: "600" }}
          >
            {fare.fare}
          </span>
        )} */}
      </div>
    );
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      setFocusedInput(null);
      setCalVisible(false);
    } else if (startDate) {
      setFocusedInput("endDate");
    }
  };

  const toggleCalendar = (input) => {
    setCalVisible(!calVisible);
    if (!calVisible) {
      if (input === "startDate") {
        setFocusedInput("startDate");
      } else if (input === "endDate") {
        setFocusedInput("endDate");
      } else {
        setCalVisible(!calVisible);
        if (!calVisible) setFocusedInput("startDate");
        else setFocusedInput(null);
      }
    }
  };

  const updateRoom = (index, field, value) => {
    const updatedRooms = [...rooms];
    updatedRooms[index][field] = value;
    setRooms(updatedRooms);
  };

  const updateRoomsData = (searchData) => {
    const updatedRooms = [
      {
        adults: parseInt(searchData.AdultCount),
        children: parseInt(searchData.ChildCount),
        infants: parseInt(searchData.InfantCount),
      },
    ];
    setRooms(updatedRooms);
  };

  const updateDates = (searchData) => {
    const departureTime = moment(searchData.Segments[0].PreferredDepartureTime);
    const arrivalTime = moment(searchData.Segments[0].PreferredArrivalTime);
    setStartDate(departureTime);
    // active2 &&
    setEndDate(arrivalTime);
  };

  const parseFareRule = (fareRuleDetail) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = fareRuleDetail;
    const listItems = Array.from(tempDiv.querySelectorAll("li")).map((li) =>
      li.textContent.trim()
    );

    return listItems;
  };

  const [fareRules, setFareRules] = useState("");
  const handleClick = async (id, SrdvIndex) => {
    setActiveId(activeId === id ? null : id);
    console.log("srdvIdx", SrdvIndex);
    if (SrdvIndex !== "SrdvP" && SrdvIndex !== "SrdvTJ") {
      console.log("tbo farerule called");
      const response1 = await axios.post(
        "https://admin.lelotrip.com/api/flight-fare-rule",
        {
          EndUserIp: "192.168.11.58",
          TokenId: token,
          TraceId: traceId,
          ResultIndex: id,
        }
      );
      console.log("response1", response1);
      setFareRules(
        response1.data.data.FareRules[0] &&
          parseFareRule(response1.data.data.FareRules[0].FareRuleDetail)
      );
    } else if (SrdvIndex === "SrdvTJ") {
      console.log("tj farerule called");
      const responseTJ1 = await axios.post(
        "https://admin.lelotrip.com/api/flight_farerule",
        {
          id: id,
          flowType: "SEARCH",
        }
      );
      console.log("response1", responseTJ1);
      if (responseTJ1.data.success)
        setFareRules(
          responseTJ1.data.data.fareRule && responseTJ1.data.data.fareRule
        );
    } else if (SrdvIndex === "SrdvP") {
      console.log("parto farerule called");
      const responseP1 = await axios.post(
        "https://admin.lelotrip.com/api/air_rules",
        {
          SessionId: sessionId,
          FareSourceCode: id,
        }
      );
      console.log("responseP1", responseP1);
      if (responseP1.data.success)
        setFareRules(
          responseP1.data.data.FareRules[0] &&
            responseP1.data.data.FareRules[0].RuleDetails[0].Rules
        );
    }
  };

  const [isItemSelected, setIsItemSelected] = useState(false);
  const [isItemSelected2, setIsItemSelected2] = useState(false);
  const [destination1, setDestination1] = useState("");
  const [destination2, setDestination2] = useState("");

  const handleCitySelect = (city) => {
    setDestinationCity(`${city["CITYNAME"]} (${city["AIRPORTCODE"]})`);
    setSelectedCityCode(city["AIRPORTCODE"]);
    SetClickDestination(false); // Close the city suggestion div
    setSearchInput(`${city["AIRPORTCODE"]}`); // Clear the search input after selecting a city
    setIsItemSelected(true);
    setDestination1(city);
  };

  const handleCitySelect2 = (city) => {
    setDestinationCity2(`${city["CITYNAME"]} (${city["AIRPORTCODE"]})`);
    setSelectedCityCode2(city["AIRPORTCODE"]);
    SetClickDestination2(false); // Close the city suggestion div
    setSearchInput2(`${city["AIRPORTCODE"]}`); // Clear the search input after selecting a city
    setIsItemSelected2(true);
    setDestination2(city);
  };

  const handleMoreFare = (idx) => {
    setShowModal(showModal === idx ? null : idx);
  };
  const handlebookmodal = (url) => {
    navigate(url);
    setShowModal(false);
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    setVisible(scrollTop > 300);
  };

  const handleSliderChange = (value) => {
    setSliderValue(value);
  };

  const handleCheckedstops = (stopType) => {
    if (checkedStops.includes(stopType)) {
      setCheckedStops(checkedStops.filter((stop) => stop !== stopType));
    } else {
      setCheckedStops([...checkedStops, stopType]);
    }
  };

  const handledepTimeFilter = (range) => {
    if (deptimeRange[0] === range[0] && deptimeRange[1] === range[1])
      setdepTimeRange([0, 0]);
    else setdepTimeRange(range);
  };

  const handlearrTimeFilter = (range) => {
    if (arrtimeRange[0] === range[0] && arrtimeRange[1] === range[1])
      setarrTimeRange([0, 0]);
    else setarrTimeRange(range);
  };

  const applyFilters = () => {
    if (search.length === 0 || isLoading) {
      return;
    }

    const selectedAirlines = airlines
      .filter((airline) => airline.selected)
      .map((airline) => airline.name);

    const newFilteredData =
      search[0] &&
      search[0].filter((e) => {
        const fareInRange =
          e.Fare.PublishedFare >= sliderValue[0] &&
          e.Fare.PublishedFare <= sliderValue[1];

        const depTimeInRange =
          (deptimeRange[0] === 0 && deptimeRange[1] === 0) ||
          (new Date(e.Segments[0][0].Origin.DepTime).getHours() >=
            deptimeRange[0] &&
            new Date(e.Segments[0][0].Origin.DepTime).getHours() <=
              deptimeRange[1]);

        const arrTimeInRange =
          (arrtimeRange[0] === 0 && arrtimeRange[1] === 0) ||
          (new Date(e.Segments[0][0].Destination.ArrTime).getHours() >=
            arrtimeRange[0] &&
            new Date(e.Segments[0][0].Destination.ArrTime).getHours() <=
              arrtimeRange[1]);

        const isAirlineSelected =
          selectedAirlines.length === 0 ||
          selectedAirlines.includes(e.Segments[0][0].Airline.AirlineName);

        let stopCountMatch = false;
        if (checkedStops.length === 0) {
          stopCountMatch = true;
        } else {
          const stopCount = e.Segments[0].length - 1;
          if (checkedStops.includes("non-stop") && stopCount === 0) {
            stopCountMatch = true;
          } else if (checkedStops.includes("1-stop") && stopCount === 1) {
            stopCountMatch = true;
          } else if (checkedStops.includes("2-stop") && stopCount >= 2) {
            stopCountMatch = true;
          }
          // } else if (checkedStops.includes("3-stop") && stopCount >= 3) {
          //   stopCountMatch = true;
          // }
        }
        return (
          fareInRange &&
          depTimeInRange &&
          arrTimeInRange &&
          isAirlineSelected &&
          stopCountMatch
        );
      });
    setFilteredData(newFilteredData);
    console.log("Filtered Data:", newFilteredData);
  };

  const handleChecked = (airlineName) => {
    const updatedAirlines = airlines.map((airline) =>
      airline.name === airlineName
        ? { ...airline, selected: !airline.selected }
        : airline
    );
    setAirlines(updatedAirlines);
  };

  const handleShowAllairlinenames = (event) => {
    const isChecked = event.target.checked;
    const updatedAirlines = airlines.map((airline) => ({
      ...airline,
      selected: isChecked,
    }));
    setAirlines(updatedAirlines);
  };

  const handleShowAllStops = (event) => {
    if (event.target.checked) {
      setCheckedStops(["non-stop", "1-stop", "2-stop"]);
    } else {
      setCheckedStops([]);
    }
  };

  const clearAllFilters = () => {
    setSliderValue([minFare, maxFare]);
    setdepTimeRange([0, 0]);
    setarrTimeRange([0, 0]);
    setCheckedStops(["non-stop", "1-stop", "2-stop"]);

    const updatedAirlines = airlines.map((airline) => ({
      ...airline,
      selected: true,
    }));
    setAirlines(updatedAirlines);
  };

  // const [searchInput, setSearchInput] = useState(destinationCity);
  // const [searchInput2, setSearchInput2] = useState(destinationCity2);

  // const handleInputChange = (event) => {
  //   const userInput = event.target.value.toUpperCase();
  //   setSearchInput(userInput);
  //   // const filteredCities = cities2.filter((city) => {
  //   //   return (
  //   //     city["Airport Name"].toLowerCase().includes(userInput) ||
  //   //     city["Airport Code"].toLowerCase().includes(userInput) ||
  //   //     city["City Code"].toLowerCase().includes(userInput) ||
  //   //     city["Country Name"].toLowerCase().includes(userInput) ||
  //   //     city["Country Code"].toLowerCase().includes(userInput)
  //   //   );
  //   // });
  //   // setCities2(filteredCities);
  //   setIsItemSelected(false); // Reset the state when input changes
  // };

  // const handleInputChange2 = (event) => {
  //   const userInput = event.target.value.toUpperCase();
  //   setSearchInput2(userInput);
  //   // const filteredCities = cities2.filter((city) => {
  //   //   return (
  //   //     city["Airport Name"].toLowerCase().includes(userInput) ||
  //   //     city["Airport Code"].toLowerCase().includes(userInput) ||
  //   //     city["City Code"].toLowerCase().includes(userInput) ||
  //   //     city["Country Name"].toLowerCase().includes(userInput) ||
  //   //     city["Country Code"].toLowerCase().includes(userInput)
  //   //   );
  //   // });
  //   // setCities2(filteredCities);
  //   setIsItemSelected2(false); // Reset the state when input changes
  // };

  // const filteredCities = cities2.filter(
  //   (city) =>
  //     city["AIRPORTNAME"].toLowerCase().includes(searchInput.toLowerCase()) ||
  //     city["AIRPORTCODE"].toLowerCase().includes(searchInput.toLowerCase()) ||
  //     city["CITYCODE"].toLowerCase().includes(searchInput.toLowerCase()) ||
  //     city["COUNTRYNAME"].toLowerCase().includes(searchInput.toLowerCase()) ||
  //     city["COUNTRYCODE"].toLowerCase().includes(searchInput.toLowerCase()) ||
  //     city["CITYNAME"].toLowerCase().includes(searchInput.toLowerCase())
  // ).map((city) => {
  //   let priority = 3;

  //   if (city["CITYCODE"].toLowerCase().includes(searchInput.toLowerCase())) {
  //     priority = 1; // Highest priority for city code matches
  //   } else if (city["CITYNAME"].toLowerCase().includes(searchInput.toLowerCase())) {
  //     priority = 2; // Second priority for city name matches
  //   } else if (city["COUNTRYNAME"].toLowerCase().includes(searchInput.toLowerCase())) {
  //     priority = 3; // Third priority for country name matches
  //   }

  //   return { ...city, priority };
  // })
  // .sort((a, b) => a.priority - b.priority);

  // const filteredCities2 = cities2.filter(
  //   (city) =>
  //     city["AIRPORTNAME"].toLowerCase().includes(searchInput2.toLowerCase()) ||
  //     city["AIRPORTCODE"].toLowerCase().includes(searchInput2.toLowerCase()) ||
  //     city["CITYCODE"].toLowerCase().includes(searchInput2.toLowerCase()) ||
  //     city["COUNTRYNAME"].toLowerCase().includes(searchInput2.toLowerCase()) ||
  //     city["COUNTRYCODE"].toLowerCase().includes(searchInput2.toLowerCase()) ||
  //     city["CITYNAME"].toLowerCase().includes(searchInput2.toLowerCase())
  // ).map((city) => {
  //   let priority = 3;

  //   if (city["CITYCODE"].toLowerCase().includes(searchInput2.toLowerCase())) {
  //     priority = 1; // Highest priority for city code matches
  //   } else if (city["CITYNAME"].toLowerCase().includes(searchInput2.toLowerCase())) {
  //     priority = 2; // Second priority for city name matches
  //   } else if (city["COUNTRYNAME"].toLowerCase().includes(searchInput2.toLowerCase())) {
  //     priority = 3; // Third priority for country name matches
  //   }

  //   return { ...city, priority };
  // })
  // .sort((a, b) => a.priority - b.priority);

  const [searchInput, setSearchInput] = useState(destinationCity);
  const [searchInput2, setSearchInput2] = useState(destinationCity2);
  const [cities2, setCities2] = useState([]);
  const [cities22, setCities22] = useState([]);
  const fetchDatas = (value) => {
    fetch("https://admin.lelotrip.com/api/airport")
      .then((response) => response.json())
      .then((json) => {
        const results = json.data
          .filter((user) => {
            return (
              user &&
              ((user.CITYNAME &&
                user.CITYNAME.toLowerCase().includes(value.toLowerCase())) ||
                (user.AIRPORTNAME &&
                  user.AIRPORTNAME.toLowerCase().includes(
                    value.toLowerCase()
                  )) ||
                (user.CITYCODE &&
                  user.CITYCODE.toLowerCase().includes(value.toLowerCase())) ||
                (user.COUNTRYNAME &&
                  user.COUNTRYNAME.toLowerCase().includes(
                    value.toLowerCase()
                  )) ||
                (user.COUNTRYCODE &&
                  user.COUNTRYCODE.toLowerCase().includes(
                    value.toLowerCase()
                  )) ||
                (user.AIRPORTCODE &&
                  user.AIRPORTCODE.toLowerCase().includes(value.toLowerCase())))
            );
          })
          .map((user) => {
            let priority = 3;

            if (
              user.AIRPORTCODE &&
              user.AIRPORTCODE.toLowerCase().includes(value.toLowerCase())
            ) {
              priority = 1; // Highest priority for city code matches
            } else if (
              user.CITYNAME &&
              user.CITYNAME.toLowerCase().includes(value.toLowerCase())
            ) {
              priority = 2; // Second priority for city name matches
            } else if (
              user.COUNTRYNAME &&
              user.COUNTRYNAME.toLowerCase().includes(value.toLowerCase())
            ) {
              priority = 3; // Third priority for country name matches
            }

            return { ...user, priority };
          })
          .sort((a, b) => a.priority - b.priority);

        console.log("RESULTS", results);
        setCities2(results);
      });
  };

  const handleInputChange = (value) => {
    setSearchInput(value.toUpperCase());
    fetchDatas(value.toLowerCase());
    SetClickDestination(true);
    setIsItemSelected(false);
  };
  const fetchDatass = (value) => {
    fetch("https://admin.lelotrip.com/api/airport")
      .then((response) => response.json())
      .then((json) => {
        const results = json.data
          .filter((user) => {
            return (
              user &&
              ((user.CITYNAME &&
                user.CITYNAME.toLowerCase().includes(value.toLowerCase())) ||
                (user.AIRPORTNAME &&
                  user.AIRPORTNAME.toLowerCase().includes(value)) ||
                (user.CITYCODE &&
                  user.CITYCODE.toLowerCase().includes(value.toLowerCase())) ||
                (user.COUNTRYNAME &&
                  user.COUNTRYNAME.toLowerCase().includes(
                    value.toLowerCase()
                  )) ||
                (user.COUNTRYCODE &&
                  user.COUNTRYCODE.toLowerCase().includes(
                    value.toLowerCase()
                  )) ||
                (user.AIRPORTCODE &&
                  user.AIRPORTCODE.toLowerCase().includes(value.toLowerCase())))
            );
          })
          .map((user) => {
            let priority = 3;

            if (
              user.AIRPORTCODE &&
              user.AIRPORTCODE.toLowerCase().includes(value.toLowerCase())
            ) {
              priority = 1; // Highest priority for city code matches
            } else if (
              user.CITYNAME &&
              user.CITYNAME.toLowerCase().includes(value.toLowerCase())
            ) {
              priority = 2; // Second priority for city name matches
            } else if (
              user.COUNTRYNAME &&
              user.COUNTRYNAME.toLowerCase().includes(value.toLowerCase())
            ) {
              priority = 3; // Third priority for country name matches
            }

            return { ...user, priority };
          })
          .sort((a, b) => a.priority - b.priority);

        // console.log("RESULTS",results)
        setCities22(results);
      });
  };
  const handleInputChange2 = (value) => {
    setSearchInput2(value.toUpperCase());
    fetchDatass(value.toLowerCase());
    SetClickDestination2(true);
    setIsItemSelected2(false);
  };

  // const [fareRules, setFareRules] = useState("");
  // const handleClickRule = async (id) => {
  //   setActiveId(activeId === id ? null : id);
  //   // const response1 = await axios.post(
  //   //   "https://admin.lelotrip.com/api/flightfarerule",
  //   //   {
  //   //     APIToken: tokensss || tokenss,
  //   //     BookingKey: bookingKey || bookingKeyss,
  //   //     ContractId: id,
  //   //   }
  //   // );

  //   setFareRules(response1.data.data.FareRule);
  // };

  return (
    <div className="flightListPage roundtrippg">
      <ReSearchForm
        dataSearch={dataSearch}
        handleSubmit={handleSubmit}
        tripsActive={tripsActive}
        active={active}
        active2={active2}
        active3={active3}
        handleSearchFlight={handleSearchFlight}
        handleSearchFlightRound={handleSearchFlightRound}
        handleSearchFlightMultiPle={handleSearchFlightMultiPle}
        setTripsActive={setTripsActive}
        searchInput={searchInput}
        searchInput2={searchInput2}
        SetClickDestination={SetClickDestination}
        SetClickDestination2={SetClickDestination2}
        isItemSelected={isItemSelected}
        isItemSelected2={isItemSelected2}
        handleInputChange={handleInputChange}
        handleInputChange2={handleInputChange2}
        filteredCities={cities2}
        filteredCities2={cities22}
        clickDestination={clickDestination}
        clickDestination2={clickDestination2}
        handleCitySelect={handleCitySelect}
        handleCitySelect2={handleCitySelect2}
        startDate={startDate}
        setStartDate={setStartDate}
        moment={moment}
        endDate={endDate}
        toggleCalendar={toggleCalendar}
        rooms={rooms}
        setTravellerActive={setTravellerActive}
        travellerActive={travellerActive}
        updateRoom={updateRoom}
        selectedOption={selectedOption}
        handleOptionChange={handleOptionChange}
        calVisible={calVisible}
        setCalVisible={setCalVisible}
        handleDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        setFocusedInput={setFocusedInput}
        renderDayContents={renderDayContents}
        numberOfMonths={numberOfMonths}
      />

      <div className="flightsMainBody">
        {/* <Navbar /> */}
        {/* <Bookingmenu /> */}
        <Container className="bodyDiv">
          <Row>
            <FilterBar
              showFilter={showFilter}
              minFare={minFare}
              maxFare={maxFare}
              sliderValue={sliderValue}
              clearAllFilters={clearAllFilters}
              handleSliderChange={handleSliderChange}
              handledepTimeFilter={handledepTimeFilter}
              deptimeRange={deptimeRange}
              arrtimeRange={arrtimeRange}
              handlearrTimeFilter={handlearrTimeFilter}
              handleShowAllStops={handleShowAllStops}
              checkedStops={checkedStops}
              handleCheckedstops={handleCheckedstops}
              handleShowAllairlinenames={handleShowAllairlinenames}
              airlines={airlines}
              handleChecked={handleChecked}
              setShowFilter={setShowFilter}
              applyFilters={applyFilters}
              handleChnageCurrency={handleChnageCurrency}
            />
            <Col md={9} className="rightDiv" style={{ paddingRight: "0px" }}>
              <Row style={{ margin: "0px", padding: "0px" }} className="mbsm">
                <div className="calendarSlider" style={{ padding: "0px" }}>
                  <div className="sliderLeft" style={{ width: "100%" }}>
                    <img
                      src="./images/leftArrow.png"
                      alt=""
                      style={{
                        height: "80%",
                        paddingBlock: "15px",
                        paddingInline: "15px",
                        borderLeft: "1px solid rgb(228,228,228)",
                      }}
                    />
                    <div style={{}} className="datesdiv">
                      {sliderItems.map((item, index) => (
                        <CalenderSliderContent
                          key={index}
                          style={{ textWrap: "nowrap" }}
                        >
                          <p
                            //   style={{
                            //     lineHeight: "0cm",
                            //     paddingTop: "5px",
                            //     fontSize: "15px",
                            //     marginTop: "10px",
                            //   }}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              paddingInline: "5px",
                              marginBottom: "2px",
                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                            className="calenderslidercontent"
                          >
                            {item.date}
                          </p>
                          <p
                            style={{
                              fontSize: "10px",
                              color: "black",
                              marginBottom: "2px",
                              color: "rgb(45, 50, 144)",
                              fontWeight: "600",
                            }}
                            className="calenderprice"
                          >
                            INR{handleChnageCurrency(item.price)}
                          </p>
                          <div className="calenderData">
                            {/* <img src={rupee} alt="" /> */}
                            {/* <p>{item.price}</p> */}
                          </div>
                        </CalenderSliderContent>
                      ))}
                    </div>
                    <img
                      src="./images/rightArrow.png"
                      alt=""
                      style={{
                        height: "80%",
                        paddingBlock: "15px",
                        paddingInline: "15px",
                        borderLeft: "1px solid rgb(228,228,228)",
                      }}
                    />
                  </div>
                  {/* <div className="sliderRight" style={{ width: "10%" }}>
                    <MdFlight size={22} className="" />
                  </div> */}
                </div>
              </Row>

              <Row
                style={{ margin: "0px", padding: "0px" }}
                className="d-none d-md-flex"
              >
                <div
                  className="menuItems"
                  style={{
                    width: "100%",
                    fontWeight: "600",
                    color: "rgb(45, 50, 144)",
                    margin: "10px 0px",
                    padding: "5px",
                    background: "rgb(249, 249, 249)",
                    height: "max-content",
                    textAlign: "center",
                  }}
                >
                  <p style={{ marginLeft: "3px" }}>AIRLINES</p>
                  <p>DEPART</p>
                  <p>DURATION</p>
                  <p>ARRIVE</p>
                  <p>PRICE</p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p>RECOMMENDED</p>
                    <label
                      className="toggleSwitch"
                      style={{
                        marginTop: "0px",
                        marginLeft: "5px",
                        marginRight: "2px",
                      }}
                    >
                      <input className="toggleInput" type="checkbox" />
                      <span className="toggleSlider" />
                    </label>
                  </div>
                </div>
              </Row>
              {search.length !== 0 && !isLoading ? (
                <div>
                  {search &&
                    filteredData &&
                    filteredData.map((e, indexx) => {
                      return (
                        <FlightListCard
                          key={indexx}
                          e={e}
                          handleMoreFare={handleMoreFare}
                          handleClick={handleClick}
                          activeId={activeId}
                          showModal={showModal}
                          setShowModal={setShowModal}
                          formatTime={formatTime}
                          handlebookmodal={handlebookmodal}
                          handleChnageCurrency={handleChnageCurrency}
                          fareRules={fareRules}
                        />
                      );
                    })}
                </div>
              ) : (
                <div className="d-flex flex-column gap-3">
                  <FlightListSkeleton />
                  <FlightListSkeleton />
                  <FlightListSkeleton />
                  <FlightListSkeleton />
                </div>
              )}
            </Col>
          </Row>
          <div
            className={`applyFilter ${Visible && "show"}`}
            onClick={() => setShowFilter(true)}
          >
            <FaFilter /> Filter
          </div>
        </Container>
      </div>
    </div>
  );
};
