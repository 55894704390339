import React,{useState} from "react";
import data from "./data";
import { Link, useNavigate } from "react-router-dom";
import QuotesModal from "./QuotesModal";

const DetailPageReuse = ({ data }) => {
  const navigate = useNavigate();
  // const handleSearch = () => {
  //   navigate("/tour/detailpageslist");
  // };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      {data.map((item) => (
        <div
          className="DetailPageReuseMain border rounded-lg mb-4 w-full xl:px-0 md:px-0"
          key={item.id}
        >
          <div className="DetailPageReuseMain2">
            <div className="DetailPageReuseMainInner1">
              <img
                src={item.image}
                alt=""
                // className="rounded-md h-[225px] w-[400px] object-cover"
              />
            </div>
            <div className="DetailPageReuseMain3">
              <div className="DetailPageReuseMainInner2">
                <div>
                  <h2 className="DetailPageReuseMainInner2Head">{item.name}</h2>
                  <p className="DetailPageReuseMainInner2para">
                    {" "}
                    {item.no_of_nights} Nights / {item.no_of_days} Days
                  </p>
                </div>
                <div className="">
                  <p className="DetailPageReuseMainInner2para2">
                    ₹{item.publish_price}
                  </p>
                  <p className="DetailPageReuseMainInner2para3">
                    Offer Prize :{" "}
                    <span className="DetailPageReuseMainInner2Span">
                      ₹{item.offer_price}
                    </span>
                  </p>
                </div>
              </div>
              <div className="DetailPageReuseMainInner3">
                {/* <p className='text-[12px] text-[#336d85] font-[600] '>{item.day}</p> */}
                <p className="DetailPageReuseMainInner3Para">
                  {item.details_day_night}
                </p>
                <p className="DetailPageReuseMainInner3Para2">Top Inclusion</p>
                <div className="DetailPageReuseMainInner4">
                  {item.top_inclusion.map((dat) => (
                    <img
                      src={dat.image}
                      alt=""
                      // className="w-[25px] h-[20px]"
                    />
                  ))}
                </div>
              </div>
              <div className="DetailPageReuseMainInner5">
                <button className="DetailPageReuseMainInner5Btn1"  onClick={handleShow}>
                  Get Quotes
                </button>
                {/* <Link to="/destinations/family/appealing-swiss"> */}
                <Link to={`${item.name.replace(/\s+/g, "-").toLowerCase()}`}>
                  <button
                    className="DetailPageReuseMainInner5Btn2"
                    // onClick={handleSearch}
                  >
                    View Details
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
      <QuotesModal show={show} handleClose={handleClose}/>
    </div>
  );
};

export default DetailPageReuse;
