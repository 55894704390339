// import { useAuth } from "../context/AuthContext";
// import LoginFirst from "./Navbar/LoginFirst";

// const ProtectedRoute = ({ children }) => {
//   const { user } = useAuth();

//   if (!user) {
//     return <LoginFirst />;
//   }

//   return children;
// };

// export default ProtectedRoute;

// import React from "react";
// import { Navigate } from "react-router-dom";
// import { useAuth } from "../context/AuthContext";
// import LoginFirst from "./Navbar/LoginFirst";

// const ProtectedRoute = ({ element: Element, ...rest }) => {
//   const { isAuthenticated } = useAuth();

//   return isAuthenticated ? <Element {...rest} /> : <LoginFirst />;
// };

// export default ProtectedRoute;


import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import LoginFirst from "./Navbar/LoginFirst";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? children : <LoginFirst />;
};

export default ProtectedRoute;
