import React from "react";

const BDSend = ({walletData}) => {
  return (
    <div className="componentContainer">
      <div className="bookingDetailsForm" id="contactDetails">
        <p className="fontSize14 boldFont appendBottom15">
          Booking details will be sent to
        </p>
        <div className="adultItemRow">
          <div className="adultItem" id="Country Code">
            <label className="makeFlex hrtlCenter">Country Code</label>
            <div className="selectItem relative ">
              <div className="selectList css-2b097c-container">
                <div className="dropdown__control css-yk16xz-control">
                  <div className="dropdown__value-container dropdown__value-container--has-value css-1hwfws3">
                    <div className="dropdown__single-value css-1uccc91-singleValue">
                      India(91)
                    </div>
                    <div className="css-1g6gooi">
                      <div
                        className="dropdown__input"
                        style={{ display: "inline-block" }}
                      >
                        <input
                          autoCapitalize="none"
                          autoComplete="off"
                          autoCorrect="off"
                          id="react-select-2-input"
                          spellCheck="false"
                          tabIndex={0}
                          type="text"
                          aria-autocomplete="list"
                          defaultValue=""
                          style={{
                            boxSizing: "content-box",
                            width: 2,
                            background: "0px center",
                            border: 0,
                            fontSize: "inherit",
                            opacity: 1,
                            outline: 0,
                            padding: 0,
                            color: "inherit",
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            visibility: "hidden",
                            height: 0,
                            overflow: "scroll",
                            whiteSpace: "pre",
                            fontSize: 14,
                            fontFamily: "lato, sans-serif",
                            fontWeight: 400,
                            fontStyle: "normal",
                            letterSpacing: "normal",
                            textTransform: "none",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="dropdown__indicators css-1wy0on6">
                    <span className="dropdown__indicator-separator css-1hyfx7x" />
                    <div
                      aria-hidden="true"
                      className="dropdown__indicator dropdown__dropdown-indicator css-1eew81i"
                    >
                      <svg
                        height={20}
                        width={20}
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        focusable="false"
                        className="css-19bqh2r"
                      >
                        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="adultItem flightBookingFirstName1" id="Mobile No">
            <div className="relative">
              <label htmlFor="popup">Mobile No</label>
              <input
                autoComplete="none"
                defaultValue={walletData.Phone}
                // value={walletData.Phone}
                // placeholder={walletData.Phone}
                className="tvlrInput "
                type="text"
              />
            </div>
          </div>
          <div className="adultItem flightBookingFirstName1" id="Email">
            <div className="relative">
              <label htmlFor="popup">Email</label>
              <input
                autoComplete="none"
                defaultValue={walletData.Email}
                // placeholder={walletData.Email}
                className="tvlrInput "
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BDSend;
