import React from "react";
import { Container } from "react-bootstrap";
import {
  FaPlane,
  FaHotel,
  FaTrain,
  FaBus,
  FaSuitcase,
  FaCar,
  FaHiking,
  FaGift,
  FaShip,
  FaSuitcaseRolling,
  FaHeadphones,
} from "react-icons/fa";
import './ListProduct.css';
import { Link } from "react-router-dom";

const ListProduct = ({active}) => {
  return (
    <section className="outer listProduct  app-hide" id="mainhotelhedermob" style={{background:"#fff"
    // , marginTop:"15px"
    }}>
      {/* <Container fluid> */}
      {/* <div className={`_innerbx ${active === 'flight' ? 'actvMenu' : ''}`}>
          <Link to="/">
            <div className="flight-icon produt-icon" />
            <span className="_icnPttl">Flight</span>
          </Link>
        </div> */}
        <div className={`_innerbx ${active === 'hotel' ? 'actvMenu' : ''}`}>
          <Link to="/hotel">
            <div className="hotel-icon produt-icon" />
            <span className="_icnPttl">Hotel</span>
          </Link>
        </div>
        
        <div className="_innerbx" id="divHoliday">
          <Link to="/tour">
            <div className="holiday-icon produt-icon" />
            <span className="_icnPttl">Tours</span>
          </Link>
        </div>
        {/* <div className="_innerbx2" id="divGift">
          <a href="">
            <div className="giftcard-icon produt-icon" />
            <span className="_icnPttl">Offers</span>
          </a>
        </div>
        <div className="_innerbx" id="divActivity">
          <a href="">
            <FaSuitcase className="activity-icon produt-icon" />
            <span className="_icnPttl">Bookings</span>
          </a>
        </div>
        <div className="_innerbx" id="divActivity">
          <a href="">
            <FaHeadphones className="activity-icon produt-icon" />
            <span className="_icnPttl">Support</span>
          </a>
        </div> */}
        
        
      {/* </Container> */}
    </section>
  );
};

export default ListProduct;
