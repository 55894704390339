import { combineReducers } from "@reduxjs/toolkit";
import flightReducer from "../slices/flightSlice";
import authReducer from "../slices/auth";
// import flightReducer from "../slices/flightSlice";


const rootReducer = combineReducers({
  flight: flightReducer,
  auth:authReducer
});

export default rootReducer;
