import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./FlightSearchForm.css";
// import WebCheckInForm from "./WebCheckInForm";
import CitySelection from "./CitySelection";
import SelectClass from "./SelectClass";
import TravellerSelection from "./TravellerSelection";
import FlightDatePickerMobile from "./FlightDatePickerMobile";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const FlightSearchForm = () => {
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);

  // const [cal, setCal] = useState(false);
  const [active, setActive] = useState(true);

  const handleSearchFlight = () => {
    setActive(true);
    setActive2(false);
    setActive3(false);
    setEndDate(null);
  };
  const handleSearchFlightRound = () => {
    setActive2(true);
    setActive(false);
    setActive3(false);
  };
  const handleSearchFlightMultiPle = () => {
    setActive3(true);
    setActive(false);
    setActive2(false);
  };

  const [isTravellerInputSelected, setTravellerInputSelected] = useState(false);
  const [isClassInputSelected, setClassInputSelected] = useState(false);
  // const [isWebInputSelected, setWebInputSelected] = useState(false);
  const [isCalenderSelected, setCalenderSelected] = useState(false);
  const [fromCitySelected, setFromCitySelected] = useState(false);
  const [toCitySelected, setToCitySelected] = useState(false);
  const [departureCity, setDepartureCity] = useState({
    code: "DEL",
    name: "Delhi",
  });
  const [arrivalCity, setArrivalCity] = useState({
    code: "BOM",
    name: "Mumbai",
  });

  const handleTravellerInputFocus = () => {
    setTravellerInputSelected(true);
  };
  const handleClassInputFocus = () => {
    setClassInputSelected(true);
  };
  // const handleWebInputFocus = () => {
  //   setWebInputSelected(true);
  // };
  const handleCalenderFocus = (input) => {
    setCalenderSelected(true);
    if (!isCalenderSelected) {
      if (input === "startDate") {
        setFocusedInput("startDate");
      } else if (input === "endDate") {
        setFocusedInput("endDate");
      } else {
        // setStartDate(null);
        // setEndDate(null);
        // setFocusedInput(null);

        setCalenderSelected(!isCalenderSelected);
        if (!isCalenderSelected) setFocusedInput("startDate");
        else setFocusedInput(null);
      }
    }
  };
  // const handleCheckOutFocus = () => {
  //   setCheckOutSelected(true);
  // };
  const handleFromCityClick = () => {
    setFromCitySelected(true);
    // setToCitySelected(false);
  };

  const handleToCityClick = () => {
    // setFromCitySelected(false);
    setToCitySelected(true);
  };

  const closeTravellerInput = () => {
    setTravellerInputSelected(false);
  };
  const closeClassInput = () => {
    setClassInputSelected(false);
  };
  // const closeWebInput = () => {
  //   setWebInputSelected(false);
  // };
  const closeDateInput = () => {
    setCalenderSelected(false);
    // setCheckOutSelected(false);
  };
  const closeCitySelection = () => {
    setFromCitySelected(false);
    setToCitySelected(false);
  };

  const [rooms, setRooms] = useState([{ adults: 2, children: 0, infants: 0 }]);

  const updateRoom = (index, field, value) => {
    const updatedRooms = [...rooms];
    updatedRooms[index][field] = value;
    setRooms(updatedRooms);
  };

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  const navigate = useNavigate();
  const handleFlightSearch = () => {
    const tripTypeMapping = {
      OneWay: 1,
      RoundTrip: 2,
      MultiCity: 3,
    };
    const tripType = tripTypeMapping[!setActive ? "RoundTrip" : "OneWay"];

    const SearchData = {
      EndUserIp: "192.168.10.10",
      TokenId: "47ed8194-4d47-4e3e-b19d-b7e44a7588a5",
      AdultCount: rooms[0].adults,
      ChildCount: rooms[0].children,
      InfantCount: rooms[0].infants,
      JourneyType: tripType,
      Segments: [
        {
          Origin: departureCity.code,
          Destination: arrivalCity.code,
          FlightCabinClass: selectedClass,
          PreferredDepartureTime: startDate
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss"),
          PreferredArrivalTime: endDate
            ? endDate.startOf("day").format("YYYY-MM-DDTHH:mm:ss")
            : startDate
                .add(1, "day")
                .startOf("day")
                .format("YYYY-MM-DDTHH:mm:ss"),
        },
      ],
    };
    console.log("SearchData", SearchData);
    // active &&
    //   navigate(
    //     `/flightList/${encodeURIComponent(`
    //       dest=${SearchData.Segments[0].Destination}|org=${SearchData.Segments[0].Origin}|dep=${SearchData.Segments[0].PreferredDepartureTime}|arr=${SearchData.Segments[0].PreferredArrivalTime}|px=${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}|jt=${SearchData.JourneyType}|cbn=${SearchData.Segments[0].FlightCabinClass}
    //     `)}`
    //   );

    // active2 &&
    //   navigate(
    //     `/round/${encodeURIComponent(`
    //       dest=${SearchData.Segments[0].Destination}|org=${SearchData.Segments[0].Origin}|dep=${SearchData.Segments[0].PreferredDepartureTime}|arr=${SearchData.Segments[0].PreferredArrivalTime}|px=${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}|jt=${SearchData.JourneyType}|cbn=${SearchData.Segments[0].FlightCabinClass}
    //     `)}`
    //   );
    if (active) {
      window.location.assign(
        `/flightList/${encodeURIComponent(
          `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
        )}`
      );
    }

    if (active2) {
      // if (destination1.COUNTRYCODE !== destination2.COUNTRYCODE) {
      //   window.location.assign(
      //     `/international-round/${encodeURIComponent(
      //       `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
      //     )}`
      //   );
      // } else {
        window.location.assign(
          `/round/${encodeURIComponent(
            `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
          )}`
        );
      // }
    }
  };

  const [selectedClass, setSelectedClass] = useState(2); // Initial selected class

  return (
    <div className="cont_pnl">
      <div className="pd20_n">
        <div className="m_rel">
          <h1 className="mb_title">Flights</h1>
          {/* <Link className="chkbtn" onClick={handleWebInputFocus}>
            Web Check-In
          </Link> */}
        </div>

        <div className="sfp-change-trip">
          {/* <ButtonGroup> */}
          <div
            // variant="light"
            onClick={handleSearchFlight}
            className={`sfp-trip ${active ? "active" : ""}`}
          >
            One Way
          </div>
          <div
            // variant="light"
            onClick={handleSearchFlightRound}
            className={`sfp-trip ${active2 ? "active" : ""}`}
          >
            Round Trip
          </div>
          <div
            // variant="light"
            onClick={handleSearchFlightMultiPle}
            className={`sfp-trip ${active3 ? "active" : ""}`}
          >
            MultiCity
          </div>
          {/* </ButtonGroup> */}
          <div className="sfp-trip-layer layer-left"></div>
        </div>

        <div id="divOneWayRT">
          <div className="wid46 fl m_rel" onClick={handleFromCityClick}>
            <div className="lb_bg mgt13 hgt93">
              <div className="top_block">
                <p className="inpttl">From</p>
              </div>
              <div className="form-new">
                <span id="lblDeparture" className="origin_cd">
                  {departureCity.code}
                </span>
                <span id="lblDepart" className="origin_full">
                  {departureCity.name}
                </span>
              </div>
              <i
                className="swap_icn"
                id="SwapImg"
                // onClick={handleSwapMobileSoDes}
              />
            </div>
          </div>
          <div className="wid46 fr" onClick={handleToCityClick}>
            <div className="lb_bg mgt13 hgt93">
              <div className="top_block">
                <p className="inpttl">To</p>
              </div>
              <div className="to-new">
                <span id="lblArrival" className="origin_cd">
                  {arrivalCity.code}
                </span>
                <span id="lblArr" className="origin_full">
                  {arrivalCity.name}
                </span>
              </div>
            </div>
            <div className="clr" />
          </div>

          <div
            controlId="ddate"
            className="wid46 fl mgt13"
            onClick={handleCalenderFocus}
          >
            <div
              className="lb_bg ddateClass"
              id="dvfarecal"
              //   onClick={() => handleGetTextboxName("ddate")}
            >
              <p className="inpttl2">Departure Date</p>
              <input
                type="text"
                readOnly
                className="hl-input2"
                fdprocessedid="tmgf4i"
                // placeholder=
                value={startDate ? startDate.format("MM/DD/YYYY") : ""}
                onClick={handleCalenderFocus}
              />
              <div className="clr" />
            </div>
          </div>

          <div controlId="rdate" className="wid46 fr mgt13">
            <div
              className="retu-date-n"
              id="rdatelbl"
              style={{ display: "block", opacity: active2 ? "1" : "0.4" }}
            >
              {/* Return Date */}
              <div className="lb_bg m_rel">
                <div
                  className="rdateClass"
                  id="rdateFade"
                  //   onClick={() => handleGetTextboxName("rdate")}
                >
                  <p className="inpttl2">Return Date</p>
                  <input
                    type="text"
                    readOnly
                    className="hl-input2 cl-bl hide-ddate round-but1"
                    style={{}}
                    fdprocessedid="975arp"
                    value={endDate ? endDate.format("MM/DD/YYYY") : ""}
                    onClick={() => {
                      handleCalenderFocus("endDate");
                      handleSearchFlightRound();
                    }}
                  />
                  <div className="clr" />
                  <input type="hidden" name="hdn1" id="hdn1" />
                  <input id="hdn" type="hidden" />
                </div>
                <i
                  className="cross_i crs2"
                  id="divCross"
                  style={{}}
                  onClick={handleSearchFlight}
                />
              </div>
            </div>
          </div>

          <div
            controlId="travellers"
            className="wid46 fl mgt13"
            onClick={handleTravellerInputFocus}
          >
            <div className="lb_bg ad-ch-sec">
              <p className="inpttl2">Traveller(s)</p>
              <div className="trvsc">
                <div className="mid_title drpNoTrv">
                  <span id="spnTraveller">
                    {rooms.reduce(
                      (total, room) =>
                        total + room.adults + room.children + room.infants,
                      0
                    )}
                  </span>{" "}
                  <span id="spnTravellerText"> Traveller</span>
                </div>
                <span id="optAdult" style={{ display: "none" }}>
                  1
                </span>
                <span id="optChild" style={{ display: "none" }}>
                  0
                </span>
                <span id="optInfant" style={{ display: "none" }}>
                  0
                </span>
              </div>
              <div className="clr" />
            </div>
          </div>
          {/* Rest of the JSX */}
          <div
            controlId="class"
            className="wid46 fr mgt13"
            onClick={handleClassInputFocus}
          >
            <div className="lb_bg m_rel" id="showCclass">
              <p className="inpttl2" onClick={handleClassInputFocus}>
                Class
              </p>
              <i className="dw_tringle" onClick={handleClassInputFocus} />
              <div className="optclss">
                <Form.Control
                  as="select"
                  id="optClassUl"
                  value={selectedClass}
                  //   onChange={(e) => handleSetCabCookie(e.target.value)}
                  disabled
                >
                  <option value="2">Economy</option>
                  <option value="3">PremiumEconomy</option>
                  <option value="4">Business</option>
                  <option value="6">First</option>
                </Form.Control>
              </div>
              <div className="clr" />
            </div>
          </div>
          {/* Rest of the JSX */}
          <div className="clr" />
          {/* <div className="hg12" />
          <div id="divFamilyFare" style={{}}>
            <Form.Group className="container_lgn">
              <Form.Check
                type="checkbox"
                id="chkArmy"
                label="Defence Forces"
                // onClick={handleCheckFareType}
              />
            </Form.Group>
            <Form.Group className="container_lgn">
              <Form.Check
                type="checkbox"
                id="chkStudent"
                label="Students"
                // onClick={handleCheckFareType}
              />
            </Form.Group>
            <Form.Group className="container_lgn">
              <Form.Check
                type="checkbox"
                id="chkSeniorCitizen"
                label="Senior Citizens"
                // onClick={handleCheckFareType}
              />
            </Form.Group>
            <Form.Group className="container_lgn">
              <Form.Check
                type="checkbox"
                id="chkDoctors"
                label="Doctors Nurses"
                // onClick={handleCheckFareType}
              />
            </Form.Group>
          </div> */}
          <input
            name=""
            defaultValue="Search"
            style={{ WebkitAppearance: "none" }}
            type="button"
            className="fs_btn"
            onClick={handleFlightSearch}
            fdprocessedid="5e85fe"
          />
        </div>
      </div>

      {/* {isWebInputSelected && <WebCheckInForm closeWebInput={closeWebInput} />} */}

      {fromCitySelected && (
        <CitySelection
          // handleCitySelection={(code, name) => {
          //   // Handle selection for "From" city
          //   setDepartureCity(code, name);
          // }}
          code={departureCity.code}
          name={departureCity.name}
          setCity={setDepartureCity}
          closeCitySelection={closeCitySelection}
        />
      )}

      {toCitySelected && (
        <CitySelection
          // handleCitySelection={(code, name) => {
          //   // Handle selection for "To" city
          //   setArrivalCity(code, name);
          // }}
          code={arrivalCity.code}
          name={arrivalCity.name}
          setCity={setArrivalCity}
          closeCitySelection={closeCitySelection}
        />
      )}

      {isClassInputSelected && (
        <SelectClass
          closeClassInput={closeClassInput}
          setClass={setSelectedClass}
        />
      )}

      {isCalenderSelected && (
        <FlightDatePickerMobile
          closeDateInput={closeDateInput}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          active2={active2}
          focusedInput={focusedInput}
          setFocusedInput={setFocusedInput}
        />
      )}

      {isTravellerInputSelected && (
        <TravellerSelection
          rooms={rooms}
          updateRoom={updateRoom}
          closeTravellerInput={closeTravellerInput}
        />
      )}
    </div>
  );
};

export default FlightSearchForm;