import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
const SliderCode = () => {
  var settings = {
    dots: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1500,
    infinite: true,
    speed: 100,
    // nextArrow: <NextBtn />,
    // prevArrow: <PreviousBtn />,
    slidesToShow: 1,
    slidesToScroll: 1,
    // responsive: [
    //     {
    //         breakpoint: 1024,
    //         settings: {
    //             slidesToShow: 1,
    //             slidesToScroll: 3,
    //             infinite: true,
    //             dots: true
    //         }
    //     },
    //     {
    //         breakpoint: 600,
    //         settings: {
    //             slidesToShow: 1,
    //             slidesToScroll: 2,
    //             initialSlide: 2
    //         }
    //     },
    //     {
    //         breakpoint: 480,
    //         settings: {
    //             slidesToShow: 1,
    //             slidesToScroll: 1
    //         }
    //     }
    // ]
  };

  const sliderItems = [
    {
      id: 1,
      imgSrc: "https://www.alhindholidays.com/assets/img/visit-saudi.jpg",
      title: "Visit Saudi",
      link: "/tour/saudi",
    },
    {
      id: 2,
      imgSrc: "https://www.alhindholidays.com/assets/img/explore-kerala.jpg",
      title: "Explore Kerala",
      link: "/tour/kerala",
    },
    {
      id: 3,
      imgSrc: "https://www.alhindholidays.com/assets/img/visit-saudi.jpg",
      title: "Visit Saudi",
      link: "/tour/saudi",
    },
    {
      id: 4,
      imgSrc: "https://www.alhindholidays.com/assets/img/visit-saudi.jpg",
      title: "Visit Saudi",
      link: "/tour/saudi",
    },
  ];

  return (
    <div style={{ marginTop: "50px" }}>
      <Container>
        <Slider {...settings}>
          {sliderItems.map((item) => (
            <div className="item" key={item.id}>
              <Link to={item.link}>
                <img
                  src={item.imgSrc}
                  className="rounded img-fluid"
                  alt={item.title}
                />
                <div className="overlay">
                  <div className="overlay-content">
                    <h4 className="view-button">{item.title}</h4>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  );
};

export default SliderCode;
