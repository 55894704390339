import React from "react";
import { Link } from "react-router-dom";
import "./Reuse.css";
const Reuse = ({ image, day, night, price, detail, nam }) => {
  return (
    <div className="">
      <div className="mainReuse">
        <div className="reuseRelative">
          <div className="">
            <Link>
              <img src={image} alt="" className="ReuseTourPackageImage " />
            </Link>
          </div>
          <div className="reuseSecMain">
            <h2 className="reuseSecMainhead1">HISTORIC</h2>
            <h2 className="reuseSecMainhead2">
              {day}D/{night}N
            </h2>
          </div>
        </div>
        <div className="reuseSecMain2">
          <h2 className="reuseSecMain2Head1">{nam}</h2>
          <h2 className="reuseSecMain2Head2">{detail}</h2>
          <h2 className="reuseSecMain2Head3">
            From <span className="text-[#9dc541]">₹ {price}</span>/per person
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Reuse;
