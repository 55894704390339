import React from "react";
// import TopHeader from "./TopHeader";
import ListProduct from "./ListProduct";
import SearchForm from "./SearchForm";
import HotelsContainer from "../HotelComponent";
import { Container } from "react-bootstrap";
import WhyUs from "../../../Home/Home/WhyUs";
import SliderCode from "../../../Home/Home/SliderCode";
import HolidayPackages from "../../../Home/Home/HolidayPackage";
// import AppDownloadBanner from "./AppDownloadBanner";
// import LastCards from "./LastCards";
// import PopularDestinations from "./PopularDestinations";
// import OffersAndDeals from "./OffersAndDeals";
// import AutoSuggest from './AutoSuggest'
// import DatePickerComponent from './DatePickerComponent'
// import RoomSelectionComponent from './RoomSelectionComponent'
// import Slider from "react-slick";
// import Reuse from "../../data/Reuse";
// import { Card, Col, Container, Row } from "react-bootstrap";
// import { FaWallet } from "react-icons/fa";
// import { BiSolidOffer } from "react-icons/bi";
// import { data, settings } from "../HotelSearchData";
// import Reuse from "../../../data/Reuse";

const HotelSearchMobile = () => {
  return (
    <div id="hotelSearch" className="hotelsearchmobile">
      {/* <TopHeader heading="Hotel Search" showNationality={false} /> */}
      <ListProduct active="hotel" />
      <SearchForm />
      {/* <AppDownloadBanner/>
        <OffersAndDeals/> */}
      {/* <Container>
        <Row>
          <Col md={12} className="resp-mt-20">
            <Card
              className="dashboardbox dashboxcolor2"
              style={{ marginBottom: "5px" }}>
              <Card.Body style={{ padding: "0px" }}>
                <h2 className="boxheading boxheadcolor2">
                  My Wallet
                  <FaWallet style={{ float: "right", fontSize: "18px" }} />
                </h2>
                <div className="dashinnerbox">
                  <ul className="creditlist">
                    <li>
                      <label>Profile Credit Limit:</label> USD 0.00
                    </li>
                    <li>
                      <label>Wallet Balance:</label> USD 0.00
                    </li>
                  </ul>
                </div>
              </Card.Body>
              <Card.Footer
                className="text-muted"
                style={{ paddingInline: "5px" }}>
                <Card.Text
                  style={{
                    color: "white",
                    fontSize: "14px",
                    textWrap: "wrap",
                    overflow: "ellipsis",
                  }}>
                  Available Credit Limit:{" "}
                  <span className="float-right">USD 0.00</span>
                </Card.Text>
              </Card.Footer>
            </Card>
          </Col>

          <Col>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                marginTop: "15px",
              }}>
              <BiSolidOffer size={22} style={{ color: "#2d3290" }} />
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                }}>
                {" "}
                More Offer
              </div>
            </div>
            <Slider {...settings} className="mb-3">
              {data.map((item, id) => (
                <Reuse key={id} url={item.img} />
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
      <PopularDestinations />
      <LastCards /> */}

      {/* <AutoSuggest/> */}
      {/* <DatePickerComponent/> */}
      {/* <RoomSelectionComponent/> */}
      <HotelsContainer/>
      <Container>
        <WhyUs/>
        {/* <SliderCode/> */}
        <HolidayPackages/>
      </Container>
    </div>
  );
};

export default HotelSearchMobile;
