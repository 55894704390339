import React, { useEffect, useState } from "react";
import "./DetailPage.css";
import DetailPageReuse from "./DetailPageReuse";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
const DetailPage = () => {
  //   const [sliderValue, setSliderValue] = useState([180, 3000]);
  const { pack } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        `https://tripoholidays.in/api/destinations/${pack}`
      );
      if (res) {
        setData(res.data.data);
      }
    };
    fetchData();
  }, []);
  console.log("data", data);
  return (
    <Container className="containerss">
      <div className="DestinationDetailMainPage">
        <div className="DestinationDetailMainDiv ">
          <div className="DestinationDetailMainDivHead border shadow-md">
            <h2
              className=""
              style={{
                color: "#002543",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {pack}
            </h2>
            <p
              className=""
              style={{
                fontSize: "14px",
                lineHeight: "27px",
                wordSpacing: "2px",
              }}
            >
              Embark on an unforgettable journey with Lelotrip Tour & Travels as
              we take you on a magical adventure through the heart of Europe.
              Our carefully curated Europe tour packages offer you the
              opportunity to explore the continent's rich history, diverse
              cultures, stunning landscapes, and iconic landmarks. Whether
              you're a history enthusiast, a foodie, or simply seeking a
              romantic getaway, Europe has something to offer everyone, and
              we're here to make your dream vacation a reality.
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <div className="DestinationDetailMailFilter">
              <div>
                <h2 className="DestinationDetailMailFilterHead1 ">Filter</h2>
              </div>
              {/* <div>
                <h2 className="DestinationDetailMailFilterHead2">FLIGHTS </h2>
                <div>
                  <fieldset>
                    <div className="DestinationDetailMailFilterInput">
                      <input type="checkbox" />
                      <label htmlFor="">With Flight</label>
                    </div>
                    <div className="DestinationDetailMailFilterInput ">
                      <input type="checkbox" />
                      <label htmlFor="">Without Flight</label>
                    </div>
                  </fieldset>
                </div>
              </div> */}
              <div>
                <h2 className="DestinationDetailMailFilterHead2">
                  HOLIDAY TYPE
                </h2>
                <fieldset>
                  <div className="DestinationDetailMailFilterInput">
                    <input type="checkbox" />
                    <label htmlFor="">Fixed</label>
                  </div>
                  <div className="DestinationDetailMailFilterInput">
                    <input type="checkbox" />
                    <label htmlFor="">Customized</label>
                  </div>
                  <div className="DestinationDetailMailFilterInput">
                    <input type="checkbox" />
                    <label htmlFor="">Group</label>
                  </div>
                </fieldset>
              </div>
              <div className="DestinationDetailMailFilterHead3 ">
                <h2 className="DestinationDetailMailFilterHead2">
                  DESTINATION
                </h2>
                <input
                  type="text"
                  placeholder="Enter Destinations"
                  className="DestinationDetailMailFilterInput2 "
                />
              </div>
              <div className="text-center">
                <button className="DestinationDetailMailFilterSearch">
                  Search
                </button>
              </div>
            </div>
            <div className="DestinationDetailMainDiv2">
              <div className="DestinationDetailMainDivInner1">
                <p>
                  Sort by: &nbsp;
                  <span>
                    <select
                      name=""
                      id=""
                      className="DestinationDetailMainDivInner1sel"
                    >
                      <option value="">1</option>
                      <option value="">2</option>
                      <option value="">3</option>
                      <option value="">4</option>
                    </select>
                  </span>
                </p>
              </div>
              {/* <EuropeReuse /> */}
              <DetailPageReuse data={data} />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DetailPage;
