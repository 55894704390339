import React, { useState } from "react";
import { FaArrowAltCircleDown, FaStar } from "react-icons/fa";
import "./Itenary.css";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";

const Iternary = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0);

  const toggleTab = (tab) => {
    if (activeTab === tab) {
      setActiveTab(0);
    } else {
      setActiveTab(tab);
    }
  };
  return (
    <div className=" mt-8 ">
      <div>
        <div className="intineraryMainDiv mt-4 mb-5" id="ltinerary">
          <h2 className="intineraryMainhead text-[18px] font-[600] mb-4">
            Itenirary
          </h2>
          <div>
            {data.packagedetail.package_itinerary.map((item, id) => (
              <div className="itinerarysecDiv" key={id}>
                <button
                  className="itinerarysecDivbtn1"
                  onClick={() => toggleTab(item.id)}
                >
                  {/* <div className="itinerarysecDIvMain "> */}
                  <div className="itinerarysecDiv2">
                    <div style={{display:'flex'}}>
                      <span
                        className="itinerarysecDiv2para1"
                        style={{ marginRight: "10px" }}
                      >
                        Day {id + 1}
                      </span>
                      <span className="itinerarysecDiv2para2">{item.title}</span>
                    </div>
                    <div>
                      {activeTab === item.id ? (
                        <FiMinusCircle />
                      ) : (
                        <FiPlusCircle />
                      )}
                    </div>
                  </div>
                  <div>
                    {/* <RiArrowDropDownLine size={25} color="#002543" /> */}
                    {/* </div> */}
                  </div>
                </button>
                {activeTab === item.id && (
                  <div className="mt-2">
                    <p
                      className="itinerarysecDiv2para3"
                      dangerouslySetInnerHTML={{ __html: item.details }}
                    ></p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div id="inclusion">
          <h2 className="itineraryInclusion">Inclusions</h2>
          <ul className="itineraryInclusionUnder">
            {data.packagedetail.package_inclusions.map((item) => (
              <div className="itineraryInclusionInnerDiv">
                <span className=""> ✔ </span>
                <li dangerouslySetInnerHTML={{ __html: item }}></li>
              </div>
            ))}
          </ul>
        </div>
        <div id="exlcusion">
          <h2 className="itineraryInclusion">Exclusion</h2>

          <ul className="itineraryInclusionUnder">
            {data.packagedetail.package_exclusions.map((item) => (
              <div className="itineraryInclusionInnerDivs">
                <span className=""> ⨯ </span>
                <li dangerouslySetInnerHTML={{ __html: item }}></li>
              </div>
            ))}
          </ul>
        </div>
        <div className="itineraryHotelDetailMainHead" id="Hotels">
          <h2 className="itineraryHotelDetailHead">Hotel Details</h2>
          <div className="itineraryHotelDetailMain">
            <div className="itineraryHotelDetailMain2">
              <img
                src="https://tripoholidays.in/img/media_gallery/8549_rokgc_00_p_1024x768_NHPP2bxAPw.jpg"
                className="ItineraryHotelDetailMainImage"
                alt=""
              />
            </div>
            <div className="itineraryHotelDetailMain3">
              <div className="itineraryHotelDetailMain3Inner">
                <h3 className="itineraryHotelDetailMain3Innerh3 ">
                  Ibis Styles lucerne
                  <p>or similar</p>
                </h3>
                <p className="itineraryHotelDetailpara1">Include a trip</p>
              </div>
              <div className="itineraryHotelDetailStar">
                <FaStar size={13.5} color="#f5b800" />
                <FaStar size={13.5} color="#f5b800" />
                <FaStar size={13.5} color="#f5b800" />
              </div>
              <div>
                <p className="itineraryHotelDetailpara3">
                  <span style={{ fontWeight: "600" }}>Address: </span>{" "}
                  Friedenstrasse 8, 6004 Luzern, Switzerland
                </p>
              </div>
              <div style={{ textAlign: "end", width: "100%" }}>
                <p className="itineraryHotelDetailparaIn">Include a trip</p>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="itineraryHotelDetailMainHead">
          <h2 className="itineraryHotelDetailHead">Hotel Details</h2>
          <div className="itineraryHotelDetailMain">
            <div className="itineraryHotelDetailMain2">
              <img
                src="https://tripoholidays.in/img/media_gallery/8549_rokgc_00_p_1024x768_NHPP2bxAPw.jpg"
                // width={227}
                alt=""
                className="ItineraryHotelDetailMainImage"
              />
            </div>
            <div className="itineraryHotelDetailMain3">
              <div className="itineraryHotelDetailMain3Inner">
                <h3 className="itineraryHotelDetailMain3Innerh3 ">
                  Ibis Styles lucerne
                  <p>or similar</p>
                </h3>
                <p className="itineraryHotelDetailpara1">Include a trip</p>
              </div>
              <div className="itineraryHotelDetailStar">
                <FaStar size={13.5} color="#f5b800" />
                <FaStar size={13.5} color="#f5b800" />
                <FaStar size={13.5} color="#f5b800" />
              </div>
              <div>
                <p className="itineraryHotelDetailpara3">
                  <span className="font-semibold">Address: </span>{" "}
                  Friedenstrasse 8, 6004 Luzern, Switzerland
                </p>
              </div>
              <div style={{ textAlign: "end", width: "100%" }}>
                <p className="itineraryHotelDetailparaIn">Include a trip</p>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div id="term-condition">
          <h2 className="itineraryHotelDetailHead">Our Policy</h2>
          <div>
            <ul
              className="itineraryInclusionUnderss"
              style={{ listStyleType: "circle" }}
            >
              {data.tour_policy.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <h2 className="itineraryHotelDetailHead">Cancelation Policy</h2>
          <div>
            <ul
              className="itineraryInclusionUnderss"
              style={{ listStyleType: "circle" }}
            >
              {data.cancelation_policy.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <h2 className="itineraryHotelDetailHead">Payment Policy</h2>
          <div>
            <ul
              className="itineraryInclusionUnderss"
              style={{ listStyleType: "circle" }}
            >
              {data.payment_policy.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Iternary;
