import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Spinner,
} from "react-bootstrap";
import { FaUser, FaKey, FaUserTie } from "react-icons/fa";
import "./LoginFirst.css";
import { Link, useNavigate } from "react-router-dom";
import SignUp from "./SignUp";
import ViewCancelReschedule from "./ViewCancelReschedule";
import { useAuth } from "../../context/AuthContext";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import { Wallentbalance, login } from "../../../../redux/services/operations/auth";

const LoginFirst = () => {
  const [UserName, setUserName] = useState("");
  const [Password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  //   const { loading } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  //   const dispatch = useDispatch();

  const { login } = useAuth(); // Get login function from AuthContext

  const handleLogin = async () => {
    // dispatch(login(UserName, Password, navigate));
    // dispatch(Wallentbalance(UserName,Password, navigate))
    // navigate('/agent/dashboard')

    // Simple mock validation, you can replace it with your actual login logic
    // if (UserName === "admin" && Password === "password") {
      login(); // Set user as authenticated
      // navigate("/dashboard"); // Navigate to the protected route
    // } else {
    //   alert("Invalid credentials, please try again.");
    // }
  };

  const handleOtpToggle = () => {
    setShowOtp(!showOtp);
  };

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="travelagent">
      <Container>
        <Row className="d-flex align-items-center flex_wrap">
          {/* <Col md={6}>
            <h1 className="text-white" style={{ fontSize: "36px" }}>
              <b>Welcome to Abasinexpress B2B Portal</b>
            </h1>
            <p className="text-white">
              Welcome to Abasinexpress, your gateway to seamless and efficient
              travel management. Our B2B portal is designed to elevate your
              travel experience, providing a usessr-friendly platform for travel
              professionals like you.
            </p>
            <Button
              as={Link}
              style={{ marginTop: "20px" }}
              to="/agent-registration"
              className="btn btn-primary"
            >
              Register with us
            </Button>
          </Col> */}
          <Col md={6} className="col_xs_480 floatrgt_lg">
            <div className="agent_form">
              <form
                method="POST"
                // action="https://heritage.travelsdata.com/agent/login"
                acceptCharset="UTF-8"
                name="admin_login"
                autoComplete="off"
              >
                <input
                  name="_token"
                  type="hidden"
                  value="OOXcavt5rXm9WUguAc6TjZyQBDng1kOKbQCm66Sm"
                />
                <h1>Login or Create an account</h1>
                <div className="inner_form_field">
                  <div className="form-group">
                    <div
                      className="radio"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        columnGap: "15px",
                      }}
                    >
                      <label className="label-container radio-default">
                        <input
                          name="remember"
                          type="radio"
                          value=""
                          defaultChecked={true}
                        />{" "}
                        Agent <span className="checkmark input"></span>
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      <span className="input-group-addon">
                        <FaUser className="fas" />
                      </span>
                      <input
                        autoComplete="off"
                        type="text"
                        name="username"
                        value={UserName}
                        onChange={(e) => setUserName(e.target.value)}
                        className="form-control input"
                        id="username"
                        placeholder="Email ID / Phone Number"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      <span className="input-group-addon">
                        <FaKey className="fa" />
                      </span>
                      <input
                        value={Password}
                        autoComplete="off"
                        type={showOtp ? "text" : "password"}
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control input"
                        id="password"
                        placeholder="Password"
                      />
                      <div
                        onClick={handleOtpToggle}
                        className="pass_eyeswitch lgpassicon"
                      >
                        <i
                          className={showOtp ? "fa fa-eye" : "fa fa-eye-slash"}
                        ></i>
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <div className="checkbox">
                      <label className="label-container checkbox-default">
                        <input
                          name="remember"
                          type="checkbox"
                          value={rememberMe}
                          onChange={(e) => setRememberMe(e.target.checked)}
                          className="input"
                        />{" "}
                        Remember me <span className="checkmark"></span>
                      </label>
                    </div>
                  </div> */}
                  {/* {showOtp && ( */}
                  <div className="form-group d-none" id="otp_div">
                    <div className="input-group">
                      <span className="input-group-addon">
                        <i className="fa fa-key icon"></i>
                      </span>
                      <input
                        value={otp}
                        autoComplete="off"
                        type="text"
                        name="otp"
                        onChange={(e) => setOtp(e.target.value)}
                        className="form-control input"
                        id="otp"
                        placeholder="OTP"
                      />
                    </div>
                  </div>
                  {/* )} */}
                  <div className="form_btn">
                    <Button
                      type="button"
                      className="btn verify_button input"
                      name="submit"
                      onClick={handleLogin}
                      //   disabled={loading}
                    >
                      {/* {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : ( */}
                      Login
                      {/* )} */}
                    </Button>
                    {/* <input
                      type="submit"
                      className="btn submit_btn"
                      name="submit"
                      value="Login"
                      style={{ display: "none" }}
                    /> */}
                  </div>
                  <div className="cus_txt">
                    <p>
                      Don’t have any account?{" "}
                      <Link onClick={handleShow}>Sign Up Here</Link>
                    </p>
                    <p>
                      Forgot your password?{" "}
                      <Button
                        variant="link"
                        style={{
                          padding: "0px",
                          textDecoration: "none",
                          color: "#2d3290",
                          fontSize: "inherit",
                        }}
                        // href="#"
                        // data-toggle="modal"
                        // data-target="#forget_password_modal"
                        onClick={handleShowModal}
                      >
                        Forgot Password
                      </Button>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </Col>

          <Col md={6} className="cancel_col">
            <ViewCancelReschedule />
          </Col>
        </Row>
      </Container>

      <SignUp
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        handleShow={handleShow}
      />

      {/* Forgot Password Modal */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="md"
        className="forgotpasswordmodal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Change your Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <div className="travelagent">
              <div className="agent_form">
                <div className="input-group">
                  <span className="input-group-addon">
                    <FaUserTie />
                  </span>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email ID"
                    autoComplete="off"
                  />
                </div>
                <div className="form_btn">
                  <Button type="submit" className="btn submit_btn">
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LoginFirst;
