import React, { useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Button,
  Modal,
  Form,
  Image,
  Row,
  Col,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import Lelo from "./logo-lelotrip.png";
import "./MainNav.css";
// import "./LoginModal.css";
import Handles from "rc-slider/lib/Handles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Autoplay, Navigation } from "swiper/modules";
const SignUp = ({ show, setShow, handleClose, handleShow }) => {
  const settings = {
    dots: true,
    infinite: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 1500,
    arrows: true,
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="p-fixed l-0 r-0 b-0 t-0 flex flex-center flex-middle z-70 signup_modal"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.4)", zIndex: 10000 }}
      size="lg"
    >
      <Modal.Body>
        <div
          className="p-fixed l-0 r-0 b-0 t-0 flex flex-center flex-middle z-70"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.4)", zIndex: "10000" }}
        >
          <div className="p-relative">
            <div>
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <div
                  className="o-hidden flex-column brLogin-4 slick-dots slider_part"
                  style={{ width: "386px", height: "500px" }}
                >
                  <Swiper
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    navigation={true}
                    modules={[Navigation, Autoplay]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <img
                        src="https://promos.makemytrip.com/notification/xhdpi/Roadblock-DilMaange-386x540-5Aug.jpg"
                        alt=""
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div
                  className="bg-white o-hidden d-flex flex-column brLoginNew-4 signup_form"
                  style={{ width: "400px", height: "500px" }}
                >
                  <div className="px-8 d-flex flex-1 flex-column">
                    <div className="pt-6 pb-6 flex flex-top flex-between">
                      <div className="flex flex-column">
                        <div className="fw-600 c-primary-0 lpf-40"></div>
                      </div>
                      <div
                        className="px-1 flex flex-middle nmx-1 pb-1"
                        style={{ borderRadius: "14px" }}
                        onClick={handleClose}
                      >
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          className="c-pointer c-neutral-900"
                        >
                          <path
                            d="M18 6L12 12M12 12L6 18M12 12L6 6M12 12L18 18"
                            stroke="#1A1A1A"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div className="d-flex flex-1 flex-between flex-column">
                      <div>
                        <Row>
                          <Col xs={6}>
                            <div className="p-relative">
                              <button
                                className="flex flex-middle flex-between t-all fs-2 focus:bc-secondary-500 bg-transparent bc-neutral-100 c-pointer pr-2 pl-3 pt-2 pb-2 ba br-4 h-8 h-9 p-0 px-2 fs-3 country_code"
                                style={{
                                  //  minWidth: "80px",
                                  minHeight: "44px",
                                }}
                              >
                                <div className="fs-2 c-inherit flex flex-nowrap">
                                  +91
                                </div>
                                <svg
                                  width="14"
                                  height="9"
                                  fill="currentColor"
                                  className="t-all ml-2"
                                  style={{
                                    transform: "rotate(-180deg)",
                                    color: "rgb(153, 153, 153)",
                                  }}
                                >
                                  <g fill="none" fillRule="evenodd">
                                    <path d="M15 12H-1V-4h16z"></path>
                                    <path
                                      stroke="#FFF"
                                      strokeWidth="0.5"
                                      fill="currentColor"
                                      d="M11.59 8L7 3.42 2.41 8 1 6.59l6-6 6 6z"
                                    ></path>
                                  </g>
                                </svg>
                              </button>
                            </div>
                          </Col>
                          <Col>
                            <div className="p-relative">
                              <Form.Control
                                className="field bw-1 bs-solid w-100p p-2 box-border br-4 fs-2 c-neutral-900 h-9 fs-3 bc-neutral-100 c-neutral-900 focus:bc-secondary-500"
                                data-testid="Mobile"
                                type="text"
                                placeholder="Enter mobile number"
                                style={{ minWidth: "80px", minHeight: "44px" }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <div
                          className="m-0 mt-0 mb-0 ml-0 mr-0 mx-0 my-0 mt-6 margin-modalbooking"
                          style={{ height: "1px", width: "1px" }}
                        ></div>
                        <Button className="h-10 bg-black-500 hover:bg-black-500 bg-secondary-500 hover:bg-secondary-600 c-white bc-transparent c-pointer w-100p py-2 px-4 h-9 fs-4 fw-600 t-all button bs-solid tp-color td-500 bw-1 br-4 lh-solid box-border">
                          <span className="fs-3 fw-500">Get OTP</span>
                        </Button>
                        <div
                          className="mt-3 fs-12 fw-400 c-neutral-grey ta-center"
                          style={{ marginTop: "20px" }}
                        >
                          We no more support email-based login. You can now
                          login via mobile number & link email to access your
                          account.
                        </div>
                      </div>
                      <div className="mb-5">
                        <div className="pos-r">
                          <div className="bc-grey-10 d-block bb bc-grey-10 flex-1"></div>
                        </div>
                        <div className="mt-5 d-flex flex-column flex-middle">
                          <span>
                            <span className="fs-2 c-grey-70">
                              By continuing, you agree to Lelotrip Tour & Travels's
                            </span>
                            <span className="fs-2 fw-500 c-blue c-pointer">
                              {" "}
                              privacy policy
                            </span>
                            <span className="fs-2 c-grey-70"> & </span>
                          </span>
                          <span>
                            <span className="fs-2 fw-500 c-blue c-pointer">
                              terms of use.
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      {/* </div> */}
    </Modal>
  );
};

export default SignUp;
