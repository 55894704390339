import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FaAngleDown, FaSyncAlt } from "react-icons/fa";
import FlightDetailSide from "./FlightDetailSide";
import { HiMiniMinusSmall } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";
import FlightPayDetail from "./FlightPayDetail";
import Emi from "./Emi";

const ChargesOneWay = ({
  srdvIdx,
  flight,
  flight2,
  setShowdetail,
  showdetail,
  handleChangeCurrency,
  handleChangeCurrency2,
  walletData,
  setOpenPayBtn,
  openPayBtn,
  setPaymentGateway,
  paymentGateway,
  setEmiBtn,
  emiBtn,
  passengerSeatPreferences,
  passengerMealPreferences,
  passengerBaggagePreferences,
  validatePassengerData,
}) => {
  let totalMealCharges = passengerMealPreferences.reduce((total, meal) => {
    if (meal && meal.Price) {
      total += meal.Price;
    }
    return total;
  }, 0);

  let totalMealWithCharges =
    (flight && srdvIdx === "undefined" && flight.Fare.TotalMealCharges) +
    totalMealCharges;

  let totalSeatCharges = passengerSeatPreferences.reduce((total, meal) => {
    if (meal && meal.Price) {
      total += meal.Price;
    }
    return total;
  }, 0);

  let totalSeatWithCharges =
    (flight && srdvIdx === "undefined" && flight.Fare.TotalSeatCharges) +
    totalSeatCharges;

  let totalBaggageCharges = passengerBaggagePreferences.reduce(
    (total, meal) => {
      if (meal && meal.Price) {
        total += meal.Price;
      }
      return total;
    },
    0
  );

  let totalBaggageWithCharges =
    (flight && srdvIdx === "undefined" && flight.Fare.TotalBaggageCharges) +
    totalBaggageCharges;

  const handleFareRecheck = () => {
    // console.log("fare recheck clicked");
  };

  const navigate = useNavigate();
  // console.log("flightchargesoneway", flight2);

  console.log("openpaybtn2", openPayBtn);
  console.log("paymentgateway2", paymentGateway);
  return (
    <>
      {srdvIdx === "undefined" && (
        <Col md={3}>
          <Row>
            <Col md={12} style={{}}>
              <p
                style={{
                  marginBottom: "10px",
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  padding: "5px",
                }}
                onClick={() => handleFareRecheck()}
              >
                {" "}
                Fare Recheck <FaSyncAlt style={{ marginLeft: "5px" }} />
              </p>
            </Col>
            <Col md={12}>
              {flight ? (
                <div className="flightBookingsecDiv">
                  <Row className="flightBookingSecDivRow3">
                    <Col xs={12} md={12}>
                      <span
                        className="lbl"
                        onClick={() => setShowdetail(!showdetail)}
                      >
                        Base Fare (
                        {flight.FareBreakdown.reduce(
                          (total, item) => total + item.PassengerCount,
                          0
                        )}{" "}
                        Travellers)
                      </span>{" "}
                      <FaAngleDown onClick={() => setShowdetail(!showdetail)} />{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}  */}INR
                        {handleChangeCurrency(flight.Fare.BaseFare)}
                      </span>
                    </Col>
                  </Row>
                  {showdetail && (
                    <Row
                      className="flightBookingSecDivRow3"
                      style={{ width: "90%", marginInline: "auto" }}
                    >
                      <Col xs={12} md={12}>
                        <span className="lbl">
                          {flight.FareBreakdown[0].PassengerCount} x Adult
                        </span>{" "}
                        <span className="val">
                          {/* {flight.FareBreakdown[0].Currency} */}INR
                          {handleChangeCurrency(
                            flight.FareBreakdown[0].BaseFare
                          )}
                        </span>
                      </Col>
                      {flight.FareBreakdown[1] && (
                        <Col xs={12} md={12}>
                          <span className="lbl">
                            {flight.FareBreakdown[1].PassengerCount} x Children
                          </span>{" "}
                          <span className="val">
                            {/* {flight.FareBreakdown[1].Currency} */}INR
                            {handleChangeCurrency(
                              flight.FareBreakdown[1].BaseFare
                            )}
                          </span>
                        </Col>
                      )}
                      {flight.FareBreakdown[2] && (
                        <Col xs={12} md={12}>
                          <span className="lbl">
                            {flight.FareBreakdown[2].PassengerCount} x Infants
                          </span>{" "}
                          <span className="val">
                            {/* {flight.FareBreakdown[2].Currency} */}INR
                            {handleChangeCurrency(
                              flight.FareBreakdown[2].BaseFare
                            )}
                          </span>
                        </Col>
                      )}
                    </Row>
                  )}
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Taxes & Surcharges</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency} */}INR
                        {handleChangeCurrency(flight.Fare.Tax)}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Discount</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency} */}INR
                        {handleChangeCurrency(flight.Fare.Discount)}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Service Fee</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}  */}INR
                        {handleChangeCurrency(flight.Fare.ServiceFee)}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Excess Baggage (0KG )</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}{" "} */}INR
                        {handleChangeCurrency(totalBaggageWithCharges)}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Meal (0)</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}{" "} */}INR
                        {handleChangeCurrency(totalMealWithCharges)}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Seat Charges</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}{" "} */}INR
                        {handleChangeCurrency(totalSeatWithCharges)}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    className="flightBookingSecDivRow2"
                    style={{ paddingBlock: "8px" }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Total Fare</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}{" "} */}INR
                        {handleChangeCurrency(
                          flight.Fare.PublishedFare -
                            flight.Fare.Discount +
                            flight.Fare.ServiceFee +
                            totalBaggageWithCharges +
                            totalMealWithCharges +
                            totalSeatWithCharges
                        )}
                      </span>
                    </Col>
                  </Row>
                  <Row className="flightBookingsecDivRow">
                    <Col xs={12} md={12}>
                      <span className="lbl">You Pay:</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}{" "} */}INR
                        {handleChangeCurrency(
                          flight.Fare.PublishedFare -
                            flight.Fare.Discount +
                            flight.Fare.ServiceFee +
                            totalBaggageWithCharges +
                            totalMealWithCharges +
                            totalSeatWithCharges
                        )}
                      </span>
                    </Col>
                  </Row>
                </div>
              ) : (
                <FlightDetailSide />
              )}
            </Col>
            <Col md={12} className="resp-mt-20">
              <Card
                className=" "
                style={{ marginBottom: "5px", marginTop: "10px" }}
              >
                <Card.Body style={{ padding: "0px" }}>
                  <h2
                    className="boxheading walletMainDiv"
                    style={{
                      background: "#f4f4f4",
                      color: "black",
                      paddingInline: "15px",
                      textAlign: "center",
                      marginBottom: "0px",
                    }}
                  >
                    Fare has been reduced, Wanna Search Again?
                  </h2>
                  <div className="dashinnerboxs">
                    <div
                      // style={{ width: "100%" }}
                      onClick={() => {
                        // setOpenPayBtn(!openPayBtn);
                        // handleTicketBook();
                        navigate(-1);
                      }}
                    >
                      {" "}
                      <button className="ewalletbtn">Search Again</button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* {flight ? (
            <Row style={{ marginTop: "15px" }}>
              {walletData.Wallet >
              flight.Fare.PublishedFare -
                flight.Fare.Discount +
                flight.Fare.ServiceFee +
                totalBaggageWithCharges +
                totalMealWithCharges +
                totalSeatWithCharges ? (
                <Col md={12} className="resp-mt-20">
                  <Card
                    className="dashboardbox "
                    style={{ marginBottom: "5px" }}
                  >
                    <Card.Body style={{ padding: "0px" }}>
                      <h2
                        className="boxheading walletMainDiv"
                        style={{
                          background: "#f4f4f4",
                          color: "black",
                          paddingInline: "15px",
                        }}
                      >
                        Pay using eWallet
                      </h2>
                      <div className="dashinnerboxs">
                        <div
                          className="ewalletMainDiv"
                          style={{ borderBottom: "1px solid #e4e4e4" }}
                        >
                          <p>Current Balance </p>
                          <p>INR{handleChangeCurrency(walletData.Wallet)}</p>
                        </div>
                        <div
                          className="ewalletMainDiv"
                          style={{ borderBottom: "1px solid #e4e4e4" }}
                        >
                          <p>Flight Amount </p>
                          <p>
                            INR
                            {handleChangeCurrency(
                              flight.Fare.PublishedFare -
                                flight.Fare.Discount +
                                flight.Fare.ServiceFee +
                                totalBaggageWithCharges +
                                totalMealWithCharges +
                                totalSeatWithCharges
                            )}
                          </p>
                        </div>
                        <div
                          className="ewalletMainDiv"
                          style={{ borderBottom: "1px solid #e4e4e4" }}
                        >
                          <p>Amount to deduct </p>
                          <p>
                            <HiMiniMinusSmall />INR
                            {handleChangeCurrency(
                              flight.Fare.PublishedFare -
                                flight.Fare.Discount +
                                flight.Fare.ServiceFee +
                                totalBaggageWithCharges +
                                totalMealWithCharges +
                                totalSeatWithCharges
                            )}
                          </p>
                        </div>
                        <div
                          className="ewalletMainDiv"
                          style={{ borderBottom: "1px solid #e4e4e4" }}
                        >
                          <p>Remaining Balance </p>
                          <p>
                          INR
                            {handleChangeCurrency(
                              walletData.Wallet -
                                (flight.Fare.PublishedFare -
                                  flight.Fare.Discount +
                                  flight.Fare.ServiceFee +
                                  totalBaggageWithCharges +
                                  totalMealWithCharges +
                                  totalSeatWithCharges)
                            )}
                          </p>
                        </div>
                        <div
                          
                          onClick={() => {
                            setOpenPayBtn(true);
                            setPaymentGateway(true);
                          }}
                        >
                          {" "}
                          <button className="ewalletbtn">Pay Now</button>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ) : (
                <Col md={12} className="resp-mt-20">
                  <Card
                    className="dashboardbox "
                    style={{ marginBottom: "5px" }}
                  >
                    <Card.Body style={{ padding: "0px" }}>
                      <h2 className="boxheading walletMainDiv">
                        Pay using eWallet
                      </h2>
                      <div className="dashinnerboxs">
                        <div className="ewalletMainDiv">
                          <p>Current Balance </p>
                          <div style={{ width: "45px" }}>
                            <p>
                              INR{handleChangeCurrency(walletData.Wallet)}
                              <div
                                style={{
                                  fontSize: "11px",
                                  marginLeft: "-74px",
                                  fontWeight: "500",
                                  color: "red",
                                }}
                              >
                                (Insufficient Balance)
                              </div>
                            </p>
                          </div>
                        </div>
                        <div className="ewalletMainDiv">
                          <p>Flight Amount </p>
                          <p>
                            INR
                            {handleChangeCurrency(
                              flight.Fare.PublishedFare -
                                flight.Fare.Discount +
                                flight.Fare.ServiceFee +
                                totalBaggageWithCharges +
                                totalMealWithCharges +
                                totalSeatWithCharges
                            )}
                          </p>
                        </div>
                        <Link style={{ width: "100%" }} to="/wallet">
                          <button className="ewalletbtn">Top Up Request</button>
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>
          ) : (
            <FlightPayDetail />
          )} */}
        </Col>
      )}
      {srdvIdx === "SrdvTJ" && (
        <Col md={3}>
          <Row>
            <Col md={12} style={{}}>
              <p
                style={{
                  marginBottom: "10px",
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  padding: "5px",
                }}
                onClick={() => handleFareRecheck()}
              >
                {" "}
                Fare Recheck <FaSyncAlt style={{ marginLeft: "5px" }} />
              </p>
            </Col>
            <Col md={12}>
              {flight ? (
                <div className="flightBookingsecDiv">
                  <Row className="flightBookingSecDivRow3">
                    <Col xs={12} md={12}>
                      <span
                        className="lbl"
                        onClick={() => setShowdetail(!showdetail)}
                      >
                        Base Fare (
                        {Object.values(flight.searchQuery.paxInfo).reduce(
                          (total, quantity) => total + quantity,
                          0
                        )}{" "}
                        Travellers)
                      </span>{" "}
                      <FaAngleDown onClick={() => setShowdetail(!showdetail)} />{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}  */}INR
                        {handleChangeCurrency(
                          flight.totalPriceInfo.totalFareDetail.fC.BF
                        )}
                      </span>
                    </Col>
                  </Row>
                  {showdetail && (
                    <Row
                      className="flightBookingSecDivRow3"
                      style={{ width: "90%", marginInline: "auto" }}
                    >
                      <Col xs={12} md={12}>
                        <span className="lbl">
                          {flight.searchQuery.paxInfo.ADULT} x Adult
                        </span>{" "}
                        <span className="val">
                          {/* {flight.FareBreakdown[0].Currency} */}INR
                          {handleChangeCurrency(
                            flight.tripInfos[0].totalPriceList[0].fd.ADULT.fC.BF
                          )}
                        </span>
                      </Col>
                      {flight.tripInfos[0].totalPriceList[0].fd.CHILD && (
                        <Col xs={12} md={12}>
                          <span className="lbl">
                            {flight.searchQuery.paxInfo.CHILD} x Children
                          </span>{" "}
                          <span className="val">
                            {/* {flight.FareBreakdown[1].Currency} */}INR
                            {handleChangeCurrency(
                              flight.tripInfos[0].totalPriceList[0].fd.CHILD.fC
                                .BF
                            )}
                          </span>
                        </Col>
                      )}
                      {flight.tripInfos[0].totalPriceList[0].fd.INFANT && (
                        <Col xs={12} md={12}>
                          <span className="lbl">
                            {flight.searchQuery.paxInfo.INFANT} x Infants
                          </span>{" "}
                          <span className="val">
                            {/* {flight.FareBreakdown[2].Currency} */}INR
                            {handleChangeCurrency(
                              flight.tripInfos[0].totalPriceList[0].fd.INFANT.fC
                                .BF
                            )}
                          </span>
                        </Col>
                      )}
                    </Row>
                  )}
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Taxes & Surcharges</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency} */}INR
                        {handleChangeCurrency(
                          flight.totalPriceInfo.totalFareDetail.fC.TAF
                        )}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Discount</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency} */}INR
                        {/* {handleChangeCurrency(flight.Fare.Discount)} */}0
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Service Fee</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}  */}INR
                        {/* {handleChangeCurrency(flight.Fare.ServiceFee)} */}0
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Excess Baggage (0KG )</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}{" "} */}INR
                        {handleChangeCurrency(totalBaggageWithCharges)}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Meal (0)</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}{" "} */}INR
                        {handleChangeCurrency(totalMealWithCharges)}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Seat Charges</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}{" "} */}INR
                        {handleChangeCurrency(totalSeatWithCharges)}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    className="flightBookingSecDivRow2"
                    style={{ paddingBlock: "8px" }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Total Fare</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}{" "} */}INR
                        {handleChangeCurrency(
                          flight.totalPriceInfo.totalFareDetail.fC.TF
                        )}
                      </span>
                    </Col>
                  </Row>
                  <Row className="flightBookingsecDivRow">
                    <Col xs={12} md={12}>
                      <span className="lbl">You Pay:</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}{" "} */}INR
                        {handleChangeCurrency(
                          flight.totalPriceInfo.totalFareDetail.fC.TF
                        )}
                      </span>
                    </Col>
                  </Row>
                  {/* <Row > */}
                  {/* <Col> */}
                  <div
                    style={{ marginLeft: "-15px", marginRight: "-15px" }}
                    onClick={() => {
                      setOpenPayBtn(true);
                      setPaymentGateway(true);
                    }}
                  >
                    {" "}
                    <button className="ewalletbtn">Pay Now</button>
                  </div>
                  {/* </Col> */}
                  {/* </Row> */}
                </div>
              ) : (
                <FlightDetailSide />
              )}
            </Col>
            <Col md={12} className="resp-mt-20" style={{ marginTop: "20px" }}>
              <Card
                className=" "
                style={{ marginBottom: "5px", marginTop: "10px" }}
              >
                <Card.Body style={{ padding: "10px" }}>
                  <h2
                    className="boxheading walletMainDiv"
                    style={{
                      background: "#f4f4f4",
                      color: "black",
                      paddingInline: "15px",
                      textAlign: "center",
                      marginBottom: "0px",
                    }}
                  >
                    Fare has been reduced, Wanna Search Again?
                  </h2>
                  <div className="dashinnerboxs">
                    <div
                      // style={{ width: "100%" }}
                      onClick={() => {
                        // setOpenPayBtn(!openPayBtn);
                        // handleTicketBook();
                        navigate(-1);
                      }}
                    >
                      {" "}
                      <button className="ewalletbtn">Search Again</button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* {flight ? (
            <Row style={{ marginTop: "15px" }}>
              {walletData.Wallet >
              flight.totalPriceInfo.totalFareDetail.fC.TF ? (
                <Col md={12} className="resp-mt-20">
                  <Card
                    className="dashboardbox "
                    style={{ marginBottom: "5px" }}
                  >
                    <Card.Body style={{ padding: "0px" }}>
                      <h2
                        className="boxheading walletMainDiv"
                        style={{
                          background: "#f4f4f4",
                          color: "black",
                          paddingInline: "15px",
                        }}
                      >
                        Pay using eWallet
                      </h2>
                      <div className="dashinnerboxs">
                        <div
                          className="ewalletMainDiv"
                          style={{ borderBottom: "1px solid #e4e4e4" }}
                        >
                          <p>Current Balance </p>
                          <p>INR{handleChangeCurrency(walletData.Wallet)}</p>
                        </div>
                        <div
                          className="ewalletMainDiv"
                          style={{ borderBottom: "1px solid #e4e4e4" }}
                        >
                          <p>Flight Amount </p>
                          <p>
                            INR
                            {handleChangeCurrency(
                             flight.totalPriceInfo.totalFareDetail.fC.TF
                            )}
                          </p>
                        </div>
                        <div
                          className="ewalletMainDiv"
                          style={{ borderBottom: "1px solid #e4e4e4" }}
                        >
                          <p>Amount to deduct </p>
                          <p>
                            <HiMiniMinusSmall />INR
                            {handleChangeCurrency(
                             flight.totalPriceInfo.totalFareDetail.fC.TF
                            )}
                          </p>
                        </div>
                        <div
                          className="ewalletMainDiv"
                          style={{ borderBottom: "1px solid #e4e4e4" }}
                        >
                          <p>Remaining Balance </p>
                          <p>
                            INR
                            {handleChangeCurrency(
                              walletData.Wallet -
                                (flight.totalPriceInfo.totalFareDetail.fC.TF)
                            )}
                          </p>
                        </div>
                        <div
                         
                          onClick={() => {
                            setOpenPayBtn(true);
                            setPaymentGateway(true);
                        
                          }}
                        >
                          {" "}
                          <button className="ewalletbtn">Pay Now</button>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ) : (
                <Col md={12} className="resp-mt-20">
                  <Card
                    className="dashboardbox "
                    style={{ marginBottom: "5px" }}
                  >
                    <Card.Body style={{ padding: "0px" }}>
                      <h2 className="boxheading walletMainDiv">
                        Pay using eWallet
                      </h2>
                      <div className="dashinnerboxs">
                        <div className="ewalletMainDiv">
                          <p>Current Balance </p>
                          <div style={{ width: "45px" }}>
                            <p>
                              INR{handleChangeCurrency(walletData.Wallet)}
                              <div
                                style={{
                                  fontSize: "11px",
                                  marginLeft: "-74px",
                                  fontWeight: "500",
                                  color: "red",
                                }}
                              >
                                (Insufficient Balance)
                              </div>
                            </p>
                          </div>
                        </div>
                        <div className="ewalletMainDiv">
                          <p>Flight Amount </p>
                          <p>
                            INR
                            {handleChangeCurrency(
                              flight.totalPriceInfo.totalFareDetail.fC.TF
                            )}
                          </p>
                        </div>
                        <Link style={{ width: "100%" }} to="/wallet">
                          <button className="ewalletbtn">Top Up Request</button>
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>
          ) : (
            <FlightPayDetail />
          )} */}
        </Col>
      )}
      {srdvIdx === "SrdvP" && (
        <Col md={3}>
          <Row>
            <Col md={12} style={{}}>
              <p
                style={{
                  marginBottom: "10px",
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  padding: "5px",
                }}
                onClick={() => handleFareRecheck()}
              >
                {" "}
                Fare Recheck <FaSyncAlt style={{ marginLeft: "5px" }} />
              </p>
            </Col>
            <Col md={12}>
              {flight ? (
                <div className="flightBookingsecDiv">
                  <Row className="flightBookingSecDivRow3">
                    <Col xs={12} md={12}>
                      <span
                        className="lbl"
                        onClick={() => setShowdetail(!showdetail)}
                      >
                        Base Fare (
                        {flight.AirItineraryPricingInfo.PtcFareBreakdown.reduce(
                          (total, item) =>
                            total + item.PassengerTypeQuantity.Quantity,
                          0
                        )}{" "}
                        Travellers)
                      </span>{" "}
                      <FaAngleDown onClick={() => setShowdetail(!showdetail)} />{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}  */}INR
                        {handleChangeCurrency(
                          handleChangeCurrency2(
                            flight.AirItineraryPricingInfo.ItinTotalFare
                              .BaseFare
                          )
                        )}
                      </span>
                    </Col>
                  </Row>
                  {showdetail && (
                    <Row
                      className="flightBookingSecDivRow3"
                      style={{ width: "90%", marginInline: "auto" }}
                    >
                      <Col xs={12} md={12}>
                        <span className="lbl">
                          {
                            flight.AirItineraryPricingInfo.PtcFareBreakdown[0]
                              .PassengerTypeQuantity.Quantity
                          }{" "}
                          x Adult
                        </span>{" "}
                        <span className="val">
                          {/* {flight.FareBreakdown[0].Currency} */}INR
                          {handleChangeCurrency(
                            handleChangeCurrency2(
                              flight.AirItineraryPricingInfo.PtcFareBreakdown[0]
                                .PassengerFare.BaseFare
                            )
                          )}
                        </span>
                      </Col>
                      {flight.AirItineraryPricingInfo.PtcFareBreakdown[1] && (
                        <Col xs={12} md={12}>
                          <span className="lbl">
                            {
                              flight.AirItineraryPricingInfo.PtcFareBreakdown[1]
                                .PassengerTypeQuantity.Quantity
                            }{" "}
                            x Children
                          </span>{" "}
                          <span className="val">
                            {/* {flight.FareBreakdown[1].Currency} */}INR
                            {handleChangeCurrency(
                              handleChangeCurrency2(
                                flight.AirItineraryPricingInfo
                                  .PtcFareBreakdown[1].PassengerFare.BaseFare
                              )
                            )}
                          </span>
                        </Col>
                      )}
                      {flight.AirItineraryPricingInfo.PtcFareBreakdown[2] && (
                        <Col xs={12} md={12}>
                          <span className="lbl">
                            {
                              flight.AirItineraryPricingInfo.PtcFareBreakdown[2]
                                .PassengerTypeQuantity.Quantity
                            }{" "}
                            x Infants
                          </span>{" "}
                          <span className="val">
                            {/* {flight.FareBreakdown[2].Currency} */}INR
                            {handleChangeCurrency(
                              handleChangeCurrency2(
                                flight.AirItineraryPricingInfo
                                  .PtcFareBreakdown[2].PassengerFare.BaseFare
                              )
                            )}
                          </span>
                        </Col>
                      )}
                    </Row>
                  )}
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Taxes & Surcharges</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency} */}INR
                        {handleChangeCurrency(
                          handleChangeCurrency2(
                            flight.AirItineraryPricingInfo.ItinTotalFare
                              .ServiceTax +
                              flight.AirItineraryPricingInfo.ItinTotalFare
                                .TotalTax
                          )
                        )}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Discount</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency} */}INR
                        {/* {handleChangeCurrency(flight.Fare.Discount)} */}0
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Service Fee</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}  */}INR
                        {/* {handleChangeCurrency(flight.Fare.ServiceFee)} */} 0
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Excess Baggage (0KG )</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}{" "} */}INR
                        {handleChangeCurrency(
                          handleChangeCurrency2(totalBaggageWithCharges)
                        )}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Meal (0)</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}{" "} */}INR
                        {handleChangeCurrency(
                          handleChangeCurrency2(totalMealWithCharges)
                        )}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      borderBottom: "1px solid #e4e4e4",
                      paddingBlock: "8px",
                    }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Seat Charges</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}{" "} */}INR
                        {handleChangeCurrency(
                          handleChangeCurrency2(totalSeatWithCharges)
                        )}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    className="flightBookingSecDivRow2"
                    style={{ paddingBlock: "8px" }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">Total Fare</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}{" "} */}INR
                        {handleChangeCurrency(
                          handleChangeCurrency2(
                            flight.AirItineraryPricingInfo.ItinTotalFare
                              .TotalFare
                          )
                        )}
                      </span>
                    </Col>
                  </Row>
                  <Row className="flightBookingsecDivRow">
                    <Col xs={12} md={12}>
                      <span className="lbl">You Pay:</span> -{" "}
                      <span className="val">
                        {/* {flight.Fare.Currency}{" "} */}INR
                        {handleChangeCurrency(
                          handleChangeCurrency2(
                            flight.AirItineraryPricingInfo.ItinTotalFare
                              .TotalFare
                          )
                        )}
                      </span>
                    </Col>
                  </Row>
                </div>
              ) : (
                <FlightDetailSide />
              )}
            </Col>
            <Col md={12} className="resp-mt-20">
              <Card
                className=" "
                style={{ marginBottom: "5px", marginTop: "10px" }}
              >
                <Card.Body style={{ padding: "0px" }}>
                  <h2
                    className="boxheading walletMainDiv"
                    style={{
                      background: "#f4f4f4",
                      color: "black",
                      paddingInline: "15px",
                      textAlign: "center",
                      marginBottom: "0px",
                    }}
                  >
                    Fare has been reduced, Wanna Search Again?
                  </h2>
                  <div className="dashinnerboxs">
                    <div
                      // style={{ width: "100%" }}
                      onClick={() => {
                        // setOpenPayBtn(!openPayBtn);
                        // handleTicketBook();
                        navigate(-1);
                      }}
                    >
                      {" "}
                      <button className="ewalletbtn">Search Again</button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* {flight ? (
            <Row style={{ marginTop: "15px" }}>
              {parseInt(handleChangeCurrency(walletData.Wallet)) >
              parseInt(
                handleChangeCurrency(
                  handleChangeCurrency2(
                    flight.AirItineraryPricingInfo.ItinTotalFare.TotalFare
                  )
                )
              ) ? (
                <Col md={12} className="resp-mt-20">
                  <Card
                    className="dashboardbox "
                    style={{ marginBottom: "5px" }}
                  >
                    <Card.Body style={{ padding: "0px" }}>
                      <h2
                        className="boxheading walletMainDiv"
                        style={{
                          background: "#f4f4f4",
                          color: "black",
                          paddingInline: "15px",
                        }}
                      >
                        Pay using eWallet
                      </h2>
                      <div className="dashinnerboxs">
                        <div
                          className="ewalletMainDiv"
                          style={{ borderBottom: "1px solid #e4e4e4" }}
                        >
                          <p>Current Balance </p>
                          <p>INR{handleChangeCurrency(walletData.Wallet)}</p>
                        </div>
                        <div
                          className="ewalletMainDiv"
                          style={{ borderBottom: "1px solid #e4e4e4" }}
                        >
                          <p>Flight Amount </p>
                          <p>
                            INR
                            {handleChangeCurrency(
                              handleChangeCurrency2(
                                flight.AirItineraryPricingInfo.ItinTotalFare
                                  .TotalFare
                              )
                            )}
                          </p>
                        </div>
                        <div
                          className="ewalletMainDiv"
                          style={{ borderBottom: "1px solid #e4e4e4" }}
                        >
                          <p>Amount to deduct </p>
                          <p>
                            <HiMiniMinusSmall />INR
                            {handleChangeCurrency(
                              handleChangeCurrency2(
                                flight.AirItineraryPricingInfo.ItinTotalFare
                                  .TotalFare
                              )
                            )}
                          </p>
                        </div>
                        <div
                          className="ewalletMainDiv"
                          style={{ borderBottom: "1px solid #e4e4e4" }}
                        >
                          <p>Remaining Balance </p>
                          <p>
                           INR
                            {handleChangeCurrency(
                              walletData.Wallet -
                                handleChangeCurrency2(
                                  flight.AirItineraryPricingInfo.ItinTotalFare
                                    .TotalFare
                                )
                            )}
                          </p>
                        </div>
                        <div
                        
                          onClick={() => {
                            if (validatePassengerData()) {
                              setOpenPayBtn(true);
                              setPaymentGateway(true);
                            }
                          }}
                        >
                          {" "}
                          <button className="ewalletbtn">Pay Now</button>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ) : (
                <Col md={12} className="resp-mt-20">
                  <Card
                    className="dashboardbox "
                    style={{ marginBottom: "5px" }}
                  >
                    <Card.Body style={{ padding: "0px" }}>
                      <h2 className="boxheading walletMainDiv">
                        Pay using eWallet
                      </h2>
                      <div className="dashinnerboxs">
                        <div className="ewalletMainDiv">
                          <p>Current Balance </p>
                          <div style={{ width: "45px" }}>
                            <p>
                              INR{handleChangeCurrency(walletData.Wallet)}
                              <div
                                style={{
                                  fontSize: "11px",
                                  marginLeft: "-74px",
                                  fontWeight: "500",
                                  color: "red",
                                }}
                              >
                                (Insufficient Balance)
                              </div>
                            </p>
                          </div>
                        </div>
                        <div className="ewalletMainDiv">
                          <p>Flight Amount </p>
                          <p>
                            INR
                            {handleChangeCurrency(
                              handleChangeCurrency2(
                                flight.AirItineraryPricingInfo.ItinTotalFare
                                  .TotalFare
                              )
                            )}
                          </p>
                        </div>
                        <Link style={{ width: "100%" }} to="/wallet">
                          <button className="ewalletbtn">Top Up Request</button>
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>
          ) : (
            <FlightPayDetail />
          )} */}
        </Col>
      )}
    </>
  );
};

export default ChargesOneWay;
