import React from "react";
// import FlightBooking from '../BookingSearch/FlightBooking'
import HolidayPackages from "./Home/HolidayPackage";
import WhyUs from "./Home/WhyUs";
import SliderCode from "./Home/SliderCode";
import TopCities from "./TopCities/TopCities";
import FlightBooking from "../Flight/BookingSearch/FlightBooking";
import SectionOne from "../TourPackages/TourBanner/SectionOne";
import International from "../TourPackages/TourBanner/International";
import ReasonsToBook from "./Home/ReasonToBook";

const Hero = () => {
  return (
    <div>
      <FlightBooking />
      {/* <WhyUs /> */}
      <ReasonsToBook />
      <TopCities />
      <SectionOne />
      <SliderCode />
      <International />
      <HolidayPackages />
    </div>
  );
};

export default Hero;
