import React from "react";
// import { Col, Container, Row } from "react-bootstrap";
import "./TourBanner.css";
import { Col, Container, Row } from "react-bootstrap";
import { FaMapMarkerAlt } from "react-icons/fa";
import PlanTour from "../PlanTourPackage/PlanTour";
import PolpularTour from "../Popular/PolpularTour";
import TourDestinations from "../Destinations/TourDestinations";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import TravelSection from "./TravelSection";
import SectionOne from "./SectionOne";
import TripCategorySection from "./TripCategorySection";
import WhyUs from "./WhyUs";
import International from "./International";
import Trending from "./Trending";
import SliderCode from "../../Home/Home/SliderCode";
import ReasonsToBook from "../../Home/Home/ReasonToBook";

const TourBanner = () => {
  const navigate = useNavigate();
  const handleSearch = () => {
    navigate("/tour/detailPage");
  };
  return (
    <div>
      <TravelSection />
      <Trending />
      {/* <SliderCode/> */}
      <SectionOne />
      {/* <PlanTour /> */}

      <TripCategorySection />

      <International />
      {/* <WhyUs /> */}
      {/* <ReasonsToBook /> */}
      {/* <section className="tourBannerMainPage">
        <div className="image-layer-overlay"></div>
        <div className="tourBannerHeading">
          <h2>
            Travel &{" "}
            <span className="AdventureSpanTag">
              Adventure
              <img
                src="https://tevily-nextjs.vercel.app/_next/static/media/main-slider-shape-1.d5aadaec.png"
                alt=""
                className="AdventureImage"
              />
            </span>
          </h2>
          <p>Where Would You Like To Go?</p>
          <div className="ui-widget">
            <input
              type="text"
              className="form-controlsss ui-autocomplete-input"
              id="tags"
              placeholder="Search Destinations"
              autoComplete="off"
            />
            <button
              type="submit"
              className="search_btn"
              onclick="gotoDestination()"
            >
              <CiSearch color="#000" size={20}  />
            </button>
          </div>
        </div>
      </section> */}

      {/* <PolpularTour /> */}
      {/* <TourDestinations /> */}
    </div>
  );
};

export default TourBanner;
