import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import "./FlightInternational.css";
import { FlightListInfo } from "../FlightList/FlightListInfo";
import { FlightListInfoInternational } from "./FlightListInfoInternational";

const InternationalFlightListCard = ({
  e,
  handleMoreFare,
  handleClick,
  activeId,
  showModal,
  setShowModal,
  formatTime,
  handlebookmodal,
  handleChnageCurrency,
  handleChangeCurrency2,
  fareRules
}) => {
  return (
    <div className="internationtrip_search">
      {e.OriginDestinationOptions && (
        <Container
          className="refendable11 refendable11onword"
          refendable=""
          test_org_cust=""
          test_org_pub=""
          test_pub=""
          test_off=""
          // style={{maxWidth:"100%"}}
        >
          <Row
            className="price1"
            price="33827"
            data-price="33827"
            data-arrtime="1300"
            data-duration="102300"
            data-deptime="1125"
            data-flight="UL"
          >
            <Col
              className="price111 price111onword"
              timedep="67500"
              timearr="21"
              style={{ paddingInline: "5px" }}
            >
              <Row className="flight11" flight="UL">
                <Col className="flight112" flightcraft="">
                  <div className="stopscount" stop="1">
                    <div
                      id="divUL196UL225UL226UL195"
                      attrtime="21"
                      className="Price33827 allshow block-content-2 custom_block_content flight-list-v2 ULSri Lankan Airlines 0Stops bingo_button_4"
                    >
                      <div className="box-result custom_box_result">
                        <div className="inter_trip_left">
                          <h4>Depart</h4>
                          {/* {e.Segments[0].map((returnflight, index2) => ( */}
                          <ul
                            className="list-search-result result_list"
                            style={{ display: "flex" }}
                          >
                            <li>
                              <Image
                                src={`/Images/AirlineLogo/${e.OriginDestinationOptions[0].FlightSegments[0].OperatingAirline.Code}.gif`}
                                alt=""
                              />
                              <div className="flight_name obflight_name">
                                {e.OriginDestinationOptions[0].FlightSegments[0].OperatingAirline.EquipmentName}{" "}
                                <span className="flight_no">
                                  {e.OriginDestinationOptions[0].FlightSegments[0].OperatingAirline.Code}-
                                  {e.OriginDestinationOptions[0].FlightSegments[0].OperatingAirline.FlightNumber}
                                </span>
                              </div>
                            </li>
                            <li className="depart_time cus_dep_arr_time">
                              <span className="date departdate">
                                {e.OriginDestinationOptions[0].FlightSegments[0].DepartureAirportLocationCode}{" "}
                                {new Date(
                                  e.OriginDestinationOptions[0].FlightSegments[0].DepartureDateTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })}
                              </span>
                              {/* {e.Segments[0][0].Origin.Airport.CityName} */}
                              <div className="date_time">
                                {formatTime(e.OriginDestinationOptions[0].FlightSegments[0].DepartureDateTime)}
                              </div>
                            </li>
                            <li className="flight_time_between">
                              <span className="duration departdur">
                                {e.OriginDestinationOptions[0].FlightSegments[0].JourneyDuration} |
                                <div className="cus_tooltip">
                                  {e.OriginDestinationOptions[0].FlightSegments.length - 1 == 0
                                    ? "Non-Stop"
                                    : `${e.OriginDestinationOptions[0].FlightSegments.length - 1} Stops`}{" "}
                                  <span className="tooltiptext">
                                    {e.OriginDestinationOptions[0].FlightSegments.map(
                                        (segment, index) =>
                                          segment.DepartureAirportLocationCode
                                      )
                                      .join("→")}
                                    →
                                    {
                                      e.OriginDestinationOptions[0].FlightSegments[e.OriginDestinationOptions[0].FlightSegments.length-1].ArrivalAirportLocationCode
                                    }
                                  </span>
                                </div>
                              </span>
                              <div className="time_separete"></div>
                              <div className="flight_rel">
                                {" "}
                                {e.OriginDestinationOptions[0].FlightSegments.map(
                                        (segment, index) =>
                                          segment.DepartureAirportLocationCode
                                      )
                                      .join("→")}
                                    →
                                    {
                                      e.OriginDestinationOptions[0].FlightSegments[e.OriginDestinationOptions[0].FlightSegments.length-1].ArrivalAirportLocationCode
                                    }
                              </div>
                            </li>
                            <li className="arrive_time cus_dep_arr_time">
                              <span className="date arivedate">
                                {
                                  e.OriginDestinationOptions[0].FlightSegments[e.OriginDestinationOptions[0].FlightSegments.length-1].ArrivalAirportLocationCode
                                }{" "}
                                {new Date(
                                  e.OriginDestinationOptions[0].FlightSegments[e.OriginDestinationOptions[0].FlightSegments.length-1].ArrivalDateTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })}
                              </span>
                              {
                                // e.OriginDestinationOptions[e.OriginDestinationOptions.length-1].ArrivalAirportLocationCode
                              }
                              <div className="date_time">
                                {formatTime(
                                  e.OriginDestinationOptions[0].FlightSegments[e.OriginDestinationOptions[0].FlightSegments.length-1].ArrivalDateTime
                                )}
                              </div>
                            </li>
                          </ul>
                          {/* ))} */}
                          <div className="clearfix"></div>
                          <div className="hr_seperator"></div>
                          <h4>Return</h4>
                          {/* {e.Segments[1].map((returnflight, index2) => ( */}
                          <ul
                            className="list-search-result result_list"
                            style={{ display: "flex" }}
                          >
                            <li>
                              <Image
                                src={`/Images/AirlineLogo/${e.OriginDestinationOptions[1].FlightSegments[0].OperatingAirline.Code}.gif`}
                                alt=""
                              />
                              <div className="flight_name">
                                {e.OriginDestinationOptions[1].FlightSegments[0].OperatingAirline.EquipmentName}{" "}
                                <span className="flight_no">
                                  {" "}
                                  {e.OriginDestinationOptions[1].FlightSegments[0].OperatingAirline.Code}-
                                  {e.OriginDestinationOptions[1].FlightSegments[0].OperatingAirline.FlightNumber}
                                </span>
                              </div>
                            </li>
                            <li className="depart_time cus_dep_arr_time">
                              <span className="date">
                                {" "}
                                {e.OriginDestinationOptions[1].FlightSegments[0].DepartureAirportLocationCode}{" "}
                                {new Date(
                                  e.OriginDestinationOptions[1].FlightSegments[0].DepartureDateTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })}
                              </span>
                              {/* {e.Segments[1][0].Origin.Airport.CityName} */}
                              <div className="date_time cus_dep_arr_time">
                              {formatTime(e.OriginDestinationOptions[1].FlightSegments[0].DepartureDateTime)}
                              </div>
                            </li>
                            <li className="flight_time_between">
                              <span className="duration">
                              {e.OriginDestinationOptions[1].FlightSegments[0].JourneyDuration} |
                                <div className="cus_tooltip">
                                {e.OriginDestinationOptions[0].FlightSegments.length - 1 == 0
                                    ? "Non-Stop"
                                    : `${e.OriginDestinationOptions[1].FlightSegments.length - 1} Stops`}{" "}
                                    <span className="tooltiptext">
                                    {e.OriginDestinationOptions[1].FlightSegments.map(
                                        (segment, index) =>
                                          segment.DepartureAirportLocationCode
                                      )
                                      .join("→")}
                                    →
                                    {
                                      e.OriginDestinationOptions[1].FlightSegments[e.OriginDestinationOptions[1].FlightSegments.length-1].ArrivalAirportLocationCode
                                    }
                                  </span>
                                </div>
                              </span>
                              <div className="time_separete"></div>
                              <div className="flight_rel">
                                {" "}
                                {e.OriginDestinationOptions[1].FlightSegments.map(
                                        (segment, index) =>
                                          segment.DepartureAirportLocationCode
                                      )
                                      .join("→")}
                                    →
                                    {
                                      e.OriginDestinationOptions[1].FlightSegments[e.OriginDestinationOptions[1].FlightSegments.length-1].ArrivalAirportLocationCode
                                    }
                              </div>
                            </li>
                            <li className="arrive_time cus_dep_arr_time">
                              <span className="date">
                              {
                                  e.OriginDestinationOptions[1].FlightSegments[e.OriginDestinationOptions[1].FlightSegments.length-1].ArrivalAirportLocationCode
                                }{" "}
                                {new Date(
                                  e.OriginDestinationOptions[1].FlightSegments[e.OriginDestinationOptions[1].FlightSegments.length-1].ArrivalDateTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })}
                              </span>
                              {
                                // e.Segments[1][e.Segments[1].length - 1].Destination.Airport.CityName
                              }
                              <div className="date_time">
                              {formatTime(
                                  e.OriginDestinationOptions[1].FlightSegments[e.OriginDestinationOptions[1].FlightSegments.length-1].ArrivalDateTime
                                )}
                              </div>
                            </li>
                          </ul>
                          {/* ))} */}
                        </div>
                        <div className="book_flight">
                          <div className="refundable clr_green">
                            {/* <span>
                              {e.IsRefundable ? "Refundable" : "Non-Refundable"}
                            </span> */}
                          </div>
                          <span className="fli_price airlineprice">
                            <span className="mainprice">
                              ${handleChnageCurrency(handleChangeCurrency2(e.AirItineraryPricingInfo.ItinTotalFare.TotalFare))}
                            </span>
                          </span>
                          <div className="bookbtn_morefare">
                            <div className="book_btn">
                              <Button
                                className="btn small colorful-transparent hover-colorful btn_green checkout_flight"
                                faretype="offer 1"
                                fareindex="OB2"
                                faretraceid="23c6201f-d7df-45aa-a04e-9d2b533ea956"
                                fareuid="OB2"
                                resultindex="LS1DOFQuIyxYLCYpQSxWLFEsMGBgCmAK"
                                flightindex="NDU0UFEuMzk1MyMoUi01NSwsQyhWNTRQUS4zNGAKYAo="
                                onClick={() => handlebookmodal(e.FareSourceCode)}
                              >
                                Book Now
                              </Button>
                            </div>
                            <div className="more_farebtn"></div>
                          </div>
                        </div>

                        <div className="clearfix"></div>
                        <div className="flight_details">
                          <div className="main_flight_btn">
                            <a
                              is_btn="detail"
                              href="javascript:;"
                              sessionid="6648380ba3c11"
                              flightindex="UL196UL225UL226UL195"
                              className="details_btn flightdetailajax"
                              onClick={() => {
                                handleClick(e.FareSourceCode, "SrdvP");
                              }}
                            >
                              Flight Details
                            </a>
                          </div>
                          {/* <div
                          className="flight_details_info"
                          is_show="0"
                          id="show_UL196UL225UL226UL195"
                        >
                          <h4>Please wait loading...</h4>
                        </div> */}
                        </div>
                        {activeId === e.FareSourceCode && (
                          <FlightListInfoInternational
                            idx={e.FareSourceCode}
                            flight={e}
                            handleChnageCurrency={handleChnageCurrency}
                            handleChangeCurrency2={handleChangeCurrency2}
                            isInterNational={true}
                            SrdvIndex="SrdvP"
                            fareRules={fareRules}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default InternationalFlightListCard;
