import React, { useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const destinations = [
  {
    name: "Singapore",
    image:
      "https://www.easemytrip.com/holidays/Content/home-page/img/trnd_singapore.png",
    url: "/tour/singapore",
  },
  {
    name: "Europe",
    image:
      "https://www.easemytrip.com/holidays/Content/home-page/img/trnd_europe.png",
      url: "/tour/europe",
  },
  {
    name: "Bhutan",
    image:
      "https://www.easemytrip.com/holidays/Content/home-page/img/trnd_bhutan.png",
      url: "/tour/bhutan",
  },
  {
    name: "Australia",
    image:
      "https://www.easemytrip.com/holidays/Content/home-page/img/trnd_australia.png",
      url: "/tour/australia",
  },
  {
    name: "Bali",
    image:
      "https://www.easemytrip.com/holidays/Content/home-page/img/trnd_bali.png",
      url: "/tour/bali",
  },
  {
    name: "Bhutan",
    image:
      "https://www.easemytrip.com/holidays/Content/home-page/img/trnd_bhutan.png",
      url: "/tour/bhutan",
  },
];

const Trending = () => {
  const swiperRef = useRef(null);
  return (
    <div>
      <Container>
        <h2 className="custom_heading ft-700 mt-20">
          <span className="text-b text-center">Top </span>Trending Destinations
        </h2>
        <div className="text trending-tour-textbb text-center">
          Explore the hottest travel spots around the globe and experience the
          best of holidays.
        </div>
        <Row className="jckpt_box_prnt" style={{ position: "relative" }}>
          {/* {destinations.map((destination, index) => (
            <a key={index} className="jckpt_box text-center">
              <div className="img_box hoverEff">
                <img src={destination.image} alt={destination.name} />
              </div>
              <span className="f-20 mt-15 ft-600">{destination.name}</span>
            </a>
          ))} */}

          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              "@1.00": {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              "@1.50": {
                slidesPerView: 5,
                spaceBetween: 20,
              },
            }}
            modules={[Pagination, Autoplay, Navigation]}
            autoplay
            loop
            // navigation={true}
            navigation={{
              nextEl: ".custom-next",
              prevEl: ".custom-prev",
            }}
            className="mySwiper"
            style={{ paddingBottom: "40px" }}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
          >
            {destinations.map((destination, index) => (
              <SwiperSlide key={index}>
                <Link key={index} className="jckpt_box text-center" to={destination.url}>
                  <div className="img_box hoverEff">
                    <img src={destination.image} alt={destination.name} />
                  </div>
                  <span className="f-20 mt-15 ft-600">{destination.name}</span>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            className="custom-next"
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <FaChevronRight />
          </div>
          <div
            className="custom-prev"
            onClick={() => swiperRef.current?.slideNext()}
          >
            <FaChevronLeft />
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Trending;
