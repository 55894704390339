export const CountriesArray = [
    { code: "ad", name: "Andorra", flag: "🇦🇩" },
    { code: "ae", name: "United Arab Emirates", flag: "🇦🇪" },
    { code: "af", name: "Afghanistan", flag: "🇦🇫" },
    { code: "ag", name: "Antigua and Barbuda", flag: "🇦🇬" },
    { code: "ai", name: "Anguilla", flag: "🇦🇮" },
    { code: "al", name: "Albania", flag: "🇦🇱" },
    { code: "am", name: "Armenia", flag: "🇦🇲" },
    { code: "ao", name: "Angola", flag: "🇦🇴" },
    { code: "aq", name: "Antarctica", flag: "🇦🇶" },
    { code: "ar", name: "Argentina", flag: "🇦🇷" },
    { code: "as", name: "American Samoa", flag: "🇦🇸" },
    { code: "at", name: "Austria", flag: "🇦🇹" },
    { code: "au", name: "Australia", flag: "🇦🇺" },
    { code: "aw", name: "Aruba", flag: "🇦🇼" },
    { code: "ax", name: "Åland Islands", flag: "🇦🇽" },
    { code: "az", name: "Azerbaijan", flag: "🇦🇿" },
    { code: "ba", name: "Bosnia and Herzegovina", flag: "🇧🇦" },
    { code: "bb", name: "Barbados", flag: "🇧🇧" },
    { code: "bd", name: "Bangladesh", flag: "🇧🇩" },
    { code: "be", name: "Belgium", flag: "🇧🇪" },
    { code: "bf", name: "Burkina Faso", flag: "🇧🇫" },
    { code: "bg", name: "Bulgaria", flag: "🇧🇬" },
    { code: "bh", name: "Bahrain", flag: "🇧🇭" },
    { code: "bi", name: "Burundi", flag: "🇧🇮" },
    { code: "bj", name: "Benin", flag: "🇧🇯" },
    { code: "bl", name: "Saint Barthélemy", flag: "🇧🇱" },
    { code: "bm", name: "Bermuda", flag: "🇧🇲" },
    { code: "bn", name: "Brunei", flag: "🇧🇳" },
    { code: "bo", name: "Bolivia", flag: "🇧🇴" },
    { code: "bq", name: "Caribbean Netherlands", flag: "🇧🇶" },
    { code: "br", name: "Brazil", flag: "🇧🇷" },
    { code: "bs", name: "Bahamas", flag: "🇧🇸" },
    { code: "bt", name: "Bhutan", flag: "🇧🇹" },
    { code: "bv", name: "Bouvet Island", flag: "🇧🇻" },
    { code: "bw", name: "Botswana", flag: "🇧🇼" },
    { code: "by", name: "Belarus", flag: "🇧🇾" },
    { code: "bz", name: "Belize", flag: "🇧🇿" },
    { code: "ca", name: "Canada", flag: "🇨🇦" },
    { code: "cc", name: "Cocos (Keeling) Islands", flag: "🇨🇨" },
    { code: "cd", name: "DR Congo", flag: "🇨🇩" },
    { code: "cf", name: "Central African Republic", flag: "🇨🇫" },
    { code: "cg", name: "Republic of the Congo", flag: "🇨🇬" },
    { code: "ch", name: "Switzerland", flag: "🇨🇭" },
    { code: "ci", name: "Côte d'Ivoire (Ivory Coast)", flag: "🇨🇮" },
    { code: "ck", name: "Cook Islands", flag: "🇨🇰" },
    { code: "cl", name: "Chile", flag: "🇨🇱" },
    { code: "cm", name: "Cameroon", flag: "🇨🇲" },
    { code: "cn", name: "China", flag: "🇨🇳" },
    { code: "co", name: "Colombia", flag: "🇨🇴" },
    { code: "cr", name: "Costa Rica", flag: "🇨🇷" },
    { code: "cu", name: "Cuba", flag: "🇨🇺" },
    { code: "cv", name: "Cape Verde", flag: "🇨🇻" },
    { code: "cw", name: "Curaçao", flag: "🇨🇼" },
    { code: "cx", name: "Christmas Island", flag: "🇨🇽" },
    { code: "cy", name: "Cyprus", flag: "🇨🇾" },
    { code: "cz", name: "Czechia", flag: "🇨🇿" },
    { code: "de", name: "Germany", flag: "🇩🇪" },
    { code: "dj", name: "Djibouti", flag: "🇩🇯" },
    { code: "dk", name: "Denmark", flag: "🇩🇰" },
    { code: "dm", name: "Dominica", flag: "🇩🇲" },
    { code: "do", name: "Dominican Republic", flag: "🇩🇴" },
    { code: "dz", name: "Algeria", flag: "🇩🇿" },
    { code: "ec", name: "Ecuador", flag: "🇪🇨" },
    { code: "ee", name: "Estonia", flag: "🇪🇪" },
    { code: "eg", name: "Egypt", flag: "🇪🇬" },
    { code: "eh", name: "Western Sahara", flag: "🇪🇭" },
    { code: "er", name: "Eritrea", flag: "🇪🇷" },
    { code: "es", name: "Spain", flag: "🇪🇸" },
    { code: "et", name: "Ethiopia", flag: "🇪🇹" },
    { code: "eu", name: "European Union", flag: "🇪🇺" },
    { code: "fi", name: "Finland", flag: "🇫🇮" },
    { code: "fj", name: "Fiji", flag: "🇫🇯" },
    { code: "fk", name: "Falkland Islands", flag: "🇫🇰" },
    { code: "fm", name: "Micronesia", flag: "🇫🇲" },
    { code: "fo", name: "Faroe Islands", flag: "🇫🇴" },
    { code: "fr", name: "France", flag: "🇫🇷" },
    { code: "ga", name: "Gabon", flag: "🇬🇦" },
    { code: "gb", name: "United Kingdom", flag: "🇬🇧" },
    { code: "gb-eng", name: "England", flag: "🏴" },
    { code: "gb-nir", name: "Northern Ireland", flag: "🇬🇧" },
    { code: "gb-sct", name: "Scotland", flag: "🏴" },
    { code: "gb-wls", name: "Wales", flag: "🏴" },
    { code: "gd", name: "Grenada", flag: "🇬🇩" },
    { code: "ge", name: "Georgia", flag: "🇬🇪" },
    { code: "gf", name: "French Guiana", flag: "🇬🇫" },
    { code: "gg", name: "Guernsey", flag: "🇬🇬" },
    { code: "gh", name: "Ghana", flag: "🇬🇭" },
    { code: "gi", name: "Gibraltar", flag: "🇬🇮" },
    { code: "gl", name: "Greenland", flag: "🇬🇱" },
    { code: "gm", name: "Gambia", flag: "🇬🇲" },
    { code: "gn", name: "Guinea", flag: "🇬🇳" },
    { code: "gp", name: "Guadeloupe", flag: "🇬🇵" },
    { code: "gq", name: "Equatorial Guinea", flag: "🇬🇶" },
    { code: "gr", name: "Greece", flag: "🇬🇷" },
    { code: "gs", name: "South Georgia", flag: "🇬🇸" },
    { code: "gt", name: "Guatemala", flag: "🇬🇹" },
    { code: "gu", name: "Guam", flag: "🇬🇺" },
    { code: "gw", name: "Guinea-Bissau", flag: "🇬🇼" },
    { code: "gy", name: "Guyana", flag: "🇬🇾" },
    { code: "hk", name: "Hong Kong", flag: "🇭🇰" },
    { code: "hm", name: "Heard Island and McDonald Islands", flag: "🇭🇲" },
    { code: "hn", name: "Honduras", flag: "🇭🇳" },
    { code: "hr", name: "Croatia", flag: "🇭🇷" },
    { code: "ht", name: "Haiti", flag: "🇭🇹" },
    { code: "hu", name: "Hungary", flag: "🇭🇺" },
    { code: "id", name: "Indonesia", flag: "🇮🇩" },
    { code: "ie", name: "Ireland", flag: "🇮🇪" },
    { code: "il", name: "Israel", flag: "🇮🇱" },
    { code: "im", name: "Isle of Man", flag: "🇮🇲" },
    { code: "in", name: "India", flag: "🇮🇳" },
    { code: "io", name: "British Indian Ocean Territory", flag: "🇮🇴" },
    { code: "iq", name: "Iraq", flag: "🇮🇶" },
    { code: "ir", name: "Iran", flag: "🇮🇷" },
    { code: "is", name: "Iceland", flag: "🇮🇸" },
    { code: "it", name: "Italy", flag: "🇮🇹" },
    { code: "je", name: "Jersey", flag: "🇯🇪" },
    { code: "jm", name: "Jamaica", flag: "🇯🇲" },
    { code: "jo", name: "Jordan", flag: "🇯🇴" },
    { code: "jp", name: "Japan", flag: "🇯🇵" },
    { code: "ke", name: "Kenya", flag: "🇰🇪" },
    { code: "kg", name: "Kyrgyzstan", flag: "🇰🇬" },
    { code: "kh", name: "Cambodia", flag: "🇰🇭" },
    { code: "ki", name: "Kiribati", flag: "🇰🇮" },
    { code: "km", name: "Comoros", flag: "🇰🇲" },
    { code: "kn", name: "Saint Kitts and Nevis", flag: "🇰🇳" },
    { code: "kp", name: "North Korea", flag: "🇰🇵" },
    { code: "kr", name: "South Korea", flag: "🇰🇷" },
    { code: "kw", name: "Kuwait", flag: "🇰🇼" },
    { code: "ky", name: "Cayman Islands", flag: "🇰🇾" },
    { code: "kz", name: "Kazakhstan", flag: "🇰🇿" },
    { code: "la", name: "Laos", flag: "🇱🇦" },
    { code: "lb", name: "Lebanon", flag: "🇱🇧" },
    { code: "lc", name: "Saint Lucia", flag: "🇱🇨" },
    { code: "li", name: "Liechtenstein", flag: "🇱🇮" },
    { code: "lk", name: "Sri Lanka", flag: "🇱🇰" },
    { code: "lr", name: "Liberia", flag: "🇱🇷" },
    { code: "ls", name: "Lesotho", flag: "🇱🇸" },
    { code: "lt", name: "Lithuania", flag: "🇱🇹" },
    { code: "lu", name: "Luxembourg", flag: "🇱🇺" },
    { code: "lv", name: "Latvia", flag: "🇱🇻" },
    { code: "ly", name: "Libya", flag: "🇱🇾" },
    { code: "ma", name: "Morocco", flag: "🇲🇦" },
    { code: "mc", name: "Monaco", flag: "🇲🇨" },
    { code: "md", name: "Moldova", flag: "🇲🇩" },
    { code: "me", name: "Montenegro", flag: "🇲🇪" },
    { code: "mf", name: "Saint Martin", flag: "🇲🇫" },
    { code: "mg", name: "Madagascar", flag: "🇲🇬" },
    { code: "mh", name: "Marshall Islands", flag: "🇲🇭" },
    { code: "mk", name: "North Macedonia", flag: "🇲🇰" },
    { code: "ml", name: "Mali", flag: "🇲🇱" },
    { code: "mm", name: "Myanmar", flag: "🇲🇲" },
    { code: "mn", name: "Mongolia", flag: "🇲🇳" },
    { code: "mo", name: "Macau", flag: "🇲🇴" },
    { code: "mp", name: "Northern Mariana Islands", flag: "🇲🇵" },
    { code: "mq", name: "Martinique", flag: "🇲🇶" },
    { code: "mr", name: "Mauritania", flag: "🇲🇷" },
    { code: "ms", name: "Montserrat", flag: "🇲🇸" },
    { code: "mt", name: "Malta", flag: "🇲🇹" },
    { code: "mu", name: "Mauritius", flag: "🇲🇺" },
    { code: "mv", name: "Maldives", flag: "🇲🇻" },
    { code: "mw", name: "Malawi", flag: "🇲🇼" },
    { code: "mx", name: "Mexico", flag: "🇲🇽" },
    { code: "my", name: "Malaysia", flag: "🇲🇾" },
    { code: "mz", name: "Mozambique", flag: "🇲🇿" },
    { code: "na", name: "Namibia", flag: "🇳🇦" },
    { code: "nc", name: "New Caledonia", flag: "🇳🇨" },
    { code: "ne", name: "Niger", flag: "🇳🇪" },
    { code: "nf", name: "Norfolk Island", flag: "🇳🇫" },
    { code: "ng", name: "Nigeria", flag: "🇳🇬" },
    { code: "ni", name: "Nicaragua", flag: "🇳🇮" },
    { code: "nl", name: "Netherlands", flag: "🇳🇱" },
    { code: "no", name: "Norway", flag: "🇳🇴" },
    { code: "np", name: "Nepal", flag: "🇳🇵" },
    { code: "nr", name: "Nauru", flag: "🇳🇷" },
    { code: "nu", name: "Niue", flag: "🇳🇺" },
    { code: "nz", name: "New Zealand", flag: "🇳🇿" },
    { code: "om", name: "Oman", flag: "🇴🇲" },
    { code: "pa", name: "Panama", flag: "🇵🇦" },
    { code: "pe", name: "Peru", flag: "🇵🇪" },
    { code: "pf", name: "French Polynesia", flag: "🇵🇫" },
    { code: "pg", name: "Papua New Guinea", flag: "🇵🇬" },
    { code: "ph", name: "Philippines", flag: "🇵🇭" },
    { code: "pk", name: "Pakistan", flag: "🇵🇰" },
    { code: "pl", name: "Poland", flag: "🇵🇱" },
    { code: "pm", name: "Saint Pierre and Miquelon", flag: "🇵🇲" },
    { code: "pn", name: "Pitcairn Islands", flag: "🇵🇳" },
    { code: "pr", name: "Puerto Rico", flag: "🇵🇷" },
    { code: "ps", name: "Palestine", flag: "🇵🇸" },
    { code: "pt", name: "Portugal", flag: "🇵🇹" },
    { code: "pw", name: "Palau", flag: "🇵🇼" },
    { code: "py", name: "Paraguay", flag: "🇵🇾" },
    { code: "qa", name: "Qatar", flag: "🇶🇦" },
    { code: "re", name: "Réunion", flag: "🇷🇪" },
    { code: "ro", name: "Romania", flag: "🇷🇴" },
    { code: "rs", name: "Serbia", flag: "🇷🇸" },
    { code: "ru", name: "Russia", flag: "🇷🇺" },
    { code: "rw", name: "Rwanda", flag: "🇷🇼" },
    { code: "sa", name: "Saudi Arabia", flag: "🇸🇦" },
    { code: "sb", name: "Solomon Islands", flag: "🇸🇧" },
    { code: "sc", name: "Seychelles", flag: "🇸🇨" },
    { code: "sd", name: "Sudan", flag: "🇸🇩" },
    { code: "se", name: "Sweden", flag: "🇸🇪" },
    { code: "sg", name: "Singapore", flag: "🇸🇬" },
    { code: "sh", name: "Saint Helena, Ascension and Tristan da Cunha", flag: "🇸🇭" },
    { code: "si", name: "Slovenia", flag: "🇸🇮" },
    { code: "sj", name: "Svalbard and Jan Mayen", flag: "🇸🇯" },
    { code: "sk", name: "Slovakia", flag: "🇸🇰" },
    { code: "sl", name: "Sierra Leone", flag: "🇸🇱" },
    { code: "sm", name: "San Marino", flag: "🇸🇲" },
    { code: "sn", name: "Senegal", flag: "🇸🇳" },
    { code: "so", name: "Somalia", flag: "🇸🇴" },
    { code: "sr", name: "Suriname", flag: "🇸🇷" },
    { code: "ss", name: "South Sudan", flag: "🇸🇸" },
    { code: "st", name: "São Tomé and Príncipe", flag: "🇸🇹" },
    { code: "sv", name: "El Salvador", flag: "🇸🇻" },
    { code: "sx", name: "Sint Maarten", flag: "🇸🇽" },
    { code: "sy", name: "Syria", flag: "🇸🇾" },
    { code: "sz", name: "Eswatini (Swaziland)", flag: "🇸🇿" },
    { code: "tc", name: "Turks and Caicos Islands", flag: "🇹🇨" },
    { code: "td", name: "Chad", flag: "🇹🇩" },
    { code: "tf", name: "French Southern and Antarctic Lands", flag: "🇹🇫" },
    { code: "tg", name: "Togo", flag: "🇹🇬" },
    { code: "th", name: "Thailand", flag: "🇹🇭" },
    { code: "tj", name: "Tajikistan", flag: "🇹🇯" },
    { code: "tk", name: "Tokelau", flag: "🇹🇰" },
    { code: "tl", name: "Timor-Leste", flag: "🇹🇱" },
    { code: "tm", name: "Turkmenistan", flag: "🇹🇲" },
    { code: "tn", name: "Tunisia", flag: "🇹🇳" },
    { code: "to", name: "Tonga", flag: "🇹🇴" },
    { code: "tr", name: "Turkey", flag: "🇹🇷" },
    { code: "tt", name: "Trinidad and Tobago", flag: "🇹🇹" },
    { code: "tv", name: "Tuvalu", flag: "🇹🇻" },
    { code: "tw", name: "Taiwan", flag: "🇹🇼" },
    { code: "tz", name: "Tanzania", flag: "🇹🇿" },
    { code: "ua", name: "Ukraine", flag: "🇺🇦" },
    { code: "ug", name: "Uganda", flag: "🇺🇬" },
    { code: "um", name: "United States Minor Outlying Islands", flag: "🇺🇲" },
    { code: "us", name: "United States", flag: "🇺🇸" },
    { code: "uy", name: "Uruguay", flag: "🇺🇾" },
    { code: "uz", name: "Uzbekistan", flag: "🇺🇿" },
    { code: "va", name: "Vatican City", flag: "🇻🇦" },
    { code: "vc", name: "Saint Vincent and the Grenadines", flag: "🇻🇨" },
    { code: "ve", name: "Venezuela", flag: "🇻🇪" },
    { code: "vg", name: "British Virgin Islands", flag: "🇻🇬" },
    { code: "vi", name: "United States Virgin Islands", flag: "🇻🇮" },
    { code: "vn", name: "Vietnam", flag: "🇻🇳" },
    { code: "vu", name: "Vanuatu", flag: "🇻🇺" },
    { code: "wf", name: "Wallis and Futuna", flag: "🇼🇫" },
    { code: "ws", name: "Samoa", flag: "🇼🇸" },
    { code: "xk", name: "Kosovo", flag: "🇽🇰" },
    { code: "ye", name: "Yemen", flag: "🇾🇪" },
    { code: "yt", name: "Mayotte", flag: "🇾🇹" },
    { code: "za", name: "South Africa", flag: "🇿🇦" },
    { code: "zm", name: "Zambia", flag: "🇿🇲" },
    { code: "zw", name: "Zimbabwe", flag: "🇿🇼" }
  ];
  