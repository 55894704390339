import React from "react";
import TopBanner from "./TopBanner";
import GreatDeals from "./GreatDeals";
import FeaturedCategories from "./FeaturedCategories";

const Offers = () => {
  return (
    <>
      <TopBanner />
      <GreatDeals />
      <FeaturedCategories />
    </>
  );
};

export default Offers;
